const registrationConst = {
  CREATE_ACCOUNT: 'Create Account',
  CERTIFICATE: 'Upload Certificate',
  DOCUMENT: 'Upload Document',
  NO_IMAGE: ' No image added',
  CREATE_YOUR_ACCOUNT: 'Create your account!',
  SIGN_IN: 'Sign in',
  ALREADY: 'Already have an account?',
  ACCOUNT: "Don't have an account?",
  P10: 'consectetur adipiscing elit duis tristique sollicitudin nibh sit amet commodo nulla.',
  TERM_TEXT: 'By clicking this button, you agree to the ',
  TERM_TEXT2: 'and the ',
  TC: 'Terms and Conditions ',
  PRIVACY: 'Privacy Policy.',
  ADD_MORE: '+ Add more',
  REMOVE: 'Remove',
  LETS_GET: "Let's get you set",
  P11: 'Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium fringilla blandit. Etiam ut accumsan leo. Aliquam id mi quam.',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  COACHING: 'Coaching Accreditation',
  LINKS: 'Links:',
  BACK: 'Back',
  NEXT: 'Next',
  SPECIALIZATION: 'Language and specialization',
  STEP: 'Step 3 of 5',
  EDUCATION: 'Educational Qualification',
  OPTIONAL: '(Optional)',
  MEDIA: 'Media',
  P5: 'Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in orci risus. Donec pretium fringilla blandit.',
  SKIP: 'Skip',
  EXPERIENCE: 'Experience',
  TITLE: 'I am currently working in this role',
  STEP01: 'Step 1 of 5',
  STEP02: 'Step 2 of 5',
  STEP03: 'Step 3 of 5',
  STEP04: 'Step 4 of 5',
  STEP05: 'Step 5 of 5',
  FIRST: 'First Feature',
  DESCRIPTION:
    'In fermentum et sollicitudin ac orci phasellus egestas tellus rutrum isus in hendrerit gravida rutrum quisque non tellus orci ac',
  SECOND: 'Second Feature',
  THIRD: 'Third Feature',
  FORTH: 'Forth Feature',
  FIFTH: 'Fifth Feature',
  PATH_SPECIALIZATION_DETAILS: '/coach/registration/specialization-details',
  PATH_PERSONAL_DETAILS: '/coach/registration/personal-details',
  PATH_EXPERIENCE_DETAILS: '/coach/registration/experience-details',
  PATH_COACHING_DETAILS: '/coach/registration/coaching-details',
  PATH_EDUCATIONAL_DETAILS: '/coach/registration/educational-details',

  PATH_LENDING_PAGE: '/',
  // PATH_SPECIALIZATION:'/kun/registration/specialization-details',
};

export default registrationConst;

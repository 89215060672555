import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { performanceInfo } from '../../coachSlices/coachPerformance';
import { Image } from '../../components/Image';
import NoData from '../../components/noData';

interface IClientsCardProps {
  coachRevenueClients: any;
  isLoading: boolean;
}
const ClientsCard: FC<IClientsCardProps> = ({
  coachRevenueClients,
  isLoading,
}): JSX.Element => {
  const { clientsFilter } = useSelector(performanceInfo);
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  const mobileViewTableData = coachRevenueClients?.map((item: any) =>
    clientsFilter?.serviceStatusFilter === ''
      ? [
          {
            title1: t('PREFORMANCE.NAME'),
            value: (
              <div className="flex flex-row text-[14px] font-bold gap-2 items-center text-[#153243]">
                {' '}
                <Image
                  id={item?.clientProfilePicId}
                  propClass={'w-10 h-10 rounded-full bg-lightBlack'}
                  bgClass={
                    'w-10 h-10 rounded-full flex items-center justify-center bg-lightBlack'
                  }
                  bgImgUrl={'/assets/default-img-icon.svg'}
                />
                {item?.clientName}
              </div>
            ),
          },
          { title1: t('PREFORMANCE.ENROLLED_ITEM'), value: item?.data?.title },
          {
            title1: t('PREFORMANCE.PURCHASED_DATE'),
            value: item?.startDate,
          },
          {
            title1: t('PREFORMANCE.OFFERING'),
            value:
              item?.data?.serviceType !== 'SESSION'
                ? '--'
                : `${item?.data?.duration} ${t('MINS')}`,
          },
          // { title1: t('PREFORMANCE.RATING'), value: item?.data?.rating },
          {
            title1: t('PREFORMANCE.PRICE'),
            value: `${t('SAR')} ${item?.data?.price}`,
          },
        ]
      : clientsFilter?.serviceStatusFilter === 'CLOSED'
      ? [
          {
            title1: t('PREFORMANCE.NAME'),
            value: (
              <div className="flex flex-row text-[14px] font-bold gap-2 items-center text-[#153243]">
                {' '}
                <Image
                  id={item?.clientProfilePicId}
                  propClass={'w-10 h-10 rounded-full bg-lightBlack'}
                  bgClass={
                    'w-10 h-10 rounded-full flex items-center justify-center bg-lightBlack'
                  }
                  bgImgUrl={'/assets/default-img-icon.svg'}
                />
                {item?.clientName}
              </div>
            ),
          },
          { title1: t('PREFORMANCE.ENROLLED_ITEM'), value: item?.data?.title },

          { title1: t('PREFORMANCE.CATEGORY'), value: item?.data?.serviceType },
          {
            title1: t('PREFORMANCE.START_DATE'),
            value: moment(item?.startDate).locale('en').format('MMM DD, YYYY'),
          },
          {
            title1: t('PREFORMANCE.END_DATE'),
            value: moment(item?.endDate).locale('en').format('MMM DD, YYYY'),
          },
          {
            title1: t('PREFORMANCE.PRICE'),
            value: `${t('SAR')} ${item?.data?.price}`,
          },
        ]
      : [
          {
            title1: t('PREFORMANCE.NAME'),
            value: (
              <div className="flex flex-row text-[14px] font-bold gap-2 items-center text-[#153243]">
                {' '}
                <Image
                  id={item?.clientProfilePicId}
                  propClass={'w-10 h-10 rounded-full bg-lightBlack'}
                  bgClass={
                    'w-10 h-10 rounded-full flex items-center justify-center bg-lightBlack'
                  }
                  bgImgUrl={'/assets/profile/avatar.svg'}
                />
                {item?.clientName}
              </div>
            ),
          },
          { title1: t('PREFORMANCE.ENROLLED_ITEM'), value: item?.data?.title },

          { title1: t('PREFORMANCE.CATEGORY'), value: item?.data?.serviceType },
          {
            title1: t('PREFORMANCE.START_DATE'),
            value: moment(item?.startDate).locale('en').format('MMM DD, YYYY'),
          },
          {
            title1: t('PREFORMANCE.END_DATE'),
            value: moment(item?.endDate).locale('en').format('MMM DD, YYYY'),
          },
          {
            title1: t('PREFORMANCE.REMAINING_DAYS'),
            value: (
              <div className="text-[12px] font-bold text-[#153243]">
                {item?.remainingDays} {t('PREFORMANCE.DAYS')}
              </div>
            ),
          },
        ]
  );

  return coachRevenueClients?.length ? (
    <div>
      <div className=" flex flex-col gap-4 md:hidden">
        {mobileViewTableData.map((item: any) => (
          <ul className="border rounded border-disableGray shadow-lg ">
            {item.map((itemValue: any) => (
              <li className="flex flex-row  justify-between border-b border-disableGray">
                <div className="p-4 text-11 font-semibold font-general rtl:font-Almarai text-gray">
                  {itemValue.title1}
                </div>
                <div
                  className={`text-12 font-normal font-general rtl:font-Almarai p-4 flex flex-row items-center gap-1 ${
                    ['Revenue', 'item'].includes(itemValue.title1) &&
                    'font-bold'
                  }`}
                >
                  {itemValue.value}
                </div>
              </li>
            ))}
            {clientsFilter?.serviceStatusFilter !== 'CLOSED' &&
              clientsFilter?.serviceStatusFilter !== '' && (
                <li className="flex flex-row  justify-center border-b border-disableGray p-5">
                  <div
                    onClick={() => navigate('/schedule')}
                    className={
                      ' w-fit flex justify-center py-2 px-4 rounded  font-normal text-13 font-general rtl:font-Almarai pt-2 px-4 bg-primary text-[#FFFFFF] cursor-pointer'
                    }
                  >
                    {t('PREFORMANCE.VIEW_CALENDAR')}
                  </div>
                </li>
              )}
          </ul>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex  md:hidden justify-center  h-56 items-center text-light-black font-general rtl:font-Almarai font-normal">
      <NoData />
    </div>
  );
};

export default ClientsCard;

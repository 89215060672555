import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IPasswordDetails,
  IPasswordPayload,
  IPasswordRequestPayload,
} from '../coachInterfaces/passwordInterface';
import { postApi } from '../coachServices';
import { postAccountSecurity } from '../coachServices/authenticationService';
import { env } from '../config/env';
import { showAlert } from '../slice/alert';

export const passwordRequest = createAsyncThunk(
  'passwordRequest',
  async (userData: IPasswordRequestPayload, thunkAPI) => {
    try {
      const response = await postApi(
        `${env.development.BASE_URL}/auth/forgot-password
        `,
        userData
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateAccountSecurity = createAsyncThunk(
  'updateSocialMedia',
  async (userData: any, thunkAPI) => {
    try {
      const response = await postAccountSecurity(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setPasswordRequest = createAsyncThunk(
  'setPasswordRequest',
  async (userData: IPasswordPayload, thunkAPI) => {
    try {
      const response = await postApi(
        `${env.development.BASE_URL}/auth/change-password
        `,
        userData
      );
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState: IPasswordDetails = {
  isSuccess: false,
  setNewPassword: [],
  updateNewPassword: [],
  loading: false,
  emailError: '',
};

export const passwordSlice = createSlice({
  name: 'personalData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(passwordRequest.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
      })
      .addCase(passwordRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.emailError = action.payload?.response?.data;
        state.isSuccess = action?.payload?.data;
      })
      .addCase(passwordRequest.rejected, (state, err) => {
        state.loading = false;
        state.isSuccess = false;
      })
      .addCase(setPasswordRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(setPasswordRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.setNewPassword = action.payload;
      })
      .addCase(setPasswordRequest.rejected, (state, err) => {
        state.loading = false;
        state.setNewPassword = err;
      })

      //UPDATE PASSWORD
      .addCase(updateAccountSecurity.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAccountSecurity.fulfilled, (state, action) => {
        state.loading = false;
        state.updateNewPassword = action.payload;
      })
      .addCase(updateAccountSecurity.rejected, (state, err) => {
        state.loading = false;
        state.updateNewPassword = err;
      });
  },
});

export const passwordReducer = passwordSlice.reducer;

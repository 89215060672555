import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectClientHome } from '../../clientSlices/home';
import { USER_TYPE } from '../../coachConstants/genericConstants';
import { Image } from '../../components/Image';
import { useAppSelector } from '../../hooks';
import Button from '../buttons';

const ChatProfile = ({ setMobileViewContent }: any) => {
  const dispatch = useDispatch<any>();
  const { coachByIdData, clientByIdData, coachSocialByIdData } =
    useSelector(selectClientHome);
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');

  const { socialMediaDetails } = useAppSelector((state) => state.socialMedia);
  const userData =
    userType === USER_TYPE.CLIENT ? coachByIdData : clientByIdData;
  const onClickUrl = (name: string) => {
    const data = coachSocialByIdData?.find((item: any) => name === item.name);
    window.location.replace(data?.link);
  };

  return (
    <div className="h-auto   xll:w-[40%] w-[100%] boxShadow  rounded-lg relative">
      <img
        className="xll:hidden flex absolute top-[26px] ltr:left-5 rtl:right-5 cursror-pointer"
        src="/assets/goBack.svg"
        alt=""
        onClick={() => setMobileViewContent('CHAT')}
      />

      <div className="pt-6 px-4  flex flex-col">
        <div className="flex justify-center ">
          <Image
            id={userData?.profilePicId}
            propClass={'w-20 h-20 rounded-full bg-lightBlack'}
            bgClass={
              'w-20 h-20 rounded-full bg-lightBlack flex justify-center items-center'
            }
            bgImgUrl={'/assets/profile/avatar.svg'}
          />
        </div>
        <div className="flex justify-center pt-3 pb-1">
          <div className="w-fit text-18 font-satoshi rtl:font-Almarai font-bold">
            {userData?.fullName}
          </div>{' '}
        </div>
        <div className="flex justify-center pb-4">
          <div className="w-fit text-12 font-general rtl:font-Almarai font-normal text-gray">
            {userType === USER_TYPE.CLIENT
              ? userData?.specialization?.map((item: string) => (
                  <span>{item},&nbsp;</span>
                ))
              : userData.email}
          </div>{' '}
        </div>
        {userType === USER_TYPE.CLIENT && (
          <>
            <div className="flex justify-center pb-4">
              <div className="flex gap-4">
                <div className="flex gap-1 items-start">
                  <img
                    className=" h-4"
                    src="/assets/messages/time.svg"
                    alt=""
                  />
                  <div className="w-fit  text-12 font-general rtl:font-Almarai font-normal flex flex-col">
                    {userData?.hoursAndServicesResponsesList?.responseList?.map(
                      (item: any) => {
                        const startTime = moment(item.startTime, 'HH:mm:ss');
                        const startFormattedTime =
                          startTime.locale('en').format('h:mm:ss A');
                        const endTime = moment(item.endTime, 'HH:mm:ss');
                        const endFormattedTime = endTime.locale('en').format('h:mm:ss A');
                        return (
                          <span>
                            {item?.day} :&nbsp;{startFormattedTime}-
                            {endFormattedTime}
                          </span>
                        );
                      }
                    )}
                  </div>{' '}
                </div>
                <div className="flex gap-1 items-start">
                  <img
                    className=" h-4"
                    src="/assets/messages/language.svg"
                    alt=""
                  />
                  <div className="w-fit  text-12 font-general rtl:font-Almarai font-normal">
                    {userData?.country}
                  </div>{' '}
                </div>
              </div>
            </div>
            {coachSocialByIdData?.length ? (
              <div className="flex gap-4 pb-6  justify-center">
                <div className="flex border w-[31px] h-[31px] items-center justify-center rounded border-lightBlack">
                  {' '}
                  <img
                    onClick={() => onClickUrl('WEBSITE')}
                    className=" h-3 cursor-pointer"
                    src="/assets/messages/url.svg"
                    alt=""
                  />
                </div>
                <div className="flex border w-[31px] h-[31px] items-center justify-center rounded border-lightBlack">
                  <img
                    onClick={() => onClickUrl('TWITTER')}
                    className=" h-4 cursor-pointer"
                    src="/assets/messages/twitter.svg"
                    alt=""
                  />
                </div>
                <div className="flex border w-[31px] h-[31px] items-center justify-center rounded border-lightBlack">
                  {' '}
                  <img
                    onClick={() => onClickUrl('FACEBOOK')}
                    className=" h-4 cursor-pointer"
                    src="/assets/messages/facebook.svg"
                    alt=""
                  />
                </div>
                <div className="flex border w-[31px] h-[31px] items-center justify-center rounded border-lightBlack">
                  {' '}
                  <img
                    onClick={() => onClickUrl('LINKEDIN')}
                    className=" h-4 cursor-pointer"
                    src="/assets/messages/linkedin.svg"
                    alt=""
                  />
                </div>
                <div className="flex border w-[31px] h-[31px] items-center justify-center rounded border-lightBlack">
                  {' '}
                  <img
                    onClick={() => onClickUrl('YOUTUBE')}
                    className=" h-4 cursor-pointer"
                    src="/assets/messages/youtube.svg"
                    alt=""
                  />
                </div>
              </div>
            ) : null}

            <Button
              className={''}
              onClick={() =>
                navigate('/coach-profile-view?id=' + coachByIdData?.id)
              }
              children={
                <div className=" bg-lightGreen py-[10px] text-16 font-satoshi rtl:font-Almarai font-bold rounded ">
                  {' '}
                  View Profile
                </div>
              }
            />
          </>
        )}
      </div>

      <div className="h-[10vh]"></div>
    </div>
  );
};

export default ChatProfile;

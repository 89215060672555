import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listLanguages } from '../clientSlices/profile';
import { weekDaysList } from '../coachConstants/coachSessionConstants';
import { decodeUserId, getAccessToken } from '../coachHelpers/localStorage';
import {
  convert24to12,
  convert24to12Item,
  getAssessmentForEditPayload,
  getAssessmentValuePayload,
  getHoursData,
  getTagsFromAssessmentEdit,
  isAlternative,
  thirdPartyEditPayloadCreator,
  utcToLocal,
  UtcToLocaldayConversion,
  utcToLocalDaySelection,
} from '../coachHelpers/utility';
import { coachHomeInterface } from '../coachInterfaces/coachHomeInterface';
import {
  IassessmentPackageResponses,
  IsessionPackageResponses,
  myCoachUploadsInterface,
} from '../coachInterfaces/myUploadInterface';
import {
  deleteDraftApi,
  fetchDrafts,
  getDraftById,
  getServices,
} from '../coachServices/coachHomeServices';
import { showAlert } from '../slice/alert';
import {
  setAssessmentFormData,
  setAssessmentImageFileFormData,
  setCoachingPriceAssessmentFormData,
  setIntendedClientAssessmentFormData,
  setThirdPartyAssessmentFormData,
  setThirdPartyFileIds,
} from './assessmentSlice';
import {
  downloadImage,
  fetchAssessmentTitles,
  fetchSessionTitles,
  listPackageDuration,
  setAssessmentPackageFormData,
  setCoachingPricePackageFormData,
  setIntendedClientPackageFormData,
  setPackageFormData,
  setPackageImageFileFormData,
} from './packageSlice';
import {
  listCoachingTiming,
  listHour,
  listMonths,
  setCoachingHoursFormData,
  setCoachingPriceFormData,
  setIntendedClientFormData,
  setSessionFormData,
  setSessionImageFileFormData,
} from './sessionSlice';

const initialState: coachHomeInterface = {
  drafts: [],
  isProcessingDrafts: false,
  isProcessingfetchSessionDraftById: false,
  isProcessingfetchPackageDraftById: false,
  isProcessingfetchAssessmentDraftById: false,
  serviceCount: 0,
};

export const coachHomeSlice = createSlice({
  name: 'coachHomeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountOfService.fulfilled, (state, action) => {
        state.serviceCount = action.payload.data[0].count;
      })
      .addCase(fetchSessionDraftById.pending, (state) => {
        state.isProcessingfetchSessionDraftById = true;
      })
      .addCase(fetchSessionDraftById.fulfilled, (state, action) => {
        state.isProcessingfetchSessionDraftById = false;
      })
      .addCase(fetchSessionDraftById.rejected, (state) => {
        state.isProcessingfetchSessionDraftById = false;
      })
      .addCase(fetchPackageDraftById.pending, (state) => {
        state.isProcessingfetchPackageDraftById = true;
      })
      .addCase(fetchPackageDraftById.fulfilled, (state, action) => {
        state.isProcessingfetchPackageDraftById = false;
      })
      .addCase(fetchPackageDraftById.rejected, (state) => {
        state.isProcessingfetchPackageDraftById = false;
      })

      .addCase(fetchAssessmentDraftById.pending, (state) => {
        state.isProcessingfetchAssessmentDraftById = true;
      })
      .addCase(fetchAssessmentDraftById.fulfilled, (state, action) => {
        state.isProcessingfetchAssessmentDraftById = false;
      })
      .addCase(fetchAssessmentDraftById.rejected, (state) => {
        state.isProcessingfetchAssessmentDraftById = false;
      })
      .addCase(listDrafts.pending, (state) => {
        state.isProcessingDrafts = true;
      })
      .addCase(listDrafts.fulfilled, (state, action) => {
        state.isProcessingDrafts = false;
        state.drafts = action.payload.data[0].content;
      })
      .addCase(listDrafts.rejected, (state) => {
        state.isProcessingDrafts = false;
      });
  },
});

export const listDrafts = createAsyncThunk(
  'list/drafts',
  async (id, thunkAPI) => {
    try {
      const response = await fetchDrafts();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteDraft = createAsyncThunk(
  'delete/drafts',
  async (id: string, thunkAPI) => {
    try {
      const response = await deleteDraftApi(id);
      thunkAPI.dispatch(listDrafts());
      // thunkAPI.dispatch(
      //   showAlert({
      //     type: 'success',
      //     message: response?.message,
      //   })
      // );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchDraftById = createAsyncThunk(
  'get/draft-by-id',
  async (id: string, thunkAPI) => {
    try {
      const response = await getDraftById(id);
      thunkAPI.dispatch(listDrafts());
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchCountOfService = createAsyncThunk(
  'get/fetchCountOfService',
  async (id, thunkAPI) => {
    try {
      const response = await getServices(
        decodeUserId(getAccessToken() as string)
      );

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchSessionDraftById = createAsyncThunk(
  'get/session-draft-by-id',
  async (id: string, thunkAPI) => {
    try {
      const response = await getDraftById(id);

      const data: myCoachUploadsInterface = response?.data[0]?.response;
      thunkAPI.dispatch(listLanguages());
      thunkAPI.dispatch(listMonths());
      thunkAPI.dispatch(listHour());
      thunkAPI.dispatch(listCoachingTiming());

      thunkAPI.dispatch(
        setSessionFormData({
          title: data?.title,
          subTitle: data?.subTitle,
          description: data?.description,
          duration: data?.duration,
          retakePeriod: data?.retakePeriod,
          language: data?.language,
          category: data?.category,

          categoryTags: data?.categoryTags?.map((item: string) => ({
            label: item,
            value: item,
          })),
        })
      );

      thunkAPI.dispatch(
        setIntendedClientFormData({
          gender: data?.intendedClientRequest?.gender,
          whatWillLearn: data?.intendedClientRequest?.whatWillLearn?.length
            ? data?.intendedClientRequest?.whatWillLearn
            : ['', '', ''],
          requirements: data?.intendedClientRequest?.requirements?.length
            ? data?.intendedClientRequest?.requirements
            : ['', '', ''],
          forWhom: getAssessmentForEditPayload(
            data?.intendedClientRequest?.forWhom as string[]
          ),
          benefits: data?.intendedClientRequest?.benefits?.length
            ? data?.intendedClientRequest?.benefits
            : ['', '', ''],
          tags: getTagsFromAssessmentEdit(
            data?.intendedClientRequest?.forWhom as string[]
          ),
        })
      );

      if (data?.coachingHourRequests?.length === 1) {
        let localData: any = {};
        const utcToLocalConversion = () => {
          Object.keys(
            data?.coachingHourRequests?.length
              ? data?.coachingHourRequests[0]
              : []
          )?.map((item) => {
            if (item === 'day') {
              localData[`${item}`] = UtcToLocaldayConversion(
                data?.coachingHourRequests?.length
                  ? data?.coachingHourRequests[0]?.day
                    ? data?.coachingHourRequests[0]?.day
                    : ''
                  : '',
                data?.coachingHourRequests?.length
                  ? data?.coachingHourRequests[0]?.startTime
                    ? data?.coachingHourRequests[0]?.startTime
                    : ''
                  : ''
              );
            } else if (item === 'startTime') {
              localData[`${item}`] = utcToLocal(
                data?.coachingHourRequests?.length
                  ? data?.coachingHourRequests[0]?.startTime
                    ? data?.coachingHourRequests[0]?.startTime
                    : ''
                  : ''
              );
            } else if (item === 'endTime') {
              localData[`${item}`] = utcToLocal(
                data?.coachingHourRequests?.length
                  ? data?.coachingHourRequests[0]?.endTime
                    ? data?.coachingHourRequests[0]?.endTime
                    : ''
                  : ''
              );
            }
          });
        };
        utcToLocalConversion();

        thunkAPI.dispatch(
          setCoachingHoursFormData({
            days: 'Sunday-Thursday',
            start: '00:00',
            end: '02:00',
            alternative: true,
            coachingHourRequests: convert24to12Item(
              data?.coachingHourRequests?.length && localData
            ),
          })
        );
      } else if (isAlternative(data?.coachingHourRequests as any[])) {
        let hoursData: any = {};
        const utcToLocalConversion = () => {
          const date: any = {
            'SUNDAY-THURSDAY': 'Sunday-Thursday',
            'MONDAY-FRIDAY': 'Monday-Friday',
          };

          Object.keys(getHoursData(data?.coachingHourRequests as any[])).map(
            (item) => {
              if (item === 'days') {
                hoursData[`${item}`] =
                  date[
                    utcToLocalDaySelection(
                      getHoursData(data?.coachingHourRequests as any[]).days,
                      getHoursData(data?.coachingHourRequests as any[]).start
                    )
                  ];
              } else if (item === 'start') {
                hoursData[`${item}`] = utcToLocal(
                  getHoursData(data?.coachingHourRequests as any[]).start
                );
              } else if (item === 'end') {
                hoursData[`${item}`] = utcToLocal(
                  getHoursData(data?.coachingHourRequests as any[]).end
                );
              }
            }
          );
        };
        utcToLocalConversion();

        thunkAPI.dispatch(
          setCoachingHoursFormData({
            days: hoursData.days,
            start: hoursData.start,
            end: hoursData.end,
            alternative: false,
            coachingHourRequests: weekDaysList.map(
              (item: { label: string; value: string }) => ({
                day: item.value,
                from: '12:00',
                localFromTime: 'AM',
                end: '02:00',
                locaEndTime: 'AM',
              })
            ),
          })
        );
      } else {
        let convertedData: any = [];
        const utcToLocalConversion = () => {
          data?.coachingHourRequests?.map((value) => {
            let localData: any = {};
            Object.keys(value)?.map((item) => {
              if (item === 'day') {
                localData[`${item}`] = UtcToLocaldayConversion(
                  value.day as string,
                  value.startTime as string
                );
              } else if (item === 'startTime') {
                localData[`${item}`] = utcToLocal(value.startTime as string);
              } else if (item === 'endTime') {
                localData[`${item}`] = utcToLocal(value.endTime as string);
              }
            });
            convertedData.push(localData);
          });
        };
        utcToLocalConversion();

        thunkAPI.dispatch(
          setCoachingHoursFormData({
            days: 'Sunday-Thursday',
            start: '00:00',
            end: '02:00',
            alternative: true,
            coachingHourRequests: convert24to12(
              data?.coachingHourRequests?.length ? convertedData : []
            ),
          })
        );
      }
      thunkAPI.dispatch(
        setCoachingPriceFormData({
          price: data?.price,
        })
      );
      const imageData = await thunkAPI.dispatch(
        downloadImage(data?.imgId as number)
      );
      if (imageData?.payload?.data?.length)
        thunkAPI.dispatch(
          setSessionImageFileFormData({
            url: `data:image/png;base64,${imageData?.payload?.data[0]?.fileContent}`,
            name: imageData?.payload?.data[0]?.originalFileName,
            file: '',
            fileId: data?.imgId,
          })
        );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const fetchPackageDraftById = createAsyncThunk(
  'get/package-draft-by-id',
  async (id: string, thunkAPI) => {
    try {
      const response = await getDraftById(id);

      const data: myCoachUploadsInterface = response?.data[0].response;
      thunkAPI.dispatch(fetchAssessmentTitles());
      thunkAPI.dispatch(fetchSessionTitles());
      thunkAPI.dispatch(listPackageDuration());
      thunkAPI.dispatch(
        setPackageFormData({
          title: data.title,
          subTitle: data.subTitle,
          description: data.description,
          // duration: data.duration,
          language: data.language,
          categoryTags: data.categoryTags?.map((item: string) => ({
            label: item,
            value: item,
          })),
        })
      );
      thunkAPI.dispatch(
        setIntendedClientPackageFormData({
          gender: data?.intendedClientRequest?.gender,
          whatWillLearn: data?.intendedClientRequest?.whatWillLearn?.length
            ? data?.intendedClientRequest?.whatWillLearn
            : ['', '', ''],
          requirements: data?.intendedClientRequest?.requirements?.length
            ? data?.intendedClientRequest?.requirements
            : ['', '', ''],
          forWhom: getAssessmentForEditPayload(
            data?.intendedClientRequest?.forWhom as string[]
          ),
          benefits: data?.intendedClientRequest?.benefits?.length
            ? data?.intendedClientRequest?.benefits
            : ['', '', ''],
          tags: getTagsFromAssessmentEdit(
            data?.intendedClientRequest?.forWhom as string[]
          ),
        })
      );

      // if (data?.coachingHourRequests?.length === 1) {
      //   thunkAPI.dispatch(
      //     setCoachingHoursPackageFormData({
      //       days: 'Sunday-Thursday',
      //       start: '00:00',
      //       end: '02:00',
      //       alternative: true,
      //       coachingHourRequests: convert24to12Item(
      //         data?.coachingHourRequests?.length &&
      //           (data?.coachingHourRequests[0] as any)
      //       ),
      //     })
      //   );
      // } else if (isAlternative(data?.coachingHourRequests as any)) {
      //   const hoursData = getHoursData(data?.coachingHourRequests as any);
      //   thunkAPI.dispatch(
      //     setCoachingHoursPackageFormData({
      //       days: hoursData.days,
      //       start: hoursData.start,
      //       end: hoursData.end,
      //       alternative: false,
      //       coachingHourRequests: weekDaysList.map(
      //         (item: { label: string; value: string }) => ({
      //           day: item.value,
      //           from: '12:00',
      //           localFromTime: 'AM',
      //           end: '02:00',
      //           locaEndTime: 'AM',
      //         })
      //       ),
      //     })
      //   );
      // } else {
      //   thunkAPI.dispatch(
      //     setCoachingHoursPackageFormData({
      //       days: 'Sunday-Thursday',
      //       start: '00:00',
      //       end: '02:00',
      //       alternative: true,
      //       coachingHourRequests: convert24to12(
      //         data?.coachingHourRequests as any
      //       ).map((item: coachingHourPackageRequestsInterface) => {
      //         return {
      //           day: item.day,
      //           from: item.from,
      //           localFromTime: item.localFromTime,
      //           end: item.end,
      //           locaEndTime: item.locaEndTime,
      //         };
      //       }),
      //     })
      //   );
      // }
      thunkAPI.dispatch(
        setCoachingPricePackageFormData({
          price: data?.price === 0 ? '' : data?.price,
        })
      );

      thunkAPI.dispatch(
        setAssessmentPackageFormData({
          // assessmentId:
          //   data?.assessmentAndSessionRequest?.assessmentPackageRequests[0]
          //     ?.assessmentId,
          // durationInWeek:
          //   data?.assessmentAndSessionRequest?.assessmentPackageRequests[0]
          //     ?.durationInWeek,
          // sessionId:
          //   data?.assessmentAndSessionRequest?.sessionPackageRequests[0]
          //     ?.sessionId,
          // durationInDays:
          //   data?.assessmentAndSessionRequest?.sessionPackageRequests[0]
          //     ?.durationInDays,
          // durationInHr:
          //   data?.assessmentAndSessionRequest?.sessionPackageRequests[0]
          //     ?.durationInHr,
          assessmentValue: getAssessmentValuePayload(
            data?.assessmentAndSessionRequest
              ?.assessmentPackageRequests as IassessmentPackageResponses[],
            data?.assessmentAndSessionRequest
              ?.sessionPackageRequests as IsessionPackageResponses[]
          ),
        })
      );

      const imageData = await thunkAPI.dispatch(
        downloadImage(data?.imgId as number)
      );
      if (imageData?.payload?.data?.length)
        thunkAPI.dispatch(
          setPackageImageFileFormData({
            url: `data:image/png;base64,${imageData?.payload?.data[0]?.fileContent}`,
            name: imageData?.payload?.data[0]?.originalFileName,
            file: '',
            fileId: data?.imgId,
          })
        );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAssessmentDraftById = createAsyncThunk(
  'get/assessment-draft-by-id',
  async (id: string, thunkAPI) => {
    try {
      const response = await getDraftById(id);

      const data: myCoachUploadsInterface = response?.data[0].response;

      thunkAPI.dispatch(listLanguages());
      thunkAPI.dispatch(listCoachingTiming());
      thunkAPI.dispatch(
        setAssessmentFormData({
          title: data.title,
          subTitle: data.subTitle,
          description: data.description,
          // deliveryDuration: data.deliveryDuration,
          language: data.language,
          category: data.category,
          categoryTags: data.categoryTags?.length
            ? data.categoryTags?.map((item: string) => ({
                label: item,
                value: item,
              }))
            : [],
        })
      );
      thunkAPI.dispatch(
        setIntendedClientAssessmentFormData({
          gender: data?.intendedClientRequest?.gender,
          whatWillLearn: data?.intendedClientRequest?.whatWillLearn?.length
            ? data?.intendedClientRequest?.whatWillLearn
            : ['', '', ''],
          requirements: data?.intendedClientRequest?.requirements?.length
            ? data?.intendedClientRequest?.requirements
            : ['', '', ''],
          forWhom: getAssessmentForEditPayload(
            data?.intendedClientRequest?.forWhom as string[]
          ),
          benefits: data?.intendedClientRequest?.benefits?.length
            ? data?.intendedClientRequest?.benefits
            : ['', '', ''],
          tags: getTagsFromAssessmentEdit(
            data?.intendedClientRequest?.forWhom as string[]
          ),
        })
      );
      thunkAPI.dispatch(
        setThirdPartyAssessmentFormData({
          title: data?.thirdPartyProviderRequest?.title,
          provider: data?.thirdPartyProviderRequest?.provider,
          deliveryMethod: data?.thirdPartyProviderRequest?.deliveryMethod,
          thirdParty: thirdPartyEditPayloadCreator(
            data?.thirdPartyProviderRequest?.fileIds as number[],
            data?.thirdPartyProviderRequest?.termsOfServiceUrl as string[]
          ),
        })
      );
      thunkAPI.dispatch(
        setThirdPartyFileIds(data?.thirdPartyProviderRequest?.fileIds)
      );

      thunkAPI.dispatch(
        setCoachingPriceAssessmentFormData({
          price: data?.price,
        })
      );
      const imageData = await thunkAPI.dispatch(
        downloadImage(data?.imageId as number)
      );
      if (imageData?.payload?.data?.length)
        thunkAPI.dispatch(
          setAssessmentImageFileFormData({
            url: `data:image/png;base64,${imageData?.payload?.data[0]?.fileContent}`,
            name: imageData?.payload?.data[0]?.originalFileName,
            file: '',
            fileId: data?.imageId,
          })
        );
      // payload.navigate(`/coach/coach-assessment?form=EDIT&id=${data?.id}`);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const coachHomeInfo = (state: any) => state.coachHomeInfo;
export const coachHomeInfoReducer = coachHomeSlice.reducer;

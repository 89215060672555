import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  coachRelatedTags,
  countCount,
  countServices,
  getRelatedTags,
  listCategory,
  searchServiceList,
  selectClientHome,
  serviceList,
  setClearToggle,
} from '../../clientSlices/home';
import CoachList from '../../components/coachProfile';
import CustomRangeInput from '../../components/rangeInput';
import SearchWithDropdown from '../../components/searchWithDropdown';
import { useAppSelector } from '../../hooks';
// import AdvancedMultiDropdown from '../advancedDropDown';

import MultiDropdown from '../multiDropDown';
import Pagination from '../pagination';

import { SearchContent } from '../searchContent';
import { SearchListViewContent } from '../searchListView';
import SelectDropdown from '../selectDropdown';
import SortDropdown from '../sortDropDown';

import { getDefaultValueArabic } from '../../clientHelpers/utility';
import './index.css';

export interface IFilter {
  filter: (data: boolean) => void;
  filterView?: boolean;
  active?: boolean;
}

export const BodySearchView: FC<IFilter> = ({
  filter,
  filterView,
  active,
}): JSX.Element => {
  const [gridView, setGridView] = useState(false);
  const [t] = useTranslation('common');
  const [value, setValue] = useState('');

  const sortValues = [
    {
      label: 'Most Relevant',
      value: 'MOST_RELEVANT',
      label2: 'الأكثر صلة',
    },
    {
      label: 'Most Popular',
      value: 'MOST_POPULAR',
      label2: 'الأكثر شعبية',
    },
  ];

  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    serachResults,
    serviceAllList,
    countResponses,
    totalResults,
    relatedTags,
    isSearchResults,
    isCleared,
    isServiceAllList,
  } = useSelector(selectClientHome);
  const [maxPrice, setMaxPrice] = useState(
    searchParams.get('maximum') ? searchParams.get('maximum') : 0
  );
  useEffect(() => {
    if (!serviceType) {
      const pricesSessions = serachResults?.sessions?.map(
        (product: any) => product.price
      );
      const pricesAssessments = serachResults?.assessments?.map(
        (product: any) => product.price
      );
      const pricesPackages = serachResults?.packages?.map(
        (product: any) => product.price
      );
      let prices;
      if (pricesSessions && pricesAssessments && pricesPackages) {
        prices = [...pricesSessions, ...pricesAssessments, ...pricesPackages];
      }
      if (prices) {
        const highestPrice = Math.max(...prices);

        console.log(highestPrice);
        if (Number(highestPrice) > Number(maxPrice)) setMaxPrice(highestPrice);
      }
    } else {
      const pricesService = serviceAllList?.content?.map(
        (product: any) => product.price
      );
      // Find the highest price using Math.max
      if (pricesService) {
        const highestPrice = Math.max(...pricesService);
        console.log(highestPrice);
        const temp: { [key: string]: string } = {};
        searchParams.forEach((value, key) => {
          temp[key] = value;
        });
        if (Number(highestPrice) > Number(maxPrice)) {
          console.log('hello');
          temp['maximum'] = String(highestPrice);
        } else {
          console.log('hello');
          temp['maximum'] = String(maxPrice);
        }
        setSearchParams((value: any) => (value = temp));
        if (Number(highestPrice) > Number(maxPrice)) setMaxPrice(highestPrice);
      }
    }
  }, [serachResults, serviceAllList]);
  const accordionData = [
    {
      id: 3,
      title: t('SEARCH_RESULT_VIEW.CLIENTS_GENDER'),
      content: [
        {
          id: 1,
          name: t('MALE'),
          number: countResponses?.maleFilteringCount,
          filterName: 'gender',
          value: 'MALE'
        },
        {
          id: 1,
          name: t('FEMALE'),
          number: countResponses?.femaleFilteringCount,
          filterName: 'gender',
          value: 'FEMALE'
        },
      ],
    },
    {
      id: 4,
      title: t('SEARCH_RESULT_VIEW.LANGUAGES'),
      content: [
        {
          id: 1,
          name: t('SEARCH_RESULT_VIEW.ARABIC'),
          number: countResponses?.arabicLanguageFilteringCount,
          filterName: 'language',
          value: 'ARABIC'
        },
        {
          id: 1,
          name: t('SEARCH_RESULT_VIEW.ENGLISH'),
          number: countResponses?.englishLanguageFilteringCount,
          filterName: 'language',
          value: 'ENGLISH'
        },
      ],
    },
    // {
    //   id: 5,

    //   title: t('SEARCH_RESULT_VIEW.RATINGS'),

    //   content: [
    //     {
    //       id: 1,
    //       name: '4 & above',
    //       number: countResponses?.ratingFourStarAboveCount,
    //       filterName: 'ratings',
    //     },
    //     {
    //       id: 1,
    //       name: '3 & above',
    //       number: countResponses?.ratingThreeStarAboveCount,
    //       filterName: 'ratings',
    //     },
    //     {
    //       id: 1,
    //       name: '2 & above',
    //       number: countResponses?.ratingTwoStarAboveCount,
    //       filterName: 'ratings',
    //     },
    //   ],
    // },
  ];

  const { lang } = useAppSelector((state) => state.language);
  const [price, setPrice] = useState(
    searchParams.get('finalPrice') ? searchParams.get('finalPrice') : 0
  );

  const [categoryValue, setCategoryValue] = useState<any>(
    searchParams.get('category')
  );
  const [popular, setPopularValue] = useState<any>();
  useEffect(() => {
    !searchParams.get('finalPrice') && setPrice(0);
  }, [searchParams.get('finalPrice'), isCleared]);
  let categoryListEn: { label: ''; value: ''; label2: '' }[] = [];
  const { categories, serviceView } = useSelector(selectClientHome);
  categories.map((item: any, index: number) => {
    categoryListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
      label2: item.nameInArabic,
    });
  });

  const [search, setSearch] = useState(searchParams.get('keyword'));
  const navigate = useNavigate();
  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) {
      const temp: { [key: string]: string } = {};
      searchParams.forEach((value, key) => {
        temp[key] = value;
      });
      temp['keyword'] = search;
      if (categoryValue) temp['category'] = categoryValue;
      else delete temp['category'];
      setSearchParams((value: any) => (value = temp));
    }
  };
  const handleListAll = (val: string) => {
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    temp['page'] = '0';
    temp['size'] = '9';
    temp['serviceType'] = val;
    setServiceType((value) => (value = val));
    setSearchParams((value: any) => (value = temp));

    // if (search) temp['keyword'] = search;
    // if (categoryValue) temp['category'] = categoryValue;
    // if (val) temp['serviceType'] = val.toUpperCase().slice(0, -1);

    // dispatch(setServiceType(val));
    // dispatch(setServiceView(false));
  };
  useEffect(() => {
    if (!categories.length) dispatch(listCategory());
  }, []);
  const [serviceType, setServiceType] = useState(
    searchParams.get('serviceType')
  );
  useEffect(() => {
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    if (search) temp['keyword'] = search;
    else delete temp['keyword'];
    if (!categoryValue) delete temp['category'];
    else temp['category'] = categoryValue;
    if (serviceType) {
      // navigate(`/client/search-result`);

      temp['serviceType'] = serviceType;
      if (popular) {
        temp['sort'] =
          popular === 'MOST_RELEVANT' ? 'client_count,desc' : 'rating,desc';
      } else delete temp['sortType'];

      if (serviceType.toLocaleLowerCase() === 'coaches')
        temp['baseFilter'] = 'ACTIVE';
      setSearchParams((value: any) => (value = temp));
      setSelectedOption(
        (value) => (value = serviceType.toUpperCase() as string)
      );
      dispatch(
        serviceList({
          service: String(serviceType).toLocaleLowerCase(),
          temp,
        })
      );
      if (serviceType.toLocaleLowerCase() === 'coaches') {
        temp['serviceType'] = serviceType.toLocaleUpperCase();
        dispatch(countCount());
        dispatch(coachRelatedTags(temp));
      } else {
        temp['serviceType'] = serviceType.toLocaleUpperCase().slice(0, -1);

        dispatch(getRelatedTags(temp));
        dispatch(
          countServices({
            serviceType: serviceType.slice(0, -1).toLocaleUpperCase(),
            data: temp,
          })
        );
      }
    } else {
      if (popular) {
        temp['sortType'] = popular;
      } else delete temp['sort'];
      delete temp['serviceType'];

      setSearchParams((value: any) => (value = temp));
      dispatch(searchServiceList(searchParams));
    }
  }, [categoryValue, serviceType, popular, searchParams]);
  useEffect(() => {
    setSearch((value) => (value = searchParams.get('keyword')));
  }, [searchParams.get('keyword')]);
  const handleFilter = () => {
    let temp: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });

    temp['initialPrice'] = 0;
    temp['maximum'] = maxPrice;
    if (price) temp['finalPrice'] = price;
    if (coachingCategoryInfo.length)
      temp['coachingCategory'] = coachingCategoryInfo.toString();
    else delete temp['coachingCategory'];
    temp = { ...temp, ...filterValue };
    // dispatch(searchServiceList(searchParams));
    setSearchParams(temp);
  };
  // const [serviceView, setServiceView] = useState(true);
  const [coachingCategoryInfo, setCoachingCategoryInfo] = useState<string[]>(
    []
  );
  const [filterValue, setFilterValue] = useState<any>();
  const [selectedOption, setSelectedOption] = useState('Sessions');
  const handleCategory = (event: any) => {
    setCategoryValue((value: any) => (value = event.value));
    let temp: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    temp['category'] = event.value;
    setSearchParams((value: any) => (value = temp));
  };
  const handleRelatedTags = (value: any) => {
    let temp: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    if (searchParams.get('coachingCategory') === value) {
      delete temp['coachingCategory'];
    } else {
      temp['coachingCategory'] = value;
    }
    setSearchParams((value: any) => (value = temp));
  };
  const handleMostPopular = (event: any) => {
    setPopularValue((value: any) => (value = event.value));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const clearFilter = () => {
    setSearch('');
    setSearchParams({});
    setFilterValue(null);
    setCoachingCategoryInfo([]);
    dispatch(setClearToggle(true));
  };
  const [iserveLoading, setIseferLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIseferLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
  return !iserveLoading ? (
    <>
      <div className="flex flex-col gap-16 md:gap-5">
        <div className="flex h-[46px] flex-col md:flex-row mx-5 smMax:mx-[100px] gap-5 md:mt-10 mt-6">
          <div className="flex md:w-[24%] w-full z-[2]">
            <SelectDropdown
              className={`font-general rtl:font-Almarai w-full text-var(--secondary)`}
              id="FormLanguage"
              name="languages"
              placeholder={t('LANDING_PAGE_AFTER_LOGIN.CATEGORY')}
              labelClass={searchParams.get('category') ? 'hidden' : ''}
              optional={1}
              defaultValue={{
                label: searchParams.get('category'),
                value: searchParams.get('category'),
                label2: getDefaultValueArabic(
                  searchParams.get('category'),
                  categoryListEn
                ),
              }}
              onChange={(event: { label: string; value: string }) =>
                handleCategory(event)
              }
              getOptionLabel={(option: any) =>
                lang === 'en' ? option.label : option.label2
              }
              isSearchable={false}
              options={categoryListEn}
              grayOutline={true}
            />
          </div>
          <div className="w-full h-[46px]">
            {!serviceType ? (
              <SearchService
                search={search}
                onHandleSearch={onHandleSearch}
                setSearchParams={setSearchParams}
                setSearch={setSearch}
                t={t}
              />
            ) : (
              <SearchWithDropdown
                search={search}
                onHandleSearch={onHandleSearch}
                setSearchParams={setSearchParams}
                setSearch={setSearch}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                searchParams={searchParams}
                setServiceType={setServiceType}
              />
            )}
          </div>
        </div>

        <div className=" flex lg:flex-row flex-col justify-between rtl:smMax:mr-[75px] rtl:smMax:ml-[100px] ltr:mr-5 ltr:ml-5 ltr:smMax:mr-[100px] ltr:smMax:ml-[100px] border-b-[1px] border-lightBlack pb-8">
          <div className="font-bold text-[22px] pt-2 md:pt-0 smMax:text-[34px] text-darkGrey">
            {searchParams.get('keyword') ? (
              <>
                {t('SEARCH_RESULT_VIEW.SEARCH_RESULT')} "
                {searchParams.get('keyword')}"
              </>
            ) : null}
          </div>
          <div className="font-bold text-xl text-greyText flex items-end">
            {totalResults} {t('SEARCH_RESULT_VIEW.RESULTS')}{' '}
          </div>
        </div>
      </div>

      {relatedTags.length ? (
        <div className="flex flex-col ltr:mr-5 ltr:smMax:mr-[100px] ltr:ml-5 ltr:smMax:ml-[100px] rtl:smMax:mr-[100px] rtl:smMax:ml-[100px]   mt-5 border-b-[1px] border-lightBlack pb-8">
          <div className="text-darkGrey font-bold text-xl pb-10  rtl:font-Almarai">
            {t('SEARCH_RESULT_VIEW.RELATED_TAGS')}
          </div>
          <div className="flex gap-5 overflow-x-auto form-scroll">
            {relatedTags?.map((item: any, index: any) => (
              <div
                onClick={() => handleRelatedTags(item)}
                key={`tag-related-${item.id}${index}`}
                className={`flex flex-row border-[1px] p-2 h-[50%] rounded-md  cursor-pointer  ${
                  searchParams.get('coachingCategory') === item
                    ? 'border-primary text-white bg-primary'
                    : 'border-lightBlack text-greyGrey'
                } `}
              >
                <div className="">{item}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="flex flex-row smMax:mx-[100px] mx-5 pt-4">
        <div className="flex w-full  gap-2  ">
          <div
            className="  px-3 flex gap-1 items-center border-[1px] h-[64px] sm:h-14 rounded min-w-[90px] border-borderColor rtl:font-Almarai lg:hidden cursor-pointer"
            onClick={() => {
              filter(true);
            }}
          >
            <img
              src="/assets/gridView/filterIcon.svg"
              className=" w-fit"
              alt=""
            />
            <div>{t('SEARCH_RESULT_VIEW.FILTER')}</div>
          </div>
          <div className="lg:hidden block z-10 ">
            {filterView ? (
              <AdvancedMultiDropdown
                filter={filter}
                countResponses={countResponses}
                serviceType={serviceType}
                price={price}
                setPrice={setPrice}
                isCleared={isCleared}
                coachingCategoryInfo={coachingCategoryInfo}
                setCoachingCategoryInfo={setCoachingCategoryInfo}
                accordionData={accordionData}
                handleFilter={handleFilter}
                clearFilter={clearFilter}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                t={t}
              />
            ) : null}
          </div>
          <div className="md:w-[300px]  flex  w-full">
            <SortDropdown
              options={sortValues}
              handleMostPopular={handleMostPopular}
              value={value}
              onChange={(e: any) => {
                setValue(e);
              }}
              className={'!h-52px !w-100px '}
            />
          </div>
        </div>
        {serviceType !== 'COACHES' ? (
          <div className=" gap-6 cursor-pointer hidden lg:flex">
            <div>
              <img
                className="w-[26px]"
                src={
                  gridView
                    ? '/assets/gridView/listView.svg'
                    : '/assets/gridView/gridViewIcon-2.svg'
                }
                onClick={() => {
                  setGridView(true);
                }}
                alt=""
              ></img>
            </div>
            <div>
              <img
                className="w-[26px]"
                src={
                  gridView
                    ? '/assets/gridView/gridViewInactive.svg'
                    : '/assets/gridView/griviewIcon.svg'
                }
                onClick={() => {
                  setGridView(false);
                }}
                alt=""
              ></img>
            </div>
          </div>
        ) : null}
      </div>
      <div className="  w-full flex flex-row smMax:px-[100px] px-5 justify-center    gap-10">
        <div className="w-[24%] hidden  lg:block pb-10">
          <div className="bg-white border-[1px] border-borderColor rounded-lg shadow-lg  ">
            <div className="p-5 font-bold text-[22px] text-darkGrey rtl:font-Almarai">
              {t('SEARCH_RESULT_VIEW.ADVANCED_FILTER')}
            </div>
            {/* <PriceRangeslider min={0} max={100} /> */}
            {serviceType?.toLocaleLowerCase() !== 'coaches' ? (
              <PriceRange
                price={price}
                setPrice={setPrice}
                clearFieldToggle={isCleared}
                maxPrice={maxPrice}
              />
            ) : null}
            <CoachingCategoryList
              data={countResponses?.countOfCategoryTagFilterings}
              coachingCategoryInfo={coachingCategoryInfo}
              setCoachingCategoryInfo={setCoachingCategoryInfo}
              clearFieldToggle={isCleared}
              clearFieldFunction={setClearToggle}
            />
            {accordionData.map((item, index) => (
              <MultiDropdown
                title={item.title}
                content={item.content}
                key={`select-datas-${index}`}
                // checked={checked}
                // setCheckBoxChecked={setCheckBoxChecked}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                clearFieldToggle={isCleared}
                // onChangeAttributeFilter={onChangeAttributeFilter}
              />
            ))}
            <hr className="border-line" />
            <div className="flex justify-center p-4 gap-2">
              <button
                onClick={handleFilter}
                className=" bg-lightBlue text-white rounded-md p-2 h-8 flex items-center font-semibold rtl:font-Almarai"
              >
                {t('SEARCH_RESULT_VIEW.DONE')}
              </button>
              <button
                onClick={clearFilter}
                className=" bg-lightBlue text-white rounded-md p-2 h-8 flex items-center font-semibold rtl:font-Almarai"
              >
                {t('SEARCH_RESULT_VIEW.CLEAR')}
              </button>
            </div>
          </div>
        </div>
        <div className={`lg:w-[76%] w-full `}>
          {!serviceType ? (
            <>
              {serachResults.sessions?.length ? (
                gridView ? (
                  <SearchListViewContent
                    serviceType="Sessions"
                    data={serachResults.sessions}
                    handleListAll={() => handleListAll('Sessions')}
                    showAll={true}
                  />
                ) : (
                  <SearchContent
                    serviceType="Sessions"
                    data={serachResults.sessions}
                    handleListAll={() => handleListAll('Sessions')}
                    showAll={true}
                    isLoading={isSearchResults}
                  />
                )
              ) : null}
              {serachResults.packages?.length ? (
                gridView ? (
                  <SearchListViewContent
                    serviceType="Packages"
                    data={serachResults.packages}
                    handleListAll={() => handleListAll('Packages')}
                    showAll={true}
                  />
                ) : (
                  <SearchContent
                    serviceType="Packages"
                    data={serachResults.packages}
                    handleListAll={() => handleListAll('Packages')}
                    showAll={true}
                    isLoading={isSearchResults}
                  />
                )
              ) : null}

              {serachResults.assessments?.length ? (
                gridView ? (
                  <SearchListViewContent
                    serviceType="Assessments"
                    data={serachResults.assessments}
                    handleListAll={() => handleListAll('Assessments')}
                    showAll={true}
                  />
                ) : (
                  <SearchContent
                    serviceType="Assessments"
                    data={serachResults.assessments}
                    handleListAll={() => handleListAll('Assessments')}
                    showAll={true}
                    isLoading={isSearchResults}
                  />
                )
              ) : null}
              {serachResults.coaches?.length ? (
                <CoachList
                  data={serachResults.coaches}
                  serviceType="Coaches"
                  handleListAll={() => handleListAll('Coaches')}
                  showAll={true}
                  loading={isSearchResults}
                />
              ) : null}
            </>
          ) : (
            <ListAllData
              gridView={gridView}
              serviceAllList={serviceAllList?.content}
              serviceType={serviceType}
              isloading={isServiceAllList}
            />
          )}

          {/* {isSearchResults || isServiceAllList ? (
            <div className=" w-full h-full flex justify-center items-center">
              <div className="loader "></div>
            </div>
          ) : serachResults.coaches?.length ||
            serachResults.assessments?.length ||
            serachResults.packages?.length ||
            serachResults.sessions?.length ||
            serviceAllList?.content?.length ? null : (
            <div className={`flex justify-center mb-6 `}>
              <NoData />
            </div>
          )} */}
          {relatedTags?.length ? (
            <div className="flex flex-col   mt-5   pb-8">
              <div className="text-darkGrey font-bold text-xl pb-10 rtl:font-Almarai">
                {t('SEARCH_RESULT_VIEW.RELATED_TAGS')}
              </div>
              <div className="flex gap-5 overflow-x-auto  form-scroll">
                {relatedTags?.map((item: any, index: any) => (
                  <div
                    onClick={() => handleRelatedTags(item)}
                    key={`tag-related-${item.id}${index}`}
                    className={`flex flex-row border-[1px] p-2 h-[50%] rounded-md  cursor-pointer  ${
                      searchParams.get('coachingCategory') === item
                        ? 'border-primary text-white bg-primary'
                        : 'border-lightBlack text-greyGrey'
                    } `}
                  >
                    <div className="">{item}</div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {/* {relatedTags && relatedTags?.length ? null : (
            <div className="flex justify-center mb-6">NO DATA</div>
          )} */}
        </div>
      </div>

      <div>
        {serviceType && serviceAllList?.totalPages && (
          <Pagination
            totalPagesNumber={serviceAllList?.totalPages}
            currentPage={serviceAllList?.page}
            isLoading={false}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )}
      </div>
    </>
  ) : (
    <div className="flex justify-center h-[60vh] items-center">
      <div className="loader" />
    </div>
  );
};
const ListAllData = ({
  gridView,
  serviceAllList,
  serviceType,
  isloading,
}: any) => {
  return (
    <div>
      {serviceType.toLowerCase() === 'coaches' ? (
        <CoachList
          data={serviceAllList}
          serviceType="Coaches"
          showAll={false}
          loading={isloading}
        />
      ) : (
        <>
          {gridView ? (
            <SearchListViewContent
              serviceType={serviceType}
              data={serviceAllList}
              showAll={false}
            />
          ) : (
            <SearchContent
              serviceType={serviceType}
              data={serviceAllList}
              showAll={false}
            />
          )}
        </>
      )}
    </div>
  );
};

const SearchService = ({
  search,
  onHandleSearch,
  setSearch,
  setSearchParams,
  t,
}: any) => {
  return (
    <div className="w-full h-full  ">
      <form className="h-full">
        <div className=" my-upload-search-box w-full h-full">
          <div className="w-fit pl-2 rtl:pr-2 h-full flex items-center justify-center">
            <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
              <img
                src="/assets/gridView/searchIcon.svg"
                id={search as string}
                alt=""
              />
            </button>
          </div>
          <input
            type="text"
            id="simple-search"
            className="text-gray-900 text-sm h-full search-input  w-[93%] flex-grow rtl:font-Almarai"
            placeholder={t('MY_UPLOADS.SEARCH_TXT')}
            required
            onChange={(e: any) => {
              setSearch((value: any) => (value = e.target.value));
              // setSearchParams({ keyword: e.target.value });
            }}
            value={search as string}
          />
          {search && (
            <img
              onClick={() => {
                setSearchParams();
                setSearch('');
              }}
              className="cursor-pointer w-fit pr-2 rtl:pl-2"
              src="/assets/subscriptionPage/plusIcon.svg"
              alt="bell-icon"
            />
          )}
        </div>
      </form>
    </div>
  );
};
const PriceRange = ({ price, setPrice, maxPrice }: any) => {
  const [trigger, settrigger] = useState(false);
  const { t } = useTranslation('common');
  return (
    <div className="accordion-item border-t-[1px] border-borderColor ">
      <div className="accordion-title " onClick={() => settrigger(!trigger)}>
        <div className="font-bold text-[18px] text-darkGrey rtl:font-Almarai">
          {t(`SEARCH_RESULT_VIEW.PRICE`)}
        </div>
        <div className="flex items-center">
          {trigger ? '^' : <img src="/assets/gridView/downArrow.svg" alt="" />}
        </div>
      </div>
      {trigger && (
        <CustomRangeInput value={price} setValue={setPrice} max={maxPrice} />
      )}
    </div>
  );
};

const CoachingCategoryList = ({
  data,
  coachingCategoryInfo,
  setCoachingCategoryInfo,
  clearFieldToggle,
  clearFieldFunction,
}: any) => {
  const [trigger, settrigger] = useState(false);
  const { t } = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="accordion-item border-t-[1px] border-borderColor ">
      <div className="accordion-title " onClick={() => settrigger(!trigger)}>
        <div className="font-bold text-[18px] text-darkGrey rtl:font-Almarai">
          {t(`SEARCH_RESULT_VIEW.COACHING_CATEGORY`)}
        </div>
        <div className="flex items-center">
          {trigger ? '^' : <img src="/assets/gridView/downArrow.svg" alt="" />}
        </div>
      </div>
      {trigger &&
        data?.map((item: any, index: number) => (
          <div
            className="p-1 font-normal text-[17px] text-darkGrey flex items-center rtl:font-Almarai"
            key={`coaching-category-${index}`}
          >
            <input
              onChange={(e) => {
                // add to list
                if (e.target.checked) {
                  setCoachingCategoryInfo([
                    ...coachingCategoryInfo,
                    item.categoryTag,
                  ]);
                } else {
                  // remove from list
                  setCoachingCategoryInfo(
                    coachingCategoryInfo.filter(
                      (people: any) => people !== item.categoryTag
                    )
                  );
                }
              }}
              checked={
                coachingCategoryInfo.includes(item?.categoryTag) ||
                item.categoryTag === searchParams.get('coachingCategory')
              }
              value={coachingCategoryInfo}
              style={{ margin: '20px' }}
              type="checkbox"
            />
            <span className=" line-clamp-3">{item.categoryTag} </span>(
            <span>{item.count}</span>)
          </div>
        ))}
    </div>
  );
};

const AdvancedMultiDropdown = ({
  filter,
  serviceType,
  price,
  setPrice,
  isCleared,
  countResponses,
  coachingCategoryInfo,
  setCoachingCategoryInfo,
  accordionData,
  handleFilter,
  clearFilter,
  filterValue,
  setFilterValue,
  t,
}: any) => {
  return (
    <>
      <div className="absolute bg-white top-[39px] ltr:right-0 rtl:left-0 rounded-lg ">
        <div
          className="flex justify-end cursor-pointer pr-5  pl-5"
          onClick={() => {
            filter && filter(false);
          }}
        >
          <img src=" /assets/headerAfterLogin/close.svg" alt=""></img>
        </div>
        <div className="shadow ">
          <div className="p-5 mt-9 font-bold text-20 text-darkGrey  shadow-none rtl:font-Almarai">
            {' '}
            {t('SEARCH_RESULT_VIEW.ADVANCED_FILTER')}
          </div>
          {serviceType?.toLocaleLowerCase() !== 'coaches' ? (
            <PriceRange
              price={price}
              setPrice={setPrice}
              clearFieldToggle={isCleared}
            />
          ) : null}
          <CoachingCategoryList
            data={countResponses?.countOfCategoryTagFilterings}
            coachingCategoryInfo={coachingCategoryInfo}
            setCoachingCategoryInfo={setCoachingCategoryInfo}
            clearFieldToggle={isCleared}
            clearFieldFunction={setClearToggle}
          />
          {accordionData.map((item: any, index: number) => (
            <MultiDropdown
              title={item.title}
              content={item.content}
              key={`select-datas-${index}`}
              // checked={checked}
              // setCheckBoxChecked={setCheckBoxChecked}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              clearFieldToggle={isCleared}
              // onChangeAttributeFilter={onChangeAttributeFilter}
            />
          ))}
          {/* <div className="flex justify-center">
            <button className="bg-primary text-white w-[90%] h-[50px] mb-5 rounded-md">
              Done
            </button>
          </div> */}
          <hr className="border-line" />
          <div className="flex justify-center p-4 gap-2">
            <button
              onClick={handleFilter}
              className=" bg-lightBlue text-white rounded-md p-2 h-8 flex items-center font-semibold rtl:font-Almarai"
            >
              {t('SEARCH_RESULT_VIEW.DONE')}
            </button>
            <button
              onClick={clearFilter}
              className=" bg-lightBlue text-white rounded-md p-2 h-8 flex items-center font-semibold rtl:font-Almarai"
            >
              {t('SEARCH_RESULT_VIEW.CLEAR')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

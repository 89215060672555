import { FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import Date from '../../clientComponents/date';
import Input from '../../clientComponents/inputs';
import { registrationConst } from '../../coachConstants';
import { educationalValidationSchema } from '../../coachHelpers';
import {
  IFormikInterface,
  IUploadInterface,
} from '../../coachInterfaces/registrationInterface';
import { educationalInformation } from '../../coachSlices/registrationSlice';
import { useAppSelector } from '../../hooks';

import Button from '../buttons';
import Carousel from '../imageSlider';
import TextArea from '../textArea';
import './index.css';

const Component = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [t] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);
  const { educationalRequests, personalInfo } = useAppSelector(
    (state) => state.registration
  );

  let clone = educationalRequests?.educationRequests;

  const [data, setData] = useState<any>(clone);

  const uploadFile = (e: IUploadInterface, i: number, setFieldValue: any) => {
    setFieldValue(`educationRequests.${i}.file`, e.target.files[0]);
  };
  const handleSubmit = (data: any) => {
    navigate(registrationConst.PATH_EXPERIENCE_DETAILS);
  };
  useEffect(() => {
    if (!personalInfo?.fullName)
      navigate(registrationConst.PATH_PERSONAL_DETAILS);
  }, [personalInfo]);

  return (
    <div className="registrationStep-container01">
      <div className="registrationStep-container03">
        <p className="step">{t('EDUCATIONAL_DETAILS.STEP04')}</p>
        <div className="progress_bar">
          <div className="bg-primary h-1 w-4/5"></div>
        </div>

        <Formik
          validationSchema={educationalValidationSchema}
          initialValues={{
            educationRequests:
              data?.length > 0
                ? [...data]
                : [
                    {
                      university: '',
                      degree: '',
                      fieldOfStudy: '',
                      startDate: '',
                      endDate: '',
                      description: '',
                      fileId: '',
                      file: '',
                    },
                  ],
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            submitForm,
            setFieldValue,
          }: IFormikInterface) => {
            dispatch(educationalInformation(values));

            return (
              <Form>
                <div className="lg:pr-5 mb-6 lg:mb-24">
                  <h1 className="heading_large sm:heading_small">
                    {t('EDUCATIONAL_DETAILS.EDUCATION')}
                    <span className="font-bold text-20 font-satoshi rtl:font-Almarai text-gray">
                      {t('EDUCATIONAL_DETAILS.OPTIONAL')}
                    </span>
                  </h1>
                  <p className="paragraph-educational">
                    {t('EDUCATIONAL_DETAILS.DESCRIPTION')}
                  </p>
                  <FieldArray
                    name="educationRequests"
                    render={(arrayHelpers) => {
                      const formData =
                        values?.educationRequests?.length > 0 &&
                        values?.educationRequests;
                      return (
                        <div>
                          {formData && formData?.length > 0
                            ? formData?.map(
                                (educationRequests: any, i: number) => (
                                  <div key={i}>
                                    {i > 0 && (
                                      <div className="flex justify-end text-end border-info mt-8 lg:border-t-2">
                                        <Button
                                          onClick={() => arrayHelpers.remove(i)}
                                          className="mb-5 mt-9 text-12 font-bold text-red rtl:font-Almarai border-2 border-red px-4 rounded-md py-2"
                                        >
                                          {t('COACHING_DETAILS.REMOVE')}
                                        </Button>
                                      </div>
                                    )}
                                    <Input
                                      id={`educationRequests.${i}.university`}
                                      type="text"
                                      name={`educationRequests.${i}.university`}
                                      value={
                                        values?.educationRequests[i]?.university
                                      }
                                      placeholder={t(
                                        'EDUCATIONAL_DETAILS.UNIVERSITY'
                                      )}
                                      onBlur={handleBlur}
                                      optional={true}
                                      // value={
                                      //   values?.educationRequests[i]?.provider
                                      // }
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `educationRequests.${i}.university`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        touched?.educationRequests?.length > 0
                                          ? touched?.educationRequests[i]
                                              ?.university && 1
                                          : undefined
                                      }
                                      errormessage={
                                        errors?.educationRequests?.length > 0
                                          ? errors.educationRequests[i]
                                              ?.university
                                          : undefined
                                      }
                                    />
                                    <Input
                                      id={`educationRequests.${i}.degree`}
                                      type="text"
                                      name={`educationRequests.${i}.degree`}
                                      placeholder={t(
                                        'EDUCATIONAL_DETAILS.DEGREE'
                                      )}
                                      optional={true}
                                      value={
                                        values?.educationRequests[i]?.degree
                                      }
                                      onBlur={handleBlur}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `educationRequests.${i}.degree`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        touched?.educationRequests?.length > 0
                                          ? touched?.educationRequests[i]
                                              ?.degree && 1
                                          : undefined
                                      }
                                      errormessage={
                                        errors?.educationRequests?.length > 0
                                          ? errors.educationRequests[i]?.degree
                                          : undefined
                                      }
                                    />
                                    <Input
                                      id={`educationRequests.${i}.fieldOfStudy`}
                                      type="text"
                                      name={`educationRequests.${i}.fieldOfStudy`}
                                      placeholder={t(
                                        'EDUCATIONAL_DETAILS.FIELD_OF_STUDY'
                                      )}
                                      optional={true}
                                      value={
                                        values?.educationRequests[i]
                                          ?.fieldOfStudy
                                      }
                                      onBlur={handleBlur}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `educationRequests.${i}.fieldOfStudy`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        touched?.educationRequests?.length > 0
                                          ? touched?.educationRequests[i]
                                              ?.fieldOfStudy && 1
                                          : undefined
                                      }
                                      errormessage={
                                        errors?.educationRequests?.length > 0
                                          ? errors.educationRequests[i]
                                              ?.fieldOfStudy
                                          : undefined
                                      }
                                    />

                                    <div className="lg:flex justify-between ">
                                      <div
                                        className={` lg:w-6/12 ${
                                          lang === 'en' ? 'lg:mr-3' : 'lg:ml-3'
                                        } `}
                                      >
                                        <Date
                                          type="date"
                                          id={`educationRequests.${i}.startDate`}
                                          name={`educationRequests.${i}.startDate`}
                                          placeholder={t(
                                            'EDUCATIONAL_DETAILS.START_DATE'
                                          )}
                                          onBlur={handleBlur}
                                          optional={1}
                                          error={
                                            touched?.educationRequests?.length >
                                            0
                                              ? touched?.educationRequests[i]
                                                  ?.startDate && 1
                                              : undefined
                                          }
                                          value={
                                            values.educationRequests[i]
                                              ?.startDate
                                          }
                                          onChange={(opt: any) => {
                                            const date = `${opt?.year}-${
                                              opt?.monthIndex + 1 < 10
                                                ? `0${opt?.monthIndex + 1}`
                                                : opt?.monthIndex + 1
                                            }-${
                                              opt?.day < 10
                                                ? `0${opt?.day}`
                                                : opt?.day
                                            }`;
                                            setFieldValue(
                                              `educationRequests.${i}.startDate`,
                                              date
                                            );
                                          }}
                                          errormessage={
                                            errors?.educationRequests?.length >
                                            0
                                              ? errors.educationRequests[i]
                                                  ?.startDate
                                              : undefined
                                          }
                                        />
                                      </div>
                                      <div className=" lg:w-6/12">
                                        <Date
                                          type="date"
                                          id={`educationRequests.${i}.endDate`}
                                          name={`educationRequests.${i}.endDate`}
                                          placeholder={t(
                                            'EDUCATIONAL_DETAILS.END_DATE'
                                          )}
                                          optional={1}
                                          onBlur={handleBlur}
                                          onChange={(opt: any) => {
                                            const date = `${opt?.year}-${
                                              opt?.monthIndex + 1 < 10
                                                ? `0${opt?.monthIndex + 1}`
                                                : opt?.monthIndex + 1
                                            }-${
                                              opt?.day < 10
                                                ? `0${opt?.day}`
                                                : opt?.day
                                            }`;
                                            setFieldValue(
                                              `educationRequests.${i}.endDate`,
                                              date
                                            );
                                          }}
                                          value={
                                            values.educationRequests[i]?.endDate
                                          }
                                          error={
                                            touched?.educationRequests?.length >
                                            0
                                              ? touched?.educationRequests[i]
                                                  ?.endDate && 1
                                              : undefined
                                          }
                                          errormessage={
                                            errors?.educationRequests?.length >
                                            0
                                              ? errors.educationRequests[i]
                                                  ?.endDate
                                              : undefined
                                          }
                                        />
                                      </div>
                                    </div>
                                    <TextArea
                                      id={`educationRequests.${i}.description`}
                                      type="text"
                                      rows={3}
                                      cols={50}
                                      name={`educationRequests.${i}.description`}
                                      placeholder={t(
                                        'EDUCATIONAL_DETAILS.DESCRIPTION_DETAILS'
                                      )}
                                      optional={1}
                                      onBlur={handleBlur}
                                      onChange={(e: any) => {
                                        setFieldValue(
                                          `educationRequests.${i}.description`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        touched?.educationRequests?.length > 0
                                          ? touched?.educationRequests[i]
                                              ?.description && 1
                                          : undefined
                                      }
                                      errormessage={
                                        errors?.educationRequests?.length > 0
                                          ? errors.educationRequests[i]
                                              ?.description
                                          : undefined
                                      }
                                      value={
                                        values.educationRequests[i]?.description
                                      }
                                    />
                                    <h1 className="text-22 font-bold font-satoshi rtl:font-Almarai text-secondary mt-4 mb-2 ">
                                      {t('EDUCATIONAL_DETAILS.MEDIA')}
                                    </h1>
                                    <p className="text-gray text-17 font-normal font-general rtl:font-Almarai">
                                      {t('EDUCATIONAL_DETAILS.MEDIA_DEC')}
                                    </p>
                                    <div className={`my-5 `}>
                                      <label
                                        htmlFor={`educationRequests.${i}.fileId`}
                                        className="bg-gray rounded px-4 py-2 cursor-pointer text-white text-14 font-bold"
                                      >
                                        {t('EDUCATIONAL_DETAILS.UPLOAD_DOC')}
                                      </label>
                                      <input
                                        id={`educationRequests.${i}.fileId`}
                                        name={`educationRequests.${i}.file`}
                                        className="form-control"
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.pdf"
                                        onChange={(e) => {
                                          uploadFile(e, i, setFieldValue);
                                        }}
                                        style={{ display: 'none' }}
                                      />
                                      <span
                                        id={`file${i}`}
                                        className={`${
                                          lang === 'en' ? 'ml-3' : 'mr-3'
                                        } text-13 font-normal text-gray`}
                                      >
                                        {values.educationRequests[i]?.file
                                          ? values.educationRequests[i]?.file
                                              ?.name
                                          : t(
                                              'COACHING_DETAILS.NO_IMAGE_ADDED'
                                            )}
                                      </span>
                                    </div>
                                    <div className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
                                      {errors?.educationRequests?.length > 0
                                        ? t(
                                            `ERRORS.${errors.educationRequests[i]?.file}`
                                          )
                                        : ''}
                                    </div>
                                  </div>
                                )
                              )
                            : null}
                          <button
                            type="button"
                            className="add_more "
                            onClick={() => {
                              arrayHelpers.push({
                                university: '',
                                degree: '',
                                fieldOfStudy: '',
                                startDate: '',
                                endDate: '',
                                description: '',
                                fileId: '',
                              });
                            }}
                          >
                            {t('ADD_MORE')}
                          </button>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className=" lg:flex lg:justify-between grid py-4 mb-20 border-info lg:border-t-2">
                  <button
                    onClick={() =>
                      navigate(registrationConst.PATH_SPECIALIZATION_DETAILS)
                    }
                    className="order-last lg:order-first cancel-btn mb-6 py-4 hover:bg-primary hover:border-transparent hover:text-white hover:lg:next-btn hover:next-btn-large"
                  >
                    {t('BACK')}
                  </button>
                  <div className=" lg:flex lg:justify-start grid lg:w-1/2">
                    <button
                      className={` order-last lg:order-first lg:skip-btn skip-btn-large ${
                        lang === 'en' ? 'lg:mr-8' : 'lg:ml-8'
                      } `}
                      // onClick={() =>
                      //   navigate(registrationConst.PATH_EXPERIENCE_DETAILS)
                      // }
                    >
                      {t('EDUCATIONAL_DETAILS.SKIP')}
                    </button>
                    <button
                      // onClick={submitForm}
                      className="lg:next-btn next-btn-large bg-primary"
                    >
                      {t('NEXT')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Carousel />
    </div>
  );
};
const EducationalDetails = styled(Component)``;
export default EducationalDetails;

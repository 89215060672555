import ApartmentIcon from '@mui/icons-material/Apartment';
import CakeIcon from '@mui/icons-material/Cake';
import EmailIcon from '@mui/icons-material/Email';
import FemaleIcon from '@mui/icons-material/Female';
import LockIcon from '@mui/icons-material/Lock';
import MaleIcon from '@mui/icons-material/Male';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Button from '../../clientComponents/buttons';
import Date from '../../clientComponents/date';
import Input from '../../clientComponents/inputs';
import PhoneNumberInput from '../../clientComponents/phoneNumberInput/phoneNumberInput';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { SimpleSlider } from '../../clientComponents/slider';
import { signUpValidationSchema } from '../../clientHelpers';
import { getDefaultValue } from '../../clientHelpers/utility';
import {
  personalInformationSinup,
  registerClient,
  selectAuthentication,
} from '../../clientSlices/authentication';
import { useAppSelector } from '../../hooks';
import './index.css';
const Signup = () => {
  const [t] = useTranslation('common');

  const imagePath = [
    {
      path: '/assets/signup/add_client.jpg',
      heading: t('SIGNUP.FIRST_FEATURE'),
      title: t('SIGNUP.FIRST_FEATURE_DESCRIPTION'),
    },
    {
      path: '/assets/signup/add_client.jpg',
      heading: t('SIGNUP.SECOND_FEATURE'),
      title: t('SIGNUP.SECOND_FEATURE_DESCRIPTION'),
    },
    {
      path: '/assets/signup/add_client.jpg',
      heading: t('SIGNUP.THIRD_FEATURE'),
      title: t('SIGNUP.THIRD_FEATURE_DESCRIPTION'),
    },
  ];
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const settings = {
    className: '',
    dotsClass: 'slick-dotss',
    initialSlide: 0,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    pauseOnHover: true,
    arrows: false,
    appendDots: (dots: any) => {
      return <ul style={{ margin: '0px', display: '-webkit-box' }}>{dots}</ul>;
    },
    beforeChange: (prev: any, next: any) => {
      // here to detect slide change
      setCurrentSlideIndex(next);
    },
    customPaging: (i: any) => {
      const style = {
        width: 8,
        height: 8,
        display: '',
        backgroundImage: `url(/assets/registrationPageImages/dot.svg)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      };
      const activeStyle = {
        width: 38,
        height: 8,
        display: '',
        // backgroundImage: `url(/assets/registrationPageImages/activeDot.svg)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      };

      return (
        <>
          <link
            className=""
            style={i === currentSlideIndex ? activeStyle : style}
          />
        </>
      );
    },
  };

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="R-container">
        <div className="R-parent">
          <div className="R-left">
            <SignupForm t={t} />
          </div>
          <div className="R-right">
            <SimpleSlider settings={settings} imagePath={imagePath} />
          </div>
        </div>
      </div>
    </>
  );
};

const SignupForm = ({ t }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const handleFormSubmit = (data: any) => {
    if (isChecked) dispatch(registerClient({ ...data, history: navigate }));
  };
  const { isProcessingRegisterRequest } = useSelector(selectAuthentication);
  const [searchParams] = useSearchParams();
  const onChangeHandleChecked = () => {
    setIsChecked((value) => !value);
  };
  const [passwordType, setPasswordType] = useState('password');

  const { lang } = useAppSelector((state) => state.language);

  const { personalInfoData } = useAppSelector(selectAuthentication);
  const togglePassword = () => {
    if (passwordType === 'password') setPasswordType('text');
    else setPasswordType('password');
  };

  const [isChecked, setIsChecked] = useState(false);
  // const genderOption = [
  //   { label: 'MALE', value: 'MALE' },
  //   { label: 'FEMALE', value: 'FEMALE' },
  // ];
  const genderOption = [
    { label: 'MALE', value: 'MALE', label2: 'ذكر' },
    { label: 'FEMALE', value: 'FEMALE', label2: 'أنثى' },
  ];

  const arabicGenderOption = [
    { label: 'ذكر', value: 'MALE' },
    { label: 'أنثى', value: 'FEMALE' },
  ];
  const [genderInputValue, setGenderInputValue] = useState('');
  const handleGenderInputChange = (event: any) => {
    setGenderInputValue(event);
  };

  return (
    <>
      <div className="R-form-div">
        <h1 className=" font-satoshi rtl:font-Almarai text-3xl font-bold text-secondary">
          {t('SIGNUP.CREATE_YOUR_ACCOUNT')}
        </h1>
        <p className="font-normal  font-general rtl:font-Almarai  text-base text-gray mt-4">
          {t('SIGNUP.CREATE_ACCOUNT_SHORT_DESCRIPTION')}
        </p>
        <p className="font-normal  font-general rtl:font-Almarai  text-base text-gray mt-4 mb-4">
          {t('SIGNUP.ALREADY_HAVE_ACCOUNT')}
          <span
            className="text-primary font-semibold  font-general rtl:font-Almarai cursor-pointer"
            onClick={() => navigate('/login?user=CLIENT')}
          >
            {' '}
            {t('SIGNUP.SIGN_IN')}
          </span>
        </p>
        <Formik
          initialValues={personalInfoData}
          validationSchema={signUpValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
          }) => {
            dispatch(personalInformationSinup(values));
            return (
              <Form>
                <Input
                  id="FormFullName"
                  type="text"
                  name="fullName"
                  placeholder={t('SIGNUP.FULL_NAME')}
                  onBlur={handleBlur}
                  error={touched.fullName && 1}
                  onChange={handleChange}
                  value={values.fullName}
                  errormessage={errors.fullName as string}
                  optional={false}
                  className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12"
                  icon={
                    <PersonIcon
                      className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                      sx={{ color: '#687E8E' }}
                    />
                  }
                  clearText={() => setFieldValue('fullName', '')}
                />
                <Input
                  id="FormEmail"
                  type="email"
                  name="email"
                  placeholder={t('SIGNUP.EMAIL')}
                  onBlur={handleBlur}
                  error={touched.email && 1}
                  onChange={handleChange}
                  value={values.email}
                  errormessage={errors.email as string}
                  optional={false}
                  className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12 "
                  icon={
                    <EmailIcon
                      className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                      sx={{ color: '#687E8E' }}
                    />
                  }
                  clearText={() => setFieldValue('email', '')}
                />
                <Input
                  id="FormPassword"
                  type={passwordType}
                  name="password"
                  placeholder={t('SIGNUP.PASSWORD')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && 1}
                  value={values.password}
                  errormessage={errors.password as string}
                  optional={false}
                  togglePassword={togglePassword}
                  showEyeStrike={passwordType === 'password'}
                  showEye={passwordType === 'text'}
                  className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12 "
                  icon={
                    <LockIcon
                      className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                      sx={{ color: '#687E8E' }}
                    />
                  }
                />
                <div className="mdlg:flex justify-between gap-2 ">
                  <div className=" mdlg:w-6/12 mdlg:mr-3 rtl:mr-0">
                    {/* <Date
                    type="date"
                    id="birth"
                    name="dob"
                    placeholder={t('SIGNUP.DOB')}
                    onBlur={handleBlur}
                    error={touched.dob && 1}
                    onChange={handleChange}
                    value={values.dob}
                    errormessage={errors.dob}
                  /> */}
                    <Date
                      type="date"
                      id="dob"
                      name="dob"
                      placeholder={t('SIGNUP.DOB')}
                      onBlur={handleBlur}
                      optional={0}
                      error={touched.dob && 1}
                      value={values.dob}
                      onChange={(opt: any) => {
                        const date = `${opt?.year}-${
                          opt?.monthIndex + 1 < 10
                            ? `0${opt?.monthIndex + 1}`
                            : opt?.monthIndex + 1
                        }-${opt?.day < 10 ? `0${opt?.day}` : opt?.day}`;
                        setFieldValue(`dob`, date);
                      }}
                      errormessage={errors.dob as string}
                      className="indent-[27px]"
                      icon={
                        <CakeIcon
                          className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-40"
                          sx={{ color: '#687E8E' }}
                        />
                      }
                      dateClassName="indent-8"
                    />
                  </div>
                  <div className="mb-8 mdlg:w-6/12 pr-[2px]">
                    <SelectDropdown
                      id="FormGender"
                      name="gender"
                      placeholder={t('PERSONAL_DETAILS.GENDER')}
                      options={genderOption}
                      onBlur={handleBlur}
                      error={touched.gender && 1}
                      onChange={(opt: any) => {
                        setFieldValue('gender', opt.value);
                      }}
                      errormessage={errors.gender}
                      isValue={values.gender}
                      inputValue={genderInputValue}
                      onInputChange={handleGenderInputChange}
                      getOptionLabel={(option: any) =>
                        lang === 'en' ? option.label : option.label2
                      }
                      defaultValue={
                        getDefaultValue(values.gender, genderOption)[0]
                      }
                      iconValue={
                        values.gender === 'FEMALE' ? (
                          <FemaleIcon
                            className="ml-3"
                            // className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-40 "
                            sx={{ color: '#687E8E' }}
                          />
                        ) : (
                          <MaleIcon
                            className="ml-3"
                            // className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-40"
                            sx={{ color: '#687E8E' }}
                          />
                        )
                      }
                    />
                  </div>
                </div>
                {/* <Input
                id="FormPhoneNumber"
                type="number"
                name="phoneNumber"
                placeholder={t('SIGNUP.PHONE_NUMBER')}
                onBlur={handleBlur}
                error={touched.phoneNumber && 1}
                onChange={handleChange}
                value={values.phoneNumber}
                errormessage={errors.phoneNumber}
                optional={false}
                className="indent-[80px] text-xl text-[#687E8E]"
                icon={
                  <PhoneIcon
                    className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                    sx={{ color: '#687E8E' }}
                  />
                }
                clearText={() => setFieldValue('phoneNumber', '')}
              /> */}
                <PhoneNumberInput
                  id="FormPhoneNumber"
                  type="number"
                  name="phoneNumber"
                  placeholder={t('SIGNUP.PHONE_NUMBER')}
                  codeValue={values.countryCode}
                  onBlur={handleBlur}
                  error={touched.phoneNumber && 1}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  errormessage={errors.phoneNumber as string}
                  optional={false}
                  className="pl-[6px]  w-[100%]"
                  icon={
                    <PhoneIcon
                      className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                      sx={{ color: '#687E8E' }}
                    />
                  }
                  clearText={() => setFieldValue('phoneNumber', '')}
                  setFieldValue={setFieldValue}
                />
                <Input
                  id="FormOccupation"
                  type="text"
                  name="occupation"
                  placeholder={t('SIGNUP.OCCUPATION')}
                  onBlur={handleBlur}
                  error={touched.occupation && 1}
                  onChange={handleChange}
                  value={values.occupation}
                  errormessage={errors.occupation as string}
                  optional={true}
                  className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12"
                  icon={
                    <WorkIcon
                      className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                      sx={{ color: '#687E8E' }}
                    />
                  }
                  clearText={() => setFieldValue('occupation', '')}
                />
                <Input
                  id="FormCompany"
                  type="text"
                  name="company"
                  placeholder={t('SIGNUP.COMPANY')}
                  onBlur={handleBlur}
                  error={touched.company && 1}
                  onChange={handleChange}
                  value={values.company}
                  errormessage={errors.company as string}
                  optional={true}
                  className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12"
                  icon={
                    <ApartmentIcon
                      className=" absolute top-2.5 ltr:left-3 rtl:right-3"
                      sx={{ color: '#687E8E' }}
                    />
                  }
                  clearText={() => setFieldValue('company', '')}
                />{' '}
                <div className="flex">
                  <div className="h-full pt-[5px] flex items-start">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={onChangeHandleChecked}
                    />
                  </div>
                  <p className="p-[13px] pt-0 font-normal  font-general rtl:font-Almarai  text-base text-gray">
                    {t('SIGNUP.TERMS_TEXT_ONE')}
                    <Link to="/terms-and-condition">
                      <span className="text-primary font-normal  font-general rtl:font-Almarai cursor-pointer">
                        {' '}
                        {t('SIGNUP.TERMS_AND_CONDITIONS')}
                      </span>{' '}
                    </Link>
                    {t('SIGNUP.TERMS_TEXT_TWO')}{' '}
                    <Link to="/privacy-policy">
                      <span className="text-primary font-normal  font-general rtl:font-Almarai cursor-pointer">
                        {t('SIGNUP.PRIVACY_POLICY')}
                      </span>
                    </Link>
                  </p>
                </div>
                {!isChecked ? (
                  <p className="text-red rtl:font-Almarai">
                    {t('SIGNUP.ERROR_ACCEPTANCE')}
                  </p>
                ) : null}
                <div className="mt-4">
                  <Button
                    type="submit"
                    className={`w-full py-3 px-4 text-18 text-white font-bold font-satoshi rtl:font-Almarai rounded flex justify-center items-center font-bold font-satoshi rtl:font-Almarai   mt-1 ${
                      !isChecked
                        ? ' cursor-not-allowed bg-[#38383840]'
                        : ' bg-primary '
                    }`}
                    disabled={!isChecked}
                  >
                    <div className="w-full flex justify-around">
                      {isProcessingRegisterRequest && (
                        <div className="loader" />
                      )}

                      {t('SIGNUP.CREATE_ACCOUNT')}
                    </div>
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default Signup;

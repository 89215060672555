import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { getAccessToken } from '../../clientHelpers/localStorage';
import { postProfileFile } from '../../coachServices/apiService';

import {
  downloadById,
  educationById,
  educationDelete,
  experienceById,
  experienceDelete,
  getCoachProfile,
  getProfilesEducations,
  getProfilesExperience,
  postAddEducation,
  postAddExperience,
  postEditEducation,
  postEditExperience,
  updateCoachProfile,
} from '../../coachServices/authenticationService';
import { showAlert } from '../../slice/alert';
import { getSubscription } from '../paymentSlice';

const initialState: any = {
  isEducationAddedSuccess: false,
  isEducationDeletedSuccess: false,
  isEducationEditSuccess: false,
  isEducationDeleteErrorMessage: '',

  isExperienceAddedSuccess: false,
  isExperienceEditSuccess: false,
  isExperienceDeletedSuccess: false,
  isExperienceDeleteErrorMessage: '',
  isProcessingPersonalDetails: false,
  personalDetails: [],
  updatePersonalDetails: [],

  coachEducationalDetails: [],
  coachEducationalDetailsById: [],

  coachExperienceDetails: [],
  coachExperienceDetailsById: [],

  profileFileId: [],
  addEducationFileId: [],
  addExperienceFileId: [],
  editEducationFileId: [],
  editExperienceFileId: [],

  downloadEducationDocument: [],
  downloadExperienceDocument: [],
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // COACH PROFILE ALL DATA
      .addCase(coachProfile.pending, (state) => {
        state.isProcessingPersonalDetails = true;
      })
      .addCase(coachProfile.fulfilled, (state, action) => {
        state.isProcessingPersonalDetails = false;

        state.personalDetails = action?.payload?.data;
      })
      .addCase(coachProfile.rejected, (state) => {
        state.isProcessingPersonalDetails = false;

        state.isProcessingLoginRequest = false;
      })

      .addCase(updateProfile.pending, (state) => {})
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.personalDetails = action?.payload?.data;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.isProcessingLoginRequest = false;
      })

      // COACH EDUCATION DATA
      .addCase(getProfileEducations.pending, (state) => {})
      .addCase(getProfileEducations.fulfilled, (state, action) => {
        state.coachEducationalDetails = action?.payload?.data[0]?.content;
      })
      .addCase(getProfileEducations.rejected, (state) => {
        state.isProcessingLoginRequest = false;
      })

      // COACH EDUCATION DATA BY ID
      .addCase(getProfileEducationById.pending, (state) => {
        state.coachEducationalDetailsById = {};
      })
      .addCase(getProfileEducationById.fulfilled, (state, action) => {
        state.coachEducationalDetailsById = action?.payload?.data[0];
      })
      .addCase(getProfileEducationById.rejected, (state) => {})

      // COACH EXPERIENCE DATA
      .addCase(getProfileExperience.pending, (state) => {})
      .addCase(getProfileExperience.fulfilled, (state, action) => {
        state.coachExperienceDetails = action?.payload?.data[0]?.content;
      })
      .addCase(getProfileExperience.rejected, (state) => {
        state.coachExperienceDetails = [];
      })

      // COACH EXPERIENCE DATA BY ID
      .addCase(getProfileExperienceById.pending, (state) => {
        state.coachExperienceDetailsById = {};
      })
      .addCase(getProfileExperienceById.fulfilled, (state, action) => {
        state.coachExperienceDetailsById = action?.payload?.data[0];
      })
      .addCase(getProfileExperienceById.rejected, (state) => {})

      // COACH DELETE EDUCATION
      .addCase(deleteEducation.pending, (state, action) => {
        state.isEducationDeletedSuccess = false;
      })
      .addCase(deleteEducation.fulfilled, (state, action) => {
        state.isEducationDeletedSuccess = true;
      })
      .addCase(deleteEducation.rejected, (state, err) => {
        state.isEducationDeleteErrorMessage = err;
        state.isEducationDeletedSuccess = false;
      })

      // COACH DELETE EXPERIENCE
      .addCase(deleteExperience.pending, (state, action) => {
        state.isExperienceDeletedSuccess = false;
      })
      .addCase(deleteExperience.fulfilled, (state, action) => {
        state.isExperienceDeletedSuccess = true;
      })
      .addCase(deleteExperience.rejected, (state, err) => {
        state.isExperienceDeleteErrorMessage = err;
        state.isExperienceDeletedSuccess = false;
      })

      // COACH ADD EDUCATION
      .addCase(addEducation.pending, (state) => {
        state.isEducationAddedSuccess = false;
      })
      .addCase(addEducation.fulfilled, (state, action) => {
        state.isEducationAddedSuccess = true;
      })
      .addCase(addEducation.rejected, (state, err) => {
        state.isEducationAddedSuccess = false;
      })

      // COACH EDIT EDUCATION
      .addCase(editEducation.pending, (state) => {
        state.isEducationEditSuccess = false;
      })
      .addCase(editEducation.fulfilled, (state, action) => {
        state.isEducationEditSuccess = true;
      })
      .addCase(editEducation.rejected, (state, err) => {
        state.isEducationEditSuccess = false;
      })

      // COACH ADD EXPERIENCE
      .addCase(addExperience.pending, (state) => {
        state.isExperienceAddedSuccess = false;
      })
      .addCase(addExperience.fulfilled, (state, action) => {
        state.isExperienceAddedSuccess = true;
      })
      .addCase(addExperience.rejected, (state, err) => {
        state.isExperienceAddedSuccess = false;
      })

      // COACH EDIT EXPERIENCE
      .addCase(editExperience.pending, (state) => {
        state.isExperienceEditSuccess = false;
      })
      .addCase(editExperience.fulfilled, (state, action) => {
        state.isExperienceEditSuccess = true;
      })
      .addCase(editExperience.rejected, (state, err) => {
        state.isExperienceEditSuccess = false;
      })

      // COACH PROFILE FILE
      .addCase(profileFile.pending, (state) => {})
      .addCase(profileFile.fulfilled, (state, action) => {
        state.profileFileId = action?.payload?.data[0];
      })
      .addCase(profileFile.rejected, (state) => {})

      // COACH ADD EDUCATION FILE
      .addCase(addEducationFile.fulfilled, (state, action) => {
        state.addEducationFileId = action?.payload?.data[0];
      })
      .addCase(addEducationFile.rejected, (state) => {})

      // COACH ADD EXPERIENCE FILE
      .addCase(addExperienceFile.fulfilled, (state, action) => {
        state.addExperienceFileId = action?.payload?.data[0];
      })
      .addCase(addExperienceFile.rejected, (state) => {})

      // COACH EDIT EDUCATION FILE
      .addCase(editEducationFile.fulfilled, (state, action) => {
        state.editEducationFileId = action?.payload?.data[0];
      })
      .addCase(editEducationFile.rejected, (state) => {})

      // COACH EDIT EXPERIENCE FILE
      .addCase(editExperienceFile.fulfilled, (state, action) => {
        state.editExperienceFileId = action?.payload?.data[0];
      })
      .addCase(editExperienceFile.rejected, (state) => {})

      //DOWNLOAD EDUCATION FILE
      .addCase(downloadFileEducation.pending, (state) => {
        state.downloadEducationDocument = [];
      })
      .addCase(downloadFileEducation.fulfilled, (state, action) => {
        state.downloadEducationDocument.push(action?.payload?.data[0]);
      })
      .addCase(downloadFileEducation.rejected, (state) => {
        state.downloadEducationDocument = [];
      })

      //DOWNLOAD EXPERIENCE FILE
      .addCase(downloadFileExperience.pending, (state) => {
        state.downloadExperienceDocument = [];
      })
      .addCase(downloadFileExperience.fulfilled, (state, action) => {
        state.downloadExperienceDocument.push(action?.payload?.data[0]);
      })
      .addCase(downloadFileExperience.rejected, (state) => {})

      //DOWNLOAD PROFILE PIC
      .addCase(downloadProfilePic.fulfilled, (state, action) => {
        state.downloadProfilePicture = action?.payload?.data[0];
      })
      .addCase(downloadProfilePic.rejected, (state) => {});
  },
});

//PROFILE INFO APIS

export const coachProfile = createAsyncThunk(
  'coachProfile',
  async (_, thunkAPI) => {
    try {
      const response = await getCoachProfile();
      thunkAPI.dispatch(getSubscription());
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async (userData: any, thunkAPI) => {
    try {
      const decode: { id: number } = jwt_decode(getAccessToken() as string);
      const response = await updateCoachProfile(userData, decode['id']);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getProfileEducations = createAsyncThunk(
  'getProfileEducations',
  async (_, thunkAPI) => {
    try {
      const response = await getProfilesEducations();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getProfileEducationById = createAsyncThunk(
  'getProfileEducationById',
  async (id: number, thunkAPI) => {
    try {
      const response = await educationById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getProfileExperience = createAsyncThunk(
  'getProfileExperience',
  async (_, thunkAPI) => {
    try {
      const response = await getProfilesExperience();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getProfileExperienceById = createAsyncThunk(
  'getProfileExperienceById',
  async (id: number, thunkAPI) => {
    try {
      const response = await experienceById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addEducation = createAsyncThunk(
  'addEducation',
  async (userData: any, thunkAPI) => {
    try {
      const response = await postAddEducation(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const addExperience = createAsyncThunk(
  'addExperience',
  async (userData: any, thunkAPI) => {
    try {
      const response = await postAddExperience(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editEducation = createAsyncThunk(
  'editEducation',
  async (userData: any, thunkAPI) => {
    try {
      const response = await postEditEducation(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editExperience = createAsyncThunk(
  'editExperience',
  async (userData: any, thunkAPI) => {
    try {
      const response = await postEditExperience(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteEducation = createAsyncThunk(
  'deleteEducation',
  async (id: number, thunkAPI) => {
    try {
      const response = await educationDelete(id);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteExperience = createAsyncThunk(
  'deleteExperience',
  async (id: number, thunkAPI) => {
    try {
      const response = await experienceDelete(id);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addExperienceFile = createAsyncThunk(
  'addExperienceFile',
  async (uploadFile: any, thunkAPI) => {
    try {
      const type = 'EXPERIENCE_CERTIFICATE';
      const res = await postProfileFile(uploadFile, type);
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editExperienceFile = createAsyncThunk(
  'editExperienceFile',
  async (uploadFile: any, thunkAPI) => {
    try {
      const type = 'EXPERIENCE_CERTIFICATE';
      const res = await postProfileFile(uploadFile, type);
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addEducationFile = createAsyncThunk(
  'addEducationFile',
  async (uploadFile: any, thunkAPI) => {
    try {
      const type = 'EDUCATION_CERTIFICATES';
      const res = await postProfileFile(uploadFile, type);
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editEducationFile = createAsyncThunk(
  'editEducationFile',
  async (uploadFile: any, thunkAPI) => {
    try {
      const type = 'EDUCATION_CERTIFICATES';
      const res = await postProfileFile(uploadFile, type);
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const oneMSizeByByte = 1024 * 1024;

export const profileFile = createAsyncThunk(
  'profileFile',
  async (uploadFile: any, thunkAPI) => {
    try {
        if (!!uploadFile && uploadFile[0].size <= oneMSizeByByte) {
            const type = 'PROFILE_PIC';
            const res = await postProfileFile(uploadFile, type);
            thunkAPI.dispatch(
                showAlert({
                    type: 'success',
                    message: res?.message,
                })
            );
            return res;
        } else {
            thunkAPI.dispatch(
                showAlert({
                    type: 'error',
                    message: 'File size should be less than 1MB',
                })
            );
            return thunkAPI.rejectWithValue('File size should be less than 1MB');
        }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadFileEducation = createAsyncThunk(
  'downloadFileEducation',
  async (id: number, thunkAPI) => {
    try {
      const response = await downloadById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadFileExperience = createAsyncThunk(
  'downloadFileExperience',
  async (id: number, thunkAPI) => {
    try {
      const response = await downloadById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadProfilePic = createAsyncThunk(
  'downloadProfilePic',
  async (id: number, thunkAPI) => {
    try {
      const response = await downloadById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const profileReducer = profileSlice.reducer;

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoData from '../../components/noData';

interface ITableProps {
  header: any;
  data: any;
  loading: boolean;
  category: string;
  profilePicture: any;
  profilePictureLoading: boolean;
}
const Table: FC<ITableProps> = ({
  header,
  data,
  loading,
  category,
  profilePicture,
  profilePictureLoading,
}): JSX.Element => {
  const [hoverRow, SetHoverRow] = useState();
  const navigate = useNavigate();
  const [t] = useTranslation('common');

  const dispatch = useDispatch<any>();
  const list = ['overall_rating', 'service', 'package', 'assessment'];

  return (
    <>
      {
        <>
          <div className="overflow-hidden w-full">
            <div className="  bg-gray-100 flex  bg-gray-100 font-sans overflow-hidden   ">
              <div className="w-full">
                <div className="bg-white  overflow-x-scroll scroll-mt-2">
                  <table className="min-w-max w-full table-auto  ">
                    <thead>
                      <tr className="bg-darkBlue text-white text-sm leading-normal  h-10">
                        {!loading ? (
                          header && data?.length !== 0 ? (
                            header &&
                            header.map(
                              (
                                item: { value: string; label: string },
                                index: number
                              ) => (
                                <th
                                  key={index}
                                  className={`${
                                    item.value === 'id' ||
                                    item.value === 'profilePicId'
                                      ? 'hidden'
                                      : 'py-3 px-6 text-left rtl:text-right font-general rtl:font-Almarai font-semibold text-12'
                                  }
                       `}
                                >
                                  {item.label
                                    .replace(/([A-Z])/g, ' $1')
                                    .toUpperCase()}
                                </th>
                              )
                            )
                          ) : (
                            <th></th>
                          )
                        ) : (
                          <th></th>
                        )}
                      </tr>
                    </thead>
                    <tbody
                      className={`text-gray-600 text-sm font-light overflow-hidden `}
                    >
                      {!loading ? (
                        data && data?.length !== 0 ? (
                          data.map((value: any, i: any) => {
                            return (
                              <tr
                                key={i}
                                className={`border-b border-disableGray h-16 ${
                                  value?.isClosed
                                    ? 'bg-[#BC455E] !text-[#ffffff]'
                                    : 'bg-[#ffffff] !text-gray'
                                } ${
                                  value?.deleted
                                    ? 'bg-disableGray border-disableGray'
                                    : ''
                                }`}
                                onMouseEnter={() => SetHoverRow(i)}
                                onMouseLeave={() => SetHoverRow(undefined)}
                              >
                                {header.map(
                                  (
                                    item: { value: string; label: string },
                                    index: number
                                  ) => {
                                    return (
                                      item.value !== 'id' &&
                                      item.value !== 'profilePicId' &&
                                      (item.value === 'fullName' ? (
                                        value['profilePicId'] ? (
                                          !profilePictureLoading ? (
                                            profilePicture?.map(
                                              (
                                                profilePictureData: any,
                                                profileIndex: number
                                              ) =>
                                                profilePictureData?.id ===
                                                  value['profilePicId'] && (
                                                  <td
                                                    key={profileIndex}
                                                    className={`py-4 px-6 `}
                                                  >
                                                    <div
                                                      className={`flex  items-center ${
                                                        category === 'coach' &&
                                                        (!value['deleted']
                                                          ? value[
                                                              'requestStatus'
                                                            ] === 'REJECTED'
                                                            ? 'cursor-not-allowed'
                                                            : 'cursor-pointer'
                                                          : 'cursor-not-allowed')
                                                      } ${
                                                        list.includes(
                                                          item.value
                                                        )
                                                          ? 'justify-center'
                                                          : ''
                                                      }`}
                                                    >
                                                      <div
                                                        className={` font-normal text-general ${
                                                          value[item.value]
                                                            ? ''
                                                            : ' flex justify-center w-full'
                                                        }`}
                                                      >
                                                        <div className="flex flex-row gap-2 w-fit justify-center">
                                                          <div>
                                                            <img
                                                              className=" w-[32px] h-[32px] rounded-[16px]"
                                                              src={`${
                                                                !profilePictureData?.fileContent
                                                                  ? '/assets/coach/avatar.svg'
                                                                  : `data:image/png;base64,${profilePictureData?.fileContent}`
                                                              }`}
                                                              alt=""
                                                            />
                                                          </div>
                                                          <div className="flex items-center text-textBlack capitalize font-semibold">
                                                            {value[item.value]
                                                              ? value[
                                                                  item.value
                                                                ]
                                                              : '-'}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                )
                                            )
                                          ) : (
                                            <td
                                              key={index}
                                              className="w-full h-16 flex justify-center items-center"
                                            >
                                              {<div className="loader"></div>}
                                            </td>
                                          )
                                        ) : !profilePictureLoading ? (
                                          <td
                                            key={index}
                                            className={`py-4 px-6  `}
                                          >
                                            <div
                                              className={`flex items-center ${
                                                category === 'coach' &&
                                                (!value['deleted']
                                                  ? value['requestStatus'] ===
                                                    'REJECTED'
                                                    ? 'cursor-not-allowed'
                                                    : 'cursor-pointer'
                                                  : 'cursor-not-allowed')
                                              } ${
                                                list.includes(item.value)
                                                  ? 'justify-center'
                                                  : ''
                                              }`}
                                            >
                                              <div
                                                className={` font-normal text-general ${
                                                  value[item.value]
                                                    ? ''
                                                    : ' flex justify-center w-full'
                                                }`}
                                              >
                                                <div className="flex gap-2">
                                                  <div className=" ">
                                                    <img
                                                      className="w-[32px] h-[32px] rounded-[20px]"
                                                      src="/assets/profilePic.svg"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="flex items-center font-semibold capitalize text-textBlack">
                                                    {value[item.value]
                                                      ? value[item.value]
                                                      : '-'}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        ) : (
                                          <td
                                            key={index}
                                            className="w-full h-16 flex justify-center items-center"
                                          >
                                            {<div className="loader"></div>}
                                          </td>
                                        )
                                      ) : (
                                        <td
                                          key={index}
                                          className={`py-4 px-6  `}
                                        >
                                          <div
                                            className={`flex items-center ${
                                              category === 'coach' &&
                                              (!value['deleted']
                                                ? value['requestStatus'] ===
                                                  'REJECTED'
                                                  ? 'cursor-not-allowed'
                                                  : 'cursor-pointer'
                                                : 'cursor-not-allowed')
                                            } ${
                                              list.includes(item.value)
                                                ? 'justify-center'
                                                : ''
                                            }`}
                                          >
                                            <div
                                              className={` font-normal text-general flex flex-row items-center gap-3 ${
                                                value[item.value]
                                                  ? ''
                                                  : ' flex justify-center w-full'
                                              }`}
                                            >
                                              {value[item.value]
                                                ? value[item.value]
                                                : '-'}

                                              {item?.value ===
                                                'remainingDays' &&
                                                hoverRow === i && (
                                                  <div
                                                    onClick={() =>
                                                      navigate('/schedule')
                                                    }
                                                    className={
                                                      ' w-fit flex justify-center py-2 px-4 rounded  font-normal text-13 font-general rtl:font-Almarai pt-2 px-4 bg-primary text-[#FFFFFF] cursor-pointer'
                                                    }
                                                  >
                                                    {t(
                                                      'PREFORMANCE.VIEW_CALENDAR'
                                                    )}
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        </td>
                                      ))
                                    );
                                  }
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr className={`${!data?.length && 'h-[20vh]'}`}>
                            <td className="flex justify-center border-b border-disableGray h-56 items-center text-light-black font-general rtl:font-Almarai font-normal">
                              <NoData />
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr className="">
                          <td className="flex justify-center h-56   w-full items-center text-light-black font-general rtl:font-Almarai font-normal">
                            <div className="loader"></div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <Pagination
            isLoading={loading}
            totalPagesNumber={totalPages && totalPages}
          /> */}
        </>
      }
    </>
  );
};

export default Table;

/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { paymentInfo } from '../coachSlices/paymentSlice';

interface Children {
  children: JSX.Element;
  user: string;
}

const ProtectedRoute = ({ children, user }: Children) => {
  const location = useLocation();
  const loginAuth = localStorage.getItem('token');
  const { isProcessingSubscription } = useSelector(paymentInfo);

  return loginAuth ? (
    children
  ) : (
    <Navigate replace to="/" state={{ path: location.pathname }} />
  );
};
export default ProtectedRoute;

/* eslint-disable react/react-in-jsx-scope */
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../clientComponents/buttons';
import Input from '../../clientComponents/inputs';
import { forgotPasswordValidationSchema } from '../../clientHelpers';
import { forgotPassword, getCaptcha } from '../../clientSlices/authentication';
import './index.css';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useDispatch<any>();
  const handleFormSubmit = async (data: { email: string }) => {
    await dispatch(forgotPassword({ ...data, history: navigate }));
  };
  useEffect(() => {
    dispatch(getCaptcha());
  }, []);
  const [t] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div
        className=" -z-10 h-screen w-full flex bg-no-repeat bg-cover "
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(21, 50, 67, 0.6), rgba(21, 50, 67, 0.6)),url(${'/assets/forgotPassword/forgot-password-bg.svg'})`,
        }}
      >
        <div className="login-container ">
          <h1 className="text-3xl font-bold font-satoshi rtl:font-Almarai text-secondary mt-4 mb-4 ">
            {t('FORGOT_PASSWORD.PASSWORD_RECOVERY')}
          </h1>
          <p className="paragraph mb-4">
            {' '}
            {params.get('user') === 'CLIENT'
              ? t('FORGOT_PASSWORD.PASSWORD_DESCRIPTION')
              : t('FORGOT_PASSWORD.PASSWORD_DESCRIPTION_COACH')}
          </p>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form>
                <Input
                  id="FormEmail"
                  type="email"
                  name="email"
                  placeholder={t('FORGOT_PASSWORD.EMAIL')}
                  onBlur={handleBlur}
                  error={touched.email && 1}
                  onChange={handleChange}
                  value={values.email}
                  errormessage={errors.email}
                />
                <div className="flex justify-center items-center mt-2">
                  <Button type="submit" className="btn-submit bg-primary">
                    {t('FORGOT_PASSWORD.RECOVER_PASSWORD')}
                  </Button>
                </div>
                <div className="flex justify-center items-center mt-2">
                  <Link to="/">{t('FORGOT_PASSWORD.CANCEL')}</Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

/* eslint-disable react/react-in-jsx-scope */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadImage } from '../coachSlices/packageSlice';
interface ImageProps {
  id: number;
  propClass: string;
  bgClass: string;
  bgImgUrl: string;
  onImageCLick?: () => void;
  imageNameClass?: string;
}
export const Image: FC<ImageProps> = ({
  id,
  propClass,
  bgClass,
  onImageCLick,
  bgImgUrl,
  imageNameClass,
}): JSX.Element => {
  const dispatch = useDispatch<any>();
  const [image, setImage] = useState('');
  const [imageName, setImageName] = useState('');

  const [imageLoader, setImageLoader] = useState(false);
  useEffect(() => {
    async function getImage() {
      setImageLoader(true);
      if (id && id !== 0) {
        try {
          const data = await dispatch(downloadImage(id));
          setImage(data.payload?.data[0]?.fileContent);
          setImageName(data.payload?.data[0]?.originalFileName);
          setImageLoader(false);
        } catch (err) {
          setImageLoader(false);
          setImage('');
          setImageName('');
        }
      } else {
        setImageLoader(false);
        setImage('');
      }
    }
    getImage();
  }, [id]);

  return imageLoader ? (
    <div className={`${bgClass}`}>
      <div className="loader" />
    </div>
  ) : image ? (
    <>
      {' '}
      <img
        onClick={onImageCLick}
        src={`data:image/png;base64,${image}`}
        alt=""
        className={`${propClass}`}
      />{' '}
      {imageNameClass ? (
        <span className={imageNameClass}>{imageName}</span>
      ) : null}
    </>
  ) : (
    <div className={`${bgClass}`}>
      <img src={bgImgUrl} alt="" />
    </div>
  );
};

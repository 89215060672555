import { Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paymentValidationSchema } from '../../clientHelpers';
import {
  createTokenPayment,
  payment,
  paymentTabby,
  registerTabby,
  resetErrorTabbyPaymentType,
} from '../../clientSlices/payment';
import { selectClientProfile } from '../../clientSlices/profile';
import Button from '../../coachComponents/buttons';
import { USER_TYPE } from '../../coachConstants/genericConstants';
import OverlayLoader from '../../components/overlayLoader';
import { useAppSelector } from '../../hooks';

const Payment = () => {
  const [t] = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { lang } = useAppSelector((state) => state.language);
  const { isTabbyPayment, isUrwayPayemt, messageErrorTabby } = useAppSelector(
    (state) => state.payment
  );

  useEffect(() => {
    getEmiDates();
    dispatch(resetErrorTabbyPaymentType(''));
  }, []);
  const [dates, setDates] = useState<any>([]);
  const getEmiDates = () => {
    const emiDates: Date[] = [];
    const today = new Date();
    for (let i = 0; i < 4; i++) {
      const emiDate = new Date(
        today.getFullYear(),
        today.getMonth() + i,
        today.getDate()
      );
      emiDates.push(emiDate);
    }
    setDates((value: any) => (value = emiDates));
    return emiDates;
  };
  const localizer = momentLocalizer(moment);

  moment.locale(lang === 'en' ? 'en' : 'ar');
  const handleOrder = (values: any) => {
    switch (values.paymentType) {
      case 'URWAY':
        const data = {
          orderId: Number(searchParams.get('orderId')),
          paymentMethod: values.paymentType,
        };
        dispatch(payment({ data: data, navigate: navigate }));
        break;
      case 'tabbyInterestFree':
        const requesyBody = {
          description: 's',
          taxAmount: 0,
          referenceId: 's',
          orderId: Number(searchParams.get('orderId')),
          language: lang === 'en' ? 'eng' : 'ara',
          orderType:
            localStorage.getItem('userType') === 'COACH'
              ? 'SUBSCRIPTION'
              : 'COACH_SERVICES',
        };

        dispatch(paymentTabby({ data: requesyBody, navigate: navigate }));
        break;
      case 'tabbyPayFourteenDay':
        if (user.hasPaymentToken) {
          const payload = {
            description: 'this is a description',
            referenceId: 'KUN',
            orderId: Number(searchParams.get('orderId')),
          };
          dispatch(
            createTokenPayment({ payload: payload, navigate: navigate })
          );
        } else {
          dispatch(registerTabby({ language: lang === 'en' ? 'eng' : 'ara' }));
        }
        break;
      case 'international':
        navigate(
          '/international?orderId=' + Number(searchParams.get('orderId'))
        );
        break;
      default:
        break;
    }
  };
  const userType = localStorage.getItem('userType');
  const [paymentMethodValue, setPaymentMethodValue] = useState('');

  const { profileData } = useAppSelector(selectClientProfile);
  const { personalDetails } = useAppSelector((state: any) => state.profile);
  const user = userType === USER_TYPE.CLIENT ? profileData : personalDetails[0];
  return (
    <div className="py-16 px-5 md:px-[100px] flex flex-col sm:gap-12">
      <OverlayLoader loader={isTabbyPayment || isUrwayPayemt} />
      {paymentMethodValue === 'tabbyInterestFree' ? (
        <div className="flex flex-col gap-3">
          <div>
            <img
              className="sm_max:w-32 sm_max:h-14"
              src="/assets/payment/tabbyLogo.svg"
              alt=""
            />
          </div>
          <div className="flex sm_max:pb-5">
            <div className="flex flex-col justify-center items-center gap-1">
              <img
                className="sm_max:w-[145px] sm_max:h-14"
                src="/assets/shipping.svg"
                alt=""
              />
              <div className=" sm_max:text-14">shipping</div>
            </div>
            <div className="sm_max:mx-[-8px] mx-[-4px] flex flex-col justify-center items-center gap-1 ">
              <img
                className="sm_max:w-32 sm_max:h-14"
                src="/assets/review.svg"
                alt=""
              />
              <div className=" sm_max:text-14">Review and payments</div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="lg:text-48 text-28 font-bold font-satoshi rtl:font-Almarai sm_max:pb-8 ">
        {t('PAYMENT.PAYMENT_METHOD')}
      </div>

      <div className=" flex w-full ">
        <div className="w-full">
          <Formik
            initialValues={{ paymentType: '' }}
            // validations
            validationSchema={paymentValidationSchema}
            onSubmit={(values) => {
              handleOrder(values);
            }}
          >
            {(formik) => (
              <Form
                className="w-full flex justify-center"
                onSubmit={formik.handleSubmit}
              >
                <div className="flex flex-col  flex-grow w-full xl:w-[60vw] h-[70vh] justify-between  border border-lightBlack shadow-xl p-5 rounded-lg">
                  <div className=" flex flex-col flex-grow">
                    <div className="w-full bg-lightBlack flex justify-between p-5 rounded-lg font-satoshi rtl:font-Almarai  text-16 md:text-22 font-medium ">
                      <div>{t('PAYMENT.ORDER_SUMMARY')}</div>
                      <div>
                        {`${t('SAR')} `} {searchParams.get('amount')}
                      </div>
                    </div>
                    <div className="pt-6 flex gap-2 font-satoshi rtl:font-Almarai text-14 md:text-16 font-medium pb-4 border-b border-lightBlack">
                      <input
                        id="male"
                        type="radio"
                        value="URWAY"
                        name="paymentType"
                        onChange={(e) => {
                          formik.handleChange(e);
                          setPaymentMethodValue('URWAY');
                        }}
                        defaultChecked={formik.values.paymentType === 'URWAY'}
                      />
                      <label className="custom-control-label" htmlFor="male">
                        {t('PAYMENT.DEBIT_CREDIT')}
                      </label>
                    </div>
                    {
                      <div className="border-b border-lightBlack">
                        <div className="flex gap-2 font-satoshi rtl:font-Almarai text-14 md:text-16 font-medium pt-4 pb-5">
                          <input
                            id="female"
                            type="radio"
                            value="tabbyInterestFree"
                            name="paymentType"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setPaymentMethodValue('tabbyInterestFree');
                            }}
                            defaultChecked={
                              formik.values.paymentType === 'female'
                            }
                          />
                          <span>
                            <img
                              className="h-5"
                              src="/assets/payment/tabbyLogo.svg"
                              alt=""
                            />
                          </span>
                          <label
                            className="flex  gap-5 items-center"
                            htmlFor="female"
                          >
                            {t('PAYMENT.4_INTEREST_FREE')} <br />
                          </label>
                        </div>
                        <div className="text-grey px-5 pb-1">
                          {t('PAYMENT.ANY_CARD')}{' '}
                        </div>
                        {messageErrorTabby ? (
                          <div className="border border-[#e6d4d4] text-red px-4 py-2 rounded-lg bg-[#e6d4d4] m-6">
                            {messageErrorTabby}
                          </div>
                        ) : null}
                        <div className="flex flex-col w-fit gap-2 px-2 pb-6  ">
                          <div className="flex items-center  w-full px-1 smmd:px-4 sm:px-5">
                            <div>
                              <img
                                className="h-8"
                                src="/assets/payment/firstPayment.svg"
                                alt=""
                              />
                            </div>
                            <div className=" flex items-center flex-grow">
                              <div className="h-[2px] w-full bg-lightBlack mx-1"></div>
                            </div>

                            <div>
                              <img
                                className="h-8"
                                src="/assets/payment/secondPayment.svg"
                                alt=""
                              />
                            </div>

                            <div className=" flex items-center flex-grow">
                              <div className="h-[2px] w-full bg-lightBlack mx-1"></div>
                            </div>
                            <div>
                              <img
                                className="h-8"
                                src="/assets/payment/thirdPayment.svg"
                                alt=""
                              />
                            </div>

                            <div className=" flex items-center flex-grow">
                              <div className="h-[2px] w-full bg-lightBlack mx-1"></div>
                            </div>
                            <div>
                              <img
                                className="h-8"
                                src="/assets/payment/fourthPayment.svg"
                                alt=""
                              />
                            </div>
                          </div>

                          <div className="flex gap-6 ">
                            {dates?.map((data: any, index: number) => {
                              return (
                                <div className=" flex flex-col" key={index}>
                                  <div className="text-12 font-general rtl:font-Almarai font-bold text-center">
                                    {Number(searchParams.get('amount')) / 4}{' '}
                                    {t('SAR')}
                                  </div>
                                  <div className="text-13 general text-lightBlack flex justify-center ">
                                    {data.toLocaleDateString()}
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div className=" flex flex-col">
                                <div className="text-13 font-general rtl:font-Almarai font-bold text-center">
                                  {Number(searchParams.get('amount')) / 4} SAR
                                </div>
                                <div className="text-13 general text-lightBlack flex justify-center ">
                                  Today
                                </div>
                              </div> */}
                            {/* <div className=" flex flex-col">
                                <div className="text-13 font-general rtl:font-Almarai font-bold text-center">
                                  {Number(searchParams.get('amount')) / 4} SAR
                                </div>
                                <div className="text-13 general text-lightBlack flex justify-center ">
                                  Today
                                </div>
                              </div> */}
                            {/* <div className=" flex flex-col">
                                <div className="text-13 font-general rtl:font-Almarai font-bold text-center">
                                  {Number(searchParams.get('amount')) / 4} SAR
                                </div>
                                <div className="text-13 general text-lightBlack flex justify-center ">
                                  Today
                                </div>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    }
                    {userType === USER_TYPE.COACH ? (
                      <div className="pt-4 flex gap-2 font-satoshi rtl:font-Almarai text-14 md:text-16 font-medium pb-4 border-b border-lightBlack">
                        <input
                          id="international"
                          type="radio"
                          value="international"
                          name="paymentType"
                          onChange={(e) => {
                            formik.handleChange(e);
                            setPaymentMethodValue('international');
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="international"
                        >
                          {t('PAYMENT.INTERNATIONAL')}
                        </label>
                      </div>
                    ) : null}
                    {/* {Number(searchParams.get('amount')) >= 10 &&
                      Number(searchParams.get('amount')) <= 1500 && (
                        <div className="flex gap-2 font-satoshi rtl:font-Almarai text-14 md:text-16 font-medium border-y border-lightBlack py-4 ">
                          <input
                            id="female"
                            type="radio"
                            value="tabbyPayFourteenDay"
                            name="paymentType"
                            onChange={formik.handleChange}
                            defaultChecked={
                              formik.values.paymentType === 'female'
                            }
                          />
                          <label
                            className="flex  gap-5 items-center"
                            htmlFor="female"
                          >
                            Pay in 14 days{' '}
                            <span>
                              <img
                                className="h-5"
                                src="/assets/payment/tabbyLogo.svg"
                                alt=""
                              />
                            </span>
                          </label>
                        </div>
                      )} */}
                    <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
                      {t(formik.errors.paymentType as string)}
                    </p>
                  </div>
                  <div className="w-full flex justify-center ">
                    <Button
                      type={'submit'}
                      children={
                        <div className=" bg-primary flex  py-3 px-5 text-white text-16 mg:text-22 font-bold font-satoshi rtl:font-Almarai rounded">
                          {t('PAYMENT.COMPLETE_ORDER')}
                        </div>
                      }
                    />{' '}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Payment;

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { USER_TYPE } from '../coachConstants/genericConstants';
import { paymentInfo } from '../coachSlices/paymentSlice';

const IndexRoute = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');
  const { purchased } = useSelector(paymentInfo);

  return token ? (
    <Navigate
      to={
        userType === USER_TYPE.CLIENT
          ? '/client/profile'
          : purchased
          ? '/coach/home'
          : '/coach/subscription'
      }
    />
  ) : (
    <Navigate to="/" />
  );
};

export default IndexRoute;

import { sessionPayloadInterface } from '../coachInterfaces/sessionInterface';
import { env } from '../config/env';
import { post, postFile, postImageFileWithToken, put } from './apiService';

export const createAssessmentApi = async (data: sessionPayloadInterface) => {
  return await post('/assessments', data);
};
export const editAssessmentApi = async (
  data: sessionPayloadInterface,
  id: string
) => {
  return await put('/assessments/' + id, data);
};
export const saveDraftAssessmentApi = async (
  data: {
    draftType: string;
    request: sessionPayloadInterface;
  },
  id: string
) => {
  return await put('/coach-service-draft/' + id, data);
};
export const saveAssessmentApi = async (data: {
  draftType: string;
  request: sessionPayloadInterface;
}) => {
  return await post('/coach-service-draft', data);
};
export const ImageUploadAssessment = async (payload: any) => {
  return await postImageFileWithToken('/files', payload);
};
export const fileUploadRegistration = async (payload: any) => {
  return await postFile(`${env.development.BASE_URL}/files`, payload);
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logOutUser } from '../../clientSlices/authentication';
import {
  postUserRating,
  setRatingModalCoachOpen,
  setRatingModalKunOpen,
  setRatingModalServiceOpen,
} from '../../clientSlices/ratingModalSlice';
import { TextArea } from '../../coachComponents';
import { useAppSelector } from '../../hooks';
import Button from '../buttons';
interface IClientProps {
  sessionResults?: any;
  rating: string;
  coachByIdData?: any;
  show: boolean;
}
const RatingInput = ({
  show,
  sessionResults,
  rating,
  coachByIdData,
}: IClientProps) => {
  const { isProcessingRating } = useAppSelector((state) => state.rating);
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const dispatch = useDispatch<any>();
  const [ratingCount, setRatingCount] = useState<any>(0);
  const [description, setDescription] = useState<any>('');
  const [isSubmitted, setIsSubmitted] = useState<any>(false);
  const [t] = useTranslation('common');
  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (description) {
      if (rating === 'SERVICE') {
        dispatch(
          postUserRating({
            reviewType: sessionResults?.serviceType,
            rating: ratingCount,
            serviceId: sessionResults?.id,
            description: description,
          })
        );
      } else if (rating === 'COACH') {
        dispatch(
          postUserRating({
            reviewType: 'COACH',
            rating: ratingCount,
            coachId: coachByIdData?.id,
            description: description,
          })
        );
      } else if (rating === 'KUN') {
        await dispatch(
          postUserRating({
            reviewType: 'KUN',
            rating: ratingCount,
            description: description,
          })
        );
        dispatch(logOutUser({ history: navigate }));
      }
    }
  };
  const ratingData = [
    { value: 0, color: '#B72224' },
    { value: 1, color: '#D52029' },
    { value: 2, color: '#E95223' },
    { value: 3, color: '#EA6F22' },
    { value: 4, color: '#F6A726' },
    { value: 5, color: '#FDC729' },
    { value: 6, color: '#EBDB0A' },
    { value: 7, color: '#E5E044' },
    { value: 8, color: '#C2D234' },
    { value: 9, color: '#AEC93C' },
    { value: 10, color: '#66B44E' },
  ];

  return show ? (
    <div className=" fixed top-0 left-0 right-0 bottom-0 bg-lightBlack   flex items-center justify-center z-50">
      <div className=" p-[30px] lg:max-w-[78%]  xl:max-w-[55vw] max-w-[90vw] lg:max-h-[70%]  bg-white rounded flex-col px-6 lg:mt-9 mt-20 mb-10 relative">
        <h1 className="font-bold md:font-semibold text-14 md:text-16 md:font-general  font-satoshi rtl:font-Almarai">
          {t('HOW_MUCH')}
          {rating === 'SERVICE'
            ? t('SERVICE')
            : rating === 'SERVICE'
            ? t('COACH')
            : t('KUN_PLATFORM')}
          {t('YOUR_FRIENDS')}
        </h1>
        <div className="pt-4 w-fit">
          <div className="flex justify-between font-normal text-11 text-gray font-general rtl:font-Almarai">
            <div>{t('NOTATALL_LIKELY')}</div>
            <div className="md:block hidden rtl:font-Almarai">
              {' '}
              {t('EXTREME_LIKELY')}
            </div>
          </div>
          <div className="pt-[10px] text-11 font-general rtl:font-Almarai ">
            <ul className="flex  md:flex-row flex-wrap gap-[10px] font-nunito font-bold text-22">
              {ratingData.map((item) => (
                <li>
                  <div
                    onClick={() => setRatingCount(item.value)}
                    style={{
                      backgroundColor:
                        item.value <= ratingCount ? '#00a5ec' : item.color,
                    }}
                    className={`relative w-[45px] cursor-pointer h-[45px] rounded flex text-white justify-center items-center `}
                  >
                    {item.value}
                    <span
                      className={`${
                        item.value !== 10
                          ? 'hidden'
                          : 'md:hidden absolute top-13  w-[100%]  font-normal text-11 text-gray font-general rtl:font-Almarai'
                      } inline-block whitespace-nowrap rtl:font-Almarai`}
                    >
                      {t('EXTREME_LIKELY')}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <h1 className="pt-[40px] md:pt-[15px] text-14 md:text-16 font-bold md:font-semibold font-satoshi rtl:font-Almarai md:font-general rtl:font-Almarai">
          {t('REVIEW_DESC')}
        </h1>
        {/* <div className="pt-[10px]  md:w-[660px] h-[119px]">
          <Input className="md:w-[660px] flex flex-grow w-full h-[119px] border border-disableGray" /> */}
        <TextArea
          id={`description`}
          type="text"
          rows={3}
          cols={50}
          maxLength={150}
          name={`description`}
          placeholder={t('EDUCATIONAL_DETAILS.DESCRIPTION_DETAILS')}
          optional={0}
          onChange={(e: any) => {
            var value = e.target.value;
            value = value.trim();
              setDescription(value);
          }}
          error={isSubmitted}
          errormessage={
            isSubmitted && description === ''
              ? t('PLEASE_FILL_DESCRIPTION')
              : undefined
          }
          value={description}
        />
        <div className="pt-[15px] gap-2">
          <Button
            onClick={handleSubmit}
            className={''}
            children={
              <div className="text-14 md:text-16 font-bold font-satoshi rtl:font-Almarai text-white bg-primary rounded py-[10px] w-[150px]">
                {isProcessingRating && <div className="loader" />}
                {t('EXPERIENCE.SUBMIT')}
              </div>
            }
          />
          <Button
            onClick={() => {
              if (rating === 'KUN') {
                dispatch(setRatingModalKunOpen(false));
                dispatch(logOutUser({ history: navigate }));
              } else {
                dispatch(setRatingModalCoachOpen(false));
                dispatch(setRatingModalServiceOpen(false));
              }
            }}
            className={''}
            children={
              <div className="text-14 md:text-16 font-bold font-satoshi rtl:font-Almarai text-primary bg-white border-primary mx-3  rounded py-[10px] w-[150px]">
                {t('EXPERIENCE.REMIND')}
              </div>
            }
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default RatingInput;

import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { clientAuthenticationReducer } from './clientSlices/authentication';
import { conatctUSReducer } from './clientSlices/conatctUs';
import { clientHomeReducer } from './clientSlices/home';
import { landingPageReducer } from './clientSlices/landingPageSlice';
import { messageReducer } from './clientSlices/messages';
import { modalReducer } from './clientSlices/modalSlice';
import { paymentReducer } from './clientSlices/payment';
import { clientProfileReducer } from './clientSlices/profile';
import { scheduleReducer } from './clientSlices/schedule';
import { assessmentInfoReducer } from './coachSlices/assessmentSlice';
import { coachHomeInfoReducer } from './coachSlices/coachHomeSlice';
import { passwordReducer } from './coachSlices/forgotPasswordSlice';
import { languageReducer } from './coachSlices/headerSlice';
import { myUploadInfoReducer } from './coachSlices/myUploadSlice';
import { packageInfoReducer } from './coachSlices/packageSlice';
import { paymentInfoReducer } from './coachSlices/paymentSlice';
import { accountSecurityReducer } from './coachSlices/profileSlices/accountSecurity';
import { bankingInfoReducer } from './coachSlices/profileSlices/bankingInfoSlice';
import { hoursAndServiceInfoReducer } from './coachSlices/profileSlices/hoursAndServices';
import { profileReducer } from './coachSlices/profileSlices/profileInfoSlice';
import { socialMediaReducer } from './coachSlices/profileSlices/socialMediaSlice';
import { tradeRegistrationReducer } from './coachSlices/profileSlices/tradeRegistrationSlice';
import { registrationDetails } from './coachSlices/registrationSlice';
import { sessionInfoReducer } from './coachSlices/sessionSlice';
import { alertReducer } from './slice/alert';
import { performanceInfoReducer } from './coachSlices/coachPerformance';
import { ratingReducer } from './clientSlices/ratingModalSlice';

const reducer = combineReducers({
  clientAuthentication: clientAuthenticationReducer,
  landingPageInfo: landingPageReducer,
  contactUS: conatctUSReducer,
  profile: profileReducer,
  tradeRegistration: tradeRegistrationReducer,
  socialMedia: socialMediaReducer,
  bankingInfo: bankingInfoReducer,
  modalInfo: modalReducer,
  accountSecurity: accountSecurityReducer,
  password: passwordReducer,
  language: languageReducer,
  registration: registrationDetails,
  clientProfile: clientProfileReducer,
  alertInfo: alertReducer,
  sessionInfo: sessionInfoReducer,
  coachHomeInfo: coachHomeInfoReducer,
  packageInfo: packageInfoReducer,
  paymentInfo: paymentInfoReducer,
  performanceInfo: performanceInfoReducer,
  assessmentInfo: assessmentInfoReducer,
  myUploadInfo: myUploadInfoReducer,
  clientHome: clientHomeReducer,
  hoursAndServiceInfo: hoursAndServiceInfoReducer,
  schedule: scheduleReducer,
  messages: messageReducer,
  rating: ratingReducer,
  payment: paymentReducer,
});

const persistConfig = {
  key: 'root',
  whitelist: ['language'],
  storage,
};

const persistedReducer = persistReducer<RootReducer, AnyAction>(
  persistConfig,
  reducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type RootReducer = ReturnType<typeof reducer>;
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React from 'react';
import './App.css';
import Footer from './components/Footer';
import Navigation from './components/navigation';
import Alert from './components/toast';
import RouteContainer from './routes';
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize('G-YGM9BRRYPW');


  return (
    <React.Fragment>
      <Alert />
      <Navigation />
      <RouteContainer />
      <Footer />
     
    </React.Fragment>
  );
}

export default App;

import { get, put } from './api';

export const getScheduleList = async (data: any) => {
  return await get('/schedules', data);
};

export const getMeetingPasscodeID = async (data: any) => {
  return await get(`/schedules/my-schedules/${data}`);
};

export const getMeetingLink = async (data: any) => {
  return await get(`/meeting/join-meeting`, data);
};

export const goingMeeting = async (data: any, schedule: number) => {
  return await put(
    `/schedules/going/${schedule}?goingStatus=${data.goingStatus}`,
    {}
  );
};

import { FieldArray, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { getAccessToken } from '../../clientHelpers/localStorage';
import {
  checkTimeGap12FormatMin2,
  checkTimeGapMin2,
  get12FormatTimeGreater,
  getCoachingHoursValidationMin2,
  getDefaultValueHours,
  getTimeGreater,
  timeArrayGenerator,
  timeArrayGenerator24Format,
} from '../../clientHelpers/utility';
import { AccountListSideBar } from '../../coachComponents';
import { coachIntendValidationSchema } from '../../coachHelpers';
import { decodeUserId } from '../../coachHelpers/localStorage';
import {
  coachingHoursPayload,
  convertTime12to24,
  daySelection,
  localToUTC,
} from '../../coachHelpers/utility';
import {
  coachingHourRequestsInterface,
  listInterface,
} from '../../coachInterfaces/sessionInterface';
import {
  createHoursAndService,
  getHoursAndService,
  hoursAndServiceInfo,
  setHoursAndServiceFormData,
} from '../../coachSlices/profileSlices/hoursAndServices';
import {
  listCoachingTiming,
  sessionInfo,
} from '../../coachSlices/sessionSlice';
import OverlayLoader from '../../components/overlayLoader';
import { useAppSelector } from '../../hooks';
import './index.css';

interface IFaqCard {
  hoursFormRef: any;
}
const Component = () => {
  const Day = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  const [t] = useTranslation('common');
  const [toggle, setToggle] = useState(false);
  const hoursFormRef: any = useRef();
  const dispatch = useDispatch<any>();
  const { lang } = useAppSelector((state) => state.language);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const [alternativeState, setAlternativeState] = useState(false);
  const { coachingHoursAndServiceFormValue, isProcessingMyCoachUploads } =
    useSelector(hoursAndServiceInfo);
  console.log(
    'coachingHoursAndServiceFormValue',
    coachingHoursAndServiceFormValue
  );
  const { timing } = useSelector(sessionInfo);
  let timingEn: { label: string; value: string; label2: string }[] = [];

  timing?.map((item: listInterface) => {
    timingEn.push({
      label: item?.nameInEnglish,
      value: item?.nameInEnglish,
      label2: item?.nameInArabic,
    });
  });
  const localTime = [
    {
      label: t('AM'),
      value: 'AM',
      // label2: 'ص',
    },
    {
      label: t('PM'),
      value: 'PM',
      // label2: 'م',
    },
  ];
  const weekDays = [
    {
      label: t('COACH_SESSION.SUNDAY'),
      value: 'SUNDAY',
    },
    {
      label: t('COACH_SESSION.MONDAY'),
      value: 'MONDAY',
    },
    {
      label: t('COACH_SESSION.TUESDAY'),
      value: 'TUESDAY',
    },
    {
      label: t('COACH_SESSION.WEDNESDAY'),
      value: 'WEDNESDAY',
    },
    {
      label: t('COACH_SESSION.THURSDAY'),
      value: 'THURSDAY',
    },
    {
      label: t('COACH_SESSION.FRIDAY'),
      value: 'FRIDAY',
    },
    {
      label: t('COACH_SESSION.SATURDAY'),
      value: 'SATURDAY',
    },
  ];
  const [checkedState, setCheckedState] = useState(
    new Array(weekDays.length).fill(false)
  );
  const handleOnChange = (
    position: number,
    setFieldValue: any,
    value: string
  ) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    if (
      coachingHoursAndServiceFormValue.coachingHourAndServiceRequests.length > 0
    ) {
      if (
        coachingHoursAndServiceFormValue.coachingHourAndServiceRequests[
          position
        ].day === ''
      ) {
        setFieldValue(`coachingHourAndServiceRequests.${position}.day`, value);
      } else {
        setFieldValue(`coachingHourAndServiceRequests.${position}.day`, '');
      }
    }

    setCheckedState(updatedCheckedState);
  };
  useEffect(() => {
    if (
      coachingHoursAndServiceFormValue?.coachingHourAndServiceRequests?.length >
      0
    ) {
      let updatedCheckedState: any = [];

      coachingHoursAndServiceFormValue.coachingHourAndServiceRequests.map(
        (item: any, index: number) => {
          updatedCheckedState.push(
            item.day === null || item.day === '' ? false : true
          );
        }
      );
      setCheckedState(updatedCheckedState);
    }
  }, [coachingHoursAndServiceFormValue]);
  const onAlternateChange = (setFieldVlaue: any, name: string) => {
    setFieldVlaue(name, !alternativeState);
    setAlternativeState(!alternativeState);
  };
  useEffect(() => {
    dispatch(listCoachingTiming());
    dispatch(getHoursAndService(decodeUserId(getAccessToken() as string)));
  }, []);

  const handleFormSubmit = () => {
    let coachingHourAndServiceRequests: any = [];
    if (coachingHoursAndServiceFormValue?.alternative === true) {
      coachingHoursAndServiceFormValue?.coachingHourAndServiceRequests?.map(
        (item: coachingHourRequestsInterface) => {
          if (item?.day !== '') {
            coachingHourAndServiceRequests.push({
              day: daySelection(
                item?.day,
                convertTime12to24(`${item?.from} ${item?.localFromTime}`)
              ),
              startTime: localToUTC(
                convertTime12to24(`${item?.from} ${item?.localFromTime}`)
              ),
              endTime: localToUTC(
                convertTime12to24(`${item?.end} ${item?.locaEndTime}`)
              ),
            });
          }
        }
      );
    }
    if (coachingHoursAndServiceFormValue?.alternative === false) {
      coachingHoursPayload(coachingHoursAndServiceFormValue).map(
        (item: { day: string; startTime: string; endTime: string }) => {
          coachingHourAndServiceRequests.push({
            day: daySelection(item?.day, item?.startTime),
            startTime: localToUTC(item?.startTime),
            endTime: localToUTC(item?.endTime),
          });
        }
      );
    }

    if (getCoachingHoursValidationMin2(coachingHoursAndServiceFormValue)) {
      dispatch(
        createHoursAndService({
          daysAndTimeRequest: coachingHourAndServiceRequests,
        })
      );
    }
  };
  const [iserveLoading, setIseferLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIseferLoading(false);
    }, 1000);
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return iserveLoading ? (
    <div className="flex w-full h-[60vh] items-center justify-center ">
      <div className="loader" />
    </div>
  ) : (
    <div className="mt-6 lg:mt-12 w-full h-auto">
      <div className="bg-white mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar handleSubmit={() => handleFormSubmit()} />
        <div className=" lg:p-8 p-4 col-span-5 lg:col-span-4 lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.PROFILE_INFO.HOURS_SERVICES')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.PROFILE_INFO.HOURS_AND_SERVICE_DESCRIPTION')}
          </p>
          <div className="border border-info mb-8"></div>

          {isProcessingMyCoachUploads ? (
            <OverlayLoader loader={true} />
          ) : (
            <Formik
              innerRef={hoursFormRef}
              initialValues={coachingHoursAndServiceFormValue}
              validationSchema={coachIntendValidationSchema}
              onSubmit={() => console.log()}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => {
                dispatch(setHoursAndServiceFormData(values));

                return (
                  <>
                    {!values.alternative && (
                      <div className=" coaching-hr-container">
                        <div className=" coaching-hr-container-day">
                          <SelectDropdown
                            id="days"
                            isMulti={false}
                            isCreatable={false}
                            isSearchable={false}
                            // options={weekDaysList()}
                            options={timingEn}
                            optional={1}
                            dropDown={true}
                            name="days"
                            placeholder={t('COACH_SESSION.DAYS')}
                            onBlur={handleBlur}
                            isValue={values.days}
                            onChange={(opt: any) => {
                              setFieldValue('days', opt.value);
                            }}
                            className="my-3"
                            // defaultValue={{
                            //   label: getTimingLabel(values.days),
                            //   value: values.days,
                            // }}
                            getOptionLabel={(option: any) =>
                              lang === 'en' ? option.label : option.label2
                            }
                            defaultValue={
                              getDefaultValueHours(
                                values.days,
                                timingEn,
                                values.start
                              )[0]
                            }
                            controlShouldRenderValue={true}
                          />
                        </div>
                        <div className="w-full flex gap-3">
                          <div className="coaching-hr-container-hour">
                            <SelectDropdown
                              id="start"
                              isMulti={false}
                              isCreatable={false}
                              isSearchable={false}
                              options={timeArrayGenerator24Format(24)}
                              optional={1}
                              dropDown={true}
                              name="start"
                              placeholder={t('COACH_SESSION.START_TIME')}
                              onBlur={handleBlur}
                              onChange={(opt: any) => {
                                setFieldValue('start', opt.value);
                              }}
                              isValue={values.start}
                              className="my-3"
                              // value={{
                              //   value: utcToLocal(values.start),
                              //   label: utcToLocal(values.start),
                              // }}
                              defaultValue={() => {
                                setStartTime(values.start);
                                return {
                                  value: values.start,
                                  label: values.start,
                                };
                              }}
                              controlShouldRenderValue={true}
                            />
                          </div>
                          <div className="coaching-hr-container-hour">
                            <SelectDropdown
                              id="end"
                              isMulti={false}
                              isCreatable={false}
                              isSearchable={false}
                              options={timeArrayGenerator24Format(24)}
                              optional={1}
                              dropDown={true}
                              name="end"
                              placeholder={t('COACH_SESSION.END_TIME')}
                              onBlur={handleBlur}
                              onChange={(opt: any) => {
                                setFieldValue('end', opt.value);
                              }}
                              isValue={values.end}
                              className="my-3"
                              // value={{
                              //   value: utcToLocal(values.end),
                              //   label: utcToLocal(values.end),
                              // }}
                              defaultValue={() => {
                                setEndTime(values.end);
                                return {
                                  value: values.end,
                                  label: values.end,
                                };
                              }}
                              controlShouldRenderValue={true}
                              error={
                                !getTimeGreater(values.start, values.end) && 1
                              }
                              errormessage={' '}
                            />
                            {!getTimeGreater(values.start, values.end) ? (
                              <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 ">
                                {t('HOUR_VALIDATION')}
                              </p>
                            ) : (
                              !checkTimeGapMin2(values.start, values.end) && (
                                <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 ">
                                  {t('HOUR_GAP_MIN_MINUTE_VALIDATION')}
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="w-full font-general rtl:font-Almarai font-normal text-base mt-8 text-[#687E8E] flex items-center gap-3">
                      <label className="switch">
                        <input
                          name="alternative"
                          type="checkbox"
                          onChange={() =>
                            onAlternateChange(setFieldValue, 'alternative')
                          }
                          checked={values.alternative}
                        />
                        <span className="slider round"></span>
                      </label>
                      {t('COACH_SESSION.ALTERNATIVE_DAYS')}
                    </div>
                    {values.alternative && (
                      <FieldArray
                        name="coachingHourAndServiceRequests"
                        render={(arrayHelpers) => {
                          return (
                            <div className="flex flex-col gap-8 mt-8">
                              {weekDays.map(
                                (
                                  item: { label: string; value: string },
                                  i: number
                                ) => (
                                  <div className="w-full flex flex-row items-center  ">
                                    <>
                                      <input
                                        id={`coachingHourAndServiceRequests.${i}.day`}
                                        type="checkbox"
                                        name={`coachingHourAndServiceRequests.${i}.day`}
                                        onChange={() =>
                                          handleOnChange(
                                            i,
                                            setFieldValue,
                                            item.value
                                          )
                                        }
                                        value={item.value}
                                        checked={checkedState[i]}
                                        defaultChecked={true}
                                      />
                                      <span className=" sm:text-[16px] text-[14px] font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3 w-[25%]">
                                        {item.label}
                                      </span>
                                    </>
                                    <div className="hour-input-container">
                                      <div
                                        className={`hour-input-container-box `}
                                      >
                                        <SelectDropdown
                                          id={`coachingHourAndServiceRequests.${i}.from`}
                                          isMulti={false}
                                          isCreatable={false}
                                          options={timeArrayGenerator()}
                                          optional={1}
                                          dropDown={true}
                                          name={`coachingHourAndServiceRequests.${i}.from`}
                                          placeholder={t('COACH_SESSION.FROM')}
                                          onBlur={handleBlur}
                                          isValue={
                                            values
                                              ?.coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              ?.coachingHourAndServiceRequests[
                                              i
                                            ]?.from
                                          }
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `coachingHourAndServiceRequests.${i}.from`,
                                              opt.value
                                            );
                                          }}
                                          className="mb-3"
                                          defaultValue={{
                                            value:
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.from,
                                            label:
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.from,
                                          }}
                                          controlShouldRenderValue={true}
                                        />
                                      </div>
                                      <div className="hour-input-container-AM">
                                        <SelectDropdown
                                          id={`coachingHourAndServiceRequests.${i}.localFromTime`}
                                          isMulti={false}
                                          isCreatable={false}
                                          options={localTime}
                                          isSearchable={false}
                                          optional={1}
                                          dropDown={true}
                                          // getOptionLabel={(option: any) =>
                                          //   lang === 'en'
                                          //     ? option.label
                                          //     : option.label
                                          // }
                                          name={`coachingHourAndServiceRequests.${i}.localFromTime`}
                                          placeholder={''}
                                          isValue={
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.localFromTime
                                          }
                                          onBlur={handleBlur}
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `coachingHourAndServiceRequests.${i}.localFromTime`,
                                              opt.value
                                            );
                                          }}
                                          className="mb-3"
                                          defaultValue={{
                                            value:
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.localFromTime,

                                            label: t(
                                              `${
                                                values
                                                  .coachingHourAndServiceRequests
                                                  ?.length &&
                                                values
                                                  .coachingHourAndServiceRequests[
                                                  i
                                                ]?.localFromTime
                                              }`
                                            ),
                                          }}
                                          controlShouldRenderValue={true}
                                        />
                                      </div>
                                      <div className="hour-input-container-box">
                                        <SelectDropdown
                                          id={`coachingHourAndServiceRequests.${i}.end`}
                                          isMulti={false}
                                          isCreatable={false}
                                          options={timeArrayGenerator()}
                                          optional={1}
                                          dropDown={true}
                                          name={`coachingHourAndServiceRequests.${i}.end`}
                                          placeholder={t('COACH_SESSION.TO')}
                                          onBlur={handleBlur}
                                          isValue={
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.end
                                          }
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `coachingHourAndServiceRequests.${i}.end`,
                                              opt.value
                                            );
                                          }}
                                          className="mb-3"
                                          defaultValue={{
                                            value:
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.end,
                                            label:
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.end,
                                          }}
                                          controlShouldRenderValue={true}
                                          error={
                                            !get12FormatTimeGreater(
                                              `${
                                                values
                                                  .coachingHourAndServiceRequests
                                                  ?.length &&
                                                values
                                                  .coachingHourAndServiceRequests[
                                                  i
                                                ]?.from
                                              } ${
                                                values
                                                  .coachingHourAndServiceRequests
                                                  ?.length &&
                                                values
                                                  .coachingHourAndServiceRequests[
                                                  i
                                                ]?.localFromTime
                                              }`,
                                              `${
                                                values
                                                  .coachingHourAndServiceRequests
                                                  ?.length &&
                                                values
                                                  .coachingHourAndServiceRequests[
                                                  i
                                                ]?.end
                                              } ${
                                                values
                                                  .coachingHourAndServiceRequests
                                                  ?.length &&
                                                values
                                                  .coachingHourAndServiceRequests[
                                                  i
                                                ]?.locaEndTime
                                              }`
                                            ) && 1
                                          }
                                          errormessage={' '}
                                        />
                                        {!get12FormatTimeGreater(
                                          `${
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.from
                                          } ${
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.localFromTime
                                          }`,
                                          `${
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.end
                                          } ${
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.locaEndTime
                                          }`
                                        ) ? (
                                          <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 ">
                                            {t('HOUR_VALIDATION')}
                                          </p>
                                        ) : (
                                          !checkTimeGap12FormatMin2(
                                            `${
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.from
                                            } ${
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.localFromTime
                                            }`,
                                            `${
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.end
                                            } ${
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.locaEndTime
                                            }`
                                          ) && (
                                            <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 ">
                                              {t(
                                                'HOUR_GAP_MIN_MINUTE_VALIDATION'
                                              )}
                                            </p>
                                          )
                                        )}
                                      </div>
                                      <div className="hour-input-container-AM">
                                        <SelectDropdown
                                          id={`coachingHourAndServiceRequests.${i}.locaEndTime`}
                                          isMulti={false}
                                          isCreatable={false}
                                          options={localTime}
                                          // getOptionLabel={(option: any) =>
                                          //   lang === 'en'
                                          //     ? option.label
                                          //     : option.label2
                                          // }
                                          isSearchable={false}
                                          optional={1}
                                          dropDown={true}
                                          name={`coachingHourAndServiceRequests.${i}.locaEndTime`}
                                          placeholder={''}
                                          onBlur={handleBlur}
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `coachingHourAndServiceRequests.${i}.locaEndTime`,
                                              opt.value
                                            );
                                          }}
                                          className="mb-3"
                                          isValue={
                                            values
                                              .coachingHourAndServiceRequests
                                              ?.length &&
                                            values
                                              .coachingHourAndServiceRequests[i]
                                              ?.locaEndTime
                                          }
                                          defaultValue={{
                                            value:
                                              values
                                                .coachingHourAndServiceRequests
                                                ?.length &&
                                              values
                                                .coachingHourAndServiceRequests[
                                                i
                                              ]?.locaEndTime,
                                            label: t(
                                              `${
                                                values
                                                  .coachingHourAndServiceRequests
                                                  ?.length &&
                                                values
                                                  .coachingHourAndServiceRequests[
                                                  i
                                                ]?.locaEndTime
                                              }`
                                            ),
                                          }}
                                          controlShouldRenderValue={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          );
                        }}
                      />
                    )}
                  </>
                );
              }}
            </Formik>
          )}
          <div className=" grid lg:hidden mt-10">
            <button
              form="my-form"
              type="submit"
              onClick={() => handleFormSubmit()}
              className="  py-4 px-8 text-white font-satoshi rtl:font-Almarai font-bold bg-primary rounded"
            >
              {t('PROFILE.PROFILE_INFO.SAVE_CHANGE')}
            </button>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

const HoursServices = styled(Component)``;

export default HoursServices;

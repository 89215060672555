import { deleteApi, get } from './apiService';

export const fetchDrafts = async () => {
  return await get('/coach-service-draft');
};
export const deleteDraftApi = async (id: string) => {
  return await deleteApi('/coach-service-draft/' + id);
};
export const getDraftById = async (id: string) => {
  return await get('/coach-service-draft/' + id);
};
export const getServices = async (id: string) => {
  return await get('/coaches/count-of-my-services/'+ id);
};

/* eslint-disable react/react-in-jsx-scope */
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../coachComponents/buttons';
import { paymentInfo } from '../../coachSlices/paymentSlice';
import './index.css';

const CoachPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { isProcessingPayment, purchased } = useSelector(paymentInfo);
  const [t] = useTranslation('common');
  const [params] = useSearchParams();

  const handleSubCardClick = () => {
    const id = params.get('id');
    // if (id) dispatch(subscribeSelection({ id: id, navigate: navigate }));
  };

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
      </Helmet>
      <div className="h-screen w-full flex  justify-center items-center ">
        <div className="payment-card ">
          <div className="w-[40 vw] w-full h-full payment-card-inner ltr:border-none px-12 py-10 relative flex flex-col justify-between items-center">
            <span className="font-satoshi rtl:font-Almarai font-bold text-[28px]">
              Purchase the Pro plan
            </span>
            <div className="flex gap-1">
              <span className="font-general rtl:font-Almarai font-normal text-sm text-[#687E8E]">
                You're
              </span>
              <span className="font-general rtl:font-Almarai font-bold text-sm text-primary">
                saving $420
              </span>
              <span className="font-general rtl:font-Almarai font-normal text-sm text-[#687E8E]">
                by paying annually!
              </span>
            </div>
            <span className="font-satoshi rtl:font-Almarai font-bold text-[14px] text-[#687E8E]">
              Annually
            </span>
            <span className="font-satoshi rtl:font-Almarai font-medium text-[45px]">
              $499.00
            </span>
            <div className="flex gap-1">
              <span className="font-general rtl:font-Almarai font-bold text-sm text-[#687E8E]">
                + $0.00 sales tax
              </span>
              <span className="font-general rtl:font-Almarai font-normal text-sm text-[#687E8E]">
                per year
              </span>
            </div>

            <div className="h-[70px] payment-inner-bottom w-full flex justify-between items-center">
              <Button
                onClick={() => handleSubCardClick()}
                className="get-started-btn  btn-small mt-4 w-full"
              >
                {isProcessingPayment && <div className="loader absolute" />}
                subscribe
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoachPayment;

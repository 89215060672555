import AllInboxIcon from '@mui/icons-material/AllInbox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ChatContent from '../../clientComponents/messages/chatContent';
import ChatProfile from '../../clientComponents/messages/chatProfile';
import ChatList from '../../clientComponents/messages/chattingList';
import NewChat from '../../clientComponents/messages/newChat';
import { selectClientHome } from '../../clientSlices/home';
import {
  chatContent,
  messageLists,
  setRoomsAlert,
  setSocketObject,
} from '../../clientSlices/messages';
import { selectClientProfile } from '../../clientSlices/profile';
import { USER_TYPE } from '../../coachConstants/genericConstants';
import { useAppSelector } from '../../hooks';

const Messages = () => {
  const dispatch = useDispatch<any>();
  const [profileNav, setProfileNav] = useState('MEDIA');
  const [messageSelect, setMessageSelect] = useState({ name: '', roomId: '' });
  const [recieverId, setRecieverId] = useState('');
  const [message, setMessage] = useState('');
  const [profileView, setProfileView] = useState(false);
  const [mobileViewContent, setMobileViewContent] = useState('MSG');
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [t] = useTranslation('common');
  const search = searchParams.get('search');
  const id = searchParams.get('id');

  useEffect(() => {
    dispatch(messageLists(search as string));
  }, [searchParams]);
  const {
    messageListData,
    chatContentData,
    socketObject,
    roomId,
    roomAlertIds,
  } = useAppSelector((state) => state.messages);

  const { profileData } = useAppSelector(selectClientProfile);
  const { personalDetails } = useAppSelector((state) => state.profile);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  const { coachByIdData, clientByIdData } = useSelector(selectClientHome);
  const userType = localStorage.getItem('userType');

  const userData =
    userType === USER_TYPE.CLIENT ? coachByIdData : clientByIdData;
  const onHandleSendMessage = (e: any) => {
    e.preventDefault();
    if (message !== '' && userData.id) {
      const sendMessage = {
        recipientId: userData.id,
        message: message,
      };
      socketObject?.publish({
        destination: '/app/chat',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(sendMessage),
      });
      setMessage('');
    }
  };
  useEffect(() => {
    if (messageListData?.length && !roomId) {
      setMessageSelect({
        name: messageListData[0]?.receiverName,
        roomId: messageListData[0]?.chatId,
      });
      dispatch(chatContent({ id: messageListData[0]?.chatId, page: 0 }));
      setRecieverId(messageListData[0]?.receiverId);
      dispatch(
        setSocketObject({ key: 'roomId', value: messageListData[0]?.chatId })
      );
    }
  }, [messageListData]);

  useEffect(() => {
    if (roomId) {
      //  && page + 1 <= totalPages

      dispatch(chatContent({ id: roomId, page: page }));
    }
  }, [page, roomId]);
  const isNotNewUser = messageListData?.some(
    (item: any) => `${item.receiverId}` === id
  );
  useEffect(() => {
    if (isNotNewUser) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [isNotNewUser]);

  const array: string[] = [...roomAlertIds];

  useEffect(() => {
    if (roomId !== '') {
      const filtered = array.filter((value: string) => value != roomId);

      dispatch(setRoomsAlert([...filtered]));
    }
  }, [roomId]);
  return !id ? (
    <div className="mx-[20px] lg:mx-[100px] pt-14 pb-20 flex flex-col">
      {messageListData.length === 0 && !search ? (
        <div className="bg-gray-100 height-payment flex justify-center items-center">
          <div className="bg-white p-6  md:mx-auto ">
            <div className="text-center">
              <AllInboxIcon sx={{ color: '#38383840', fontSize: 100 }} />

              <>
                <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                  {t('NO_MESSAGE')}
                </h3>
              </>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="text-34 font-satoshi rtl:font-Almarai font-bold">
            {t('MESSAGES')}
          </div>
          <div className="pt-4 text-20 font-satoshi rtl:font-Almarai font-bold pb-12 text-gray">
            {t('YOU_HAVE')}&nbsp;
            {roomAlertIds?.length}
            &nbsp;
            {t('UNREAD')}
          </div>
          <div className=" gap-5 xll:flex hidden">
            <ChatList
              messageListData={messageListData}
              setMessageSelect={setMessageSelect}
              setRecieverId={setRecieverId}
              messageSelect={messageSelect}
              page={page}
            />
            <ChatContent
              setPage={setPage}
              page={page}
              messageListData={messageListData}
              setProfileView={setProfileView}
              chatContentData={chatContentData}
              profileData={
                userType === USER_TYPE.CLIENT ? profileData : personalDetails[0]
              }
              message={message}
              handleInputChange={handleInputChange}
              onHandleSendMessage={onHandleSendMessage}
              recieverId={recieverId}
              setMobileViewContent={setMobileViewContent}
            />
            {profileView && (
              <ChatProfile
                profileView={profileView}
                setProfileNav={setProfileNav}
                profileNav={profileNav}
              />
            )}
          </div>

          <div className="xll:hidden block">
            {mobileViewContent === 'MSG' && (
              <ChatList
                setMobileViewContent={setMobileViewContent}
                messageListData={messageListData}
                setMessageSelect={setMessageSelect}
                setRecieverId={setRecieverId}
                messageSelect={messageSelect}
                page={page}
              />
            )}
            {mobileViewContent === 'CHAT' && (
              <ChatContent
                setPage={setPage}
                page={page}
                setMobileViewContent={setMobileViewContent}
                messageListData={messageListData}
                setProfileView={setProfileView}
                chatContentData={chatContentData}
                profileData={
                  userType === USER_TYPE.CLIENT
                    ? profileData
                    : personalDetails[0]
                }
                message={message}
                handleInputChange={handleInputChange}
                onHandleSendMessage={onHandleSendMessage}
                recieverId={recieverId}
              />
            )}
            {mobileViewContent === 'PRO' && (
              <ChatProfile
                setMobileViewContent={setMobileViewContent}
                profileView={profileView}
                setProfileNav={setProfileNav}
                profileNav={profileNav}
              />
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <div className="mx-[20px] lg:mx-[100px] pt-14 pb-20 flex flex-col">
      <NewChat
        id={id}
        setRecieverId={setRecieverId}
        setMobileViewContent={setMobileViewContent}
      />
    </div>
  );
};

export default Messages;

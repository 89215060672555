import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logOutUser } from '../../clientSlices/authentication';
import { selectClientProfile } from '../../clientSlices/profile';
import { setRatingModalKunOpen } from '../../clientSlices/ratingModalSlice';
import { myUploadInfo } from '../../coachSlices/myUploadSlice';
import { useAppSelector } from '../../hooks';
import Tooltip from '../tooltip/input';
import './index.css';

const ProfileDropdownCard = ({ Options, togglefunction, toggle }: any) => {
  const popup: any = document.querySelector('.popup');
  const navigate = useNavigate();
  // dispatch(coachProfile());
  const { profileData } = useAppSelector(selectClientProfile);
  const { personalDetails } = useAppSelector((state) => state.profile);
  const { myCoachUploads } = useSelector(myUploadInfo);
  function hidePopup() {
    popup?.classList?.remove('open');
  }
  const dispatch = useDispatch<any>();
  const userType = localStorage.getItem('userType');
  const handleClick = async (item: string) => {
    switch (item) {
      case 'logout':
        if (
          userType === 'COACH' &&
          !personalDetails[0]?.isReviewed &&
          myCoachUploads?.length
        ) {
          await dispatch(setRatingModalKunOpen(true));
        } else if (userType === 'CLIENT' && !profileData?.isReviewed) {
          await dispatch(setRatingModalKunOpen(true));
        } else {
          dispatch(logOutUser({ history: navigate }));
        }
        break;
    }
  };
  return (
    <div className="w-60 h-fit bg bg-white absolute bottom-[-98px]   top-[100px] pt-2.5 rtl:xmd:left-[110px] ltr:xmd:right-[97px] ltr:right-0   rtl:left-0 ">
      {Options?.map((item: any, index: any) => {
        switch (item.type) {
          case 'Link':
            return (
              item.show && (
                <Link to={item.route}>
                  <div
                    key={`${index}-options `}
                    onClick={() => (handleClick(item.value), togglefunction())}
                    className={`py-4 pl-2 cursor-pointer items-center text-start text-base rtl:font-Almarai font-normal leading-[21px] text-darkGrey hover:bg-lightBlack rtl:pr-3 ${item.className}`}
                  >
                    {item.title}
                  </div>
                </Link>
              )
            );
          case 'help':
            return (
              <a
                href="https://chat.whatsapp.com/FRXgV2Jy8kF4yA85MlqfG0"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={`py-4 pl-2 cursor-pointer items-center text-start text-base rtl:font-Almarai font-normal leading-[21px] text-darkGrey hover:bg-lightBlack rtl:pr-3 ${item.className}`}
                >
                  {item.title}
                </div>
              </a>
            );
          case 'Button':
            return (
              item.show && (
                <Link
                  className={`${
                    item.title === 'Create new service' && 'xmd:hidden'
                  }`}
                  to={item.route}
                >
                  <div className="w-full p-3">
                    <button
                      onClick={() => (
                        handleClick(item.title), togglefunction()
                      )}
                      className={`bg-primary btn-small text-white w-[100%] `}
                    >
                      {item.title}
                    </button>
                  </div>
                </Link>
              )
            );
          case 'profile':
            return (
              <Link onClick={() => togglefunction()} to={item.route}>
                <div className="flex pl-2.5 pr-2.5 pb-2.5 border-b-[1px] border-b-lightBlack ">
                  <img
                    className=" max-w-[45px] object-cover h-[45px] rounded-[22px]"
                    src={item.path}
                    alt=""
                  />
                  <div className="flex flex-col ml-2.5 rtl:mr-2.5   ">
                    <div className="text-start text-xs  font-bold leading-[22px] text-darkGrey">
                      {item.name}
                    </div>
                    <div className="   font-normal leading-[18px] text-xs text-darkGrey  ">
                      <Tooltip
                        content={`${item.email}`}
                        children={
                          <div className="w-40 truncate text-black">{`${item.email}`}</div>
                        }
                      ></Tooltip>
                    </div>
                  </div>
                </div>
              </Link>
            );
        }
      })}
    </div>
  );
};

export default ProfileDropdownCard;

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteWishlistItem } from '../../clientSlices/home';
import { Image } from '../../components/Image';

interface ICarouselCardData {
  data: any;
  category?: string;
  isLoading: boolean;
}
const CarouselCard = ({ data, category, isLoading }: ICarouselCardData) => {
  const { t } = useTranslation('common');
  const [removeToggle, setRemoveToggle] = useState<any>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const handleDocumentClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setRemoveToggle(-1);
    }
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        removeToggle &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setRemoveToggle(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [removeToggle]);
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const deleteItem = (data: any) => {
    dispatch(
      deleteWishlistItem({
        serviceType: data.serviceType,
        serviceId: data.serviceId,
        searchParams: searchParams,
      })
    );
  };
  const navigate = useNavigate();
  const serviceView = (item: any) => {
    if (item.data.isDeleted) return;
    navigate(
      `/service-view?id=${
        item.data.serviceId ? item.data.serviceId : item.data.id
      }&serviceType=${item?.serviceType}S`
    );
  };

  return (
    <div>
      <div className={`${!isLoading && 'hidden'} flex flex-wrap gap-5`}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
          (item: any, index: number) => (
            <div
              key={index}
              className="flex flex-col w-[295px] shadow-lg rounded-lg cursor-pointer "
            >
              <div
                className={` ${
                  isLoading && 'loader_pulse w-full h-[165px] rounded'
                }`}
              ></div>

              <div className="p-3">
                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[50%] h-8  rounded'
                  }`}
                ></div>
                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[30%] h-4 rounded mt-1'
                  }`}
                ></div>
                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[65%] h-4 rounded mt-1'
                  }`}
                ></div>
                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[75%] h-4 rounded mt-1'
                  }`}
                ></div>
                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[65%] h-4 rounded mt-1'
                  }`}
                ></div>

                <div className="flex justify-between">
                  <div
                    className={` ${
                      isLoading && 'loader_pulse w-[20%] h-6 rounded mt-1'
                    }`}
                  ></div>

                  <div
                    className={` ${
                      isLoading && 'loader_pulse w-[30%] h-8 rounded mt-1'
                    }`}
                  >
                    {' '}
                  </div>
                </div>
              </div>
            </div>
          )
        )}{' '}
      </div>
      <ul className="flex flex-wrap gap-5">
        {data?.length
          ? data?.map((item: any, index: number) => (
              <li
                className="flex flex-col  rounded-xl   shadow-lg relative w-[295px] h-fit !mb-5 "
                key={`${item?.data?.coachName + index}`}
              >
                <div
                  className={`${
                    item.data.isDeleted
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => serviceView(item)}
                >
                  {' '}
                  <Image
                    id={item?.data?.imageId || item?.data?.imgId}
                    bgImgUrl={'/assets/default-img-icon.svg'}
                    bgClass={
                      ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[165px]'
                    }
                    propClass={'w-full h-[165px] rounded-t'}
                  />
                </div>
                <div
                  ref={modalRef}
                  onClick={() =>
                    setRemoveToggle(
                      `${item?.data?.id}${item?.data?.serviceType}`
                    )
                  }
                  className={`${
                    category !== 'wishlist' && 'hidden'
                  } absolute top-2 ltr:right-2 rtl:left-2 cursor-pointer w-fit`}
                >
                  <MoreVertOutlinedIcon sx={{ color: 'darkgray' }} />
                </div>
                {removeToggle ===
                  `${item?.data?.id}${item?.data?.serviceType}` && (
                  <div
                    ref={modalRef}
                    className="absolute top-8 rounded ltr:pl-2 rtl:pr-2 ltr:right-4 rtl:left-4 py-1 w-[100px] bg-white shadow-md cursor-pointer"
                    onClick={() => {
                      deleteItem(item);
                      setRemoveToggle(false);
                    }}
                  >
                    {t(`REMOVE`)}
                  </div>
                )}
                <div
                  className={`p-3 flex flex-col gap-1 ${
                    item.data.isDeleted
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                  }`}
                  onClick={() => serviceView(item)}
                >
                  <div className="text-18 font-bold capitalize font-satoshi rtl:font-Almarai ">
                    {item?.data?.title}
                  </div>
                  <div className="text-12 font-normal font-general rtl:font-Almarai  capitalize text-darkGrey ">
                    {item?.data?.subTitle}
                  </div>
                  <div className="text-12 font-semibold capitalize font-general rtl:font-Almarai  ">
                    {item?.data?.coachName}
                  </div>

                  {/* <div className="flex flex-row justify-start items-center">
                    <div className="text-[#BC455E] font-semibold text-xs pr-1 font-general rtl:font-Almarai flex pt-1  h-full">
                      {item.data.rating}
                    </div>

                    <Rating value={item.data.rating} />

                    <span className="text-12 font-normal font-general rtl:font-Almarai  text-disableGray">
                      {' '}
                      ( {item.data.ratingCount} ratings)
                    </span>
                  </div> */}
                  <div className="text-12 font-normal font-general rtl:font-Almarai text-disableGray">
                    {item?.duration ? `${item?.duration} ${t('MINS')}` : null}
                  </div>
                  <div className="flex justify-between">
                    <div className="text-24 font-bold font-satoshi rtl:font-Almarai ">
                      {t('SAR')}&nbsp;{item?.data?.price}
                    </div>
                    {item?.data?.bestSeller ? (
                      <div className="bg-lightBlue items-center flex text-white text-12 font-bold p-2  rounded">
                        {t(`CLIENT_SESSION_VIEW.BEST_SELLER`)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default CarouselCard;

import { FC, useState } from 'react';
import styled from 'styled-components';
import { IFaqContent } from '../../coachInterfaces/subscriptionInterface';
interface IFaqCard {
  item: IFaqContent;
}
const Component: FC<IFaqCard> = ({ item }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={isOpen ? 'faq-card max' : 'faq-card '}>
      <div className="w-full flex  justify-between items-center ">
        Question 1
        {isOpen ? (
          <img
            onClick={() => setIsOpen(!isOpen)}
            src="/assets/subscriptionPage/plusIcon.svg"
            // className={
            //   isOpen ? 'rotate cursor-pointer' : 'rotate down cursor-pointer'
            // }
            alt=""
          />
        ) : (
          <img
            onClick={() => setIsOpen(!isOpen)}
            src="/assets/subscriptionPage/crossIcon.svg"
            // className={
            //   isOpen ? 'rotate cursor-pointer' : 'rotate down cursor-pointer'
            // }
            alt=""
          />
        )}
      </div>
      <div
        className={
          isOpen
            ? 'subscription-page-card-desc pt-6 faq-desc '
            : 'subscription-page-card-desc pt-6 faq-desc faq-desc-hidden pt-0'
        }
      >
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
        sint. Velit officia consequat duis{' '}
      </div>
    </div>
  );
};
const FaqCard = styled(Component)``;

export default FaqCard;

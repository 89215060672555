import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ISelectInterface } from '../../coachInterfaces/inputInterFace/selectDropdownInterface';
import './index.css';

const SelectDropdown = ({
  ref,
  dropDown,
  onInputChange,
  controlShouldRenderValue,
  options,
  onBlur,
  onChange,
  inputValue,
  isValue,
  errormessage,
  isCreatable,
  optional,
  error,
  placeholder,
  isMulti,
  isSearchable,
  defaultValue,
  getOptionLabel,
  name,
  id,
  inn,
  iconValue,
  isDisabled,
  labelClass,
  className,
  grayOutline,
  ...rest
}: ISelectInterface) => {
  const attributes = {
    onInputChange,
    controlShouldRenderValue,
    options,
    onChange,
    onBlur,
    inputValue,
    defaultValue,
    getOptionLabel,
    optional,
    isValue,
    errormessage,
    isCreatable,
    error,
    name,
    inn,
  };
  const customStyles = {
    control: (base: any) => ({
      ...base,
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      padding: '3px',
      border: 'none',
      outline: !isCreatable
        ? 'none'
        : errormessage !== undefined && error
        ? '1px solid #bc455e'
        : !grayOutline
        ? '1px solid #687e8e'
        : '1px solid rgba(56, 56, 56, 0.25)',
      boxShadow: 'none',
    }),

    option: (styles: any, prop: any) => {
      return {
        ...styles,
        color: prop.isSelected ? 'white' : '#333333',
      };
    },
    dropdownIndicator: (base: any) => ({
      ...base,
      display: dropDown && 'none',
      color: '#687e8e',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#687e8e',
    }),
    placeholder: (base: any) => ({
      ...base,
      display: 'none',
    }),
  };
  const [t] = useTranslation('common');
  const selectRef: any = useRef(null);
  const handleClick = () => {
    selectRef?.current?.focus(); // Give focus to the Select component
    selectRef?.current?.openMenu(); // Open the dropdown menu
  };
  return isCreatable ? (
    <div className="relative cursor-pointer w-full parent h-auto ">
      <CreatableSelect
        className={`font-general rtl:font-Almarai text-var(--secondary) ${
          inn || inputValue ? 'lok' : ''
        } `}
        {...attributes}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={customStyles}
        isMulti={isMulti}
        // ref={ref}
        ref={selectRef}
        getOptionLabel={getOptionLabel}
      />
      <label
        onClick={handleClick}
        className={`input-text cursor-text font-general rtl:font-Almarai  text-gray text-17  font-normal text-opacity-80 bg-white absolute ltr:left-3 rtl:right-3 top-2.5 px-1 transition duration-200 label ${
          inn ? '' : 'ltr:pr-8 rtl:pl-8'
        }  `}
      >
        {placeholder}
        {optional ? (
          ''
        ) : (
          <span className="text-red rtl:font-Almarai cursor-text">*</span>
        )}
      </label>
      {error && (
        <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
          {t(errormessage)}
        </p>
      )}
    </div>
  ) : (
    <>
      <div
        // className={`relative parent w-full h-auto ${
        //   isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        // }`}
        className={` font-general rtl:font-Almarai relative parent flex flex-row h-fit items-center  border border-solid  rounded cursor-pointer
         parent text-var(--secondary)  
     
       placeholder-transperant
      ${className} ${
          errormessage !== undefined && error
            ? ' border border-solid border-red '
            : ' !border-[#687e8e]'
        } ${isDisabled && 'cursor-not-allowed bg-[#f1f1f1]'} ${
          iconValue && isValue
            ? 'registrationselect'
            : (isValue || inputValue) && !iconValue
            ? 'selectinput'
            : ''
        }`}
      >
        {iconValue}
        <Select
          id={id}
          ref={selectRef}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          className={`pl-[5px] ${
            iconValue ? 'w-[95%]' : 'w-[100%]'
          } !border-0  ${
            iconValue && isValue
              ? 'registrationselect'
              : (isValue || inputValue) && !iconValue
              ? 'selectinput'
              : ''
          } `}
          // className={`font-general rtl:font-Almarai  parent text-var(--secondary)  ${
          //   iconValue && 'indent-8 '
          // } placeholder-transperant ${
          // iconValue && isValue
          //   ? 'registrationselect'
          //   : (isValue || inputValue) && !iconValue
          //   ? 'selectinput'
          //   : ''
          // } ${className}`}
          {...attributes}
          styles={customStyles}
          isMulti={isMulti}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          getOptionLabel={getOptionLabel}
        />

        <label
          onClick={handleClick}
          htmlFor={id}
          className={`input-text 
        ${iconValue ? 'ml-7 rtl:!right-[40px]' : 'rtl:right-3'} 
        ${isDisabled ? 'cursor-not-allowed' : 'cursor-text'} 
        font-general rtl:font-Almarai  text-gray text-17  font-normal text-opacity-80 absolute ltr:left-3  top-2.5 px-1 transition duration-200 label 
        ${isValue ? '' : 'ltr:pr-12 rtl:pl-6 w-[60%]'} 
        ${!isDisabled && 'bg-white'} 
        ${labelClass}`}
        >
          {placeholder}
          {optional ? (
            ''
          ) : (
            <span className="text-red rtl:font-Almarai cursor-text">*</span>
          )}
        </label>
      </div>
      {error && (
        <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
          {t(errormessage)}
        </p>
      )}
    </>
  );
};

export default SelectDropdown;

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchChatContent,
  fetchMessagesList,
  getChatRoomToken,
} from '../clientServices/messages';
import { getMyChatParams, getMyMessageParams } from '../coachHelpers/query';
import { showAlert } from '../slice/alert';
import { RootState } from '../store';

interface IProfileViewSlice {
  isMessageLists: boolean;
  messageListData: any;
  isChatContent: boolean;
  chatContentData: any;
  isChatRoomConnect: boolean;
  chatRoomToken: any;
  socketObject: any;
  roomId: string;
  totalElements: number;
  totalPages: number;
  currentMessage: any;
  isMessageRecieved: any;
  roomAlertIds: string[];
}

const initialState: IProfileViewSlice = {
  isMessageLists: false,
  messageListData: [],
  isChatContent: false,
  chatContentData: [],
  socketObject: {},
  isChatRoomConnect: false,
  chatRoomToken: '',
  roomId: '',
  totalElements: 0,
  totalPages: 0,
  isMessageRecieved: '',
  currentMessage: '',
  roomAlertIds: [],
};
export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setSocketObject: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    },
    setCurrentMessage: (state, action: PayloadAction<any>) => {
      state.currentMessage = action.payload;
    },
    setIsMessageRecieved: (state, action: PayloadAction<string>) => {
      state.isMessageRecieved = action.payload;
    },
    setRoomsAlert: (state, action: PayloadAction<string[]>) => {
      state.roomAlertIds = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(messageLists.pending, (state) => {
        state.isMessageLists = true;
      })
      .addCase(messageLists.fulfilled, (state, action) => {
        state.messageListData = action.payload.data[0];
        state.isMessageLists = false;
      })
      .addCase(messageLists.rejected, (state, action: any) => {
        state.isMessageLists = false;
      })
      .addCase(chatContent.pending, (state) => {
        state.isChatContent = true;
      })
      .addCase(chatContent.fulfilled, (state, action) => {
        state.chatContentData = [
          ...state.chatContentData,
          ...action.payload.data[0].content,
        ];
        state.totalElements = action.payload.data[0]?.totalElements;
        state.totalPages = action.payload.data[0]?.totalPages;
        state.isChatContent = false;
      })
      .addCase(chatContent.rejected, (state, action: any) => {
        state.isChatContent = false;
      })
      .addCase(chatRoomConnect.pending, (state) => {
        state.isChatRoomConnect = true;
      })
      .addCase(chatRoomConnect.fulfilled, (state, action) => {
        state.chatRoomToken = action.payload.data[0];
        state.isChatRoomConnect = false;
      })
      .addCase(chatRoomConnect.rejected, (state, action: any) => {
        state.isChatRoomConnect = false;
      });
  },
});

export const messageLists = createAsyncThunk(
  'meesages/messageLists',
  async (param: string, thunkAPI) => {
    try {
      const params = getMyMessageParams(param as unknown as string);

      const response = await fetchMessagesList(params);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const chatContent = createAsyncThunk(
  'meesages/chatContent',
  async (payload: any, thunkAPI) => {
    try {
      const params = getMyChatParams(payload.page, 10);
      const response = await fetchChatContent(payload.id, params);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const chatRoomConnect = createAsyncThunk(
  'meesages/chatRoomConnect',
  async (id, thunkAPI) => {
    try {
      const response = await getChatRoomToken();
      return response;
    } catch (err: any) {
  
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const { setSocketObject, setRoomsAlert } = messageSlice.actions;
export const selectMessages = (state: RootState) => state.messages;
export const messageReducer = messageSlice.reducer;

import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactBigCalendar from '../../clientComponents/reactBigCalendar';
import { getTodaySchedules, selectSchedule } from '../../clientSlices/schedule';
import ScheduleCard from '../../coachComponents/HomePageComponents/ScheduleCard';
import { UtcDateToTime, scheduleDateToLocal } from '../../coachHelpers/utility';
import NoData from '../../components/noData';

const Schedule = () => {
  const dispatch = useDispatch<any>();
  useEffect(() => {
    const data = {
      startDate: moment(new Date()).locale('en').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('en').format('YYYY-MM-DD'),
    };
    dispatch(getTodaySchedules(data));
  }, []);
  const { scheduleTodayData, isProcessingSchedule } =
    useSelector(selectSchedule);
  const [t] = useTranslation('common');
  return (
    <div className="md:mx-[100px] mx-5">
      <h1 className="font-bold text-48 font-satoshi rtl:font-Almarai mt-[72px]">
        {t('LANDING_PAGE_AFTER_LOGIN.SCHEDULE')}{' '}
      </h1>
      <div className="flex pt-12 gap-5 mb-[71px] flex-col md:flex-row">
        <div className="pt-6 border border-lightBlack w-full md:w-[70%] rounded-xl">
          <ReactBigCalendar />
          {/* <CalendarWrapper /> */}
        </div>
        <div className="w-full md:w-[30%] border border-lightBlack rounded-xl">
          <div className="mx-4 py-4 text-18 font-bold font-satoshi rtl:font-Almarai border-b border-disableGray">
            {' '}
            {t('HOME.TODAYS_SCHEDULE')} &nbsp;{' '}
            {localStorage.getItem('timezone')}
          </div>
          <div className="pt-5 w-full overflow-y-auto  px-4">
            {scheduleTodayData.length
              ? scheduleTodayData.map(
                  ({ title, scheduledDate, startTime, endTime }: any) => (
                    <ScheduleCard
                      key={title + startTime}
                      title={title}
                      date={scheduleDateToLocal(startTime)}
                      time={`${UtcDateToTime(startTime)}-${UtcDateToTime(
                        endTime
                      )}`}
                    />
                  )
                )
              : null}
          </div>
          {isProcessingSchedule ? null : !scheduleTodayData.length ? (
            <div className="pt-5 w-full h-auto px-4">
              <div className="flex justify-center items-center h-full w-full ">
                {' '}
                <NoData />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Schedule;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import CoachMyUploadsCard from '../../coachComponents/MyUploadCard';
import { fetchMyUploads, myUploadInfo } from '../../coachSlices/myUploadSlice';
import NoData from '../../components/noData';
// import { randomCaps, randomFontSize } from '../../helpers/utility';
import { Helmet } from 'react-helmet';
import Button from '../../clientComponents/buttons';
import './index.css';

const Component = () => {
  const [t] = useTranslation('common');
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch<any>();
  const buttonList = [
    { title: 'Approved', value: 'APPROVED' },
    { title: 'Pending', value: 'PENDING' },
  ];
  const { myCoachUploads, isProcessingMyCoachUploads, deletedId } =
    useSelector(myUploadInfo);
  useEffect(() => {
    const params: { [key: string]: string } = {};
    searchParams &&
      searchParams.forEach((value: string, key: string) => {
        params[key] = value;
      });
    if (!params['filter']) setSearchParams({ filter: 'APPROVED' });
  }, [searchParams.get('filter')]);
  useEffect(() => {
    const search = searchParams.get('search');
    const filter = searchParams.get('filter');

    dispatch(
      fetchMyUploads({
        search: search,
        activityStatus: 'LIVE',
        requestStatusFilter: filter,
      })
    );
  }, [searchParams, deletedId]);
  const onHandleSearch = (e: any) => {
    e.preventDefault();
    const filter = searchParams.get('filter') as string;
    if (search !== null) setSearchParams({ filter,search });
  };
  const getUploadCardLoader = () => {
    return (
      <div className="w-full h-[120px] rounded-lg shadow-lg min-h-[325px] h-[100%] relative ">
        <div className="content-loader rounded-t h-40"></div>
        <div className="w-full h-[160px]  p-3 flex flex-col justify-between">
          <div className="content-loader rounded w-[30%] h-4"></div>

          <div className="content-loader rounded w-[40%] h-3"></div>
          <div className="content-loader rounded w-[45%] h-3"></div>
          <div className="content-loader rounded w-[70%] h-3"></div>
          <div className="content-loader rounded w-[70%] h-3"></div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>{t('KUN_COACH')}</title>
      </Helmet>
      <div className="w-full md:px-20 px-5 md:py-[56px] py-10">
        <div className="font-satoshi rtl:font-Almarai font-bold text-[34px] md:pb-12 pb-6">
          {t('MY_UPLOADS.MY_UPLOADS')}
        </div>
        <div className="flex gap-5 pb-5">
          {buttonList.map((item, index) => (
            <Button
              className={''}
              children={
                <div
                  onClick={() => setSearchParams({ filter: item.value })}
                  className={` font-general rtl:font-Almarai text-16 rounded py-2 px-2 border ${
                    searchParams.get('filter') === item.value
                      ? 'bg-primary text-white border-primary '
                      : 'text-black  border-disableGray shadow-lg '
                  } `}
                >
                  {t(`MY_UPLOADS.${item.value}`)}
                </div>
              }
            />
          ))}
        </div>
        <form>
          <div className=" my-upload-search-box ">
            <div className="w-[30px]  h-[44px] flex items-center justify-center">
              <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
                <img src="/assets/gridView/searchIcon.svg" id={search} alt="" />
              </button>
            </div>
            <input
              type="text"
              id="simple-search"
              className="text-gray-900 text-sm h-[42px] search-input  w-[85%] rtl:font-Almarai"
              placeholder={t('MY_UPLOADS.SEARCH_TXT')}
              required
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
            {search && (
              <img
                onClick={() => {
                  const filter = searchParams.get('filter') as string
                  setSearchParams({filter});
                  setSearch('');
                }}
                className="cursor-pointer"
                src="/assets/subscriptionPage/plusIcon.svg"
                alt="bell-icon"
              />
            )}
          </div>
        </form>

        {isProcessingMyCoachUploads ? (
          <div className=" my-upload-card-grid">
            {getUploadCardLoader()}
            {getUploadCardLoader()}
            {getUploadCardLoader()}
            {getUploadCardLoader()}
            {getUploadCardLoader()}
            {getUploadCardLoader()}
          </div>
        ) : myCoachUploads.length > 0 ? (
          <div className=" my-upload-card-grid">
            {myCoachUploads.map((item: any) => (
              <CoachMyUploadsCard
                item={item}
                filter={String(searchParams.get('filter'))}
              />
            ))}
          </div>
        ) : (
          <div className="w-full h-[70vh] flex justify-center items-center font-bold text-2xl">
            <NoData />
          </div>
        )}
      </div>
    </>
  );
};
const CoachMyUploads = styled(Component)``;

export default CoachMyUploads;

import './index.css';

const CustomRangeInput = ({ value, setValue, max }: any) => {
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(value * 100) / max}% 100%`,
    };
  };
  return (
    <div className="range-input flex relative w-full flex-col">
      <div className=" flex justify-between w-full px-5">
        <div className="">0</div>
        {value ? <div className="">{value}</div> : null}
        <div className="range-value">{max}</div>
      </div>
      <div className="pb-5">
        <input
          type="range"
          min="0"
          max={max}
          onChange={(e) => setValue(parseInt(e.target.value))}
          style={getBackgroundSize()}
          value={value}
          className={`sliderc`}
        />
        <img
          src="/assets/clientPrice/price-range.svg"
          className="absolute left-0 top-0 z-[-1] pt-[29px] pl-6"
          alt=""
        />
      </div>
    </div>
  );
};

export default CustomRangeInput;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks';
import './index.css';

const SearchWithDropdown = ({
  search,
  setSearchParams,
  setSelectedOption,
  selectedOption,
  setSearch,
  searchParams,
  setServiceType,
}: any) => {
  const options = [
    { value: 'SESSIONS', label: 'Sessions', label2: 'الجلسات' },
    { value: 'PACKAGES', label: 'Packages', label2: 'الباقات' },
    { value: 'ASSESSMENTS', label: 'Assessments', label2: 'التقييمات' },
    { value: 'COACHES', label: 'Coaches', label2: 'المدربين' },
  ];
  const { t } = useTranslation('common');
  const dispatch = useDispatch<any>();
  useEffect(() => {
    setSelectedOption(
      (value: any) => (value = searchParams.get('serviceType'))
    );
  }, []);
  const handleOptionChange = (option: string) => {
    setSelectedOption((value: string) => (value = option));
    setServiceType(option);
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value: any, key: any) => {
      temp[key] = value;
    });
    temp['page'] = '0';
    temp['size'] = '9';
    temp['serviceType'] = option;

    setSearchParams((value: any) => (value = temp));
  };

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value: any, key: any) => {
      temp[key] = value;
    });
    if (search !== null) temp['keyword'] = search;
    if (search !== null) setSearchParams((value: any) => (value = temp));
    // dispatch(setServiceType(selectedOption));
  };
  const { lang } = useAppSelector((state) => state.language);

  return (
    <div className=" w-full">
      <form onSubmit={handleSubmit}>
        <div className="relative">
          <select
            className="absolute left-2 top-[5.5px]  w-[129px] h-[34px] rounded  bg-white border text-13 text-gray font-normal font-general rtl:font-Almarai  px-3 border-lightBlack  focus:outline-none
"
            value={selectedOption}
            onChange={(e) => handleOptionChange(e.target.value)}
          >
            {options.map((option) => (
              <option
                key={`option-data-search-${option.value}`}
                value={option.value}
              >
                {lang === 'en' ? option.label : option.label2}
              </option>
            ))}
          </select>
          <input
            type="text"
            className={`border border-lightBlack w-full h-[46px] ${
              lang === 'en' ? 'indent-[172px]' : 'indent-[60px]'
            } rounded placeholder:text-13 placeholder-grey ltr:font-general rtl:font-Almarai  `}
            value={search}
            onChange={handleSearch}
            name={'hello'}
            placeholder={t(`SUBJECT_TITLE`)}
          />
          <div className="absolute left-[148px] top-4">
            <img
              src="/assets/search.svg"
              alt=""
              className="cursor-pointer"
              onClick={handleSubmit}
            />
          </div>
          {search && (
            <img
              onClick={() => {
                setSearchParams();
                setSearch('');
              }}
              className="cursor-pointer absolute right-[20px] top-4"
              src="/assets/subscriptionPage/plusIcon.svg"
              alt="bell-icon"
            />
          )}
        </div>
      </form>{' '}
    </div>
  );
};

export default SearchWithDropdown;

import moment from 'moment';
import {
  IassessmentPackageResponses,
  IsessionPackageResponses,
} from '../coachInterfaces/myUploadInterface';
import {
  assessmentValueArrayInterface,
  coachingHourPackagePayloadInterface,
  coachingHourPackageRequestsInterface,
  coachingHourResponseInterface,
} from '../coachInterfaces/packageInterface';
import {
  coachingHoursFormValueInterface,
  tagsInterface,
} from '../coachInterfaces/sessionInterface';

export function getRandomArbitrary(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export const randomFontSize = (captcha: any) => {
  const randomfontSizeString = [...captcha]
    .map((c) =>
      Math.random() < 0.6 ? c : c.fontsize(getRandomArbitrary(3, 6))
    )
    .join('');
  return {
    __html: randomfontSizeString,
  };
};
export const localToUTC = (localTimes: string) => {
  const localDate = new Date();
  const [hours, minutes] = localTimes.split(':');
  localDate.setHours(Number(hours), Number(minutes));
  const year = 2023;
  const month = 8; // Note: Months are 0-indexed (0 = January, 1 = February, etc.)
  const day = 13;
  const hour = Number(hours);
  const minute = Number(minutes);
  const second = 0;
  const millisecond = 0;

  // Create the UTC timestamp
  const utcTime = new Date(year, month, day, hour, minute, second, millisecond);

  // console.log(
  //   'new time stamp',
  //   moment.utc(utcTime.toISOString()).format('dddd')
  // );
  // var utc = new Date();
  // var offset = utc.getTimezoneOffset();
  // var local = utc.getTime() + offset * 60000;
  // console.log('offset', local);
  // const localTimes = '14:17:00';
  // Create a Date object with today's date and the local time

  // Get the UTC timestamp in milliseconds
  const utcTimestamp = localDate.getTime();
  // console.log('UTC Time:', utcDate.toISOString());
  // Create a new Date object with the UTC timestamp
  const utcDate = new Date(utcTimestamp);
  return moment.utc(utcDate.toISOString()).locale('en').format('HH:mm');
};
export const utcToLocal = (utcTime: string) => {
  const [hours, minutes, seconds] = utcTime.split(':');
  const utcDate = new Date();

  utcDate.setUTCHours(Number(hours));

  utcDate.setUTCMinutes(Number(minutes));

  const localDate = new Date(utcDate.getTime()).toISOString();

  // const localTimeString = localDate.toLocaleString();
  // var utc = new Date();
  // var offset = utc.getTimezoneOffset();
  // const newDate = new Date(utcDate.getTime() + offset * 60000);

  return moment(localDate).locale('en').format('HH:mm');
};
export const UtcDateToTime = (date: string) => {
  const localDate = new Date(`${date}Z`);
  return moment(new Date(localDate).toISOString()).locale('en').format('HH:mm');
};
export const daySelection = (dayValue: string, localTimes: string) => {
  const localDate = new Date();
  const [hours, minutes] = localTimes.split(':');
  localDate.setHours(Number(hours), Number(minutes));
  const year = 2023;
  const month = 7; // Note: Months are 0-indexed (0 = January, 1 = February, etc.)
  var day = 13;
  const hour = Number(hours);
  const minute = Number(minutes);
  const second = 0;
  const millisecond = 0;

  // Create the UTC timestamp
  switch (dayValue) {
    case 'MONDAY':
      day = day + 1;
      break;
    case 'TUESDAY':
      day = day + 2;
      break;
    case 'WEDNESDAY':
      day = day + 3;
      break;
    case 'THURSDAY':
      day = day + 4;
      break;
    case 'FRIDAY':
      day = day + 5;
      break;
    case 'SATURDAY':
      day = day + 6;
      break;
  }
  const utcTime = new Date(year, month, day, hour, minute, second, millisecond);
  console.log()
  const dayafter = moment.utc(utcTime.toISOString()).locale('en').format('dddd').toUpperCase();
  return dayafter;
};
export const sessionDaySelection = (dayValue: string, localTimes: string) => {
  const [startDay, endDay] = dayValue.split('-');
  const localDate = new Date();
  const [hours, minutes] = localTimes.split(':');
  localDate.setHours(Number(hours), Number(minutes));
  const year = 2023;
  const month = 8; // Note: Months are 0-indexed (0 = January, 1 = February, etc.)
  var day = 13;
  const hour = Number(hours);
  const minute = Number(minutes);
  const second = 0;
  const millisecond = 0;

  // Create the UTC timestamp
  switch (dayValue) {
    case 'MONDAY':
      day = day + 1;
      break;
    case 'TUESDAY':
      day = day + 2;
      break;
    case 'WEDNESDAY':
      day = day + 3;
      break;
    case 'THURSDAY':
      day = day + 4;
      break;
    case 'FRIDAY':
      day = day + 5;
      break;
    case 'SATURDAY':
      day = day + 6;
      break;
  }
  const utcTime = new Date(year, month, day, hour, minute, second, millisecond);

  return `${daySelection(startDay, localTimes)}-${daySelection(
    endDay,
    localTimes
  )}`;
};
export const UtcToLocaldayConversion = (dayValue: string, utcTimes: string) => {
  const localDate = new Date();
  const [hours, minutes] = utcTimes.split(':');
  localDate.setHours(Number(hours), Number(minutes));
  const year = 2023;
  const month = 8; // Note: Months are 0-indexed (0 = January, 1 = February, etc.)
  var day = 13;
  const hour = Number(hours);
  const minute = Number(minutes);
  const second = `00`;
  const millisecond = 0;

  // Create the UTC timestamp
  switch (dayValue.toUpperCase()) {
    case 'MONDAY':
      day = day + 1;
      break;
    case 'TUESDAY':
      day = day + 2;
      break;
    case 'WEDNESDAY':
      day = day + 3;
      break;
    case 'THURSDAY':
      day = day + 4;
      break;
    case 'FRIDAY':
      day = day + 5;
      break;
    case 'SATURDAY':
      day = day + 6;
      break;
  }
  const doubleDigitConversion = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  const utcTime = new Date(
    `${doubleDigitConversion(year)}-${doubleDigitConversion(
      month
    )}-${doubleDigitConversion(day)}T${doubleDigitConversion(
      hour
    )}:${doubleDigitConversion(minute)}:${second}Z`
  );

  return moment(utcTime).locale('en').format('dddd').toUpperCase();
};
export const utcToLocalDaySelection = (
  dayValue: string,
  localTimes: string
) => {
  const [startDay, endDay] = dayValue.split('-');

  return `${UtcToLocaldayConversion(
    startDay,
    localTimes
  ).toUpperCase()}-${UtcToLocaldayConversion(
    endDay,
    localTimes
  ).toUpperCase()}`;
};
export const scheduleDateToLocal = (date: string) => {
  return moment(new Date(`${date}Z`)).locale('en').format('Y-MM-DD');
};
export const utcDatetoLocalDate = (timeStamp: Date) => {
  const myDate = new Date(timeStamp).toLocaleDateString();
  return moment.utc(timeStamp.toISOString()).locale('en').format('Y-MM-DD');
};
// export const;
export const coachingHoursPayload = (
  coachingHoursFormValue: coachingHoursFormValueInterface
) => {
  let arr: {
    day: string;
    startTime: string;
    endTime: string;
  }[] = [];
  const week = [
    { label: 'Sunday', value: 'SUNDAY' },
    { label: 'Monday', value: 'MONDAY' },
    { label: 'Tueday', value: 'TUESDAY' },
    { label: 'Wednesday', value: 'WEDNESDAY' },
    { label: 'Thursday', value: 'THURSDAY' },
    { label: 'Friday', value: 'FRIDAY' },
    { label: 'Saturday', value: 'SATURDAY' },
  ];
  let [startDay, endDay] = coachingHoursFormValue?.days?.split('-');
  let startDayIndex: number = 0;
  let endDayIndex: number = 0;
  week.map((item: tagsInterface, index: number) => {
    if (startDay === item.label) {
      startDayIndex = index;
    }
    if (endDay === item.label) {
      endDayIndex = index;
    }
  });
  if (startDayIndex < endDayIndex) {
    week.map((item: tagsInterface, index: number) => {
      if (index >= startDayIndex && index <= endDayIndex) {
        arr.push({
          day: item.value,
          startTime: coachingHoursFormValue.start,
          endTime: coachingHoursFormValue.end,
        });
      }
    });
  } else {
    week.map((item: tagsInterface, index: number) => {
      if (
        (index <= endDayIndex && index <= startDayIndex) ||
        (index >= endDayIndex && index >= startDayIndex)
      ) {
        arr.push({
          day: item.value,
          startTime: coachingHoursFormValue.start,
          endTime: coachingHoursFormValue.end,
        });
      }
    });
  }
  return arr;
};
export const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};
export const formDataConverter = (input: any, fileType: string) => {
  const formData = new FormData();
  formData.append('file', input);
  formData.append('file-type', fileType);
  return formData;
};
const intendedClientsEditDataPayload = [
  'CEO/ C-Suite',
  'Vice President',
  'Sr Director',
  'Director',
  'Manager',
  'Team lead',
  'Individual Contributor',
  'Business Owner',
  'Entrepreneurs',
  'To Everyone',
  'Others',
];
export const getTagsFromAssessmentEdit = (editData: string[]) => {
  let missing = editData?.length
    ? editData.filter(
        (item) => intendedClientsEditDataPayload.indexOf(item) < 0
      )
    : [];
  let data = missing.map((item: string) => ({
    label: item,
    value: item,
  }));
  return data;
};
export const getAssessmentForEditPayload = (editData: string[]) => {
  const isValuePresent = (editValue: string) => {
    return (
      editData?.length && editData.some((value: string) => value === editValue)
    );
  };
  let data = intendedClientsEditDataPayload.map((item: string) => {
    if (isValuePresent(item)) return item;
    else return null;
  });

  return data;
};
export const thirdPartyEditPayloadCreator = (
  fileIds: number[],
  termsOfServiceUrl: string[]
) => {
  let data: { file: any; link: string }[] = [];

  if (fileIds?.length >= termsOfServiceUrl?.length) {
    fileIds.map((id: number, i: number) => {
      data.push({
        link: termsOfServiceUrl[i] ? termsOfServiceUrl[i] : '',
        file: id,
      });
    });
  }
  if (fileIds?.length < termsOfServiceUrl?.length) {
    termsOfServiceUrl.map((link: string, i: number) => {
      data.push({
        file: fileIds[i] ? fileIds[i] : 0,
        link: link,
      });
    });
  }

  return data;
};
export const isAlternative = (array: coachingHourResponseInterface[]) => {
  const allStartTimeEqual = (arr: coachingHourResponseInterface[]) =>
    arr?.length &&
    arr.every(
      (val: coachingHourResponseInterface) => val.startTime === arr[0].startTime
    );
  const allEndTimeEqual = (arr: coachingHourResponseInterface[]) =>
    arr?.length &&
    arr.every(
      (val: coachingHourResponseInterface) => val.endTime === arr[0].endTime
    );
  if (allStartTimeEqual(array) && allEndTimeEqual(array)) {
    return true;
  } else {
    return false;
  }
};
export const getHoursData = (array: coachingHourResponseInterface[]) => {
  const week = [
    { label: 'Sunday', value: 'SUNDAY' },
    { label: 'Monday', value: 'MONDAY' },
    { label: 'Tueday', value: 'TUESDAY' },
    { label: 'Wednesday', value: 'WEDNESDAY' },
    { label: 'Thursday', value: 'THURSDAY' },
    { label: 'Friday', value: 'FRIDAY' },
    { label: 'Saturday', value: 'SATURDAY' },
  ];
  const getHourAndMin = (time: string) => {
    let [hours, minutes, second] = time.split(':');
    return `${hours}:${minutes}`;
  };
  const getDayIndex = (day: string) => {
    let data: { index: number; label: string } = { index: 0, label: '' };
    week.map((item: { value: string; label: string }, index: number) => {
      if (day === item.value) {
        data = {
          index: index,
          label: item.label,
        };
      }
    });
    return data;
  };
  let dateArray: { index: number; label: string }[] = [];
  array.map((item: coachingHourResponseInterface) => {
    dateArray.push(getDayIndex(item.day));
  });
  var sortedArray: { index: number; label: string }[] = dateArray.sort(
    (obj1, obj2) => {
      if (obj1.index > obj2.index) {
        return 1;
      }

      if (obj1.index < obj2.index) {
        return -1;
      }

      return 0;
    }
  );
  let startDay: string = sortedArray[0].label;
  let endDay: string = sortedArray[sortedArray.length - 1].label;
  let startTime: string = getHourAndMin(array[0].startTime);
  let endTime: string = getHourAndMin(array[0].endTime);
  const payload = {
    days: `${startDay}-${endDay}`,
    start: startTime,
    end: endTime,
  };
  return payload;
};
export const convert24to12Item = (object: coachingHourResponseInterface) => {
  var options: any = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const week = [
    {
      day: 'SUNDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'MONDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'TUESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'WEDNESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'THURSDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'FRIDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
    {
      day: 'SATURDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
    },
  ];
  var startDate = new Date(`February 01, 2000 ${object?.startTime}`);
  var endDate = new Date(`February 01, 2000 ${object?.endTime}`);
  const [startTime, startLocalTime] = startDate
    .toLocaleString('en-US', options)
    .split(' ');
  const [endTime, endLocalTime] = endDate
    .toLocaleString('en-US', options)
    .split(' ');
  const addZero = (time: string) => {
    const [hour, second] = time.split(':');
    if (hour.length === 1) {
      return `0${hour}:${second}`;
    } else return time;
  };

  week.map((item: any) => {
    if (item.day === object?.day) {
      item.day = object?.day;
      item.from = addZero(startTime);
      item.localFromTime = startLocalTime;
      item.end = addZero(endTime);
      item.locaEndTime = endLocalTime;
    } else {
      item.day = '';
    }
  });

  return week;
};

export const convert24to12 = (array: coachingHourResponseInterface[]) => {
  var options: any = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const week = [
    {
      day: 'SUNDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'MONDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'TUESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'WEDNESDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'THURSDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'FRIDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
    {
      day: 'SATURDAY',
      from: '12:00',
      localFromTime: 'AM',
      end: '12:00',
      locaEndTime: 'PM',
      isPresent: false,
    },
  ];
  const responseArray = array.map((item: coachingHourResponseInterface) => {
    var startDate = new Date(`February 01, 2000 ${item.startTime}`);
    var endDate = new Date(`February 01, 2000 ${item.endTime}`);
    const [startTime, startLocalTime] = startDate
      .toLocaleString('en-US', options)
      .split(' ');
    const [endTime, endLocalTime] = endDate
      .toLocaleString('en-US', options)
      .split(' ');
    const addZero = (time: string) => {
      const [hour, second] = time.split(':');
      if (hour.length === 1) {
        return `0${hour}:${second}`;
      } else return time;
    };
    return {
      day: item.day,
      from: addZero(startTime),
      localFromTime: startLocalTime,
      end: addZero(endTime),
      locaEndTime: endLocalTime,
    };
  });

  week.map((item: coachingHourPackagePayloadInterface) => {
    responseArray.forEach((oItem: coachingHourPackageRequestsInterface) => {
      if (oItem.day === item.day) {
        item.day = oItem.day;
        item.from = oItem.from;
        item.localFromTime = oItem.localFromTime;
        item.end = oItem.end;
        item.locaEndTime = oItem.locaEndTime;
        item.isPresent = true;
      }
    });
  });
  week.map((item: coachingHourPackagePayloadInterface) => {
    if (!item.isPresent) {
      item.day = '';
    }
  });

  return week;
};
export const getAssessmentValuePayload = (
  assessmentPackageResponses: IassessmentPackageResponses[],
  sessionPackageResponses: IsessionPackageResponses[]
) => {
  let data: assessmentValueArrayInterface[] = [];
  assessmentPackageResponses?.length &&
    assessmentPackageResponses.map(
      (item: IassessmentPackageResponses, index: number) => {
        // if (index !== 0) {
        data.push({
          category: 'ASSESSMENT',
          assessmentId: item.assessmentId,
          // durationInWeek: item.durationInWeek,
          sessionId: '',
          // duration: '',
          // retakePeriod: '',
        });
        // }
      }
    );
  sessionPackageResponses?.length &&
    sessionPackageResponses.map(
      (item: IsessionPackageResponses, index: number) => {
        // if (index !== 0) {
        data.push({
          category: 'SESSION',
          assessmentId: '',
          // durationInWeek: '',
          sessionId: item.sessionId,
          // duration: item.duration,
          // retakePeriod: item.retakePeriod,
        });
        // }
      }
    );

  return data;
};
export const gePackageEditValuePayload = (array: any) => {
  let data: assessmentValueArrayInterface[] = [];
  array?.length &&
    array.map((item: any, index: number) => {
      if (item.serviceType === 'ASSESSMENT') {
        data.push({
          category: 'ASSESSMENT',
          assessmentId: item.id,
          // durationInWeek: item.duration,
          sessionId: '',
          // duration: '',
          // retakePeriod: '',
        });
      } else {
        data.push({
          category: 'SESSION',
          assessmentId: '',
          // durationInWeek: '',
          sessionId: item.id,
          // duration: item.duration,
          // retakePeriod: item.retakePeriod,
        });
      }
    });

  return data;
};

export function checkArray(array: any) {
  // Initialize the days and times arrays
  const days = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SUNDAY',
  ];
  const times: any = [];

  // Iterate over each item in the array
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    // Check if the day is in the list of days or not
    if (days.includes(item.day)) {
      // Check if startTime and endTime are the same
      if (
        item.startTime !== array[0].startTime ||
        item.endTime !== array[0].endTime
      ) {
        return false;
      }
      // Add the startTime to the times array if it's not already present
      if (!times.includes(item.startTime)) {
        times.push(item.startTime);
      }
    } else {
      return false;
    }
  }

  // Check if there are more than 1 distinct times in the array
  if (times.length > 1) {
    return false;
  }

  return true;
}

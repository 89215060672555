const LandingConstant = {
  CLIENT_NUMBER: '2652',
  COACH_NUMBER: '30',
  SERVICE_NUMBER: '3000+',
  ASSESSMENTS_NUMBER: '526',
  SOME_COUNT: 'Some count that matters',
  LOGIN: 'Login',
  SIGN_UP: 'Sign up',
  WE_WILL_PROVIDE: 'We will provide!',
  HEADING: 'Become an coach and change lives Get Started',
  GET_STARTED: 'Get Started',
  PARAGRAPH:
    'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcor',
  PARAGRAPH_ALL_IN_oNE:
    'Aliquam pulvinar vestibulum blandit. Donec sed nisl libero. Fusce dignissim luctus sem eu dapibus. Pellentesque vulputate quam a quam volutpat.',
  QUESTION_ANSWER:
    'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
  HOW_WE_HELP_YOU: 'How we help you!',
  COURTNEY_HENRY: '~~Courtney Henry',
  COMMON_QUESTIONS: 'Common Questions',
  NUMBER_OF_CLIENT: 'Number of clients',
  ACTIVE_COACHES: 'Active coaches',
  SERVICES_COACHES: 'Services hours',
  ASSESSMENTS_CONDUCTED: 'Assessments conducted',
  P20: 'Aliquam pulvinar vestibulum blandit. Donec sed nisl libero. Fusce dignissim luctus sem eu dapibus. Pellentesque vulputate quam a quam volutpat, sed ullamcorper erat commodo. Vestibulum sit amet ipsum vitae mauris mattis vulputate lacinia nec neque. Aenean quis consectetur nisi, ac interdum elit. Aliquam sit amet luctus elit, id tempus purus.',
  WHAT_OUR_CLIENTS_SAYS: 'What our clients says!',
  ACCORDION_PARAGRAPH:
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis ',
  OUR_PRICE: 'Our pricing made easy.',
  P15: 'In finibus purus sed tortor fringilla, eu luctus lorem sodales.Ut dignissim ante ac augue vulputate tristique. Mauris venenatis tincidunt nibh, sit amet fringilla augue malesuada a. Mauris a nunc congue.',
  EARLY: 'Early Starters',
  MOST_POPULAR: 'Most Popular',
  TIME_TO_SCALE: 'Time to Scale',
};

export default LandingConstant;

import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Image } from '../../components/Image';
import Tooltip from '../../components/tooltip/input';
interface IRating {
  data: any;
  serviceType?: string;
  isLoading?: boolean;
}

const CommonCarousal: FC<IRating> = ({
  data,
  serviceType,
  isLoading,
}): JSX.Element => {
  const [t] = useTranslation('common');
  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const priceWidthRef = useRef<HTMLDivElement>(null);
  const priceParentWidthRef = useRef<HTMLDivElement>(null);
  const [divWidth, setDivWidth] = useState<number | null>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [priceWidth, setPriceWidth] = useState<number | null>(null);
  const [priceParentWidth, setPriceParentWidth] = useState<number | null>(null);

  function Arrowss(props: any) {
    let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
    className += ' arrow';
    const char =
      props.type === 'next' ? (
        <img
          src={
            process.env.PUBLIC_URL + '/assets/bodyAfterLogin/rightArrowMark.svg'
          }
          className="absolute top-[4.25rem] right-[-16px] "
          alt=""
        ></img>
      ) : (
        <img
          src={
            process.env.PUBLIC_URL + '/assets//bodyAfterLogin/leftArrowMark.svg'
          }
          className="absolute top-[62px] left-[-26px]  z-10"
          alt=""
        ></img>
      );
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    initialSlide: 0,
    arrows: true,
    centerMode: false,
    className: '!w-[100%] !h-[50%]',

    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    nextArrow: <Arrowss type="next" />,
    prevArrow: <Arrowss type="prev" />,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1210,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  // const [updatedData, setUpdatedData] = useState([]);
  // useEffect(() => {
  //   let updatee = data;
  //   const maxSlidesToShow = 6;
  //   if (updatee?.length > 0) {
  //     while (updatee?.length < maxSlidesToShow) {
  //       updatee = updatee.concat(updatee);
  //     }
  //   }
  //   setUpdatedData((value) => (value = updatee));
  // }, [data]);
  const navigate = useNavigate();
  useEffect(() => {
    if (divRef.current) {
      const handleResize = () => {
        setDivWidth(divRef.current && divRef.current.clientWidth);
      };

      handleResize(); // Initial measurement
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const handleContainersize = () => {
        setContainerWidth(
          containerRef.current && containerRef.current.clientWidth
        );
      };

      handleContainersize(); // Initial measurement
      window.addEventListener('resize', handleContainersize);

      return () => {
        window.removeEventListener('resize', handleContainersize);
      };
    }
  }, []);
  useEffect(() => {
    if (priceWidthRef.current) {
      const handlepriceWidthRef = () => {
        setPriceWidth(
          priceWidthRef.current && priceWidthRef.current.clientWidth
        );
      };

      handlepriceWidthRef(); // Initial measurement
      window.addEventListener('resize', handlepriceWidthRef);

      return () => {
        window.removeEventListener('resize', handlepriceWidthRef);
      };
    }
  }, []);
  useEffect(() => {
    if (priceParentWidthRef.current) {
      const handlepriceParentWidth = () => {
        setPriceParentWidth(
          priceParentWidthRef.current && priceParentWidthRef.current.clientWidth
        );
      };

      handlepriceParentWidth(); // Initial measurement
      window.addEventListener('resize', handlepriceParentWidth);

      return () => {
        window.removeEventListener('resize', handlepriceParentWidth);
      };
    }
  }, []);
  return (
    <div className="flex flex-row mt-12 ">
      {isLoading && (
        <div className="flex overflow-hidden gap-4">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
            <div className="flex flex-col  min-w-[320px]   shadow-lg relative  !h-[320px] !mb-5 cursor-pointer">
              {/* <img className="w-full" src={item.image} alt="" /> */}
              <div className=" w-full  h-[165px] loader_pulse rounded "></div>

              <div className="p-2.5 gap-1 flex flex-col">
                <div className=" w-[20%] h-[22px] loader_pulse rounded "></div>
                <div className=" w-[20%] h-[20px] loader_pulse rounded  "></div>
                <div className="t w-[35%] h-[20px] loader_pulse rounded "></div>

                <div className=" w-[40%] h-[16px] loader_pulse rounded "></div>
                <div className=" w-[10%] h-[18px] loader_pulse rounded"></div>
                <div className=" w-full flex justify-between  ">
                  <div className=" w-[30%] h-[36px] loader_pulse rounded bg-primary"></div>
                  <div className=" w-[20%] h-[36px] loader_pulse rounded"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {!isLoading && (
        <Slider {...settings}>
          {data?.map((item: any, index: any) => (
            <div
              ref={containerRef}
              className="flex flex-row ltr:mr-10 pt-5    shadow-lg relative !w-[95%] !h-[320px] !mb-5 cursor-pointer"
              key={`carousel-data-${item.id}${index}`}
              onClick={() =>
                navigate(
                  `/service-view?id=${item.id}&serviceType=${item?.serviceType}S`
                )
              }
            >
              {/* <img className="w-full" src={item.image} alt="" /> */}
              <Image
                id={
                  (item?.imgId as number) ||
                  (item?.imageId as number) ||
                  (item?.profilePicId as number)
                }
                bgImgUrl={'/assets/default-img-icon.svg'}
                bgClass={
                  ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[165px]'
                }
                propClass={'w-full h-[165px] rounded-t'}
              />
              <div className="p-2.5 w-full">
                {(divWidth as number) > (containerWidth as number) ? (
                  <Tooltip
                    content={item.title}
                    children={
                      <div
                        ref={divRef}
                        className={` relative text-[18px] font-bold leading-[22px]  text-darkGrey w-full truncate`}
                      >
                        {item.title}
                      </div>
                    }
                  />
                ) : (
                  <div className="text-[18px] font-bold leading-[22px]  text-darkGrey ">
                    {item.title}
                  </div>
                )}

                <div className="text-[12px] font-xs leading-4 text-gray pt-1">
                  {item.subTitle}
                </div>
                <div className="text-[12px] font-bold leading-4  text-darkGrey pt-1">
                  {item.coachName}
                </div>

                {/* <div className="flex flex-row justify-start items-center">
                  <span className="text-closeRed font-semibold text-xs pr-1 pt-1">
                    {item.rating}
                  </span>
                  <Rating value={item.rating} />
                  <span className="text-[12px] font-xs leading-4 text-gray">
                    {`(${item.ratingCount})ratings`}
                  </span>
                </div> */}
                <div className="text-[12px] font-xs text-gray">
                  {item?.serviceType === 'SESSION' ? (
                    item?.duration ? (
                      `${item?.duration} ${t('MINS')}`
                    ) : (
                      `- ${t('MINS')}`
                    )
                  ) : (
                    <div className="w-full h-[18px]"></div>
                  )}
                </div>
                <div className="flex justify-between w-full gap-2">
                  <div
                    ref={priceParentWidthRef}
                    className="w-[calc(100%-80px)]"
                  >
                    {(priceWidth as number) > (priceParentWidth as number) ? (
                      <Tooltip
                        content={`${t('SAR')} ${item.price}`}
                        children={
                          <div
                            ref={priceWidthRef}
                            className="text-[24px] font-bold w-full truncate"
                          >
                            {t('SAR')}&nbsp;{item.price}
                          </div>
                        }
                      />
                    ) : (
                      <div className="text-[24px] font-bold leadind-[30px]">
                        {t('SAR')}&nbsp;
                        {item.price}
                      </div>
                    )}
                  </div>

                  <div className="bg-lightBlue items-center flex text-white text-[12px] font-bold p-2 leading-4 rounded">
                    {t(`CLIENT_SESSION_VIEW.BEST_SELLER`)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CommonCarousal;

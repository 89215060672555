import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientDatas,
  getCoachDatas,
  getCoachSocialMedia,
  selectClientHome,
} from '../../clientSlices/home';
import { setRoomsAlert } from '../../clientSlices/messages';
import { USER_TYPE } from '../../coachConstants/genericConstants';
import { Image } from '../../components/Image';
import { useAppSelector } from '../../hooks';
import Button from '../buttons';

const ChatContent = ({
  messageListData,
  setProfileView,
  chatContentData,
  profileData,
  message,
  handleInputChange,
  onHandleSendMessage,
  setMobileViewContent,
  recieverId,
  setPage,
  page,
}: any) => {
  const dispatch = useDispatch<any>();
  const chatLogRef: any = useRef(null);
  const { coachByIdData, clientByIdData } = useSelector(selectClientHome);
  const [chatData, setChatData] = useState<any>([]);
  const { roomId, roomAlertIds } = useAppSelector((state) => state.messages);
  const array: string[] = [...roomAlertIds];
  const [t] = useTranslation('common');

  const handleScroll = () => {
    const scrollTop = chatLogRef.current.scrollTop;
    const chatLogElement = chatLogRef.current;
    if (!chatLogElement) return;
    if (roomId !== '') {
      const filtered = array.filter((value: string) => value != roomId);

      dispatch(setRoomsAlert([...filtered]));
    }

    const { scrollHeight, offsetHeight } = chatLogElement;
    if (scrollTop === 0 && chatContentData.length > 0) {
      setPage((page: any) => page + 1);
    }
    const scrollBottom = scrollHeight - (scrollTop + offsetHeight);

    if (scrollBottom === 0 && page !== 0) {
      setPage(0);
    }
  };
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    if (recieverId && userType === USER_TYPE.CLIENT) {
      dispatch(getCoachDatas(recieverId));
      dispatch(getCoachSocialMedia(recieverId));
    } else if (recieverId) {
      dispatch(getClientDatas(recieverId));
    }
  }, [recieverId]);

  const getChatContentData = (messages: any) => {
    const chatIdToFilter = roomId;
    const filteredMessages = messages.filter(
      (message: any) => message.chatId === chatIdToFilter
    );

    const uniqueMessages = filteredMessages.reduce((acc: any, curr: any) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    const responseArray = Object.values(uniqueMessages);
    responseArray.sort((a: any, b: any): number => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    return responseArray;
  };

  useEffect(() => {
    setChatData(getChatContentData(chatContentData));
  }, [chatContentData, roomId]);
  useEffect(() => {
    if (page === 0 && chatData?.length > 0 && messageListData?.length > 0) {
      chatLogRef.current.scrollTop = chatLogRef?.current?.scrollHeight;
    }
  }, [chatData?.length]);
  const userData =
    userType === USER_TYPE.CLIENT ? coachByIdData : clientByIdData;

  return messageListData?.length ? (
    <div className="flex flex-col  w-full  justify-between max-w-[70%]">
      <div className=" flex flex-col pb-6 w-full">
        <div className="boxShadow rounded-lg p-4 flex items-center w-full gap-3">
          <img
            className="xll:hidden flex cursror-pointer"
            src="/assets/goBack.svg"
            alt=""
            onClick={() => setMobileViewContent('MSG')}
          />
          {/* <div className="flex gap-3  w-[30%]"> */}
          <div
            onClick={() => {
              if (userData?.fullName) {
                setProfileView((prevState: any) => !prevState);
                setMobileViewContent('PRO');
              }
            }}
          >
            <Image
              id={userData?.profilePicId}
              propClass={'w-10 h-10 rounded-full bg-lightBlack cursor-pointer'}
              bgClass={
                'w-10 h-10 rounded-full  flex items-center justify-center  bg-lightBlack  cursor-pointer'
              }
              bgImgUrl={'/assets/profile/avatar.svg'}
            />
          </div>{' '}
          <div className="flex justify-between flex-grow flex-col  w-[20%] ">
            <div className="font-general rtl:font-Almarai font-semibold text-16">
              {userData?.fullName ? userData?.fullName : 'Deleted User'}
            </div>
            <div className=" truncate font-normal font-general rtl:font-Almarai text-12 text-gray">
              {userData?.specialization?.map((item: string) => (
                <span>{item},&nbsp;</span>
              ))}
            </div>
          </div>
        </div>
        <ul
          ref={chatLogRef}
          className=" overflow-y-scroll h-[50vh] flex flex-col gap-3 pt-[20px]"
          onScroll={handleScroll}
        >
          {chatData?.length > 0 &&
            chatData.map((item: any, index: number) => (
              <li className="">
                {item?.senderId === profileData?.id ? (
                  <div className="w-full flex justify-end  ">
                    <div className="flex  gap-3 items-center max-w-[70%]">
                      <div className=" bg-darkBlue w-fit min-w-[70px] h-fit ltr:rounded-bl-lg rtl:rounded-br-lg rounded-t-lg relative pt-3 px-3 pb-7">
                        <div className="text-16 font-general rtl:font-Almarai font-normal text-white ">
                          {item?.message}
                        </div>
                        <div className="text-12 font-general rtl:font-Almarai text-white absolute bottom-2 right-3">
                          {/* {moment(item.createdAt).format('h:mm a')} */}
                          {moment.utc(item.createdAt).locale('en').format('h:mm a')}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={` px-5 `}>
                    <div className="flex gap-3">
                      <Image
                        id={userData?.profilePicId}
                        propClass={'w-10 h-10 rounded-full bg-lightBlack'}
                        bgClass={
                          'w-10 h-10 rounded-full flex items-center justify-center bg-lightBlack'
                        }
                        bgImgUrl={'/assets/profile/avatar.svg'}
                      />
                      <div className="flex flex-col gap-4 max-w-[70%]">
                        <div className="flex items-center gap-3 ">
                          <div className="bg-offWhite w-fit min-w-[70px]   h-fit ltr:rounded-tr-lg rtl:rounded-tl-lg rounded-b-lg pt-3 px-3 pb-7 relative">
                            <div className="text-16 font-general rtl:font-Almarai font-normal overflow-x-hidden">
                              {item?.message}
                            </div>
                            <div className="text-12 font-general rtl:font-Almarai text-gray absolute bottom-2 right-3">
                              {/* {moment(item.createdAt).format('h:mm a')} */}
                              {moment
                                .utc(item.createdAt)
                                .locale('en')
                                .format('h:mm a')}
                            </div>
                          </div>
                        </div>
                        <div> </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
        </ul>
      </div>

      <div className="flex gap-2 h-[56px]">
        <form className="w-full flex justify-between">
          <input
            className="w-[85%] border border-lightBlack rounded-lg drop-shadow-md p-[18px] "
            placeholder={t('ENTER_MSG')}
            value={message}
            onChange={handleInputChange}
          />

          <Button
            type="submit"
            onClick={onHandleSendMessage}
            className={''}
            children={
              <div className="h-full bg-primary text-white flex items-center px-7 font-satoshi rtl:font-Almarai font-bold text-18 rounded-md">
                {t('SEND')}
              </div>
            }
          />
        </form>
      </div>
    </div>
  ) : null;
};

export default ChatContent;

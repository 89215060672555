import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getCoachDatas, selectClientHome } from '../../clientSlices/home';
import { Image } from '../../components/Image';
import { useAppSelector } from '../../hooks';

const NewChat = ({ id, setRecieverId, setMobileViewContent }: any) => {
  const dispatch = useDispatch<any>();
  const chatLogRef: any = useRef(null);
  const { coachByIdData } = useSelector(selectClientHome);
  const [t] = useTranslation('common');
  const [message, setMessage] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setRecieverId(id);
    setMobileViewContent('CHAT');
    if (id) {
      dispatch(getCoachDatas(id));
    }
  }, [id]);

  const { socketObject } = useAppSelector((state) => state.messages);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const onHandleSendMessage = (e: any): any => {
    e.preventDefault();
    if (message !== '' && id) {
      const sendMessage = {
        recipientId: id,
        message: message,
      };
      socketObject?.publish({
        destination: '/app/chat',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(sendMessage),
      });
      setMessage('');
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  };
  return (
    <div className="flex flex-col  w-full  justify-between max-w-[70%]">
      <div className=" flex flex-col pb-6 w-full">
        <div className="boxShadow rounded-lg p-4 flex items-center w-full gap-3">
          <img
            className=" flex cursror-pointer"
            src="/assets/goBack.svg"
            alt=""
            onClick={() => {
              searchParams.delete('id');
              setSearchParams(searchParams);
            }}
          />
          <div>
            <Image
              id={coachByIdData?.profilePicId}
              propClass={'w-10 h-10 rounded-full bg-lightBlack'}
              bgClass={
                'w-10 h-10 rounded-full  flex items-center justify-center  bg-lightBlack'
              }
              bgImgUrl={'/assets/profile/avatar.svg'}
            />
          </div>{' '}
          <div className="flex justify-between flex-grow flex-col  w-[20%] ">
            <div className="font-general rtl:font-Almarai font-semibold text-16">
              {coachByIdData?.fullName
                ? coachByIdData?.fullName
                : 'Deleted User'}
            </div>
            <div className=" truncate font-normal font-general rtl:font-Almarai text-12 text-gray">
              {coachByIdData?.specialization?.map((item: string) => (
                <span>{item},&nbsp;</span>
              ))}
            </div>
          </div>
        </div>
        <ul
          ref={chatLogRef}
          className=" overflow-y-scroll h-[50vh] flex flex-col gap-3 pt-[20px]"
          //   onScroll={handleScroll}
        ></ul>
      </div>

      <div className="flex gap-2 h-[56px]">
        <form className="w-full flex justify-between">
          <input
            className="w-[85%] border border-lightBlack rounded-lg drop-shadow-md p-[18px] "
            placeholder={t('ENTER_MSG')}
            value={message}
            onChange={handleInputChange}
          />

          <button
            type="submit"
            onClick={onHandleSendMessage}
            className={''}
            children={
              <div className="h-full bg-primary text-white flex items-center px-7 font-satoshi rtl:font-Almarai font-bold text-18 rounded-md">
                {t('SEND')}
              </div>
            }
          />
        </form>
      </div>
    </div>
  );
};

export default NewChat;

import jwt_decode from 'jwt-decode';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-multi-date-picker/components/button';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AccountListSideBar } from '../../coachComponents';
import { getAccessToken } from '../../coachHelpers/localStorage';
import { deleteCoachAccount } from '../../coachSlices/profileSlices/accountSecurity';
import Modal from '../../components/modals';

const Component = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const decode: { id: number } = jwt_decode(getAccessToken() as string);
  const [closeToggle, setCloseToggle] = useState(false);
  const closeModal = () => {
    setCloseToggle(false);
  };
  const deleteAccount = () => {
    dispatch(deleteCoachAccount({ id: decode.id, history: navigate }));
    setCloseToggle(false);
  };

  return (
    <div className="mt-6 lg:mt-12 w-full h-fit">
      <div className="bg-white mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar />
        <div className=" lg:p-8 p-4 col-span-4 lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.CLOSE_ACCOUNT.CLOSE_ACCOUNT')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.CLOSE_ACCOUNT.DEC')}
          </p>
          <div className="border border-info mb-8"></div>
          <div className="flex">
            <p className="text-16 text-red rtl:font-Almarai font-bold font-general rtl:font-Almarai">
              {' '}
              {t('PROFILE.CLOSE_ACCOUNT.WARNING')}
              <span className="text-16 font-normal text-gray font-general rtl:font-Almarai">
                {t('PROFILE.CLOSE_ACCOUNT.MESSAGE')}
              </span>
            </p>
          </div>
          <div className="lgmd:w-2/12 w-full mt-6">
            <Button
              className="close-button bg-closeRed"
              onClick={() => setCloseToggle(true)}
            >
              <>{t('CLIENT_PROFILE.CLOSE_ACCOUNT_BUTTON')}</>
            </Button>
          </div>
          <Modal show={closeToggle} logOutClose={closeModal}>
            <div
              // onClick={() => deleteAccount()}
              className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center gap-7"
            >
              <img
                className="md:h-[73px] md:w-[73px] h-[60px] w-[60px]"
                src="/assets/clientProfile/closeAccount.svg"
                alt=""
              />
              <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-Almarai">
                {t('CLIENT_PROFILE.ARE_YOU_SURE')}{' '}
              </div>
              <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12">
                <div>
                  <Button
                    className={'w-full'}
                    children={
                      <div
                        onClick={() => setCloseToggle(false)}
                        className="h-13 border  text-gray  font-satoshi rtl:font-Almarai font-bold text-18 flex flex-grow items-center justify-center rounded  w-[100%] md:w-[200px]"
                      >
                        {t('CLIENT_PROFILE.CANCEL')}
                      </div>
                    }
                  />
                </div>
                <div>
                  <Button
                    className={'w-full'}
                    children={
                      <div
                        onClick={() => deleteAccount()}
                        className="h-13 bg-red text-white  font-satoshi rtl:font-Almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                      >
                        {t('CLIENT_PROFILE.CONFIRM')}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const CloseAccount = styled(Component)``;

export default CloseAccount;

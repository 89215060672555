import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Date from '../../../clientComponents/date';
import Input from '../../../clientComponents/inputs';
import { TextArea } from '../../../coachComponents';
import { addCoachValidationSchema } from '../../../coachHelpers';
import { useAppSelector } from '../../../coachHelpers/hooks';
import { IEducationCoachInterface } from '../../../coachInterfaces/profileInterface';
import {
  editEducation,
  editEducationFile,
  getProfileEducationById,
} from '../../../coachSlices/profileSlices/profileInfoSlice';

const EditCoachEducation = ({
  setShowEditEducation,
  educationUserId,
}: {
  setShowEditEducation: React.Dispatch<React.SetStateAction<boolean>>;
  educationUserId: number;
}) => {
  const [t] = useTranslation('common');
  const [university, setUniversity] = useState('');
  const [degree, setDegree] = useState('');
  const [fieldOfStudy, setFieldOfStudy] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [fileId, setFileId] = useState('');
  const dispatch = useDispatch<any>();
  const { coachEducationalDetailsById, editEducationFileId } = useAppSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getProfileEducationById(educationUserId));
  }, []);

  useEffect(() => {
    if (coachEducationalDetailsById) {
      setUniversity(coachEducationalDetailsById?.university);
      setDegree(coachEducationalDetailsById?.degree);
      setFieldOfStudy(coachEducationalDetailsById?.fieldOfStudy);
      setStartDate(coachEducationalDetailsById?.startDate);
      setEndDate(coachEducationalDetailsById?.endDate);
      setDescription(coachEducationalDetailsById?.description);
      setFileId(coachEducationalDetailsById.fileId);
    }
  }, [coachEducationalDetailsById]);

  const handleSubmit = (data: IEducationCoachInterface) => {
    const allData = {
      university: data.university,
      degree: data.degree,
      fieldOfStudy: data.fieldOfStudy,
      startDate: data.startDate,
      endDate: data.endDate,
      description: data.description,
      fileId: editEducationFileId.id || null,
    };
    const dataWithId = {
      data: {
        allData,
      },
      id: educationUserId,
    };
    dispatch(editEducation(dataWithId));
    setShowEditEducation(false);
  };

  const uploadFile = (e: React.FormEvent<EventTarget>): void => {
    (document.getElementById('addImage') as HTMLInputElement).innerHTML = (
      e?.target as HTMLFormElement
    )?.files[0]?.name;
    dispatch(editEducationFile((e?.target as HTMLFormElement)?.files));
  };

  return (
    <>
      <div className="fixed h-[100vh] inset-0 bg-transparentBlack bg-opacity-50 z-10  overflow-y-hidden">
        <div className="flex h-[100vh] items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg h-[90vh]">
            <div className="mt-3 sm:flex">
              <div className="grid">
                <button
                  className="flex justify-self-end"
                  onClick={() => setShowEditEducation(false)}
                >
                  <img
                    src="/assets/profilePageImage/model.svg"
                    alt="icon"
                    className="mr-2 cursor-pointer"
                  />
                </button>
                <h1 className="text-28 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
                  {t('PROFILE.PROFILE_INFO.EDIT_EDUCATION')}
                </h1>
                <p className="text-16 font-normal font-satoshi rtl:font-Almarai text-gray mb-4">
                  {t('PROFILE.PROFILE_INFO.DEC')}
                </p>
                <div className="h-[65vh] w-full overflow-y-auto pt-2">
                  <Formik
                    initialValues={{
                      university,
                      degree,
                      fieldOfStudy,
                      startDate,
                      endDate,
                      description,
                      fileId,
                    }}
                    validationSchema={addCoachValidationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                      submitForm,
                      setFieldValue,
                    }) => (
                      <Form>
                        <div className="pb-[80px]">
                          <Input
                            id="FormUniversity"
                            type="text"
                            name="university"
                            placeholder={t('PROFILE.PROFILE_INFO.UNIVERSITY')}
                            onBlur={handleBlur}
                            error={touched.university && 1}
                            onChange={handleChange}
                            value={values.university}
                            errormessage={errors.university}
                            optional={true}
                            maxLength={50}
                          />
                          <Input
                            id="FormDegree"
                            type="text"
                            name="degree"
                            placeholder={t('PROFILE.PROFILE_INFO.DEGREE')}
                            onBlur={handleBlur}
                            error={touched.degree && 1}
                            onChange={handleChange}
                            value={values.degree}
                            errormessage={errors.degree}
                            optional={true}
                            maxLength={50}
                          />
                          <Input
                            id="FormFieldOfStudy"
                            type="text"
                            name="fieldOfStudy"
                            placeholder={t(
                              'PROFILE.PROFILE_INFO.FIELD_OF_STUDY'
                            )}
                            onBlur={handleBlur}
                            error={touched.fieldOfStudy && 1}
                            onChange={handleChange}
                            value={values.fieldOfStudy}
                            errormessage={errors.fieldOfStudy}
                            optional={true}
                            maxLength={50}
                          />

                          <div className="lg:flex justify-between ">
                            <div
                              className=" lg:w-6/12
                         lg:ltr:mr-3 lg:rtl:ml-3
                         "
                            >
                              <Date
                                type="date"
                                id="dateBirth"
                                name="startDate"
                                placeholder={t(
                                  'PROFILE.PROFILE_INFO.START_DATE'
                                )}
                                onBlur={handleBlur}
                                value={values.startDate}
                                error={touched.startDate && 1}
                                onChange={(opt: any) => {
                                  const date = `${opt?.year}-${
                                    opt?.monthIndex + 1 < 10
                                      ? `0${opt?.monthIndex + 1}`
                                      : opt?.monthIndex + 1
                                  }-${
                                    opt?.day < 10 ? `0${opt?.day}` : opt?.day
                                  }`;
                                  setFieldValue('startDate', date);
                                }}
                                errormessage={errors.startDate}
                              />
                            </div>
                            <div className=" lg:w-6/12">
                              <Date
                                type="date"
                                id="dateBirth"
                                name="endDate"
                                placeholder={t('PROFILE.PROFILE_INFO.END_DATE')}
                                onBlur={handleBlur}
                                value={values.endDate}
                                error={touched.endDate && 1}
                                onChange={(opt: any) => {
                                  const date = `${opt?.year}-${
                                    opt?.monthIndex + 1 < 10
                                      ? `0${opt?.monthIndex + 1}`
                                      : opt?.monthIndex + 1
                                  }-${
                                    opt?.day < 10 ? `0${opt?.day}` : opt?.day
                                  }`;
                                  setFieldValue('endDate', date);
                                }}
                                errormessage={errors.endDate}
                              />
                            </div>
                          </div>
                          <TextArea
                            id="FormBio"
                            type="text"
                            rows={3}
                            cols={50}
                            name="description"
                            placeholder={t('PROFILE.PROFILE_INFO.DESCRIP')}
                            onBlur={handleBlur}
                            error={touched.description && 1}
                            onChange={handleChange}
                            value={values.description}
                            errormessage={errors.description}
                            optional={1}
                            maxLength={50}
                          />
                          <h1 className="text-22 font-bold font-satoshi rtl:font-Almarai text-secondary mt-1 mb-1 ">
                            {t('PROFILE.PROFILE_INFO.MEDIA')}
                          </h1>
                          <p className="text-gray text-17 font-normal font-general rtl:font-Almarai">
                            {t('PROFILE.PROFILE_INFO.MEDIA_DEC')}
                          </p>
                          <div className="col-md-9 pe-5 my-5">
                            <div className=" flex sm:mb-3">
                              <label htmlFor="image">
                                <p className="bg-gray w-44 mt-5 rounded text-center px-4 py-2 text-white font-satoshi rtl:font-Almarai text-14 font-bold">
                                  {t('PROFILE.PROFILE_INFO.UPLOAD_DOC')}
                                </p>
                              </label>
                              <input
                                id="image"
                                name="fileId"
                                className="form-control"
                                accept=".jpg,.jpeg,.png,.pdf"
                                type="file"
                                onChange={(e) => {
                                  uploadFile(e);
                                  handleChange(e);
                                }}
                                style={{ display: 'none' }}
                              />
                              <span
                                id="addImage"
                                className="
                            ltr:ml-3 rtl:mr-3 
                             mt-7 text-13 font-general rtl:font-Almarai not-italic font-normal text-gray"
                              >
                                {t('PROFILE.PROFILE_INFO.NO_IMAGE')}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="lg:flex lg:justify-end text-end grid mt-10 w-full h-[80px] items-center absolute bottom-0 left-0 bg-[#ffffff] px-5">
                          <button
                            type="submit"
                            onClick={submitForm}
                            className="bg-primary p-4 lg:px-10 lg:py-4 text-white rounded"
                          >
                            {t('PROFILE.PROFILE_INFO.SAVE')}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoachEducation;

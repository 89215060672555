import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Image } from '../../components/Image';
interface IRating {
  data: any;
}

const CoachCarousal: FC<IRating> = ({ data }): JSX.Element => {
  function Arrowss(props: any) {
    let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
    className += ' arrow';
    const char =
      props.type === 'next' ? (
        <img
          src={
            process.env.PUBLIC_URL + '/assets/bodyAfterLogin/rightArrowMark.svg'
          }
          className="absolute top-[3.25rem] right-[-16px] "
          alt=""
        ></img>
      ) : (
        <img
          src={
            process.env.PUBLIC_URL + '/assets//bodyAfterLogin/leftArrowMark.svg'
          }
          className="absolute top-[55px] left-[-26px]  z-10"
          alt=""
        ></img>
      );
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    initialSlide: 0,
    arrows: true,
    centerMode: false,
    className: '!w-[100%] !h-[50%]',

    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,

    nextArrow: <Arrowss type="next" />,
    prevArrow: <Arrowss type="prev" />,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1210,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  // const [updatedData, setUpdatedData] = useState([]);
  // useEffect(() => {
  //   let updatee = data;
  //   const maxSlidesToShow = 6;
  //   if (updatee?.length > 0) {
  //     while (updatee?.length < maxSlidesToShow) {
  //       updatee = updatee.concat(updatee);
  //     }
  //   }
  //   setUpdatedData((value) => (value = updatee));
  // }, [data]);
  const navigate = useNavigate();
  return (
    <div className="flex flex-row mt-10 ">
      <Slider {...settings}>
        {data?.map((item: any, index: any) => (
          <div
            className="flex flex-row mr-10    shadow-lg relative !w-[95%] min-h-[266px] h-fit mb-[33px] cursor-pointer"
            key={`data-slide-${item.id}${index}`}
            onClick={
              () => navigate(`/coach-profile-view?id=${item?.id}`)
              // navigate(
              //   `/client/session-view?id=${
              //     item.id
              //   }&serviceType=${serviceType.toUpperCase()}`
              // )
            }
          >
            {/* <img className="w-full" src={item.image} alt="" /> */}
            <Image
              id={
                (item?.imgId as number) ||
                (item?.imageId as number) ||
                (item?.profilePicId as number)
              }
              bgImgUrl={'/assets/default-img-icon.svg'}
              bgClass={
                ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[165px]'
              }
              propClass={'w-full h-[165px] rounded-t'}
            />
            <div className="p-2.5 h-[134px]">
              <div className="text-[18px] font-bold  text-darkGrey ">
                {item.fullName}
              </div>
              <div className="text-[12px] font-xs truncate text-gray">
                {item.headline}
              </div>
              {/* <div className="flex flex-row justify-start items-center text-[12px] font-xs text-gray py-6">
                <span className="text-closeRed font-semibold text-xs pr-1 pt-1">
                  {item.rating}
                </span>
                <Rating value={item.rating} />
              </div> */}

              <div className="flex absolute bottom-0 text-[12px] font-xs text-gray pb-3">
                <div className="flex items-center pr-2 ">
                  <img
                    className="w-[26px]"
                    src="/assets/bodyAfterLogin/coach-grp-icon.svg "
                    alt=""
                  ></img>
                </div>
                {item.clientCount ? item.clientCount : 0} Clients
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CoachCarousal;

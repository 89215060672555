import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { alertInterface } from '../coachInterfaces/alertInterface';
import { RootState } from '../store';

const initialState: alertInterface = {
  showAlert: false,
  message: '',
  messageAr: '',
  alertType: '',
};

export const alertSlice = createSlice({
  name: 'alertItems',
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        showAlert: true,
        alertType: action.payload.type,
        message: action.payload.message,
        messageAr: action.payload.messageAr,
      };
    },

    closeAlert: (state) => {
      return {
        ...state,
        showAlert: false,
      };
    },
  },
});

export const { showAlert, closeAlert } = alertSlice.actions;
export const alertInfo = (state: RootState) => state.alertInfo;
export const alertReducer = alertSlice.reducer;

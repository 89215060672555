import { FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Date from '../../clientComponents/date';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { registrationConst } from '../../coachConstants';
import { coachingValidationSchema } from '../../coachHelpers';
import { useAppSelector } from '../../coachHelpers/hooks';
import {
  IFormikInterface,
  IUploadInterface,
} from '../../coachInterfaces/registrationInterface';
import { coachingInformation } from '../../coachSlices/registrationSlice';
import Button from '../buttons';
import Carousel from '../imageSlider';
import './index.css';

const Component = () => {
  const navigate = useNavigate();
  const { lang } = useAppSelector((state) => state.language);
  const { accreditationRequest, personalInfo } = useAppSelector(
    (state) => state.registration
  );
  const dispatch = useDispatch<any>();
  const [t] = useTranslation('common');
  let clone = accreditationRequest?.accreditationRequest;
  const [data, setData] = useState<any>(clone);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: any) => {
    setInputValue(event);
  };
  const uploadFile = (e: IUploadInterface, i: number, setFieldValue: any) => {
    setFieldValue(`accreditationRequest.${i}.file`, e.target.files[0]);
  };

  const handleDispatch = (data: any) => {
    if (checkFileNames(accreditationRequest?.accreditationRequest))
      navigate(registrationConst.PATH_SPECIALIZATION_DETAILS);
  };

  const numberOption = [
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 300, value: 300 },
    { label: 400, value: 400 },
    { label: 500, value: 500 },
    { label: 600, value: 600 },
  ];
  useEffect(() => {
    if (!personalInfo?.fullName)
      navigate(registrationConst.PATH_PERSONAL_DETAILS);
  }, [personalInfo]);

  function checkFileNames(array: any) {
    if (array?.length > 0) {
      for (const obj of array) {
        if (!obj?.file || !obj?.file?.name) {
          return false;
        }
      }
      return true;
    }
    return true;
  }

  return (
    <div className="registrationStep-container01">
      <div className="registrationStep-container03">
        <p className="step">{t('COACHING_DETAILS.STEP02')}</p>
        <div className="progress_bar">
          <div className="bg-primary h-1 w-2/5"></div>
        </div>
        <Formik
          validationSchema={coachingValidationSchema()}
          initialValues={{
            accreditationRequest:
              data?.length > 0
                ? [...data]
                : [
                    {
                      accreditationName: '',
                      certificates: '',
                      coachingHours: '',
                      expiration: '',
                      fileId: '',
                      issueDate: '',
                      provider: '',
                      file: [],
                    },
                  ],
          }}
          onSubmit={handleDispatch}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            submitForm,
            errors,
            touched,
          }: IFormikInterface) => {
            dispatch(coachingInformation(values));

            return (
              <Form>
                <div className=" h-full  lg:px-1">
                  <h1 className="heading_large sm:heading_small">
                    {t('COACHING_DETAILS.COACHING_ACC')}
                  </h1>
                  <p className="paragraph">
                    {t('COACHING_DETAILS.DESCRIPTION')}
                  </p>
                  <FieldArray
                    name="accreditationRequest"
                    render={(arrayHelpers) => {
                      const formData =
                        values?.accreditationRequest?.length > 0 &&
                        values?.accreditationRequest;

                      return (
                        <div className="lg:mb-8">
                          {formData && formData?.length > 0
                            ? formData?.map(
                                (accreditationRequest: any, i: number) => {
                                  return (
                                    <div key={i}>
                                      {i > 0 && (
                                        <div className="flex justify-end text-end border-info mt-8 lg:border-t-2">
                                          <Button
                                            onClick={() =>
                                              arrayHelpers.remove(i)
                                            }
                                            className="mb-5 mt-9 text-12 font-bold text-red rtl:font-Almarai border-2 border-red px-4 rounded-md py-2"
                                          >
                                            {t('COACHING_DETAILS.REMOVE')}
                                          </Button>
                                        </div>
                                      )}

                                      <Input
                                        id={`accreditationRequest.${i}.provider`}
                                        type="text"
                                        name={`accreditationRequest.${i}.provider`}
                                        placeholder={t(
                                          'COACHING_DETAILS.PROVIDER'
                                        )}
                                        onBlur={handleBlur}
                                        error={
                                          touched?.accreditationRequest
                                            ?.length > 0
                                            ? touched?.accreditationRequest[i]
                                                ?.provider && 1
                                            : undefined
                                        }
                                        value={
                                          values?.accreditationRequest[i]
                                            ?.provider
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            `accreditationRequest.${i}.provider`,
                                            e.target.value
                                          );
                                        }}
                                        errormessage={
                                          errors?.accreditationRequest?.length >
                                          0
                                            ? errors.accreditationRequest[i]
                                                ?.provider
                                            : undefined
                                        }
                                      />

                                      <Input
                                        id={`accreditationRequest.${i}.accreditationName`}
                                        type="text"
                                        name={`accreditationRequest.${i}.accreditationName`}
                                        placeholder={t(
                                          'COACHING_DETAILS.ACCREDITATION_NAME'
                                        )}
                                        onBlur={handleBlur}
                                        error={
                                          touched?.accreditationRequest
                                            ?.length > 0
                                            ? touched?.accreditationRequest[i]
                                                ?.accreditationName && 1
                                            : undefined
                                        }
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            `accreditationRequest.${i}.accreditationName`,
                                            e.target.value
                                          );
                                        }}
                                        errormessage={
                                          errors?.accreditationRequest?.length >
                                          0
                                            ? errors.accreditationRequest[i]
                                                ?.accreditationName
                                            : undefined
                                        }
                                        value={
                                          values.accreditationRequest[i]
                                            ?.accreditationName
                                        }
                                      />
                                      <div className="xlg:flex justify-between">
                                        <div
                                          className={`xlg:w-6/12 ${
                                            lang === 'en'
                                              ? 'xlg:mr-3'
                                              : 'xlg:ml-3'
                                          } `}
                                        >
                                          <Date
                                            id={`accreditationRequest.${i}.issueDate`}
                                            type="date"
                                            name={`accreditationRequest.${i}.issueDate`}
                                            placeholder={t(
                                              'COACHING_DETAILS.ISSUE_DATE'
                                            )}
                                            // value={data && data[i]?.issueDate}
                                            value={
                                              values.accreditationRequest[i]
                                                ?.issueDate
                                            }
                                            onBlur={handleBlur}
                                            error={
                                              touched?.accreditationRequest
                                                ?.length > 0
                                                ? touched?.accreditationRequest[
                                                    i
                                                  ]?.issueDate && 1
                                                : undefined
                                            }
                                            onChange={(opt: any) => {
                                              const date = `${opt?.year}-${
                                                opt?.monthIndex + 1 < 10
                                                  ? `0${opt?.monthIndex + 1}`
                                                  : opt?.monthIndex + 1
                                              }-${
                                                opt?.day < 10
                                                  ? `0${opt?.day}`
                                                  : opt?.day
                                              }`;
                                              setFieldValue(
                                                `accreditationRequest.${i}.issueDate`,
                                                date
                                              );
                                            }}
                                            errormessage={
                                              errors?.accreditationRequest
                                                ?.length > 0
                                                ? errors.accreditationRequest[i]
                                                    ?.issueDate
                                                : undefined
                                            }
                                          />
                                        </div>
                                        <div
                                          className={` xlg:w-6/12 ${
                                            lang === 'en'
                                              ? 'xlg:mr-3'
                                              : 'xlg:ml-3'
                                          } `}
                                        >
                                          <Date
                                            id={`accreditationRequest.${i}.expiration`}
                                            type="date"
                                            optional={1}
                                            name={`accreditationRequest.${i}.expiration`}
                                            placeholder={t(
                                              'COACHING_DETAILS.EXPIRATION'
                                            )}
                                            value={
                                              values.accreditationRequest[i]
                                                ?.expiration
                                            }
                                            error={
                                              touched?.accreditationRequest
                                                ?.length > 0
                                                ? touched?.accreditationRequest[
                                                    i
                                                  ]?.expiration && 1
                                                : undefined
                                            }
                                            onBlur={handleBlur}
                                            onChange={(opt: any) => {
                                              const date = `${opt?.year}-${
                                                opt?.monthIndex + 1 < 10
                                                  ? `0${opt?.monthIndex + 1}`
                                                  : opt?.monthIndex + 1
                                              }-${
                                                opt?.day < 10
                                                  ? `0${opt?.day}`
                                                  : opt?.day
                                              }`;

                                              setFieldValue(
                                                `accreditationRequest.${i}.expiration`,
                                                opt?.year &&
                                                  opt?.monthIndex &&
                                                  opt?.day
                                                  ? date
                                                  : ''
                                              );
                                            }}
                                            errormessage={
                                              errors?.accreditationRequest
                                                ?.length > 0
                                                ? errors.accreditationRequest[i]
                                                    ?.expiration
                                                : undefined
                                            }
                                          />
                                        </div>
                                        <div className={` xlg:w-6/12 mb-8 `}>
                                          <SelectDropdown
                                            id={`accreditationRequest.${i}.coachingHours`}
                                            name={`accreditationRequest.${i}.coachingHours`}
                                            placeholder={t(
                                              'COACHING_DETAILS.COACHING_HOURS'
                                            )}
                                            onBlur={handleBlur}
                                            options={numberOption}
                                            error={
                                              touched?.accreditationRequest
                                                ?.length > 0
                                                ? touched?.accreditationRequest[
                                                    i
                                                  ]?.coachingHours && 1
                                                : undefined
                                            }
                                            errormessage={
                                              errors?.accreditationRequest
                                                ?.length > 0
                                                ? errors.accreditationRequest[i]
                                                    ?.coachingHours
                                                : undefined
                                            }
                                            onChange={(opt: {
                                              label: number;
                                              value: number;
                                            }) => {
                                              setFieldValue(
                                                `accreditationRequest.${i}.coachingHours`,
                                                opt.label
                                              );
                                            }}
                                            onInputChange={handleInputChange}
                                            inputValue={inputValue}
                                            controlShouldRenderValue={true}
                                            value={
                                              data && data[i]?.coachingHours
                                            }
                                            isValue={
                                              values.accreditationRequest
                                                ?.length &&
                                              values.accreditationRequest[i]
                                                .coachingHours
                                            }
                                            defaultValue={{
                                              label:
                                                data && data[i]?.coachingHours,
                                              value:
                                                data && data[i]?.coachingHours,
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <Input
                                        id={`accreditationRequest.${i}.certificates`}
                                        type="text"
                                        // rows={3}
                                        optional={false}
                                        // cols={50}
                                        name={`accreditationRequest.${i}.certificates`}
                                        placeholder={t(
                                          'COACHING_DETAILS.CERTIFICATES'
                                        )}
                                        onBlur={handleBlur}
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            `accreditationRequest.${i}.certificates`,
                                            e.target.value
                                          );
                                        }}
                                        value={
                                          values.accreditationRequest[i]
                                            ?.certificates
                                        }
                                        error={
                                          touched?.accreditationRequest
                                            ?.length > 0
                                            ? touched?.accreditationRequest[i]
                                                ?.certificates && 1
                                            : undefined
                                        }
                                        errormessage={
                                          errors?.accreditationRequest?.length >
                                          0
                                            ? errors.accreditationRequest[i]
                                                ?.certificates
                                            : undefined
                                        }
                                      />
                                      <div className="my-5 ">
                                        <label
                                          htmlFor={`accreditationRequest.${i}.fileId`}
                                          className="bg-skyBlue cursor-pointer rounded px-4 py-2 text-secondary font-satoshi rtl:font-Almarai text-14 font-bold"
                                        >
                                          {t('COACHING_DETAILS.UPLOAD_DOC')}{' '}
                                          <span className="text-red rtl:font-Almarai text-18">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          id={`accreditationRequest.${i}.fileId`}
                                          name={`accreditationRequest.${i}.file`}
                                          className="form-control"
                                          accept=".jpg,.jpeg,.png,.pdf"
                                          type="file"
                                          onChange={(e) => {
                                            uploadFile(e, i, setFieldValue);
                                            // handleChange(e);
                                          }}
                                          style={{ display: 'none' }}
                                        />

                                        <span
                                          id={`file${i}`}
                                          className={`${
                                            lang === 'en' ? 'ml-3' : 'mr-3'
                                          } text-13 font-general rtl:font-Almarai not-italic font-normal text-gray`}
                                        >
                                          {values.accreditationRequest[i]?.file
                                            ? values.accreditationRequest[i]
                                                ?.file?.name
                                            : t(
                                                'COACHING_DETAILS.NO_IMAGE_ADDED'
                                              )}

                                          {/* {t('COACHING_DETAILS.NO_IMAGE_ADDED')} */}
                                        </span>
                                        <div className="text-red rtl:font-Almarai font-normal text-12 pt-2">
                                          {touched?.accreditationRequest
                                            ?.length > 0
                                            ? touched?.accreditationRequest[i]
                                                ?.file &&
                                              errors?.accreditationRequest
                                                ?.length > 0
                                              ? values.accreditationRequest[i]
                                                  ?.file &&
                                                values.accreditationRequest[i]
                                                  ?.file?.name &&
                                                t(
                                                  `ERRORS.${errors.accreditationRequest[i]?.file}`
                                                )
                                              : ''
                                            : ''}
                                        </div>
                                        {touched?.accreditationRequest?.length >
                                          0 &&
                                          touched?.accreditationRequest[i]
                                            ?.file &&
                                          values.accreditationRequest[i]
                                            ?.file &&
                                          !values.accreditationRequest[i]?.file
                                            ?.name && (
                                            <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 mt-3">
                                              {t(
                                                'ERRORS.COACHING_DETAILS.EMPTY_FILE'
                                              )}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : null}

                          <button
                            type="button"
                            className="add_more lg:mb-28"
                            onClick={() => {
                              arrayHelpers.push({
                                accreditationName: '',
                                certificates: '',
                                coachingHours: '',
                                expiration: '',
                                file: [],
                                fileId: '',
                                issueDate: '',
                                provider: '',
                              });
                            }}
                          >
                            {t('ADD_MORE')}
                          </button>
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="lg:flex lg:justify-between grid py-4 mb-16 border-info lg:border-t-2">
                  <button
                    className=" order-last lg:order-first cancel-btn mb-6 py-4 hover:bg-primary hover:border-transparent hover:text-white hover:lg:next-btn hover:next-btn-large"
                    onClick={() =>
                      navigate(
                        `${registrationConst.PATH_PERSONAL_DETAILS}?reset=NO`
                      )
                    }
                  >
                    {t('BACK')}
                  </button>
                  <div className="lg:flex lg:justify-start grid lg:w-1/4">
                    <button
                      type="submit"
                      onClick={submitForm}
                      className="lg:next-btn next-btn-large bg-primary"
                    >
                      {t('NEXT')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Carousel />
    </div>
  );
};
const CoachingDetails = styled(Component)``;

export default CoachingDetails;

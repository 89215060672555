import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../coachComponents/buttons';
import {
  fetchAssessmentTitles,
  fetchSessionTitles,
  packageInfo,
} from '../../coachSlices/packageSlice';
import { showAlert } from '../../slice/alert';
import './index.css';

const Component = () => {
  const [t] = useTranslation('common');
  const [hoveredCard, setHoveredCard] = useState<string>('');
  const [selectedCard, setSelectedCard] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchAssessmentTitles());
    dispatch(fetchSessionTitles());
  }, []);
  const { assessmentTitles, sessionTitles } = useSelector(packageInfo);
  const createSessionCard = [
    {
      title: t('CREATE_SESSION.SESSION'),
      content: t('CREATE_SESSION.CARD_DESC_SESSION'),
      value: '/coach-session',
      imgUrl: '/assets/createSession/session-inactive.svg',
      activeImgUrl: '/assets/createSession/session-active.svg',
    },
    {
      title: t('CREATE_SESSION.PACKAGE'),
      content: t('CREATE_SESSION.CARD_DESC_PACKAGE'),
      value: '/coach-package',
      imgUrl: '/assets/createSession/package-inactive.svg',
      activeImgUrl: '/assets/createSession/package-active.svg',
    },
    {
      title: t('CREATE_SESSION.ASSESSMENT'),
      content: t('CREATE_SESSION.CARD_DESC_ASSESSMENT'),
      value: '/coach-assessment',
      imgUrl: '/assets/createSession/assessment-inactive.svg',
      activeImgUrl: '/assets/createSession/assessment-active.svg',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('KUN_COACH')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="session-page-container ">
        <div className="session-page-content ">
          <div className="session-page-title ">
            {t('CREATE_SESSION.CRATE_SESSION_TITLE')}
          </div>
          <div className=" session-page-desc ">
            {t('CREATE_SESSION.CRATE_SESSION_DESC')}
          </div>
          <div className="session-card-row">
            {createSessionCard.map(
              ({ title, content, value, imgUrl, activeImgUrl }) =>
                selectedCard === value ? (
                  <div
                    onClick={() => setSelectedCard('')}
                    key={value}
                    className="session-card-active cursor-pointer"
                  >
                    <img src={activeImgUrl} className="mx-auto" alt="" />
                    <div className="w-full text-center font-satoshi rtl:font-Almarai my-4  font-bold text-lg">
                      {title}
                    </div>
                    <div className="session-card-content w-full text-center font-general rtl:font-Almarai font-normal text-[13px]">
                      {content}
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setSelectedCard(value)}
                    key={value}
                    onMouseEnter={() => setHoveredCard(value)}
                    onMouseLeave={() => setHoveredCard('')}
                    className="session-card-inactive cursor-pointer"
                  >
                    <img
                      src={hoveredCard === value ? activeImgUrl : imgUrl}
                      className="mx-auto"
                      alt=""
                    />
                    <div className="w-full text-center font-satoshi rtl:font-Almarai my-4  font-bold text-lg">
                      {title}
                    </div>
                    <div
                      className={
                        hoveredCard === value
                          ? `text-white w-full text-center font-general rtl:font-Almarai font-normal text-[13px]`
                          : `text-[#687E8E] w-full text-center font-general rtl:font-Almarai font-normal text-[13px]`
                      }
                    >
                      {content}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="session-btn-row gap-3">
            <Button
              onClick={() => {
                setSelectedCard('');
                setHoveredCard('');
                navigate(-1);
              }}
              className="btn-small m-0 md:m-2 md:w-48 w-[100%] text-secondary bg-white"
            >
              {t('CREATE_SESSION.CANCEL')}
            </Button>
            <Button
              onClick={() => {
                if (selectedCard === '/coach-package') {
                  if (
                    sessionTitles?.length >= 0 &&
                    assessmentTitles?.length >= 0
                  ) {
                    selectedCard && navigate(`/coach${selectedCard}`);
                  } else {
                    dispatch(
                      showAlert({
                        type: 'error',
                        message: 'Please add a session and an assessment ',
                      })
                    );
                  }
                } else {
                  selectedCard && navigate(`/coach${selectedCard}`);
                }
              }}
              className={
                selectedCard
                  ? `bg-primary text-white btn-small m-0 md:m-2 md:w-48 w-[100%] `
                  : `bg-[#38383840] text-white btn-small m-0 md:m-2 md:w-48 w-[100%] `
              }
            >
              {t('CREATE_SESSION.CONTINUE')}
            </Button>
          </div>
        </div>
      </div>

      {/* <div className="h-[4rem] bg-[#212147] text-white w-full flex justify-center items-center font-general rtl:font-Almarai font-normal text-xs">
        {t('HOME.COPYRIGHT_TXT')}
      </div> */}
    </>
  );
};
const CreateSession = styled(Component)``;

export default CreateSession;

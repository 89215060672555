import { NavigateFunction } from 'react-router-dom';

type HistoryType = {
  navigate: NavigateFunction;
  push: <T, E>(page: T, ...rest: E[]) => void;
};

export const History: HistoryType = {
  navigate: undefined as unknown as NavigateFunction,
  push: (page: any, ...rest: any[]) =>
    History.navigate ? History.navigate(page, ...rest) : undefined,
};

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Component = ({ ...props }: any) => {
  const [t] = useTranslation('common');

  return (
    <>
      {' '}
      <Helmet>
        <title>{t('KUN_COACH')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Outlet />
    </>
  );
};

const RegistrationPage = styled(Component)``;

export default RegistrationPage;

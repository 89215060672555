import { FieldArray, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import { assessmentThirdPartySchema } from '../../coachHelpers';
import {
  assessmentInfo,
  setThirdPartyAssessmentFormData,
  setThirdPartyFileIds,
} from '../../coachSlices/assessmentSlice';
import { downloadImage } from '../../coachSlices/packageSlice';
import { listCurrencies, sessionInfo } from '../../coachSlices/sessionSlice';

interface IFaqCard {
  thirdFormRef: any;
}

const Component: FC<IFaqCard> = ({ thirdFormRef }): JSX.Element => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();
  const { currency } = useSelector(sessionInfo);
  const { thirdPartyProviderRequest, fileIds } = useSelector(assessmentInfo);
  let currencyListEn: { label: ''; value: '' }[] = [];
  let currencyListAr: { label: ''; value: '' }[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const [fileNameArray, setFileNameArray] = useState<string[]>([]);

  currency.map((item: any, index: number) => {
    currencyListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    currencyListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  useEffect(() => {
    dispatch(listCurrencies(''));
  }, []);
  const handleFormSubmit = () => {};

  const uploadImageFiles = (e: any, position: number, setFieldValue: any) => {
    setFieldValue(`thirdParty.${position}.file`, e.target.files);
  };

  useEffect(() => {
    async function getImage() {
      try {
        const nameArray: string[] = [];

        await Promise.all(
          fileIds.map(async (id: number) => {
            const imageData = await dispatch(downloadImage(id as number));

            nameArray.push(imageData?.payload?.data[0]?.originalFileName);
          })
        );
        setFileNameArray(nameArray);
      } catch (err) {}
    }
    if (
      searchParams.get('form') === 'EDIT' ||
      searchParams.get('form') === 'DRAFT'
    )
      getImage();
  }, [fileIds]);

  const removeFileFromArray = (i: number) => {
    if (
      searchParams.get('form') === 'EDIT' ||
      searchParams.get('form') === 'DRAFT'
    ) {
      let idArray: number[] = [];
      const array = fileNameArray;
      array.splice(i, 1);
      fileIds.map((item: number, index: number) => {
        if (index !== i) {
          idArray.push(item);
        }
      });

      dispatch(setThirdPartyFileIds([...idArray]));
    }
  };

  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-Almarai font-bold text-[34px]">
        {t('COACH_ASSESSMENT.ASSESSMENT_THIRD_PROVIDER')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_ASSESSMENT.INFO_PARTY_THIRD')}
      </div>
      <div className="w-full font-satoshi rtl:font-Almarai mb-4 font-bold mt-9 text-xl">
        {t('COACH_ASSESSMENT.ASSESSMENT_PARTY_DETAILS')}
      </div>

      <Formik
        innerRef={thirdFormRef}
        initialValues={thirdPartyProviderRequest}
        validationSchema={assessmentThirdPartySchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setThirdPartyAssessmentFormData(values));

          return (
            <>
              <div className="w-full">
                <Input
                  id="FormTitle"
                  type="text"
                  name="title"
                  placeholder={t('COACH_ASSESSMENT.RESOURCE_TITLE')}
                  onBlur={handleBlur}
                  error={touched.title && 1}
                  onChange={(e: any) => {
                    setFieldValue('title', e.target.value);
                  }}
                  errormessage={errors.title as string}
                  value={values.title}
                />
              </div>
              <div className="w-full">
                <Input
                  id="FormProvider"
                  type="text"
                  name="provider"
                  placeholder={t('COACH_ASSESSMENT.ASSESSMENT_PROVIDER')}
                  onBlur={handleBlur}
                  error={touched.provider && 1}
                  onChange={(e: any) => {
                    setFieldValue('provider', e.target.value);
                  }}
                  errormessage={errors.provider as string}
                  value={values.provider}
                />
              </div>
              <div className="w-full font-satoshi rtl:font-Almarai mb-8 font-bold mt-9 text-xl">
                {t('COACH_ASSESSMENT.PARTY_TERMS')}
                <span className="mx-1 font-general rtl:font-Almarai font-thin tex-base text-[#687E8E]">
                  {t('COACH_ASSESSMENT.LINK_OR_DOC')}
                </span>
              </div>
              <FieldArray
                name="thirdParty"
                render={(arrayHelpers) => {
                  const formData: any =
                    values?.thirdParty?.length > 0 && values?.thirdParty;
                  const formDataErrors: any =
                    errors?.thirdParty && errors?.thirdParty;
                  const formDataTouched: any =
                    touched?.thirdParty && touched?.thirdParty;

                  return (
                    <div>
                      {formData && formData?.length > 0
                        ? formData?.map(
                            (accreditationRequest: any, i: number) => (
                              <div className="mt-8">
                                {i > 0 && (
                                  <div className="flex justify-end text-end border-info mt-8 lg:border-t-2">
                                    <button
                                      onClick={() => {
                                        arrayHelpers.remove(i);
                                        removeFileFromArray(i);
                                      }}
                                      className="mb-5 mt-9 text-12 font-bold text-red rtl:font-Almarai border-2 border-red px-4 rounded-md py-2"
                                    >
                                      {t('COACHING_DETAILS.REMOVE')}
                                    </button>
                                  </div>
                                )}
                                <Input
                                  id={`thirdParty.${i}.link`}
                                  type="text"
                                  name={`thirdParty.${i}.link`}
                                  placeholder={t('COACH_ASSESSMENT.LINK')}
                                  onBlur={handleBlur}
                                  value={values?.thirdParty[i].link}
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      `thirdParty.${i}.link`,
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    formData[i]?.file?.length === 0 &&
                                    formDataTouched?.length > 0 &&
                                    formDataTouched[i]?.link
                                  }
                                  errormessage={
                                    formData[i]?.file?.length === 0 &&
                                    formDataErrors?.length > 0
                                      ? formDataErrors[i]?.link
                                      : undefined
                                  }
                                />
                                <div className=" w-full flex items-center">
                                  <label
                                    htmlFor={`assessmentImageUploadDiv${i}`}
                                    className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit"
                                  >
                                    {t('COACH_SESSION.UPLOAD_IMAGE')}
                                  </label>
                                  <input
                                    className="hidden"
                                    id={`assessmentImageUploadDiv${i}`}
                                    accept="image/png, image/jpeg"
                                    type="file"
                                    onChange={(e: any) =>
                                      uploadImageFiles(e, i, setFieldValue)
                                    }
                                  />
                                  <span className="mx-3 font-general rtl:font-Almarai font-normal tex-base text-[#687E8E]">
                                    {formData?.length > 0
                                      ? formData[i]?.file.length > 0
                                        ? formData[i]?.file[0]?.name
                                        : []
                                      : t('COACH_ASSESSMENT.NO_IMAGE')}
                                    {searchParams.get('form') &&
                                      fileNameArray[i]}
                                  </span>
                                </div>
                                {values?.thirdParty[i].link === '' &&
                                  formDataTouched?.length > 0 &&
                                  formDataTouched[i]?.file && (
                                    <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
                                      {formDataErrors?.length > 0 &&
                                        ((formDataErrors[i]?.file as string)
                                          ? t('COACH_ASSESSMENT.FILE_REQ')
                                          : '')}
                                    </p>
                                  )}
                              </div>
                            )
                          )
                        : null}
                      <button
                        type="button"
                        className="add_more mt-6"
                        onClick={() => {
                          arrayHelpers.push({ file: [], link: '' });
                        }}
                      >
                        {t('ADD_MORE')}
                      </button>
                    </div>
                  );
                }}
              />
              <div className="w-full font-satoshi rtl:font-Almarai mb-4 font-bold mt-9 text-xl">
                {t('COACH_ASSESSMENT.DELIVERY_METHOD')}
              </div>
              <div className="flex flex-row mt-6">
                <input
                  id="FormGender"
                  type="radio"
                  name="deliveryMethod"
                  onChange={handleChange}
                  value="SESSION"
                  checked={values.deliveryMethod === 'SESSION'}
                  defaultChecked={true}
                />
                <span className="font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3">
                  {t('COACH_ASSESSMENT.SESSION')}
                </span>
                <input
                  id="FormGender"
                  type="radio"
                  name="deliveryMethod"
                  onChange={handleChange}
                  value="PDF"
                  checked={values.deliveryMethod === 'PDF'}
                />
                <span className="font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3">
                  {t('COACH_ASSESSMENT.PDF')}
                </span>
                <input
                  id="FormGender"
                  type="radio"
                  name="deliveryMethod"
                  placeholder={t('COACH_SESSION.TITLE')}
                  onChange={handleChange}
                  value="BOTH"
                  checked={values.deliveryMethod === 'BOTH'}
                />
                <span className="font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3">
                  {t('COACH_SESSION.BOTH')}
                </span>
              </div>
              {errors.deliveryMethod && (
                <p className="text-red rtl:font-Almarai font-normal text-12 pt-3 ">
                  {errors.deliveryMethod as string}
                </p>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
const CoachingAssessmentThirdParty = styled(Component)``;

export default CoachingAssessmentThirdParty;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-multi-date-picker/components/button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  getCoachSubscriptionById,
  selectLanding,
} from '../../clientSlices/landingPageSlice';
import { AccountListSideBar } from '../../coachComponents';
import { AdditionalFeatures } from '../../coachComponents/subscription/AdditionalFeatures';
import { CoreFeatures } from '../../coachComponents/subscription/CoreFeature';
import { paymentInfo } from '../../coachSlices/paymentSlice';
import Modal from '../../components/modals';

const Component = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { subScriptionId, purchased } = useSelector(paymentInfo);
  const { coachSubscribed } = useSelector(selectLanding);
  const [closeToggle, setCloseToggle] = useState(false);

  const handleSubmit = () => {};

  useEffect(() => {
    if (subScriptionId !== 0)
      dispatch(getCoachSubscriptionById(subScriptionId));
  }, [subScriptionId]);
  function toggleText() {
    // Get all the elements from the page
    var points = document.getElementById('points') as HTMLInputElement;

    var showMoreText = document.getElementById('moreText') as HTMLInputElement;

    var buttonText = document.getElementById('textButton') as HTMLInputElement;

    // If the display property of the dots
    // to be displayed is already set to
    // 'none' (that is hidden) then this
    // section of code triggers
    if (points.style.display === 'none') {
      // Hide the text between the span
      // elements
      showMoreText.style.display = 'none';

      // Show the dots after the text
      points.style.display = 'inline';

      // Change the text on button to
      // 'Show More'
      buttonText.innerHTML = 'Show More';
    }

    // If the hidden portion is revealed,
    // we will change it back to be hidden
    else {
      // Show the text between the
      // span elements
      showMoreText.style.display = 'inline';

      // Hide the dots after the text
      points.style.display = 'none';

      // Change the text on button
      // to 'Show Less'
      buttonText.innerHTML = 'Show Less';
    }
  }
  const showModal = () => {
    const handleUpdateClick = () => {
      navigate('/coach/subscription');
    };
    return (
      <Modal
        show={closeToggle}
        logOutClose={() => {
          setCloseToggle(false);
        }}
      >
        <div
          // onClick={() => deleteAccount()}
          className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center gap-7 "
        >
          <img
            className="md:h-[73px] md:w-[73px] h-[60px] w-[60px]"
            src="/assets/clientProfile/closeAccount.svg"
            alt=""
          />
          <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-Almarai">
            Do you want to upgrade?
          </div>
          <p className="font-12">
            Only on triggering edit service the new commissions will be applied
            to old services
          </p>
          <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12">
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => setCloseToggle(false)}
                    className="h-13 border  text-gray  font-satoshi rtl:font-Almarai font-bold text-18 flex flex-grow items-center justify-center rounded  w-[100%] md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CANCEL')}
                  </div>
                }
              />
            </div>
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => handleUpdateClick()}
                    className="h-13 bg-red text-white  font-satoshi rtl:font-Almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CONFIRM')}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <div className="mt-6 lg:mt-12 w-full h-fit">
      <div className="bg-white  w-full mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar handleSubmit={handleSubmit} />
        <div className="  w-full lg:p-8 p-4 col-span-5 lg:col-span-4 lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.PROFILE_INFO.MY_SUBSCRIPTION')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.NOTIFICATION.NOTIFICATION_DEC')}
          </p>
          <div className="border border-info mb-8"></div>
          {purchased && (
            <>
              <h1 className="text-20 font-bold font-satoshi rtl:font-Almarai text-secondary ">
                {t('PROFILE.PROFILE_INFO.ADDED_ACCOUNT')}
              </h1>
              <div className="lg:w-11/12 items-center bg-secondary justify-between rounded-lg py-3  px-4 mt-6">
                <div className=" flex">
                  <div className="w-[72px] h-[72px] subscription-icon-div">
                    <img
                      src="/assets/subscriptionPage/basic-subscription-inactive.svg"
                      className="mx-auto"
                      alt=""
                    />
                  </div>
                  <div className="flex rtl:mr-5 ltr:ml-5  w-full">
                    <div className="lg:flex lg:justify-between item-center w-full">
                      <div className="mt-1">
                        <h1 className="text-13 lg:text-17 font-semibold font-satoshi rtl:font-Almarai text-white ">
                          {/* {t('PROFILE.MY_SUB.EARLY_START')}
                           */}
                          {coachSubscribed?.headline}
                        </h1>
                        <p className="text-22 lg:text-28 font-bold text-white font-general rtl:font-Almarai">
                          {/* {t('PROFILE.MY_SUB.BASIC')} */}
                          {coachSubscribed?.title}
                        </p>
                      </div>
                      <div className="flex">
                        <div className="mt-3 text-white hidden lg:flex">
                          <div>
                            <span className="text-48">
                              {`${t('SAR')} `}
                              {coachSubscribed?.charge}
                            </span>
                            <span className=" font-semibold text-16">
                              {t('PROFILE.MY_SUB.ANNUAL')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex lg:hidden mt-4 text-white">
                  <span className="text-48">${coachSubscribed?.charge}</span>{' '}
                  <span className="mt-7 font-semibold text-16">
                    {t('PROFILE.MY_SUB.ANNUAL')}
                  </span>
                </div>
                <span id="points"></span>
                <div
                  id="moreText"
                  style={{ display: 'none' }}
                  className="text-white"
                >
                  <p className="mt-4 text-12 font-normal font-general rtl:font-Almarai ">
                    {t('PROFILE.MY_SUB.PRICE_DEC')}
                    {coachSubscribed?.description}
                  </p>
                  <div className="border-b border-borderBlack">
                    <div className="font-satoshi rtl:font-Almarai text-18 font-normal pt-6 flex">
                      <div className="w-[148px] font-semibold">
                        {t('SUBSCRIPTION_PORTAL.COMMISSION')}{' '}
                      </div>
                      :{' '}
                      <span className="px-1">
                        {coachSubscribed?.commission}
                      </span>{' '}
                      %
                    </div>
                    <div className="font-satoshi rtl:font-Almarai text-18 font-normal py-6 flex">
                      <div className="w-[148px] font-semibold">
                        {t('SUBSCRIPTION_PORTAL.FIXED_COMMISSION')}
                      </div>{' '}
                      : {t(`SAR`)}
                      <span className="ltr:pl-2 rtl:pr-2">
                        {coachSubscribed?.fixedCommission}
                      </span>
                    </div>
                  </div>
                  <div className=" font-satoshi rtl:font-Almarai py-5 border-b  border-borderBlack">
                    <div className="flex">
                      <div className=" w-[180px] text-18 font-semibold">
                        {t('SUBSCRIPTION_PORTAL.SESSION_COUNT')}
                      </div>
                      <div>:&nbsp;{coachSubscribed?.sessionCount}</div>
                    </div>
                    <div className="flex">
                      <div className=" w-[180px] text-18 font-semibold">
                        {t('SUBSCRIPTION_PORTAL.ASSESSMENT_COUNT')}
                      </div>
                      <div>:&nbsp;{coachSubscribed?.assessmentCount}</div>
                    </div>
                    <div className="flex">
                      <div className=" w-[180px] text-18 font-semibold">
                        {t('SUBSCRIPTION_PORTAL.PACKAGE_COUNT')}
                      </div>
                      <div>:&nbsp;{coachSubscribed?.packageCount}</div>
                    </div>
                  </div>
                  <p className="mt-6 font-bold text-14 font-satoshi rtl:font-Almarai ">
                    {t('PROFILE.MY_SUB.CORE_FEATURE')}
                  </p>
                  {coachSubscribed?.coreFeatures.map((item) => (
                    <div className="mt-4 flex">
                      <img
                        src="/assets/profilePageImage//priceItem.svg"
                        className="mr-2"
                        alt="logo"
                      />
                      <span className="text-12 font-normal text-white font-general rtl:font-Almarai">
                        <CoreFeatures id={item} />
                      </span>
                    </div>
                  ))}

                  <p className="mt-8 font-bold text-14 font-satoshi rtl:font-Almarai ">
                    {t('PROFILE.MY_SUB.ADDITIONAL')}
                  </p>
                  {coachSubscribed?.additionalFeatures.map((item) => (
                    <div className="mt-4 flex">
                      <img
                        src="/assets/profilePageImage//priceItem.svg"
                        className="mr-2"
                        alt="logo"
                      />
                      <span className="text-12 font-normal text-white font-general rtl:font-Almarai">
                        <AdditionalFeatures id={item} />
                      </span>
                    </div>
                  ))}
                </div>
                <button
                  onClick={toggleText}
                  className=" text-skyBlue text-13 font-semibold font-general rtl:font-Almarai mt-5 lg:ml-[84px]"
                  id="textButton"
                >
                  {t('PROFILE.MY_SUB.SHOW_MORE')}
                </button>
              </div>
            </>
          )}
          <div className="mt-6">
            <button
              form="my-form"
              type="submit"
              onClick={() =>
                purchased
                  ? setCloseToggle(true)
                  : navigate('/coach/subscription')
              }
              className=" w-full  lg:w-2/5 text-18 font-bold  py-4 px-9 text-white font-satoshi rtl:font-Almarai bg-primary rounded"
            >
              {purchased
                ? t('PROFILE.PROFILE_INFO.UPGRADE_PLAN')
                : t('PROFILE.PROFILE_INFO.CHOOSE_PLAN')}
            </button>
          </div>
          {showModal()}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

const MySubscription = styled(Component)``;

export default MySubscription;

const headerConst = {
  LOGIN: 'Login',
  SIGN_UP: 'Sign up',
  HOME: 'Home',
  CLIENT: 'Client',
  MY_UPLOADS: 'My uploads',
  PERFORMANCE: 'Performance',
  SCHEDULES: 'Schedules',
  CREATE_NEW: 'Create new',
};
export const LanguageConstants = new Map([
  ['ar', 'rtl'],
  ['en', 'ltr'],
]);
export default headerConst;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITradeRegistrationInterface } from '../../coachInterfaces/profileInterface';
import { postProfileFile } from '../../coachServices/apiService';
import {
  downloadById,
  getTradeRegistrations,
  postTradeRegistration,
} from '../../coachServices/authenticationService';
import { showAlert } from '../../slice/alert';

const initialState: any = {
  isTradeRegistrationUpdateSuccess: false,
  tradeRegistrationDetails: {},
  tradeRegistrationFileId: '',
  downloadTradeDocument: [],
};

export const tradeRegistrationSlice = createSlice({
  name: 'tradeRegistration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // COACH TRADE REGISTRATION ALL DATA
      .addCase(createTradeRegistration.pending, (state) => {
        state.isTradeRegistrationUpdateSuccess = false;
      })
      .addCase(createTradeRegistration.fulfilled, (state, action) => {
        state.isTradeRegistrationUpdateSuccess = true;
      })
      .addCase(createTradeRegistration.rejected, (state) => {
        state.isTradeRegistrationUpdateSuccess = true;
      })

      // TRADE REGISTRATION UPDATE
      .addCase(getTradeRegistration.pending, (state) => {})
      .addCase(getTradeRegistration.fulfilled, (state, action) => {
        state.tradeRegistrationDetails = action?.payload?.data[0];
      })
      .addCase(getTradeRegistration.rejected, (state) => {
        state.tradeRegistrationDetails = [];
      })
      .addCase(tradeRegistrationFile.fulfilled, (state, action) => {
        state.tradeRegistrationFileId = action?.payload?.data[0];
      })
      .addCase(tradeRegistrationFile.rejected, (state) => {})

      .addCase(downloadFileTradeRegistration.fulfilled, (state, action) => {
        state.downloadTradeDocument = action?.payload?.data[0];
      })
      .addCase(downloadFileTradeRegistration.rejected, (state) => {});
  },
});

//updateTradeRegistration APIS

export const createTradeRegistration = createAsyncThunk(
  'coachProfile',
  async (tradeData: ITradeRegistrationInterface, thunkAPI) => {
    try {
      const response = await postTradeRegistration(tradeData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//getTradeRegistration APIS

export const getTradeRegistration = createAsyncThunk(
  'getProfileExperience',
  async (_, thunkAPI) => {
    try {
      const response = await getTradeRegistrations();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const tradeRegistrationFile = createAsyncThunk(
  'editEducationFile',
  async (uploadFile: any, thunkAPI) => {
    try {
      const type = 'TRADE_REGISTER_DOCUMENT';
      const res = await postProfileFile(uploadFile, type);
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadFileTradeRegistration = createAsyncThunk(
  'downloadFileExperience',
  async (id: number, thunkAPI) => {
    try {
      const response = await downloadById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const tradeRegistrationReducer = tradeRegistrationSlice.reducer;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { IProfileSideBarInterface } from '../../coachInterfaces/profileInterface';
import './index.scss';

const AccountListSideBar = ({ handleSubmit }: any) => {
  const { pathname } = useLocation();
  const [navbar, setNavbar] = useState(true);
  const [t] = useTranslation('common');

  var name = 'Profile';

  const pages = [
    { item: t('PROFILE.PROFILE_INFO.PROFILE'), path: '/coach/me' },
    {
      item: t('PROFILE.PROFILE_INFO.BANKING_INFO'),
      path: '/coach/banking-info',
    },
    {
      item: t('PROFILE.PROFILE_INFO.TRADE_REGISTRATION'),
      path: '/coach/trade-registration',
    },
    {
      item: t('PROFILE.PROFILE_INFO.MY_SUBSCRIPTION'),
      path: '/coach/my-subscription',
    },
    {
      item: t('PROFILE.PROFILE_INFO.HOURS_SERVICES'),
      path: '/coach/hours-services',
    },
    {
      item: t('PROFILE.PROFILE_INFO.ACCOUNT_SECURITY'),
      path: '/coach/account-security',
    },
    // {
    //   item: t('PROFILE.PROFILE_INFO.NOTIFICATION'),
    //   path: '/coach/notification',
    // },
    {
      item: t('PROFILE.PROFILE_INFO.SOCIAL_MEDIA'),
      path: '/coach/social-media',
    },
    {
      item: t('PROFILE.PROFILE_INFO.CLOSE_ACCOUNT'),
      path: '/coach/close-account',
    },
  ];
  return (
    <div className="h-auto w-auto lg:col-span-1 col-span-5 rtl:ml-3 ltr:mr-3 ">
      <div className="bg-white  rounded-md px-5">
        <div className="flex">
          <h1 className=" w-11/12 text-start ml-4 lg:ml-3 text-xl font-bold my-4 font-satoshi rtl:font-Almarai bg-white py-2 rounded-md cursor-pointer text-gray">
            {t('PROFILE.PROFILE_INFO.ACCOUNT')}
          </h1>

          <div className="lg:hidden w-1/12 flex justify-center text-center text-xl font-bold my-4 font-satoshi rtl:font-Almarai bg-white py-2 rounded-md cursor-pointer text-gray">
            <button
              className="p-2 text-gray-700 rounded-md outline-none"
              onClick={() => setNavbar(!navbar)}
            >
              <img
                className="cursor-pointer"
                src="/assets/profilePageImage/button.svg"
                alt="icon"
              />
            </button>
          </div>
        </div>
        <div
          className={`bg-white rounded-md list-none ${
            !navbar ? 'block' : 'hidden'
          } lg:block`}
        >
          {pages.map((page: IProfileSideBarInterface, i: number) => {
            if (pathname === page.path) {
              name = page.item;
            }
            return (
              <Link key={i} to={page.path}>
                <li
                  className={`w-full list  ${
                    pathname === page.path
                      ? 'bg-[#c6eafc] px-2 rtl:border-r-4  ltr:border-l-4 border-[#00A5EC]'
                      : 'bg-white'
                  } `}
                >
                  {page?.item}
                </li>
              </Link>
            );
          })}
          {(pathname === '/coach/me' ||
            pathname === '/coach/trade-registration' ||
            pathname === '/coach/hours-services') && (
            <div className="hidden lg:flex justify-center items-center mt-8">
              <button
                form="my-form"
                type="submit"
                onClick={() => handleSubmit()}
                className={`bg-primary w-full py-3 font-satoshi rtl:font-Almarai px-4 font-bold text-18 text-white rounded`}
              >
                {t('PROFILE.PROFILE_INFO.SAVE_CHANGE')}
              </button>
            </div>
          )}
        </div>
        <p className=" px-6 py-3 text-secondary font-normal text-16  grid lg:hidden ml-4 bg-[#c6eafc] rtl:border-r-4 ltr:border-l-4 border-[#00A5EC] ">
          {name}
        </p>
      </div>
    </div>
  );
};

export default AccountListSideBar;

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import LandingPageBody from '../../clientComponents/bodyLandingPageAfterLogin';
import './index.css';
const LandingPageWithLogin = () => {
  const [t] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="form-header">
        <LandingPageBody active={false} />
      </div>
    </>
  );
};

export default LandingPageWithLogin;

/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ILandingPageInterface } from '../clientInterfaces/landingPageInterfaces';
import {
  fetchCoachOfTheMonth,
  fetchCoachSubscription,
  fetchCoachSubscriptionAdditionalFeature,
  fetchCoachSubscriptionById,
  fetchCoachSubscriptionCoreFeature,
  landingAssessmentConducted,
  landingClientCount,
  landingCoachCount,
  landingHoursCount,
} from '../clientServices/landingPage';
import { RootState } from '../store';

const initialState: ILandingPageInterface = {
  isProcessingServiceRequest: false,
  clientCount: 0,
  hoursCount: 0,
  coachCount: 0,
  assessmentCounducted: 0,
  isProcessingCoachSubRequest: false,
  isProcessingCoachSubRequestById: false,
  coachSub: [],
  coachSubscribed: {
    additionalFeatures: [],
    charge: 0,
    coreFeatures: [],

    description: '',
    headline: '',
    icon: '',
    id: '',
    saveAmount: 0,
    subscriptionType: '',
    title: '',
  },
  coachOfMonth: {
    id: '',
    fullName: '',
    email: '',
    dob: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    specialization: [],
    coachingCategories: [],
    accreditationResponses: null,
    educationResponses: null,
    experienceResponses: null,
    createdBy: '',
    createdAt: '',
    bioDescription: '',
    profilePicId: 0,
    languages: [],
    requestStatus: '',
    hoursAndServicesResponsesList: {
      responseList: [],
    },
    deleted: false,
  },
};
export const landingPageSlice = createSlice({
  name: 'landingPageSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCoachSubscriptionById.pending, (state) => {
        state.isProcessingCoachSubRequestById = true;
      })
      .addCase(getCoachSubscriptionById.fulfilled, (state, action) => {
        state.isProcessingCoachSubRequestById = false;
        state.coachSubscribed = action.payload?.data[0];
      })
      .addCase(getCoachSubscriptionById.rejected, (state) => {
        state.isProcessingCoachSubRequestById = false;
      })

      .addCase(getCoachSubscription.pending, (state) => {
        state.isProcessingCoachSubRequest = true;
      })
      .addCase(getCoachSubscription.fulfilled, (state, action) => {
        state.isProcessingCoachSubRequest = false;
        state.coachSub = action.payload?.data[0].content;
      })
      .addCase(getCoachSubscription.rejected, (state) => {
        state.isProcessingCoachSubRequest = false;
      })
      .addCase(getCoachCount.pending, (state) => {
        state.isProcessingServiceRequest = true;
      })
      .addCase(getCoachCount.fulfilled, (state, action) => {
        state.isProcessingServiceRequest = false;
        state.coachCount = action.payload?.data[0]?.count;
      })
      .addCase(getCoachCount.rejected, (state) => {
        state.isProcessingServiceRequest = false;
      })

      .addCase(getServiceHoursCount.pending, (state) => {
        state.isProcessingServiceRequest = true;
      })
      .addCase(getServiceHoursCount.fulfilled, (state, action) => {
        state.isProcessingServiceRequest = false;
        state.hoursCount = action.payload?.data[0]?.count;
      })
      .addCase(getServiceHoursCount.rejected, (state) => {
        state.isProcessingServiceRequest = false;
      })
      .addCase(getAssessmentConducted.pending, (state) => {
        state.isProcessingServiceRequest = true;
      })
      .addCase(getAssessmentConducted.fulfilled, (state, action) => {
        state.isProcessingServiceRequest = false;
        state.assessmentCounducted = action.payload?.data[0]?.count;
      })
      .addCase(getAssessmentConducted.rejected, (state) => {
        state.isProcessingServiceRequest = false;
      })
      .addCase(getClientCount.pending, (state) => {
        state.isProcessingServiceRequest = true;
      })
      .addCase(getClientCount.fulfilled, (state, action) => {
        state.isProcessingServiceRequest = false;
        state.clientCount = action.payload?.data[0]?.count;
      })
      .addCase(getClientCount.rejected, (state) => {
        state.isProcessingServiceRequest = false;
      })

      .addCase(getCoachOfTheMonth.fulfilled, (state, action) => {
        state.coachOfMonth = action.payload?.data[0];
      });
  },
});

export const getClientCount = createAsyncThunk(
  'auth/get-client-count',
  async (id, thunkAPI) => {
    try {
      const response = await landingClientCount();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachCount = createAsyncThunk(
  'auth/get-coach-count',
  async (id, thunkAPI) => {
    try {
      const response = await landingCoachCount();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getServiceHoursCount = createAsyncThunk(
  'auth/get-hours-count',
  async (id, thunkAPI) => {
    try {
      const response = await landingHoursCount();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getAssessmentConducted = createAsyncThunk(
  'auth/get-assessment-conducted',
  async (id, thunkAPI) => {
    try {
      const response = await landingAssessmentConducted();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachSubscription = createAsyncThunk(
  'auth/get-coach-sub',
  async (id, thunkAPI) => {
    try {
      const response = await fetchCoachSubscription();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachSubscriptionById = createAsyncThunk(
  'auth/get-coach-subById',
  async (id: number, thunkAPI) => {
    try {
      const response = await fetchCoachSubscriptionById(id);

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachSubscriptionCoreFeature = createAsyncThunk(
  'auth/get-coach-core-feature',
  async (id: string, thunkAPI) => {
    try {
      const response = await fetchCoachSubscriptionCoreFeature(id);

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachSubscriptionAdditinalFeature = createAsyncThunk(
  'auth/get-coach-core-feature',
  async (id: string, thunkAPI) => {
    try {
      const response = await fetchCoachSubscriptionAdditionalFeature(id);

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachOfTheMonth = createAsyncThunk(
  'auth/get-coach-month',
  async (id, thunkAPI) => {
    try {
      const response = await fetchCoachOfTheMonth();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const selectLanding = (state: RootState) => state.landingPageInfo;
export const landingPageReducer = landingPageSlice.reducer;

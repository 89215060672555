/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import {
  giftCard,
  imageUpload,
  invoice,
  order,
  paymentPurchase,
  paymentPurchaseTabby,
  purchaseHistory,
  purchaseOrder,
  purchases,
  register,
  tabbyPayment,
  tabbyPaymentStatus,
  tabbySaveToken,
  tokenPayment,
} from '../clientServices/payment';
import { formDataConverter } from '../coachHelpers/utility';
import { coachProfile } from '../coachSlices/profileSlices/profileInfoSlice';
import { showAlert } from '../slice/alert';
import { listProfileView } from './profile';

const initialState: any = {
  isProcessingPayment: false,
  orderData: {},
  myPurchase: {},
  purchaseHistoryList: {},
  paymentIdTabby: '',
  pdffil: null,
  isInvoicePdf: false,
  appliedPromotions: {},
  isTabbyPayment: false,
  isUrwayPayemt: false,
  messageErrorTabby: '',
  internationalImagefile: {
    name: '',
    file: '',
  },
};
export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setInternationalImageFileFormData: (state, action) => {
      state.internationalImagefile.name = action.payload.name;
      state.internationalImagefile.file = action.payload.file;
    },
    resetErrorTabbyPaymentType: (state, action) => {
      state.messageErrorTabby = '';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(paymentOrder.pending, (state, action) => {
        state.isProcessingpayment = true;
      })
      .addCase(paymentOrder.fulfilled, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(paymentOrder.rejected, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(orderView.pending, (state, action) => {
        state.isProcessingpayment = true;
      })
      .addCase(orderView.fulfilled, (state, action) => {
        state.orderData = action.payload.data[0];
        state.isProcessingpayment = false;
      })
      .addCase(orderView.rejected, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(purchaseViewMy.pending, (state, action) => {
        state.isProcessingpayment = true;
      })
      .addCase(purchaseViewMy.fulfilled, (state, action) => {
        state.myPurchase = action.payload.data[0].content;
        state.isProcessingpayment = false;
      })
      .addCase(purchaseViewMy.rejected, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(purchaseViewHistory.pending, (state, action) => {
        state.isProcessingpayment = true;
      })
      .addCase(purchaseViewHistory.fulfilled, (state, action) => {
        state.purchaseHistoryList = action.payload.data[0].content;
        state.isProcessingpayment = false;
      })
      .addCase(purchaseViewHistory.rejected, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(tabbyOrderCheck.pending, (state, action) => {
        state.isProcessingpayment = true;
      })
      .addCase(tabbyOrderCheck.fulfilled, (state, action) => {
        state.orderData = action.payload.data[0];
        state.isProcessingpayment = false;
      })
      .addCase(tabbyOrderCheck.rejected, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(createTokenPayment.pending, (state, action) => {
        state.isProcessingpayment = true;
      })
      .addCase(createTokenPayment.fulfilled, (state, action) => {
        state.paymentIdTabby = action.payload.data[0]?.payment?.id;
        state.isProcessingpayment = false;
      })
      .addCase(createTokenPayment.rejected, (state, action) => {
        state.isProcessingpayment = false;
      })
      .addCase(invoicePdf.pending, (state, action) => {
        state.isInvoicePdf = true;
      })
      .addCase(invoicePdf.fulfilled, (state, action) => {
        state.isInvoicePdf = false;
      })
      .addCase(invoicePdf.rejected, (state, action) => {
        state.isInvoicePdf = false;
      })
      .addCase(gitCardPromotions.fulfilled, (state, action) => {
        state.appliedPromotions = action.payload.data[0].data;
      })
      .addCase(paymentTabby.pending, (state, action) => {
        state.isTabbyPayment = true;
      })
      .addCase(paymentTabby.fulfilled, (state, action) => {
        state.isTabbyPayment = false;
      })
      .addCase(paymentTabby.rejected, (state, action: any) => {
        console.log(state, action);
        state.messageErrorTabby = action.payload.message;
        state.isTabbyPayment = false;
      })
      .addCase(payment.pending, (state, action) => {
        state.isUrwayPayemt = true;
      })
      .addCase(payment.fulfilled, (state, action) => {
        state.isUrwayPayemt = false;
      })
      .addCase(payment.rejected, (state, action) => {
        state.isUrwayPayemt = false;
      });
  },
});

export const paymentOrder = createAsyncThunk(
  'payment/paymentOrder',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await purchaseOrder(param.payload);
      if (response?.data[0]?.orderStatus === 'COMPLETED') {
        param.navigate('/order-status?status=SUCCESS');
        dispatch(listProfileView());
      } else if (param?.amount)
        param.navigate(
          `/payment?amount=${
            param?.amount - response?.data[0]?.redeemedAmount
          }&orderId=${response.data[0]?.id}`
        );
      dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const payment = createAsyncThunk(
  'payment/payment',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await paymentPurchase(param.data);
      window.location.replace(`${response.data[0].redirectUrl}`);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const paymentTabby = createAsyncThunk(
  'payment/paymentTabby',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await paymentPurchaseTabby(param.data);
      dispatch(resetErrorTabbyPaymentType(''));
      window.location.replace(
        `${response.data[0].configuration.available_products.installments[0].web_url}`
      );
      return response;
    } catch (err: any) {
      // dispatch(
      //   showAlert({
      //     type: 'error',
      //     message: err?.message,
      //   })
      // );
      return rejectWithValue(err);
    }
  }
);

export const orderView = createAsyncThunk(
  'payment/order',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await order(param);
      const userType = localStorage.getItem('userType');
      if (userType === 'CLIENT') dispatch(listProfileView());
      else if (userType === 'COACH') dispatch(coachProfile());

      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const tabbyOrderCheck = createAsyncThunk(
  'payment/tabbyOrderCheck',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await tabbyPaymentStatus(param.id);
      const userType = localStorage.getItem('userType');
      if (userType === 'CLIENT') dispatch(listProfileView());
      else if (userType === 'COACH') dispatch(coachProfile());
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );

      return rejectWithValue(err);
    }
  }
);

export const purchaseViewHistory = createAsyncThunk(
  'payment/purchaseViewHistory',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await purchaseHistory(payload);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const purchaseViewMy = createAsyncThunk(
  'payment/purchaseViewMy',
  async (body: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await purchases(body);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const registerTabby = createAsyncThunk(
  'payment/registerTabby',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await register(param);
      window.location.replace(
        `${response.data[0].configuration.available_products.installments[0].web_url}`
      );
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);
export const createTokenPayment = createAsyncThunk(
  'payment/createTokenPayment',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await tokenPayment(param.payload);
      const res = await tabbyPayment(response?.data[0]?.payment?.id);
      param.navigate('/order-status');
      dispatch(
        showAlert({
          type: 'success',
          message: res?.message,
          messageAr: res?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const saveTabbyToken = createAsyncThunk(
  'payment/saveTabbyToken',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await tabbySaveToken(param);
      const userType = localStorage.getItem('userType');
      if (userType === 'CLIENT') dispatch(listProfileView());
      else if (userType === 'COACH') dispatch(coachProfile());
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const invoicePdf = createAsyncThunk(
  'payment/invoicePdf',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await invoice(id);
      const pdfBlob = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'invoice.pdf';
      link.click();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const gitCardPromotions = createAsyncThunk(
  'payment/gitCardPromotions',
  async (gift: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await giftCard(gift);
      // dispatch(
      //   showAlert({
      //     type: 'success',
      //     message: response?.message,
      //   })
      // );
      return response;
    } catch (error: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: error?.message,
          messageAr: error?.messageAr,
        })
      );
      return rejectWithValue(error);
    }
  }
);
const oneMSizeByByte = 1024 * 1024;

export const uploadInternationalImage = createAsyncThunk(
  'imageUpload/upload',
  async (payload: any, thunkAPI) => {
    try {
        if(payload.file.size <= oneMSizeByByte) {
            const resp = await imageUpload(
                formDataConverter(payload.file, 'WIRE_TRANSFER_RECEIPT')
            );
            const wirePayload = {
                paymentMethod: 'WIRE_TRANSFER',
                orderId: payload.orderId,
                wireTransferReceiptId: resp?.data[0]?.id,
            };
            const response = await paymentPurchase(wirePayload);
            thunkAPI.dispatch(
                showAlert({
                    type: 'success',
                    message: 'Payment request completed',
                })
            );
            payload.navigate('/coach/home');
            return response;
        } else {
            thunkAPI.dispatch(
                showAlert({
                    type: 'err',
                    message: 'File size should be less than 1MB',
                })
            );
        }
    } catch (err: any) {
      await thunkAPI.dispatch(
        showAlert({
          type: 'err',
          message: err?.message,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const { setInternationalImageFileFormData, resetErrorTabbyPaymentType } =
  paymentSlice.actions;
export const selectPayment = (state: RootState) => state.payment;
export const paymentReducer = paymentSlice.reducer;

/* eslint-disable react/react-in-jsx-scope */
import { ReactElement } from "react";
interface ButtonType {
  type?: 'button' | 'submit' | 'reset' | undefined;
  className: string;
  children: ReactElement<string>;
  disabled?: boolean;
  onClick?():any;
}
const Button = ({
  type = 'submit',
  className,
  children,
  disabled,
  onClick,
}: ButtonType) => {
  const attributes = {
    className,
    children,
    type,
    disabled,
    onClick,
  };
  return (
    <button {...attributes} type={type} className={className} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};
export default Button;
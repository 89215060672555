import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export interface IPrivacyData {
  data: string;
  key: string;
}
const TermsAndConditions = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();

  const privacyDataAlphabetIndex = [
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_ONE'),
      key: 'A',
    },
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_TWO'),
      key: 'B',
    },
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_THREE'),
      key: 'C',
    },
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_FOUR'),
      key: 'D',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>

        <div className="text-justify sm:mx-20 sm:mb-20 sm:mt-8 m-4">
            <div
                onClick={() => navigate(-1)}
                className="bg-primary h-[45px] w-[45px] rounded flex items-center justify-center  pl-2  mb-8 rtl:rotate-180"
            >
                <ArrowBackIosIcon sx={{color: '#ffff'}}/>
            </div>
            <h2 className="font-satoshi rtl:font-Almarai  font-bold text-3xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.MAIN_HEADING')}
            </h2>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.INTRODUCTION')}
            </h2>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.INTRODUCTION_DESC')}
            </p>
            <h2 className=" rtl:font-Almarai font-bold text-3xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY')}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_INTRODUCTION')}
            </p>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST')}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_INTRODUCTION')}
            </p>
            <br/>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_1')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_2')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_3')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_4')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_5')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_6')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_7')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_8')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_9')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_10')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_11')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_12')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_13')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_14')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_15')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_16')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_17')}
                </li>
            </ol>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.CONDITIONS_POLICY')}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.CONDITIONS_POLICY_INTRODUCTION')}
            </p>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND')}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_INTRODUCTION')}
            </p>
            <br/>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_1')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_2')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_3')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p><span>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_4')}</span>
                        <br/>
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_4_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_4_2')}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_5')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6')}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_3')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_4')}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_7')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_8')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_9')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_10')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_11')}
                </li>

            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE')}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1')}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_3')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_4')}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2')}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_3')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_4')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_5')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_6')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_7')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_8')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_9')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_10')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_11')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_12')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_13')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_14')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_15')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_16')}</li>
                        </ul>
                    </p>
                </li>
            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR')}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1')}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_3')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_4')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_5')}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2')}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_3')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_4')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_5')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_6')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_7')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_8')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_9')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_10')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_11')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_12')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_13')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_14')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_15')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_16')}</li>
                        </ul>
                    </p>
                </li>
            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION')}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li>
                    <p>
                        <span
                            className={"text-lg"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST')}</span>
                        <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST_3')}</li>
                        </ol>
                    </p>
                </li>
                <li>
                    <p><span
                        className={"text-lg"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND')}</span>
                        <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND_1')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND_2')}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND_3')}</li>
                        </ol>
                    </p>
                </li>
            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE')}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_INTRODUCTION')}
            </p>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS')}
            </p>
            <ol className={"rtl:mr-2  list-disc rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_1')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_2')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_3')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_4')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_5')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_6')}
                </li>
            </ol>
            <p className=" rtl:font-Almarai text-lg">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_FINALIZE')}
            </p>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX')}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX_1')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX_2')}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX_3')}
                </li>
            </ol>
            <p className=" rtl:font-Almarai text-lg">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY__FINALIZE')}
            </p>
            {/*  <p className="rtl:font-Almarai ">*/}
            {/*<span className="font-satoshi rtl:font-Almarai font-bold">*/}
            {/*  {t('TERMS_AND_CONDITION.SUB_HEADING')}:*/}
            {/*</span>{' '}*/}
            {/*      {t('TERMS_AND_CONDITION.SUB_DESCRIPTION')}*/}
            {/*  </p>*/}
            {/*  <br/>*/}
            {/*  <p className="font-satoshi rtl:font-Almarai font-bold">*/}
            {/*      1) {t('TERMS_AND_CONDITION.NUMBERIC_DATA_ONE')}*/}
            {/*  </p>*/}
            {/*  <br/>*/}
            {/*  <>*/}
            {/*      {privacyDataAlphabetIndex.map((item, index) => {*/}
            {/*          return (*/}
            {/*              <>*/}
            {/*                  <p key={index} className="rtl:font-Almarai ">*/}
            {/*                      {item.key} . {item.data}*/}
            {/*                  </p>*/}
            {/*                  <br/>*/}
            {/*              </>*/}
            {/*          );*/}
            {/*      })}*/}
            {/*  </>*/}
        </div>
    </>
  );
};

export default TermsAndConditions;

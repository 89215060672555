import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setClientById, setCoachById } from '../../clientSlices/home';
import {
  chatContent,
  setRoomsAlert,
  setSocketObject,
} from '../../clientSlices/messages';
import { Image } from '../../components/Image';
import { useAppSelector } from '../../hooks';

const ChatList = ({
  messageListData,
  setMessageSelect,
  setRecieverId,
  messageSelect,
  setMobileViewContent,
  page,
}: any) => {
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search'));
  const [t] = useTranslation('common');

  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) setSearchParams({ search });
  };
  const { roomAlertIds } = useAppSelector((state) => state.messages);
  const array: string[] = [...roomAlertIds];

  const onChatListClick = (item: any) => {
    const filtered = array.filter((value: string) => value != item?.chatId);

    dispatch(setRoomsAlert([...filtered]));

    dispatch(setSocketObject({ key: 'chatContentData', value: [] }));
    if (item?.receiverName !== messageSelect?.name) {
      dispatch(setCoachById({}));
      dispatch(setClientById({}));
    }
    setMessageSelect({
      name: item?.receiverName,
      roomId: item?.chatId,
    });
    dispatch(chatContent({ id: item?.chatId, page: 0 }));
    setRecieverId(item?.receiverId);
    dispatch(setSocketObject({ key: 'roomId', value: item?.chatId }));
    setMobileViewContent('CHAT');
  };
  const getAlert = (roomId: string) => {
    return roomAlertIds?.some((item: string) => item === roomId);
  };

  return (
    <div className="flex flex-col pb-10 rounded-lg boxShadow  xll:w-[30%] w-[100%]">
      <div className="px-3  pt-4 flex flex-col gap-4 ">
        <div className="font-satoshi rtl:font-Almarai text-22 font-bold">
          {t('CONTACTS')}
        </div>
        <form>
          <div className=" my-upload-search-box ">
            <div className="w-[30px]  h-[44px] flex items-center justify-center">
              <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
                <img
                  src="/assets/gridView/searchIcon.svg"
                  id={search as string}
                  alt=""
                />
              </button>
            </div>
            <input
              type="text"
              id="simple-search"
              className="text-gray-900 text-sm h-[42px] search-input  w-[85%] rtl:font-Almarai"
              placeholder={'Search...'}
              required
              onChange={(e: any) => {
                setSearch(e.target.value);
              }}
              value={search as string}
            />
            {search && (
              <img
                onClick={() => {
                  setSearchParams();
                  setSearch('');
                }}
                className="cursor-pointer"
                src="/assets/subscriptionPage/plusIcon.svg"
                alt="bell-icon"
              />
            )}
          </div>
        </form>
      </div>
      {messageListData?.length ? (
        <div className="pt-5">
          {messageListData.map((item: any) => (
            <div
              onClick={() => onChatListClick(item)}
              className={`px-3 flex gap-3 pt-4 ltr:border-l-2  cursor-pointer rtl:border-r-2 ${
                item?.receiverName === messageSelect?.name
                  ? 'bg-transperantBlue  border-primary'
                  : 'border-white'
              }`}
            >
              <Image
                id={item?.profilePicId}
                propClass={'w-10 h-10 rounded-full bg-lightBlack'}
                bgClass={
                  'w-10 h-10  flex items-center justify-center rounded-full bg-lightBlack'
                }
                bgImgUrl={'/assets/profile/avatar.svg'}
              />
              <div className="border-b border-lightBlack flex pb-4 gap-3 w-[100%] flex justify-between ">
                <div className="flex justify-between flex-col w-[calc(20vw-130px)]">
                  <div className="font-general rtl:font-Almarai font-semibold text-16">
                    {item?.receiverName}
                  </div>
                  <div className=" truncate font-normal font-general rtl:font-Almarai text-12 text-gray">
                    {item?.latestMessage}{' '}
                  </div>
                </div>
                <div className="flex flex-col justify-between items-end  w-10">
                  <div className="font-general rtl:font-Almarai text-10 text-normal text-gray">
                    {/* {`${moment(item?.createdAt).format('HH:mm A')}`} */}
                    {moment.utc(item.createdAt).locale('en').format('h:mm a')}
                  </div>
                  {getAlert(item.chatId) && (
                    <div className="bg-primary w-3 h-3 rounded-full "></div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="h-[30vh] w-full flex justify-center items-center">
          No chats found
        </div>
      )}
    </div>
  );
};

export default ChatList;

import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../clientComponents/buttons';
import {
  downloadTransaction,
  performanceInfo,
} from '../../coachSlices/coachPerformance';
import Table from '../coachTable';

interface ITransactionsrops {
  coachRevenueClients: any;
  isLoading: boolean;
}
const TransactionsTable: FC<ITransactionsrops> = ({
  coachRevenueClients,
  isLoading,
}): JSX.Element => {
  const [t] = useTranslation('common');
  const { clientsFilter } = useSelector(performanceInfo);

  const header = [
    {
      label: t('PREFORMANCE.AMOUNT'),
      value: 'amount',
    },
    {
      label: t('PREFORMANCE.DATE'),
      value: 'date',
    },
    {
      label: t('PREFORMANCE.CURRENCY'),
      value: 'currency',
    },
    {
      label: t('PREFORMANCE.WITHDRAW_STATUS'),
      value: 'withDrawStatus',
    },
    {
      label: t('PREFORMANCE.COMMENT'),
      value: 'comments',
    },
    {
      label: t('PREFORMANCE.RECEIPT'),
      value: 'download',
    },
  ];
  const disptach = useDispatch<any>();
  const download = (id: number) => {
    disptach(downloadTransaction(id));
  };
  const data = coachRevenueClients?.map((item: any) => {
    return {
      amount: item?.amount,
      date: moment(item?.createdAt).locale('en').format('MMM DD, YYYY'),
      currency: item?.beneficiaryCurrency,
      withDrawStatus: item?.withdrawnStatus,
      comments: item?.comments,
      download: (
        <Button
          className={''}
          onClick={() => download(item?.id)}
          children={
            <div className="text-14 font-general rtl:font-Almarai text-white bg-primary rounded px-2 py-2">
              {t('PREFORMANCE.DOWNLOAD')}
            </div>
          }
        />
      ),
      //   enrolledItem: item?.data?.title,
      //   purchasedDate: item?.createdAt,
      //   offering: `${item?.data?.duration} ${t('MINS')}`,
      //   rating: item?.data?.rating,
      //   price: `${t('SAR')} ${item?.data?.price}`,
      //   startDate: moment(item?.startDate).format('MMM DD, YYYY'),
      //   endDate: moment(item?.endDate).format('MMM DD, YYYY'),
      //   category: item?.data?.serviceType,
      //   remainingDays: (
      //     <div className="text-[12px] font-bold ">
      //       {item?.remainingDays} {t('PREFORMANCE.DAYS')}
      //     </div>
      //   ),
    };
  });

  return (
    <div className="md:block hidden">
      <Table
        header={header}
        data={data}
        loading={isLoading}
        category={''}
        profilePicture={undefined}
        profilePictureLoading={false}
      />
    </div>
  );
};

export default TransactionsTable;

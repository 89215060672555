import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  downloadTransaction,
  performanceInfo,
} from '../../coachSlices/coachPerformance';
import NoData from '../../components/noData';
import Button from '../buttons';

interface ITransactionCardProps {
  coachRevenueClients: any;
  isLoading: boolean;
}
const TransactionCard: FC<ITransactionCardProps> = ({
  coachRevenueClients,
  isLoading,
}): JSX.Element => {
  const { clientsFilter } = useSelector(performanceInfo);
  const navigate = useNavigate();
  const disptach = useDispatch<any>();
  const download = (id: number) => {
    disptach(downloadTransaction(id));
  };
  const [t] = useTranslation('common');

  const mobileViewTableData = coachRevenueClients?.map((item: any) => [
    {
      title1: t('PREFORMANCE.AMOUNT'),
      value: item?.amount,
    },
    {
      title1: t('PREFORMANCE.DATE'),
      value: moment(item?.createdAt).locale('en').format('MMM DD, YYYY'),
    },
    {
      title1: t('PREFORMANCE.CURRENCY'),
      value: item?.beneficiaryCurrency,
    },
    {
      title1: t('PREFORMANCE.WITHDRAW_STATUS'),
      value: item?.withdrawnStatus,
    },
    { title1: t('PREFORMANCE.COMMENT'), value: item?.comments },
    {
      title1: t('PREFORMANCE.RECEIPT'),
      value: (
        <Button
          className={''}
          onClick={() => download(item?.id)}
          children={
            <div className="text-14 font-general rtl:font-Almarai text-white bg-primary rounded px-2 py-2">
              {t('PREFORMANCE.DOWNLOAD')}
            </div>
          }
        />
      ),
    },
  ]);

  return coachRevenueClients?.length ? (
    <div>
      <div className=" flex flex-col gap-4 md:hidden">
        {mobileViewTableData.map((item: any) => (
          <ul className="border rounded border-disableGray shadow-lg ">
            {item.map((itemValue: any) => (
              <li className="flex flex-row  justify-between border-b border-disableGray">
                <div className="p-4 text-11 font-semibold font-general rtl:font-Almarai text-gray">
                  {itemValue.title1}
                </div>
                <div
                  className={`text-12 font-normal font-general rtl:font-Almarai p-4 flex flex-row items-center gap-1 ${
                    ['Revenue', 'item'].includes(itemValue.title1) &&
                    'font-bold'
                  }`}
                >
                  {itemValue.value}
                </div>
              </li>
            ))}
            {clientsFilter?.serviceStatusFilter !== 'CLOSED' &&
              clientsFilter?.serviceStatusFilter !== '' && (
                <li className="flex flex-row  justify-center border-b border-disableGray p-5">
                  <div
                    onClick={() => navigate('/schedule')}
                    className={
                      ' w-fit flex justify-center py-2 px-4 rounded  font-normal text-13 font-general rtl:font-Almarai pt-2 px-4 bg-primary text-[#FFFFFF] cursor-pointer'
                    }
                  >
                    {t('PREFORMANCE.VIEW_CALENDAR')}
                  </div>
                </li>
              )}
          </ul>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex  md:hidden justify-center  h-56 items-center text-light-black font-general rtl:font-Almarai font-normal">
      <NoData />
    </div>
  );
};

export default TransactionCard;

import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../coachComponents/buttons';
import CreateCoachAssessment from '../../coachComponents/createAssessment/AssessmentLanding';
import CoachingAssessmentPrice from '../../coachComponents/createAssessment/CoachAssessmentPricing';
import IntendedClientsAssessment from '../../coachComponents/createAssessment/IntendedClientsAssessment';
import CoachingAssessmentThirdParty from '../../coachComponents/createAssessment/ThirdParty';
import {
  assessmentInterface,
  assessmentPayloadInterface,
  coachingPriceAssessmentFormValueInterface,
  intendedClientAssessmentFormValueInterface,
  thirdPartyInterface,
  thirdPartyProviderRequestInterface,
} from '../../coachInterfaces/assessmentInterface';
import { tagsInterface } from '../../coachInterfaces/sessionInterface';
import {
  assessmentInfo,
  clearAssessmentFormData,
  clearAssessmentImageFileFormData,
  clearCoachingPriceAssessmentFormData,
  clearIntendedClientAssessmentFormData,
  clearThirdPartyAssessmentFormData,
  clearThirdPartyFileIds,
  editCreateAssessment,
  postCreateAssessment,
  saveCreateAssessment,
  saveDraftAssessment,
  setAssessmentImageFileFormData,
} from '../../coachSlices/assessmentSlice';
import {
  coachHomeInfo,
  fetchAssessmentDraftById,
} from '../../coachSlices/coachHomeSlice';
import {
  fetchAssessmentById,
  myUploadInfo,
} from '../../coachSlices/myUploadSlice';
import {
  fetchAssessmentTitles,
  fetchSessionTitles,
  listPackageDuration,
} from '../../coachSlices/packageSlice';
import {
  listCategories,
  listCategoriesTag,
} from '../../coachSlices/sessionSlice';
import Modal from '../../components/modals';
import { useAppSelector } from '../../hooks';
import { showAlert } from '../../slice/alert';
import { CustomIcon } from '../coachSession';
import './index.css';

const Component = () => {
  const sessionFormRef: any = useRef();
  const intendFormRef: any = useRef();
  const priceFormRef: any = useRef();
  const thirdFormRef: any = useRef();
  const dispatch = useDispatch<any>();
  const [closeToggle, setCloseToggle] = useState(false);
  const location = useLocation();
  const [t] = useTranslation('common');
  const [menu, setMenu] = useState<string>('ASSESSMENT_LANDING');
  const { lang } = useAppSelector((state) => state.language);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isCleared, setIsCleared] = useState<boolean>(false);

  const [menuName, setMenuName] = useState<string>(
    lang === 'en' ? 'Assessment landing page' : 'صفحة التقييم'
  );
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isProcessingAssessmentById } = useSelector(myUploadInfo);
  const { isProcessingfetchAssessmentDraftById } = useSelector(coachHomeInfo);

  const {
    assessmentFormValue,
    intendedClientAssessmentFormValue,
    coachingPriceAssessmentFormValue,
    isProcessingAssessmentCreate,
    isProcessingAssessmentSave,
    assessmentImagefile,
    thirdPartyProviderRequest,
    isProcessingAssessmentEdit,
  } = useSelector(assessmentInfo);
  useEffect(() => {
    const callEdit = async () => {
      setIsCleared(false);
      if (
        searchParams.get('form') === 'EDIT' ||
        searchParams.get('form') === 'DRAFT'
      ) {
        if (searchParams.get('id') && searchParams.get('form') === 'EDIT') {
          dispatch(
            fetchAssessmentById({
              id: searchParams.get('id') as string,
              navigate: navigate,
            })
          );
        }
        if (searchParams.get('id') && searchParams.get('form') === 'DRAFT') {
          dispatch(fetchAssessmentDraftById(searchParams.get('id') as string));
        }
      } else {
        dispatch(clearAssessmentFormData());
        dispatch(clearIntendedClientAssessmentFormData());
        dispatch(clearCoachingPriceAssessmentFormData());
        dispatch(clearThirdPartyAssessmentFormData());
        dispatch(clearThirdPartyFileIds());
        dispatch(
          setAssessmentImageFileFormData({
            url: '',
            name: '',
            file: '',
            fileId: '',
          })
        );
      }
      setIsCleared(true);
    };
    callEdit();
  }, []);
  useEffect(() => {
    dispatch(fetchAssessmentTitles());
    dispatch(fetchSessionTitles());
    dispatch(listPackageDuration());
    dispatch(listCategories());
    dispatch(listCategoriesTag());
  }, []);
  useEffect(() => {
    if (
      assessmentFormValue.title !== '' ||
      assessmentFormValue.subTitle !== '' ||
      assessmentFormValue.description !== '' ||
      // assessmentFormValue.deliveryDuration !== '' ||
      assessmentFormValue.language !== '' ||
      intendedClientAssessmentFormValue.gender !== '' ||
      thirdPartyProviderRequest.title !== '' ||
      thirdPartyProviderRequest.provider !== '' ||
      thirdPartyProviderRequest.thirdParty[0]?.file?.length > 0 ||
      thirdPartyProviderRequest.thirdParty[0]?.link !== '' ||
      coachingPriceAssessmentFormValue.price !== ''
    ) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [
    assessmentFormValue,
    intendedClientAssessmentFormValue,
    thirdPartyProviderRequest,
    coachingPriceAssessmentFormValue,
  ]);
  const getTagsCondition = (forWhom: string[], tags: tagsInterface[]) => {
    const isPresent = forWhom?.some((item: string) => item === 'Others');

    if (isPresent) {
      return tags?.length > 0 ? true : false;
    } else return true;
  };
  const handleSaveClick = () => {
    const payload: assessmentPayloadInterface = createAssessmentPayloadCreator(
      assessmentFormValue,
      intendedClientAssessmentFormValue,
      coachingPriceAssessmentFormValue,
      thirdPartyProviderRequest
    );
    if (searchParams.get('id') && searchParams.get('form') === 'DRAFT') {
      dispatch(
        saveDraftAssessment({
          payload: payload,
          id: searchParams.get('id') as string,
          navigate,
        })
      );
    } else dispatch(saveCreateAssessment({ payload, navigate }));
  };
  const handleUpdateClick = () => {
    setCloseToggle(false);
    if (sessionFormRef.current) {
      sessionFormRef.current?.handleSubmit();
    }
    if (intendFormRef.current) {
      intendFormRef.current?.handleSubmit();
    }

    if (priceFormRef.current) {
      priceFormRef.current?.handleSubmit();
    }
    if (thirdFormRef.current) {
      thirdFormRef.current?.handleSubmit();
    }
    if (
      assessmentFormValue.title !== '' &&
      assessmentFormValue.subTitle !== '' &&
      assessmentFormValue.description !== '' &&
      assessmentFormValue.category !== ''
      // assessmentFormValue.duration !== ''
    ) {
      if (
        intendedClientAssessmentFormValue.gender !== '' &&
        getTagsCondition(
          intendedClientAssessmentFormValue.forWhom,
          intendedClientAssessmentFormValue.tags
        )
      ) {
        if (
          thirdPartyProviderRequest.title !== '' &&
          thirdPartyProviderRequest.provider !== '' &&
          (thirdPartyProviderRequest.thirdParty[0]?.file?.length > 0 ||
            thirdPartyProviderRequest.thirdParty[0]?.link !== '')
        ) {
          if (coachingPriceAssessmentFormValue.price !== '') {
            if (
              assessmentImagefile.file !== '' ||
              assessmentImagefile.fileId !== ''
            ) {
              const payload: assessmentPayloadInterface =
                createAssessmentPayloadCreator(
                  assessmentFormValue,
                  intendedClientAssessmentFormValue,
                  coachingPriceAssessmentFormValue,
                  thirdPartyProviderRequest
                );
              if (searchParams.get('id'))
                dispatch(
                  editCreateAssessment({
                    data: payload,
                    id: searchParams.get('id') as string,
                    navigate: navigate,
                  })
                );
            } else {
              setMenu('ASSESSMENT_LANDING');
              setMenuName(t('COACH_ASSESSMENT.ASSESSMENT_LANDING'));

              dispatch(
                showAlert({
                  type: 'error',
                  message: 'Please add an image',
                })
              );
            }
          } else {
            setMenu('PRICING');
            setMenuName(t('COACH_SESSION.PRICING'));
          }
        } else {
          setMenu('THIRD_PARTYS');
          setMenuName(t('COACH_ASSESSMENT.THIRD_PARTY'));
        }
      } else {
        setMenu('INTENDED_CLIENTS');
        setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
      }
    } else {
      setMenu('ASSESSMENT_LANDING');
      setMenuName(t('COACH_ASSESSMENT.ASSESSMENT_LANDING'));
    }
  };
  const handleClick = () => {
    if (sessionFormRef.current) {
      sessionFormRef.current?.handleSubmit();
    }
    if (intendFormRef.current) {
      intendFormRef.current?.handleSubmit();
    }

    if (priceFormRef.current) {
      priceFormRef.current?.handleSubmit();
    }
    if (thirdFormRef.current) {
      thirdFormRef.current?.handleSubmit();
    }
    if (
      assessmentFormValue.title !== '' &&
      assessmentFormValue.subTitle !== '' &&
      assessmentFormValue.description !== '' &&
      assessmentFormValue.category !== ''
      // assessmentFormValue.duration !== ''
    ) {
      if (
        intendedClientAssessmentFormValue.gender !== '' &&
        getTagsCondition(
          intendedClientAssessmentFormValue.forWhom,
          intendedClientAssessmentFormValue.tags
        )
      ) {
        if (
          thirdPartyProviderRequest.title !== '' &&
          thirdPartyProviderRequest.provider !== '' &&
          (thirdPartyProviderRequest.thirdParty[0]?.file?.length > 0 ||
            thirdPartyProviderRequest.thirdParty[0]?.link !== '')
        ) {
          if (coachingPriceAssessmentFormValue.price !== '') {
            if (
              assessmentImagefile.file !== '' ||
              assessmentImagefile.fileId !== ''
            ) {
              const payload: assessmentPayloadInterface =
                createAssessmentPayloadCreator(
                  assessmentFormValue,
                  intendedClientAssessmentFormValue,
                  coachingPriceAssessmentFormValue,
                  thirdPartyProviderRequest
                );

              dispatch(
                postCreateAssessment({
                  payload: {
                    payload: payload,
                    draft: searchParams.get('form') === 'DRAFT' ? 'DRAFT' : '',
                    id: searchParams.get('id')
                      ? (searchParams.get('id') as string)
                      : ('' as string),
                  },
                  navigate,
                })
              );
            } else {
              setMenu('ASSESSMENT_LANDING');
              setMenuName(t('COACH_ASSESSMENT.ASSESSMENT_LANDING'));

              dispatch(
                showAlert({
                  type: 'error',
                  message: 'Please add an image',
                })
              );
            }
          } else {
            setMenu('PRICING');
            setMenuName(t('COACH_SESSION.PRICING'));
          }
        } else {
          setMenu('THIRD_PARTYS');
          setMenuName(t('COACH_ASSESSMENT.THIRD_PARTY'));
        }
      } else {
        setMenu('INTENDED_CLIENTS');
        setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
      }
    } else {
      setMenu('ASSESSMENT_LANDING');
      setMenuName(t('COACH_ASSESSMENT.ASSESSMENT_LANDING'));
    }
  };
  const createAssessmentPayloadCreator = (
    assessmentFormValue: assessmentInterface,
    intendedClientAssessmentFormValue: intendedClientAssessmentFormValueInterface,
    coachingPriceAssessmentFormValue: coachingPriceAssessmentFormValueInterface,
    thirdPartyProviderRequest: thirdPartyProviderRequestInterface
  ) => {
    let whatWillLearn: string[] = [];
    let requirements: string[] = [];
    let benefits: string[] = [];
    let forWhom: string[] = [];
    intendedClientAssessmentFormValue?.whatWillLearn?.map((item: string) => {
      if (item !== '') {
        whatWillLearn.push(item);
      }
    });
    intendedClientAssessmentFormValue?.requirements?.map((item: string) => {
      if (item !== '') {
        requirements.push(item);
      }
    });
    intendedClientAssessmentFormValue?.benefits?.map((item: string) => {
      if (item !== '') {
        benefits.push(item);
      }
    });
    intendedClientAssessmentFormValue?.forWhom?.map((item: string) => {
      if (item !== null) {
        forWhom.push(item);
      }
    });
    if (
      intendedClientAssessmentFormValue?.forWhom.some(
        (item: string) => item === 'Others'
      )
    ) {
      intendedClientAssessmentFormValue?.tags?.map((item: tagsInterface) => {
        forWhom.push(item?.value);
      });
    }
    let termsOfServiceUrl: string[] = [];
    let files: any[] = [];
    thirdPartyProviderRequest?.thirdParty?.map((item: thirdPartyInterface) => {
      if (item.link !== '') {
        termsOfServiceUrl.push(item.link);
      }
      if (item?.file?.length) {
        files.push(Array.from(item.file));
      }
    });
    const payloadData = {
      ...coachingPriceAssessmentFormValue,
      ...assessmentFormValue,
      categoryTags: assessmentFormValue?.categoryTags?.map(
        (item: tagsInterface) => {
          return item?.value;
        }
      ),
      intendedClientRequest: {
        gender: intendedClientAssessmentFormValue?.gender,
        whatWillLearn: whatWillLearn,
        requirements: requirements,
        benefits: benefits,
        forWhom: forWhom,
      },
      thirdPartyProviderRequest: {
        title: thirdPartyProviderRequest.title,
        provider: thirdPartyProviderRequest.provider,
        deliveryMethod: thirdPartyProviderRequest.deliveryMethod,
        file: files,
        termsOfServiceUrl: termsOfServiceUrl,
      },
    };

    return payloadData;
  };

  const CoachAssessmentContent = [
    {
      title: t('COACH_ASSESSMENT.ASSESSMENT_LANDING'),
      value: 'ASSESSMENT_LANDING',
      icon: SchoolOutlinedIcon,
    },
    {
      title: t('COACH_SESSION.INTENDED_CLIENTS'),
      value: 'INTENDED_CLIENTS',
      icon: PeopleAltOutlinedIcon,
    },
    {
      title: t('COACH_ASSESSMENT.THIRD_PARTY'),
      value: 'THIRD_PARTYS',
      icon: DynamicFeedOutlinedIcon,
    },
    {
      title: t('COACH_SESSION.PRICING'),
      value: 'PRICING',
      icon: SellOutlinedIcon,
    },
  ];

  const CoachAssessmentComponent = [
    {
      component: <CreateCoachAssessment sessionFormRef={sessionFormRef} />,
      value: 'ASSESSMENT_LANDING',
    },
    {
      component: <IntendedClientsAssessment intendFormRef={intendFormRef} />,
      value: 'INTENDED_CLIENTS',
    },
    {
      component: <CoachingAssessmentThirdParty thirdFormRef={thirdFormRef} />,
      value: 'THIRD_PARTYS',
    },
    {
      component: <CoachingAssessmentPrice priceFormRef={priceFormRef} />,
      value: 'PRICING',
    },
  ];
  const handleNextClick = () => {
    switch (menu) {
      case 'ASSESSMENT_LANDING':
        if (sessionFormRef.current) {
          sessionFormRef.current?.handleSubmit();
        }
        if (
          assessmentFormValue.title !== '' &&
          assessmentFormValue.subTitle !== '' &&
          assessmentFormValue.description !== '' &&
          assessmentFormValue.category !== ''
          // assessmentFormValue.duration !== ''
        ) {
          setMenu('INTENDED_CLIENTS');
          setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
        }
        break;
      case 'INTENDED_CLIENTS':
        if (intendFormRef.current) {
          intendFormRef.current?.handleSubmit();
        }
        if (
          intendedClientAssessmentFormValue.gender !== '' &&
          getTagsCondition(
            intendedClientAssessmentFormValue.forWhom,
            intendedClientAssessmentFormValue.tags
          )
        ) {
          setMenu('THIRD_PARTYS');
          setMenuName('Third party’s');
        }
        break;

      case 'THIRD_PARTYS':
        if (thirdFormRef.current) {
          thirdFormRef.current?.handleSubmit();
        }
        if (
          thirdPartyProviderRequest.title !== '' &&
          thirdPartyProviderRequest.provider !== '' &&
          (thirdPartyProviderRequest.thirdParty[0]?.file?.length > 0 ||
            thirdPartyProviderRequest.thirdParty[0]?.link !== '')
        ) {
          setMenu('PRICING');
          setMenuName(t('COACH_SESSION.PRICING'));
        }
        break;
    }
  };
  const onCancelClick = () => {
    if (location.pathname === '/coach/coach-assessment') {
      navigate(-1);
    } else {
      navigate('/coach/create-session');
      if (sessionFormRef.current) {
        sessionFormRef.current?.resetForm();
      }
      dispatch(clearAssessmentFormData());
      dispatch(clearAssessmentImageFileFormData());
      if (thirdFormRef.current) {
        thirdFormRef.current?.resetForm();
      }
      dispatch(clearThirdPartyAssessmentFormData());
      if (intendFormRef.current) {
        intendFormRef.current?.resetForm();
      }
      dispatch(clearIntendedClientAssessmentFormData());
      if (priceFormRef.current) {
        priceFormRef.current?.resetForm();
      }
      dispatch(clearCoachingPriceAssessmentFormData());
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('KUN_COACH')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Modal
        show={closeToggle}
        logOutClose={() => {
          setCloseToggle(false);
        }}
      >
        <div
          // onClick={() => deleteAccount()}
          className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center gap-7 "
        >
          <img
            className="md:h-[73px] md:w-[73px] h-[60px] w-[60px]"
            src="/assets/clientProfile/closeAccount.svg"
            alt=""
          />
          <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-Almarai">
            {t('CLIENT_PROFILE.CONTINUE')}
          </div>
          <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12">
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => setCloseToggle(false)}
                    className="h-13 border  text-gray  font-satoshi rtl:font-Almarai font-bold text-18 flex flex-grow items-center justify-center rounded  w-[100%] md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CANCEL')}
                  </div>
                }
              />
            </div>
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => handleUpdateClick()}
                    className="h-13 bg-red text-white  font-satoshi rtl:font-Almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CONFIRM')}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="coach-session-container ">
        <div className="coach-session-nav ">
          <div>
            <div className="h-14 w-full p-5 mb-3 font-satoshi rtl:font-Almarai  font-bold text-2xl">
              {t('COACH_ASSESSMENT.ASSESSMENT')}
            </div>
            {CoachAssessmentContent.map(({ title, value, icon }) =>
              value === menu ? (
                <div className="h-14 w-full flex flex-row">
                  <div className="h-full w-[3px] bg-[#02A4EA]" />
                  <div className="h-full w-full px-6 flex items-center  bg-[#00a5ec1a] rtl:font-Almarai">
                    <CustomIcon Icon={icon} color={'#153243'} />
                    {title}
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setMenu(value)}
                  className="h-14 w-full flex flex-row cursor-pointer"
                >
                  <div className="h-full w-[3px] " />
                  <div className="h-full w-full px-6 flex items-center  rtl:font-Almarai">
                    <CustomIcon Icon={icon} color={'#687E8E'} />
                    {title}
                  </div>
                </div>
              )
            )}
          </div>
          <div>
            {searchParams.get('form') === 'EDIT' ? (
              <Button
                htmlFor="submitSessionButton"
                form="my-form"
                type="submit"
                onClick={() => setCloseToggle(true)}
                className="bg-primary text-white btn-small m-2 w-full flex justify-around justify-center h-fit overflow-hidden"
              >
                <>
                  {isProcessingAssessmentEdit && <div className="loader" />}
                  {t('UPDATE')}
                </>
              </Button>
            ) : (
              <>
                <Button
                  htmlFor="submitSessionButton"
                  form="my-form"
                  type="submit"
                  onClick={() => handleClick()}
                  className="bg-primary text-white btn-small m-2 w-full flex justify-around h-fit overflow-hidden"
                >
                  <>
                    {isProcessingAssessmentCreate && <div className="loader" />}
                    {t('COACH_SESSION.SUBMIT_REVIEW')}
                  </>
                </Button>
                {isSaveDisabled && (
                  <Button
                    onClick={() => handleSaveClick()}
                    className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small m-2 w-full flex justify-around h-fit overflow-hidden"
                  >
                    {isProcessingAssessmentSave && <div className="loader" />}
                    {t('COACH_SESSION.SAVE_DRAFT')}
                  </Button>
                )}
              </>
            )}
          </div>
          <Button
            onClick={() => onCancelClick()}
            className="btn-small m-2 w-full mb-6 text-secondary bg-white h-fit overflow-hidden min-h-[40px]"
          >
            {t('CREATE_SESSION.CANCEL')}
          </Button>
        </div>
        <div className="session-mobile-menu-container">
          <div className="session-mobile-menu ">
            {t('COACH_ASSESSMENT.ASSESSMENT')}

            {!mobileMenu ? (
              <img
                onClick={() => setMobileMenu(true)}
                className="mt-auto mb-auto cursor-pointer"
                src="/assets/homePageImages/menu-dots-icon.svg"
                alt="bell-icon"
              />
            ) : (
              <img
                onClick={() => setMobileMenu(false)}
                className="mt-auto mb-auto cursor-pointer"
                src="/assets/subscriptionPage/plusIcon.svg"
                alt="bell-icon"
              />
            )}
            {mobileMenu && (
              <div className="coach-session-dropdown-container shadow-lg ltr:right-0 rtl:left-0 z-50  cursor-pointer">
                {CoachAssessmentContent.map(
                  ({ title, value, icon }) =>
                    value !== menu && (
                      <div
                        onClick={() => {
                          setMenu(value);
                          setMenuName(title);
                          setMobileMenu(false);
                        }}
                        className="h-10 border-b-[1px] border-[#38383840] w-full flex flex-row"
                      >
                        <div className="h-full w-[3px] " />
                        <div className="h-full font-general rtl:font-Almarai font-normal text-base w-full px-6 flex items-center ">
                          <CustomIcon Icon={icon} color={'#687E8E'} />
                          {title}
                        </div>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          <div className="h-14 w-full mb-10 flex flex-row">
            <div className="h-full w-[3px] bg-[#02A4EA]" />
            <div className="h-full w-full px-6 flex items-center  bg-[#00a5ec1a] rtl:font-Almarai">
              <CustomIcon
                Icon={
                  CoachAssessmentContent.filter(
                    ({ value }: any) => menu === value
                  )[0].icon as any
                }
                color={'#153243'}
              />
              {menuName}
            </div>
          </div>
        </div>
        {isCleared ? (
          (isProcessingAssessmentById && searchParams.get('form') === 'EDIT') ||
          (isProcessingfetchAssessmentDraftById &&
            searchParams.get('form') === 'DRAFT') ? (
            <div className=" session-form-container h-[90vh] justify-center items-center flex">
              <div className="loader" />
            </div>
          ) : (
            <div className="lggmd:h-[calc(100vh-270px)] w-full  lggmd:overflow-scroll  lggmd:shadow-custom lggmd:rounded-lg">
              {CoachAssessmentComponent.map(
                (item: any) => item.value === menu && item.component
              )}
            </div>
          )
        ) : null}
        <div className="mobile-btn-div">
          {menu !== 'PRICING' ? (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleNextClick}
              className="bg-white border-primary text-primary btn-small mt-6 w-full h-fit overflow-hidden"
            >
              {t('COACH_PACKAGE.NEXT')}
            </Button>
          ) : searchParams.get('form') !== 'EDIT' ? (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleClick}
              className="bg-primary text-white btn-small w-full flex justify-around h-fit overflow-hidden"
            >
              <>
                {isProcessingAssessmentCreate && <div className="loader" />}
                {t('COACH_SESSION.SUBMIT_REVIEW')}
              </>
            </Button>
          ) : (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={() => setCloseToggle(true)}
              className="bg-primary text-white btn-small w-full flex justify-around h-fit overflow-hidden"
            >
              <>
                {isProcessingAssessmentEdit && <div className="loader" />}
                {t('UPDATE')}
              </>
            </Button>
          )}
          {isSaveDisabled && searchParams.get('form') !== 'EDIT' && (
            <Button
              onClick={() => handleSaveClick()}
              className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small mt-2 w-full flex justify-around h-fit overflow-hidden"
            >
              {isProcessingAssessmentSave && <div className="loader" />}

              {t('COACH_SESSION.SAVE_DRAFT')}
            </Button>
          )}

          <Button
            onClick={() => onCancelClick()}
            className="btn-small mt-2 w-full mb-6 text-secondary bg-white h-fit overflow-hidden"
          >
            {t('CREATE_SESSION.CANCEL')}
          </Button>
        </div>
      </div>
    </>
  );
};
const CoachAssessment = styled(Component)``;

export default CoachAssessment;

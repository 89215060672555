import CakeIcon from '@mui/icons-material/Cake';
import EmailIcon from '@mui/icons-material/Email';
import FemaleIcon from '@mui/icons-material/Female';
import LanguageIcon from '@mui/icons-material/Language';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MaleIcon from '@mui/icons-material/Male';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import { Form, Formik } from 'formik';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Date from '../../clientComponents/date';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { registrationConst } from '../../coachConstants';
import { personalValidationSchema } from '../../coachHelpers';
import { IFormikInterface } from '../../coachInterfaces/registrationInterface';
import {
  cityList,
  coachingInformation,
  countryList,
  educationalFile,
  educationalInformation,
  experienceInformation,
  getCoachDetailsById,
  personalInformation,
  setAccreditationRequestFiles,
  specializationInformation,
} from '../../coachSlices/registrationSlice';
import { useAppSelector } from '../../hooks';
import Carousel from '../imageSlider';

import PhoneNumberInput from '../../clientComponents/phoneNumberInput/phoneNumberInput';
import { getDefaultValue } from '../../clientHelpers/utility';
import './index.css';
const Component = () => {
  const { lang } = useAppSelector((state) => state.language);
  const {
    countryDropDownList,
    cityDropDown,
    personalInfo,
    isProcessingCoachDetailsById,
  } = useAppSelector((state) => state.registration);
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(
    searchParams.get('reset') === 'NO' ? false : true
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useLayoutEffect(() => {
    const reset = searchParams.get('reset') || '';
    let timer: any;
    if (reset !== 'NO') {
      setIsLoading(false);
      dispatch(personalInformation({}));
      dispatch(coachingInformation({}));
      dispatch(specializationInformation({}));
      dispatch(educationalInformation({}));
      dispatch(experienceInformation({}));
      dispatch(educationalFile([]));
      dispatch(setAccreditationRequestFiles({}));
    } else {
      timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [searchParams]);

  const [t] = useTranslation('common');
  useEffect(() => {
    const id = searchParams.get('id') || '';
    if (id) {
      dispatch(getCoachDetailsById(id));
    }
  }, [searchParams]);
  useEffect(() => {
    dispatch(countryList());
  }, [dispatch]);
  // useEffect(() => {
  //   dispatch(cityList(countryInputValue));
  // }, [countryInputValue]);

  let cityOption: { value: string; label: string; label2: string }[] = [];
  if (cityDropDown?.length > 0) {
    cityDropDown.forEach((client: any) => {
      let roleDate = { value: '', label: '', label2: '' };
      roleDate.value = lang === 'en' ? client.city : client.city;
      roleDate.label = client.city;
      roleDate.label2 = client.cityAr;
      cityOption.push(roleDate);
    });
  }

  let countryOption: { value: string; label: string; label2: string }[] = [];
  if (countryDropDownList?.length > 0) {
    countryDropDownList.forEach((client: any) => {
      let roleDate = { value: '', label: '', label2: '' };
      roleDate.value =
        lang === 'en'
          ? client.countryNameInEnglish
          : client.countryNameInEnglish;
      roleDate.label = client.countryNameInEnglish;
      roleDate.label2 = client.countryNameInArabic;
      countryOption.push(roleDate);
    });
  }

  const genderOption = [
    { label: 'MALE', value: 'MALE', label2: 'ذكر' },
    { label: 'FEMALE', value: 'FEMALE', label2: 'أنثى' },
  ];

  const [countryInputValue, setCountryInputValue] = useState('');
  useEffect(() => {
    dispatch(cityList(countryInputValue));
  }, [countryInputValue]);
  const [cityInputValue, setCityInputValue] = useState('');
  const [genderInputValue, setGenderInputValue] = useState('');

  const handleCountryInputChange = (event: any) => {
    setCountryInputValue(event);
  };
  const handleCityInputChange = (event: any) => {
    setCityInputValue(event);
  };
  const handleGenderInputChange = (event: any) => {
    setGenderInputValue(event);
  };

  const handleDispatch = (data: any) => {
    dispatch(personalInformation(data));
    navigate(registrationConst.PATH_COACHING_DETAILS);
  };
  const cancel = () => {
    dispatch(personalInformation({}));
    dispatch(educationalInformation({}));
    dispatch(coachingInformation({}));
    dispatch(experienceInformation({}));
    dispatch(specializationInformation({}));
    dispatch(educationalFile([]));
    dispatch(setAccreditationRequestFiles([]));
    navigate('/');
  };

  return (
    <div className="registrationStep-container01">
      <div className=" registrationStep-container03 ">
        <p className="step"> {t('PERSONAL_DETAILS.STEP01')}</p>
        <div className="progress_bar">
          <div className="bg-primary h-1 w-1/5"></div>
        </div>
        <h1 className="heading_large sm:heading_small">
          {t('PERSONAL_DETAILS.CREATE_YOUR_ACCOUNT')}
        </h1>
        <p className="paragraph">{t('PERSONAL_DETAILS.DESCRIPTION')}</p>
        <p className=" mb-10">
          <span className="paragraph"> {t('PERSONAL_DETAILS.ALREADY')}</span>
          <span className="text-primary text-17.5 font-semibold ">
            <Link to={'/login?user=COACH'}>
              {t('PERSONAL_DETAILS.SIGN_IN')}
            </Link>
          </span>
        </p>
        {isLoading || isProcessingCoachDetailsById ? (
          <div className="flex justify-center h-[60vh] items-center">
            <div className="loader" />
          </div>
        ) : (
          <Formik
            initialValues={{
              fullName: personalInfo?.fullName || '',
              email: personalInfo?.email,
              phoneNumber: personalInfo?.phoneNumber,
              address01: personalInfo?.address01,
              address02: personalInfo?.address02,
              country: personalInfo?.country || '',
              city: personalInfo?.city || '',
              dateOfBirth: personalInfo?.dateOfBirth || '',
              gender: personalInfo?.gender || '',
              countryCode: personalInfo?.countryCode || '+966',
            }}
            validationSchema={personalValidationSchema}
            onSubmit={handleDispatch}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              submitForm,
              setFieldValue,
            }: IFormikInterface) => {
              return (
                <Form>
                  <Input
                    id="FormFullName"
                    type="text"
                    name="fullName"
                    placeholder={t('PERSONAL_DETAILS.FULL_NAME')}
                    onBlur={handleBlur}
                    error={touched.fullName && 1}
                    onChange={handleChange}
                    value={values.fullName}
                    errormessage={errors.fullName}
                    className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12 "
                    icon={
                      <PersonIcon
                        className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-0"
                        sx={{ color: '#687E8E' }}
                      />
                    }
                    clearText={() => setFieldValue('fullName', '')}
                  />
                  <div className="lg:flex lg:justify-between">
                    <div
                      className={` lg:w-6/12 ${
                        lang === 'en' ? 'lg:mr-3' : 'lg:ml-3'
                      } `}
                    >
                      <Input
                        id="FormEmail"
                        type="email"
                        name="email"
                        placeholder={t('PERSONAL_DETAILS.EMAIL')}
                        onBlur={handleBlur}
                        error={touched.email && 1}
                        onChange={handleChange}
                        value={values.email}
                        errormessage={errors.email}
                        className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12 "
                        icon={
                          <EmailIcon
                            className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-0"
                            sx={{ color: '#687E8E' }}
                          />
                        }
                        clearText={() => setFieldValue('email', '')}
                      />
                    </div>
                    <div className=" lg:w-6/12">
                      <PhoneNumberInput
                        id="FormPhoneNumber"
                        type="number"
                        name="phoneNumber"
                        codeValue={values.countryCode}
                        placeholder={t('PERSONAL_DETAILS.PHONE_NUMBER')}
                        onBlur={handleBlur}
                        error={touched.phoneNumber && 1}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                        value={values.phoneNumber}
                        errormessage={errors.phoneNumber}
                        className="pl-[5px] w-[100%]"
                        icon={
                          <PhoneIcon
                            className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-0"
                            sx={{ color: '#687E8E' }}
                          />
                        }
                        clearText={() => setFieldValue('phoneNumber', '')}
                      />
                    </div>
                  </div>

                  <div className="lg:flex lg:justify-between">
                    <div
                      className={` lg:w-6/12 mb-8  ${
                        lang === 'en' ? 'lg:mr-3' : 'lg:ml-3'
                      } `}
                    >
                      <SelectDropdown
                        id="FormCountry"
                        name="country"
                        placeholder={t('PERSONAL_DETAILS.COUNTRY')}
                        options={countryOption}
                        error={touched.country && 1}
                        errormessage={errors.country}
                        onBlur={handleBlur}
                        onChange={(opt: any) => {
                          setFieldValue('country', opt.value);
                          setFieldValue('city', '');
                          setCountryInputValue((value) => (value = opt.value));
                        }}
                        isValue={values.country}
                        // inputValue={countryInputValue}
                        // onInputChange={handleCountryInputChange}
                        // getOptionLabel={(option: any) =>
                        //   lang === 'en' ? option.label : option.label2
                        // }
                        defaultValue={
                          getDefaultValue(values.country, countryOption)[0]
                        }
                        getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                        }
                        iconValue={
                          <LanguageIcon
                            className="ml-3"
                            sx={{ color: '#687E8E' }}
                          />
                        }
                      />
                    </div>
                    <div className=" lg:w-6/12 mb-8 ">
                      {cityOption?.length > 0 && (
                        <SelectDropdown
                          id="FormCity"
                          name="city"
                          placeholder={t('PERSONAL_DETAILS.CITY')}
                          options={cityOption}
                          optional={0}
                          onBlur={handleBlur}
                          error={touched.city && 1}
                          onChange={(opt: any) => {
                            setFieldValue('city', opt.value);
                          }}
                          getOptionLabel={(option: any) =>
                              lang === 'en' ? option.label : !!option.label2 ? option.label2 : option.label
                          }
                          errormessage={errors.city}
                          isValue={values.city ? values.city : ''}
                          // inputValue={cityInputValue}
                          // onInputChange={handleCityInputChange}
                          // getOptionLabel={(option: any) =>
                          //   lang === 'en' ? option.label : option.label2
                          // }
                          // defaultValue={
                          //   getDefaultValue(values.city, cityOption)[0]
                          // }
                          isDisabled={values.country ? false : true}
                          iconValue={
                            <LocationCityIcon
                              className="ml-3"
                              // className="absolute top-2.5 ltr:left-3 rtl:right-3"
                              sx={{ color: '#687E8E' }}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                  <Input
                    id="FormAddress01"
                    type="text"
                    name="address01"
                    placeholder={t('PERSONAL_DETAILS.ADDRESS1')}
                    onBlur={handleBlur}
                    error={touched.address01 && 1}
                    onChange={handleChange}
                    value={values.address01}
                    errormessage={errors.address01}
                    className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12 "
                    icon={
                      <LocationOnIcon
                        className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-0"
                        sx={{ color: '#687E8E' }}
                      />
                    }
                    clearText={() => setFieldValue('address01', '')}
                  />
                  <Input
                    id="FormAddress02"
                    type="text"
                    name="address02"
                    placeholder={t('PERSONAL_DETAILS.ADDRESS2')}
                    optional={true}
                    onBlur={handleBlur}
                    error={touched.address02 && 1}
                    onChange={handleChange}
                    value={values.address02}
                    errormessage={errors.address02}
                    className="indent-1  ltr:pl-10 ltr:pr-12 rtl:pr-10 rtl:pl-12 "
                    icon={
                      <LocationOnIcon
                        className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-0"
                        sx={{ color: '#687E8E' }}
                      />
                    }
                    clearText={() => setFieldValue('address02', '')}
                  />
                  <div className="lg:flex justify-between">
                    <div
                      className={` lg:w-6/12 ${
                        lang === 'en' ? 'lg:mr-3' : 'lg:ml-3'
                      } `}
                    >
                      <Date
                        id="FormBirth"
                        type="date"
                        name="dateOfBirth"
                        placeholder={t('PERSONAL_DETAILS.DOB')}
                        onBlur={handleBlur}
                        value={values.dateOfBirth}
                        error={touched.dateOfBirth && 1}
                        onChange={(opt: any) => {
                          const date = `${opt?.year}-${
                            opt?.monthIndex + 1 < 10
                              ? `0${opt?.monthIndex + 1}`
                              : opt?.monthIndex + 1
                          }-${opt?.day < 10 ? `0${opt?.day}` : opt?.day}`;
                          setFieldValue('dateOfBirth', date);
                        }}
                        errormessage={errors.dateOfBirth}
                        className="indent-[27px]"
                        icon={
                          <CakeIcon
                            className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-0"
                            sx={{ color: '#687E8E' }}
                          />
                        }
                        dateClassName="indent-8"
                      />
                    </div>
                    <div className=" lg:w-6/12 mb-8 ">
                      <SelectDropdown
                        id="FormGender"
                        name="gender"
                        placeholder={t('PERSONAL_DETAILS.GENDER')}
                        options={genderOption}
                        onBlur={handleBlur}
                        error={touched.gender && 1}
                        onChange={(opt: any) => {
                          setFieldValue('gender', opt.value);
                        }}
                        errormessage={errors.gender}
                        isValue={values.gender}
                        inputValue={genderInputValue}
                        onInputChange={handleGenderInputChange}
                        getOptionLabel={(option: any) =>
                          lang === 'en' ? option.label : option.label2
                        }
                        defaultValue={
                          getDefaultValue(values.gender, genderOption)[0]
                        }
                        iconValue={
                          values.gender === 'FEMALE' ? (
                            <FemaleIcon
                              className="ml-3"
                              // className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-40 "
                              sx={{ color: '#687E8E' }}
                            />
                          ) : (
                            <MaleIcon
                              className="ml-3"
                              // className=" absolute top-2.5 ltr:left-3 rtl:right-3 z-40"
                              sx={{ color: '#687E8E' }}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="lg:flex lg:justify-between grid py-4 mb-20 mt:10 lg:mt-20 border-info lg:border-t-2">
                    <button
                      onClick={() => cancel()}
                      className="order-last lg:order-first cancel-btn mb-6 py-4 hover:bg-primary hover:border-transparent hover:text-white hover:lg:next-btn hover:next-btn-large"
                    >
                      {t('PERSONAL_DETAILS.CANCEL')}
                    </button>
                    <div className="lg:flex lg:justify-start grid lg:w-1/4">
                      <button
                        type="submit"
                        onClick={submitForm}
                        className="lg:next-btn next-btn-large bg-primary"
                      >
                        {t('PERSONAL_DETAILS.NEXT')}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      <Carousel />
    </div>
  );
};
const PersonalDetails = styled(Component)``;

export default PersonalDetails;

/* eslint-disable react/react-in-jsx-scope */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCoachSubscriptionCoreFeature } from '../../clientSlices/landingPageSlice';
import { useAppSelector } from '../../hooks';
interface ImageProps {
  id: string;
}
export const CoreFeatures: FC<ImageProps> = ({ id }): JSX.Element => {
  const dispatch = useDispatch<any>();
  const initialState = {
    id: '',
    nameInArabic: '',
    nameInEnglish: '',
  };
  const { lang } = useAppSelector((state) => state.language);

  const [data, setData] = useState(initialState);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    async function getImage() {
      setLoader(true);
      if (id) {
        try {
          const data = await dispatch(getCoachSubscriptionCoreFeature(id));
          setData(data.payload?.data[0]);
          setLoader(false);
        } catch (err) {
          setLoader(false);
          setData(initialState);
        }
      } else {
        setLoader(false);
        setData(initialState);
      }
    }
    getImage();
  }, [id]);

  return loader ? (
    <div className="content-loader  w-[50%] h-[20px] rounded" />
  ) : (
    <div>{lang === 'en' ? data?.nameInEnglish : data?.nameInArabic}</div>
  );
};

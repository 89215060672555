import { postImageFileWithToken } from '../coachServices/apiService';
import { get, getFile, post } from './api';

export const purchaseOrder = async (data: any) => {
  return await post('/order', data);
};

export const paymentPurchase = async (data: any) => {
  return await post('/payment', data);
};

export const paymentPurchaseTabby = async (data: any) => {
  return await post('/tabby/checkout', data);
};

export const order = async (data: any) => {
  return await get(
    `/payment/trackId/${data.trackId}/cardBrand/${data.cardBrand}`
  );
};

export const tabbyPaymentStatus = async (data: any) => {
  return await get(`/tabby/mytabby/${data}`);
};

export const purchaseHistory = async (payload: any) => {
  return await get(`/payment/purchase-history`, payload);
};

export const purchases = async (body: any) => {
  return await get(`/clients/my-purchases`, body);
};
export const register = async (data: any) => {
  return await post(`/tabby/sessions`, data);
};

export const tokenPayment = async (data: any) => {
  return await post(`/tabby/payments`, data);
};
export const tabbyPayment = async (id: any) => {
  return await get(`/tabby/mytabby/${id}`);
};

export const tabbySaveToken = async (data: any) => {
  return await post(`/tabby/mytabby/token`, data);
};
export const invoice = async (data: any) => {
  return await getFile(`/invoice/payment/${data}`);
};
export const giftCard = async (data: any) => {
  return await get('/giftcards/' + data);
};
export const imageUpload = async (payload: any) => {
  return await postImageFileWithToken('/files', payload);
};

export const imageUploadPayment = async (data: any) => {
  return await post('/order', data);
};

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import { AccountListSideBar } from '../../coachComponents';
import { socialMediaValidationSchema } from '../../coachHelpers';
import { useAppSelector } from '../../coachHelpers/hooks';
import {
  createSocialMedia,
  getAllSocialMedia,
} from '../../coachSlices/profileSlices/socialMediaSlice';

const Component = () => {
  const [t] = useTranslation('common');
  const [website, setWebsite] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [youtube, setYoutube] = useState('');
  const dispatch = useDispatch<any>();
  const { socialMediaDetails } = useAppSelector((state) => state.socialMedia);

  useEffect(() => {
    dispatch(getAllSocialMedia());
  }, []);

  useEffect(() => {
    if (socialMediaDetails?.length) {
      socialMediaDetails &&
        socialMediaDetails?.map((ele: any, i: any) => {
          socialMediaDetails[i]?.name === 'WEBSITE' &&
            setWebsite(socialMediaDetails[i]?.link);
          socialMediaDetails[i]?.name === 'TWITTER' &&
            setTwitter(socialMediaDetails[i]?.link);
          socialMediaDetails[i]?.name === 'FACEBOOK' &&
            setFacebook(socialMediaDetails[i]?.link);
          socialMediaDetails[i]?.name === 'LINKEDIN' &&
            setLinkedin(socialMediaDetails[i]?.link);
          socialMediaDetails[i]?.name === 'YOUTUBE' &&
            setYoutube(socialMediaDetails[i]?.link);
        });
    }
  }, [socialMediaDetails]);

  const updateWebsite = (e: any) => {
    const link = {
      socialMedia: 'WEBSITE',
      link: e.target.value,
    };
    dispatch(createSocialMedia(link));
  };
  const updateTwitter = (e: any) => {
    const link = {
      socialMedia: 'TWITTER',
      link: e.target.value,
    };
    dispatch(createSocialMedia(link));
  };
  const updateFacebook = (e: any) => {
    const link = {
      socialMedia: 'FACEBOOK',
      link: e.target.value,
    };
    dispatch(createSocialMedia(link));
  };
  const updateLinkedin = (e: any) => {
    const link = {
      socialMedia: 'LINKEDIN',
      link: e.target.value,
    };
    dispatch(createSocialMedia(link));
  };
  const updateYoutube = (e: any) => {
    const link = {
      socialMedia: 'YOUTUBE',
      link: e.target.value,
    };
    dispatch(createSocialMedia(link));
  };

  const handleSubmit = (e: any) => {};

  return (
    <div className="mt-6 lg:mt-12 w-full h-fit">
      <div className="bg-white mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar />
        <div className=" lg:p-8 p-4 col-span-5 lg:col-span-4 lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.SOCIAL_MEDIA.SOCIAL_MEDIA')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.SOCIAL_MEDIA.SOCIAL_MEDIA_DEC')}
          </p>
          <div className="border border-info mb-8"></div>
          <Formik
            initialValues={{
              website,
              twitter,
              facebook,
              linkedin,
              youtube,
            }}
            validationSchema={socialMediaValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              submitForm,
            }) => (
              <Form id="my-form">
                <div className="flex justify-between h-[46px] mb-5">
                  <div className="ltr:mr-2 rtl:ml-2 py-4  px-4 rounded border border-gray items-center">
                    <img
                      className="cursor-pointer"
                      src="/assets/profilePageImage/link.svg"
                      alt="twitter-icon"
                    />
                  </div>
                  <Input
                    id="FormWebsite"
                    type="text"
                    optional={true}
                    name="website"
                    placeholder={t('PROFILE.SOCIAL_MEDIA.WEBSITE')}
                    onBlur={handleBlur}
                    error={touched.website && 1}
                    onChange={handleChange}
                    value={values.website}
                    errormessage={errors.website}
                    onMouseLeave={(e: any) => {
                      if (e.target.value !== website) updateWebsite(e);
                    }}
                  />
                </div>
                <div className="flex justify-between h-[46px] mb-5">
                  <div className="ltr:mr-2 rtl:ml-2 py-3  px-4 rounded border border-gray items-center">
                    <img
                      className="cursor-pointer"
                      src="/assets/profilePageImage/twitter.svg"
                      alt="twitter-icon"
                    />
                  </div>
                  <Input
                    id="FormTwitter"
                    type="text"
                    name="twitter"
                    placeholder={t('PROFILE.SOCIAL_MEDIA.TWITTER')}
                    onBlur={handleBlur}
                    optional={true}
                    error={touched.twitter && 1}
                    onChange={handleChange}
                    value={values.twitter}
                    errormessage={errors.twitter}
                    onMouseLeave={(e: any) => {
                      if (e.target.value !== twitter) updateTwitter(e);
                    }}
                  />
                </div>
                <div className="flex justify-between h-[46px] mb-5">
                  <div className="ltr:mr-2 rtl:ml-2 py-3  px-4 rounded border border-gray items-center">
                    <img
                      className="cursor-pointer"
                      src="/assets/profilePageImage/facebook.svg"
                      alt="twitter-icon"
                    />
                  </div>
                  <Input
                    id="FormFacebook"
                    type="text"
                    name="facebook"
                    placeholder={t('PROFILE.SOCIAL_MEDIA.FACEBOOK')}
                    onBlur={handleBlur}
                    optional={true}
                    error={touched.facebook && 1}
                    onChange={handleChange}
                    value={values.facebook}
                    errormessage={errors.facebook}
                    onMouseLeave={(e: any) => {
                      if (e.target.value !== facebook) updateFacebook(e);
                    }}
                  />
                </div>
                <div className="flex justify-between h-[46px] mb-5">
                  <div className="ltr:mr-2 rtl:ml-2 py-3  px-4 rounded border border-gray items-center">
                    <img
                      className="cursor-pointer"
                      src="/assets/profilePageImage/linkdin.svg"
                      alt="twitter-icon"
                    />
                  </div>
                  <Input
                    id="FormLinkedin"
                    type="text"
                    optional={true}
                    name="linkedin"
                    placeholder={t('PROFILE.SOCIAL_MEDIA.LINKDIN')}
                    onBlur={handleBlur}
                    error={touched.linkedin && 1}
                    onChange={handleChange}
                    value={values.linkedin}
                    errormessage={errors.linkedin}
                    onMouseLeave={(e: any) => {
                      if (e.target.value !== linkedin) updateLinkedin(e);
                    }}
                  />
                </div>
                <div className="flex justify-between h-[46px] mb-5">
                  <div className="ltr:mr-2 rtl:ml-2 py-3  px-4 rounded border border-gray items-center">
                    <img
                      className="cursor-pointer"
                      src="/assets/profilePageImage/youtube.svg"
                      alt="twitter-icon"
                    />
                  </div>
                  <Input
                    id="FormYoutube"
                    type="text"
                    name="youtube"
                    optional={true}
                    placeholder={t('PROFILE.SOCIAL_MEDIA.YOUTUBE')}
                    onBlur={handleBlur}
                    error={touched.youtube && 1}
                    onChange={handleChange}
                    value={values.youtube}
                    errormessage={errors.youtube}
                    onMouseLeave={(e: any) => {
                      if (e.target.value !== youtube) updateYoutube(e);
                    }}
                  />
                </div>
                {/* <div className=" grid lg:hidden">
                  <button
                    form="my-form"
                    type="submit"
                    onClick={handleSubmit}
                    className="  py-4 px-8 text-white font-satoshi rtl:font-Almarai font-bold bg-primary rounded"
                  >
                    {t('PROFILE.PROFILE_INFO.SAVE_CHANGE')}
                  </button>
                </div> */}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const SocialMedia = styled(Component)``;

export default SocialMedia;

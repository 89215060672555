import {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import LandingPageBody from '../../clientComponents/landingPageBody';

const LandingPageWithoutLogin = () => {
    const [t] = useTranslation('common');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>{t('KUN')}</title>
                {/* <meta name="description" content="Helmet application" /> */}
            </Helmet>
            <div className="m-0 w-full">
                <LandingPageBody/>
            </div>
        </>
    );
};

export default LandingPageWithoutLogin;

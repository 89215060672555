import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';
import FaqCard from '../../coachComponents/subscription/FaqCard';
import SubscriptionCard from '../../coachComponents/subscription/SubscriptionCard';
import { IFaqContent } from '../../coachInterfaces/subscriptionInterface';
// import { randomCaps, randomFontSize } from '../../helpers/utility';
import './index.css';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { coachSubInterface } from '../../clientInterfaces/landingPageInterfaces';
import {
  getCoachSubscription,
  selectLanding,
} from '../../clientSlices/landingPageSlice';

const Component = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { coachSub, isProcessingCoachSubRequest } = useSelector(selectLanding);

  useEffect(() => {
    dispatch(getCoachSubscription());
  }, []);

  const offerCardsContent = [
    {
      title: t('SUBSCRIPTION.FREE_SESSIONS'),
      desc: t('SUBSCRIPTION.FREE_SESSIONS_DESC'),
    },
    {
      title: t('SUBSCRIPTION.QUALITY_CONTROL'),
      desc: t('SUBSCRIPTION.QUALITY_CONTROL_DESC'),
    },
    {
      title: t('SUBSCRIPTION.CENTRALIZATION'),
      desc: t('SUBSCRIPTION.CENTRALIZATION_DESC'),
    },
  ];
  const faqContent = [
    {
      title: 'Question 1',
      question:
        'Get to know the coach before you book a session with a free introductory sessions.',
    },
    {
      title: 'Question 2',
      question:
        'Only internationally recognized coaches can list their services in KUN',
    },
    {
      title: 'Question 3',
      question:
        'Everything you need in one place, from coaching, assessments and more!',
    },
    {
      title: 'Question 4',
      question:
        'Everything you need in one place, from coaching, assessments and more!',
    },
  ];
  const settings = {
    dots: true,
    infinite: false,
    initialSlide: 1,
    centerMode: false,
    className: '!w-[100%] !h-[50%]',
    centerPadding: '5px',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'button__bar',
    autoPlay: true,
    autoPlaySpeed: 500,
  };
  const handleSubCardClick = () => {
    navigate('/coach/registration/personal-details');
  };
  const getSubCardLoader = () => {
    return (
      <div className="subscription-card ">
        <div className="w-full flex flex-row">
          <div className="w-[72px] h-[72px] content-loader subscription-icon-div"></div>
          <div className="subscription-card-title font-general rtl:font-Almarai text-base font-semibold ">
            <div className="content-loader w-[40%] h-[20px]" />
            <div className="subscription-card-subtitle font-satoshi rtl:font-Almarai font-bold text-[28px] mt-4">
              <div className="content-loader mt-2 w-[40%] h-[20px]" />
            </div>
          </div>
        </div>
        <div className=" subscription-card-price items-end">
          <div className="content-loader mt-2 w-[30%] h-[20px]" />
        </div>
        <div className=" subscription-page-card-desc pb-0">
          <div className="content-loader mt-2 w-[100%] h-[20px]" />
          <div className="content-loader mt-2 w-[20%] h-[20px]" />
        </div>
        <div className="subscription-card-feature">
          <div className="content-loader mt-2 w-[60%] h-[20px]" />
          <div className="content-loader mt-2 w-[50%] h-[20px]" />
          <div className="content-loader mt-2 w-[80%] h-[20px]" />
          <div className="content-loader mt-2 w-[60%] h-[20px]" />
          <div className="content-loader mt-2 w-[50%] h-[20px]" />
          <div className="content-loader mt-2 w-[80%] h-[20px]" />
        </div>
        <div className="subscription-card-add">
          <div className="content-loader mt-2 w-[60%] h-[20px]" />
          <div className="content-loader mt-2 w-[50%] h-[20px]" />
          <div className="content-loader mt-2 w-[80%] h-[20px]" />
          <div className="content-loader mt-2 w-[60%] h-[20px]" />
          <div className="content-loader mt-2 w-[50%] h-[20px]" />
          <div className="content-loader mt-2 w-[80%] h-[20px]" />
        </div>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="subscription-container">
        <div className="session-page-title pt-12">
          {t('SUBSCRIPTION.OUR_PRICING')}
        </div>
        <div className=" session-page-desc pb-0">
          {t('CREATE_SESSION.CRATE_SESSION_DESC')}
        </div>
        <div className="subscription-slider overflow-x-hidden w-[100vw]  mt-8 ltr:ml-[-1.25rem] rtl:pl-[18px] ltr:[18px] rtl:mr-[-1.25rem]">
          <Slider {...settings}>
            {isProcessingCoachSubRequest ? (
              <>
                {getSubCardLoader()}
                {getSubCardLoader()}
                {getSubCardLoader()}{' '}
              </>
            ) : (
              coachSub.map((item: coachSubInterface) => (
                <SubscriptionCard
                  handleSubCardClick={handleSubCardClick}
                  item={item}
                />
              ))
            )}
          </Slider>
        </div>
        <div className="subscription-card-container ">
          {isProcessingCoachSubRequest ? (
            <>
              {getSubCardLoader()}
              {getSubCardLoader()}
              {getSubCardLoader()}{' '}
            </>
          ) : (
            coachSub.map((item: coachSubInterface) => (
              <SubscriptionCard
                handleSubCardClick={handleSubCardClick}
                item={item}
              />
            ))
          )}
        </div>
        <div className="session-page-title justify-start pt-12">
          {t('SUBSCRIPTION.WE_OFFER')}
        </div>
        <div className=" session-page-desc w-full flex w-full text-left justify-start pb-0">
          {t('SUBSCRIPTION.KUN')}
        </div>
        <div className="offer-card-container">
          {offerCardsContent.map(({ title, desc }) => (
            <div className="offer-card ">
              <img
                src="/assets/subscriptionPage/cloud-icon.svg"
                className="w-[60px] h-[60px]"
                alt=""
              />
              <div className="font-satoshi rtl:font-Almarai font-bold text-[22px]">
                {title}
              </div>
              <div className="subscription-page-card-desc">{desc}</div>
            </div>
          ))}
        </div>
        <div className="session-page-title justify-start pt-12">
          {t('SUBSCRIPTION.FAQ')}
        </div>
        <div className=" session-page-desc w-full flex w-full text-left justify-start pb-0">
          In finibus purus sed tortor fringilla, eu luctus lorem sodales.Ut
          dignissim ante ac augue vulputate tristique. Mauris venenatis
          tincidunt nibh, sit amet fringilla augue malesuada a. Mauris a nunc
          congue.
        </div>
        <div className="faq-card-container ">
          {faqContent.map((item: IFaqContent) => (
            <FaqCard item={item} />
          ))}
        </div>
        <div className="flex xsmMax:my-[79px] my-5 lgmd:justify-start justify-center ">
          <div className="flex lgmd:flex-row flex-col justify-between">
            <div className="flex flex-col lgmd:w-[40%] w-full lgmd:text-left ">
              <div className="text-black font-bold md:text-5xl text-3xl text-darkGrey ">
                {t('LANDING_PAGE_BEFORE_LOGIN.SIGNUP_KUN')}
              </div>
              <div className="text-greyText pt-2">
                {t('LANDING_PAGE_BEFORE_LOGIN.SIGNUP_TEXT')}
              </div>
            </div>
            <div className="flex  lgmd:flex-row flex-col pt-4 pb-5  gap-4 items-center justify-cenr ">
              <button
                onClick={() =>
                  navigate('/search-result?page=0&size=9&serviceType=COACHES')
                }
                className="about-us-page-footer-btn  
                w-full md:w-[295px] font-satoshi rtl:font-Almarai text-lg"
              >
                {t('LANDING_PAGE_BEFORE_LOGIN.BROWSE_COACHES')}
              </button>

              <button
                onClick={() => navigate('/coach/registration/personal-details')}
                className="about-us-body-page-footer-btn-without-bg  w-full md:w-[295px]
                font-satoshi rtl:font-Almarai text-lg  text-darkGrey "
              >
                {t('LANDING_PAGE_BEFORE_LOGIN.BECOME_COACH')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const CoachSubscription = styled(Component)``;

export default CoachSubscription;

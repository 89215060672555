import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getAccessToken } from '../../clientHelpers/localStorage';
import { coachSubInterface } from '../../clientInterfaces/landingPageInterfaces';
import { COACH_SUB_CONSTANTS } from '../../coachConstants/genericConstants';
import NoData from '../../components/noData';
import Tooltip from '../../components/tooltip/input';
import Button from '../buttons';
import { AdditionalFeatures } from './AdditionalFeatures';
import { CoreFeatures } from './CoreFeature';
interface ISubscriptionCard {
  item: coachSubInterface;
  handleSubCardClick: (item: any) => void;
}
const Component: FC<ISubscriptionCard> = ({
  item,
  handleSubCardClick,
}): JSX.Element => {
  const [t] = useTranslation('common');
  const [hoveredCard, setHoveredCard] = useState('');
  return (
    <div
      className="subscription-card"
      onMouseLeave={() => setHoveredCard('')}
      onMouseEnter={() => setHoveredCard('BASIC')}
    >
      <div className="w-full flex flex-row">
        <div className="w-[72px] h-[72px] subscription-icon-div">
          {item?.icon === COACH_SUB_CONSTANTS.BASE && (
            <img
              src="/assets/subscriptionPage/basic-subscription-inactive.svg"
              className="mx-auto"
              alt=""
            />
          )}
          {item?.icon === COACH_SUB_CONSTANTS.PRO && (
            <img
              src="/assets/subscriptionPage/pro-subscription-inactive.svg"
              className="mx-auto"
              alt=""
            />
          )}
          {item?.icon === COACH_SUB_CONSTANTS.PREMIER && (
            <img
              src="/assets/subscriptionPage/premier-subscription-inactive.svg"
              className="mx-auto"
              alt=""
            />
          )}
        </div>
        <div className="subscription-card-title font-general rtl:font-Almarai text-base font-semibold ">
          {item?.headline}
          <div className="subscription-card-subtitle font-satoshi rtl:font-Almarai font-bold text-[28px] mt-4">
            {item?.title}
          </div>
        </div>
      </div>
      <div className="flex flex-row items-end">
        <span className=" subscription-card-price items-end">
          {t('SAR')}&nbsp; {item?.charge}
        </span>
        <span className=" subscription-card-annual ">
          / &nbsp;{t(item?.subscriptionType)}
        </span>
      </div>
      <div className=" subscription-page-card-desc pb-0">
        <Tooltip
          content={item?.description ? item?.description : '------------------'}
          children={
            <div className="w-40  text-black">
              {item?.description ? item?.description : '------------------'}
            </div>
          }
        ></Tooltip>
      </div>
      <Button
        onClick={() => handleSubCardClick(item)}
        className="get-started-btn  btn-small mt-4 w-full"
      >
        {getAccessToken()
          ? t('PROFILE.PROFILE_INFO.CHOOSE_PLAN')
          : t('SUBSCRIPTION.GET_STARTED')}
      </Button>
      <div className="border-b border-borderBlack">
        <div className="font-satoshi rtl:font-Almarai text-18 font-normal pt-6 flex">
          <div className="w-[148px] font-semibold">
            {t('SUBSCRIPTION_PORTAL.COMMISSION')}{' '}
          </div>
          : <span className="px-1">{item?.commission}</span> %
        </div>
        <div className="font-satoshi rtl:font-Almarai text-18 font-normal py-6 flex">
          <div className="w-[148px] font-semibold">
            {t('SUBSCRIPTION_PORTAL.FIXED_COMMISSION')}
          </div>{' '}
          : {t(`SAR`)}
          <span className="ltr:pl-2 rtl:pr-2">{item?.fixedCommission}</span>
        </div>
      </div>
      <div className=" font-satoshi rtl:font-Almarai py-5 border-b  border-borderBlack">
        <div className="flex">
          <div className=" w-[180px] text-18 font-semibold">
            {t('SUBSCRIPTION_PORTAL.SESSION_COUNT')}
          </div>
          <div>:&nbsp;{item?.sessionCount}</div>
        </div>
        <div className="flex">
          <div className=" w-[180px] text-18 font-semibold">
            {t('SUBSCRIPTION_PORTAL.ASSESSMENT_COUNT')}
          </div>
          <div>:&nbsp;{item?.assessmentCount}</div>
        </div>
        <div className="flex">
          <div className=" w-[180px] text-18 font-semibold">
            {t('SUBSCRIPTION_PORTAL.PACKAGE_COUNT')}
          </div>
          <div>:&nbsp;{item?.packageCount}</div>
        </div>
      </div>
      <div className="subscription-card-feature">
        {t('SUBSCRIPTION.CORE_FEATURES')}
        {item?.coreFeatures?.length > 0 &&
          item.coreFeatures.map((item: string) => (
            <div className="flex items-start w-full pt-4 subscription-page-card-desc">
              <img
                src={
                  hoveredCard === 'BASIC'
                    ? '/assets/subscriptionPage/subscription-active-check.svg'
                    : '/assets/subscriptionPage/subscription-inactive-check.svg'
                }
                className="ltr:pr-2 rtl:pl-2"
                alt=""
              />
              <CoreFeatures id={item} />
            </div>
          ))}
      </div>
      <div className="subscription-card-add">
        {t('SUBSCRIPTION.ADD_FEATURES')}
        {item?.additionalFeatures?.length > 0 ? (
          item?.additionalFeatures.map((item: string) => (
            <div className="flex items-start w-full pt-4 subscription-page-card-desc">
              <img
                src={
                  hoveredCard === 'BASIC'
                    ? '/assets/subscriptionPage/subscription-active-check.svg'
                    : '/assets/subscriptionPage/subscription-inactive-check.svg'
                }
                className="ltr:pr-2 rtl:pl-2"
                alt=""
              />
              <AdditionalFeatures id={item} />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};
const SubscriptionCard = styled(Component)``;

export default SubscriptionCard;

import { deleteApi, get, post } from './api';

export const categories = async () => {
  return await get('/categories');
};

export const homeServices = async () => {
  return await get('/client-home/landing-page');
};

export const searchService = async (queryParam: any) => {
  return await get(
    `/client-home?baseFilter=ACTIVE&activityStatus=LIVE`,
    queryParam
  );
};

export const serviceLists = async (
  serviceType: string,
  servicePayload: any
) => {
  return await get(
    `/${serviceType}?baseFilter=ACTIVE&activityStatus=LIVE`,
    servicePayload
  );
};

export const sessionView = async (param: any) => {
  return await get(`/${param.serviceType}/${param.serviceId}`);
};

export const createWish = async (data: any) => {
  return await post('/wishlists', data);
};

export const reviewSession = async (queryParam: any) => {
  return await get(`/review-rating`, queryParam);
};

export const getCoachById = async (id: number) => {
  return await get(`/coaches/${id}`);
};
export const getCoachSocialById = async (id: number) => {
  return await get(`/social-medias/coaches/${id}`);
};
export const getClientById = async (id: number) => {
  return await get(`/clients/${id}`);
};

export const recommendedSession = async (data: string) => {
  return await get(`/${data}/recommended`);
};

export const deliverablesCoach = async (id: number) => {
  return await get(`/coaches/my-services/${id}`);
};

export const getCountServices = async (serviceType: string, data: any) => {
  return await get(
    `/client-home/filtering-count/${serviceType}?activityStatus=LIVE`,
    data
  );
};

export const getCountCoaches = async () => {
  return await get(`/coaches/filtering-count`);
};

export const getSchedules = async (payload: any) => {
  return await get(`/schedules/available-slots`, payload);
};

export const getCart = async () => {
  return await get(`/carts`);
};

export const addService = async (data: any) => {
  return await post(`/carts`, data);
};

export const cartYouMightAlsoLike = async () => {
  return await get(`/carts/you-might-also-like`);
};

export const removeCart = async (id: number) => {
  return await deleteApi(`/carts/${id}`);
};

export const removeWishlist = async (data: any) => {
  return await deleteApi(`/wishlists`, data);
};

export const getWishlist = async (param: any) => {
  return await get(`/wishlists`, param);
};

export const getScheduleTimes = async (data: any) => {
  return await get('/schedules/available-slots', data);
};

export const bookASlot = async (data: any) => {
  return await post('/schedules', data);
};

export const relatedTagsList = async (data: any) => {
  return await get('/client-home/related-tags?activityStatus=LIVE', data);
};

export const coachRelatedTagsList = async (data: any) => {
  return await get('/coaches/related-tags', data);
};

export const closeAssessment = async (id: any) => {
  return await get(`/assessments/close/${id}`);
};

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { coachHomeInfo } from '../../coachSlices/coachHomeSlice';
import { performanceInfo } from '../../coachSlices/coachPerformance';

const Component = () => {
  const [t] = useTranslation('common');
  const { serviceCount } = useSelector(coachHomeInfo);
  const { coachCounts } = useSelector(performanceInfo);

  const homeCardContents = [
    {
      value: serviceCount,
      text: t('HOME.MY_SERVICES'),
      key: 1,
    },
    {
      value: coachCounts.totalClientCount,
      text: t('HOME.TOTAL_CLIENTS'),
      key: 2,
    },
    {
      value: coachCounts.activeClientCount,
      text: t('HOME.CURRENTLY_ACTIVE_CLIENTS'),
      key: 3,
    },
    {
      value: `${t('SAR')} ${coachCounts.totalRevenue}`,
      text: t('HOME.TOTAL_REVENUE'),
      key: 4,
    },
  ];
  return (
    <div className="home-card-row">
      {homeCardContents.map(({ value, text, key }) => (
        <div key={key} className="home-card">
          <img
            src="/assets/homePageImages/home-card-info-icon.svg"
            alt="bell-icon"
          />
          <div className="mt-4 text-[#153243] font-satoshi rtl:font-Almarai font-bold text-3xl">
            {value?.toString().padStart(2, '0')}
          </div>
          <div className="text-[#687E8E] font-satoshi rtl:font-Almarai font-normal text-sm">
            {text}
          </div>
        </div>
      ))}
    </div>
  );
};
const HomeCard = styled(Component)``;

export default HomeCard;

import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConstants from '../../clientConstants/modal/modalConstants';
import { IModalHeaderElements } from '../../clientInterfaces/modal';
import { modalInfo, modalState } from '../../clientSlices/modalSlice';
import './index.css';

type InputFieldProps = {
  show: boolean;
  children: any;
  logOutClose?: () => void;
  title?: string;
  titleChildren?: JSX.Element;
};

const Modal: React.FC<InputFieldProps> = ({
  show,
  children,
  logOutClose,
  title,
  titleChildren,
}): JSX.Element => {
  const showHideClassName = show ? 'modal block ' : 'modal hidden';
  const dispatch = useDispatch();
  const modalData: IModalHeaderElements = useSelector(modalInfo);
  const handleClose = () => {
    if (logOutClose) {
      logOutClose();
    }

    dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: false }));
    dispatch(modalState({ key: ModalConstants.MODAL_DELETE, value: false }));
    dispatch(modalState({ key: ModalConstants.MODAL_EDIT, value: false }));
    dispatch(modalState({ key: ModalConstants.MODAL_GET, value: false }));
  };
  return (
    <div className={showHideClassName}>
      <section
        className={classNames(
          'modal-main three flex flex-col max-h-[80vh] md:w-[47.5rem]  w-[19rem]  '
        )}
      >
        <div className={classNames('modal-header flex flex-row')}>
          {!titleChildren ? (
            <div
              className={classNames('modal-header-title ', {
                'bg-[var(--primary)]': title,
              })}
            >
              {title ? title : modalData.title}
            </div>
          ) : (
            titleChildren
          )}
          <div
            className={classNames(`modal-header-close  cursor-pointer  `, {
              'bg-[var(--primary)]': title,
            })}
            onClick={handleClose}
          >
            <img src="/assets/headerImage/cancel.svg" className="" alt="logo" />
          </div>
        </div>
        <div className={classNames('modal-content ')}>{children}</div>
      </section>
    </div>
  );
};

export default Modal;

import { FC } from 'react';
import { Image } from '../../components/Image';
interface IEducationData {
  data: any;
}
const EducationQualification: FC<IEducationData> = ({ data }): JSX.Element => {
  return (
    <>
      {data?.map((item: any, index: number) => (
        <div className="flex pt-4 gap-3">
          <img key={index} src={item.image} alt="" className="self-start"></img>

          <div
            className={
              index === data?.length - 1
                ? 'pb-5 pl-5 '
                : 'border-b-2 border-[#38383840] pb-5 pl-5'
            }
          >
            <div className="font-bold text-[#153243] text-lg">
              {item.university ? (
                <>{item.university}</>
              ) : (
                <>{item.title && item.title}</>
              )}
            </div>
            <div className="text-[#687E8E] font-normal text-[13px]">
              {item.degree ? (
                <>{item.degree}</>
              ) : (
                <>{item.companyName && item.companyName}</>
              )}
            </div>
            <div className="text-[#687E8E] font-normal text-[13px]">
              {item.fieldOfStudy ? (
                <>{item.fieldOfStudy}</>
              ) : (
                <>{item.location && item.location}</>
              )}
            </div>
            <div className="text-[#687E8E] font-normal text-[13px]">
              {item.startDate} -{' '}
              {item?.isCurrentlyWorking ? 'Present' : item.endDate}
            </div>
            <div className="text-base font-normal text-[#153243] pt-4 break-all">
              {item.description}
            </div>
            <div className="flex pt-2">
              <div className="flex w-[55px] ">
                {/* <img
                  src="/assets/clientCoachProfilePage/downloadedImage.svg"
                  alt=""
                ></img>
                 */}
                <Image
                  id={(item?.imgId as number) || (item?.fileId as number)}
                  bgImgUrl={'/assets/default-img-icon.svg'}
                  bgClass={
                    ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[165px]'
                  }
                  propClass={' rounded-[6px]'}
                  imageNameClass={
                    'flex items-center pl-1 text-[#687E8E] font-base text-[13px]'
                  }
                />
              </div>
              <span className="flex items-center pl-1 text-[#687E8E] font-base text-[13px] ">
                {item.certificate}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default EducationQualification;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { animated as a, useTrail } from 'react-spring';
import { useAppSelector } from '../../hooks';

import CountUp from 'react-countup';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import VisibilitySensor from 'react-visibility-sensor';
import {
  listCategory,
  selectClientHome,
  setServiceType,
  setServiceView,
} from '../../clientSlices/home';
import {
  getAssessmentConducted,
  getClientCount,
  getCoachCount,
  getCoachOfTheMonth,
  getServiceHoursCount,
  selectLanding,
} from '../../clientSlices/landingPageSlice';
import { Image } from '../../components/Image';
import Button from '../buttons';
import SelectDropdown from '../selectDropdown';
import './index.css';

export interface CoachClient {
  isClient: boolean;
  setIsClient: any;
}

export interface CoachClientData {
  heading: string;
  description: string;
}
const LandingPageBody = () => {
  const [t] = useTranslation('common');
  const [isClient, setIsClient] = useState(true);
  const dispatch = useDispatch<any>();
  const { coachOfMonth } = useSelector(selectLanding);
  useEffect(() => {
    dispatch(getCoachOfTheMonth());
  }, []);

  return (
    <section className="bg-white w-full">
      <LearnSkills t={t} />
      <Offers t={t} />
      <SomeCount t={t} />
      <HowKUNWorks isClient={isClient} setIsClient={setIsClient} t={t} />
      {/* {isClient ? <CoachOfMonth t={t} /> : null} */}
      {isClient && coachOfMonth?.email ? <Coaches t={t} /> : null}
      <SignUpKUN t={t} />
    </section>
  );
};

const LearnSkills = ({ t }: any) => {
  const navigate = useNavigate();
  const { createOpen } = useAppSelector((state) => state.modalInfo);

  const items = React.Children.toArray(
    t('LANDING_PAGE_BEFORE_LOGIN.LEARN_NEW_SKILLS')
  );
  const trail = useTrail(items?.length, {
    config: { mass: 5, tension: 500, friction: 200 },
    opacity: 1,
    x: 0,
    from: { opacity: 0.1, x: 100 },
  });
  const dispatch = useDispatch<any>();
  const [categoryValue, setCategoryValue] = useState('');
  const { lang } = useAppSelector((state) => state.language);
  let categoryListEn: { label: ''; value: ''; label2: '' }[] = [];
  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) {
      dispatch(setServiceView(true));
      dispatch(setServiceType(''));
      navigate(
        `/search-result${search && `?keyword=${search}`}${
          categoryValue && `&category=${categoryValue}`
        }`
      );
    }
  };
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { categories, homeServices, serviceType } =
    useSelector(selectClientHome);
  categories.map((item: any, index: number) => {
    categoryListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
      label2: item.nameInArabic,
    });
  });
  useEffect(() => {
    if (categoryValue)
      if (search)
        navigate(
          `/search-result${search && `?keyword=${search}`}${
            categoryValue && `&category=${categoryValue}`
          }`
        );
      else
        navigate(
          `/search-result${categoryValue && `?category=${categoryValue}`}`
        );
  }, [categoryValue]);
  useEffect(() => {
    dispatch(listCategory());
    // setSearchParams();
  }, []);
  return (
    <>
      <div className="flex  gap-5   flex-col  md:flex-row  mx-4 md:mx-[100px]">
        <div className="w-full  h-[42px]  mt-10 md:w-[30%]  category-dropdown">
          <SelectDropdown
            className={`font-general rtl:font-Almarai text-var(--secondary) `}
            id="FormLanguage"
            name="languages"
            placeholder={t('LANDING_PAGE_AFTER_LOGIN.CATEGORY')}
            labelClass={categoryValue ? 'hidden' : ''}
            optional={1}
            value={categoryValue}
            isSearchable={false}
            onChange={(event: { label: string; value: string }) =>
              setCategoryValue((value) => (value = event.value))
            }
            getOptionLabel={(option: any) =>
              lang === 'en' ? option.label : option.label2
            }
            options={categoryListEn}
            grayOutline={true}
          />
        </div>
        <div className="w-full h-[46px] md:mt-10 md:w-[70%]  mb-2 ">
          <form className="h-full">
            <div className=" my-upload-search-box w-full h-full">
              <div className="w-[4%]  h-[44px] flex items-center justify-center">
                <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
                  <img
                    src="/assets/gridView/searchIcon.svg"
                    id={search}
                    alt=""
                  />
                </button>
              </div>
              <input
                type="text"
                id="simple-search"
                className="text-gray-900 text-sm h-[42px] search-input  w-[93%] rtl:font-Almarai"
                placeholder={t('MY_UPLOADS.SEARCH_TXT')}
                required
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                value={search}
              />
              {search && (
                <img
                  onClick={() => {
                    setSearchParams('');
                    setSearch('');
                  }}
                  className="cursor-pointer"
                  src="/assets/subscriptionPage/plusIcon.svg"
                  alt="bell-icon"
                />
              )}
            </div>
          </form>
        </div>
      </div>
      <div
        className="  flex justify-around bg-contain xl:bg-cover lg:h-[597px]  lg:pt-12 bg-no-repeat mt-10"
        style={{
          backgroundImage: `url(${'/assets/bodyAfterLogin/coverImage.svg'})`,
        }}
      >
        <div className="flex  justify-between gap-5 ltr:lg:gap-10 flex-col md:flex-row w-full ">
          <div className="flex items-center ltr:md:ml-[100px] ltr:xl:ml-[200px] rtl:md:mr-[100px] rtl:xl:mr-[200px]  md:max-w-[50%] flex-shrink ">
            <div className="flex-shrink flex flex-col w-full px-5 md:px-0 pt-[70px]">
              <div className="flex sm:pr-2.5 flex-col  text-28 md:text-48 flex-shrink    md:mb-[16px] font-satoshi rtl:font-Almarai  font-bold text-darkGrey ">
                <div className="w-full  bg-white">
                  <div className="  w-full  lg:w-[414px] bg-white ">
                    {createOpen
                      ? t('LANDING_PAGE_BEFORE_LOGIN.LEARN_NEW_SKILLS')
                      : trail.map(({ ...style }, index) => (
                          <a.div className={'z-10'} key={index} style={style}>
                            <a.div className={'z-10'}>{items[index]}</a.div>
                          </a.div>
                        ))}
                  </div>
                </div>

                <div>
                  <p className="p-text text-justify  text-16 lg:text-20  xl:w-/12  ltr:mr-4 rtl:ml-4 flex-shrink">
                    <span className="bg-[#ffffff]">
                      {t(
                        'LANDING_PAGE_BEFORE_LOGIN.LEARN_NEW_SKILLS_DESCRIPTION'
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex  gap-5 flex-row  flex-shrink">
                <button
                  onClick={() =>
                    navigate('/search-result?page=0&size=9&serviceType=COACHES')
                  }
                  className="bg-lightBlue text-white font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-full md:w-[240px] h-auto min-h-[54px] rounded py-4  sm:px-4"
                >
                  {t('LANDING_PAGE_BEFORE_LOGIN.BROWSE_COACHES')}
                </button>

                <button
                  onClick={() =>
                    navigate('/coach/registration/personal-details')
                  }
                  className="bg-white text-black font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-full md:w-[240px] h-auto  min-h-[54px] rounded py-4  sm:px-4 border-[1px] border-black  text-darkGrey  hover:bg-primary hover:border-transparent hover:text-white  "
                >
                  {t('LANDING_PAGE_BEFORE_LOGIN.BECOME_COACH')}
                </button>
              </div>
            </div>
          </div>

          <div className=" flex  justify-center  items-center px-5 md:px-0  md:w-[50%] rtl:lg:ml-20 rtl:sm:ml-5">
            {createOpen ? (
              <img
                className="object-cover"
                src="/assets/bodyAfterLogin/coverImage-2.png"
                alt=""
              />
            ) : (
              trail.map(({ ...style }, index) => (
                <a.div className={''} key={index} style={style}>
                  <a.div className={''}>
                    <img
                      className="object-cover"
                      src="/assets/bodyAfterLogin/coverImage-2.png"
                      alt=""
                    />
                  </a.div>
                </a.div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const Offers = ({ t }: any) => {
  const whatWeOffers = [
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_CARD_HEADING_ONE'),
      text: t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_CARD_TEXT_ONE'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_CARD_HEADING_TWO'),
      text: t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_CARD_TEXT_TWO'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_CARD_HEADING_THREE'),
      text: t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_CARD_TEXT_THREE'),
    },
  ];
  return (
    <div className="px-5 md:px-24   ">
      <div className="mt-12 md:mt-[80px] font-satoshi rtl:font-Almarai h-[41px] text-34 font-bold">
        {t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS')}
      </div>
      <p className=" p-text  text-17  font-normal  w-full pt-6 text-justify  ">
        {t('LANDING_PAGE_BEFORE_LOGIN.WHAT_WE_OFFERS_DESCRIPTION')}
      </p>
      <div className="mt-12 flex flex-col xmd:flex-row justify-between gap-5  ">
        {whatWeOffers.map((element, index: number) => {
          return (
            <div
              key={`${index}`}
              className="flex flex-col flex-1 flex-start flex-grow h-[216px] justify-center rounded-10px bg-white  p-6 shadow-[0_10px_25px_0_rgba(0,0,51,0.08)]"
            >
              <div>
                <img src="/assets/subscriptionPage/cloud-icon.svg" alt="" />
              </div>
              <div className="text-22  font-bold font-satoshi rtl:font-Almarai pt-[22px] pb-4">
                {element.heading}
              </div>
              <div className="font-normal text-16 landing-paragraph-client  font-general rtl:font-Almarai rtl:text-right ltr:text-left">
                {element.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SomeCount = ({ t }: any) => {
  const dispatch = useDispatch<any>();
  const { clientCount, coachCount, assessmentCounducted, hoursCount } =
    useSelector(selectLanding);

  useEffect(() => {
    dispatch(getClientCount());
    dispatch(getCoachCount());
    dispatch(getAssessmentConducted());
    dispatch(getServiceHoursCount());
  }, []);
  const { lang } = useAppSelector((state) => state.language);

  const countDatas = [
    {
      no: clientCount,
      text: t('LANDING_PAGE_BEFORE_LOGIN.NUMBER_OF_CLIENTS'),
    },
    {
      no: coachCount,
      text: t('LANDING_PAGE_BEFORE_LOGIN.ACTIVE_COACHES'),
    },
    {
      no: hoursCount,
      text: t('LANDING_PAGE_BEFORE_LOGIN.SERVICE_HOURS'),
    },
    {
      no: assessmentCounducted,
      text: t('LANDING_PAGE_BEFORE_LOGIN.ASSESSMENTS_CONDUCTED'),
    },
  ];
  return (
    <div className="mt-12 bg-footerBg h-full flex flex-col items-center px-5 md:px-24  text-center  ">
      <div className="text-white font-bold text-2xl mb-3 lg:text-[34px] mt-10 rtl:font-Almarai">
        {t('LANDING_PAGE_BEFORE_LOGIN.SOME_COUNT_THAT_MATTERS')}
      </div>
      <div className="text-white text-center rtl:font-Almarai">
        {t('LANDING_PAGE_BEFORE_LOGIN.SHORT_DESCRIPTION')}
      </div>
      <div className="flex lg:flex-row gap-10 justify-center items-center mt-10 flex-col w-full ">
        {countDatas.map((item: any, index: any) => (
          <React.Fragment key={index}>
            <div
              className={
                'flex flex-col w-full items-center justify-center align-middle  lg:border-none '
              }
            >
              <div
                className={`text-lightGreen font-bold text-[48px]  w-full  text-center`}
              >
                <VisibilitySensor>
                  {({ isVisible }: any) => (
                    <div>
                      {isVisible ? (
                        <CountUp end={item.no} duration={1} />
                      ) : (
                        <>{item.no}</>
                      )}
                    </div>
                  )}
                </VisibilitySensor>
              </div>
              <div className="text-white  mb-10  w-full text-center rtl:font-Almarai">
                {item.text}
              </div>
            </div>
            {(lang === 'en'
              ? index + 1 !== countDatas.length
              : index !== 3) && (
              <div className=" lg:w-[1px] lg:h-[100px] w-[60vw] h-[1px]   bg-white opacity-25"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const HowKUNWorks = ({ isClient, setIsClient, t }: any) => {
  const navigate = useNavigate();

  const clientData: CoachClientData[] = [
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_HEADING_ONE'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_SHORT_ONE'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_HEADING_TWO'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_SHORT_TWO'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_HEADING_THREE'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_SHORT_THREE'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_HEADING_FOUR'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.CLIENT_TEXT_SHORT_FOUR'),
    },
  ];
  const coachData: CoachClientData[] = [
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_HEADING_ONE'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_SHORT_ONE'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_HEADING_TWO'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_SHORT_TWO'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_HEADING_THREE'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_SHORT_THREE'),
    },
    {
      heading: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_HEADING_FOUR'),
      description: t('LANDING_PAGE_BEFORE_LOGIN.COACH_TEXT_SHORT_FOUR'),
    },
  ];

  const config = { mass: 50, tension: 2000, friction: 1000 };

  const clientTrail = useTrail(clientData.length, {
    config,
    opacity: isClient ? 1 : 0,
    x: isClient ? 20 : 20,
    from: { opacity: 0, x: 20, height: 0 },
  });
  const coachTrail = useTrail(coachData.length, {
    config,
    opacity: !isClient ? 1 : 0,
    x: !isClient ? 20 : 20,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <>
      {' '}
      <div
        className={`flex px-5 md:px-24 flex-col xmd:flex-row xmd:items-end items-center ${
          !isClient && 'rtl:gap-10'
        }`}
      >
        <div className="xmd:w-[55%] w-full ">
          <div className="mt-12 md:mt-[80px] font-satoshi rtl:font-Almarai h-[41px] text-34 font-bold">
            {t('LANDING_PAGE_BEFORE_LOGIN.HOW_KUN_WORKS')}
          </div>
          <p className=" p-text  text-17  font-normal  w-full pt-6 text-justify  ">
            {t('LANDING_PAGE_BEFORE_LOGIN.KUN_WORKS_DESCRIPTION')}
          </p>
          <div className="flex mb-6 gap-2 bg-offWhite w-fit max-w-[80vw] rounded p-1">
            <button
              onClick={() => setIsClient(true)}
              className={`text-14
          md:text-18 w-[190px] md:w-[200px] h-[54px] rounded py-4  lg:px-auto font-satoshi rtl:font-Almarai font-bold ${
            isClient
              ? 'bg-primary text-white '
              : 'border border-offWhite bg-offWhite border-offWhite text-secondary  hover:bg-primary hover:border-transparent hover:text-white'
          }`}
            >
              {t('LANDING_PAGE_BEFORE_LOGIN.CLIENT')}
            </button>
            <button
              onClick={() => setIsClient(false)}
              className={` text-14
          md:text-18 w-[190px] md:w-[200px] h-[54px] rounded py-4
           lg:px-auto font-satoshi rtl:font-Almarai font-bold  hover:bg-primary hover:border-transparent hover:text-white ${
             isClient
               ? 'border border-offWhite bg-offWhite border-offWhite text-secondary'
               : 'bg-primary text-white'
           }`}
            >
              {t('LANDING_PAGE_BEFORE_LOGIN.COACH')}
            </button>
          </div>
          {isClient
            ? clientTrail.map(
                ({ x, height, ...rest }, index) =>
                  index === 0 && (
                    <a.div
                      key={clientData[index].heading}
                      className="trails-text"
                      style={{
                        ...rest,
                        transform: x.interpolate(
                          (x) => `translate3d(0,${x}px,0)`
                        ),
                      }}
                    >
                      {clientData.map((item: CoachClientData, i: number) => (
                        <ClientCoachCard data={item} key={i} />
                      ))}
                    </a.div>
                  )
              )
            : coachTrail.map(
                ({ x, height, ...rest }, index) =>
                  index === 0 && (
                    <a.div
                      key={coachData[index].heading}
                      className="trails-text"
                      style={{
                        ...rest,
                        transform: x.interpolate(
                          (x) => `translate3d(0,${x}px,0)`
                        ),
                      }}
                    >
                      {coachData.map((item: CoachClientData, i: number) => (
                        <ClientCoachCard data={item} key={i} />
                      ))}
                    </a.div>
                  )
              )}
        </div>
        <div className="xmd:w-[45%] xmd:flex  lg:mt-[18px] ml-0 xmd:ml-[152px] mb-[12px] xxl:mt-[26px] w-full hidden justify-center">
          {isClient
            ? clientTrail.map(
                ({ x, height, ...rest }, index) =>
                  index === 0 && (
                    <a.div
                      key={clientData[index].heading}
                      className="trails-text "
                      style={{
                        ...rest,
                        transform: x.interpolate(
                          (x) => `translate3d(0,${x}px,0)`
                        ),
                      }}
                    >
                      {index === 0 && (
                        <img
                          src="/assets/bodyBeforeLogin/kun-client.png"
                          className=""
                          alt=""
                        />
                      )}
                    </a.div>
                  )
              )
            : coachTrail.map(
                ({ x, height, ...rest }, index) =>
                  index === 0 && (
                    <a.div
                      key={coachData[index].heading}
                      className="trails-text"
                      style={{
                        ...rest,
                        transform: x.interpolate(
                          (x) => `translate3d(0,${x}px,0)`
                        ),
                      }}
                    >
                      {index === 0 && (
                        <img
                          src="/assets/bodyBeforeLogin/kun-coach.png"
                          className=""
                          alt=""
                        />
                      )}
                    </a.div>
                  )
              )}
        </div>
      </div>
      {!isClient ? (
        <div className="w-full px-5 pd:mx-24 ">
          <Button
            onClick={() => navigate('/coach-subscription')}
            className="text-white  bg-footerBg md:mx-20 mt-8 md:w-[257px] w-full h-[54px] rounded py-4
    lg:px-auto font-satoshi rtl:font-Almarai font-bold "
          >
            <>{t('LANDING_PAGE_BEFORE_LOGIN.COACH_SUBSCRIPTION')}</>
          </Button>
        </div>
      ) : null}
    </>
  );
};

const ClientCoachCard = ({ data }: any) => {
  return (
    <>
      {/* {data.map((item: CoachClientData, index: number) => ( */}
      <div
        key={`${data.heading}-coach-client`}
        className="flex flex-row border-[1px] border-lightBlack mb-3 items-start rounded p-[20px]"
      >
        <div className=":w-[7%]">
          <div className="w-[60px] p-2">
            <img src="/assets/aboutUsPage/uploadIcon.svg" alt=""></img>
          </div>
        </div>
        <div>
          <div className="font-bold pt-2 pl-1 rtl:font-Almarai ">
            {data.heading}
          </div>
          <div className="text-greyText p-1 rtl:font-Almarai ">
            {data.description}
          </div>
        </div>
      </div>
      {/* ))} */}
    </>
  );
};
const CoachOfMonth = ({ t, slider }: any) => {
  const [footerImage, setFooterImage] = useState(-1);
  const { coachOfMonth } = useSelector(selectLanding);

  return (
    <div className=" sm_max:mt-6 bg-footerBg w-full object-cover mx-0 mt-[33px] mt-10 ">
      <div className="flex flex-col w-full md:flex-row items-center justify-center h-full">
        <div className=" md:w-[30vw] mx-5 sm:ml-11 py-14">
          <div className="flex flex-col  landing-heading text-white  flex-start font-bold  ">
            <div>{t('LANDING_PAGE_BEFORE_LOGIN.COACH_OF_MONTH')}</div>
          </div>
          <div className="landing-paragraph-client text-[#ffffff] font-general rtl:font-Almarai w-full  pt-4 lg:text-justify ">
            {coachOfMonth.bioDescription}
          </div>
          <div className="text-lightGreen pt-6 text-14 font-satoshi rtl:font-Almarai font-bold mb-[10vw]">
            {coachOfMonth.fullName}
          </div>
          <div className="flex gap-1  md:flex hidden">
            <div
              onClick={() => slider?.current?.slickPrev()}
              onMouseEnter={() => setFooterImage(0)}
              onMouseLeave={() => setFooterImage(-1)}
            >
              <img
                src={`${
                  footerImage !== 0
                    ? '/assets/bodyBeforeLogin/arrow-left.svg'
                    : '/assets/bodyBeforeLogin/arrow-left-green.svg'
                }`}
                alt=""
              />
            </div>
            <div
              onClick={() => slider?.current?.slickNext()}
              onMouseEnter={() => setFooterImage(1)}
              onMouseLeave={() => setFooterImage(-1)}
            >
              <img
                src={`${
                  footerImage !== 1
                    ? '/assets/bodyBeforeLogin/arrow-right.svg'
                    : '/assets/bodyBeforeLogin/arrow-right-green.svg'
                }`}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="  justify-center py-14 mx-10">
          {/* <img className="" src="/assets/coach.svg" alt="" /> */}
          <Image
            id={coachOfMonth?.profilePicId}
            // id={0}
            bgImgUrl={'/assets/default-img-icon.svg'}
            bgClass={
              ' bg-[#F6F8F5] sm:h-[470px] w-[90vw] sm:w-[500px] h-[300px] rounded flex justify-center items-center'
            }
            propClass={'sm:h-[470px] w-[90vw] sm:w-[500px] h-[300px] rounded'}
          />
          <div className="flex gap-1   md:hidden flex mt-4">
            <div
              onClick={() => slider?.current?.slickPrev()}
              onMouseEnter={() => setFooterImage(0)}
              onMouseLeave={() => setFooterImage(-1)}
            >
              <img
                src={`${
                  footerImage !== 0
                    ? '/assets/bodyBeforeLogin/arrow-left.svg'
                    : '/assets/bodyBeforeLogin/arrow-left-green.svg'
                }`}
                alt=""
              />
            </div>
            <div
              onClick={() => slider?.current?.slickNext()}
              onMouseEnter={() => setFooterImage(1)}
              onMouseLeave={() => setFooterImage(-1)}
            >
              <img
                src={`${
                  footerImage !== 1
                    ? '/assets/bodyBeforeLogin/arrow-right.svg'
                    : '/assets/bodyBeforeLogin/arrow-right-green.svg'
                }`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Coaches = ({ t }: any) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const slider: any = React.useRef(null);
  return (
    <div className="relative w-[100vw] overflow-hidden">
      <Slider ref={slider} {...settings}>
        <CoachOfMonth slider={slider} t={t} />
        <CoachOfMonth slider={slider} t={t} />
      </Slider>
    </div>
  );
};
const SignUpKUN = ({ t }: any) => {
  const navigate = useNavigate();

  return (
    <div className="flex  md:px-20 smMax:w-auto w-[100vw] px-5 my-[50px] ">
      <div className="flex lgmd:flex-row flex-col  w-[100%] gap-5 justify-between ">
        <div className="flex flex-col">
          <div className="text-black font-bold md:text-48 text-34  text-darkGrey rtl:font-Almarai">
            {t('LANDING_PAGE_BEFORE_LOGIN.SIGNUP_KUN')}
          </div>
          <div className="text-greyText pt-2 rtl:font-Almarai">
            {t('LANDING_PAGE_BEFORE_LOGIN.SIGNUP_TEXT')}
          </div>
        </div>
        <div className="flex lgmd:flex-row flex-col pt-4 p-0  lgmd:pt-4 items-center gap-4 ">
          <button
            onClick={() =>
              navigate('/search-result?page=0&size=9&serviceType=COACHES')
            }
            className="about-us-page-footer-btn  font-satoshi rtl:font-Almarai text-18"
          >
            {t('LANDING_PAGE_BEFORE_LOGIN.BROWSE_COACHES')}
          </button>

          <button
            onClick={() => navigate('/coach/registration/personal-details')}
            className="about-us-body-page-footer-btn-without-bg  font-satoshi rtl:font-Almarai text-18  text-darkGrey  hover:bg-primary hover:border-transparent hover:text-white"
          >
            {t('LANDING_PAGE_BEFORE_LOGIN.BECOME_COACH')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPageBody;

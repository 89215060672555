import {
  IChangePassword,
  ILoginPayload,
  IPostCaptchaPayload,
  IRecoverPassword,
  IRegister,
} from '../clientInterfaces/authentication';
import { get, post } from './api';

export const register = async (data: IRegister) => {
  return await post('/clients', data);
};

export const authenticate = async (data: ILoginPayload) => {
  return await post('/auth/login', data);
};

export const captchaText = async () => {
  return await get('/auth/generate-captcha');
};

export const recoverPassword = async (data: IRecoverPassword) => {
  return await post('/auth/forgot-password', data);
};

export const changePassword = async (data: IChangePassword) => {
  return await post('/auth/change-password', data);
};

export const postVerifyCaptcha = async (captcha: IPostCaptchaPayload) => {
  return await post('/auth/verify-captcha', captcha);
};

export const logOut = async () => {
  return await post('/auth/logout', {});
};

import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { coachIntendValidationSchema } from '../../coachHelpers';
import {
  sessionInfo,
  setIntendedClientFormData,
} from '../../coachSlices/sessionSlice';

interface IFaqCard {
  intendFormRef: any;
}

const Component: FC<IFaqCard> = ({ intendFormRef }): JSX.Element => {
  const dispatch = useDispatch<any>();
  const [t] = useTranslation('common');
  const { intendedClientFormValue } = useSelector(sessionInfo);

  const handleFormSubmit = () => {};

  const checkBoxValues = [
    {
      label: t('COACH_SESSION.CEOC_SUITE'),
      value: 'CEO/ C-Suite',
    },
    {
      label: t('COACH_SESSION.VICE_PRESIDENT'),
      value: 'Vice President',
    },
    {
      label: t('COACH_SESSION.SR_DIRECTOR'),
      value: 'Sr Director',
    },
    {
      label: t('COACH_SESSION.DIRECTOR'),
      value: 'Director',
    },
    {
      label: t('COACH_SESSION.MANAGER'),
      value: 'Manager',
    },
    {
      label: t('COACH_SESSION.TEAM_LEAD'),
      value: 'Team lead',
    },
    {
      label: t('COACH_SESSION.INDIVIDUAL_CONTRIBUTER'),
      value: 'Individual Contributor',
    },
    {
      label: t('COACH_SESSION.BUSINESS_OWNER'),
      value: 'Business Owner',
    },
    {
      label: t('COACH_SESSION.ENTREPRENEURS'),
      value: 'Entrepreneurs',
    },
    {
      label: t('COACH_SESSION.TO_EVERYONE'),
      value: 'To Everyone',
    },
    { label: t('COACH_SESSION.OTHERS'), value: 'Others' },
  ];
  const [checkedState, setCheckedState] = useState(
    new Array(checkBoxValues.length).fill(false)
  );
  const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);

  const handleOnChange = (
    position: number,
    setFieldValue: any,
    value: string
  ) => {
    if (value === 'Others') {
      setDropDownVisible(!dropDownVisible);
    }
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    let array: any = [];
    array.push(
      updatedCheckedState.map((item: boolean, index: number) => {
        if (item === true) {
          return checkBoxValues[index].value;
        } else return null;
      })
    );

    setFieldValue('forWhom', ...array);

    setCheckedState(updatedCheckedState);
  };
  useEffect(() => {
    if (intendedClientFormValue.forWhom.length > 0) {
      let updatedCheckedState: any = [];

      intendedClientFormValue.forWhom.map((value: string, index: number) => {
        updatedCheckedState.push(value === null || value === '' ? false : true);
        if (value === 'Others') {
          setDropDownVisible(true);
        }
      });
      setCheckedState(updatedCheckedState);
    }
  }, [intendedClientFormValue]);

  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-Almarai font-bold text-[34px]">
        {t('COACH_SESSION.INTENDED_CLIENTS')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_SESSION.INTENDED_CLIENTS_TXT')}
      </div>
      <div className="w-full font-satoshi rtl:font-Almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.PREFERRED_GENDER')}
      </div>
      <Formik
        innerRef={intendFormRef}
        initialValues={intendedClientFormValue}
        validationSchema={coachIntendValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setIntendedClientFormData(values));

          return (
            <Form>
              <div className="flex flex-row">
                <input
                  id="FormGender"
                  type="radio"
                  name="gender"
                  onChange={handleChange}
                  value="MALE"
                  checked={values.gender === 'MALE'}
                  defaultChecked={true}
                />
                <span className="font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3">
                  {t('COACH_SESSION.MALE')}
                </span>
                <input
                  id="FormGender"
                  type="radio"
                  name="gender"
                  onChange={handleChange}
                  value="FEMALE"
                  checked={values.gender === 'FEMALE'}
                />
                <span className="font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3">
                  {t('COACH_SESSION.FEMALE')}
                </span>
                <input
                  id="FormGender"
                  type="radio"
                  name="gender"
                  placeholder={t('COACH_SESSION.TITLE')}
                  onChange={handleChange}
                  value="BOTH"
                  checked={values.gender === 'BOTH'}
                />
                <span className="font-general rtl:font-Almarai font-normal text-[#687E8E] text-base mx-3">
                  {t('COACH_SESSION.BOTH')}
                </span>
              </div>
              {errors?.gender && (
                <p className="text-red rtl:font-Almarai font-normal text-12 pt-3 ">
                  {t(errors?.gender as string)}
                </p>
              )}
              <div className="w-full font-satoshi rtl:font-Almarai mb-2 font-bold mt-9 text-xl">
                {t('COACH_SESSION.CLIENTS_LEARN')}
              </div>
              <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 ">
                {t('COACH_SESSION.CLIENTS_LEARN_TXT')}
              </div>
              <FieldArray
                name="whatWillLearn"
                render={(arrayHelpers) => {
                  const formData =
                    values?.whatWillLearn?.length > 0 && values?.whatWillLearn;

                  return (
                    <div>
                      {formData && formData?.length > 0
                        ? formData?.map(
                            (accreditationRequest: any, i: number) => (
                              <Input
                                optional={true}
                                id={`whatWillLearn.${i}`}
                                type="text"
                                name={`whatWillLearn.${i}`}
                                placeholder={t('COACH_SESSION.EXAMPLE_SESSION')}
                                onBlur={handleBlur}
                                value={values?.whatWillLearn[i]}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    `whatWillLearn.${i}`,
                                    e.target.value
                                  );
                                }}
                              />
                            )
                          )
                        : null}
                      <button
                        type="button"
                        className="add_more "
                        onClick={() => {
                          arrayHelpers.push('');
                        }}
                      >
                        {t('ADD_MORE')}
                      </button>
                    </div>
                  );
                }}
              />
              <div className="w-full font-satoshi rtl:font-Almarai mb-2 font-bold mt-9 text-xl">
                {t('COACH_SESSION.SESSION_REQ')}
              </div>
              <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 ">
                {t('COACH_SESSION.SESSION_REQ_TXT')}
              </div>
              <FieldArray
                name="requirements"
                render={(arrayHelpers) => {
                  const formData =
                    values?.requirements?.length > 0 && values?.requirements;

                  return (
                    <div>
                      {formData && formData?.length > 0
                        ? formData?.map(
                            (accreditationRequest: any, i: number) => (
                              <Input
                                optional={true}
                                id={`requirements.${i}`}
                                type="text"
                                name={`requirements.${i}`}
                                placeholder={t(
                                  'COACH_SESSION.SESSION_REQ_EXAMPLE'
                                )}
                                onBlur={handleBlur}
                                value={values?.requirements[i]}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    `requirements.${i}`,
                                    e.target.value
                                  );
                                }}
                              />
                            )
                          )
                        : null}
                      <button
                        type="button"
                        className="add_more "
                        onClick={() => {
                          arrayHelpers.push('');
                        }}
                      >
                        {t('ADD_MORE')}
                      </button>
                    </div>
                  );
                }}
              />
              <div className="w-full font-satoshi rtl:font-Almarai mb-2 font-bold mt-9 text-xl">
                {t('COACH_SESSION.SESSION_USERS')}
              </div>
              <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 ">
                {t('COACH_SESSION.SESSION_USERS_TXT')}
              </div>
              <div className="w-full flex flex-wrap">
                {checkBoxValues.map(
                  (item: { value: string; label: string }, index: number) => (
                    <div
                      className={`${
                        checkedState[index]
                          ? ' bg-primary text-[#ffffff] '
                          : ' bg-white text-[#687E8E] '
                      } 
                      check-box-intended h-[40px] width-fit font-general rtl:font-Almarai font-medium text-base flex   rounded border-[1px] border-[#687E8E] p-2 items-center gap-2 mb-2 ltr:mr-2 rtl:ml-2`}
                    >
                      <input
                        className="ltr:mr-2 rtl:ml-2 regular-checkbox"
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name="forWhom"
                        value={item.value}
                        checked={checkedState[index]}
                        onChange={() =>
                          handleOnChange(index, setFieldValue, item.value)
                        }
                      />
                      <label htmlFor={`custom-checkbox-${index}`}>
                        {item.label}
                      </label>
                    </div>
                  )
                )}
              </div>
              {dropDownVisible && (
                <>
                  <Field name="tags">
                    {(field: FieldProps) => (
                      <div className="react-multi-react-tags mt-3 ">
                        <div className="mb-8">
                          <SelectDropdown
                            id="Formtags"
                            isMulti={true}
                            isCreatable={true}
                            options={[]}
                            optional={1}
                            dropDown={true}
                            name="tags"
                            placeholder={t('COACH_SESSION.TAGS')}
                            onBlur={handleBlur}
                            onChange={(opt: any) => {
                              setFieldValue('tags', opt);
                            }}
                            className="my-3 "
                            isValue={values.tags[0]?.value}
                            inn={values.tags[0]?.value}
                            defaultValue={values.tags}
                            controlShouldRenderValue={true}
                            error={
                              field?.field?.value?.length > 0
                                ? false
                                : field.meta.touched && 1
                            }
                            errormessage={''}
                          />
                          {field.meta.touched &&
                            field?.field?.value?.length === 0 && (
                              <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 ">
                                {t('COACH_SESSION.TAGS_REQ')}
                              </p>
                            )}
                        </div>
                      </div>
                    )}
                  </Field>
                </>
              )}
              <div className="w-full font-satoshi rtl:font-Almarai mb-2 font-bold mt-9 text-xl">
                {t('COACH_SESSION.SESSION_BENEFIT')}
              </div>
              <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 ">
                {t('COACH_SESSION.SESSION_BENEFIT_TXT')}
              </div>
              <FieldArray
                name="benefits"
                render={(arrayHelpers) => {
                  const formData =
                    values?.benefits?.length > 0 && values?.benefits;

                  return (
                    <div>
                      {formData && formData?.length > 0
                        ? formData?.map(
                            (accreditationRequest: any, i: number) => (
                              <Input
                                optional={true}
                                id={`benefits.${i}`}
                                type="text"
                                name={`benefits.${i}`}
                                placeholder={t(
                                  'COACH_SESSION.EXAMPLE_SESSION_BENEFITS'
                                )}
                                className="truncate"
                                onBlur={handleBlur}
                                value={values?.benefits[i]}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    `benefits.${i}`,
                                    e.target.value
                                  );
                                }}
                              />
                            )
                          )
                        : null}
                      <button
                        type="button"
                        className="add_more "
                        onClick={() => {
                          arrayHelpers.push('');
                        }}
                      >
                        {t('ADD_MORE')}
                      </button>
                    </div>
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
const IntendedClientsSession = styled(Component)``;

export default IntendedClientsSession;

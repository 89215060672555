import { useTranslation } from 'react-i18next';

export const Bestseller = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <button className="w-full bg-lightBlue text-white rounded-md p-2 h-8 flex items-center font-semibold rtl:font-Almarai">
        {t(`CLIENT_SESSION_VIEW.BEST_SELLER`)}
      </button>
    </>
  );
};

/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import {Route, Routes, useLocation} from 'react-router-dom';
import {
  IRoutesDetails,
  ISubRouteDetails,
} from '../coachInterfaces/routeInterface';
import { paymentInfo } from '../coachSlices/paymentSlice';
import ProtectedRoute from './protectedRoute';
import PublicRoute from './publicRoute';
import { RouteDetails, paymentRoutes } from './routeConstants';
import {useEffect} from "react";
import ReactGA from "react-ga4";

const RouteContainer = () => {
  const location = useLocation();
  useEffect(() => {
    console.log('Location changed!', location.pathname);
    ReactGA.send({ hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname
    });
  }, [location]);
  const { purchased } = useSelector(paymentInfo);
  const data = purchased ? RouteDetails : paymentRoutes;
  return (
    <Routes>
      {data.map((routeDetails: IRoutesDetails, index: number) => {
        return (
          <Route key={`route-${routeDetails.path}${index}`}>
            <Route
              key={index}
              path={routeDetails.path}
              element={
                routeDetails.isProtected ? (
                  <ProtectedRoute user={routeDetails.user}>
                    <routeDetails.component />
                  </ProtectedRoute>
                ) : (
                  <PublicRoute
                    restricted={routeDetails.restricted}
                    user={routeDetails.user}
                  >
                    <routeDetails.component />
                  </PublicRoute>
                )
              }
            >
              {routeDetails.children &&
                routeDetails.children.map(
                  (subRoute: ISubRouteDetails, index: number) => (
                    <Route
                      key={`route-path-${subRoute.path}${index}`}
                      path={subRoute.path}
                      element={<subRoute.component />}
                    />
                  )
                )}
            </Route>
          </Route>
        );
      })}
    </Routes>
  );
};

export default RouteContainer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { weekDaysList } from '../../coachConstants/coachSessionConstants';
import {
  checkArray,
  convert24to12,
  convert24to12Item,
  getHoursData,
  utcToLocal,
  UtcToLocaldayConversion,
} from '../../coachHelpers/utility';
import {
  getHoursServiceApi,
  hoursAndServiceApi,
} from '../../coachServices/profile';
import { showAlert } from '../../slice/alert';

const initialState: any = {
  coachingHoursAndServiceFormValue: {
    days: 'Sunday-Thursday',
    start: '00:00',
    end: '02:00',
    alternative: false,
    coachingHourAndServiceRequests: weekDaysList.map(
      (item: { label: string; value: string }) => ({
        day: item.value,
        from: '12:00',
        localFromTime: 'AM',
        end: '12:00',
        locaEndTime: 'PM',
      })
    ),
  },
  isProcessingMyCoachUploads: false,
};

export const hoursAndServiceSlice = createSlice({
  name: 'hoursAndService',
  initialState,
  reducers: {
    setHoursAndServiceFormData: (state, action: PayloadAction<any>) => {
      state.coachingHoursAndServiceFormValue = action.payload;
    },

    clearoursAndServiceFormData: (state) => {
      state.coachingHoursAndServiceFormValue = {
        days: 'Sunday-Thursday',
        start: '00:00',
        end: '02:00',
        alternative: false,
        coachingHourAndServiceRequests: Array.from(Array(7)).map(() => ({
          day: '',
          from: '12:00',
          localFromTime: 'AM',
          end: '12:00',
          locaEndTime: 'PM',
        })),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHoursAndService.pending, (state) => {
        state.isProcessingMyCoachUploads = true;
      })
      .addCase(getHoursAndService.fulfilled, (state, action) => {
        state.myCoachUploads = action.payload;
        state.isProcessingMyCoachUploads = false;
      })
      .addCase(getHoursAndService.rejected, (state, action) => {
        state.isProcessingMyCoachUploads = false;
      });
  },
});

export const createHoursAndService = createAsyncThunk(
  'hoursAndService',
  async (userData: any, thunkAPI) => {
    try {
      const response = await hoursAndServiceApi(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getHoursAndService = createAsyncThunk(
  'getHoursAndService',
  async (userData: any, thunkAPI) => {
    try {
      const response = await getHoursServiceApi(userData);
      const hours = response.data[0]?.responseList.map((item: any) => {
        item['day'] = UtcToLocaldayConversion(item?.day, item?.startTime);
        item['endTime'] = utcToLocal(item?.endTime);
        item['startTime'] = utcToLocal(item?.startTime);
        return item;
      });

      if (hours?.length > 0) {
        if (hours?.length === 1) {
          thunkAPI.dispatch(
            setHoursAndServiceFormData({
              days: 'Sunday-Thursday',
              start: '00:00',
              end: '02:00',
              alternative: true,
              coachingHourAndServiceRequests: convert24to12Item(
                hours?.length && (hours[0] as any)
              ),
            })
          );
        } else if (checkArray(hours as any[])) {
          const hoursData = getHoursData(hours as any[]);
          thunkAPI.dispatch(
            setHoursAndServiceFormData({
              days: hoursData.days,
              start: hoursData.start,
              end: hoursData.end,
              alternative: false,
              coachingHourAndServiceRequests: weekDaysList.map(
                (item: { label: string; value: string }) => ({
                  day: item.value,
                  from: '12:00',
                  localFromTime: 'AM',
                  end: '12:00',
                  locaEndTime: 'PM',
                })
              ),
            })
          );
        } else {
          thunkAPI.dispatch(
            setHoursAndServiceFormData({
              days: 'Sunday-Thursday',
              start: '00:00',
              end: '02:00',
              alternative: true,
              coachingHourAndServiceRequests: convert24to12(
                hours?.length ? (hours as any[]) : []
              ),
            })
          );
        }
      }
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const { setHoursAndServiceFormData, clearoursAndServiceFormData } =
  hoursAndServiceSlice.actions;
export const hoursAndServiceInfo = (state: any) => state.hoursAndServiceInfo;
export const hoursAndServiceInfoReducer = hoursAndServiceSlice.reducer;

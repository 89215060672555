/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { clearLocalStorage, setTokens } from '../clientHelpers/localStorage';
import { randomFontSize } from '../clientHelpers/utility';
import {
  IAuthentication,
  ICaptchaPayload,
  ILoginPayloadData,
  ILogOut,
  IRecoverPassword,
  IRegister,
  ISetNewPassword,
} from '../clientInterfaces/authentication';
import {
  authenticate,
  captchaText,
  changePassword,
  logOut,
  postVerifyCaptcha,
  recoverPassword,
  register,
} from '../clientServices/authentication';
import { decodeUserType } from '../coachHelpers/localStorage';
import { getSubscription } from '../coachSlices/paymentSlice';
import { coachProfile } from '../coachSlices/profileSlices/profileInfoSlice';
import { showAlert } from '../slice/alert';
import { RootState } from '../store';
import { listProfileView } from './profile';

const initialState: IAuthentication = {
  isProcessingLoginRequest: false,
  isProcessingCaptchaImage: false,
  captchaText: '',
  captchaId: '',
  captchaError: '',
  errorMessage: '',
  captchaErrorTouched: false,
  isProcessingRegisterRequest: false,
  isSetNewPassword: false,
  personalInfoData: {
    fullName: '',
    email: '',
    password: '',
    phoneNumber: '',
    occupation: '',
    company: '',
    dob: '',
    gender: '',
    countryCode: '+966',
  },
};
export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    personalInformationSinup: (state, action) => {
      state.personalInfoData = action.payload;
    },
    clearPersonalInformationSinup: (state) => {
      state.personalInfoData = {
        fullName: '',
        email: '',
        password: '',
        phoneNumber: '',
        occupation: '',
        company: '',
        dob: '',
        gender: '',
        countryCode: '+966',
      };
    },
    setCaptchaError: (
      state,
      action: PayloadAction<{ message: any; error: boolean }>
    ) => {
      state.captchaError = action.payload.message;
      state.captchaErrorTouched = action.payload.error;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(registerClient.pending, (state) => {
        state.isProcessingRegisterRequest = true;
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        state.isProcessingRegisterRequest = false;
      })
      .addCase(registerClient.rejected, (state, action: any) => {
        state.isProcessingRegisterRequest = false;
      })
      .addCase(authenticateUser.pending, (state) => {
        state.errorMessage = '';
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.isProcessingLoginRequest = false;
        state.errorMessage = '';
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isProcessingLoginRequest = false;
      })
      .addCase(logOutUser.pending, (state) => {})
      .addCase(logOutUser.fulfilled, (state, action) => {})
      .addCase(logOutUser.rejected, (state) => {})
      .addCase(forgotPassword.pending, (state) => {})
      .addCase(forgotPassword.fulfilled, (state, action) => {
        setTokens(action.payload.data[0]?.token);
      })
      .addCase(forgotPassword.rejected, (state) => {})
      .addCase(setNewPassword.pending, (state) => {
        state.isSetNewPassword = true;
      })
      .addCase(setNewPassword.fulfilled, (state, action) => {
        setTokens(action.payload.data[0]?.token);
        state.isSetNewPassword = false;
      })
      .addCase(setNewPassword.rejected, (state) => {
        state.isSetNewPassword = false;
      })
      .addCase(getCaptcha.pending, (state) => {
        state.isProcessingCaptchaImage = true;
      })
      .addCase(getCaptcha.fulfilled, (state, action) => {
        state.isProcessingCaptchaImage = false;
        state.captchaText = randomFontSize(
          action.payload?.data[0]?.captchaValue
        );
        state.captchaId = action.payload?.data[0]?.id;
      })
      .addCase(getCaptcha.rejected, (state) => {
        state.isProcessingCaptchaImage = false;
      })
      .addCase(verifyCaptcha.pending, (state) => {
        state.isProcessingLoginRequest = true;
      })
      .addCase(verifyCaptcha.fulfilled, (state) => {})
      .addCase(verifyCaptcha.rejected, (state) => {
        state.isProcessingLoginRequest = false;
      });
  },
});

export const registerClient = createAsyncThunk(
  'auth/register',
  async (userData: IRegister, thunkAPI) => {
    try {
      const response = await register(userData);
      thunkAPI.dispatch(
        authenticateUser({
          email: userData.email,
          password: userData.password,
          history: userData.history,
          user: 'CLIENT',
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const authenticateUser = createAsyncThunk(
  'auth/login',
  async (userData: ILoginPayloadData, thunkAPI) => {
    try {
      const response = await authenticate({
        email: userData.email,
        password: userData.password,
      });
      const decode: { userType: string } = jwt_decode(response.data[0].token);
      setTokens(response.data[0]?.token);

      if (userData.user === 'CLIENT') {
        if (userData.user === decode.userType) {
          localStorage.setItem(
            'userType',
            decodeUserType(response.data[0]?.token)
          );
          localStorage.setItem(
            'timezone',
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );
          userData.history('/client/profile');
          thunkAPI.dispatch(listProfileView());
        } else {
          thunkAPI.dispatch(
            showAlert({
              type: 'error',
              message: 'Tried to login as client with coach credentials',
              messageAr: 'لا يمكن التسجيل',
            })
          );
          await thunkAPI.dispatch(checkUserTypeLogOut());
          await thunkAPI.dispatch(getCaptcha());
        }
      } else if (userData.user === 'COACH') {
        if (userData.user === decode.userType) {
          localStorage.setItem(
            'userType',
            decodeUserType(response.data[0]?.token)
          );
          await thunkAPI.dispatch(getSubscription());
          localStorage.setItem(
            'timezone',
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );
          userData.history('/coach/home');
          thunkAPI.dispatch(coachProfile());
        } else {
          thunkAPI.dispatch(
            showAlert({
              type: 'error',
              message: 'Tried to login as coach with client credentials',
              messageAr: 'لا يمكن التسجيل'
            })
          );
          await thunkAPI.dispatch(checkUserTypeLogOut());
          await thunkAPI.dispatch(getCaptcha());
        }
      }

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      thunkAPI.dispatch(getCaptcha());
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkUserTypeLogOut = createAsyncThunk(
  'auth/logOut/usertype',
  async (userData, thunkAPI) => {
    try {
      const response = await logOut();
      clearLocalStorage();
      // userData.history('/');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const logOutUser = createAsyncThunk(
  'auth/logOut',
  async (userData: ILogOut, thunkAPI) => {
    try {
      const response = await logOut();
      clearLocalStorage();
      userData.history('/');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/recover-password',
  async (userData: IRecoverPassword, thunkAPI) => {
    try {
      const response = await recoverPassword(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: 'Please check your mail and reset password.',
          messageAr: 'الرجاء التأكد من البريد الألكتروني و اعاد كلمة المرور.',
        })
      );
      userData.history('/');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const setNewPassword = createAsyncThunk(
  'auth/set-new-password',
  async (userData: ISetNewPassword, thunkAPI) => {
    try {
      const payload = {
        confirmPassword: userData.confirmPassword,
        newPassword: userData.newPassword,
        token: userData.token,
      };
      const response = await changePassword(payload);
      userData.history(`/login?user=${userData.user}`);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCaptcha = createAsyncThunk(
  'auth/get-captcha',
  async (id, thunkAPI) => {
    try {
      const response = await captchaText();

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const verifyCaptcha = createAsyncThunk(
  'auth/verify-captcha',
  async (captchaPayload: ICaptchaPayload, thunkAPI) => {
    const { clientAuthentication } = thunkAPI.getState() as {
      clientAuthentication: IAuthentication;
    };
    try {
      const response = await postVerifyCaptcha({
        id: clientAuthentication.captchaId,

        captchaValue: captchaPayload.captcha,
      });
      thunkAPI.dispatch(setCaptchaError({ message: undefined, error: false }));

      thunkAPI.dispatch(
        authenticateUser({
          email: captchaPayload.email,
          password: captchaPayload.password,
          history: captchaPayload.history,
          user: captchaPayload.user,
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      thunkAPI.dispatch(
        setCaptchaError({
          message: 'ERRORS.LOGIN.INVALID_CAPTCHA',
          error: true,
        })
      );
      thunkAPI.dispatch(getCaptcha());
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const {
  setCaptchaError,
  clearPersonalInformationSinup,
  personalInformationSinup,
} = authenticationSlice.actions;
export const selectAuthentication = (state: RootState) =>
  state.clientAuthentication;
export const clientAuthenticationReducer = authenticationSlice.reducer;

import { useEffect, useState } from 'react';

const OverlayLoader = ({ loader }: { loader?: boolean }) => {
  const [loaderToggle, setLoaderToggle] = useState(false);
  useEffect(() => {
    loader && setLoaderToggle(loader);
    if (loader) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [loader]);
  return (
    <div className={`${!loader ? 'hidden' : 'block'}`}>
      <div
        className={`${
          loaderToggle
            ? '   fixed top-0 left-0 right-0 bottom-0 bg-lightBlack flex items-center justify-center z-30 '
            : ''
        }`}
      >
        <div className="z-50 loader"></div>
      </div>
    </div>
  );
};

export default OverlayLoader;

import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { coachPriceValidationSchema } from '../../coachHelpers';
import {
  listCurrencies,
  sessionInfo,
  setCoachingPriceFormData,
} from '../../coachSlices/sessionSlice';
import { useAppSelector } from '../../hooks';

interface IFaqCard {
  priceFormRef: any;
}

const Component: FC<IFaqCard> = ({ priceFormRef }): JSX.Element => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();
  const { coachingPriceFormValue, currency } = useSelector(sessionInfo);
  const { lang } = useAppSelector((state) => state.language);
  let currencyListEn: { label: ''; value: '' }[] = [];
  let currencyListAr: { label: ''; value: '' }[] = [];
  let currencyValue = [{ label: 'SAR', value: 'SAR' }];
  currency.map((item: any, index: number) => {
    currencyListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    currencyListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  useEffect(() => {
    dispatch(listCurrencies(''));
  }, []);
  const handleFormSubmit = () => {};
  const getPercentageValue = (value: string, percent: number) => {
    const result = (parseFloat(value) / 100) * percent;
    return result;
  };
  const { personalDetails } = useAppSelector((state) => state.profile);
  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-Almarai font-bold text-[34px]">
        {t('COACH_SESSION.PRICING')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_SESSION.PRICING_TEXT')}
      </div>
      <div className="w-full font-satoshi rtl:font-Almarai mb-4 font-bold mt-9 text-xl">
        {t('COACH_SESSION.SESSION_PRICE')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] ">
        {t('COACH_SESSION.SESSION_PRICE_TEXT')}
      </div>
      <Formik
        innerRef={priceFormRef}
        initialValues={coachingPriceFormValue}
        validationSchema={coachPriceValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setCoachingPriceFormData(values));

          return (
            <>
              <div className="w-full flex gap-3 h-fit flex-row ">
                <div className="price-input-container h-[60px]">
                  <SelectDropdown
                    id="currency"
                    isMulti={false}
                    isCreatable={false}
                    isSearchable={false}
                    // options={lang === 'en' ? currencyValue : currencyValue}
                    optional={1}
                    dropDown={true}
                    name="currency"
                    placeholder={t('PREFORMANCE.CURRENCY')}
                    onBlur={handleBlur}
                    isValue={'SAR'}
                    onChange={(opt: any) => {
                      setFieldValue('days', opt.value);
                    }}
                    className="my-3 "
                    defaultValue={{
                      value: lang === 'en' ? 'SAR' : 'ريال سعودي',
                      label: lang === 'en' ? 'SAR' : 'ريال سعودي',
                    }}
                    controlShouldRenderValue={true}
                  />
                </div>
                <div className="  price-input-container pt-[11px]">
                  <Input
                    className=" !h-[40px]"
                    id="FormTitle"
                    type="number"
                    name="price"
                    placeholder={t('COACH_PACKAGE.AMOUNT')}
                    onBlur={handleBlur}
                    error={touched.price && 1}
                    onChange={(e: any) => {
                      setFieldValue('price', e.target.value);
                    }}
                    errormessage={errors.price as string}
                    value={values.price}
                  />
                </div>
              </div>
              {Number(values.price) !== 0 ? (
                <>
                  <div className="w-full text-sm font-bold font-satoshi rtl:font-Almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.KUN_CHARGE')}&nbsp;
                    <span className="text-sm font-bold font-satoshi rtl:font-Almarai text-[#000000]">
                      {personalDetails[0]?.commission}%+
                      {personalDetails[0]?.fixedCommission} {t('SAR')}&nbsp; (
                      {Number(values.price).toFixed(2)}-
                      {getPercentageValue(
                        values.price,
                        personalDetails[0]?.commission
                      ).toFixed(2)}{' '}
                      - {personalDetails[0]?.fixedCommission} ){' '}
                    </span>
                  </div>
                  <div className="w-full pt-3 text-sm font-bold font-satoshi rtl:font-Almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.YOU_WILL_GET')} &nbsp;
                    <span className="text-2xl font-bold font-satoshi rtl:font-Almarai text-primary">
                      {t('SAR')}&nbsp;
                      {(
                        parseFloat(values.price) -
                        getPercentageValue(
                          values.price,
                          personalDetails[0]?.commission
                        ) -
                        personalDetails[0]?.fixedCommission
                      ).toFixed(2)}
                    </span>
                  </div>
                </>
              ) : (
                <div className="font-bold font-satoshi rtl:font-Almarai text-black">
                  {t('VOLUNTARY_SESSION')}
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
const CoachingPrice = styled(Component)``;

export default CoachingPrice;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import CarouselCard from '../../clientComponents/carouselCard';
import { getWishlists, selectClientHome } from '../../clientSlices/home';

const Wishlist = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();
  const { wishListDatas, isLoading } = useSelector(selectClientHome);
  useEffect(() => {
    dispatch(getWishlists(searchParams));
  }, [searchParams]);
  const [t] = useTranslation('common');

  const [search, setSearch] = useState(searchParams.get('keyword'));

  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) {
      setSearchParams({ keyword: search });
      // dispatch(getWishlists(searchParams));
    }
  };
  return (
    <>
      {' '}
      <div className="lg:mx-[100px] mx-5 flex flex-col gap-12">
        <div className="mt-20 font-bold lg:text-48 font-satoshi rtl:font-Almarai text-28">
          {t('LANDING_PAGE_AFTER_LOGIN.MY_WISHLIST')}
        </div>
        <div className="w-full">
          <form>
            <div className=" my-upload-search-box">
              <div className="w-[10%]  h-[44px] flex items-center justify-center">
                <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
                  <img
                    src="/assets/gridView/searchIcon.svg"
                    id={search as string}
                    alt=""
                  />
                </button>
              </div>
              <input
                type="text"
                id="simple-search"
                className="text-gray-900 text-sm h-[42px] search-input  w-[85%] rtl:font-Almarai"
                placeholder={t('MY_UPLOADS.SEARCH_TXT')}
                required
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                value={search as string}
              />
              {search && (
                <img
                  onClick={() => {
                    setSearchParams();
                    setSearch('');
                  }}
                  className="cursor-pointer"
                  src="/assets/subscriptionPage/plusIcon.svg"
                  alt="bell-icon"
                />
              )}
            </div>
          </form>
        </div>
        {isLoading ? (
          <CarouselCard data={[]} category={'wishlist'} isLoading={isLoading} />
        ) : (
          <>
            {wishListDatas?.content?.length ? (
              <>
                <div className="mt-5 ">
                  <CarouselCard
                    data={wishListDatas?.content}
                    category={'wishlist'}
                    isLoading={false}
                  />
                </div>
              </>
            ) : (
              <div className="justify-center flex h-[57vh] items-center flex-col rtl:font-Almarai">
                {t('EMPTY_MESSAGES.WISHLIST_EMPTY')}
                <div>
                  <Link to="/search-result" className="text-primary">
                    {t('CLICK_HERE')}
                  </Link>{' '}
                  {t('EMPTY_MESSAGES.WISHLIST_ADD')}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Wishlist;

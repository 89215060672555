import { AnyCnameRecord } from 'dns';
import { myChatParamType, myMessageParamType } from '../coachHelpers/query';
import { get, post } from './api';

export const fetchMessagesList = async (param: myMessageParamType) => {
  return await get('/rooms/me', param);
};
export const fetchChatContent = async (id: string, params: myChatParamType) => {
  return await get(`/messages/chatRoom/${id}`, params);
};
export const getChatRoomToken = async () => {
  return await post(`/auth/socket`, {});
};
export const postRating = async (data: any) => {
  return await post('/review-rating', data);
};
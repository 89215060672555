import {
    IContactUs,
  } from '../clientInterfaces/authentication';
  import { post } from './api';
  
  export const sendContactUs = async (data: IContactUs) => {
    return await post('/contact-us', data);
  };
  
 
  
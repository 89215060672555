import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RatingInput from '../../clientComponents/ratingInput';
import ModalConstants from '../../clientConstants/modal/modalConstants';
import { getAccessToken } from '../../clientHelpers/localStorage';
import { useWindowSize } from '../../clientHelpers/resizeWindow';
import ChatRoom from '../../clientPages/messages/chatroom';
import { clearPersonalInformationSinup } from '../../clientSlices/authentication';
import { modalState } from '../../clientSlices/modalSlice';
import {
  downloadProfilePicClient,
  listProfileView,
  selectClientProfile,
} from '../../clientSlices/profile';
import { LanguageConstants } from '../../coachConstants/headerConstant';
import { fetchMyUploads } from '../../coachSlices/myUploadSlice';
import { paymentInfo } from '../../coachSlices/paymentSlice';
import {
  coachProfile,
  downloadProfilePic,
} from '../../coachSlices/profileSlices/profileInfoSlice';
import { useAppSelector } from '../../hooks';
import { languageSelect } from '../../slice/header';
import DropdownWithoutLogin from '../dropdownWithoutLogin';
import Modal from '../modals';
import ProfileDropdownCard from '../profileDropdownCard';
import './index.css';

const Navigation = () => {
  const { lang } = useAppSelector((state) => state.language);
  const userType = localStorage.getItem('userType');
  const [t, i18n] = useTranslation('common');
  const { profileData, downloadProfilePictureClient } =
    useAppSelector(selectClientProfile);
  const { personalDetails, downloadProfilePicture } = useAppSelector(
    (state) => state.profile
  );
  const { purchased } = useSelector(paymentInfo);
  const { roomAlertIds } = useAppSelector((state) => state.messages);
  useEffect(() => {
    if (userType === 'COACH') {
      dispatch(
        fetchMyUploads({
          search: '',
          activityStatus: 'LIVE',
          requestStatusFilter: 'APPROVED',
        })
      );
    }
  }, []);
  const navigationsValue = [
    {
      userType: null,
      fields: [
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.HOME'),
          type: 'Link',
          className: 'text-secondary',
          route: '/',
          show: true,
        },
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.ABOUT_US'),
          className: ' text-gray',
          type: 'Link',
          route: '/about-us',
          show: true,
        },
        {
          title: t('ABOUT_US_PAGE.CONTACT'),
          className: 'text-gray',
          type: 'Link',
          route: '/contact-us',
          show: true,
        },
        {
          title: t('ABOUT_US_PAGE.LOGIN'),
          className:
            'btn-small mr-4  text-secondary bg-white hover:bg-primary hover:border-transparent hover:text-white',
          type: 'AuthButton',
          value: 'Login',
          route: '',
          show: true,
        },
        {
          title: t('ABOUT_US_PAGE.SIGN_UP'),
          className: 'bg-primary btn-small text-white  mr-4',
          type: 'AuthButton',
          value: 'Signup',

          route: '',
          show: true,
        },
        {
          title: '',
          type: 'language',
          arImg: '/assets/headerImage/erLanguage-black.svg',
          enImg: '/assets/headerImage/language-black.svg',
          route: '',
          show: true,
        },
      ],
    },
    {
      userType: 'CLIENT',
      fields: [
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.HOME'),
          type: 'Link',
          route: '/client/profile',
          show: true,
        },
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.MY_PURCHASE'),
          type: 'Link',
          route: '/my-purchase',
          show: true,
        },
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.SCHEDULES'),
          type: 'Link',
          route: '/schedule',
          show: true,
        },
        {
          title: '',
          type: 'Cart',
          route: '/cart',
          show: true,
        },
        {
          title: '',
          type: 'Wishlist',
          route: '/wishlist',
          show: true,
        },
        // {
        //   title: '',
        //   type: 'Notification',
        //   route: '',
        //   show: true,
        // },
        {
          title: '',
          type: 'Dropdown',
          path: downloadProfilePictureClient
            ? `data:image/png;base64,${downloadProfilePictureClient}`
            : '/assets/profile/avatar.svg',
          route: '',
          Options: [
            {
              path: downloadProfilePictureClient
                ? `data:image/png;base64,${downloadProfilePictureClient}`
                : '/assets/profile/avatar.svg',
              name: profileData ? profileData.fullName : 'User name',
              email: profileData ? profileData.email : 'user@gmail.com',
              type: 'profile',
              route: '/client/client-profile',
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.HOME'),
              type: 'Link',
              route: '/client/profile',
              show: true,
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.SCHEDULES'),
              type: 'Link',
              route: '/schedule',
              show: true,
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.MY_PURCHASE'),
              type: 'Link',
              show: true,
              route: '/my-purchase',
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.MY_CART'),
              type: 'Link',
              show: true,
              route: '/cart',
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.WISHLIST'),
              type: 'Link',
              show: true,
              route: '/wishlist',
            },
            // {
            //   title: t('PROFILE.PROFILE_INFO.NOTIFICATION'),
            //   type: 'Link',
            //   show: true,
            // },
            {
              title: (
                <span className="flex flex-row items-center">
                  {t('LANDING_PAGE_AFTER_LOGIN.MESSAGE')}{' '}
                  {roomAlertIds?.length ? (
                    <div className="bg-primary w-3 h-3 rounded-full ml-2"></div>
                  ) : null}
                </span>
              ),
              type: 'Link',
              className: ' border-b-[1px] border-lightBlack ',
              show: true,
              route: '/messages',
            },
            // {
            //   title: t('LANDING_PAGE_AFTER_LOGIN.ACCOUNT_SETTINGS'),
            //   type: 'Link',
            //   show: true,
            // },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.PURCHASE_HISTORY'),
              type: 'Link',
              className: ' border-b-[1px] border-lightBlack ',
              show: true,
              route: '/purchase-history',
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.HELP'),
              type: 'help',
              show: true,
              className: ' border-b-[1px] border-lightBlack ',
              route: ' https://chat.whatsapp.com/FRXgV2Jy8kF4yA85MlqfG0',
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.LOGOUT'),
              type: 'Link',
              value: 'logout',
              show: true,
            },
          ],
        },
      ],
    },
    {
      userType: 'COACH',
      fields: [
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.HOME'),
          type: 'Link',
          className:
            'px-6 flex items-center cursor-pointer font-normal font-general rtl:font-Almarai text-gray',
          route: '/coach/home',
          show: purchased,
        },
        {
          title: t('HOME.MY_UPLOADS'),
          type: 'Link',
          className:
            'px-6 flex items-center cursor-pointer font-normal font-general rtl:font-Almarai text-gray',
          route: '/coach/my-uploads?filter=APPROVED',
          show: purchased,
        },
        {
          title: t('HOME.PERFORMANCE'),
          type: 'Link',
          className:
            'px-6 flex items-center cursor-pointer font-normal font-general rtl:font-Almarai text-gray',
          route: '/coach/performance?page=0',
          show: purchased,
        },
        {
          title: t('LANDING_PAGE_AFTER_LOGIN.SCHEDULES'),
          type: 'Link',
          className:
            'px-6 flex items- center cursor-pointer font-normal font-general rtl:font-Almarai text-gray',
          route: '/schedule',
          show: purchased,
        },
        {
          title: t('HOME.CREATE_NEW'),
          className: 'bg-primary btn-small text-white',
          type: 'Button',
          route: '/coach/create-session',
          show: purchased,
        },
        // {
        //   title: '',
        //   type: 'Notification',
        //   path: '/assets/homePageImages/bell-icon.svg',
        //   route: '',
        //   show: purchased,
        // },

        {
          title: '',
          type: 'Dropdown',
          path: downloadProfilePicture?.fileContent
            ? `data:image/png;base64,${downloadProfilePicture?.fileContent}`
            : '/assets/profile/avatar.svg',
          route: '',
          Options: [
            {
              path: downloadProfilePicture?.fileContent
                ? `data:image/png;base64,${downloadProfilePicture?.fileContent}`
                : '/assets/profile/avatar.svg',
              name: personalDetails
                ? personalDetails[0]?.fullName
                : 'User name',
              email: personalDetails
                ? personalDetails[0]?.email
                : 'user@gmail.com',
              type: 'profile',
              route: '/coach/me',
            },
            {
              title: t('HOME.CREATE_NEW'),
              type: 'Button',
              className: ' border-b-[1px] border-lightBlack ',
              route: '/coach/create-session',
              show: purchased,
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.HOME'),
              type: 'Link',
              route: '/coach/home',
              show: purchased,
            },
            {
              title: t('HOME.MY_UPLOADS'),
              type: 'Link',
              route: '/coach/my-uploads?filter=APPROVED',
              show: purchased,
            },
            {
              title: t('HOME.PERFORMANCE'),
              route: '/coach/performance?page=0',
              type: 'Link',
              show: purchased,
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.SCHEDULES'),
              type: 'Link',
              route: '/schedule',
              show: purchased,
            },
            {
              title: t('LANDING_PAGE_AFTER_LOGIN.MESSAGE'),
              type: 'Link',
              className: ' border-b-[1px] border-lightBlack ',
              show: purchased,
              route: '/messages',
            },
            // {
            //   title: t('HOME.NOTIFICATIONS'),
            //   type: 'Link',
            //   className: ' border-b-[1px] border-lightBlack ',
            //   show: purchased,
            // },
            // { title: t('HOME.ACCOUNT_SETINGS'), type: 'Link', show: true },
            {
              title: t('HOME.HELP'),
              type: 'help',
              show: true,
              className: ' border-b-[1px] border-lightBlack ',
              route: 'https://chat.whatsapp.com/FRXgV2Jy8kF4yA85MlqfG0',
            },
            {
              title: t('HOME.LOGOUT'),
              type: 'Link',
              value: 'logout',
              className: ' border-b-[1px] border-lightBlack ',
              show: true,
            },
          ],
        },
      ],
    },
  ];

  const authPaths = [
    { page: 'clientLogin', path: '/login' },
    {
      page: 'coachRegistration',
      path: '/coach/registration/personal-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/educational-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/coaching-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/experience-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/specialization-details',
    },
    { page: 'clientRegistration', path: '/client/registration' },
    { page: 'clientForgotPassword', path: '/forgot-password' },
    { page: 'clientChangePassword', path: '/set-new-password' },
  ];

  const filteredNavigations = navigationsValue.find(
    (item) => item.userType === userType
  );
  const [width] = useWindowSize();
  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();
  const [type, setType] = useState('');
  const [logoutOpen, setLogoutOpen] = useState(false);

  useEffect(() => {
    if (getAccessToken()) {
      if (userType === 'CLIENT') dispatch(listProfileView());
      else if (userType === 'COACH') dispatch(coachProfile());
    }
  }, []);
  useEffect(() => {
    if (profileData?.profilePicId) {
      dispatch(downloadProfilePicClient(profileData?.profilePicId));
    }
  }, [profileData]);
  useEffect(() => {
    if (personalDetails[0]?.profilePicId) {
      dispatch(downloadProfilePic(personalDetails[0]?.profilePicId));
    }
  }, [personalDetails]);
  const showLogoutModal = (type: string) => {
    setType(type);
    setLogoutOpen(true);
    document.body.classList.add('overflow-hidden');
    dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: true }));
  };

  const logOutClose = () => {
    setLogoutOpen(false);
    dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: false }));
    document.body.style.overflow = 'unset';
    document.body.classList.remove('overflow-hidden');
  };
  const navigate = useNavigate();

  const handleNavigation = () => {
    logOutClose();
    if (type === 'Login') {
      navigate('/login?user=CLIENT');
    } else {
      dispatch(clearPersonalInformationSinup());
      navigate('/client/registration');
    }
  };
  const handleCoachNavigation = () => {
    logOutClose();
    if (type === 'Login') {
      navigate('/login?user=COACH');
    } else {
      navigate('/coach/registration/personal-details');
    }
  };

  const filteredPath = authPaths.find((item) => item.path === pathname);

  useEffect(() => {
    document
      .querySelector('html')
      ?.setAttribute('dir', `${LanguageConstants.get(lang)}`);
    i18n.changeLanguage(lang);
  }, [lang]);
  const handleTranslationChange = (value: string) => {
    dispatch(languageSelect(value));
  };
  const loginAuth = localStorage.getItem('token');
  const { isRatingModalKunOpen } = useAppSelector((state) => state.rating);
  return (
    <>
      {!filteredPath ? (
        <>
          <RatingInput rating={'KUN'} show={isRatingModalKunOpen} />

          <NonAuthHeader
            profileData={profileData}
            filteredNavigations={filteredNavigations}
            showLogoutModal={showLogoutModal}
            logoutOpen={logoutOpen}
            type={type}
            setLogoutOpen={setLogoutOpen}
            logOutClose={logOutClose}
            handleCoachNavigation={handleCoachNavigation}
            handleNavigation={handleNavigation}
            t={t}
            lang={lang}
            handleTranslationChange={handleTranslationChange}
          />
          {loginAuth && purchased && <ChatRoom />}
        </>
      ) : (
        <AuthenticationHeader
          width={width}
          pathname={pathname}
          lang={lang}
          handleTranslationChange={handleTranslationChange}
        />
      )}
    </>
  );
};

const NonAuthHeader = ({
  profileData,
  filteredNavigations,
  showLogoutModal,
  logoutOpen,
  type,
  setLogoutOpen,
  logOutClose,
  handleCoachNavigation,
  handleNavigation,
  t,
  lang,
  handleTranslationChange,
}: any) => {
  const [dropdownToggle, setDropdownToggle] = useState<boolean>(false);
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const buttonRef: any = useRef();

  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (
        dropdownToggle &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setDropdownToggle(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, [dropdownToggle]);
  const handleToggle = () => {
    setDropdownToggle(false);
  };
  return (
    <div className="relative z-10 xmd:px-[75px] px-[30px] w-full bg-white block lg:mx-auto  flex-row drop-shadow-[0_1px_4px_rgba(0,0,0,0.1)] h-[100px]">
      <div className="flex items-center justify-between w-full h-[100px]">
        <Link to="/">
          <img
            className=" xmd:flex  "
            src="/assets/headerAfterLogin/headerIcon.svg"
            alt=""
          />
          <h3 className="rtl:font-Almarai rtl:px-2">{t('BETA_LUNCH')}</h3>
        </Link>
        <div className="flex items-center">
          {filteredNavigations?.fields.map((item: any, index: number) => {
            switch (item.type) {
              case 'Link':
                const [first] = item.route.split('?');
                return (
                  item.show && (
                    <Link
                      key={`${item.route}${index}`}
                      to={item.route}
                      className={
                        pathName === first
                          ? 'text-secondary mx-8 xmd:flex hidden rtl:font-Almarai'
                          : 'text-gray mx-8 xmd:flex hidden rtl:font-Almarai'
                      }
                    >
                      {item.title}
                    </Link>
                  )
                );
              case 'Button':
                return (
                  item.show && (
                    <button
                      key={`${item.route}${index}`}
                      onClick={() => {
                        navigate(item.route);
                      }}
                      className={`${item.className}  mx-4 xmd:flex hidden`}
                    >
                      {item.title}
                    </button>
                  )
                );
              case 'AuthButton':
                return (
                  <div key={`${item.route}${index}`}>
                    <button
                      className={`${item.className} mx-4 xmd:flex hidden`}
                      onClick={() => showLogoutModal(item.value)}
                    >
                      {item.title}
                    </button>
                    {logoutOpen ? (
                      <AuthenticationModal
                        type={type}
                        setLogoutOpen={setLogoutOpen}
                        logoutOpen={logoutOpen}
                        logOutClose={logOutClose}
                        handleCoachNavigation={handleCoachNavigation}
                        handleNavigation={handleNavigation}
                        t={t}
                      />
                    ) : null}
                  </div>
                );
              case 'Notification':
                return (
                  item.show && (
                    <div
                      className="xmd:flex hidden"
                      key={`${item.route}${index}`}
                    >
                      <NotificationsNoneOutlinedIcon
                        sx={{ color: '#687E8E' }}
                        className="mx-4 xmd:flex hidden"
                      />
                    </div>
                  )
                );
              case 'Dropdown':
                return (
                  <div ref={buttonRef} key={`${item.route}${index}`}>
                    <div className="xmd:hidden">
                      <button>
                        <img
                          onClick={() => {
                            setDropdownToggle(!dropdownToggle);
                          }}
                          src={
                            dropdownToggle
                              ? '/assets/headerAfterLogin/close.svg'
                              : '/assets/headerAfterLogin/navigation.svg'
                          }
                          alt=""
                        />
                        {dropdownToggle ? (
                          <ProfileDropdownCard
                            toggle={dropdownToggle}
                            togglefunction={handleToggle}
                            Options={item?.Options}
                          />
                        ) : null}
                      </button>
                    </div>

                    <button
                      className="cursor-pointer xmd:flex hidden"
                      // ref={buttonRef}
                      onClick={() => {
                        setDropdownToggle(!dropdownToggle);
                      }}
                    >
                      <img
                        src={item.path}
                        alt="title"
                        // ref={buttonRef}
                        className="max-w-[45px] h-[45px] object-cover rounded-[22px]"
                      />
                    </button>
                    {dropdownToggle ? (
                      <ProfileDropdownCard
                        toggle={dropdownToggle}
                        togglefunction={handleToggle}
                        Options={item?.Options}
                      />
                    ) : null}
                  </div>
                );
              case 'language':
                return (
                  <div key={`${item.route}${index}`}>
                    <img
                      className="cursor-pointer xmd:flex hidden"
                      src={lang === 'ar' ? item.enImg : item.arImg}
                      alt="title"
                      onClick={() =>
                        handleTranslationChange(lang === 'ar' ? 'en' : 'ar')
                      }
                    />
                    <div className="xmd:hidden">
                      <button>
                        {dropdownToggle ? (
                          <img
                            src="/assets/headerAfterLogin/close.svg"
                            alt=""
                            onClick={() => {
                              setDropdownToggle(!dropdownToggle);
                            }}
                            className={
                              dropdownToggle ? 'category2' : 'category2'
                            }
                          />
                        ) : (
                          <img
                            src="/assets/headerAfterLogin/navigation.svg"
                            alt=""
                            onClick={() => {
                              setDropdownToggle(!dropdownToggle);
                            }}
                          />
                        )}

                        {dropdownToggle ? (
                          <DropdownWithoutLogin
                            showLogoutModal={showLogoutModal}
                          />
                        ) : null}
                      </button>
                    </div>
                  </div>
                );

              case 'Cart':
                return (
                  <Link
                    to={item.route}
                    className="xmd:flex hidden"
                    key={`${item.route}${index}`}
                  >
                    <div className="relative">
                      <div
                        className="absolute bg-[#BC455E] text-white top-[-2px] ltr:right-3 rtl:left-3 rounded-[8px] font-general rtl:font-Almarai flex text-center items-center justify-center h-[16px] w-[16px] font-semibold  text-8 
                    "
                      >
                        {profileData?.cartCount}{' '}
                      </div>
                      <ShoppingCartOutlinedIcon
                        sx={{ color: '#687E8E' }}
                        className="mx-4 xmd:flex hidden"
                      />
                    </div>
                  </Link>
                );
              case 'Wishlist':
                return (
                  <Link
                    to={item.route}
                    className="xmd:flex hidden"
                    key={`${item.route}${index}`}
                  >
                    <div className="relative">
                      <div
                        className="absolute bg-[#BC455E] text-white top-[-2px] ltr:right-3 rtl:left-3 rounded-[8px] font-general rtl:font-Almarai flex text-center items-center justify-center h-[16px] w-[16px] font-semibold  text-8 
                    "
                      >
                        {profileData?.wishlistCount}{' '}
                      </div>
                      <FavoriteBorderIcon
                        sx={{ color: '#687E8E' }}
                        className="mx-4 xmd:flex hidden"
                      />
                    </div>
                  </Link>
                );
            }
          })}

          {getAccessToken() ? (
            <img
              className="cursor-pointer xmd:flex hidden ltr:ml-3 rtl:mr-3"
              src={
                lang === 'ar'
                  ? '/assets/headerImage/language-black.svg'
                  : '/assets/headerImage/erLanguage-black.svg'
              }
              alt="title"
              onClick={() =>
                handleTranslationChange(lang === 'ar' ? 'en' : 'ar')
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
const AuthenticationHeader = ({
  width,
  pathname,
  lang,
  handleTranslationChange,
}: any) => {
  const [path, setpath] = useState('');
  useEffect(() => {
    if (
      pathname.includes('/client/registration') ||
      pathname.includes('/coach/registration')
    ) {
      if (width > 976) {
        setpath((value) => (value = '/assets/headerImage/logo-bg-black.svg'));
      } else {
        setpath((value) => (value = '/assets/headerImage/logo.svg'));
      }
    } else {
      setpath((value) => (value = '/assets/headerImage/logo.svg'));
    }
  }, [width, path, pathname]);
  return (
    <nav className={`absolute top-0 left-0 right-0`}>
      <div className="">
        <div
          className={`flex justify-between  h-16 lg:px-24 px-5  items-center`}
        >
          <Link
            to={'/'}
            className="flex z-index-1000 items-center space-x-8 pt-5"
          >
            <img
              src={path}
              alt="logo"
              className="cursor-pointer sm:w-full w-[100px]"
            />
          </Link>
          <div className="enlish-tag cursor-pointer pt-5">
            {lang === 'en' && (
              <img
                src="/assets/headerImage/erLanguage.svg"
                alt="logo"
                onClick={() => handleTranslationChange('ar')}
              />
            )}
            {lang === 'ar' && (
              <img
                src="/assets/headerImage/language.svg"
                alt="ar"
                onClick={() => handleTranslationChange('en')}
              />
            )}
          </div>
          {/* <img
            className="cursor-pointer xmd:flex hidden"
            src={
              lang === 'ar'
                ? '/assets/headerImage/language.svg'
                : '/assets/headerImage/erLanguage.svg'
            }
            alt="title"
            onClick={() => handleTranslationChange(lang === 'ar' ? 'en' : 'ar')}
          /> */}
        </div>
      </div>
    </nav>
  );
};
const AuthenticationModal = ({
  logoutOpen,
  logOutClose,
  handleCoachNavigation,
  handleNavigation,
  t,
}: any) => {
  const [userImage, setUserImage] = useState(-1);
  useEffect(() => {
    if (logoutOpen) document.body.style.overflow = 'hidden';
  }, [logoutOpen]);
  return (
    <Modal show={logoutOpen} logOutClose={logOutClose}>
      <div className="mt-11 mb-11">
        <div className="flex  gap-6  flex-col justify-center md:flex-row">
          <div
            onClick={handleCoachNavigation}
            onMouseEnter={() => setUserImage(0)}
            onMouseLeave={() => setUserImage(-1)}
            className={`card md:w-[275px] h-fit w-[274x] min-h-[282px] ${
              userImage !== 0 ? 'bg-white' : 'bg-footerBg'
            }`}
          >
            <div className="flex justify-center w-full p-4">
              <img
                src={`${
                  userImage !== 0
                    ? '/assets/headerImage/coach-icon.svg'
                    : '/assets/headerImage/coach-white.svg'
                }`}
                alt="img"
              />
            </div>
            <p
              className={`text-lg font-bold font-satoshi rtl:font-Almarai text-center ${
                userImage !== 0 ? 'text-black' : 'text-white'
              }`}
            >
              {t('SIGNUP_LOGIN_MODAL.COACH')}
            </p>
            <p
              className={`font-normal font-general rtl:font-Almarai text-center  p-6 ${
                userImage !== 0 ? 'text-greyText' : 'text-white'
              }`}
            >
              {t('SIGNUP_LOGIN_MODAL.COACH_TEXT')}
            </p>
          </div>
          <div
            className={`card md:w-[275px] h-fit w-[274px] min-h-[282px] ${
              userImage !== 1 ? 'bg-white' : 'bg-footerBg'
            }`}
            onClick={handleNavigation}
            onMouseEnter={() => setUserImage(1)}
            onMouseLeave={() => setUserImage(-1)}
          >
            <div className="flex justify-center w-full p-4">
              <img
                src={`${
                  userImage !== 1
                    ? '/assets/headerImage/client-icon.svg'
                    : '/assets/headerImage/client-white.svg'
                }`}
                alt="img"
              />
            </div>
            <p
              className={`text-lg font-bold font-satoshi rtl:font-Almarai text-center ${
                userImage !== 1 ? 'text-black' : 'text-white'
              }`}
            >
              {t('SIGNUP_LOGIN_MODAL.CLIENT')}
            </p>
            <p
              className={`font-normal font-general rtl:font-Almarai text-center  p-6 ${
                userImage !== 1 ? 'text-greyText' : 'text-white'
              }`}
            >
              {t('SIGNUP_LOGIN_MODAL.CLIENT_TEXT')}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Navigation;

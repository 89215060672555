/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IProfileViewSlice } from '../clientInterfaces/authentication';
import { RootState } from '../store';

import { NavigateFunction } from 'react-router-dom';
import { clearLocalStorage } from '../clientHelpers/localStorage';
import { IUpdatePassword } from '../clientInterfaces/profile';
import { postProfileFile } from '../clientServices/api';
import {
  cities,
  countries,
  deleteClient,
  downloadById,
  languages,
  profileView,
  updateClient,
  updatePassword,
} from '../clientServices/profile';
import { showAlert } from '../slice/alert';

const initialState: IProfileViewSlice = {
  isProfileView: false,
  profileData: {
    fullName: '',
    email: '',
    phoneNumber: '',
    description: '',
    bioDescription: '',
    languages: [],
    dob: '',
    gender: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    timezone: '',
    profilePicId: 0,

  },

  languages: [],
  cities: [],
  countries: [],
  isPasswordUpdate: false,
  downloadProfilePictureClient: '',
  profileFileId: 0,
};
export const clientProfileSlice = createSlice({
  name: 'client-profile',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(listProfileView.pending, (state) => {
        state.isProfileView = true;
      })
      .addCase(listProfileView.fulfilled, (state, action) => {
        state.profileData = action.payload.data[0];
        state.isProfileView = false;
      })
      .addCase(listProfileView.rejected, (state, action: any) => {
        state.isProfileView = false;
      })
      .addCase(listLanguages.pending, (state) => {
        state.isProfileView = true;
      })
      .addCase(listLanguages.fulfilled, (state, action) => {
        state.languages = action.payload.data[0];
        state.isProfileView = false;
      })
      .addCase(listLanguages.rejected, (state, action: any) => {
        state.isProfileView = false;
      })

      .addCase(listCities.pending, (state) => {
        // state.isProfileView = true;
      })
      .addCase(listCities.fulfilled, (state, action) => {
        state.cities = action.payload.data[0];
        // state.isProfileView = false;
      })
      .addCase(listCities.rejected, (state, action: any) => {
        // state.isProfileView = false;
      })

      .addCase(listCountries.pending, (state) => {
        state.isProfileView = true;
      })
      .addCase(listCountries.fulfilled, (state, action) => {
        state.countries = action.payload.data[0];
        state.isProfileView = false;
      })
      .addCase(listCountries.rejected, (state, action: any) => {
        state.isProfileView = false;
      })
      .addCase(updateProfile.pending, (state) => {})
      .addCase(updateProfile.fulfilled, (state, action) => {})
      .addCase(updateProfile.rejected, (state, action: any) => {})
      .addCase(deleteClientAccount.pending, (state) => {})
      .addCase(deleteClientAccount.fulfilled, (state, action) => {})
      .addCase(deleteClientAccount.rejected, (state, action: any) => {})
      .addCase(updateClientPassword.pending, (state) => {
        state.isPasswordUpdate = false;
      })
      .addCase(updateClientPassword.fulfilled, (state, action) => {
        state.isPasswordUpdate = true;
      })
      .addCase(updateClientPassword.rejected, (state, action: any) => {
        state.isPasswordUpdate = false;
      })
      .addCase(downloadProfilePicClient.fulfilled, (state, action) => {
        state.downloadProfilePictureClient =
          action?.payload?.data[0]?.fileContent;
      })
      .addCase(downloadProfilePicClient.rejected, (state) => {})
      .addCase(profileFile.pending, (state) => {})
      .addCase(profileFile.fulfilled, (state, action) => {
        state.profileFileId = action?.payload?.data[0]?.id;
      })
      .addCase(profileFile.rejected, (state) => {});
  },
});

export const listProfileView = createAsyncThunk(
  'auth/profile-view',
  async (id, thunkAPI) => {
    try {
      const response = await profileView();
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const listLanguages = createAsyncThunk(
  'auth/language',
  async (id, thunkAPI) => {
    try {
      const response = await languages();
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const listCountries = createAsyncThunk(
  'auth/countries',
  async (id, thunkAPI) => {
    try {
      const response = await countries();
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const listCities = createAsyncThunk(
  'auth/cities',
  async (country: any, thunkAPI) => {
    try {
      const response = await cities(country);
      return response;
    } catch (err: any) {
      // thunkAPI.dispatch(
      //   showAlert({
      //     type: 'error',
      //     message: err?.message,
      //   })
      // );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'auth/update/client',
  async (userData: any, thunkAPI) => {
    try {
      const response = await updateClient(userData);
      // userData.history('client/login');
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );

      thunkAPI.dispatch(listProfileView());
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteClientAccount = createAsyncThunk(
  'auth/delete/client',
  async (userData: { id: number; history: NavigateFunction }, thunkAPI) => {
    try {
      const response = await deleteClient(userData.id);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      clearLocalStorage();
      userData.history('/');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateClientPassword = createAsyncThunk(
  'auth/update-password/client',
  async (userData: IUpdatePassword, thunkAPI) => {
    try {
      const response = await updatePassword(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const downloadProfilePicClient = createAsyncThunk(
  'download/profile/pic/client',
  async (id: number, thunkAPI) => {
    try {
      const response = await downloadById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const oneMSizeByByte = 1024 * 1024;
export const profileFile = createAsyncThunk(
  'profile/client/file',
  async (uploadFile: any, thunkAPI) => {
    try {
      const type = 'PROFILE_PIC';
      if (!!uploadFile && uploadFile[0].size <= oneMSizeByByte) {
          const res = await postProfileFile(uploadFile, type);
          return res;
      } else {
          thunkAPI.dispatch(
              showAlert({
                  type: 'error',
                  message: 'File size should be less than 1MB',
              })
          )
          return thunkAPI.rejectWithValue('File size should be less than 1MB');
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const selectClientProfile = (state: RootState) => state.clientProfile;
export const clientProfileReducer = clientProfileSlice.reducer;

/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IClientHome } from '../clientInterfaces/authentication';
import { RootState } from '../store';

import {
  addService,
  bookASlot,
  cartYouMightAlsoLike,
  categories,
  closeAssessment,
  coachRelatedTagsList,
  createWish,
  deliverablesCoach,
  getCart,
  getClientById,
  getCoachById,
  getCoachSocialById,
  getCountCoaches,
  getCountServices,
  getSchedules,
  getScheduleTimes,
  getWishlist,
  homeServices,
  recommendedSession,
  relatedTagsList,
  removeCart,
  removeWishlist,
  reviewSession,
  searchService,
  serviceLists,
  sessionView,
} from '../clientServices/home';
import { showAlert } from '../slice/alert';
import { listProfileView } from './profile';

const initialState: IClientHome = {
  categories: [],
  serachResults: [],
  sessionResults: [],
  reviewResults: [],
  serviceAllList: [],
  isServiceAllList: false,
  paginationDetails: [],
  coachByIdData: [],
  isProcessingCoachByIdData: false,
  clientByIdData: [],
  homeServices: [],
  serviceType: '',
  serviceView: true,
  recommenedSessions: [],
  myDeliverables: [],
  countResponses: {},
  scheduleList: [],
  totalResults: 0,
  relatedTags: [],
  cartList: [],
  youMightAlsoLikeList: [],
  wishListDatas: [],
  timeSlots: [],
  scheduleData: [],
  bookSessionToggle: false,
  coachSocialByIdData: [],
  isLoading: false,
  isBookingSlot: false,
  isSearchResults: false,
  isViewSession: false,
  isCleared: false,
};
export const clientHomeSlice = createSlice({
  name: 'clientHome',
  initialState,
  reducers: {
    setServiceType: (state, action) => {
      state.serviceType = action.payload;
    },
    setServiceView: (state, action) => {
      state.serviceView = action.payload;
    },
    setTimeSlotInitials: (state, action) => {
      state.timeSlots = [];
    },

    setCoachById: (state, action) => {
      state.coachByIdData = {};
    },
    setClientById: (state, action) => {
      state.coachByIdData = {};
    },
    setBookSessionToggle: (state, action) => {
      state.bookSessionToggle = action.payload;
    },
    setClearToggle: (state, action) => {
      state.isCleared = !state.isCleared;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(listCategory.fulfilled, (state, action) => {
        state.categories = action.payload.data[0];
      })
      .addCase(searchServiceList.pending, (state) => {
        state.isSearchResults = true;
      })
      .addCase(searchServiceList.fulfilled, (state, action) => {
        state.serachResults = action.payload.data[0];
        state.countResponses = action.payload.data[0]?.countResponses;
        state.totalResults = action.payload.data[0]?.searchResultCount;
        state.relatedTags = action.payload.data[0]?.relatedTags;
        state.isSearchResults = false;
      })
      .addCase(searchServiceList.rejected, (state) => {
        state.isSearchResults = false;
      })
      .addCase(serviceList.pending, (state, action) => {
        state.isServiceAllList = true;
      })
      .addCase(serviceList.fulfilled, (state, action) => {
        state.serviceAllList = action.payload?.data[0];
        state.totalResults = action.payload.data[0]?.totalElements;
        state.isServiceAllList = false;
        // state.relatedTags = action.payload.data[0]?.relatedTags;
      })
      .addCase(serviceList.rejected, (state, action) => {
        state.isServiceAllList = false;
      })
      .addCase(viewSession.pending, (state, action) => {
        state.isViewSession = true;
      })
      .addCase(viewSession.fulfilled, (state, action) => {
        state.sessionResults = action.payload.data[0];
        state.isViewSession = false;
      })
      .addCase(viewSession.rejected, (state, action) => {
        state.isViewSession = false;
      })
      .addCase(reviewSessions.fulfilled, (state, action) => {
        state.reviewResults = action.payload.data[0];
      })
      .addCase(getCoachDatas.fulfilled, (state, action) => {
        state.coachByIdData = action.payload.data[0];
      })
      .addCase(getCoachSocialMedia.fulfilled, (state, action) => {
        state.coachSocialByIdData = action.payload.data;
      })
      .addCase(getClientDatas.pending, (state, action) => {
        state.isProcessingCoachByIdData = true;
      })
      .addCase(getClientDatas.rejected, (state, action) => {
        state.isProcessingCoachByIdData = false;
      })
      .addCase(getClientDatas.fulfilled, (state, action) => {
        state.clientByIdData = action.payload.data[0];
        state.isProcessingCoachByIdData = false;
      })
      .addCase(listHomeServices.fulfilled, (state, action) => {
        state.homeServices = action.payload.data[0];
      })
      .addCase(recommendedSessionList.fulfilled, (state, action) => {
        state.recommenedSessions = action.payload.data[0];
      })
      .addCase(deliverablesCoachServices.fulfilled, (state, action) => {
        state.myDeliverables = action.payload.data[0];
      })
      .addCase(countServices.fulfilled, (state, action) => {
        state.countResponses = action.payload.data[0];
      })
      .addCase(countCount.fulfilled, (state, action) => {
        state.countResponses = action.payload.data[0];
      })
      .addCase(getSchedulesList.fulfilled, (state, action) => {
        state.scheduleList = action.payload.data[0];
      })
      .addCase(getCartList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCartList.fulfilled, (state, action) => {
        state.cartList = action.payload.data[0];
        state.isLoading = false;
      })
      .addCase(getCartList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(youMightAlsoLike.fulfilled, (state, action) => {
        state.youMightAlsoLikeList = action.payload.data[0];
      })
      .addCase(getWishlists.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getWishlists.fulfilled, (state, action) => {
        state.wishListDatas = action.payload.data[0];
        state.isLoading = false;
      })
      .addCase(getWishlists.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(wishlist.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(wishlist.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(wishlist.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteWishlistItem.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteWishlistItem.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteWishlistItem.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getSchedulesSlot.pending, (state, action) => {
        state.timeSlots = [];
        state.isLoading = true;
      })
      .addCase(getSchedulesSlot.fulfilled, (state, action) => {
        state.timeSlots = action.payload.data[0];
        state.isLoading = false;
      })
      .addCase(getSchedulesSlot.rejected, (state, action) => {
        state.timeSlots = [];
        state.isLoading = false;
      })
      .addCase(getRelatedTags.fulfilled, (state, action) => {
        state.relatedTags = action.payload.data[0];
      })
      .addCase(bookingASlot.pending, (state, action) => {
        state.isBookingSlot = true;
      })
      .addCase(bookingASlot.fulfilled, (state, action) => {
        state.scheduleData = action.payload.data[0];
        state.isBookingSlot = false;
      })
      .addCase(bookingASlot.rejected, (state, action) => {
        state.isBookingSlot = false;
      })
      .addCase(coachRelatedTags.fulfilled, (state, action) => {
        state.relatedTags = action.payload.data[0];
      });
  },
});

export const listCategory = createAsyncThunk(
  'clienthome/category',
  async (id, thunkAPI) => {
    try {
      const response = await categories();
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const listHomeServices = createAsyncThunk(
  'clienthome/listHomeDatas',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await homeServices();
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const viewSession = createAsyncThunk(
  'clienthome/viewSession',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await sessionView(queryParam);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const searchServiceList = createAsyncThunk(
  'clienthome/serviceResult',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await searchService(queryParam);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const serviceList = createAsyncThunk(
  'clienthome/searchResult',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await serviceLists(queryParam.service, queryParam.temp);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const wishlist = createAsyncThunk(
  'clienthome/wish',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await createWish(queryParam);
      thunkAPI.dispatch(
        viewSession({
          serviceId: response?.data[0]?.serviceId,
          serviceType: (response?.data[0]?.serviceType + 's').toLowerCase(),
        })
      );
      thunkAPI.dispatch(getCartList());
      thunkAPI.dispatch(listProfileView());
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const addToCartData = createAsyncThunk(
  'clienthome/cartadd',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await addService({
        serviceType: data.serviceType,
        serviceId: data.serviceId,
        scheduleId: data.scheduleId,
      });
      localStorage.removeItem('scheduleId');

      dispatch(listProfileView());
      dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr,
        })
      );
      data.history('/cart');
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);
export const reviewSessions = createAsyncThunk(
  'clienthome/reviewSession',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await reviewSession(queryParam);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const closeAssessmentPermission = createAsyncThunk(
  'clienthome/closeAssessmentPermission',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await closeAssessment(queryParam);
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCoachDatas = createAsyncThunk(
  'clienthome/getCoachById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCoachById(id);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);
export const getCoachSocialMedia = createAsyncThunk(
  'clienthome/getCoachSocialMedia',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCoachSocialById(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);
export const getClientDatas = createAsyncThunk(
  'clienthome/getClientById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await getClientById(id);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);
export const recommendedSessionList = createAsyncThunk(
  'clienthome/recommendSessions',
  async (data: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await recommendedSession(data);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const deliverablesCoachServices = createAsyncThunk(
  'clienthome/deliverableCoach',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await deliverablesCoach(id);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const countServices = createAsyncThunk(
  'clienthome/countServices',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCountServices(param.serviceType, param.data);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);
export const countCount = createAsyncThunk(
  'clienthome/countCoach',
  async (__, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCountCoaches();
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);
export const getSchedulesList = createAsyncThunk(
  'clienthome/schedules',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getSchedules(payload);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const getCartList = createAsyncThunk(
  'clienthome/carts',
  async (__, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCart();
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const youMightAlsoLike = createAsyncThunk(
  'clienthome/youMightAlsoLike',
  async (__, { dispatch, rejectWithValue }) => {
    try {
      const response = await cartYouMightAlsoLike();
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const deleteItem = createAsyncThunk(
  'clienthome/deleteCartItem',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await removeCart(id);
      dispatch(getCartList());
      dispatch(listProfileView());
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const deleteWishlistItem = createAsyncThunk(
  'clienthome/deleteWishlistItem',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await removeWishlist({
        serviceType: param.serviceType,
        serviceId: param.serviceId,
      });
      dispatch(getCartList());
      dispatch(listProfileView());
      if (param.searchParams) dispatch(getWishlists(param.searchParams));
      dispatch(
        viewSession({
          serviceId: response?.data[0]?.serviceId,
          serviceType: (response?.data[0]?.serviceType + 's').toLowerCase(),
        })
      );
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const getWishlists = createAsyncThunk(
  'clienthome/wishlistData',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getWishlist(param);
      // dispatch(getCartList());
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const getSchedulesSlot = createAsyncThunk(
  'clienthome/scheduletime',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getScheduleTimes(data);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const bookingASlot = createAsyncThunk(
  'clienthome/bookingASlot',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await bookASlot(queryParam.payload);
      if (queryParam.serviceType === 'SESSIONS')
        queryParam.addToCart([response.data[0].id]);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr,
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getRelatedTags = createAsyncThunk(
  'clienthome/relatedtags',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await relatedTagsList(queryParam);

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const coachRelatedTags = createAsyncThunk(
  'clienthome/coachRelatedtags',
  async (queryParam: any, thunkAPI) => {
    try {
      const response = await coachRelatedTagsList(queryParam);

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const selectClientHome = (state: RootState) => state.clientHome;
export const {
  setServiceType,
  setServiceView,
  setTimeSlotInitials,
  setClientById,
  setCoachById,
  setBookSessionToggle,
  setClearToggle,
} = clientHomeSlice.actions;
export const clientHomeReducer = clientHomeSlice.reducer;

import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../../components/Image';
import NoData from '../../components/noData';

interface IoverviewCardProps {
  coachOverView: any;
  isLoading: boolean;
}
const OverviewCard: FC<IoverviewCardProps> = ({
  coachOverView,
  isLoading,
}): JSX.Element => {
  const [t] = useTranslation('common');

  const mobileViewTableData = coachOverView?.map((item: any) => [
    {
      title1: t('PREFORMANCE.ITEM'),
      value: (
        <div className="flex flex-row text-[14px] font-bold gap-2 items-center text-[#153243]">
          {' '}
          <Image
            id={item?.data?.imgId}
            propClass={'w-10 h-10 rounded bg-lightBlack'}
            bgClass={
              'w-10 h-10 rounded flex items-center justify-center bg-lightBlack'
            }
            bgImgUrl={'/assets/default-img-icon.svg'}
          />
          {item?.data?.title}
        </div>
      ),
    },
    {
      title1: t('PREFORMANCE.UPLOAD_DATE'),
      value: moment(item?.data?.createdAt).locale('en').format('MMM DD, YYYY'),
    },
    {
      title1: t('PREFORMANCE.OFFERING'),
      value:
        item?.data?.serviceType !== 'SESSION'
          ? '--'
          : `${item?.data?.duration} ${t('MINS')}`,
    },
    {
      title1: t('PREFORMANCE.STUDENTS_ENROLLED'),
      value: item?.data?.clientCount ? item?.data?.clientCount : 0,
    },
    // { title1: t('PREFORMANCE.RATING'), value: item?.data?.rating },
    {
      title1: t('PREFORMANCE.REVENUE'),
      value: `${t('SAR')} ${item?.data?.price}`,
    },
  ]);

  return coachOverView.length ? (
    <div>
      <div className=" flex flex-col gap-4 md:hidden">
        {mobileViewTableData.map((item: any) => (
          <ul className="border rounded border-disableGray shadow-lg ">
            {item.map((itemValue: any) => (
              <li className="flex flex-row  justify-between border-b border-disableGray">
                <div className="p-4 text-11 font-semibold font-general rtl:font-Almarai text-gray">
                  {itemValue.title1}
                </div>
                <div
                  className={`text-12 font-normal font-general rtl:font-Almarai p-4 flex flex-row items-center gap-1 ${
                    ['Revenue', 'item'].includes(itemValue.title1) &&
                    'font-bold'
                  }`}
                >
                  {itemValue.value}
                </div>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex  md:hidden justify-center  h-56 items-center text-light-black font-general rtl:font-Almarai font-normal">
      <NoData />
    </div>
  );
};

export default OverviewCard;

import axios, { AxiosResponse } from 'axios';
import {
  getFormDataHeader,
  getFormFileHeader,
  getHeaderInfo,
  getImageHeaderInfo,
} from '../coachHelpers/apiHeaderCreator';
import { History } from '../coachHelpers/history';
import { getAccessToken } from '../coachHelpers/localStorage';
import { env } from '../config/env';
import {getLang} from "../clientHelpers/localStorage";

const handleResponse = (response: AxiosResponse) => {
  if (response.status === 401) {
    localStorage.clear();
    History.navigate('/');
    return Promise.reject(response.data);
  }
  if (response.data.success) {
    return response.data;
  }
  return Promise.reject(response.data);
};
export const post = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  const token = getAccessToken();
  let requestBody;
  if (token) {
    requestBody = {
      ...header,
    };
  } else {
    requestBody = {};
  }
  try {
    const resp = await axios.post(
      env.development.BASE_URL + url,
      body,
      requestBody
    );
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const patch = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.patch(
      env.development.BASE_URL + url,
      body,
      header
    );
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const get = async (url: string, params: any = {}) => {
  const header = await getHeaderInfo();
  const token = getAccessToken();
  let requestBody;
  if (token) {
    requestBody = {
      ...header,
      params,
    };
  } else {
    const lang = await getLang();
    requestBody = {
      headers: {
        'Language': lang,
      },
      params,
    };
  }
  try {
    const resp = await axios.get(env.development.BASE_URL + url, requestBody);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const deleteApi = async function (url: string) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.delete(env.development.BASE_URL + url, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const put = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.put(env.development.BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getImageApi = async (url: any, params: any = {}) => {
  try {
    const resp: any = await axios.get(env.development.BASE_URL + url, {
      // ...header,
      responseType: 'arraybuffer',
    });

    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getFileApi = async (url: any, params: any = {}) => {
  const header = await getImageHeaderInfo();

  try {
    const resp: any = await axios.get(url, header);

    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const postData = async function (url: string, body: any) {
  const header = await getFormDataHeader();
  try {
    const resp = await axios.post(url, body, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const postDataWithToken = async function (url: string, body: any) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.post(env.development.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const putDataWithToken = async function (url: string, body: any) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.put(env.development.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const postFile = async function (url: string, body: any) {
  const header = await getFormFileHeader();
  try {
    const resp = await axios.post(url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const postImageFileWithToken = async function (url: string, body: any) {
  const header = await getImageHeaderInfo();
  try {
    const resp = await axios.post(env.development.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const postProfileFile = async function (uploadFile: any, type: string) {
  const header = await getFormFileHeader();
  try {
    const formData: any = new FormData();
    formData.append('file-type', type);
    formData.append('file', uploadFile[0]);
    const res = await axios.post(
      `${env.development.BASE_URL}/files`,
      formData,
      header
    );
    return handleResponse(res);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

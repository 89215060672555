import { put } from '../clientServices/api';
import { get, post } from './apiService';

export const selectSubscriptionApi = async (id: string) => {
  return await post('/subscription-selection/' + id, {});
};

export const fetchPayment = async (data: any) => {
  return await post('/order', data);
};
export const fetchSubscription = async (id: string) => {
  return await get('/subscription-selection?statusFilter=ACTIVE&coachId=' + id);
};
export const fetchSubscriptionById = async (id: string) => {
  return await get('/subscription-selection/' + id);
};
export const upgradeSelection = async (id: string) => {
  return await put('/subscription-selection/' + id, {});
};

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { uploadInternationalImage } from '../../clientSlices/payment';

const International = () => {
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const uploadImageFiles = (e: any) => {
    dispatch(
      uploadInternationalImage({
        file: Array.from(e.target.files)[0],
        orderId: Number(searchParams.get('orderId')),
        navigate: navigate,
      })
    );
  };
  const [t] = useTranslation('common');
  const receipt = () => {};
  return (
    <div className="font-satoshi rtl:font-Almarai">
      <div className="min-h-[56vh] flex items-center flex-col justify-center bg-gray-100">
        <h2 className="flex text-2xl p-6 font-bold items-center justify-center mt-2 text-grey font-general rtl:font-Almarai">
          {t('INTERNATIONAL.UPLOAD_HEAD')}
        </h2>
        <div className="p-8 bg-white rounded-lg shadow-md max-w-md w-full">
          <h1 className="text-xl font-semibold mb-4">
            {t('INTERNATIONAL.BANK_DETAILS')}
          </h1>
          <div className="space-y-4">
            <p>
              <span className="font-semibold">
                {t('INTERNATIONAL.ACCOUNT_HOLDER')}: مؤسسة مكعبات ذكية لتقنية المعلومات
              </span>{' '}
              <span id="accountHolder"></span>
            </p>
            <p>
              <span className="font-semibold">
              {t('INTERNATIONAL.IBAN')}: SA9805000068204366361000
              </span>{' '}
              <span id="accountNumber"></span>
            </p>
            <p>
              <span className="font-semibold">{t('INTERNATIONAL.IBAN')}: Alinma</span>
              <span id="balance"></span>
            </p>
            <p>
              <span className="font-semibold">
                {t('INTERNATIONAL.SWIFT_CODE')}: INMASARI
              </span>{' '}
              <span id="accountHolder"></span>
            </p>
          </div>
        </div>
        <div className="flex items-center flex-col justify-center p-6">
          <label
            // onClick={uploadPicHandler('sessionImageUploadDiv')}
            htmlFor="sessionImageUploadDiv"
            className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer font-general rtl:font-Almarai"
          >
            {t('INTERNATIONAL.UPLOAD')}
          </label>
          <input
            className="hidden"
            id="sessionImageUploadDiv"
            accept="image/png, image/jpeg"
            type="file"
            onChange={(e: any) => uploadImageFiles(e)}
          />{' '}
        </div>
      </div>
      {/* <div className=" flex items-center justify-center m-6">
        <button
          className="bg-primary btn-small text-white  mr-4 mx-4 xmd:flex"
          onClick={receipt}
        >
          <input
            className="hidden"
            id="sessionImageUploadDiv"
            accept="image/png, image/jpeg"
            type="file"
            onChange={(e: any) => uploadImageFiles(e)}
          />
          UPLOAD
        </button>
      </div> */}
    </div>
  );
};
export default International;

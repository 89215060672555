import RatingInput from '../clientComponents/ratingInput';
import AboutUsPage from '../clientPages/aboutUsPage';
import Cart from '../clientPages/cart';
import { ClientCoachProfilePage } from '../clientPages/clientCoachProfilePage';
import ClientProfile from '../clientPages/clientProfile';
import ClientSession from '../clientPages/clientSession';
import ContactUsPage from '../clientPages/conatctUsPage';
import ForgotPassword from '../clientPages/forgotPassword';
import LandingPageWithLogin from '../clientPages/landingPageAfterLogin';
import LandingPageWithoutLogin from '../clientPages/landingPageWithoutLogin';
import Login from '../clientPages/login';
import Messages from '../clientPages/messages';
import MyPurchase from '../clientPages/myPurchase';
import Order from '../clientPages/order';
import Payment from '../clientPages/payment';
import PrivacyPolicy from '../clientPages/privacyPolicy';
import PurchaseHistory from '../clientPages/purchaseHistory';
import Schedule from '../clientPages/schedulePage';
import SearchReultGridViewPage from '../clientPages/searchView';
import SetNewPassword from '../clientPages/setNewPassword';
import Signup from '../clientPages/signup';
import TermsAndConditions from '../clientPages/termsAndConditions';
import Wishlist from '../clientPages/wishlist';
import {
  CoachingDetails,
  EducationalDetails,
  ExperienceDetails,
  PersonalDetails,
  SpecializationDetails,
} from '../coachComponents/registration';
import { USER_TYPE } from '../coachConstants/genericConstants';
import { HomePage, Profile, RegistrationPage } from '../coachPages';
import CoachAssessment from '../coachPages/coachAssessment';
import CoachMyUploads from '../coachPages/CoachMyUploads';
import CoachPackage from '../coachPages/coachPackage';
import CoachPayment from '../coachPages/coachPayment';
import CoachPerformance from '../coachPages/coachPerformance/indext';
import AccountSecurity from '../coachPages/coachProfile/accountSecurity';
import BankingInfo from '../coachPages/coachProfile/bankingInfo';
import CloseAccount from '../coachPages/coachProfile/closeAccount';
import HoursServices from '../coachPages/coachProfile/hoursService';
import MySubscription from '../coachPages/coachProfile/mySubscription';
import CoachNotification from '../coachPages/coachProfile/notification';
import SocialMedia from '../coachPages/coachProfile/socialMedia';
import TradeRegistration from '../coachPages/coachProfile/tradeRegistration';
import CoachSession from '../coachPages/coachSession';
import CoachSubscription from '../coachPages/coachSubscription';
import CoachSubscriptionAfterLogin from '../coachPages/coachSubScriptionAfterLogin';
import CreateSession from '../coachPages/createSession';
import International from '../coachPages/international';
import IndexRoute from './indexRoute';
export const RouteDetails: any = [
  {
    path: '*',
    component: IndexRoute,
    isProtected: false,
    user: '',
  },
  {
    path: '*',
    component: IndexRoute,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '*',
    component: IndexRoute,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/',
    component: LandingPageWithoutLogin,
    isProtected: false,
    user: '',
    restricted: true,
  },
  {
    path: '/search-result',
    component: SearchReultGridViewPage,
    isProtected: false,
    user: '',
    restricted: false,
  },
  {
    path: '/cart',
    component: Cart,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/wishlist',
    component: Wishlist,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/payment',
    component: Payment,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/messages',
    component: Messages,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/ratingInput',
    component: RatingInput,
  },
  {
    path: '/wishlist',
    component: Wishlist,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/schedule',
    component: Schedule,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/purchase-history',
    component: PurchaseHistory,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/order-status',
    component: Order,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/my-purchase',
    component: MyPurchase,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/coach-profile-view',
    component: ClientCoachProfilePage,
    isProtected: false,
    user: '',
    restricted: false,
  },
  {
    path: '/client/profile',
    component: LandingPageWithLogin,
    isProtected: true,
    user: USER_TYPE.CLIENT,
    restricted: true,
  },
  {
    path: '/client/client-profile',
    component: ClientProfile,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/contact-us',
    component: ContactUsPage,
    isProtected: false,
    user: '',
  },
  {
    path: '/about-us',
    component: AboutUsPage,
    isProtected: false,
    user: '',
  },
  {
    path: '/client/registration',
    component: Signup,
    isProtected: false,
    user: '',
  },
  {
    path: '/terms-and-condition',
    component: TermsAndConditions,
    isProtected: false,
    user: USER_TYPE.COACH,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    isProtected: false,
    user: USER_TYPE.COACH,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    isProtected: false,
    user: '',
  },
  {
    path: '/set-new-password',
    component: SetNewPassword,
    isProtected: false,
    user: '',
  },
  {
    path: '/service-view',
    component: ClientSession,
    isProtected: false,
    user: '',
    restricted: false,
  },
  {
    path: '/coach-subscription',
    component: CoachSubscription,
    isProtected: false,
    user: '',
  },
  {
    path: '/login',
    component: Login,
    isProtected: false,
    user: '',
    restricted: true,
  },
  {
    path: '/coach/me',
    component: Profile,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/performance',
    component: CoachPerformance,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/contact-us',
    component: ContactUsPage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/about-us',
    component: AboutUsPage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/subscription',
    component: CoachSubscriptionAfterLogin,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/payment',
    component: CoachPayment,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/my-uploads',
    component: CoachMyUploads,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/banking-info',
    component: BankingInfo,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/trade-registration',
    component: TradeRegistration,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/my-subscription',
    component: MySubscription,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/hours-services',
    component: HoursServices,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/account-security',
    component: AccountSecurity,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/notification',
    component: CoachNotification,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/social-media',
    component: SocialMedia,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/close-account',
    component: CloseAccount,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/home',
    component: HomePage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/international',
    component: International,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/performance',
    component: HomePage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/create-session',
    component: CreateSession,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/coach-package',
    component: CoachPackage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/coach-session',
    component: CoachSession,
    isProtected: true,
    user: USER_TYPE.COACH,
  },

  {
    path: '/coach/coach-assessment',
    component: CoachAssessment,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/registration',
    component: RegistrationPage,
    isProtected: false,
    user: '',

    children: [
      {
        path: 'personal-details',
        component: PersonalDetails,
      },
      {
        path: 'educational-details',
        component: EducationalDetails,
      },
      {
        path: 'coaching-details',
        component: CoachingDetails,
      },
      {
        path: 'experience-details',
        component: ExperienceDetails,
      },
      {
        path: 'specialization-details',
        component: SpecializationDetails,
      },
    ],
  },
];
export const paymentRoutes: any = [
  {
    path: '*',
    component: IndexRoute,
    isProtected: false,
    user: '',
  },
  {
    path: '*',
    component: IndexRoute,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '*',
    component: IndexRoute,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/',
    component: LandingPageWithoutLogin,
    isProtected: false,
    user: '',
    restricted: true,
  },
  {
    path: '/coach-subscription',
    component: CoachSubscription,
    isProtected: false,
    user: '',
  },
  {
    path: '/international',
    component: International,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/search-result',
    component: SearchReultGridViewPage,
    isProtected: false,
    user: '',
    restricted: false,
  },
  {
    path: '/cart',
    component: Cart,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/wishlist',
    component: Wishlist,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/payment',
    component: Payment,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  // {
  //   path: '/messages',
  //   component: Messages,
  //   isProtected: true,
  //   user: USER_TYPE.CLIENT,
  // },
  {
    path: '/ratingInput',
    component: RatingInput,
  },
  // {
  //   path: '/schedule',
  //   component: Schedule,
  //   isProtected: true,
  //   user: USER_TYPE.CLIENT,
  // },
  {
    path: '/purchase-history',
    component: PurchaseHistory,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/order-status',
    component: Order,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/my-purchase',
    component: MyPurchase,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/service-view',
    component: ClientSession,
    isProtected: false,
    user: '',
    restricted: false,
  },
  {
    path: '/coach-profile-view',
    component: ClientCoachProfilePage,
    isProtected: false,
    user: '',
    restricted: false,
  },
  {
    path: '/client/profile',
    component: LandingPageWithLogin,
    isProtected: true,
    user: USER_TYPE.CLIENT,
    restricted: true,
  },
  {
    path: '/client/client-profile',
    component: ClientProfile,
    isProtected: true,
    user: USER_TYPE.CLIENT,
  },
  {
    path: '/contact-us',
    component: ContactUsPage,
    isProtected: false,
    user: '',
  },
  {
    path: '/about-us',
    component: AboutUsPage,
    isProtected: false,
    user: '',
  },
  {
    path: '/client/registration',
    component: Signup,
    isProtected: false,
    user: '',
  },
  {
    path: '/terms-and-condition',
    component: TermsAndConditions,
    isProtected: false,
    user: USER_TYPE.COACH,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    isProtected: false,
    user: USER_TYPE.COACH,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    isProtected: false,
    user: '',
  },
  {
    path: '/set-new-password',
    component: SetNewPassword,
    isProtected: false,
    user: '',
  },
  {
    path: '/login',
    component: Login,
    isProtected: false,
    user: '',
    restricted: true,
  },
  {
    path: '/coach/contact-us',
    component: ContactUsPage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/about-us',
    component: AboutUsPage,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/subscription',
    component: CoachSubscriptionAfterLogin,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/payment',
    component: CoachPayment,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/registration',
    component: RegistrationPage,
    isProtected: false,
    user: '',

    children: [
      {
        path: 'personal-details',
        component: PersonalDetails,
      },
      {
        path: 'educational-details',
        component: EducationalDetails,
      },
      {
        path: 'coaching-details',
        component: CoachingDetails,
      },
      {
        path: 'experience-details',
        component: ExperienceDetails,
      },
      {
        path: 'specialization-details',
        component: SpecializationDetails,
      },
    ],
  },
  {
    path: '/coach/banking-info',
    component: BankingInfo,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/trade-registration',
    component: TradeRegistration,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/my-subscription',
    component: MySubscription,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/hours-services',
    component: HoursServices,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/account-security',
    component: AccountSecurity,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/notification',
    component: CoachNotification,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/social-media',
    component: SocialMedia,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/close-account',
    component: CloseAccount,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
  {
    path: '/coach/me',
    component: Profile,
    isProtected: true,
    user: USER_TYPE.COACH,
  },
];

import GroupsIcon from '@mui/icons-material/Groups';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import MessageIcon from '@mui/icons-material/Message';
import TopicIcon from '@mui/icons-material/Topic';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EducationQualification from '../../clientComponents/educationContent';
import Deliverable from '../../clientComponents/myDeliverable';
import RatingInput from '../../clientComponents/ratingInput';
import SelecttTimeSlot from '../../clientComponents/selectTimeSlot/inde';
import { getAccessToken } from '../../clientHelpers/localStorage';
import { numberWithCommas } from '../../clientHelpers/seperateNumberWithComma';
import {
  deliverablesCoachServices,
  getCoachDatas,
  getCoachSocialMedia,
  getSchedulesList,
  selectClientHome,
  setBookSessionToggle,
} from '../../clientSlices/home';
import { setRatingModalCoachOpen } from '../../clientSlices/ratingModalSlice';
import {
  UtcToLocaldayConversion,
  utcToLocal,
} from '../../coachHelpers/utility';
import { Image } from '../../components/Image';
import { useAppSelector } from '../../hooks';

export const ClientCoachProfilePage = () => {
  const [t] = useTranslation('common');
  const { coachByIdData, myDeliverables, isProcessingCoachByIdData } =
    useSelector(selectClientHome);
  const dispatch = useDispatch<any>();

  const { scheduleList, bookSessionToggle, coachSocialByIdData } =
    useSelector(selectClientHome);

  const [searchParams] = useSearchParams();
  const [endOfMonthDate, setEndOfMonthDate] = useState('');
  const [startOfMonthDate, setStartOfMonthDate] = useState(
    moment(new Date()).locale('en').format('YYYY-MM-DD')
  );
  useEffect(() => {
    dispatch(getCoachDatas(parseInt(searchParams.get('id') as string)));
    dispatch(deliverablesCoachServices(Number(searchParams.get('id'))));
  }, []);
  const [matchingAvailableSlot, setMatchingAvailableSlot] = useState<any>({});

  useEffect(() => {
    const temp: any = {};

    temp['coachId'] = searchParams?.get('id');
    temp['startDate'] = startOfMonthDate;
    temp['endDate'] = endOfMonthDate;
    if (bookSessionToggle) dispatch(getSchedulesList(temp));
    bookSessionToggle
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [bookSessionToggle, endOfMonthDate, startOfMonthDate]);

  const [availableSlot, setAvailableSlot] = useState<any>([]);
  useEffect(() => {
    findEndDate(moment(new Date()).locale('en').format('YYYY-MM-DD'));
  }, []);

  const findEndDate = (date: string) => {
    setStartOfMonthDate((value: string) => (value = date));
    const endDate = new Date(
      Number(moment(date).locale('en').format('YYYY')),
      Number(moment(date).locale('en').format('MM')),
      0
    );
    setEndOfMonthDate(
      (value: string) => (value = moment(endDate).locale('en').format('YYYY-MM-DD'))
    );
  };
  useEffect(() => {
    let temp: any = {};

    // let result = scheduleList.map(({ date }: any) => date);
    let result = scheduleList.map(({ startDateTime, date }: any) => {
      temp[moment(`${startDateTime}Z`).locale('en').format('Y-MM-DD')] = date;
      //   try {
      //     utcDatetoLocalDate(startDateTime);
      //   } catch (err) {}
      return moment(`${startDateTime}Z`).locale('en').format('Y-MM-DD');
    });
    let unique = new Set(result);
    let val = Array.from(unique);
    setMatchingAvailableSlot(temp);
    setAvailableSlot((value: any) => (value = val));
  }, [scheduleList]);
  const handleDemo = () => {
    dispatch(setBookSessionToggle(true));
  };
  const [isHovered, setIsHovered] = useState(false);
  const [message, setMessage] = useState('');

  // define a function to handle the hover events
  const handleHover = () => {
    if (!coachByIdData?.hoursAndServicesResponsesList?.responseList?.length) {
      setIsHovered(true);
      setMessage("Coach hasn't added coaching hours.");
    }
  };
  useEffect(() => {
    dispatch(getCoachSocialMedia(Number(searchParams.get('id'))));
    // dispatch(fetchCountOfService());
  }, []);
  const decode: any =
    getAccessToken() && jwt_decode(getAccessToken() as string);
  const handleMouseLeave = () => {
    setIsHovered(false);
    setMessage('');
  };
  const navigate = useNavigate();
  const onClickUrl = (name: string) => {
    const data = coachSocialByIdData[0]?.find(
      (item: any) => name === item.name
    );

    window.location.replace(data?.link);
  };
  const findIsSitePresent = (name: string) => {
    const { link } =
      coachSocialByIdData[0]?.find((item: any) => name === item.name) || {};
    return !!link;
  };
  useEffect(() => {
    if (coachByIdData?.purchased && !coachByIdData?.isReviewed) {
      dispatch(setRatingModalCoachOpen(true));
    } else {
      dispatch(setRatingModalCoachOpen(false));
    }
  }, [coachByIdData]);
  const { isRatingModalCoachOpen } = useAppSelector((state) => state.rating);
  return (
    <>
      {!isProcessingCoachByIdData && (
        <RatingInput
          rating={'COACH'}
          coachByIdData={coachByIdData}
          show={isRatingModalCoachOpen}
        />
      )}
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="md:pl-[205px] md:pr-[205px] pr-5 pl-5 flex flex-col pt-20 pb-10 xl:flex-row gap-3">
        <div className=" h-full">
          {/* <img src="/assets/clientCoachProfilePage/profileImg.svg" alt=""></img> */}

          <Image
            id={
              0
              // (coachByIdData?.imgId as number) ||
              // (coachByIdData?.imageId as number) ||
              // (coachByIdData?.profilePicId as number)
            }
            bgImgUrl={'/assets/default-img-icon.svg'}
            bgClass={
              ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[267px] xl:w-[267px] '
            }
            propClass={'object-fill rounded-t h-[258px] '}
          />
        </div>
        <div className="pl-5">
          <div>
            <div className="text-[#02A4EA] text-lg font-bold leading-[22px] pt-2">
              COACH
            </div>
            <div className="text-[#153243] text-[41px] font-bold leading-[41px]">
              {coachByIdData?.fullName}
            </div>
            <div className="text-[17px] text-[#687E8E] leading-6 pt-2">
              {coachByIdData.headline}
            </div>

            <div className="flex pt-6 gap-3">
              <img src="/assets/clientCoachProfilePage/web.svg" alt=""></img>
              <span className="text-base text-[#153243] font-normal pl-3.5 leading-[21px]">
                {coachByIdData?.languages ? (
                  coachByIdData?.languages?.toString()
                ) : (
                  <span className=" text-gray">--{t('NA')}--</span>
                )}
              </span>
            </div>
            <div className="flex pt-3 gap-3">
              <img
                src="/assets/clientCoachProfilePage/calender.svg"
                alt=""
                className=" self-start"
              ></img>

              <span className="text-base text-[#153243] font-normal pl-3.5 leading-[21px]">
                {coachByIdData?.hoursAndServicesResponsesList?.responseList
                  ?.length
                  ? coachByIdData?.hoursAndServicesResponsesList?.responseList?.map(
                      (item: any, index: number) => (
                        <span>
                          {UtcToLocaldayConversion(item.day, item.startTime)} :{' '}
                          {utcToLocal(item.startTime)} -{' '}
                          {utcToLocal(item.endTime)}
                          {index !==
                          coachByIdData?.hoursAndServicesResponsesList
                            ?.responseList?.length -
                            1 ? (
                            <span> &nbsp;| &nbsp;</span>
                          ) : null}
                        </span>
                      )
                    )
                  : '-NA-'}
                {coachByIdData?.hoursAndServicesResponsesList?.responseList
                  ?.length ? (
                  <span> {localStorage.getItem('timezone')}</span>
                ) : null}
              </span>
            </div>
            <div className="flex pt-3  gap-3">
              <img
                src="/assets/clientCoachProfilePage/location.svg"
                alt=""
              ></img>

              <span className="text-base text-[#153243] font-normal pl-3.5 leading-[21px] capitalize">
                {coachByIdData?.addressLine1}, {coachByIdData?.addressLine2},
                {coachByIdData?.city}, {coachByIdData?.country}
              </span>
            </div>
          </div>
          {/* ))} */}

          <div className="flex flex-wrap justify-between pt-[20px] w-full gap-2.5">
            <div className="flex gap-2  ">
              {getAccessToken() &&
              decode['id'] !== Number(searchParams.get('id')) ? (
                <div>
                  {coachByIdData.purchased ? (
                    <button
                      className={`flex p-1 w-[180px] justify-center items-center rounded ${
                        isHovered
                          ? 'cursor-not-allowed bg-[#cccccc]'
                          : 'cursor-pointer bg-[#02A4EA] '
                      }`}
                      onClick={() =>
                        navigate('/messages?id=' + searchParams.get('id'))
                      }
                    >
                      <MessageIcon sx={{ color: '#ffffff' }} />
                      <span className="text-white p-2">Message</span>
                    </button>
                  ) : (
                    <button
                      className={`flex p-1 w-[180px] justify-center items-center rounded ${
                        isHovered
                          ? 'cursor-not-allowed bg-[#cccccc]'
                          : 'cursor-pointer bg-[#02A4EA] '
                      }`}
                      onClick={handleDemo}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleMouseLeave}
                      disabled={isHovered}
                    >
                      <img
                        src="/assets/clientCoachProfilePage/videoIcon.svg"
                        alt=""
                      ></img>
                      <span className="text-white p-2">
                        {t('CLIENT_COACH_PROFILE_PAGE.SCHEDULE_DEMO')}
                      </span>
                    </button>
                  )}
                </div>
              ) : (
                <div className="w-[180px]"></div>
              )}

              {/* <button className="border-[1px] p-2.5 rounded">
                <img
                  src="/assets/clientCoachProfilePage/share.svg"
                  alt=""
                ></img>
              </button> */}
            </div>
            <div className="flex gap-2 ">
              {findIsSitePresent('WEBSITE') ? (
                <button
                  className="border-[1px]  p-2.5 rounded border-[#687E8E]"
                  onClick={() => onClickUrl('WEBSITE')}
                >
                  <img
                    src="/assets/clientCoachProfilePage/attach.svg"
                    alt=""
                  ></img>
                </button>
              ) : null}
              {findIsSitePresent('TWITTER') ? (
                <button
                  className="border-[1px]  p-2.5 rounded  border-[#687E8E]"
                  onClick={() => onClickUrl('TWITTER')}
                >
                  <img
                    src="/assets/clientCoachProfilePage/twitter.svg"
                    alt=""
                  ></img>
                </button>
              ) : null}
              {findIsSitePresent('FACEBOOK') ? (
                <button
                  className="border-[1px]   p-2.5 rounded  border-[#687E8E]"
                  onClick={() => onClickUrl('FACEBOOK')}
                >
                  <img
                    src="/assets/clientCoachProfilePage/facebook.svg"
                    alt=""
                  ></img>
                </button>
              ) : null}
              {findIsSitePresent('LINKEDIN') ? (
                <button
                  className="border-[1px]   p-2.5 rounded  border-[#687E8E]"
                  onClick={() => onClickUrl('LINKEDIN')}
                >
                  <img
                    src="/assets/clientCoachProfilePage/linkedin.svg"
                    alt=""
                  ></img>
                </button>
              ) : null}
              {findIsSitePresent('YOUTUBE') ? (
                <button
                  className="border-[1px]   p-2.5 rounded border-[#687E8E] "
                  onClick={() => onClickUrl('YOUTUBE')}
                >
                  <img
                    src="/assets/clientCoachProfilePage/youtube.svg"
                    alt=""
                  ></img>
                </button>
              ) : null}
            </div>
          </div>
          {isHovered ? <p className="text-closeRed">{message}</p> : null}
        </div>
      </div>
      {bookSessionToggle && (
        <SelecttTimeSlot
          data={{
            name: coachByIdData?.fullName,
          }}
          // toggleFunction={setBookSessionToggle}
          // toggleValue={bookSessionToggle}
          addToCart={findEndDate}
          findEndDate={findEndDate}
          availableSlot={availableSlot}
          coachId={0}
          multiple={false}
          type={'COACH'}
          // scheduleType="DEMO_SESSION"
          timeSlotMatching={matchingAvailableSlot}
        />
      )}
      <div className="md:pl-[205px] md:pr-[205px] pr-5 pl-5 flex  pb-10 ">
        <div className="flex border-t-[1px] w-full border-[#38383840] justify-between border-b-[1px] flex-col md:flex-row">
          <div className="flex flex-col  p-10 md:border-none border-b-2 border-[#38383840]">
            <div className="flex justify-center h-full">
              {/* <img
                className="w-[45px] "
                src="/assets/clientCoachProfilePage/group.svg"
                alt=""
              ></img> */}
              <HourglassFullIcon
                sx={{ color: '#00a5ec', height: '40px', width: '60px' }}
              />
            </div>
            <div className="text-[34px] text-[#153243] font-bold text-center">
              {numberWithCommas(coachByIdData?.countOfCoachingHours)}
            </div>
            <div className="text-[#687E8E] text-center  font-semibold text-[13px]">
              {t('CLIENT_COACH_PROFILE_PAGE.NUMBER_OF_COACHING_HOURS')}
            </div>
          </div>
          <div className="flex flex-col p-10 md:border-none border-b-[1px] border-[#38383840]">
            <div className="flex justify-center h-full">
              {/* <img
                className="w-[45px] "
                src="/assets/clientCoachProfilePage/document.svg"
                alt=""
              ></img> */}
              <TopicIcon
                sx={{ color: '#00a5ec', height: '40px', width: '60px' }}
              />
            </div>
            <div className="text-[34px] text-[#153243] font-bold text-center">
              {numberWithCommas(coachByIdData?.serviceCount)}
            </div>
            <div className="text-[#687E8E] text-center  font-semibold text-[13px]">
              {t('CLIENT_COACH_PROFILE_PAGE.SERVICES')}
            </div>
          </div>
          <div className="flex flex-col p-10">
            <div className="flex justify-center h-full">
              {/* <img
                className="w-[45px] "
                src="/assets/clientCoachProfilePage/ratings.svg"
                alt=""
              ></img> */}
              <GroupsIcon
                sx={{ color: '#00a5ec', height: '40px', width: '60px' }}
              />
            </div>
            <div className="text-[34px] text-[#153243] font-bold text-center ">
              {numberWithCommas(coachByIdData?.clientCount)}
            </div>
            <div className="text-[#687E8E] text-center font-semibold text-[13px]">
              {t('PREFORMANCE.CLIENTS')}
            </div>
          </div>
        </div>
      </div>
      <div className="md:pl-[205px] md:pr-[205px] pr-5 pl-5  flex flex-col  pb-10 ">
        <div className="border-b-[1px] border-[#38383840] pb-5 pr-5 ">
          <div className="flex text-[#153243] text-[24px] font-bold">
            {t('CLIENT_COACH_PROFILE_PAGE.ABOUT_ME')}
          </div>

          <p className="mt-4 font-normal text-gray text-17 font-general rtl:font-Almarai">
            {coachByIdData.bioDescription}{' '}
            {/* {showMore
              ? coachByIdData.bioDescription
              : coachByIdData.bioDescription.substring(0, 800)} */}
          </p>
          {/* <div
            className="cursor-pointer "
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {!showMore ? (
              <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-Almarai transition duration-1000 ease-in-out">
                {' '}
                {t('CLIENT_COACH_PROFILE_PAGE.SHOW_MORE')}
                <span>
                  {' '}
                  <img
                    src="/assets/clientCoachProfilePage/showMore.svg"
                    alt=""
                  />
                </span>
              </div>
            ) : (
              <div className="flex items-center gap-2 text-primary text-16 font-semibold font-general rtl:font-Almarai">
                {' '}
                {t('CLIENT_COACH_PROFILE_PAGE.SHOW_LESS')}
                <span>
                  {' '}
                  <img
                    src="/assets/clientCoachProfilePage/showLess.svg"
                    alt=""
                  />
                </span>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className="md:pl-[205px] md:pr-[205px] pr-5 pl-5 flex flex-col  pb-10 ">
        <div className="text-[#153243] text-[24px] font-bold leading-[30px] ">
          {t('CLIENT_COACH_PROFILE_PAGE.EDUCATION')}
        </div>

        <EducationQualification data={coachByIdData?.educationResponses} />
      </div>
      <div className="md:pl-[205px] md:pr-[205px] pl-5 pr-5 flex flex-col  pb-10 ">
        <div className="border-t-[1px] border-[#38383840] pt-10">
          <div className="text-[#153243] text-[24px] font-bold leading-[30px]">
            {t('CLIENT_COACH_PROFILE_PAGE.EXPERIENCE')}
          </div>
          <EducationQualification data={coachByIdData?.experienceResponses} />
        </div>
      </div>
      <div className="md:pl-[205px] md:pr-[205px] pr-5 pl-5 flex flex-col  pb-10">
        <div className="border-t-[1px] border-[#38383840] pt-10">
          <span className="text-2xl font-bold text-[#153243] rtl:font-Almarai">
            {t('CLIENT_COACH_PROFILE_PAGE.MY_DELIVERABLE')}
          </span>
          <Deliverable data={myDeliverables?.myDeliveries} />
        </div>
      </div>
    </>
  );
};

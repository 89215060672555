import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { coachPriceValidationSchema } from '../../coachHelpers';
import { myCoachUploadsInterface } from '../../coachInterfaces/myUploadInterface';
import { listInterface } from '../../coachInterfaces/sessionInterface';
import {
  packageInfo,
  setCoachingPricePackageFormData,
} from '../../coachSlices/packageSlice';
import { listCurrencies, sessionInfo } from '../../coachSlices/sessionSlice';
import { Image } from '../../components/Image';
import Tooltip from '../../components/tooltip/input';
import { useAppSelector } from '../../hooks';

interface IFaqCard {
  priceFormRef: any;
}

const Component: FC<IFaqCard> = ({ priceFormRef }): JSX.Element => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();
  const { coachingPricePackageFormValue } = useSelector(packageInfo);
  const { currency } = useSelector(sessionInfo);
  const { assessmentSession } = useSelector(packageInfo);
  const { lang } = useAppSelector((state) => state.language);
  let currencyListEn: { label: string; value: string }[] = [];
  let currencyListAr: { label: string; value: string }[] = [];
  const [priceArray, setPriceArray] = useState(assessmentSession);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [totalRetakePeriod, setTotalRetakePeriod] = useState<number>(0);

  useEffect(() => {
    let totalValue = 0;
    let totalValueDuration = 0;
    let totalValueRetakePeriod = 0;
    assessmentSession.map((item: myCoachUploadsInterface) => {
      totalValue = totalValue + (item?.price as number);
      if (Number(item?.duration))
        totalValueDuration = totalValueDuration + Number(item?.duration);
      if (Number(item?.retakePeriod))
        totalValueRetakePeriod =
          totalValueRetakePeriod + Number(item?.retakePeriod);
    });
    setTotalPrice(totalValue);
    setTotalDuration(totalValueDuration);
    setTotalRetakePeriod(totalValueRetakePeriod);
    dispatch(setCoachingPricePackageFormData({ price: totalValue }));
  }, [assessmentSession]);

  currency.map((item: listInterface) => {
    currencyListEn.push({
      label: item.nameInEnglish as string,
      value: item.nameInEnglish,
    });
    currencyListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  useEffect(() => {
    dispatch(listCurrencies(''));
  }, []);
  const handleFormSubmit = () => {};
  const getPercentageValue = (value: any, percent: number) => {
    const result = (parseFloat(value) / 100) * percent;
    return result;
  };
  const onInputChange = (value: string, index: number, setFieldValue: any) => {
    let dataArray = priceArray.map(
      (item: myCoachUploadsInterface, i: number) => {
        return {
          ...item,
          price: i === index ? value : item.price,
        };
      }
    );
    setPriceArray(dataArray as myCoachUploadsInterface[]);
    let totalValue = 0;

    dataArray.map((item: any) => {
      totalValue = totalValue + (parseInt(item?.price) as number);
    });

    setTotalPrice(totalValue);
    setFieldValue('price', totalValue);
    onTotalPriceChange(totalValue, setFieldValue);
  };
  const onTotalPriceChange = (total: number, setFieldValue: any) => {
    setTotalPrice(total);
    const value = total / assessmentSession.length;

    let dataArray = assessmentSession.map((item: myCoachUploadsInterface) => {
      return {
        ...item,
        price: value,
      };
    });
    setPriceArray(dataArray);
    setFieldValue('price', total);
    dispatch(setCoachingPricePackageFormData({ price: total }));
  };
  const { personalDetails } = useAppSelector((state) => state.profile);

  let currencyValue = [{ label: 'SR', vslue: 'SR' }];

  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-Almarai font-bold text-[34px]">
        {t('COACH_SESSION.PRICING')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_PACKAGE.PACKAGE_PRICE_TXT')}
      </div>
      <Formik
        innerRef={priceFormRef}
        initialValues={{ price: totalPrice }}
        validationSchema={coachPriceValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          // dispatch(setCoachingPricePackageFormData(values));
          return (
            <>
              {priceArray?.length ? (
                <>
                  <div className="w-full font-satoshi rtl:font-Almarai mb-4 font-bold mt-9 text-xl">
                    {t('COACH_PACKAGE.PACKAGE_PRICE')}
                  </div>
                  <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] ">
                    {t('COACH_PACKAGE.IF_ANY_TXT')}
                  </div>

                  <div className="w-full border-b-2 flex">
                    <div className="w-[30%]  p-5">
                      {' '}
                      {t('COACH_PACKAGE.ITEM')}
                    </div>
                    <div className="w-[30%]  p-5">
                      {' '}
                      {t('COACH_PACKAGE.DURATION')} | Time Gap
                    </div>
                    <div className="w-[40%]  p-5">
                      {t('COACH_PACKAGE.PRICE')} {`(${t('SAR')})`}
                    </div>
                  </div>
                  {priceArray.map((item, index) => (
                    <div className="w-full border-b-[1px] flex">
                      <div className="w-[30%]  p-5 flex items-center">
                        {' '}
                        <Image
                          id={
                            (item?.imageId as number) || (item?.imgId as number)
                          }
                          bgImgUrl={'/assets/default-img-icon.svg'}
                          bgClass={
                            ' bg-[#F6F8F5] h-[30px] w-[30px] rounded-lg ltr:mr-[5px] rtl:ml-[5px] flex justify-center items-center'
                          }
                          propClass={
                            ' h-[30px] w-[30px] rounded-lg ltr:mr-[5px] rtl:ml-[5px]'
                          }
                        />
                        <Tooltip
                          content={
                            item?.title ? item?.title : '------------------'
                          }
                          children={
                            <div className="w-[100%] truncate text-black">
                              {item?.title ? item?.title : '------------------'}
                            </div>
                          }
                        ></Tooltip>
                      </div>
                      <div className="w-[30%]  p-5 flex items-center text-[#687E8E]">
                        {item?.duration
                          ? `${item?.duration} ${t('MINS')} | ${
                              item?.retakePeriod
                            } ${t('COACH_PACKAGE.DAYS')}`
                          : '--'}
                      </div>
                      <div className="w-[30%]  p-5 flex items-center font-bold">
                        &nbsp;
                        <input
                          type="number"
                          className="w-[90px]  !border-[#ccc] !border"
                          value={
                            totalPrice === 0
                              ? 0
                              : Number(item?.price)?.toFixed(2)
                          }
                          onChange={(e) => {
                            onInputChange(e.target.value, index, setFieldValue);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="w-full border-b-[1px] flex justify-between">
                    <div className="w-[30%] flex justify-between  p-5">
                      {t('COACH_PACKAGE.TOTAL')}
                    </div>
                    <span className="w-[30%]  p-5 flex items-center text-[#687E8E]">
                      {totalDuration} {t('MINS')} | {totalRetakePeriod}{' '}
                      {t('COACH_PACKAGE.DAYS')}
                    </span>
                    <div className="w-[40%] px-5  items-center  text-2xl font-bold font-satoshi rtl:font-Almarai text-primary flex justify-start">
                      <input
                        onChange={(e) => {
                          onTotalPriceChange(
                            parseInt(e.target.value),
                            setFieldValue
                          );
                          // setFieldValue('price', e.target.value);
                        }}
                        type="number"
                        className="w-[100px]  text-2xl font-bold font-satoshi rtl:font-Almarai text-primary !border-[#ccc] !border"
                        value={totalPrice?.toFixed(2)}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {/* <div className="w-full font-satoshi rtl:font-Almarai mb-4 font-bold mt-3 text-xl">
                {t('COACH_PACKAGE.NEW_PRICE')}
                <span className="mx-1 font-general rtl:font-Almarai font-thin tex-base text-[#687E8E]">
                  ({t('COACH_PACKAGE.IF_ANY')})
                </span>
              </div>
              <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] ">
                {t('COACH_PACKAGE.NEW_PRICE_TXT')}
              </div>

              <div className="w-full flex gap-3 flex-row">
                <div className="price-input-container">
                  <SelectDropdown
                    id="currency"
                    isMulti={false}
                    isCreatable={false}
                    isSearchable={false}
                    options={lang === 'en' ? currencyValue : currencyValue}
                    optional={1}
                    dropDown={true}
                    name="currency"
                    placeholder={'currency'}
                    onBlur={handleBlur}
                    isValue={'SAR'}
                    onChange={(opt: any) => {
                      setFieldValue('days', opt.value);
                    }}
                    className="my-3 "
                    defaultValue={{
                      value: lang === 'en' ? 'SAR' : 'ريال سعودي',
                      label: lang === 'en' ? 'SAR' : 'ريال سعودي',
                    }}
                    controlShouldRenderValue={true}
                  />
                </div>
                <div className="  price-input-container pt-[11px]">
                  <Input
                    className="!h-[40px]"
                    id="FormTitle"
                    type="number"
                    name="price"
                    placeholder={t('COACH_PACKAGE.AMOUNT')}
                    onBlur={handleBlur}
                    error={touched.price && 1}
                    onChange={(e: any) => {
                      setFieldValue('price', e.target.value);
                    }}
                    value={values.price}
                    errormessage={errors.price}
                  />
                </div>
              </div> */}
              {Number(totalPrice) !== 0 ? (
                <>
                  <div className="w-full text-sm font-bold font-satoshi rtl:font-Almarai text-[#687E8E] mt-4">
                    {t('COACH_PACKAGE.KUN_CHARGE')} &nbsp;
                    <span className="text-sm font-bold font-satoshi rtl:font-Almarai text-[#000000]">
                      {personalDetails[0]?.commission}%+
                      {personalDetails[0]?.fixedCommission} {t('SAR')} (
                      {Number(totalPrice)?.toFixed(2)}-
                      {getPercentageValue(
                        totalPrice,
                        personalDetails[0]?.commission
                      )?.toFixed(2)}{' '}
                      - {personalDetails[0]?.fixedCommission} ){' '}
                    </span>
                  </div>
                  <div className="w-full pt-3 text-sm font-bold font-satoshi rtl:font-Almarai text-[#687E8E]">
                    {t('COACH_PACKAGE.YOU_WILL_GET')}&nbsp;
                    <span className="text-2xl font-bold font-satoshi rtl:font-Almarai text-primary">
                      {t('SAR')}&nbsp;
                      {(
                        totalPrice -
                        getPercentageValue(
                          totalPrice,
                          personalDetails[0]?.commission
                        ) -
                        personalDetails[0]?.fixedCommission
                      )?.toFixed(2)}
                    </span>
                  </div>
                </>
              ) : (
                <div className="font-bold font-satoshi rtl:font-Almarai text-black pt-2">
                  {t('VOLUNTARY_PACKAGE')}
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
const CoachingPackagePrice = styled(Component)``;

export default CoachingPackagePrice;

import { useTranslation } from 'react-i18next';
import SelectDropdown from '../selectDropdown';

import { useAppSelector } from '../../hooks';
import './index.css';

const SortDropdown = ({
  onInputChange,
  controlShouldRenderValue,
  options,
  onBlur,
  onChange,
  inputValue,
  value,
  errormessage,
  optional,
  error,
  placeholder,
  isMulti,
  name,
  handleMostPopular,
  id,
  ...rest
}: any) => {
  const attributes = {
    onInputChange,
    controlShouldRenderValue,
    options,
    onChange,
    onBlur,
    inputValue,
    optional,
    value,
    errormessage,
    error,
    name,
    id,
  };
  const customStyles = {
    control: (base: any) => ({
      ...base,
      borderRadius: '4px',
      width: '100%',
      padding: '3px',
      border: 0,
      textIndent: '0px',
      paddingLeft: '0px',
      placeholder: 'red',
      height: '25px',
      paddingTop: '0px',
      paddingBottom: '0px',
      // outline:
      //   errormessage !== undefined && error
      //     ? '1px solid #e00000'
      //     : '1px solid #687e8e',
    }),
    option: (styles: any) => ({
      ...styles,
      zIndex: 1,
      color: `#687e8e`,
      margin: 0,
      cursor: 'pointer',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#687e8e',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#687e8e',
    }),
  };
  const [t] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);

  return (
    <div className="relative category w-full mb-8 flex flex-row items-center border border-disableGray shadow-lg px-2 rounded h-[64px] sm:h-[56px]">
      <div className="flex w-full items-center ">
        <img src="/assets/gridView/sort.svg" className="mx-2" alt="" />
        <div className="w-full flex sm:flex-row flex-col sm:items-center">
          <div className="flex flex-grow w-[80px] text-disableGray pl-3 sm:pl-0">
            <div className="sm:relative absolute z-[1] top-0 l-0 text-disableGray w-inherit rtl:font-Almarai">
              {t('SEARCH_RESULT_VIEW.SORT_BY')}
            </div>
          </div>
          {/* <CreatableSelect
            {...attributes}
            styles={customStyles}
            isSearchable={false}
            // defaultInputValue='Most relavent'
            placeholder={'Most popular'}
            isMulti={isMulti}
            value={}
          /> */}
          {lang === 'en' && <SelectDropdown
              className={`font-general rtl:font-Almarai !h-[10px] text-var(--secondary) !border-0 mt-[10px] sm:mt-0 !w-[100%]`}
              id="FormLanguage"
              name="languages"
              optional={1}
              defaultValue={{
                label: 'Most Relevant',
                value: 'MOST_RELEVANT',
                label2: 'الأكثر صلة',
              }}
              onChange={(event: { label: string; value: string }) =>
                  handleMostPopular(event)
              }
              isSearchable={false}
              options={options}
              grayOutline={true}
              getOptionLabel={(option: any) =>
                  option.label
              }
          />}
          {lang === 'ar' && <SelectDropdown
              className={`font-general rtl:font-Almarai !h-[10px] text-var(--secondary) !border-0 mt-[10px] sm:mt-0 !w-[100%]`}
              id="FormLanguage"
              name="languages"
              optional={1}
              defaultValue={{
                label: 'Most Relevant',
                value: 'MOST_RELEVANT',
                label2: 'الأكثر صلة',
              }}
              onChange={(event: { label: string; value: string }) =>
                  handleMostPopular(event)
              }
              isSearchable={false}
              options={options}
              grayOutline={true}
              getOptionLabel={(option: any) => option.label2
              }
          />}
          {error && (
            <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 pl-4">
              {t(errormessage)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SortDropdown;

import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import { AccountListSideBar } from '../../coachComponents';
import { setNewPasswordValidationSchema } from '../../coachHelpers';
import { updateAccountSecurity } from '../../coachSlices/forgotPasswordSlice';

const Component = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();

  const handleSubmit = (password: any) => {
    const data = {
      newPassword: password.password,
      confirmPassword: password.reEnterPassword,
    };
    dispatch(updateAccountSecurity(data));
    (document.getElementById('myForm') as HTMLFormElement).reset();
  };
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  const togglePassword = () => {
    if (passwordType === 'password') setPasswordType('text');
    else setPasswordType('password');
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === 'password') setConfirmPasswordType('text');
    else setConfirmPasswordType('password');
  };
  return (
    <div className=" mt-6 lg:mt-12 w-full h-fit">
      <div className="bg-white  w-full mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar handleSubmit={handleSubmit} />
        <div className="h-auto w-full lg:p-8 p-4 col-span-5 lg:col-span-4  lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.ACCOUNT_SECURITY.ACCOUNT_SEC')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.ACCOUNT_SECURITY.ACCOUNT_DEC')}
          </p>
          <div className="border border-info mb-8"></div>
          <Formik
            initialValues={{
              reEnterPassword: '',
              password: '',
            }}
            validationSchema={setNewPasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              submitForm,
            }) => (
              <Form className="lg:w-1/2" id="myForm">
                <Input
                  id="FormPassword"
                  type={passwordType}
                  name="password"
                  placeholder={t('PROFILE.ACCOUNT_SECURITY.ENTER_PASSWORD')}
                  onBlur={handleBlur}
                  error={touched.password && 1}
                  onChange={handleChange}
                  value={values.password}
                  errormessage={errors.password}
                  togglePassword={togglePassword}
                  showEyeStrike={passwordType === 'password'}
                  showEye={passwordType === 'text'}
                />
                <Input
                  id="FormReEnterPassword"
                  type={confirmPasswordType}
                  name="reEnterPassword"
                  placeholder={t('PROFILE.ACCOUNT_SECURITY.RE_ENTER')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.reEnterPassword && 1}
                  value={values.reEnterPassword}
                  errormessage={errors.reEnterPassword}
                  togglePassword={toggleConfirmPassword}
                  showEyeStrike={confirmPasswordType === 'password'}
                  showEye={confirmPasswordType === 'text'}
                />
                <div className="mt-6">
                  <button
                    type="submit"
                    onClick={submitForm}
                    className=" w-full  lg:w-auto text-18 font-bold  py-4 px-6 text-white font-satoshi rtl:font-Almarai bg-primary rounded"
                  >
                    {t('PROFILE.ACCOUNT_SECURITY.CHANGE_PASSWORD')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const AccountSecurity = styled(Component)``;

export default AccountSecurity;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { downloadProfilePicClient } from '../clientSlices/profile';

export const FileDownload = ({
  id,
  purchased,
}: {
  id: number;
  purchased?: boolean;
}) => {
  const dispatch = useDispatch<any>();
  const [fileValue, setFileValue] = useState<{ [key: string]: string }>();
  const [fileLoader, setFileLoader] = useState<boolean>();
  let temp: any;
  useEffect(() => {
    const fileData = async () => {
      try {
        setFileLoader(true);
        temp = await dispatch(downloadProfilePicClient(id));
        setFileValue(temp?.payload?.data[0]);
        setFileLoader(false);
      } catch (err) {
        setFileLoader(false);
      }
    };
    fileData();
  }, []);
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `data:;base64,${fileValue?.fileContent}`;
    link.setAttribute('download', `${fileValue?.originalFileName}`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div
      className=" cursor-pointer"
      onClick={purchased ? handleDownload : undefined}
    >
      {fileValue?.originalFileName}
    </div>
  );
};

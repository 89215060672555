import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../../coachComponents/buttons';
import { LandingConstant } from '../../coachConstants';
import {
  IClientDetails,
  IServiceDec,
} from '../../coachInterfaces/landingInterface';
import './index.scss';

const Component = () => {
  const [t] = useTranslation('common');
  const clientDetails = [
    {
      title: LandingConstant.ACTIVE_COACHES,
      number: LandingConstant.COACH_NUMBER,
    },
    {
      title: LandingConstant.SERVICES_COACHES,
      number: LandingConstant.SERVICE_NUMBER,
    },
    {
      title: LandingConstant.ASSESSMENTS_CONDUCTED,
      number: LandingConstant.ASSESSMENTS_NUMBER,
    },
  ];
  const questionAns = [
    {
      title: 'Et, odio elementum',
      description:
        'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
    },
    {
      title: 'Et, odio elementum',
      description:
        'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
    },
    {
      title: 'Et, odio elementum',
      description:
        'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
    },
    {
      title: 'Et, odio elementum',
      description:
        'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
    },
    {
      title: 'Et, odio elementum',
      description:
        'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
    },
    {
      title: 'Et, odio elementum',
      description:
        'Aliquam vehicula pellentesque id mi quam ipsum. Arcu nisl faucibus mattis etiam.',
    },
  ];

  return (
    <div className="bg-white">
      <Helmet>
        <title>{t('KUN_COACH')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <section className="bg-white px-auto">
        <div className=" mt-10 rounded-md  grid px-4 py-8 mx-36 lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto p-7 place-self-center lg:col-span-6">
            <h1 className="max-w-2xl mb-4 text-4xl font-bold leading-none text-secondary font-satoshi rtl:font-Almarai not-italic md:text-5xl xl:text-5xl">
              {LandingConstant.HEADING}
            </h1>
            <p className="max-w-2xl mb-6 text-light-black font-normal font-general rtl:font-Almarai lg:mb-8 not-italic md:text-sm lg:text-base">
              {LandingConstant.PARAGRAPH}
            </p>
            <Button className="inline-flex items-center font-satoshi rtl:font-Almarai  justify-center bg-primary px-11 py-4 text-lg font-bold text-white rounded ">
              {LandingConstant.GET_STARTED}
            </Button>
          </div>
          <div
            className="p-7 place-self-center lg:col-span-6 bg-no-repeat"
            style={{
              backgroundImage: `url(${'assets/landingPageImages/group.svg'})`,
            }}
          >
            <img src="/assets/landingPageImages/image.svg" alt="mockup" />
          </div>
        </div>
      </section>

      <section className="">
        <div className="py-8 px-8 mx-32 sm:py-16 lg:px-4">
          <div className="mb-8 lg:mb-16">
            <h2 className="landing-heading">
              {LandingConstant.WE_WILL_PROVIDE}
            </h2>
            <p className="landing-paragraph">{LandingConstant.PARAGRAPH}</p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            {questionAns.map((serviceDetails: IServiceDec, index) => {
              return (
                <div
                  key={index}
                  className="rounded-md px-4 py-3 shadow-[0_10px_25px_rgba(0,0,51,0.08)]"
                >
                  <div className="  flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 ">
                    <img
                      src="/assets/landingPageImages/avatar.svg"
                      alt="logo"
                    />
                  </div>
                  <h3 className="mb-2 text-22 font-bold text-secondary">
                    {serviceDetails.title}
                  </h3>
                  <p className="text-light-black text-16 font-normal">
                    {serviceDetails.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="bg-secondary">
        <div className="py-8 px-4 mx-32 sm:py-16 lg:px-4">
          <div className="mb-8 lg:mb-16">
            <h2 className=" font-satoshi rtl:font-Almarai font-bold text-34 mb-4 text-center text-white">
              {LandingConstant.SOME_COUNT}
            </h2>
            <p className="font-general rtl:font-Almarai font-normal text-17.5 text-white text-center">
              {LandingConstant.PARAGRAPH_ALL_IN_oNE}
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">
            <div className="px-4 py-3 text-center">
              <h3 className="mb-2 text-5xl font-bold text-yellow">2652</h3>
              <p className="text-base font-normal font-general rtl:font-Almarai text-white">
                {LandingConstant.NUMBER_OF_CLIENT}
              </p>
            </div>
            {clientDetails.map((clientInfo: IClientDetails, index) => {
              return (
                <div
                  key={index}
                  className="px-4 py-3 text-center lg:border-l-2 border-opacity-40 border-light-black"
                >
                  <h3 className="mb-2 text-5xl font-bold text-yellow">
                    {clientInfo.number}
                  </h3>
                  <p className="text-base font-normal font-general rtl:font-Almarai text-white">
                    {clientInfo.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section>
        <div className="items-center py-8 px-4 mx-32 lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <img
            className=""
            src="/assets/landingPageImages/helpSection.svg"
            alt="office"
          />
          <div className="font-light sm:text-lg mr-10 sm:mt-3">
            <h2 className="landing-heading">
              {LandingConstant.HOW_WE_HELP_YOU}
            </h2>
            <p className="landing-paragraph text-landing-paragraph-new">
              {LandingConstant.P20}
            </p>
          </div>
        </div>
      </section>

      <section className="">
        <div className="items-center py-8 px-4 sm:mx-32 lg:mx-52 lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light mt-28 text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="landing-heading">
              {LandingConstant.WHAT_OUR_CLIENTS_SAYS}
            </h2>
            <p className="landing-paragraph">{LandingConstant.P20}</p>
            <p className="text-sm font-bold font-satoshi rtl:font-Almarai mt-6">
              {LandingConstant.COURTNEY_HENRY}
            </p>
            <div className="mt-28">
              <Button id="sButton1" className=" text-white-new mr-1 ">
                <img src="/assets/landingPageImages/previous.svg" alt="logo" />
              </Button>
              <Button id="sButton2">
                <img src="/assets/landingPageImages/next.svg" alt="logo" />
              </Button>
            </div>
          </div>
          <div>
            <img
              className=""
              src="/assets/landingPageImages/clientSection.svg"
              alt="office"
            />
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};
const LandingPage = styled(Component)``;

export default LandingPage;

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getAccessToken } from '../../clientHelpers/localStorage';
import {
  closeAssessmentPermission,
  selectClientHome,
  setBookSessionToggle,
} from '../../clientSlices/home';
import {
  gitCardPromotions,
  invoicePdf,
  paymentOrder,
  selectPayment,
} from '../../clientSlices/payment';
import { USER_TYPE } from '../../coachConstants/genericConstants';
import { decodeUserId } from '../../coachHelpers/localStorage';
import { Image } from '../../components/Image';
import OverlayLoader from '../../components/overlayLoader';
import { CONSTANTS } from '../../constant';
import { useAppSelector } from '../../hooks';
import Button from '../buttons';
import Input from '../inputs';
interface ICardData {
  cardData: any;
  category: string;
  wishlistUpdate?: any;
  removeItem?: any;
  total?: number;
  setBookSessionToggle?: any;
  bookSessionToggle?: any;
  purchased?: boolean;
  isLoading?: boolean;
  cartTotal?: number;
  vatAmount?: number;
}
const CartCard = ({
  cardData,
  category,
  wishlistUpdate,
  removeItem,
  total,
  purchased,
  isLoading,
  cartTotal,
  vatAmount,
}: ICardData) => {
  const [termsAndConditionsToggle, setTermsAndConditionsToggle] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation('common');
  const { bookSessionToggle } = useSelector(selectClientHome);
  const { isProcessingpayment } = useSelector(selectPayment);
  const { lang } = useAppSelector((state) => state.language);
  moment.locale(lang === 'en' ? 'en' : 'ar');
  const { appliedPromotions } = useSelector(selectPayment);

  const createOrder = () => {
    const cartId: any = [];
    cardData.map((data: any) => {
      cartId.push(data.id);
    });
    const payload: any = {};
    payload['orderType'] = 'COACH_SERVICES';
    // payload['finalAmount'] = total;
    payload['cartList'] = cartId;
    if (gift && appliedPromotions?.remaining_value !== '0.0')
      payload['meritCardNumber'] = gift;
    else delete payload['meritCardNumber'];
    dispatch(
      paymentOrder({
        payload: payload,
        navigate: navigate,
        amount: total,
      })
    );
    // navigate(`/payment?amount=${cardData?.total}`);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSchedule = (id: any) => {
    const temp: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      temp[key] = value;
    });
    temp['serviceId'] = id;
    setSearchParams((value: any) => (value = temp));
    dispatch(setBookSessionToggle(!bookSessionToggle));
  };
  const download = async (clientId: any) => {
    await dispatch(invoicePdf(clientId));
  };
  const serviceView = (item: any) => {
    if (item.data.isDeleted) return;
    navigate(
      category === 'package_assessment'
        ? `/service-view?id=${item?.data?.id}&serviceType=${item?.data?.serviceType}S`
        : `/service-view?id=${
            item.serviceId ? item.serviceId : item.data.id
          }&serviceType=${item?.serviceType}S`
    );
  };
  const userType = localStorage.getItem('userType');
  const userId = getAccessToken() && decodeUserId(getAccessToken() as string);
  const [gift, setGift] = useState('');
  const onApply = () => {
    const payload = {
      cardNumber: gift,
      redemption_amount: total,
    };
    if (gift) dispatch(gitCardPromotions(gift));
  };

  const closeAssessment = async (id: any) => {
    await dispatch(closeAssessmentPermission(id));
    await window.location.reload();
  };
  return (
    <div className="">
      <OverlayLoader loader={isProcessingpayment} />
      {!isLoading && (
        <div className="text-greyText font-normal font-gernal text-17 rtl:font-Almarai">
          {category === 'cart' &&
            `${cardData?.length} ${t(`MY_CART.ITEMS_IN_CART`)}`}
          {category === 'purchaseHistory' && cardData?.length
            ? `${cardData?.length} ${t(`PURCHASE_HISTORY.ITEMS_PURCHASED`)}`
            : ''}
        </div>
      )}
      <div
        className={`w-[10%] h-[22px] loader_pulse rounded ${
          !isLoading && 'hidden'
        } `}
      ></div>
      {isLoading && (
        <div className="w-full flex gap-4 lg:flex-row flex-col">
          <ul className="w-full flex flex-col gap-4">
            {[1, 2, 3, 4, 5].map(() => (
              <li className="p-4 rounded-lg shadow-xl">
                <div className="flex  gap-4">
                  <div className=" flex-grow w-[25%] rounded  loader_pulse"></div>
                  <div className="w-full ">
                    <div className="flex flex-col gap-3">
                      <div className="flex justify-between ">
                        <div className="w-[20%] h-[25px] loader_pulse rounded"></div>
                        <div className="w-[25%] h-[30px] loader_pulse rounded"></div>
                      </div>
                      <div className="w-[20%] h-[18px] loader_pulse rounded"></div>
                      <div className="w-[20%] h-[18px] loader_pulse rounded"></div>
                      <div className="w-[30%] h-[18px] loader_pulse rounded"></div>
                      <div className="w-[50%] h-[18px] loader_pulse rounded"></div>
                      <div className="w-full flex justify-end gap-2">
                        <div className="w-[5%] h-[24px] loader_pulse rounded"></div>
                        <div className="w-[5%] h-[24px] loader_pulse rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {category === 'purchaseHistory' ? null : (
            <div className="flex flex-col min-w-full lg:min-w-[251px] xl:mt-0 mt-5">
              <div className="w-[20%] h-[25px] loader_pulse rounded mb-2"></div>
              <div className="w-[70%] h-[42px] loader_pulse rounded"></div>
              <div className="w-[100%] h-[54px] loader_pulse rounded my-6"></div>
              <div className="w-[20%] h-[28px] loader_pulse rounded mb-2"></div>
              <div className="w-[100%] h-[46px] loader_pulse rounded"></div>
              <div className="w-[100%] h-[24px] loader_pulse rounded mt-6"></div>
            </div>
          )}
        </div>
      )}

      <div className="flex lg:flex-row flex-col justify-between w-full  gap-5">
        <ul
          className={`flex ${
            category === 'package_assessment' ? 'pt-5' : 'gap-5 '
          } flex-col w-full h-full `}
        >
          {cardData?.length > 0
            ? cardData?.map((item: any, index: number) => {
                return (
                  <div className="" key={`cart-list-${item.id}${index}`}>
                    <div
                      className={`flex items-center gap-4 ${
                        !(category === 'package_assessment') && 'hidden'
                      }`}
                    >
                      <div>
                        <img src="/assets/package/darkCircle.svg" alt="" />
                      </div>
                      <div className="font-17 font-semibold font-general rtl:font-Almarai capitalize">
                        {item?.data?.serviceType}
                      </div>
                    </div>
                    <div>
                      <div
                        className={`${
                          category === 'package_assessment' &&
                          'pl-4 py-4 ml-[7px] border-l border-l-disableGray'
                        }`}
                      >
                        <div
                          className={
                            'flex flex-row w-full shadow-xl rounded-lg p-4 '
                          }
                          key={`cart-list-${item.id}${index}`}
                        >
                          <div
                            className={`w-[100px] lg:w-[200px] lg:h-full ${
                              item.data.isDeleted
                                ? 'cursor-not-allowed'
                                : 'cursor-pointer'
                            }`}
                            onClick={() => serviceView(item)}
                          >
                            <Image
                              id={item?.data?.imgId || item?.data?.imageId}
                              bgImgUrl={'/assets/default-img-icon.svg'}
                              bgClass={
                                ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[100px] rounded-b-lg lg:h-[165px]'
                              }
                              propClass={'w-full h-[165px] rounded-t'}
                            />
                          </div>
                          <div
                            className={`flex ${
                              category === 'purchaseHistory'
                                ? 'lg:flex-row flex-col'
                                : 'flex-row'
                            }
                      ${
                        ['package'].includes(category) && 'items-center'
                      }  w-full flex-grow ltr:pl-5 rtl:pr-5`}
                          >
                            <div className="flex flex-col flex-grow gap-1">
                              <div
                                className={`font-semibold capitalize lg:font-bold font-satoshi rtl:font-Almarai text-17 lg:text-20 ${
                                  item.data.isDeleted
                                    ? 'cursor-not-allowed'
                                    : 'cursor-pointer'
                                }`}
                                onClick={() => serviceView(item)}
                              >
                                {item?.data?.title}
                              </div>
                              <div className="font-normal capitalize text-greyText font-general rtl:font-Almarai lg:text-13 text-12">
                                {item?.data?.subTitle}
                              </div>
                              <div className="font-semibold font-general rtl:font-Almarai lg:text-13 text-12">
                                {item?.data?.coachName}
                              </div>
                              {/* <div className="flex items-center">
                                <div className="pt-1 pr-.5 text-[#BC455E] text-12 font-semibold font-general rtl:font-Almarai ">
                                  {' '}
                                  {item?.data?.rating}
                                </div>
                                <Rating value={item?.data?.rating} />
                                <div className="font-general rtl:font-Almarai text-12 lg:text-13 font-normal text-greyText">
                                  {item?.data?.ratingCount} ratings
                                </div>
                              </div> */}
                              {item?.data?.serviceType === 'SESSION' && (
                                <div className="font-normal text-greyText font-general rtl:font-Almarai text-12 lg:text-13">
                                  {item?.duration
                                    ? `${item?.duration} ${t('MINS')}`
                                    : null}
                                </div>
                              )}
                              {/* {item?.data?.serviceType === 'PACKAGE' && (
                                <div className="font-normal text-greyText font-general rtl:font-Almarai text-12 lg:text-13">
                                  {`${item?.data?.duration} service `}
                                </div>
                              )}

                              {item?.data?.serviceType === 'ASSESSMENT' && (
                                <div className="font-normal text-greyText font-general rtl:font-Almarai text-12 lg:text-13">
                                  {`${item?.data?.deliveryDuration} service `}
                                </div>
                              )} */}
                              <div className="flex flex-row gap-2 w-full">
                                {category === 'cart' && (
                                  <div className="flex lg:hidden text-20 lg:text-24 font-bold font-satoshi rtl:font-Almarai items-end  w-fit">
                                    {`${t('SAR')} ${item?.data?.price}`}
                                  </div>
                                )}
                                <div
                                  className={`${
                                    ['package'].includes(category) && 'hidden'
                                  } flex flex-grow justify-between`}
                                >
                                  {item?.data?.bestSeller && (
                                    <div className="font-semibold w-fit  font-general rtl:font-Almarai mt-2 text-11 lg:text-12 text-white bg-primary p-1 lg:p-2 rounded">
                                       {t(`CLIENT_SESSION_VIEW.BEST_SELLER`)}
                                    </div>
                                  )}
                                  {category === 'purchaseHistory' && (
                                    <div className="lg:hidden  flex text-13 text-disableGray font-normal font-general rtl:font-Almarai  items-center ">
                                      {`Aug 25, 2022`}
                                    </div>
                                  )}
                                </div>

                                {category === 'cart' && (
                                  <div className="lg:hidden flex-row flex  justify-end">
                                    <FavoriteBorderIcon
                                      onClick={() => wishlistUpdate(item)}
                                      className="cursor-pointer"
                                      sx={{
                                        color: `${
                                          item?.data?.inWishList
                                            ? 'red'
                                            : '#687E8E'
                                        } `,
                                      }}
                                    />
                                    <DeleteOutlinedIcon
                                      onClick={() => removeItem(item.id)}
                                      className="cursor-pointer"
                                      sx={{ color: '#687E8E' }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            {userType === USER_TYPE.COACH &&
                            item?.data?.createdBy === userId
                              ? null
                              : !purchased &&
                                item?.schedule &&
                                category === 'package_assessment' &&
                                item?.data?.serviceType === 'SESSION' && (
                                  <button
                                    className="bg-[#02A4EA]  sm:w-[110px] h-fit py-1 text-[13px] md:text-[15px] text-white rounded cursor-pointer"
                                    onClick={() =>
                                      handleSchedule(item?.data?.id)
                                    }
                                  >
                                    {t('MY_CART.SCHEDULE_NOW')}
                                  </button>
                                )}
                            {userType === USER_TYPE.COACH &&
                            item?.data?.createdBy === userId
                              ? null
                              : purchased &&
                                category === 'package_assessment' &&
                                item?.data?.serviceType === 'ASSESSMENT' &&
                                item?.data?.isPurchased && (
                                  <button
                                    className="bg-[#02A4EA] md:p-[3px] md:w-[146px] md:h-[50px]  
                                    w-[137px] h-[43px] p-[3px] text-[13px] md:text-[15px] text-white rounded cursor-pointer"
                                    onClick={() =>
                                      closeAssessment(item?.data?.id)
                                    }
                                  >
                                    {t('MY_CART.ASSESSMENT_REPORT_RECIEVED')}
                                  </button>
                                )}
                            {category === 'cart' && (
                              <div className="flex flex-col h-full justify-between  ">
                                <div className=" hidden lg:flex text-24 font-bold font-satoshi rtl:font-Almarai justify-end ">
                                  {`${t('SAR')} ${item?.data?.price}`}
                                </div>
                                <div className="lg:flex flex-row hidden w-full justify-end">
                                  <FavoriteBorderIcon
                                    onClick={() => wishlistUpdate(item)}
                                    className="cursor-pointer"
                                    sx={{
                                      color: `${
                                        item?.data?.inWishList
                                          ? 'red'
                                          : '#687E8E'
                                      } `,
                                    }}
                                  />
                                  <DeleteOutlinedIcon
                                    onClick={() => removeItem(item.id)}
                                    className="cursor-pointer"
                                    sx={{ color: '#687E8E' }}
                                  />
                                </div>
                              </div>
                            )}
                            {category === 'purchaseHistory' && (
                              <div className="flex flex-col h-full justify-center  relative ">
                                <div className=" absolute top-5 right-0 rtl:left-5  hidden lg:flex text-13 text-disableGray font-normal font-general rtl:font-Almarai justify-end ">
                                  {moment(item?.purchaseDate).locale('en').format(
                                    'MMM DD, yyyy'
                                  )}
                                </div>
                                <div className="flex flex-row gap-2 lg:gap-6 mt-3 lg:mt-0  w-full justify-start lg:justify-center">
                                  {/* <div>
                                  <Button
                                    className={
                                      'border rounded border-light-black min-w-[80px] lg:w-[15vw] flex justify-center'
                                    }
                                    children={
                                      <div className="py-1 lg:py-2 lg:text-16 text-12 font-bold font-satoshi rtl:font-Almarai">
                                        Reciept
                                      </div>
                                    }
                                  />
                                </div> */}
                                  <div>
                                    {item?.paymentStatus === 'COMPLETED' ? (
                                      <Button
                                        className={
                                          'border rounded border-light-black min-w-[80px] lg:w-[15vw] flex justify-center'
                                        }
                                        onClick={() =>
                                          item.data.isDeleted
                                            ? null
                                            : download(item?.clientPurchaseId)
                                        }
                                        children={
                                          <div className="py-1 lg:py-2 lg:text-16 text-12 font-bold font-satoshi rtl:font-Almarai">
                                            <div
                                              className={`${
                                                item.data.isDeleted
                                                  ? 'cursor-not-allowed'
                                                  : 'cursor-pointer'
                                              }`}
                                            >
                                              {t(`PURCHASE_HISTORY.INVOICE`)}
                                            </div>
                                          </div>
                                        }
                                      />
                                    ) : (
                                      <Button
                                        className={
                                          'border rounded border-light-black min-w-[80px] lg:w-[15vw] flex justify-center cursor-default'
                                        }
                                        children={
                                          <div className="py-1 lg:py-2 lg:text-16 text-12 font-bold font-satoshi rtl:font-Almarai">
                                            <div>{item?.paymentStatus}</div>{' '}
                                          </div>
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </ul>
        {!['purchaseHistory', 'package', 'package_assessment'].includes(
          category
        ) && !isLoading ? (
          <div>
            <div>
              <h1 className="text-15 font-bold font-satoshi rtl:font-Almarai p-1 text-gray">
                {t(`MY_CART.CART_TOTAL`)}:&nbsp;
                <span className="pl-.5 text-20 text-primary">
                  {t('SAR')} &nbsp;
                  {cartTotal}
                </span>
              </h1>
            </div>
            <div>
              <h1 className="text-15 font-bold font-satoshi rtl:font-Almarai p-1 text-gray">
                {t(`MY_CART.VAT_TOTAL`)}&nbsp;{`(${CONSTANTS.VAT})`}:&nbsp;
                <span className="pl-.5 text-20 text-primary">
                  {t('SAR')} &nbsp;
                  {vatAmount}
                </span>
              </h1>
            </div>
            <h1 className="text-15 font-bold font-satoshi rtl:font-Almarai text-gray">
              {t(`MY_CART.TOTAL`)}:
              <span className="pl-.5 text-2xl  font-bold font-satoshi rtl:font-Almarai text-primary">
                &nbsp;
                {t('SAR')} {total}
              </span>
            </h1>

            <div className="py-6">
              <Button
                className={`w-full ${
                  cardData?.length && termsAndConditionsToggle
                    ? 'bg-primary text-white'
                    : ' bg-disableGray cursor-not-allowed text-white'
                }  rounded py-4`}
                children={
                  // <Link to={`/payment?amount=${cardData?.total}`}>
                  <div
                    className="text-18 font-bold font-satoshi rtl:font-Almarai "
                    onClick={termsAndConditionsToggle ? createOrder : undefined}
                  >
                    {t(`MY_CART.CHECKOUT`)}
                  </div>
                  // </Link>
                }
              />
            </div>

            <h1 className="font-satoshi rtl:font-Almarai font-bold pb-2 text-14">
              {t(`MY_CART.PROMOTIONS`)}
            </h1>
            <div className="flex flex-row h-[46px] w-full mb-2">
              <div className="w-[80%]">
                <Input
                  className="ltr:rounded-l rtl:rounded-r rtl:rounded-l-none !border-greyText rounded-r-none ltr:border-r-0 rtl:border-l-0"
                  optional={true}
                  value={gift}
                  onChange={(e) => {
                    setGift(e.target.value);
                  }}
                  cart={true}
                />
              </div>
              <div
                className=" rtl:font-Almarai flex px-[20px] flex-grow lg:flex-grow-0 justify-center ltr:rounded-r rtl:rounded-l items-center bg-[#9EE0E6] cursor-pointer"
                onClick={onApply}
              >
                {t(`MY_CART.APPLY`)}
              </div>
            </div>
            {gift === appliedPromotions?.giftcard_number ? (
              appliedPromotions?.remaining_value !== '0.0' ? (
                <>
                  <div className="text-14 font-bold">
                    {t('MY_CART.REMAING_BALANCE')}&nbsp; : {t('SAR')}&nbsp;
                    {appliedPromotions?.remaining_value}
                  </div>
                  {/* <div className="text-14">
                    Amount remaining : SAR&nbsp;
                    {appliedPromotions?.data?.spend?.remaining_value}
                  </div> */}
                </>
              ) : (
                <div className="text-14">Can't redeem with this card.</div>
              )
            ) : null}
            <div className="pt-6 font-normal text-16 font-general rtl:font-Almarai text-gray flex">
              <div
                onClick={() =>
                  setTermsAndConditionsToggle(!termsAndConditionsToggle)
                }
              >
                {termsAndConditionsToggle ? (
                  <CheckBoxOutlinedIcon sx={{ color: '#02A4EA' }} />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon sx={{ color: '#687E8E' }} />
                )}
              </div>
              <Link
                className="underline underline-offset-auto cursor-pointer pl-1"
                to={'/terms-and-condition'}
              >
                {t(`TOS`)}
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default CartCard;

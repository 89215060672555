import { FieldArray, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { experienceValidationSchema } from '../../coachHelpers';
import {
  ICoachingInterface,
  IEducationalInterface,
  IExperienceInterface,
  IFormikInterface,
  IUploadInterface,
} from '../../coachInterfaces/registrationInterface';
import {
  experienceInformation,
  registrationFormDetails,
} from '../../coachSlices/registrationSlice';
import { useAppSelector } from '../../hooks';

import { useEffect } from 'react';
import Date from '../../clientComponents/date';
import Input from '../../clientComponents/inputs';
import { registrationConst } from '../../coachConstants';
import Button from '../buttons';
import Carousel from '../imageSlider';
import TextArea from '../textArea';
import './index.css';

const Component = (): JSX.Element => {
  const [t] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);

  const {
    educationalRequests,
    accreditationRequest,
    specializationInfo,
    personalInfo,
    isProcessingRegistration,
    isSuccess,
    experienceRequests,
  } = useAppSelector((state: { registration: any }) => state.registration);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  var curr = new window.Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  useEffect(() => {
    if (!personalInfo?.fullName && !isSuccess)
      navigate(registrationConst.PATH_PERSONAL_DETAILS);
  }, [personalInfo]);
  const accreditationRequestData =
    accreditationRequest &&
    accreditationRequest?.accreditationRequest?.map(
      (item: ICoachingInterface, i: number) => {
        return {
          provider: item?.provider,
          accreditationName: item?.accreditationName,
          coachingHours: item?.coachingHours,
          issueDate: item?.issueDate,
          expiration: item?.expiration,
          certificatesName: item?.certificates,
          file: item?.file,
        };
      }
    );

  const educationalRequestsData = educationalRequests?.educationRequests?.map(
    (item: IEducationalInterface, i: number) => {
      return {
        university: item?.university,
        degree: item?.degree,
        branch: item?.fieldOfStudy,
        startDate: item?.startDate,
        endDate: item?.endDate,
        description: item?.description,
        file: item?.file ? item?.file : '',
      };
    }
  );

  const uploadFile = (e: IUploadInterface, i: number, setFieldValue: any) => {
    setFieldValue(`experienceRequests.${i}.file`, e.target.files[0]);
  };

  const handleDispatch = (data: any) => {
    const experienceRequestsData =
      data?.experienceRequests &&
      data?.experienceRequests?.map((item: IExperienceInterface, i: number) => {
        const payload = {
          title: item?.title ? item?.title : undefined,
          companyName: item?.companyName ? item?.companyName : undefined,
          location: item?.location ? item?.location : undefined,
          startDate: item?.role
            ? item?.startDate
            : item?.endDate && item?.startDate && !item?.role
            ? item?.startDate
            : undefined,
          description: item?.description ? item?.description : undefined,
          isCurrentlyWorking: item?.role
            ? true
            : item?.endDate && item?.startDate && !item?.role
            ? false
            : undefined,
          endDate: !item?.role && item?.endDate ? item?.endDate : undefined,
          file: item?.file ? item?.file : undefined,
        };
        return payload;
      });
    let checkEducationData: boolean[] = [];
    educationalRequestsData?.forEach((item: any) => {
      Object.keys(item).map((value) => {
        if (value !== 'file') {
          item[value]
            ? checkEducationData.push(true)
            : checkEducationData.push(false);
        } else {
          item.file
            ? checkEducationData.push(true)
            : checkEducationData.push(false);
        }
      });
    });
    let checkExperienceData: boolean[] = [];
    experienceRequestsData?.forEach((item: any) => {
      Object.keys(item).map((value) =>
        item[value]
          ? checkExperienceData.push(true)
          : checkExperienceData.push(false)
      );
    });

    // const checkData = !experienceDatas.some((item) => item !== undefined);

    const allData: any = {
      fullName: personalInfo?.fullName,
      email: personalInfo?.email,
      dob: personalInfo?.dateOfBirth,
      phoneNumber: parseInt(personalInfo?.phoneNumber),
      countryCode: personalInfo?.countryCode,
      gender: personalInfo?.gender,
      specializations: specializationInfo?.coachingSpecialization,
      coachingCategory: specializationInfo?.coachingCategories,
      languages: specializationInfo?.language,
      userAddressRequest: {
        addressLine1: personalInfo?.address01,
        addressLine2: personalInfo?.address02,
        country: personalInfo?.country,
        city: personalInfo?.city,
      },
      accreditationRequest: accreditationRequestData,
      // educationRequests: checkEducationData
      //   ? educationalRequestsData
      //   : undefined,
      // experienceRequests: !checkData ? experienceRequestsData : undefined,
    };
    if (checkEducationData.includes(true)) {
      allData['educationRequests'] = educationalRequestsData;
    }
    if (checkExperienceData.includes(true)) {
      allData['experienceRequests'] = experienceRequestsData;
    }
    dispatch(registrationFormDetails({ ...allData, history: navigate }));
  };

  return (
    <div className="registrationStep-container01">
      <div className="registrationStep-container03">
        <p className="step"> {t('EXPERIENCE.STEP05')}</p>
        <div className="progress_bar">
          <div className="bg-primary h-1 w-5/5"></div>
        </div>

        <Formik
          initialValues={{
            experienceRequests:
              experienceRequests?.experienceRequests?.length > 0
                ? [...experienceRequests?.experienceRequests]
                : [
                    {
                      title: '',
                      companyName: '',
                      location: '',
                      startDate: '',
                      description: '',
                      fileId: '',
                      role: false,
                      endDate: '',
                      file: '',
                    },
                  ],
          }}
          validationSchema={experienceValidationSchema}
          onSubmit={handleDispatch}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            submitForm,
            setFieldValue,
          }: IFormikInterface) => (
            <Form>
              <div className=" lg:pr-5 mb-6 lg:mb-24">
                <h1 className="heading_large sm:heading_small">
                  {t('EXPERIENCE.EXPERIENCE')}
                  <span className="font-bold text-20 font-satoshi rtl:font-Almarai text-gray">
                    {t('EXPERIENCE.OPTIONAL')}
                  </span>
                </h1>
                <p className="paragraph-educational">
                  {t('EXPERIENCE.DESCRIPTION')}
                </p>

                <FieldArray
                  name="experienceRequests"
                  render={(arrayHelpers) => {
                    return (
                      <div>
                        {values.experienceRequests?.map(
                          (details: [], i: number) => {
                            const ticketErrors =
                              (errors.experienceRequests?.length &&
                                errors.experienceRequests[i]) ||
                              '';
                            const ticketTouched =
                              (touched.experienceRequests?.length &&
                                touched.experienceRequests[i]) ||
                              {};
                            const valuesRole =
                              values.experienceRequests[i].role;
                            const valuesEndDate =
                              values.experienceRequests[i].endDate;

                            const valuesStartDate =
                              values.experienceRequests[i].startDate;
                            dispatch(experienceInformation(values));

                            return (
                              <div key={i}>
                                {i > 0 && (
                                  <div className="flex justify-end text-end border-info mt-8 lg:border-t-2">
                                    <Button
                                      onClick={() => arrayHelpers.remove(i)}
                                      className="mb-5 mt-9 text-12 font-bold text-red rtl:font-Almarai border-2 border-red px-4 rounded-md py-2"
                                    >
                                      {t('remove')}
                                    </Button>
                                  </div>
                                )}
                                <Input
                                  id={`experienceRequests.${i}.title`}
                                  name={`experienceRequests.${i}.title`}
                                  type="text"
                                  optional={true}
                                  placeholder={t('EXPERIENCE.TITLE')}
                                  onBlur={handleBlur}
                                  error={ticketTouched.title && 1}
                                  onChange={handleChange}
                                  errormessage={ticketErrors.title}
                                  value={values?.experienceRequests[i]?.title}
                                />
                                <Input
                                  id={`experienceRequests.${i}.companyName`}
                                  name={`experienceRequests.${i}.companyName`}
                                  type="text"
                                  optional={true}
                                  placeholder={t('EXPERIENCE.COMPANY_NAME')}
                                  onBlur={handleBlur}
                                  error={ticketTouched.companyName && 1}
                                  onChange={handleChange}
                                  errormessage={ticketErrors.companyName}
                                  value={
                                    values?.experienceRequests[i]?.companyName
                                  }
                                />
                                <Input
                                  id={`experienceRequests.${i}.location`}
                                  optional={true}
                                  name={`experienceRequests.${i}.location`}
                                  type="text"
                                  placeholder={t('EXPERIENCE.LOCATION')}
                                  onBlur={handleBlur}
                                  error={ticketTouched.location && 1}
                                  onChange={handleChange}
                                  errormessage={ticketErrors.location}
                                  value={
                                    values?.experienceRequests[i]?.location
                                  }
                                />
                                <div className="flex justify-between items-center mb-4">
                                  <div>
                                    <label className="inline-flex items-center">
                                      <input
                                        id={`experienceRequests.${i}.role`}
                                        name={`experienceRequests.${i}.role`}
                                        type="checkbox"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `experienceRequests.${i}.role`,
                                            e.target.checked
                                          );
                                        }}
                                        checked={
                                          values?.experienceRequests[i]?.role
                                        }
                                        className="form-checkbox h-6 w-6"
                                      />
                                      <span
                                        className={` ${
                                          lang === 'en' ? 'ml-2' : 'mr-2'
                                        } text-gray font-normal text-16`}
                                      >
                                        {t('EXPERIENCE.ROLE')}
                                      </span>
                                    </label>
                                  </div>
                                </div>

                                <div className="lg:flex lg:justify-between ">
                                  <div
                                    className={` lg:w-6/12 ${
                                      lang === 'en' ? 'lg:mr-3' : 'lg:ml-3'
                                    } `}
                                  >
                                    <Date
                                      id={`experienceRequests.${i}.startDate`}
                                      name={`experienceRequests.${i}.startDate`}
                                      type="date"
                                      value={valuesStartDate}
                                      optional={1}
                                      placeholder={t('EXPERIENCE.START_DATE')}
                                      onBlur={handleBlur}
                                      error={ticketTouched.startDate && 1}
                                      onChange={(opt: any) => {
                                        const date = `${opt?.year}-${
                                          opt?.monthIndex + 1 < 10
                                            ? `0${opt?.monthIndex + 1}`
                                            : opt?.monthIndex + 1
                                        }-${
                                          opt?.day < 10
                                            ? `0${opt?.day}`
                                            : opt?.day
                                        }`;
                                        setFieldValue(
                                          `experienceRequests.${i}.startDate`,
                                          date
                                        );
                                      }}
                                      errormessage={ticketErrors.startDate}
                                    />
                                  </div>
                                  {!values.experienceRequests[i].role && (
                                    <div className=" lg:w-6/12">
                                      <Date
                                        id={`experienceRequests.${i}.endDate`}
                                        name={`experienceRequests.${i}.endDate`}
                                        type="date"
                                        optional={1}
                                        value={
                                          valuesRole ? date : valuesEndDate
                                        }
                                        defaultValue={
                                          valuesRole ? date : valuesEndDate
                                        }
                                        placeholder={t('EXPERIENCE.END_DATE')}
                                        onBlur={handleBlur}
                                        error={ticketTouched.endDate && 1}
                                        onChange={(opt: any) => {
                                          const date = `${opt?.year}-${
                                            opt?.monthIndex + 1 < 10
                                              ? `0${opt?.monthIndex + 1}`
                                              : opt?.monthIndex + 1
                                          }-${
                                            opt?.day < 10
                                              ? `0${opt?.day}`
                                              : opt?.day
                                          }`;
                                          setFieldValue(
                                            `experienceRequests.${i}.endDate`,
                                            date
                                          );
                                        }}
                                        errormessage={ticketErrors.endDate}
                                      />
                                    </div>
                                  )}
                                </div>
                                <TextArea
                                  id={`experienceRequests.${i}.description`}
                                  name={`experienceRequests.${i}.description`}
                                  type="text"
                                  rows={3}
                                  cols={50}
                                  optional={1}
                                  placeholder={t(
                                    'EXPERIENCE.DESCRIPTION_DETAILS'
                                  )}
                                  onBlur={handleBlur}
                                  error={ticketTouched.description && 1}
                                  onChange={handleChange}
                                  errormessage={ticketErrors.description}
                                  value={
                                    values?.experienceRequests[i]?.description
                                  }
                                />
                                <h1 className="text-22 font-bold font-satoshi rtl:font-Almarai text-secondary mt-4 mb-2 ">
                                  {t('EXPERIENCE.MEDIA')}
                                </h1>
                                <p className="text-gray text-17 font-normal font-general rtl:font-Almarai">
                                  {t('EXPERIENCE.MEDIA_DEC')}
                                </p>
                                <div className="my-5">
                                  <label
                                    htmlFor={`experienceRequests.${i}.fileId`}
                                    className="bg-skyBlue cursor-pointer rounded px-4 py-2 text-secondary text-14 font-bold"
                                  >
                                    {t('EXPERIENCE.UPLOAD_DOC')}
                                  </label>
                                  <input
                                    id={`experienceRequests.${i}.fileId`}
                                    name={`experienceRequests.${i}.file`}
                                    className="form-control"
                                    type="file"
                                    accept=".jpg,.jpeg,.png,.pdf"
                                    onChange={(e) => {
                                      // setFieldValue(
                                      //   'file',
                                      //   e.currentTarget.files[0]
                                      // );
                                      uploadFile(e, i, setFieldValue);
                                    }}
                                    style={{ display: 'none' }}
                                  />
                                  <span
                                    id={`file${i}`}
                                    className={`${
                                      lang === 'en' ? 'ml-3' : 'mr-3'
                                    } text-13 font-normal text-gray`}
                                  >
                                    {values.experienceRequests[i]?.file
                                      ? values.experienceRequests[i]?.file?.name
                                      : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
                                  </span>
                                </div>
                                <div className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
                                  {errors?.experienceRequests?.length > 0
                                    ? t(
                                        `ERRORS.${errors.experienceRequests[i]?.file}`
                                      )
                                    : ''}
                                </div>
                              </div>
                            );
                          }
                        )}
                        <button
                          type="button"
                          className="add_more "
                          onClick={() => {
                            arrayHelpers.push({
                              title: '',
                              companyName: '',
                              location: '',
                              startDate: '',
                              description: '',
                              fileId: '',
                              role: false,
                              endDate: '',
                            });
                          }}
                        >
                          {t('ADD_MORE')}
                        </button>
                      </div>
                    );
                  }}
                />
              </div>

              <div className="lg:flex lg:justify-between grid py-4 mb-20 lg:mt-20 border-info lg:border-t-2">
                <button
                  onClick={() =>
                    navigate('/coach/registration/educational-details')
                  }
                  className="order-last lg:order-first cancel-btn mb-6 py-4 hover:bg-primary hover:border-transparent hover:text-white hover:lg:next-btn hover:next-btn-large"
                >
                  {t('BACK')}
                </button>
                <div className=" lg:flex lg:justify-start grid lg:w-1/2">
                  {/* <button
                    className={` order-last lg:order-first lg:skip-btn skip-btn-large ${
                      lang === 'en' ? 'lg:mr-8' : 'lg:ml-8'
                    } `}
                  >
                    {t('SKIP')}
                  </button> */}
                  <button className="lg:next-btn next-btn-large bg-primary   flex justify-around items-center">
                    <div className="w-1/2 flex justify-around items-center">
                      {' '}
                      {isProcessingRegistration && <div className="loader" />}
                      {t('EXPERIENCE.SUBMIT')}
                    </div>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Carousel />
    </div>
  );
};
const ExperienceDetails = styled(Component)``;

export default ExperienceDetails;

/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { USER_TYPE } from '../coachConstants/genericConstants';
import { paymentInfo } from '../coachSlices/paymentSlice';

interface Children {
  children: any;
}

const PublicRoute = ({ children, restricted }: any) => {
  const location = useLocation();
  const loginAuth = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');
  const { purchased } = useSelector(paymentInfo);
  return loginAuth && restricted ? (
    <Navigate
      to={
        userType === USER_TYPE.CLIENT
          ? '/client/profile'
          : userType === USER_TYPE.COACH && purchased
          ? '/coach/home'
          : '/coach/subscription'
      }
      replace
      state={{ path: location.pathname }}
    />
  ) : (
    children
  );
};
export default PublicRoute;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
interface INotificationCard {
  title: string;
  date: string;
  time: string;
}
const Component: FC<INotificationCard> = ({
  title,
  date,
  time,
}): JSX.Element => {
  const [t] = useTranslation('common');

  return (
    <div className="w-full mb-2 flex flex-row">
      <div className="h-[5.25rem] w-[3px] rounded-l bg-[#9ee0e6] "></div>
      <div className=" schedule-inner-box p-2 flex flex-col  justify-between">
        <div className="w-full   flex flex row justify-between text-[#153243] text-sm font-semibold">
          {title}
          <div className="h-5 w-11 text-[8px] font-medium bg-[#9EE0E6] rounded flex items-center justify-center rtl:font-Almarai">
            {t('HOME.SERVICE')}
          </div>
        </div>
        <div className="text-[#687E8E] font-normal text-[10px]">{date}</div>
        <div className="flex">
          <span className="text-[#687E8E] font-normal text-[10px] rtl:font-Almarai">
            {t('HOME.TIME')}
            &nbsp;
          </span>
          <span className=" font-bold text-[10px]">
            {time} &nbsp; {localStorage.getItem('timezone')}
          </span>
        </div>
      </div>
    </div>
  );
};
const ScheduleCard = styled(Component)``;

export default ScheduleCard;

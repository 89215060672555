import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { decodeUserId, getAccessToken } from '../coachHelpers/localStorage';
import { IPaymentSliceInterface } from '../coachInterfaces/paymentInterface';
import {
  fetchPayment,
  fetchSubscription,
  selectSubscriptionApi,
  upgradeSelection,
} from '../coachServices/paymentService';
import { RootState } from '../store';

const initialState: IPaymentSliceInterface = {
  isProcessingPayment: false,
  isProcessingSubscription: false,
  purchased: true,
  subScriptionId: 0,
};

export const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.isProcessingSubscription = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.isProcessingSubscription = false;
        if (action.payload.data[0]?.content.length < 1) state.purchased = false;
        else {
          state.purchased = action.payload.data[0]?.content[0]?.purchased;
          state.subScriptionId =
            action.payload.data[0]?.content[0]?.subscriptionId;
        }
      })
      .addCase(getSubscription.rejected, (state) => {
        state.isProcessingSubscription = false;
      })

      .addCase(subscribeSelection.pending, (state) => {
        state.isProcessingPayment = true;
      })
      .addCase(subscribeSelection.fulfilled, (state) => {
        state.isProcessingPayment = false;
      })
      .addCase(subscribeSelection.rejected, (state) => {
        state.isProcessingPayment = false;
      });
  },
});

export const subscribeSelection = createAsyncThunk(
  'subscribe/selection',
  async (payload: { data: any; navigate: any }, thunkAPI) => {
    const { paymentInfo } = thunkAPI.getState() as {
      paymentInfo: IPaymentSliceInterface;
    };
    try {
      let data;
      if (paymentInfo.purchased) data = await upgradeSelection(payload.data.id);
      else data = await selectSubscriptionApi(payload.data.id);
      // const data = purchased
      //   ? await upgradeSelection(payload.data.id)
      //   : await selectSubscriptionApi(payload.data.id);
      const response = await fetchPayment({
        orderType: 'SUBSCRIPTION',
        finalAmount: payload.data?.charge,
        subscriptionId: data?.data[0]?.id,
        cartList: [],
      });
      if(Number(payload.data?.charge)===0){
        payload.navigate('/order-status?status=SUCCESS')
      } else
      payload.navigate(
        '/payment?amount=' +
          payload.data?.charge +
          '&orderId=' +
          response.data[0].id
      );

      // await thunkAPI.dispatch(getSubscription());
      // payload.navigate('/coach/home');

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getSubscription = createAsyncThunk(
  'get/subscription',
  async (id, thunkAPI) => {
    try {
      const response = await fetchSubscription(
        decodeUserId(getAccessToken() as string)
      );

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const {} = paymentSlice.actions;
export const paymentInfo = (state: RootState) => state.paymentInfo;
export const paymentInfoReducer = paymentSlice.reducer;

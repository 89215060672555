import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { getDefaultValue } from '../../clientHelpers/utility';
import { TextArea } from '../../coachComponents';
import { bankingInfoValidationSchema } from '../../coachHelpers';
import { IBankingInterface } from '../../coachInterfaces/profileInterface';
import {
  getBankingInfoById,
  updateBankingInfo,
} from '../../coachSlices/profileSlices/bankingInfoSlice';

const EditBankingInfo = ({
  setShowEdit,
  UserId,
  showEdit,
  bankingDetailsById,
}: {
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  UserId: number;
  showEdit: boolean;
  bankingDetailsById: any;
}) => {
  const [t] = useTranslation('common');
  // const { bankingDetailsById } = useAppSelector((state) => state.bankingInfo);
  const [accountNumber, setAccountNumber] = useState('');
  const [isPrimaryAccount, setIsPrimaryAccount] = useState(true);
  const [bankCode, setBankCode] = useState(bankingDetailsById?.bankCode);
  const [bankName, setBankName] = useState(bankingDetailsById?.bankName);
  const [beneficiaryName, setName] = useState('');
  const [iban, setIBAN] = useState('');
  const [bankAddress, setbankAddress] = useState('');
  const [beneficiaryAddress, setBeneficiaryAddress] = useState('');
  const [bankClearanceCode, setBankClearanceCode] = useState('');

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getBankingInfoById(UserId));
  }, []);

  useEffect(() => {
    if (bankingDetailsById) {
      setAccountNumber(bankingDetailsById?.accountNumber);
      setName(bankingDetailsById?.beneficiaryName);
      setIBAN(bankingDetailsById?.iban);
      setBeneficiaryAddress(bankingDetailsById?.beneficiaryAddress);
      setBankClearanceCode(bankingDetailsById?.bankClearanceCode);
      setbankAddress(bankingDetailsById?.bankAddress);
      setIsPrimaryAccount(bankingDetailsById?.isPrimaryAccount);
    }
  }, [bankingDetailsById]);

  const handleSubmit = (data: IBankingInterface) => {
    const dataWithId: any = {
      data: {
        data,
      },
      id: UserId,
    };
    dispatch(updateBankingInfo(dataWithId));
    setShowEdit(false);
  };
  const [alternativeState, setAlternativeState] = useState(false);

  const onAlternateChange = (setFieldVlaue: any, name: string) => {
    setFieldVlaue(name, !alternativeState);
    setAlternativeState(!alternativeState);
  };
  const handleBankCode = (event: any) => {
    setBankCode(event);
  };
  const handleBankName = (event: any) => {
    setBankName(event);
  };
  const bankCodeValue = [
    { label: 'AAALSARI', value: 'AAALSARI' },
    { label: 'ALBISARI', value: 'ALBISARI' },
    { label: 'ARNBSARI', value: 'ARNBSARI' },
    { label: 'BJAZSAJE', value: 'BJAZSAJE' },
    { label: 'BNPASARI', value: 'BNPASARI' },
    { label: 'BSFRSARI', value: 'BSFRSARI' },
    { label: 'DEUTSARI', value: 'DEUTSARI' },
    { label: 'EBILSARI', value: 'EBILSARI' },
    { label: 'GULFSARI', value: 'GULFSARI' },
    { label: 'NCBKSAJE', value: 'NCBKSAJE' },
    { label: 'RJHISARI', value: 'RJHISARI' },
    { label: 'SABBSARI', value: 'SABBSARI' },
    { label: 'SAMBSARI', value: 'SAMBSARI' },
    { label: 'SIBCSARI', value: 'SIBCSARI' },
    { label: 'RIBLSARI', value: 'RIBLSARI' },
    { label: 'SAMASARI', value: 'SAMASARI' },
    { label: 'NBOKSAJE', value: 'NBOKSAJE' },
    { label: 'BMUSSARI', value: 'BMUSSARI' },
    { label: 'NBOBSARI', value: 'NBOBSARI' },
    { label: 'INMASARI', value: 'INMASARI' },
    { label: 'NBPASARI', value: 'NBPASARI' },
    { label: 'TCZBSAJE', value: 'TCZBSAJE' },
    { label: 'SBINSAJE', value: 'SBINSAJE' },
    { label: 'CHASSARI', value: 'CHASSARI' },
    { label: 'ICBKSARI', value: 'ICBKSARI' },
    { label: 'FABMSARI', value: 'FABMSARI' },
  ];
  const bankValues: any = {
    AAALSARI: 'Alawwal Bank',
    ALBISARI: 'BANK ALBILAD',
    ARNBSARI: 'ARAB NATIONAL BANK',
    BJAZSAJE: 'BANK ALJAZIRA',
    BNPASARI: 'BNP PARIBAS',
    BSFRSARI: 'BANQUE SAUDI FRANSI',
    DEUTSARI: 'DEUTSCHE BANK',
    EBILSARI: 'EMIRATES BANK INTERNATIONAL',
    GULFSARI: 'GULF INTERNATIONAL BANK',
    NCBKSAJE: 'NATIONAL COMMERCIAL BANK',
    RJHISARI: 'AL RAJHI BANK',
    SABBSARI: 'SABB',
    SAMBSARI: 'SAMBA',
    SIBCSARI: 'THE SAUDI INVESTMENT BANK',
    RIBLSARI: 'Riyad Bank',
    SAMASARI: 'Saudi Arabian Monetary Agency-SAMA',
    NBOKSAJE: 'National Bank of Kuwait (NBOK)',
    BMUSSARI: 'BANK MUSCAT',
    NBOBSARI: 'National Bank of Bahrain',
    INMASARI: 'AL INMA BANK',
    NBPASARI: 'National Bank of Pakistan',
    TCZBSAJE: 'TURKIYE CUMHURIYETI ZIRAAT BANKASI',
    SBINSAJE: 'State Bank of India',
    CHASSARI: 'JPMorgan Chase Bank, N.A.(Riyadh Branch)',
    ICBKSARI: 'Ind. and Commercial Bank of China',
    FABMSARI: 'First Abu Dhabi Bank',
    SABB: 'SABBSARI',
    SAMBA: 'SAMBSARI',
  };
  const bankNameValue = [
    { label: 'Alawwal Bank', value: 'Alawwal Bank' },
    { value: 'BANK ALBILAD', label: 'BANK ALBILAD' },
    { value: 'ARAB NATIONAL BANK', label: 'ARAB NATIONAL BANK' },
    { value: 'BANK ALJAZIRA', label: 'BANK ALJAZIRA' },
    { value: 'BNP PARIBAS', label: 'BNP PARIBAS' },
    { value: 'BANQUE SAUDI FRANSI', label: 'BANQUE SAUDI FRANSI' },
    { value: 'DEUTSCHE BANK', label: 'DEUTSCHE BANK' },
    {
      value: 'EMIRATES BANK INTERNATIONAL',
      label: 'EMIRATES BANK INTERNATIONAL',
    },
    { value: 'GULF INTERNATIONAL BANK', label: 'GULF INTERNATIONAL BANK' },
    { value: 'NATIONAL COMMERCIAL BANK', label: 'NATIONAL COMMERCIAL BANK' },
    { value: 'AL RAJHI BANK', label: 'AL RAJHI BANK' },
    { value: 'SABB', label: 'SABB' },
    { value: 'SAMBA', label: 'SAMBA' },
    {
      value: 'THE SAUDI INVESTMENT BANK',
      label: 'THE SAUDI INVESTMENT BANK',
    },
    { value: 'Riyad Bank', label: 'Riyad Bank' },
    {
      value: 'Saudi Arabian Monetary Agency-SAMA',
      label: 'Saudi Arabian Monetary Agency-SAMA',
    },
    {
      value: 'National Bank of Kuwait (NBOK)',
      label: 'National Bank of Kuwait (NBOK)',
    },
    { value: 'BANK MUSCAT', label: 'BANK MUSCAT' },
    { value: 'National Bank of Bahrain', label: 'National Bank of Bahrain' },
    { value: 'AL INMA BANK', label: 'AL INMA BANK' },
    {
      value: 'National Bank of Pakistan',
      label: 'National Bank of Pakistan',
    },
    {
      value: 'TURKIYE CUMHURIYETI ZIRAAT BANKASI',
      label: 'TURKIYE CUMHURIYETI ZIRAAT BANKASI',
    },
    { value: 'State Bank of India', label: 'State Bank of India' },
    {
      value: 'JPMorgan Chase Bank, N.A.(Riyadh Branch)',
      label: 'JPMorgan Chase Bank, N.A.(Riyadh Branch)',
    },
    {
      value: 'Ind. and Commercial Bank of China',
      label: 'Ind. and Commercial Bank of China',
    },
    { value: 'First Abu Dhabi Bank', label: 'First Abu Dhabi Bank' },
  ];
  const handleBankcode = (setFieldValue: any, bankName: any) => {
    Object.keys(bankValues).map((bankCode: any) => {
      console.log(bankName);
      if (bankValues[bankCode] === bankName) {
        console.log(bankCode);
        setFieldValue('bankCode', bankCode);
        // setbankCodeValue(getDefaultValue(bankCode, bankCode)[0]);
      }
    });
  };
  return (
    <>
      <div className="fixed inset-0 bg-transparentBlack bg-opacity-50 z-10">
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="mt-3 sm:flex">
              <div className="grid w-full">
                <button
                  className="flex justify-self-end"
                  onClick={() => setShowEdit(false)}
                >
                  <img
                    src="/assets/profilePageImage/model.svg"
                    alt="icon"
                    className="mr-2 cursor-pointer"
                  />
                </button>
                <h1 className="text-28 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
                  {t('PROFILE.PROFILE_INFO.EDIT_BANKING_INFO')}
                </h1>
                <Formik
                  initialValues={{
                    accountNumber,
                    iban,
                    beneficiaryAddress,
                    beneficiaryName,
                    isPrimaryAccount,
                    bankCode,
                    bankName,
                    bankAddress,
                    bankClearanceCode,
                  }}
                  validationSchema={bankingInfoValidationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({
                    errors,
                    touched,
                    values,
                    submitForm,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    console.log(touched.beneficiaryAddress);
                    return (
                      <Form id="my-form">
                        <div>
                          <div className="h-[50vh]  overflow-y-scroll ">
                            <Input
                              className="mt-5"
                              id="accountNumber"
                              type="text"
                              name="accountNumber"
                              placeholder={t(
                                'PROFILE.PROFILE_INFO.ACCOUNT_NUMBER'
                              )}
                              onBlur={handleBlur}
                              error={touched.accountNumber && 1}
                              onChange={handleChange}
                              value={values.accountNumber}
                              errormessage={errors.accountNumber}
                            />
                            <Input
                              id="beneficiaryName"
                              type="text"
                              name="beneficiaryName"
                              placeholder={t('PROFILE.PROFILE_INFO.NAME')}
                              onBlur={handleBlur}
                              error={touched.beneficiaryName && 1}
                              onChange={handleChange}
                              value={values.beneficiaryName}
                              errormessage={errors.beneficiaryName}
                            />
                            <TextArea
                              id="beneficiaryAddress"
                              type="text"
                              name="beneficiaryAddress"
                              placeholder={t(
                                'PROFILE.PROFILE_INFO.BENEFICIARY_ADDRESS'
                              )}
                              // className={errors.beneficiaryAddress ? 'border border-solid border-red' : 'border border-solid border-'}
                              onBlur={handleBlur}
                              error={touched.beneficiaryAddress && 1}
                              onChange={handleChange}
                              value={values.beneficiaryAddress}
                              errormessage={t(
                                errors.beneficiaryAddress as string
                              )}
                            />
                            <div className="pt-6">
                              <Input
                                id="iban"
                                type="text"
                                name="iban"
                                placeholder={t('PROFILE.PROFILE_INFO.IBAN')}
                                onBlur={handleBlur}
                                error={touched.iban && 1}
                                onChange={handleChange}
                                value={values.iban}
                                errormessage={errors.iban}
                              />
                            </div>

                            <SelectDropdown
                              id="bankName"
                              isMulti={false}
                              isCreatable={false}
                              isSearchable={false}
                              options={bankNameValue}
                              optional={0}
                              dropDown={true}
                              name="bankName"
                              placeholder={t('PROFILE.PROFILE_INFO.BANK_NAME')}
                              onBlur={handleBlur}
                              isValue={values.bankName}
                              onChange={(opt: any) => {
                                setFieldValue('bankName', opt.value);
                                handleBankcode(setFieldValue, opt.value);
                              }}
                              value={{
                                label: bankName,
                                value: bankName,
                              }}
                              // onInputChange={handleBankName}
                              className={`h-[46px] `}
                              defaultValue={
                                getDefaultValue(
                                  values.bankName,
                                  bankNameValue
                                )[0]
                              }
                              controlShouldRenderValue={true}
                            />
                            <div className="pt-8 ">
                              {/* <SelectDropdown
                              id="bankCode"
                              isMulti={false}
                              isCreatable={false}
                              isSearchable={false}
                              options={bankCodeValue}
                              optional={1}
                              dropDown={true}
                              name="bankCode"
                              placeholder={t('PROFILE.PROFILE_INFO.BANK_CODE')}
                              onBlur={handleBlur}
                              isValue={values.bankCode}
                              onChange={(opt: any) => {
                                setFieldValue('bankCode', opt.value);
                              }}
                              // onInputChange={handleBankCode}
                              className={`h-[46px]`}
                              defaultValue={
                                getDefaultValue(
                                  values.bankCode,
                                  bankCodeValue
                                )[0]
                              }
                              controlShouldRenderValue={true}
                            /> */}
                              <Input
                                id="bankCode"
                                type="text"
                                name="bankCode"
                                placeholder={t(
                                  'PROFILE.PROFILE_INFO.BANK_CODE'
                                )}
                                onBlur={handleBlur}
                                error={touched.bankCode && 1}
                                onChange={handleChange}
                                value={values.bankCode}
                                errormessage={errors.bankCode}
                                optional={false}
                                disabled={true}
                              />
                            </div>

                            <div className="pt-2">
                              <Input
                                id="bankClearanceCode"
                                type="text"
                                name="bankClearanceCode"
                                placeholder={t(
                                  'PROFILE.PROFILE_INFO.BANK_CLEARANCE_CODE'
                                )}
                                onBlur={handleBlur}
                                error={touched.bankClearanceCode && 1}
                                onChange={handleChange}
                                value={values.bankClearanceCode}
                                optional={true}
                                errormessage={errors.bankClearanceCode}
                              />
                            </div>
                            <TextArea
                              id="bankAddress"
                              type="text"
                              name="bankAddress"
                              placeholder={t(
                                'PROFILE.PROFILE_INFO.BANK_ADDRESS'
                              )}
                              onBlur={handleBlur}
                              error={touched.bankAddress && 1}
                              onChange={handleChange}
                              value={values.bankAddress}
                              optional={1}
                              errormessage={errors.bankAddress}
                            />
                            <div className="pb-6">
                              <input
                                name="isPrimaryAccount"
                                type="checkbox"
                                onChange={() =>
                                  onAlternateChange(
                                    setFieldValue,
                                    'isPrimaryAccount'
                                  )
                                }
                                checked={values.isPrimaryAccount}
                              />{' '}
                              {t('PROFILE.PROFILE_INFO.PRIMARY')}
                            </div>
                          </div>
                          <div className="  mb-5">
                            <div className="lg:flex lg:justify-end text-end grid mt-10">
                              <button
                                type="submit"
                                onClick={submitForm}
                                className="bg-primary p-4 lg:px-10 lg:py-4 text-white rounded"
                              >
                                {t('PROFILE.PROFILE_INFO.SAVE')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBankingInfo;

/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IContactUs,
  IContactUsSlice,
} from '../clientInterfaces/authentication';
import { RootState } from '../store';

import { sendContactUs } from '../clientServices/contactUs';
import { showAlert } from '../slice/alert';

const initialState: IContactUsSlice = {
  isProcessingContactUsRequest: false,
  successMessage: '',
};
export const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    reset: (state) => {
      state.successMessage = '';
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(ContactUs.pending, (state) => {
        state.isProcessingContactUsRequest = true;
        state.successMessage = '';
      })
      .addCase(ContactUs.fulfilled, (state, action) => {
        state.isProcessingContactUsRequest = false;
        state.successMessage = action.payload;
      })
      .addCase(ContactUs.rejected, (state, action: any) => {
        state.isProcessingContactUsRequest = false;
        state.successMessage = '';
      });
  },
});

export const ContactUs = createAsyncThunk(
  'auth/conatct-us',
  async (userData: IContactUs, thunkAPI) => {
    try {
      const response = await sendContactUs(userData);
      // userData.history('/contact-us');
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

// export const { setCaptchaError } = authenticationSlice.actions;
export const { reset } = contactUsSlice.actions;
export const selectContactUs = (state: RootState) => state.contactUS;
export const conatctUSReducer = contactUsSlice.reducer;

import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {AboutUsPageBody} from '../../clientComponents/AboutUsBody';
import ReactGA from "react-ga4";

const AboutUsPage = () => {
  const [t] = useTranslation('common');
    ReactGA.send({ hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "About us "
    });

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="form-header">
        <AboutUsPageBody />
      </div>
    </>
  );
};

export default AboutUsPage;

import i18next from 'i18next';
import ReactDOM from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {store} from './store';
import common_ar from './translations/ar/common.json';
import common_en from './translations/en/common.json';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
    ar: {
      common: common_ar,
    },
  },
});



const localTimes = '14:17:00';

// Create a Date object with today's date and the local time
const localDate = new Date();
const [hours, minutes, seconds] = localTimes.split(':');
localDate.setHours(Number(hours), Number(minutes), Number(seconds));

// Get the UTC timestamp in milliseconds
const utcTimestamp = localDate.getTime();

// Create a new Date object with the UTC timestamp
const utcDate = new Date(utcTimestamp);

const persistor = persistStore(store);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { getDefaultValue } from '../../clientHelpers/utility';
import { registrationConst } from '../../coachConstants';
import { specializationValidationSchema } from '../../coachHelpers';
import { IFormikInterface } from '../../coachInterfaces/registrationInterface';
import {
  coachingCategoryList,
  coachingSpecializationList,
  languageList,
  specializationInformation,
} from '../../coachSlices/registrationSlice';
import { useAppSelector } from '../../hooks';
import Carousel from '../imageSlider';
import './index.css';

const Component = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { lang } = useAppSelector((state) => state.language);

  const [languageInputValue, setLanguageInputValueInputValue] = useState('');
  const [coachingCategoriesInputValue, setCoachingCategoriesInputValue] =
    useState('');
  const [
    coachingSpecializationInputValue,
    setCoachingSpecializationInputValue,
  ] = useState('');

  const {
    languageDropDown,
    coachingSpecializationDropDown,
    coachingCategoryDropDown,
    specializationInfo,
    personalInfo,
  } = useAppSelector((state) => state.registration);
  useEffect(() => {
    if (!personalInfo?.fullName)
      navigate(registrationConst.PATH_PERSONAL_DETAILS);
  }, [personalInfo]);
  useEffect(() => {
    if (coachingSpecializationDropDown?.length < 1) {
      dispatch(coachingSpecializationList());
    }
    if (coachingCategoryDropDown?.length < 1) {
      dispatch(coachingCategoryList());
    }
    if (languageDropDown?.length < 1) {
      dispatch(languageList());
    }
  }, [
    coachingCategoryDropDown?.length,
    coachingSpecializationDropDown?.length,
    dispatch,
    languageDropDown?.length,
  ]);

  let languageOption: { value: string; label: string; label2: string }[] = [];
  if (languageDropDown?.length > 0) {
    languageDropDown.forEach(
      (client: { nameInEnglish: string; nameInArabic: string }) => {
        let roleDate = { value: '', label: '', label2: '' };
        roleDate.value =
          lang === 'en' ? client.nameInEnglish : client.nameInEnglish;

        roleDate.label = client.nameInEnglish;
        roleDate.label2 = client.nameInArabic;
        languageOption.push(roleDate);
      }
    );
  }

  let categoryOption: { value: string; label: string; label2: string }[] = [];
  if (coachingCategoryDropDown?.length > 0) {
    coachingCategoryDropDown.forEach(
      (client: { categoryInEnglish: string; categoryInArabic: string }) => {
        let roleDate = { value: '', label: '', label2: '' };
        roleDate.value =
          lang === 'en' ? client.categoryInEnglish : client.categoryInEnglish;

        roleDate.label = client.categoryInEnglish;
        roleDate.label2 = client.categoryInArabic;
        categoryOption.push(roleDate);
      }
    );
  }

  let specializationOption: { value: string; label: string; label2: string }[] =
    [];
  if (coachingSpecializationDropDown?.length > 0) {
    coachingSpecializationDropDown[0].forEach((client: { name: string }) => {
      let roleDate = { value: '', label: '', label2: '' };
      roleDate.value = client.name;
      roleDate.label = client.name;
      specializationOption.push(roleDate);
    });
  }

  const handleLanguageInputChange = (event: any) => {
    setLanguageInputValueInputValue(event);
  };
  const handleCoachingCategoriesInputChange = (event: any) => {
    setCoachingCategoriesInputValue(event);
  };
  const handleCoachingSpecializationInputChange = (event: any) => {
    setCoachingSpecializationInputValue(event);
  };
  const handleDispatch = (data: any) => {
    // dispatch(specializationInformation(data));
    navigate(registrationConst.PATH_EDUCATIONAL_DETAILS);
  };

  return (
    <div className="registrationStep-container01">
      <div className="registrationStep-container03">
        <p className="step">{t('SPECIALIZATION_DETAILS.STEP03')}</p>
        <div className="progress_bar">
          <div className="bg-primary h-1 w-3/5"></div>
        </div>
        <h1 className="heading_large sm:heading_small">
          {t('SPECIALIZATION_DETAILS.SPECIALIZATION')}
        </h1>
        <p className="paragraph">{t('SPECIALIZATION_DETAILS.DESCRIPTION')}</p>
        <Formik
          initialValues={{
            language: specializationInfo?.language,
            coachingCategories: specializationInfo?.coachingCategories,
            coachingSpecialization: specializationInfo?.coachingSpecialization,
          }}
          validationSchema={specializationValidationSchema}
          onSubmit={handleDispatch}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleBlur,
            submitForm,
            setFieldValue,
            values,
          }: IFormikInterface) => {
            dispatch(specializationInformation(values));
            return (
              <Form>
                <div className="w-full mb-8">
                  <SelectDropdown
                    name="language"
                    isMulti={true}
                    options={languageOption}
                    placeholder={t('SPECIALIZATION_DETAILS.LANGUAGE')}
                    onBlur={handleBlur}
                    error={touched.language && 1}
                    onChange={(opt: any) => {
                      setFieldValue(
                        'language',
                        opt.map((item: any) => item.value)
                      );
                    }}
                    defaultValue={specializationInfo?.language?.map(
                      (ele: any) => {
                        return {
                          label: ele,
                          value: ele,
                          label2: getDefaultValue(ele, languageOption)[0]
                            ?.label2,
                        };
                      }
                    )}
                    getOptionLabel={(option: any) =>
                      lang === 'en' ? option.label : option.label2
                    }
                    errormessage={errors.language}
                    id="FormLanguage"
                    isValue={values.language?.length}
                    inputValue={languageInputValue}
                    onInputChange={handleLanguageInputChange}

                    // defaultValue={getDefaultValue(
                    //   values.gender,
                    //   genderOption
                    // )[0]}
                  />
                </div>
                <div className="w-full mb-8">
                  <SelectDropdown
                    id="FormCoachingCategories"
                    name="coachingCategories"
                    isMulti={true}
                    options={categoryOption}
                    placeholder={t('SPECIALIZATION_DETAILS.COACHING_CATEGORY')}
                    onInputChange={handleCoachingCategoriesInputChange}
                    onBlur={handleBlur}
                    error={touched.coachingCategories && 1}
                    onChange={(opt: any) => {
                      setFieldValue(
                        'coachingCategories',
                        opt.map((item: any) => item.value)
                      );
                    }}
                    // defaultValue={specializationInfo?.coachingCategories?.map(
                    //   (ele: any) => {
                    //     return {
                    //       label: ele,
                    //       value: ele,
                    //     };
                    //   }
                    // )}
                    defaultValue={specializationInfo?.coachingCategories?.map(
                      (ele: any) => {
                        return {
                          label: ele,
                          value: ele,
                          label2: getDefaultValue(ele, categoryOption)[0]
                            ?.label2,
                        };
                      }
                    )}
                    getOptionLabel={(option: any) =>
                      lang === 'en' ? option.label : option.label2
                    }
                    errormessage={errors.coachingCategories}
                    className=""
                    isValue={values.coachingCategories?.length}
                    inputValue={coachingCategoriesInputValue}
                  />
                </div>
                <div className="w-full mb-8">
                  <SelectDropdown
                    id="FormCoachingSpecialization"
                    isMulti={true}
                    isCreatable={true}
                    options={specializationOption}
                    dropDown={true}
                    name="coachingSpecialization"
                    placeholder={t(
                      'SPECIALIZATION_DETAILS.COACHING_SPECIALIZATION'
                    )}
                    onBlur={handleBlur}
                    error={touched.coachingSpecialization && 1}
                    onChange={(opt: any) => {
                      setFieldValue(
                        'coachingSpecialization',
                        opt.map((item: any) => item.value)
                      );
                    }}
                    defaultValue={specializationInfo?.coachingSpecialization?.map(
                      (ele: any) => {
                        return {
                          label: ele,
                          value: ele,
                        };
                      }
                    )}
                    errormessage={errors.coachingSpecialization}
                    className="indent-8"
                    onInputChange={handleCoachingSpecializationInputChange}
                    inputValue={coachingSpecializationInputValue}
                    controlShouldRenderValue={true}
                    inn={values.coachingSpecialization?.length}
                  />
                </div>
                <div className="lg:flex lg:justify-between grid py-4 mb-20 lg:mt-80 border-info lg:border-t-2">
                  <button
                    onClick={() =>
                      navigate(registrationConst.PATH_COACHING_DETAILS)
                    }
                    className="order-last lg:order-first cancel-btn mb-6 py-4 hover:bg-primary hover:border-transparent hover:text-white hover:lg:next-btn hover:next-btn-large"
                  >
                    {t('BACK')}
                  </button>
                  <div className="lg:flex lg:justify-start grid lg:w-1/4">
                    <button
                      type="submit"
                      onClick={submitForm}
                      className="lg:next-btn next-btn-large bg-primary"
                    >
                      {t('NEXT')}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Carousel />
    </div>
  );
};
const SpecializationDetails = styled(Component)``;

export default SpecializationDetails;

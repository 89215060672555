import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BodySearchView } from '../../clientComponents/searchViewBody';
// import Navigation from '../../components/navigation';

const SearchReultGridViewPage = () => {
  const [filterView, setFilterView] = useState(false);
  const [t] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>

      <BodySearchView
        filter={setFilterView}
        filterView={filterView}
        active={false}
      />
    </>
  );
};

export default SearchReultGridViewPage;

/* eslint-disable react/react-in-jsx-scope */
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../clientComponents/buttons';
import Input from '../../clientComponents/inputs';
import { signInValidationSchema } from '../../clientHelpers';
import {
  getCaptcha,
  selectAuthentication,
  verifyCaptcha,
} from '../../clientSlices/authentication';
import './index.css';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const {
    captchaText,
    isProcessingLoginRequest,
    isProcessingCaptchaImage,
    captchaError,
    captchaErrorTouched,
  } = useSelector(selectAuthentication);
  const [t] = useTranslation('common');
  const [params] = useSearchParams();
  const handleFormSubmit = (data: {
    captcha: string;
    email: string;
    password: string;
  }) => {
    if (data?.captcha) {
      dispatch(
        verifyCaptcha({
          ...data,
          history: navigate,
          user: params.get('user') || '',
        })
      );
    }
  };
  useEffect(() => {
    dispatch(getCaptcha());
  }, []);
  const [isTruncated, setIsTruncated] = useState(true);

  function TruncatedString({ string, maxLength }: any) {
    const truncatedString = string.slice(0, maxLength) + '...';

    const handleToggle = () => {
      setIsTruncated(!isTruncated);
    };

    return (
      <div>
        {isTruncated ? <span>{truncatedString}</span> : <span>{string}</span>}
        <span
          onClick={handleToggle}
          className="text-primary cursor-pointer font-normal text-12  font-general rtl:font-Almarai"
        >
          {isTruncated ? t('VIEW_MORE') : t('VIEW_LESS')}
        </span>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div
        className=" -z-10 h-screen w-full flex bg-no-repeat bg-cover px-5 pt-[100px]"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(21, 50, 67, 0.6), rgba(21, 50, 67, 0.6)),url(${'/assets/loginPageImage/login_image.jpg'})`,
        }}
      >
        <div className="login-page-container ">
          <h1 className="text-3xl font-bold font-satoshi rtl:font-Almarai text-secondary  mb-4 ">
            {t('LOGIN.WELCOME_BACK')}
          </h1>
          <p className="paragraph mb-4">
            <TruncatedString
              string={
                params.get('user') === 'CLIENT'
                  ? t('LOGIN.WELCOME_BACK_DESCRITPION')
                  : t('LOGIN.WELCOME_BACK_DESCRITPION_COACH')
              }
              maxLength={80}
            />
          </p>
          <p className="paragraph mb-10">
            {t('LOGIN.DONT_HAVE_ACCOUNT')}
            <span className="text-primary font-semibold text-16 lg:text-17 font-satoshi rtl:font-Almarai">
              <Link
                to={
                  params.get('user') === 'CLIENT'
                    ? '/client/registration'
                    : '/coach/registration/personal-details'
                }
              >
                &nbsp;
                {t('LOGIN.SIGN_UP_TEXT')}
              </Link>
            </span>
          </p>
          <Formik
            initialValues={{
              email: '',
              password: '',
              captcha: '',
            }}
            validationSchema={signInValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => {
              return (
                <Form>
                  <Input
                    id="FormEmail"
                    type="email"
                    name="email"
                    placeholder={t('LOGIN.EMAIL')}
                    onBlur={handleBlur}
                    error={touched.email && 1}
                    onChange={handleChange}
                    value={values.email}
                    errormessage={errors.email}
                  />
                  <Input
                    id="FormPassword"
                    type="password"
                    name="password"
                    placeholder={t('LOGIN.PASSWORD')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && 1}
                    value={values.password}
                    errormessage={errors.password}
                  />
                  <div className="captcha-container ">
                    <div className="input-width-captcha ">
                      <Input
                        id="FormCaptcha"
                        type="text"
                        name="captcha"
                        placeholder={t('LOGIN.ENTER_CAPTCHA')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          (touched.captcha && 1) || (captchaErrorTouched && 1)
                        }
                        value={values.captcha}
                        errormessage={errors.captcha || captchaError}
                        className="m-0"
                      />
                    </div>
                    <div className="input-width h-[46px] ">
                      <div className="w-full flex">
                        <div className=" captcha" id="vid">
                          {captchaText !== '' && (
                            <div dangerouslySetInnerHTML={captchaText} />
                          )}
                        </div>
                        <div
                          onClick={() => dispatch(getCaptcha())}
                          className=" captcha-refresh"
                        >
                          <div className="cursor-pointer captcha-refresh-inner">
                            <img
                              className={
                                isProcessingCaptchaImage
                                  ? 'animate-spin'
                                  : 'animate-none'
                              }
                              src="/assets/refresh-icon.svg"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-2">
                    <Button
                      type="submit"
                      className="btn-submit bg-primary  flex justify-center"
                    >
                      <>
                        {isProcessingLoginRequest && <div className="loader" />}
                        {t('LOGIN.LOGIN_TEXT')}
                      </>
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="flex lg:justify-end justify-center items-center mt-6">
            <Link
              to={
                params.get('user') === 'CLIENT'
                  ? '/forgot-password?user=CLIENT'
                  : '/forgot-password?user=COACH'
              }
              className="font-semibold text-xs font-general rtl:font-Almarai text-secondary"
            >
              {t('LOGIN.FORGOT_PASSWORD')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

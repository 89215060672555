import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  orderView,
  selectPayment,
  tabbyOrderCheck,
} from '../../clientSlices/payment';
import OverlayLoader from '../../components/overlayLoader';
import './index.scss';
import {useAppSelector} from "../../hooks";

const Order = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { lang } = useAppSelector((state) => state.language);
  const { orderData, isProcessingpayment } = useSelector(selectPayment);
  useEffect(() => {
    if (searchParams.get('payment_id'))
      dispatch(
        tabbyOrderCheck({
          id: searchParams.get('payment_id'),
          navigate: navigate,
        })
      );
    else if (searchParams.get('TrackId') && searchParams.get('cardBrand'))
      dispatch(
        orderView({
          trackId: searchParams.get('TrackId'),
          cardBrand: searchParams.get('cardBrand'),
        })
      );
  }, [searchParams]);
  return (
    <>
      {isProcessingpayment ? (
        <OverlayLoader loader={true} />
      ) : (
        <div className="bg-gray-100 height-payment flex justify-center items-center">
          <div className="bg-white p-6  md:mx-auto ">
            <div className="text-center">
              {(orderData?.paymentStatus?.toUpperCase() === 'COMPLETED' ||
                orderData?.paymentStatus?.toUpperCase() === 'AUTHORIZED' ||
                searchParams.get('status') === 'SUCCESS') && (
                <CheckCircleIcon sx={{ color: '#00a5ec', fontSize: 100 }} />
              )}
              {(orderData?.paymentStatus?.toUpperCase() === 'FAILED' ||
                orderData?.paymentStatus?.toUpperCase() === 'REJECTED') && (
                <CancelIcon sx={{ color: '#bc455e', fontSize: 100 }} />
              )}
              {orderData?.paymentStatus?.toUpperCase() === 'PENDING' && (
                <WatchLaterIcon sx={{ color: '#f5b239', fontSize: 100 }} />
              )}
              {orderData?.paymentStatus?.toUpperCase() === 'STARTED' && (
                <HourglassTopIcon sx={{ color: '#f5b239', fontSize: 100 }} />
              )}
              {orderData?.paymentStatus ||
              searchParams.get('status') === 'SUCCESS' ? (
                <>
                  <h3 className="md:text-2xl text-base text-gray-900 font-semibold rtl:font-Almarai text-center">
                    {t('PAYMENT.PAYMENT')} {getLangPaymentStatus(orderData?.paymentStatus,lang,searchParams.get('status'))}!
                  </h3>
                  {orderData?.paymentStatus === 'COMPLETED' ||
                  orderData?.paymentStatus === 'AUTHORIZED' ||
                  searchParams.get('status') === 'SUCCESS' ? (
                    <div className="text-gray-600 my-2 rtl:font-Almarai">
                      {t('PAYMENT.THANK_YOU')}
                    </div>
                  ) : (
                    ''
                  )}
                  <p className="rtl:font-Almarai">
                    {' '}
                    {t('PAYMENT.HAVE_GREAT_DAY')}
                  </p>
                </>
              ) : null}
              {localStorage.getItem('userType') === 'CLIENT' && (
                <div className="py-10 text-center">
                  <a
                    href="/purchase-history"
                    className="px-12 bg-indigo-600 rtl:font-Almarai hover:bg-indigo-500 text-black font-semibold py-3"
                  >
                    {t('PAYMENT.GO_TO')}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Order;
function getLangPaymentStatus(status: any,lang: any,prams: any) {
  if(["COMPLETED","AUTHORIZED"].includes(status) || prams === 'SUCCESS') {
    if(lang === 'ar') {
      return "نجحة عملية الدفع"
    } else {
      return status;
    }
  } else if (["FAILED","REJECTED"].includes(status)) {
    if(lang == 'ar') {
      return "تم رفض عملية الدفع"
    } else {
      return status;
    }
  } else {
    return status;
  }
}


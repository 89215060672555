import { sessionPayloadInterface } from '../coachInterfaces/sessionInterface';
import { get, post, postImageFileWithToken, put } from './apiService';

export const createSessionApi = async (data: sessionPayloadInterface) => {
  return await post('/sessions', data);
};
export const editSessionApi = async (
  data: sessionPayloadInterface,
  id: string
) => {
  return await put('/sessions/' + id, data);
};
export const saveSessionApi = async (data: {
  draftType: string;
  request: sessionPayloadInterface;
}) => {
  return await post('/coach-service-draft', data);
};
export const saveDraftSessionApi = async (
  data: {
    draftType: string;
    request: sessionPayloadInterface;
  },
  id: string
) => {
  return await put('/coach-service-draft/' + id, data);
};
export const ImageUploadSession = async (payload: any) => {
  return await postImageFileWithToken('/files', payload);
};
export const currency = async () => {
  return await get('/lists?name=CURRENCY');
};
export const months = async () => {
  return await get('/lists?name=SESSION_CREATION_MONTH_WISE');
};
export const hoursApi = async () => {
  return await get('/lists?name=SESSION_CREATION_HOUR_WISE');
};
export const timingApi = async () => {
  return await get('/lists?name=COACHING_TIMING');
};
export const categoriesApi = async () => {
  return await get('/categories');
};

export const categoriesTagApi = async () => {
  return await get('/category-tags');
};

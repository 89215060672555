import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalConstants from '../../clientConstants/modal/modalConstants';
import { modalState } from '../../clientSlices/modalSlice';
import {
  attendMeetingUser,
  getMeetingAuthentication,
  getSchedules,
  selectSchedule,
} from '../../clientSlices/schedule';
import Button from '../../coachComponents/buttons';
import { Image } from '../../components/Image';
import Modal from '../../components/modals';
import Tooltip from '../../components/tooltip/input';
import { useAppSelector } from '../../hooks';
import './index.css';
const ReactBigCalendar = (props: any) => {
  const { lang } = useAppSelector((state) => state.language);

  const localizer = momentLocalizer(moment);

  // Set the locale for the moment instance used by the calendar component
  moment.locale(lang === 'en' ? 'en' : 'ar');

  const [logoutOpen, setLogoutOpen] = useState(false);
  const logOutClose = () => {
    setLogoutOpen(false);
    dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: false }));
    setSelectedEvent(null);
    document.body.classList.remove('overflow-hidden');
  };
  const showLogoutModal = () => {
    setLogoutOpen(true);
    document.body.classList.add('overflow-hidden');
    dispatch(modalState({ key: ModalConstants.MODAL_CREATE, value: true }));
  };
  const [t, i18n] = useTranslation('common');

  const events = [
    {
      title: 'Event 1',
      start: new Date(2023, 4, 3, 10, 30, 30),
      end: new Date(2023, 4, 3, 12, 0),
      coach: 1,
    },
    {
      title: 'Event 2',
      start: new Date(2023, 3, 3, 10, 0),
      end: new Date(2023, 3, 3, 12, 0),
      coach: 2,
    },
  ];
  const handleEvent = (event: any) => {};
  const dispatch = useDispatch<any>();
  const [startOfMonthDate, setStartOfMonthDate] = useState(
    moment(new Date()).locale('en').format('YYYY-MM-DD')
  );
  useEffect(() => {
    findEndDate(moment(new Date()).locale('en').format('YYYY-MM-DD'));
  }, []);
  const [endOfMonthDate, setEndOfMonthDate] = useState(
    moment(
      new Date(
        Number(moment(new Date()).locale('en').format('YYYY')),
        Number(moment(new Date()).locale('en').format('MM')),
        0
      )
    ).locale('en').format('YYYY-MM-DD')
  );
  const findEndDate = (date: string) => {
    setStartOfMonthDate((value: string) => (value = date));
    const endDate = new Date(
      Number(moment(date).locale('en').format('YYYY')),
      Number(moment(date).locale('en').format('MM')),
      0
    );
    setEndOfMonthDate(
      (value: any) =>
        (value = moment(endDate).locale('en').format('YYYY-MM-DD'))
    );
  };

  useEffect(() => {
    const data = {
      startDate: moment(startOfMonthDate).locale('en').format('YYYY-MM-DD'),
      endDate: moment(endOfMonthDate).locale('en').format('YYYY-MM-DD'),
    };
    if (startOfMonthDate && endOfMonthDate) dispatch(getSchedules(data));
  }, [startOfMonthDate, endOfMonthDate]);

  const { scheduleData, meetingData, meetingLink } =
    useSelector(selectSchedule);
  const [scheduleEvent, setScheduleEvent] = useState();
  useEffect(() => {
    let fullData: any = [];
    let data: any = {};

    scheduleData.map((item: any) => {
      data = {};

      data['title'] = item.title;
      data['id'] = item.id;
      data['end'] = new Date(
        Number(
          moment(new Date(`${item.endTime}Z`))
            .locale('en')
            .format('YYYY')
        ),
        Number(
          moment(new Date(`${item.endTime}Z`))
            .locale('en')
            .format('MM')
        ) - 1,
        Number(
          moment(new Date(`${item.endTime}Z`))
            .locale('en')
            .format('DD')
        ),
        moment(new Date(`${item.endTime}Z`), 'HH:mm:ss')
          .locale('en')
          .hour(),
        moment(new Date(`${item.endTime}Z`), 'HH:mm:ss')
          .locale('en')
          .minute(),
        moment(new Date(`${item.endTime}Z`), 'HH:mm:ss')
          .locale('en')
          .second()
      );
      data['start'] = new Date(
        Number(
          moment(new Date(`${item.startTime}Z`))
            .locale('en')
            .format('YYYY')
        ),
        Number(
          moment(new Date(`${item.startTime}Z`))
            .locale('en')
            .format('MM')
        ) - 1,
        Number(
          moment(new Date(`${item.startTime}Z`))
            .locale('en')
            .format('DD')
        ),
        moment(new Date(`${item.startTime}Z`), 'HH:mm:ss')
          .locale('en')
          .hour(),
        moment(new Date(`${item.startTime}Z`), 'HH:mm:ss')
          .locale('en')
          .minute(),
        moment(new Date(`${item.startTime}Z`), 'HH:mm:ss')
          .locale('en')
          .second()
      );
      console.log('data', data)
      fullData.push(data);
    });
    setScheduleEvent((value) => (value = fullData));
  }, [scheduleData]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventSelect = (event: any) => {
    setSelectedEvent(event);
    dispatch(
      getMeetingAuthentication({
        id: event.id,
        showLogoutModal: showLogoutModal,
      })
    );
    //change this to meeting link success when api success
    showLogoutModal();
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };
  const handleChange = (event: any) => {
    if (event.end && event.start) {
      setEndOfMonthDate(
        (value: string) =>
          (value = moment(event.end).locale('en').format('YYYY-MM-DD'))
      );
      setStartOfMonthDate(
        (value: string) =>
          (value = moment(event.start).locale('en').format('YYYY-MM-DD'))
      );
    } else {
      setEndOfMonthDate(
        (value: string) =>
          (value = moment(event[event?.length - 1])
            .locale('en')
            .format('YYYY-MM-DD'))
      );
      setStartOfMonthDate(
        (value: string) =>
          (value = moment(event[0]).locale('en').format('YYYY-MM-DD'))
      );
    }
  };
  return (
    <>
      <Calendar
        localizer={localizer}
        events={scheduleEvent}
        onRangeChange={handleChange}
        // date={startOfMonthDate}
        startAccessor="start"
        onSelectEvent={handleEventSelect}
        endAccessor="end"
        style={{ height: 500 }}
        messages={{
          next: t(`SCHEDULE.NEXT`),
          previous: t(`SCHEDULE.BACK`),
          today: t(`SCHEDULE.TODAY`),
          month: t(`SCHEDULE.MONTH`),
          week: t(`SCHEDULE.WEEK`),
          day: t(`SCHEDULE.DAY`),
          agenda: t(`SCHEDULE.AGENDA`),
          showMore: (total) => `+${total} ${t(`SCHEDULE.MORE`)} `,
          noEventsInRange: t(`SCHEDULE.NO_EVENT`),
        }}
      />{' '}
      {logoutOpen && meetingLink ? (
        <AuthenticationModal
          setLogoutOpen={setLogoutOpen}
          logoutOpen={logoutOpen}
          logOutClose={logOutClose}
          meetingData={meetingData}
          meetingLink={meetingLink}
          dispatch={dispatch}
          selectedEvent={selectedEvent}
          t={t}
        />
      ) : null}
    </>
  );
};

const AuthenticationModal = ({
  logoutOpen,
  logOutClose,
  meetingData,
  meetingLink,
  dispatch,
  handleCoachNavigation,
  handleNavigation,
  selectedEvent,
  t,
}: any) => {
  const [userImage, setUserImage] = useState(-1);

  const going = (status: string) => {
    dispatch(
      attendMeetingUser({
        data: { goingStatus: status },
        schedule: meetingData.id,
        event: selectedEvent.id,
      })
    );
  };

  const handleCopy = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <Modal
      show={logoutOpen}
      logOutClose={logOutClose}
      titleChildren={
        <div className="flex flex-grow pl-6 pt-6 gap-4 w-[80%] bg-white z-50 rtl:pr-6">
          {/* <div className="w-10 h-10 bg-primary rounded"></div> */}
          <Image
            id={
              (meetingData?.sessionImageId as number) ||
              (meetingData?.imageId as number) ||
              (meetingData?.profilePicId as number)
            }
            bgImgUrl={'/assets/profile/avatar.svg'}
            bgClass={
              ' bg-[#F6F8F5] object-fill rounded-full flex justify-center items-center w-[455pxp] h-[45px]'
            }
            propClass={'w-[45px] h-[45px] object-cover rounded-full'}
          />
          <div className="max-w-[75%]">
            <div className="font-bold  font-satoshi rtl:font-Almarai text-22 truncate max-w-full ">
              {meetingData.title}
            </div>
            <div className="flex flex-row">
              {' '}
              <div className="font-general rtl:font-Almarai text-13 text-gray  font-normal">
                {moment(new Date(`${meetingData.scheduledDate}Z`)).locale('en').format(
                  'dddd, MMMM DD'
                )}
              </div>
              <div className="font-general rtl:font-Almarai text-13 text-gray font-normal flex justify-start ">
                &nbsp;
                {moment(new Date(`${meetingData.startTime}Z`)).locale('en').format(
                  'HH:mm'
                )}–{' '}
                {moment(new Date(`${meetingData.endTime}Z`)).format('HH:mm')}{' '}
                {localStorage.getItem('timezone')}
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className="mt-14 mx-[-12px] flex flex-col gap-6">
        {meetingLink?.joinMeetingUrl?.length && (
          <div className="flex gap-4 pt-6 md:pt-0">
            <div className="h-full flex items-start pt-3 w-10 justify-start">
              <img
                className="pt-0 pl-2"
                src="/assets/schedule/bigBlueButton.svg"
                alt=""
              />
            </div>

            <div className="flex flex-col gap-2 md:w-[50%] w-[80%] ">
              <Button
                children={
                  <div className="bg-primary text-16 font-general rtl:font-Almarai font-normal text-white px-6 py-4 rounded">
                    <a href={meetingLink.joinMeetingUrl} target='="_blank'>
                      {' '}
                      {t('SCHEDULE.JOIN_WITH')} BigBlueButton
                    </a>
                  </div>
                }
              />
              <div className="text-13 font-general rtl:font-Almarai font-normal text-gray w-[100%]">
                <Tooltip
                  content={t(`SCHEDULE.CLICK_TO_COPY`)}
                  position={'left-0'}
                  children={
                    <div
                      onClick={() => handleCopy(meetingLink?.joinMeetingUrl)}
                      className="w-[100%]  truncate hover:underline"
                    >
                      {meetingLink?.joinMeetingUrl}
                    </div>
                  }
                ></Tooltip>
              </div>
            </div>
          </div>
        )}
        <div className="flex   gap-4">
          <div className="w-10 flex justify-center">
            <img
              className="w-5 h-[14px]"
              src="/assets/schedule/guests.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col">
            {' '}
            <div className="font-semibold font-general rtl:font-Almarai text-13">
              {meetingData?.guestsResponses?.length} {t('SCHEDULE.GUESTS')}
            </div>
            <div className="font-general rtl:font-Almarai text-12 font-normal text-gray">
              {meetingData.yesCount ? (
                <span>
                  {meetingData.yesCount} {t('SCHEDULE.YES')}{' '}
                </span>
              ) : null}
              {meetingData.waitingCount ? (
                <span>
                  {meetingData.waitingCount} {t('SCHEDULE.WAITING')}{' '}
                </span>
              ) : null}
              {meetingData.rejectedCount ? (
                <span>
                  {meetingData.rejectedCount} {t('SCHEDULE.REJECTED')}
                </span>
              ) : null}
            </div>
            {meetingData.guestsResponses.map((item: any) => (
              <div className="flex gap-2 pt-4">
                {' '}
                <div className="relative ">
                  <Image
                    id={
                      (item?.imgId as number) ||
                      (item?.imageId as number) ||
                      (item?.profilePicId as number)
                    }
                    bgImgUrl={'/assets/profile/avatar.svg'}
                    bgClass={
                      ' bg-[#F6F8F5] object-fill rounded-full flex justify-center items-center w-[455pxp] h-[45px]'
                    }
                    propClass={'w-[45px] h-[45px] object-cover rounded-full'}
                  />
                  {item.goingStatus === 'YES' && (
                    <CheckCircleIcon
                      sx={{ color: '#00a5ec' }}
                      fontSize="small"
                      className="absolute top-[-4px] right-[-4px]"
                    />
                  )}
                  {item.goingStatus === 'WAITING' && (
                    <AccessTimeFilledIcon
                      sx={{ color: '#f5b239' }}
                      fontSize="small"
                      className="absolute top-[-4px] right-[-4px]"
                    />
                  )}
                  {item.goingStatus === 'REJECTED' && (
                    <HighlightOffIcon
                      sx={{ color: '#BC455E' }}
                      fontSize="small"
                      className="absolute top-[-4px] right-[-4px]"
                    />
                  )}
                </div>
                <div>
                  <div className="font-general rtl:font-Almarai text-13 font-semibold">
                    {item?.userName}
                  </div>
                  <div className="font-general rtl:font-Almarai text-10 font-normal text-gray">
                    {item?.userType}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="  flex items-center  gap-4">
          <div className="w-10 flex justify-center">
            <img
              className="w-4 h-[20px]"
              src="/assets/schedule/notification.svg"
              alt=""
            />
          </div>
          <div className="">
            <div className="font-general rtl:font-Almarai text-13 font-normal ">
              {t('SCHEDULE.TIME')}{' '}
            </div>
          </div>
        </div>
        <div className="  gap-4  flex items-center pb-6 border-b-[1px] border-gray">
          <div className="w-10 flex justify-center">
            <img
              className="w-[18px] h-[20px]"
              src="/assets/schedule/schedule.svg"
              alt=""
            />
          </div>
          <div className="">
            <div className="font-general rtl:font-Almarai text-13 font-normal">
              KUN {t('SCHEDULE.COACHING')}{' '}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center pb-2 px-3">
          <div className="text-13 font-general rtl:font-Almarai font-normal">
            {t('SCHEDULE.GOING')}
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => going('YES')}
              children={
                <div className="text-12 font-general rtl:font-Almarai font-normal border border-primary text-primary px-6 py-2 rounded ">
                  {t('SCHEDULE.YES')}
                </div>
              }
            />
            <Button
              onClick={() => going('REJECTED')}
              children={
                <div className="text-12 font-general rtl:font-Almarai font-normal border border-red text-red rtl:font-Almarai px-6 py-2 rounded ">
                  {t('SCHEDULE.NO')}
                </div>
              }
            />
          </div>
        </div>

        {/* <h2>{meetingData.title}</h2>
        <a href={meetingLink.joinMeetingUrl} target="_blank" rel="noreferrer">
          bbb
        </a> */}
      </div>
    </Modal>
  );
};
export default ReactBigCalendar;

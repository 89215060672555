import arabic from 'react-date-object/calendars/arabic';
import gregorian from 'react-date-object/calendars/gregorian';
import arabic_ar from 'react-date-object/locales/arabic_ar';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import { useAppSelector } from '../../coachHelpers/hooks';
import { IDateProps } from '../../coachInterfaces/inputInterFace/dateInterFace';

import './index.css';

const Date = ({
  className,
  onChange,
  onFocus,
  error,
  value,
  placeholder,
  name,
  errormessage,
  onBlur,
  id,
  optional,
  icon,
  defaultValue,
  dateClassName,
  ...rest
}: IDateProps) => {
  const attributes = {
    className,
    optional,
    onChange,
    error,
    value,
    name,
    errormessage,
    onBlur,
    id,
    defaultValue,
  };
  const [t] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);
  const image: any = document.getElementById(`image${id}`);
  const datepicker: any = document.getElementById(id as string);
  image?.addEventListener('click', function () {
    datepicker.focus();
  });
  return (
    <div {...rest} className="mb-8 relative calendar-div">
      {icon}
      <div className={`${(false || value) && ' change-placeholder '}`}>
        <DatePicker
          {...attributes}
          style={{
            width: '100%',
            boxSizing: 'border-box',
            border:
              errormessage !== undefined && error
                ? '1px solid #bc455e'
                : '1px solid #687E8E',
            height: '26px',
            padding: '22px 12px',
            color: 'var(--secondary)',
            fontFamily: 'General Sans',
            textIndent: `${icon ? '27px' : ''}`,
          }}
          containerStyle={{
            width: '100%',
          }}
          id={id}
          calendar={gregorian}
          locale={gregorian_en}
          calendarPosition="bottom-right"
          className={`font-general rtl:font-Almarai text-var(--secondary) placeholder-transperant `}
        />
      </div>
      <label
        className={`cursor-text font-general rtl:font-Almarai text-gray text-17  font-normal text-opacity-80 bg-white absolute ltr:left-3 rtl:right-3 top-2.5 px-1 transition duration-200 label ${
          icon && 'ltr:ml-7 rtl:mr-7'
        } ${icon ? 'datelabel' : ''}`}
      >
        {placeholder}
        {optional ? (
          ''
        ) : (
          <span className="text-red rtl:font-Almarai cursor-text">*</span>
        )}
      </label>
      <button
        type="button"
        className={`
        absolute top-[14px] ltr:right-3 rtl:top-[14px] rtl:left-[0.625rem] `}
      >
        <img
          id={`image${id}`}
          src="/assets/registrationPageImages/dateIcon.svg"
          alt="eye"
        />
      </button>
      {error && (
        <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
          {t(errormessage as string)}
        </p>
      )}
    </div>
  );
};

export default Date;

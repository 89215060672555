/* eslint-disable @typescript-eslint/no-empty-function */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

import {
  getMeetingLink,
  getMeetingPasscodeID,
  getScheduleList,
  goingMeeting,
} from '../clientServices/schedule';
import { showAlert } from '../slice/alert';

const initialState: any = {
  isProcessingSchedule: false,
  successMessage: '',
  scheduleData: [],
  scheduleTodayData: [],
  meetingData: {},
  meetingLink: '',
};
export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getSchedules.pending, (state) => {
        state.isProcessingSchedule = true;
        state.scheduleData = [];
      })
      .addCase(getSchedules.fulfilled, (state, action) => {
        state.isProcessingSchedule = false;
        state.scheduleData = action.payload.data[0];
      })
      .addCase(getSchedules.rejected, (state, action: any) => {
        state.isProcessingSchedule = false;
        state.scheduleData = [];
      })
      .addCase(getTodaySchedules.fulfilled, (state, action) => {
        state.isProcessingSchedule = false;
        state.scheduleTodayData = action.payload.data[0];
      })
      .addCase(getMeetingAuthentication.fulfilled, (state, action) => {
        state.isProcessingSchedule = false;
        state.meetingData = action.payload.data[0];
      })
      .addCase(meetingLive.fulfilled, (state, action) => {
        state.isProcessingSchedule = false;
        state.meetingLink = action.payload.data[0];
      })
      .addCase(attendMeetingUser.fulfilled, (state, action) => {
        state.isProcessingSchedule = false;
      });
  },
});
export const getSchedules = createAsyncThunk(
  'schedule/getschedule',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getScheduleList(param);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const getTodaySchedules = createAsyncThunk(
  'schedule/gettodayschedule',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getScheduleList(param);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const getMeetingAuthentication = createAsyncThunk(
  'schedule/meetingauthentication',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getMeetingPasscodeID(param.id);
      const payload = {
        meetingId: response.data[0].meetingId,
        password: response.data[0].password,
        meetingType: 'SCHEDULED_MEETING',
      };
      dispatch(meetingLive(payload));
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const meetingLive = createAsyncThunk(
  'schedule/meetingLive',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getMeetingLink(param);
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const attendMeetingUser = createAsyncThunk(
  'schedule/attendMeetingUser',
  async (param: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await goingMeeting(param.data, param.schedule);
      dispatch(
        getMeetingAuthentication({
          id: param.event,
          // showLogoutModal: showLogoutModal,
        })
      );
      return response;
    } catch (err: any) {
      dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return rejectWithValue(err);
    }
  }
);

// export const { setCaptchaError } = authenticationSlice.actions;
export const selectSchedule = (state: RootState) => state.schedule;
export const scheduleReducer = scheduleSlice.reducer;

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Image } from '../../components/Image';
import { Bestseller } from '../bestSeller';
import './index.css';
interface IRating {
  data: any;
  serviceType?: string;
}

const Deliverable: FC<IRating> = ({ data, serviceType }): JSX.Element => {
  const [count, setCount] = useState(false);
  const [right, setRight] = useState(false);
  const { t } = useTranslation('common');

  function Arrowss(props: any) {
    let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
    className += ' arrow';
    const char =
      props.type === 'next' ? (
        <div
          onMouseEnter={() => {
            setCount(true);
          }}
          onMouseLeave={() => {
            setCount(false);
          }}
          className="absolute top-[-61px] rtl:left-[0rem] right-[41px] bottom-[162px] w-7 hidden sm:flex hover:bg-[#02A4EA] bg-[#F5F5F5]  h-7 p-1 border-2 border-[#02A4EA] rounded justify-center"
        >
          <img
            src={
              count
                ? '/assets/clientCoachProfilePage/leftArrowWhite.svg'
                : '/assets/clientCoachProfilePage/leftArrow.svg'
            }
            alt=""
          ></img>
        </div>
      ) : (
        <div
          onMouseEnter={() => {
            setRight(true);
          }}
          onMouseLeave={() => {
            setRight(false);
          }}
          className="absolute right-0 rtl:left-[2rem] top-[-60px] bottom-[28px] z-10 w-7 sm:flex hidden hover:bg-[#02A4EA] bg-[#F5F5F5]  h-7 p-1 border-2 border-[#02A4EA] rounded justify-center"
        >
          <img
            src={
              right
                ? '/assets/clientCoachProfilePage/rightArrowWhite.svg'
                : '/assets/clientCoachProfilePage/rightArrow.svg'
            }
            alt=""
          ></img>
        </div>
      );
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    initialSlide: 0,
    arrows: true,
    centerMode: false,
    className: '!w-[100%] !h-[50%]',
    direction: 'ltr',
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    nextArrow: <Arrowss type="prev" />,
    prevArrow: <Arrowss type="next" />,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1210,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  // const [updatedData, setUpdatedData] = useState([]);
  // useEffect(() => {
  //   let updatee = data;
  //   const maxSlidesToShow = 5;
  //   if (updatee?.length > 0) {
  //     while (updatee?.length < maxSlidesToShow) {
  //       updatee = updatee.concat(updatee);
  //     }
  //   }
  //   setUpdatedData((value) => (value = updatee));
  // }, [data]);
  const navigate = useNavigate();
  const scrollAndNavigate = (item: any) => {
    window.scrollTo(0, 0);
    navigate(`/service-view?id=${item.id}&serviceType=${item?.serviceType}S`);
  };

  return (
    <div className="flex flex-row mt-12  ">
      <Slider {...settings}>
        {data?.map((item: any, index: any) => (
          <div
            onClick={() => scrollAndNavigate(item)}
            className="flex flex-row    shadow-lg relative !w-[95%]  !h-[100%] !mb-5 cursor-pointer"
            key={index}
          >
            {/* <img className="w-full" src={item.image} alt="" /> */}
            <Image
              id={
                (item?.imgId as number) ||
                (item?.imageId as number) ||
                (item?.profilePicId as number)
              }
              bgImgUrl={'/assets/default-img-icon.svg'}
              bgClass={
                ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[154px]'
              }
              propClass={'w-full h-[154px] rounded-t'}
            />
            <div className="p-2.5">
              <div className="text-[18px] truncate font-bold leading-[22px]  text-darkGrey ">
                {item.title}
              </div>
              <div className="text-[12px] font-xs leading-4 text-gray">
                {item.subTitle}
              </div>
              <div className="text-[12px] font-bold leading-4  text-darkGrey ">
                {item.danielWalterScott}
              </div>

              {/* <div className="flex flex-row justify-start items-center">
                <Rating value={item.rating} />
                <span className="text-[12px] font-xs leading-4 text-gray">
                  {item.ratings}ratings
                </span>
              </div> */}
              <div className="text-[12px] font-xs text-gray">
                {item?.duration ? `${item?.duration} ${t('MINS')}` : null}
              </div>
              <div className="flex justify-between">
                <div className="text-[24px] font-bold ">
                  <div className="font-bold text-2xl">
                    {`${t('SAR')} `}
                    {item.price}
                  </div>
                </div>

                <div className="flex justify-between">
                  <Bestseller />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Deliverable;

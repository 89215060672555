import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { getCoachingHoursValidation } from '../../clientHelpers/utility';
import { listLanguages } from '../../clientSlices/profile';
import Button from '../../coachComponents/buttons';
import CoachingHours from '../../coachComponents/createSession/coachingHours';
import CoachingPrice from '../../coachComponents/createSession/CoachPricing';
import IntendedClientsSession from '../../coachComponents/createSession/IntendedClients';
import CreateCoachSession from '../../coachComponents/createSession/SessionLanding';
import {
  coachingHoursPayload,
  convertTime12to24,
  daySelection,
  localToUTC,
} from '../../coachHelpers/utility';
import {
  coachingHourRequestsInterface,
  coachingHoursFormValueInterface,
  coachingPriceFormValueInterface,
  intendedClientFormValueInterface,
  sessionInterface,
  tagsInterface,
} from '../../coachInterfaces/sessionInterface';
import {
  coachHomeInfo,
  fetchSessionDraftById,
} from '../../coachSlices/coachHomeSlice';
import {
  fetchSessionById,
  myUploadInfo,
} from '../../coachSlices/myUploadSlice';
import {
  clearCoachingHoursFormData,
  clearCoachingPriceFormData,
  clearIntendedClientFormData,
  clearSessionFormData,
  clearSessionImageFileFormData,
  listCategories,
  listCategoriesTag,
  listCoachingTiming,
  listHour,
  listMonths,
  postCreateSession,
  postEditSession,
  saveCreateSession,
  saveDraftSession,
  sessionInfo,
} from '../../coachSlices/sessionSlice';
import Modal from '../../components/modals';
import { useAppSelector } from '../../hooks';
import { showAlert } from '../../slice/alert';
import './index.css';

const Component = () => {
  const sessionFormRef: any = useRef();
  const intendFormRef: any = useRef();
  const hoursFormRef: any = useRef();
  const priceFormRef: any = useRef();
  const dispatch = useDispatch<any>();
  const [closeToggle, setCloseToggle] = useState(false);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [t] = useTranslation('common');
  const [menu, setMenu] = useState<string>('SESSION_LANDING');
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [isCleared, setIsCleared] = useState<boolean>(false);
  const [sessionStartTime, setSessionStartTime] = useState('');
  const [sessionEndTime, setSessionEndTime] = useState('');

  const { lang } = useAppSelector((state) => state.language);
  const navigate = useNavigate();

  const [menuName, setMenuName] = useState<string>(
    lang === 'en' ? 'Session landing page' : 'صفحة الجلسة'
  );
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const {
    sessionFormValue,
    intendedClientFormValue,
    coachingHoursFormValue,
    coachingPriceFormValue,
    isProcessingCreateSession,
    isProcessingSaveSession,
    sessionImagefile,
    isProcessingEditSession,
  } = useSelector(sessionInfo);
  const { isProcessingSessionById } = useSelector(myUploadInfo);
  const { isProcessingfetchSessionDraftById } = useSelector(coachHomeInfo);

  useEffect(() => {
    dispatch(listLanguages());
    dispatch(listMonths());
    dispatch(listHour());
    dispatch(listCoachingTiming());
    dispatch(listCategories());
    dispatch(listCategoriesTag());
  }, []);
  useEffect(() => {
    const callEdit = async () => {
      setIsCleared(false);
      if (
        searchParams.get('form') === 'EDIT' ||
        searchParams.get('form') === 'DRAFT'
      ) {
        if (searchParams.get('id') && searchParams.get('form') === 'EDIT') {
          dispatch(fetchSessionById(searchParams.get('id') as string));
        }
        if (searchParams.get('id') && searchParams.get('form') === 'DRAFT') {
          dispatch(fetchSessionDraftById(searchParams.get('id') as string));
        }
      } else {
        dispatch(clearSessionFormData());
        dispatch(clearIntendedClientFormData());
        dispatch(clearCoachingHoursFormData());
        dispatch(clearCoachingPriceFormData());
        dispatch(clearSessionImageFileFormData());
        if (sessionFormRef.current) {
          sessionFormRef.current?.resetForm();
        }
        if (intendFormRef.current) {
          intendFormRef.current?.resetForm();
        }
        if (hoursFormRef.current) {
          hoursFormRef.current?.resetForm();
        }
        if (priceFormRef.current) {
          priceFormRef.current?.resetForm();
        }
      }
      setIsCleared(true);
    };
    callEdit();
  }, []);

  useEffect(() => {
    if (
      sessionFormValue.title !== '' ||
      (sessionFormValue.title?.length >= 2 &&
        sessionFormValue.title?.length <= 256) ||
      sessionFormValue.subTitle !== '' ||
      sessionFormValue.description !== '' ||
      sessionFormValue.duration !== '' ||
      sessionFormValue.retakePeriod !== '' ||
      sessionFormValue.language !== '' ||
      sessionFormValue.categoryTags.length !== 0 ||
      intendedClientFormValue.gender !== '' ||
      coachingPriceFormValue.price !== ''
    ) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [sessionFormValue, intendedClientFormValue, coachingPriceFormValue]);
  const getTagsCondition = (forWhom: string[], tags: tagsInterface[]) => {
    const isPresent = forWhom?.some((item: string) => item === 'Others');

    if (isPresent) {
      return tags?.length > 0 ? true : false;
    } else return true;
  };
  const handleSaveClick = () => {
    const payload = createSessionPayloadCreator(
      sessionFormValue,
      intendedClientFormValue,
      coachingHoursFormValue,
      coachingPriceFormValue
    );
    if (searchParams.get('id') && searchParams.get('form') === 'DRAFT') {
      dispatch(
        saveDraftSession({
          payload: payload,
          id: searchParams.get('id') as string,
          navigate: navigate,
        })
      );
    } else dispatch(saveCreateSession({ payload, navigate }));
  };
  const handleEditClick = () => {
    setCloseToggle(false);
    if (sessionFormRef.current) {
      sessionFormRef.current?.handleSubmit();
    }
    if (intendFormRef.current) {
      intendFormRef.current?.handleSubmit();
    }
    if (hoursFormRef.current) {
      hoursFormRef.current?.handleSubmit();
    }
    if (priceFormRef.current) {
      priceFormRef.current?.handleSubmit();
    }
    if (
      sessionFormValue.title !== '' &&
      sessionFormValue.title?.length >= 2 &&
      sessionFormValue.title?.length <= 256 &&
      sessionFormValue.subTitle !== '' &&
      sessionFormValue.descriptionValid &&
      sessionFormValue.description !== '' &&
      sessionFormValue.duration !== '' &&
      sessionFormValue.category !== '' &&
      sessionFormValue.retakePeriod !== ''
    ) {
      if (
        intendedClientFormValue.gender !== '' &&
        getTagsCondition(
          intendedClientFormValue.forWhom,
          intendedClientFormValue.tags
        )
      ) {
        if (
          getCoachingHoursValidation(
            coachingHoursFormValue,
            sessionFormValue?.duration
          )
        ) {
          if (coachingPriceFormValue.price !== '') {
            if (
              sessionImagefile.file !== '' ||
              sessionImagefile.fileId !== ''
            ) {
              const payload = createSessionPayloadCreator(
                sessionFormValue,
                intendedClientFormValue,
                coachingHoursFormValue,
                coachingPriceFormValue
              );

              dispatch(
                postEditSession({
                  data: payload,
                  id: searchParams.get('id') as string,
                  navigate: navigate,
                })
              );
            } else {
              setMenu('SESSION_LANDING');
              setMenuName(t('COACH_SESSION.SESSION_LANDING'));
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'Please add an image',
                  messageAr: 'الرجاء اضافة صورة',
                })
              );
            }
          } else {
            setMenu('PRICING');
            setMenuName(t('COACH_SESSION.PRICING'));
          }
        } else {
          setMenu('COACHING_HOURS');
          setMenuName(t('COACH_SESSION.COACHING_HOURS'));
        }
      } else {
        setMenu('INTENDED_CLIENTS');
        setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
      }
    } else {
      setMenu('SESSION_LANDING');
      setMenuName(t('COACH_SESSION.SESSION_LANDING'));
    }
  };
  const handleClick = () => {
    if (sessionFormRef.current) {
      sessionFormRef.current?.handleSubmit();
    }
    if (intendFormRef.current) {
      intendFormRef.current?.handleSubmit();
    }
    if (hoursFormRef.current) {
      hoursFormRef.current?.handleSubmit();
    }
    if (priceFormRef.current) {
      priceFormRef.current?.handleSubmit();
    }
    if (
      sessionFormValue.title !== '' &&
      sessionFormValue.title?.length >= 2 &&
      sessionFormValue.title?.length <= 256 &&
      sessionFormValue.subTitle !== '' &&
      sessionFormValue.descriptionValid &&
      sessionFormValue.description !== '' &&
      sessionFormValue.duration !== '' &&
      sessionFormValue.category !== '' &&
      sessionFormValue.retakePeriod !== ''
    ) {
      if (
        intendedClientFormValue.gender !== '' &&
        getTagsCondition(
          intendedClientFormValue.forWhom,
          intendedClientFormValue.tags
        )
      ) {
        if (
          getCoachingHoursValidation(
            coachingHoursFormValue,
            sessionFormValue?.duration
          )
        ) {
          if (coachingPriceFormValue.price !== '') {
            if (
              sessionImagefile.file !== '' ||
              sessionImagefile.fileId !== ''
            ) {
              const payload = createSessionPayloadCreator(
                sessionFormValue,
                intendedClientFormValue,
                coachingHoursFormValue,
                coachingPriceFormValue
              );

              dispatch(
                postCreateSession({
                  payload: {
                    payload: payload,
                    draft: searchParams.get('form') === 'DRAFT' ? 'DRAFT' : '',
                    id: searchParams.get('id')
                      ? (searchParams.get('id') as string)
                      : ('' as string),
                  },
                  navigate,
                })
              );
            } else {
              setMenu('SESSION_LANDING');
              setMenuName(t('COACH_SESSION.SESSION_LANDING'));
              dispatch(
                showAlert({
                  type: 'error',
                  message: 'Please add an image',
                  messageAr: 'الرجاء اضافة صورة',
                })
              );
            }
          } else {
            setMenu('PRICING');
            setMenuName(t('COACH_SESSION.PRICING'));
          }
        } else {
          setMenu('COACHING_HOURS');
          setMenuName(t('COACH_SESSION.COACHING_HOURS'));
        }
      } else {
        setMenu('INTENDED_CLIENTS');
        setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
      }
    } else {
      setMenu('SESSION_LANDING');
      setMenuName(t('COACH_SESSION.SESSION_LANDING'));
    }
  };
  const createSessionPayloadCreator = (
    sessionFormValue: sessionInterface,
    intendedClientFormValue: intendedClientFormValueInterface,
    coachingHoursFormValue: coachingHoursFormValueInterface,
    coachingPriceFormValue: coachingPriceFormValueInterface
  ) => {
    let whatWillLearn: string[] = [];
    let requirements: string[] = [];
    let benefits: string[] = [];
    let forWhom: string[] = [];

    intendedClientFormValue?.whatWillLearn?.map((item: string) => {
      if (item !== '') {
        whatWillLearn.push(item);
      }
    });
    intendedClientFormValue?.requirements?.map((item: string) => {
      if (item !== '') {
        requirements.push(item);
      }
    });
    intendedClientFormValue?.benefits?.map((item: string) => {
      if (item !== '') {
        benefits.push(item);
      }
    });
    intendedClientFormValue?.forWhom?.map((item: string) => {
      if (item !== null) {
        forWhom.push(item);
      }
    });
    if (
      intendedClientFormValue?.forWhom.some((item: string) => item === 'Others')
    ) {
      intendedClientFormValue?.tags?.map((item: tagsInterface) => {
        forWhom.push(item?.value);
      });
    }
    let coachingHourRequests: any = [];
    if (coachingHoursFormValue?.alternative === true) {
      coachingHoursFormValue?.coachingHourRequests?.map(
        (item: coachingHourRequestsInterface) => {
          if (item?.day !== '') {
            coachingHourRequests.push({
              day: daySelection(
                item?.day,
                convertTime12to24(`${item?.from} ${item?.localFromTime}`)
              ),
              startTime: localToUTC(
                convertTime12to24(`${item?.from} ${item?.localFromTime}`)
              ),
              endTime: localToUTC(
                convertTime12to24(`${item?.end} ${item?.locaEndTime}`)
              ),
            });
          }
        }
      );
    }
    if (coachingHoursFormValue?.alternative === false) {
      coachingHoursPayload(coachingHoursFormValue).map(
        (item: { day: string; startTime: string; endTime: string }) => {
          coachingHourRequests.push({
            day: daySelection(item?.day, item?.startTime),
            startTime: localToUTC(item?.startTime),
            endTime: localToUTC(item?.endTime),
          });
        }
      );
    }

    const payloadData = {
      ...coachingPriceFormValue,
      ...sessionFormValue,
      categoryTags: sessionFormValue?.categoryTags?.map(
        (item: tagsInterface) => {
          return item?.value;
        }
      ),
      intendedClientRequest: {
        gender: intendedClientFormValue?.gender,
        whatWillLearn: whatWillLearn,
        requirements: requirements,
        benefits: benefits,
        forWhom: forWhom,
      },
      coachingHourRequests: coachingHourRequests,
    };

    return payloadData;
  };

  const CoachSessionContent = [
    {
      title: t('COACH_SESSION.SESSION_LANDING'),
      value: 'SESSION_LANDING',

      icon: SchoolOutlinedIcon,
    },
    {
      title: t('COACH_SESSION.INTENDED_CLIENTS'),
      value: 'INTENDED_CLIENTS',

      icon: PeopleAltOutlinedIcon,
    },
    {
      title: t('COACH_SESSION.COACHING_HOURS'),
      value: 'COACHING_HOURS',

      icon: ScheduleOutlinedIcon,
    },
    {
      title: t('COACH_SESSION.PRICING'),
      value: 'PRICING',

      icon: SellOutlinedIcon,
    },
  ];

  const CoachSessionComponent = [
    {
      component: <CreateCoachSession sessionFormRef={sessionFormRef} />,
      value: 'SESSION_LANDING',
    },
    {
      component: <IntendedClientsSession intendFormRef={intendFormRef} />,
      value: 'INTENDED_CLIENTS',
    },
    {
      component: (
        <CoachingHours
          setStartTime={setSessionStartTime}
          setEndTime={setSessionEndTime}
          hoursFormRef={hoursFormRef}
        />
      ),
      value: 'COACHING_HOURS',
    },
    {
      component: <CoachingPrice priceFormRef={priceFormRef} />,
      value: 'PRICING',
    },
  ];
  const handleNextClick = () => {
    switch (menu) {
      case 'SESSION_LANDING':
        if (sessionFormRef.current) {
          sessionFormRef.current?.handleSubmit();
        }
        if (
          sessionFormValue.title !== '' &&
          sessionFormValue.title?.length >= 2 &&
          sessionFormValue.title?.length <= 256 &&
          sessionFormValue.subTitle !== '' &&
          sessionFormValue.description !== '' &&
          sessionFormValue.duration !== '' &&
          sessionFormValue.category !== '' &&
          sessionFormValue.retakePeriod !== ''
        ) {
          setMenu('INTENDED_CLIENTS');
          setMenuName(t('COACH_SESSION.INTENDED_CLIENTS'));
        }
        break;
      case 'INTENDED_CLIENTS':
        if (intendFormRef.current) {
          intendFormRef.current?.handleSubmit();
        }
        if (
          intendedClientFormValue.gender !== '' &&
          getTagsCondition(
            intendedClientFormValue.forWhom,
            intendedClientFormValue.tags
          )
        ) {
          setMenu('COACHING_HOURS');
          setMenuName(t('COACH_SESSION.COACHING_HOURS'));
        }

        break;
      case 'COACHING_HOURS':
        if (hoursFormRef.current) {
          hoursFormRef.current?.handleSubmit();
        }
        if (
          getCoachingHoursValidation(
            coachingHoursFormValue,
            sessionFormValue.duration
          )
        ) {
          setMenu('PRICING');
          setMenuName(t('COACH_SESSION.PRICING'));
        }
        break;
    }
  };
  const onCancelClick = () => {
    if (location.pathname === '/coach/coach-session') {
      navigate(-1);
    } else {
      navigate('/coach/create-session');
      if (sessionFormRef.current) {
        sessionFormRef.current?.resetForm();
      }
      dispatch(clearSessionFormData());
      dispatch(clearSessionImageFileFormData());
      if (intendFormRef.current) {
        intendFormRef.current?.resetForm();
      }
      dispatch(clearIntendedClientFormData());
      if (hoursFormRef.current) {
        hoursFormRef.current?.resetForm();
      }
      dispatch(clearCoachingHoursFormData());
      if (priceFormRef.current) {
        priceFormRef.current?.resetForm();
      }
      dispatch(clearCoachingPriceFormData());
    }
  };
  const buttonRef: any = useRef();
  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (
        mobileMenu &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setMobileMenu(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, [mobileMenu]);

  function changeMenu(value: string) {
    if(value === 'COACHING_HOURS') {
      if(sessionFormValue.duration === '') {
        dispatch(
            showAlert({
              type: 'error',
              message: 'Please set Duration and Time gap in Session landing page to set coaching hours',
              messageAr: 'الرجاء تحديد المدة و الفجوة الزمنية في صفحة الجلسة لتحديد ساعات الكوتشينج بشكل صحيح',
            })
        );
      }
    }
    setMenu(value);
  }

  return (
    <>
      <Helmet>
        <title>{t('KUN_COACH')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Modal
        show={closeToggle}
        logOutClose={() => {
          setCloseToggle(false);
        }}
      >
        <div
          // onClick={() => deleteAccount()}
          className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center gap-7 "
        >
          <img
            className="md:h-[73px] md:w-[73px] h-[60px] w-[60px]"
            src="/assets/clientProfile/closeAccount.svg"
            alt=""
          />
          <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-Almarai">
            {t('CLIENT_PROFILE.CONTINUE')}
          </div>
          <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12">
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => setCloseToggle(false)}
                    className="h-13 border  text-gray  font-satoshi rtl:font-Almarai font-bold text-18 flex flex-grow items-center justify-center rounded  w-[100%] md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CANCEL')}
                  </div>
                }
              />
            </div>
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => handleEditClick()}
                    className="h-13 bg-red text-white  font-satoshi rtl:font-Almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CONFIRM')}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="coach-session-container">
        <div className="coach-session-nav pb-5">
          <div>
            <div className="h-14 w-full p-5 mb-3 font-satoshi rtl:font-Almarai  font-bold text-2xl">
              {t('COACH_SESSION.SESSION')}
            </div>
            {CoachSessionContent.map(({ title, value, icon }) =>
              value === menu ? (
                <div className="h-14 w-full flex  flex-row ">
                  <div className="h-full w-[3px] bg-[#02A4EA]" />

                  <div className="h-full w-full  flex items-center    bg-[#00a5ec1a] rtl:font-Almarai">
                    <CustomIcon Icon={icon} color={'#153243'} />

                    {title}
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => changeMenu(value)}
                  className="h-14 w-full flex flex-row cursor-pointer"
                >
                  <div className="h-full w-[3px] " />
                  <div className="h-full w-full  flex items-center  rtl:font-Almarai">
                    <CustomIcon Icon={icon} color={'#687E8E'} />

                    {title}
                  </div>
                </div>
              )
            )}
          </div>
          <div>
            {searchParams.get('form') === 'EDIT' ? (
              <Button
                htmlFor="submitSessionButton"
                form="my-form"
                type="submit"
                onClick={() => setCloseToggle(true)}
                className="bg-primary text-white btn-small m-2 w-full flex justify-around h-fit overflow-hidden"
              >
                <>
                  {isProcessingEditSession && <div className="loader" />}
                  {t('UPDATE')}
                </>
              </Button>
            ) : (
              <>
                <Button
                  htmlFor="submitSessionButton"
                  form="my-form"
                  type="submit"
                  onClick={handleClick}
                  className="bg-primary 
                  imary flex justify-around text-white btn-small m-2 w-full h-fit overflow-hidden"
                >
                  <>
                    {isProcessingCreateSession && <div className="loader" />}
                    {t('COACH_SESSION.SUBMIT_REVIEW')}
                  </>
                </Button>
                {isSaveDisabled && (
                  <Button
                    onClick={handleSaveClick}
                    className="bg-[#9EE0E6] border-[#9EE0E6] flex justify-around text-black btn-small m-2 w-full h-fit overflow-hidden"
                  >
                    {isProcessingSaveSession && <div className="loader" />}
                    {t('COACH_SESSION.SAVE_DRAFT')}
                  </Button>
                )}
              </>
            )}
          </div>
          <Button
            onClick={() => onCancelClick()}
            className="btn-small m-2 w-full mb-6 text-secondary bg-white h-fit overflow-hidden min-h-[40px]"
          >
            {t('CREATE_SESSION.CANCEL')}
          </Button>
        </div>
        <div className="session-mobile-menu-container  relative">
          <div className="session-mobile-menu ">
            {t('COACH_SESSION.SESSION')}
            {!mobileMenu ? (
              <img
                ref={buttonRef}
                onClick={() => setMobileMenu(true)}
                className="mt-auto mb-auto cursor-pointer"
                src="/assets/homePageImages/menu-dots-icon.svg"
                alt="bell-icon"
              />
            ) : (
              <img
                ref={buttonRef}
                onClick={() => setMobileMenu(false)}
                className="mt-auto mb-auto cursor-pointer"
                src="/assets/subscriptionPage/plusIcon.svg"
                alt="bell-icon"
              />
            )}
          </div>
          {mobileMenu && (
            <div className="coach-session-dropdown-container shadow-xl ltr:right-0 rtl:left-0 z-100 cursor-pointer">
              {CoachSessionContent.map(
                ({ title, value, icon }) =>
                  value !== menu && (
                    <div
                      onClick={() => {
                        setMenu(value);
                        setMenuName(title);
                        setMobileMenu(false);
                      }}
                      className="h-10 border-b-[1px] border-[#38383840] w-full flex flex-row"
                    >
                      <div className="h-full w-[3px] " />
                      <div className="h-full font-general rtl:font-Almarai font-normal text-base w-full  flex items-center ">
                        <CustomIcon Icon={icon} color={'#687E8E'} />
                        {title}
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
          <div className="h-14 w-full mb-10 flex flex-row">
            <div className="h-full w-[3px] bg-[#02A4EA]" />
            <div className="h-full w-full px-3 flex items-center  bg-[#00a5ec1a]">
              <CustomIcon
                Icon={
                  CoachSessionContent.filter(
                    ({ value }: any) => menu === value
                  )[0].icon as any
                }
                color={'#153243'}
              />

              {menuName}
            </div>
          </div>
        </div>

        {isCleared ? (
          (isProcessingSessionById && searchParams.get('form') === 'EDIT') ||
          (isProcessingfetchSessionDraftById &&
            searchParams.get('form') === 'DRAFT') ? (
            <div className="">
              <div className=" session-form-container  justify-center items-center flex">
                <div className="loader" />
              </div>
            </div>
          ) : (
            <div className="lggmd:h-[calc(100vh-270px)] w-full flex  lggmd:overflow-y-scroll lggmd:p-5  lggmd:shadow-custom lggmd:rounded-lg ">
              {CoachSessionComponent.map(
                (item: any) => item.value === menu && item.component
              )}
            </div>
          )
        ) : null}
        <div className="mobile-btn-div">
          {menu !== 'PRICING' ? (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleNextClick}
              className="bg-white border-primary text-primary btn-small mt-6 w-full h-fit overflow-hidden"
            >
              {t('COACH_PACKAGE.NEXT')}
            </Button>
          ) : searchParams.get('form') !== 'EDIT' ? (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleClick}
              className="bg-primary text-white btn-small  w-full  h-fit overflow-hidden"
            >
              <>
                {isProcessingCreateSession && <div className="loader" />}
                {t('COACH_SESSION.SUBMIT_REVIEW')}
              </>
            </Button>
          ) : (
            <Button
              htmlFor="submitSessionButton"
              form="my-form"
              type="submit"
              onClick={handleEditClick}
              className="bg-primary text-white btn-small w-full flex justify-around h-fit overflow-hidden"
            >
              <>
                {isProcessingEditSession && <div className="loader" />}
                {t('UPDATE')}
              </>
            </Button>
          )}
          {isSaveDisabled && (
            <Button
              onClick={handleSaveClick}
              className="bg-[#9EE0E6] flex justify-around border-[#9EE0E6] text-black btn-small mt-2 w-full h-fit overflow-hidden"
            >
              {isProcessingSaveSession && <div className="loader" />}

              {t('COACH_SESSION.SAVE_DRAFT')}
            </Button>
          )}

          <Button
            onClick={() => onCancelClick()}
            className="btn-small mt-2 w-full mb-6 text-secondary bg-white h-fit overflow-hidden"
          >
            {t('CREATE_SESSION.CANCEL')}
          </Button>
        </div>
      </div>
    </>
  );
};
const CoachSession = styled(Component)``;

export default CoachSession;

export function CustomIcon({ Icon, color }: any) {
  return (
    <Icon
      sx={{
        color: color,
        marginLeft: '10px',
        marginRight: '10px',
      }}
    />
  );
}

import { ITextAreaProps } from '../../coachInterfaces/inputInterFace/textAreaProps';
import './index.scss';
const TextArea = ({
  className,
  onChange,
  error,
  value,
  placeholder,
  optional,
  name,
  errormessage,
  onBlur,
  type,
  id,
  rows,
  cols,
  maxLength,
  ...rest
}: ITextAreaProps) => {
  const attributes = {
    className,
    onChange,
    error,
    value,
    optional,
    placeholder,
    name,
    errormessage,
    onBlur,
    type,
    rows,
    cols,
    id,
    maxLength,
  };
  return (
    <div {...rest} className="relative cursor-pointer w-full mt-7 mb-2 ltr">
      <textarea
        {...attributes}
        id={id}
        rows={rows}
        cols={cols}
        className={
          className +
          ` text-area ${
            !optional &&
            error &&
            errormessage &&
            'border border-solid border-red'
          }`
        }
      />
      <label
        htmlFor={id}
        className="input-text cursor-text font-general rtl:font-Almarai  text-gray text-17  font-normal text-opacity-80 bg-white absolute ltr:left-3 rtl:right-3 top-2.5 px-1 transition duration-200 label"
      >
        {placeholder}
        {optional ? (
          ''
        ) : (
          <span className="text-red rtl:font-Almarai cursor-text ml-1">*</span>
        )}
      </label>
      <div className="">
        {error && (
          <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5">
            {errormessage}
          </p>
        )}
      </div>
    </div>
  );
};
export default TextArea;

/* eslint-disable react/react-in-jsx-scope */
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../clientComponents/buttons';
import Input from '../../clientComponents/inputs';
import { setNewPasswordValidationSchema } from '../../clientHelpers';
import { getCaptcha, setNewPassword } from '../../clientSlices/authentication';
import OverlayLoader from '../../components/overlayLoader';
import { useAppSelector } from '../../hooks';
import './index.css';

const SetNewPassword = () => {
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleFormSubmit = (data: any) => {
    data['token'] = searchParams.get('token');
    data['user'] = searchParams.get('user');
    dispatch(setNewPassword({ ...data, history: navigate }));
  };
  const { isSetNewPassword } = useAppSelector(
    (state) => state.clientAuthentication
  );
  useEffect(() => {
    dispatch(getCaptcha());
  }, []);
  const [t] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <OverlayLoader loader={isSetNewPassword} />
      <div
        className=" -z-10 h-screen w-full flex bg-no-repeat bg-cover "
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(21, 50, 67, 0.6), rgba(21, 50, 67, 0.6)),url(${'/assets/loginPageImage/login_image.jpg'})`,
        }}
      >
        <div className="login-container ">
          <h1 className="text-3xl font-bold font-satoshi rtl:font-Almarai text-secondary mt-4 mb-4 ">
            {t('SET_NEW_PASSWORD.SET_NEW_PASSWORD_HEADER')}
          </h1>
          <p className="paragraph mb-4">
            {t('SET_NEW_PASSWORD.SET_NEW_PASSWORD_DESCRIPTION')}
          </p>
          <Formik
            initialValues={{
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={setNewPasswordValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form>
                <Input
                  id="FormNewPassword"
                  type="password"
                  name="newPassword"
                  placeholder={t('SET_NEW_PASSWORD.PASSWORD')}
                  onBlur={handleBlur}
                  error={touched.newPassword && 1}
                  onChange={handleChange}
                  value={values.newPassword}
                  errormessage={errors.newPassword}
                />
                <Input
                  id="FormConfirmPassword"
                  type="password"
                  name="confirmPassword"
                  placeholder={t('SET_NEW_PASSWORD.RE_ENTER_PASSWORD')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirmPassword && 1}
                  value={values.confirmPassword}
                  errormessage={errors.confirmPassword}
                />
                <div className="flex justify-center items-center mt-2">
                  <Button type="submit" className="btn-submit bg-primary">
                    {t('SET_NEW_PASSWORD.SET_NEW_PASSWORD_SUBMIT')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SetNewPassword;

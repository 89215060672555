import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clearStorage } from '../../coachHelpers/localStorage';
import { IUpdatePasswordPayload } from '../../coachInterfaces/passwordInterface';
import { IAccountSecurityInitialInterface } from '../../coachInterfaces/profileInterface';
import {
  deleteCoach,
  getSocialMedia,
  postAccountSecurity,
} from '../../coachServices/authenticationService';
import { showAlert } from '../../slice/alert';

const initialState: IAccountSecurityInitialInterface = {
  isAccountDelete: false,
  isBankingInfoUpdateSuccess: false,
  bankingDetails: {},
};

export const accountSecuritySlice = createSlice({
  name: 'accountSecurity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(updateAccountSecurity.pending, (state) => {
        state.isBankingInfoUpdateSuccess = false;
      })
      .addCase(updateAccountSecurity.fulfilled, (state, action) => {
        state.isBankingInfoUpdateSuccess = true;
      })
      .addCase(updateAccountSecurity.rejected, (state) => {
        state.isBankingInfoUpdateSuccess = true;
      })

      .addCase(getAccountSecurity.pending, (state) => {})
      .addCase(getAccountSecurity.fulfilled, (state, action) => {
        state.bankingDetails = action?.payload?.data[0];
      })
      .addCase(getAccountSecurity.rejected, (state) => {
        state.bankingDetails = [];
      })
      .addCase(deleteCoachAccount.pending, (state) => {
        state.isAccountDelete = false;
      })
      .addCase(deleteCoachAccount.fulfilled, (state, action) => {
        state.isAccountDelete = true;
      })
      .addCase(deleteCoachAccount.rejected, (state) => {
        state.isAccountDelete = false;
      });
  },
});

//updateTradeRegistration APIS

export const updateAccountSecurity = createAsyncThunk(
  'updateSocialMedia',
  async (userData: IUpdatePasswordPayload, thunkAPI) => {
    try {
      const response = await postAccountSecurity(userData);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//getTradeRegistration APIS

export const getAccountSecurity = createAsyncThunk(
  'getAllSocialMedia',
  async (_, thunkAPI) => {
    try {
      const response = await getSocialMedia();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteCoachAccount = createAsyncThunk(
  'auth/delete/client',
  async (userData: { id: number; history: any }, thunkAPI) => {
    try {
      const response = await deleteCoach(userData.id);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      clearStorage();
      userData.history('/');
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const accountSecurityReducer = accountSecuritySlice.reducer;

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../clientHelpers/hooks';
import { languageSelect } from '../../coachSlices/headerSlice';

const DropdownWithoutLogin = ({ showLogoutModal }: any) => {
  const popup: any = document.querySelector('.popup');
  const [t, i18n] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useAppDispatch();

  function hidePopup() {
    popup?.classList?.remove('open');
  }
  const handleChange = (value: string) => {
    if (value === 'ar') {
      i18n.changeLanguage('ar');
      if (lang !== value) {
        dispatch(languageSelect(value));
      }

      document.querySelector('html')?.setAttribute('dir', 'rtl');
    } else if (value === 'en') {
      i18n.changeLanguage('en');
      dispatch(languageSelect(value));
      document.querySelector('html')?.setAttribute('dir', 'ltr');
    }
  };

  return (
    <div
      className="w-60 h-[18rem] bg bg-white absolute bottom-[-98px]  top-[102px] pt-2.5 rtl:xl:left-[110px] ltr:xl:right-[97px] ltr:right-0   rtl:left-0 "
      onClick={() => hidePopup()}
    >
      <div className="flex pl-2.5 pr-2.5 pb-2.5  "></div>
      <Link to="/">
        <div className="pb-2.5 pl-2 text-start text-base font-normal leading-[21px] text-darkGrey pt-4 hover:bg-lightBlack rtl:pr-3 rtl:font-Almarai">
          {t('LANDING_PAGE_AFTER_LOGIN.HOME')}
        </div>
      </Link>
      <Link to="/about-us">
        <div className="pb-2.5 pl-2 text-start text-base font-normal leading-[21px] text-darkGrey  hover:bg-lightBlack rtl:pr-3 rtl:font-Almarai">
          {t('ABOUT_US_PAGE.ABOUT_US')}
        </div>
      </Link>
      <Link to="/contact-us">
        <div className="pb-2.5 pl-2 text-start text-base font-normal leading-[21px] text-darkGrey  hover:bg-lightBlack rtl:pr-3 border-b-[1px] rtl:font-Almarai border-lightBlack">
          {t('ABOUT_US_PAGE.CONTACT')}
        </div>
      </Link>

      <div className="m-3">
        <button
          className="w-full border-[1px] text-darkGrey h-8 rtl:font-Almarai"
          onClick={() => showLogoutModal('Login')}
        >
          {t('ABOUT_US_PAGE.LOGIN')}
        </button>
      </div>
      <div className="m-3">
        <button
          className="w-full bg-lightBlue text-white h-8 rtl:font-Almarai"
          onClick={() => showLogoutModal('Sign up')}
        >
          {t('ABOUT_US_PAGE.SIGN_UP')}
        </button>
      </div>
      <div className="flex justify-center ">
        {lang === 'en' ? (
          <img
            src="/assets/headerImage/dark-ar.svg"
            alt="logo"
            onClick={() => handleChange('ar')}
          />
        ) : (
          <img
            src="/assets/headerImage/dark-en.svg"
            alt="ar"
            onClick={() => handleChange('en')}
          />
        )}
      </div>
    </div>
  );
};

export default DropdownWithoutLogin;

import axios from 'axios';
import {
  getFormFileHeader,
  getHeader,
  getHeaderInfo,
} from '../clientHelpers/apiHeaderCreator';
import {getAccessToken, getLang} from '../clientHelpers/localStorage';
import { env } from '../config/env';

const handleResponse = (response: any) => {
  if (response.status === 401) {
    localStorage.clear();
    window.location.replace('/');
    return Promise.reject(response.data);
  }
  if (response.data.success) {
    return response.data;
  }
  if (response.status === 200) {
    return response.data;
  }
  return Promise.reject(response.data);
};
export const post = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  const token = await getAccessToken();
  let resp;

  try {
    if (token)
      resp = await axios.post(env.development.BASE_URL + url, body, header);
    else resp = await axios.post(env.development.BASE_URL + url, body);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const patch = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.patch(
      env.development.BASE_URL + url,
      body,
      header
    );
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const get = async (url: string, params: any = {}) => {
  const header = await getHeaderInfo();
  const token = await getAccessToken();
  let resp;
  try {
    if (token) {
      resp = await axios.get(env.development.BASE_URL + url, {
        ...header,
        params,
      });
    } else {
      const lang = await getLang();

      resp = await axios.get(env.development.BASE_URL + url, {
        headers: {
            'Language': lang,
        },
        params,
      });
    }
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const deleteApi = async function (url: string, params: any = {}) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.delete(env.development.BASE_URL + url, {
      ...header,
      params,
    });

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
export const put = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.put(env.development.BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getImageApi = async (url: any, params: any = {}) => {
  try {
    const resp: any = await axios.get(env.development.BASE_URL + url, {
      responseType: 'arraybuffer',
    });

    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const postProfileFile = async function (uploadFile: any, type: string) {
  const header = await getFormFileHeader();
  try {
    const formData: any = new FormData();
    formData.append('file-type', type);
    formData.append('file', uploadFile[0]);
    const res = await axios.post(
      `${env.development.BASE_URL}/files`,
      formData,
      header
    );
    return handleResponse(res);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getFile = async (url: string, params: any = {}) => {
  const header = await getHeader();
  const token = await getAccessToken();
  let resp;
  try {
    if (token) {
      resp = await axios.get(env.development.BASE_URL + url, {
        ...header,
        responseType: 'arraybuffer',
      });
    } else {
      resp = await axios.get(env.development.BASE_URL + url, {
        params,
      });
    }
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Button from '../../clientComponents/buttons';
import Pagination from '../../clientComponents/pagination';
import { getAccessToken } from '../../clientHelpers/localStorage';
import ClientsCard from '../../coachComponents/performance/clientsCard';
import ClientsTable from '../../coachComponents/performance/clientsTable';
import OverviewCard from '../../coachComponents/performance/overviewcard';
import OverviewTable from '../../coachComponents/performance/overviewTable';
import TransactionCard from '../../coachComponents/performance/transactionCard';
import TransactionsTable from '../../coachComponents/performance/transactionsTable';
import { decodeUserId } from '../../coachHelpers/localStorage';
import {
  getCoachPerformanceCounts,
  getCoachPerformanceOverview,
  getCoachRevenueClients,
  getCoachReview,
  getTransactionsDetails,
  performanceInfo,
  setClientDayfilter,
  setClientServiceStatusFilter,
  setClientsPage,
  setOverviewDayfilter,
  setOverviewPage,
  setReviewPage,
  setTransactionsDetailsPage,
  withdrawMoney,
} from '../../coachSlices/coachPerformance';
import { useAppSelector } from '../../hooks';

const CoachPerformance = () => {
  const [navigateState, setNavigationState] = useState('overview');
  const [navigationBuffer, setNavigationBuffer] = useState<string[]>([]);
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [t] = useTranslation('common');
  const [toggle, setToggle] = useState(false);
  const buttonRef: any = useRef();

  const {
    coachCounts,
    coachOverView,
    isProcessingCoachOverview,
    coachRevenueClients,
    isProcessingCoachRevenueClients,
    clientsFilter,
    overviewFilter,
    clientTotalPages,
    overviewTotalPages,
    reviewFilter,
    transactionFilter,
    isTransaction,
    transactionDetails,
  } = useSelector(performanceInfo);

  const { personalDetails } = useAppSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getCoachPerformanceCounts());
    handleResize();
  }, []);
  useEffect(() => {
    dispatch(getCoachRevenueClients(clientsFilter));
  }, [clientsFilter]);
  useEffect(() => {
    dispatch(getCoachPerformanceOverview(overviewFilter));
  }, [overviewFilter]);
  useEffect(() => {
    dispatch(getCoachReview(reviewFilter));
  }, [reviewFilter]);
  useEffect(() => {
    dispatch(getTransactionsDetails(transactionFilter));
  }, [transactionFilter]);

  useEffect(() => {
    const page = searchParams.get('page') || 0;
    if (navigateState === 'clients') {
      dispatch(setClientsPage(page as number));
    } else if (navigateState === 'overview') {
      dispatch(setOverviewPage(page as number));
    } else if (navigateState === 'review') {
      dispatch(setReviewPage(page as number));
    } else if (navigateState === 'transactions') {
      dispatch(
        setTransactionsDetailsPage({ page: page, id: personalDetails[0]?.id })
      );
    }
  }, [searchParams, navigateState]);
  const navigation = [
    { header: t('PREFORMANCE.OVERVIEW'), value: 'overview' },
    { header: t('PREFORMANCE.CLIENTS'), value: 'clients' },
    { header: t('PREFORMANCE.TRANSACTIONS'), value: 'transactions' },
    // { header: 'Review', value: 'review' },
  ];
  const data = [
    {
      header: t('PREFORMANCE.TOTAL_REVENUE'),
      value1: `${t('SAR')} ${coachCounts?.totalRevenue}`,
      value2: `${t('SAR')} ${coachCounts?.lastMonthTotalRevenue}`,
    },
    {
      header: t('PREFORMANCE.TOTAL_CLIENTS'),
      value1: coachCounts?.totalClientCount,
      value2: coachCounts?.lastMonthClientCount,
    },
    {
      header: t('PREFORMANCE.ACTIVE_CLIENTS'),
      value1: coachCounts?.activeClientCount,
      value2: coachCounts?.lastMonthActiveClientCount,
    },
  ];

  const clientFilterList = [
    {
      label: t('PREFORMANCE.ALL'),
      value: '',
    },
    {
      label: t('PREFORMANCE.ONGOING_SESSION'),
      value: 'ONGOING_SESSION',
    },
    {
      label: t('PREFORMANCE.ONGOING_PACKAGE'),
      value: 'ONGOING_PACKAGE',
    },
    {
      label: t('PREFORMANCE.ONGOING_ASSESSMENT'),
      value: 'ONGOING_ASSESSMENT',
    },
    {
      label: t('PREFORMANCE.CLOSED'),
      value: 'CLOSED',
    },
  ];
  const filterList = [
    {
      label: t('PREFORMANCE.7_DAYS'),
      value: 'LAST_SEVEN_DAYS',
    },
    {
      label: t('PREFORMANCE.1_MONTH'),
      value: 'LAST_ONE_MONTH',
    },
    {
      label: t('PREFORMANCE.3_MONTHS'),
      value: 'LAST_THREE_MONTHS',
    },
    {
      label: t('PREFORMANCE.6_MONTHS'),
      value: 'LAST_SIX_MONTHS',
    },
    {
      label: t('PREFORMANCE.1_YEAR'),
      value: 'LAST_ONE_YEAR',
    },
  ];
  const handleResize = () => {
    if (window.screen.width <= 320) {
      let temp: string[] = [];
      navigation?.map((item, index) => {
        if (index > 1) {
          temp.push(item.value);
        }
      });
      setNavigationBuffer(temp);
    } else {
      setNavigationBuffer([]);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);
  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (
        toggle &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setToggle(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, [toggle]);
  const handleNavigationBuffer = (value: string) => {
    setNavigationState(value);
    const temp = [...navigationBuffer];
    let flag = 0;
    navigation.map((item, index) => {
      if (value !== item?.value && flag < 1) {
        temp[temp?.indexOf(value)] = item?.value;
        flag++;
      }
    });
    // ;

    setNavigationBuffer(temp);
  };
  const userId = getAccessToken() && decodeUserId(getAccessToken() as string);

  const withdraw = () => {
    dispatch(withdrawMoney(userId));
  };
  return (
    <>
      <Helmet>
        <title>{t('KUN_COACH')}</title>
      </Helmet>
      <div className="xl:mx-[200px] lg:mx-[100px] mx-5 mb-14">
        <div className="md:text-34 text-22 font-bold font-satoshi rtl:font-Almarai mt-6 md:mt-14 md:mb-9 mb-8">
          {t('PREFORMANCE.PREFORMANCE')}
        </div>
        <ul className=" flex flex-row gap-10  border-b border-disableGray mb-2 ">
          {navigation.map((value, index) => (
            <li
              onClick={() => {
                const page: any = 0;
                setSearchParams({ page });
                setNavigationState(value.value);
              }}
              key={index}
              className={`${
                value.value === navigateState
                  ? 'border-b-[4px] border-primary text-primary pb-2  transition  duration-300 ease-in-out '
                  : 'text-gray cursor-pointer'
              } md:text-20 text-18 font-bold font-satoshi rtl:font-Almarai ${
                !navigationBuffer?.includes(value.value)
                  ? ''
                  : window.screen.width <= 320 && 'hidden'
              } `}
            >
              {value.header}
            </li>
          ))}
          <div
            ref={buttonRef}
            className="flex justify-center cursor-pointer sm:hidden relative"
          >
            <img
              onClick={() => setToggle(!toggle)}
              className=" rotate-90 cursor-pointer"
              src="/assets/threeDots.svg"
              alt=""
            />
            <div className={` flex flex-col ${!toggle && 'hidden'}`}>
              {navigationBuffer?.map((item, index) => (
                <div
                  onClick={() => (
                    handleNavigationBuffer(item), setToggle(false)
                  )}
                  className=" absolute bg-white rounded top-6 right-3 shadow-lg p-2  font-satoshi rtl:font-Almarai "
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </ul>
        {navigateState === 'clients' && (
          <div className="w-full overflow-x-scroll flex flex-row gap-3">
            {clientFilterList.map((item) => (
              <div
                onClick={() =>
                  dispatch(setClientServiceStatusFilter(item.value))
                }
                className={
                  clientsFilter.serviceStatusFilter === item.value
                    ? ' w-fit flex justify-center py-2 px-4 rounded  font-normal text-13 font-general rtl:font-Almarai pt-2 px-4 bg-primary text-[#FFFFFF] cursor-pointer'
                    : ' bg-[#ffffff] text-black border w-fit flex justify-center py-2 px-4 rounded border-disableGray font-normal text-13 font-general rtl:font-Almarai pt-2 px-4 cursor-pointer'
                }
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
        {/* {navigateState !== 'review' ? ( */}
        <>
          <div className="border border-disableGray rounded-lg shadow-lg md:mt-12 mt-8">
            <div
              className={`${
                navigateState === 'transactions' && 'hidden'
              } flex flex-col w-full  items-center md:border-b border-disableGray`}
            >
              <div className="flex md:flex-row flex-col justify-between w-full md:px-8 md:py-4 mx-3  pt-3 gap-3 md:gap-0">
                <div className="md:w-fit w-full px-3 md:px-0">
                  <ul className="flex flex-row gap-12  w-full md:w-fit justify-between ">
                    {data.map((value) => (
                      <li className="flex flex-col gap-2">
                        <div className="font-bold md:text-14 text-12 text-gray font-satoshi rtl:font-Almarai sm:h-10">
                          {value.header}
                        </div>
                        <div className="font-bold md:text-28 text-24 font-satoshi rtl:font-Almarai  sm:whitespace-nowrap ">
                          {value.value1}
                        </div>
                        <div className="font-normal md:text-13 text-11 text-gray rtl:font-Almarai ">
                          {value.value2} {t('PREFORMANCE.THIS_MONTH')}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className={`${
                    navigateState !== 'overview' && 'hidden'
                  } md:py-0 py-3 px-3 gap-2 sm:gap-0 border-t border-disableGray md:border-none w-full md:w-full h-full     flex justify-between flex-grow  `}
                >
                  {' '}
                  <div className="h-full flex justify-start flex-grow md:px-6 md:min-h-[17vh]">
                    <li className="flex flex-col gap-2 h-full">
                      <div className="font-bold md:text-14 text-12 text-gray font-satoshi rtl:font-Almarai md:h-10">
                        {t('PREFORMANCE.BALANCE_VALUE')}
                      </div>
                      <div className="font-bold md:text-28 text-24 font-satoshi rtl:font-Almarai  ">
                        {t('SAR')}&nbsp;
                        {coachCounts?.totalWithdrawalAmount}
                      </div>
                    </li>
                  </div>
                  <div className="flex full  items-center">
                    {coachCounts?.totalWithdrawalAmount >= 500 ? (
                      <div className="flex flex-col">
                        <Button
                          className={''}
                          onClick={withdraw}
                          children={
                            <div className="font-satoshi rtl:font-Almarai md:text-18 text-12 px-2 md:py-4 py-3 bg-primary rounded text-white sm:px-5 ">
                              {t('PREFORMANCE.WITHDRAW')}
                            </div>
                          }
                        />
                        <span className="font-normal text-12 pt-1.5 font-satoshi rtl:font-Almarai">
                          {t('PREFORMANCE.COST')}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-5 items-center justify-end px-8 md:flex hidden  w-full py-5 border-t border-disableGray">
                <div className="text-13 font-general rtl:font-Almarai text-gray">
                  {' '}
                  {t('PREFORMANCE.DATE_RANGE')}
                </div>
                <select
                  onChange={(e) => {
                    // navigateState === 'clients'
                    //   ? dispatch(setClientDayfilter(e.target.value))
                    //   : dispatch(setOverviewDayfilter(e.target.value))
                    dispatch(setClientDayfilter(e.target.value));
                    dispatch(setOverviewDayfilter(e.target.value));
                  }}
                  className="rtl:font-Almarai bg-white h-[38px] md:w-[158px] w-full border border-disableGray text-[#153243] text-sm rounded font-semibold"
                >
                  <option defaultChecked selected value={''}>
                    {t('PREFORMANCE.ALL')}
                  </option>
                  {filterList.map((item, index) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                {/* <div className='border flex justify-center py-2 px-4 rounded border-disableGray font-semibold text-13 font-general rtl:font-Almarai pt-2 px-4"'>
                  {' '}
                  Filter
                </div> */}
              </div>
            </div>
            {navigateState === 'overview' && (
              <OverviewTable
                coachOverView={coachOverView}
                isLoading={isProcessingCoachOverview}
              />
            )}
            {navigateState === 'clients' && (
              <ClientsTable
                coachRevenueClients={coachRevenueClients}
                isLoading={isProcessingCoachRevenueClients}
              />
            )}
            {navigateState === 'transactions' && (
              <TransactionsTable
                coachRevenueClients={transactionDetails}
                isLoading={isTransaction}
              />
            )}
          </div>

          <div className=" flex md:hidden w-full  flex-row justify-end py-5 ">
            <div className="flex flex-row gap-5 items-center">
              <div className="text-13 font-general rtl:font-Almarai text-gray">
                Date range:
              </div>
              <select
                onChange={(e) =>
                  navigateState === 'clients'
                    ? dispatch(setClientDayfilter(e.target.value))
                    : dispatch(setOverviewDayfilter(e.target.value))
                }
                className="rtl:font-Almarai bg-white h-[38px] md:w-[158px] w-full border border-disableGray text-[#153243] text-sm rounded font-semibold"
              >
                <option defaultChecked selected value={''}>
                  {t('PREFORMANCE.ALL')}
                </option>
                {filterList.map((item, index) => (
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div>
            {navigateState === 'overview' && (
              <OverviewCard
                coachOverView={coachOverView}
                isLoading={isProcessingCoachOverview}
              />
            )}
            {navigateState === 'clients' && (
              <ClientsCard
                coachRevenueClients={coachRevenueClients}
                isLoading={isProcessingCoachRevenueClients}
              />
            )}
            {navigateState === 'transactions' && (
              <TransactionCard
                coachRevenueClients={transactionDetails}
                isLoading={isTransaction}
              />
            )}
          </div>
          <div>
            <div className="mt-4">
              {((navigateState === 'clients' &&
                coachRevenueClients?.length > 0) ||
                (navigateState !== 'clients' && coachOverView?.length > 0)) && (
                <Pagination
                  totalPagesNumber={
                    navigateState === 'clients'
                      ? clientTotalPages
                      : overviewTotalPages
                  }
                  isLoading={
                    navigateState === 'clients'
                      ? isProcessingCoachRevenueClients
                      : isProcessingCoachOverview
                  }
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
              )}
            </div>
          </div>
        </>
        {/* ) : (
        <ReviewPage searchParams={searchParams} setSearchParams={setSearchParams} />
      )} */}
      </div>
    </>
  );
};

export default CoachPerformance;

import {getAccessToken, getLang} from './localStorage';

export const getHeaderInfo = async function () {
  const token = await getAccessToken();
  const lang = await getLang();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      Language: lang,
    },
  };
};
export const getImageHeaderInfo = async function () {
  const token = await getAccessToken();
  return {
    headers: {
      'Content-Type': 'image/jpeg',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFormFileHeader = async function () {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};

export const getHeader = async function () {
  const token = await getAccessToken();
  const lang = await getLang();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Language: lang,
    },
  };
};

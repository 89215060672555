import moment from 'moment';
import { array, object, ref, string } from 'yup';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const signInValidationSchema = object().shape({
  email: string().required('PLEASE_FILL_EMAIL').email('WRONG_EMAIL_FORMAT'),
  password: string().required('ENTER_PASSWORD'),
  captcha: string().required('ERRORS.LOGIN.EMPTY_CAPTCHA'),
});

export const setNewPasswordValidationSchema = object().shape({
  newPassword: string()
    .required('ENTER_PASSWORD')
    .matches(/^.*[0-9].*/, 'ATLEAST_ONE_NUMBER')
    .matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, 'ATLEAST_ONE_SYMBOL')
    .matches(/.*[A-Z].*/, 'ATLEAST_ONE_UPPERCASE')
    .matches(/.*[a-z].*/, 'ATLEAST_ONE_LOWERCASE')
    .min(8, 'MINIMUM_EIGHT_CHARACTERS')
    .max(15, 'MAXIMUM_SIXTEEN_CHARACTERS')
    .matches(/^\S*$/, 'NO_SPACE'),
  confirmPassword: string()
    .required('ENTER_CONFIRM_PASSWORD')
    .oneOf([ref('newPassword')], 'YOUR_PASSWORDS_DONOT_MATCH'),
});

export const forgotPasswordValidationSchema = object().shape({
  email: string().required('PLEASE_FILL_EMAIL').email('WRONG_EMAIL_FORMAT'),
});

export const signUpValidationSchema = object().shape({
  fullName: string()
    .required('PLEASE_FILL_FULL_NAME')
    // .matches(/^[a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS')
    .min(2, 'TOO_SHORT')
    .max(50, 'TOO_LONG')
    .test('notEmpty', 'PLEASE_FILL_FULL_NAME', (value: any) => {
      // Trim the value to remove leading and trailing spaces
      const trimmedValue = value?.trim();

      // Check if the trimmed value is empty
      if (trimmedValue?.length === 0) {
        return false; // Reject only white spaces
      }

      return true; // Accept if the field has characters or white spaces
    }),
  email: string().required('PLEASE_FILL_EMAIL').email('WRONG_EMAIL_FORMAT'),
  password: string()
    .required('ENTER_PASSWORD')
    .matches(/^.*[0-9].*/, 'ATLEAST_ONE_NUMBER')
    .matches(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g, 'ATLEAST_ONE_SYMBOL')
    .matches(/.*[A-Z].*/, 'ATLEAST_ONE_UPPERCASE')
    .matches(/.*[a-z].*/, 'ATLEAST_ONE_LOWERCASE')
    .min(8, 'MINIMUM_EIGHT_CHARACTERS')
    .max(16, 'MAXIMUM_SIXTEEN_CHARACTERS')
    .matches(/^\S*$/, 'NO_SPACE'),
  phoneNumber: string()
    .required('ERRORS.PROFILE.PHONE_NUMBER')
    .matches(phoneRegExp, 'ERRORS.PROFILE.WRONG_PHONE')
    .min(9, 'ERRORS.PERSONAL_DETAILS.TOO_SHORT')
    .max(12, 'ERRORS.PERSONAL_DETAILS.TOO_LONG'),
  occupation: string().matches(/^[0-9a-zA-Z\u0621-\u064A\u0660-\u0669 ]*$/, 'ACCEPTS_ALPHABETS'),
  company: string().matches(/^[0-9a-zA-Z\u0621-\u064A\u0660-\u0669 ]*$/, 'ACCEPTS_ALPHABETS'),
  gender: string().required('SELECT_GENDER'),
  // dob: string().required('SELECT_DATE'),
  dob: string()
    .required('ERRORS.PERSONAL_DETAILS.EMPTY_DOB')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
    .test('dob', 'ERRORS.PERSONAL_DETAILS.INVALID_AGE', (value) =>
      moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
    )
    .test('dob', 'ERRORS.PERSONAL_DETAILS.INVALID_AGE', (value) => {
      const currentDate = moment();
      const minimumDate = currentDate.clone().subtract(80, 'years');
      const maximumDate = currentDate.clone().subtract(10, 'years');

      return moment(value, 'YYYY-MM-DD').isBetween(
        minimumDate,
        maximumDate,
        'day',
        '[]'
      );
    }),
});

export const contactUsSchema = object().shape({
  name: string()
    .required('PLEASE_FILL_YOUR_NAME')
    .matches(/^[0-9a-zA-Z\u0621-\u064A\u0660-\u0669 ]*$/, 'ACCEPTS_ALPHABETS')
    .min(2, 'TOO_SHORT')
    .max(50, 'TOO_LONG'),
  email: string().required('PLEASE_FILL_EMAIL').email('WRONG_EMAIL_FORMAT'),
  phoneNumber: string()
    .required('EMPTY_PHONE_NUMBER')
    .matches(/^\d{12}$/, 'ACCEPT_TOWLIVE_DIGIT'),
  subject: string()
    .required('PLEASE_FILL_SUBJECT')
    .matches(/^[0-9a-zA-Z\u0621-\u064A\u0660-\u0669 ]*$/, 'ACCEPTS_ALPHABETS'),
  description: string()
    .required('PLEASE_FILL_DESCRIPTION')
    .matches(/^[0-9a-zA-Z\u0621-\u064A\u0660-\u0669 ]*$/, 'ACCEPTS_ALPHABETS'),
  category: string()
      .required('PLEASE_FILL_DESCRIPTION')
});

export const updateClientValidationSchema = object().shape({
  fullName: string()
    .required('PLEASE_FILL_FULL_NAME')
    // .matches(/^[a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS')
    .min(2, 'TOO_SHORT')
    .max(50, 'TOO_LONG'),
  email: string().required('PLEASE_FILL_EMAIL').email('WRONG_EMAIL_FORMAT'),
  phoneNumber: string()
    .required('ERRORS.PROFILE.PHONE_NUMBER')
    .matches(phoneRegExp, 'ERRORS.PROFILE.WRONG_PHONE')
    .min(9, 'ERRORS.PERSONAL_DETAILS.TOO_SHORT')
    .max(12, 'ERRORS.PERSONAL_DETAILS.TOO_LONG'),
  description: string()
    .matches(/^[0-9a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS')
    .min(3, 'TOO_SHORT')
    .max(50, 'TOO_LONG')
    .nullable(),
  languages: array()
    .required('ERRORS.SPECIALIZATION_DETAILS.EMPTY_LANGUAGE')
    .min(1, 'ERRORS.SPECIALIZATION_DETAILS.EMPTY_LANGUAGE'),
  addressLine1: string()
    .required('PLEASE_FILL_ADDRESS_ONE')
    .min(2, 'TOO_SHORT')
    .max(50, 'TOO_LONG'),
  addressLine2: string()
    .required('PLEASE_FILL_ADDRESS_TWO')
    .min(2, 'TOO_SHORT')
    .max(50, 'TOO_LONG'),
  occupation: string().matches(/^[a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS'),
  company: string().matches(/^[a-zA-Z ]*$/, 'ACCEPTS_ALPHABETS'),
  gender: object().nullable(),
  // dob: string().required('SELECT_DATE'),
  dob: string()
    .required('ERRORS.PERSONAL_DETAILS.EMPTY_DOB')
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'ERRORS.PERSONAL_DETAILS.INVALID_DOB')
    .test('dob', 'ERRORS.PERSONAL_DETAILS.INVALID_AGE', (value) =>
      moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
    )
    .test('dob', 'ERRORS.PERSONAL_DETAILS.INVALID_AGE', (value) => {
      const currentDate = moment();
      const minimumDate = currentDate.clone().subtract(80, 'years');
      const maximumDate = currentDate.clone().subtract(10, 'years');

      return moment(value, 'YYYY-MM-DD').isBetween(
        minimumDate,
        maximumDate,
        'day',
        '[]'
      );
    }),
  // dob: date()
  // .required('ERRORS.PERSONAL_DETAILS.EMPTY_DOB')
  // .test('dob', 'ERRORS.PERSONAL_DETAILS.INVALID_DOB', (value) =>
  //   moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day')
  // ),
  // .test(
  //   'dob',
  //   'ERRORS.PERSONAL_DETAILS.INVALID_AGE',
  //   (value) => {
  //     const currentDate = moment();
  //     const minimumDate = currentDate.clone().subtract(80, 'years');
  //     const maximumDate = currentDate.clone().subtract(10, 'years');

  //     return (
  //       moment(value, 'YYYY-MM-DD').isBetween(
  //         minimumDate,
  //         maximumDate,
  //         'day',
  //         '[]'
  //       )
  //     );
  //   }
  // ),
  country: string().required('ENTER_COUNTRY'),
  city: string().required('ENTER_CITY'),
});

export const paymentValidationSchema = object().shape({
  paymentType: string().required('SELECT_PAYMENT_METHOD'),
});

export const getAccessToken = () => localStorage.getItem('token')?.slice(1, -1);
export const getLang = () => {
    const lang = JSON.parse(localStorage.getItem('persist:root') as string) || null;
    if(!!lang) {
        return JSON.parse(lang?.language)?.lang || 'en'
    }
    return 'en';
};

export const setTokens = <T>(authRes: T) => {
  localStorage.setItem('token', JSON.stringify(authRes));
};
export const removeToken = () => {
  localStorage.removeItem('token');
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
export const isAuthenticated = () => (getAccessToken() ? true : false);

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { useWindowSize } from '../../clientHelpers/resizeWindow';
import {
  coachRelatedTags,
  countCount,
  countServices,
  getRelatedTags,
  listCategory,
  listHomeServices,
  selectClientHome,
  setServiceType,
  setServiceView,
} from '../../clientSlices/home';
import { useAppSelector } from '../../hooks';
import CommonCarousal from '../carousal';
import CoachCarousal from '../carousalForCoach';
import './index.css';
// import { CarouselCard } from '../index';
interface IFilter {
  filter?: (data: boolean) => void;
  filterView?: boolean;
  active?: boolean;
}
const LandingPageBody: FC<IFilter> = ({ active, filterView }): JSX.Element => {
  const [t] = useTranslation('common');

  const [width] = useWindowSize();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(listCategory());
    dispatch(listHomeServices());
    // setSearchParams();
  }, []);
  const { lang } = useAppSelector((state) => state.language);
  let categoryListEn: { label: ''; value: ''; label2: '' }[] = [];
  const { categories, homeServices, serviceType } =
    useSelector(selectClientHome);
  categories.map((item: any, index: number) => {
    categoryListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
      label2: item.nameInArabic,
    });
  });
  let topCategoryListEn: { label: ''; value: '' }[] = [];
  let topCategoryListAr: { label: ''; value: '' }[] = [];
  homeServices?.topCategoryList?.map((item: any, index: number) => {
    topCategoryListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    topCategoryListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });

  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) {
      dispatch(setServiceView(true));
      dispatch(setServiceType(''));
      navigate(
        `/search-result${search && `?keyword=${search}`}${
          categoryValue && `&category=${categoryValue}`
        }`
      );
    }
  };
  const [categoryValue, setCategoryValue] = useState('');
  useEffect(() => {
    if (categoryValue)
      if (search)
        navigate(
          `/search-result${search && `?keyword=${search}`}${
            categoryValue && `&category=${categoryValue}`
          }`
        );
      else
        navigate(
          `/search-result${categoryValue && `?category=${categoryValue}`}`
        );
  }, [categoryValue]);
  const handleListAllService = (val: string) => {
    // dispatch(setServiceType(val));
    // dispatch(setServiceView(false));
    if (val.toLocaleLowerCase() === 'coaches') {
      dispatch(countCount());
      const temp: any = {};
      if (search) temp['keyword'] = search;
      if (categoryValue) temp['category'] = categoryValue;
      if (val) temp['serviceType'] = val.toLocaleUpperCase();
      dispatch(coachRelatedTags(temp));
    } else {
      const temp: any = {};
      if (search) temp['keyword'] = search;
      if (categoryValue) temp['category'] = categoryValue;
      if (val) temp['serviceType'] = val.toUpperCase().slice(0, -1);
      dispatch(getRelatedTags(temp));
      dispatch(
        countServices({
          serviceType: val.toLocaleUpperCase().slice(0, -1),
          data: temp,
        })
      );
    }
    navigate(
      `/search-result?page=0&size=9${
        val &&
        `&serviceType=${val.toUpperCase()}${search && `&keyword=${search}`}${
          categoryValue && `&category=${categoryValue}`
        }`
      }`
    );
  };

  return (
    <>
      <div className="flex  gap-5   flex-col  md:flex-row  mx-4 md:mx-[100px]">
        <div className="w-full  h-[42px]  mt-10 md:w-[30%]  category-dropdown">
          <SelectDropdown
            className={`font-general rtl:font-Almarai text-var(--secondary) w-full`}
            id="FormLanguage"
            name="languages"
            placeholder={t('LANDING_PAGE_AFTER_LOGIN.CATEGORY')}
            labelClass={categoryValue ? 'hidden' : ''}
            optional={1}
            value={categoryValue}
            isSearchable={false}
            onChange={(event: { label: string; value: string }) =>
              setCategoryValue((value) => (value = event.value))
            }
            getOptionLabel={(option: any) =>
              lang === 'en' ? option.label : option.label2
            }
            options={categoryListEn}
            grayOutline={true}
          />
        </div>
        <div className="w-full h-[46px] md:mt-10 md:w-[70%]  mb-2 ">
          <form className="h-full">
            <div className=" my-upload-search-box w-full h-full">
              <div className="w-[4%]  h-[44px] flex items-center justify-center">
                <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
                  <img
                    src="/assets/gridView/searchIcon.svg"
                    id={search}
                    alt=""
                  />
                </button>
              </div>
              <input
                type="text"
                id="simple-search"
                className="text-gray-900 text-sm h-[42px] search-input  w-[93%] rtl:font-Almarai"
                placeholder={t('MY_UPLOADS.SEARCH_TXT')}
                required
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                value={search}
              />
              {search && (
                <img
                  onClick={() => {
                    setSearchParams();
                    setSearch('');
                  }}
                  className="cursor-pointer"
                  src="/assets/subscriptionPage/plusIcon.svg"
                  alt="bell-icon"
                />
              )}
            </div>
          </form>
        </div>
      </div>

      <div
        className="  flex justify-around bg-cover xl:bg-cover lg:h-[597px]  lg:pt-12 bg-no-repeat"
        style={{
          backgroundImage: `url(${
            width <= 760
              ? '/assets/aboutUsPage/mobileviewbg.svg'
              : '/assets/bodyAfterLogin/coverImage.svg'
          })`,
        }}
      >
        <div className="flex  justify-center flex-col md:flex-row ">
          <div className="flex-shrink items-center ltr:ml-5 rtl:mr-5 ltr:md:ml-16 rtl:md:mr-16">
            <div className="flex flex-col  h-[180px]  text-28 md:text-48 mt-5 lg:mt-[133px] mb-5  md:mb-[16px] font-satoshi rtl:font-Almarai  font-bold  text-darkGrey  ">
              <div className="">
                {t('LANDING_PAGE_AFTER_LOGIN.LEARN_NEW_SKILLS')}
              </div>
              {/* <div className=" ">
                {t('LANDING_PAGE_AFTER_LOGIN.ONLINE_WITH')}weverververv
              </div>
              <div className=" ">
                {t('LANDING_PAGE_AFTER_LOGIN.TOP_EDUCATOR')}
              </div> */}

              <div>
                <p className="p-text text-justify  text-16  xl:w-/12  ltr:mr-4 rtl:ml-4  mt-4  md:ltr:text-left md:rtl:text-right">
                  {t('LANDING_PAGE_AFTER_LOGIN.SUB_TITLE3')}
                </p>
              </div>
            </div>
            <div
              className=" mt-8 md:mt-36 mb-11 md:mb-14 lg:mb-[132px] flex item-center "
              onClick={() =>
                navigate(
                  `/search-result${search && `?keyword=${search}`}${
                    categoryValue && `&category=${categoryValue}`
                  }`
                )
              }
            >
              <button className="bg-lightBlue text-white font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[190px] md:w-[295px] h-[54px] rounded py-4  lg:px-auto">
                {t('LANDING_PAGE_AFTER_LOGIN.EXPLORE')}
              </button>
            </div>
          </div>
          <div className=" flex w-full lg:justify-end justify-center md:pr-20 ">
            <img
              className="overflow-x-auto"
              src="/assets/bodyAfterLogin/female-img.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="px-5 md:px-24   ">
        <div className="relative">
          <div className="flex flex-row mt-[80px] h-[41px] items-center  ">
            <div className=" landing-heading-client flex-grow">
              {t('LANDING_PAGE_AFTER_LOGIN.SEARCH_BY_TOP_CATEGORY')}
            </div>
            {/* <button className="md_max:absolute md_max:bottom-0 md_max:left-[10vw] md_max:right-[10vw] md:left-0 md:bottom-0  md:relative items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 justify-center border border-solid rounded invisible sm:visible">
              {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_CATEGORIES')}
            </button> */}
          </div>
          <div className="w-full flex sm:flex sm:flex-row    gap-5 pb-1 sm:pb-5 pt-[40px]  overflow-y-hidden overflow-x-auto justify-between form-scroll">
            {(lang === 'en' ? topCategoryListEn : topCategoryListAr)?.map(
              (value: any, index: number) => {
                return (
                  <div
                    key={`home-${value.id}${index}`}
                    onClick={() =>
                      navigate(`/search-result?category=${value.value}`)
                    }
                    className=" items-center sm:max-w-[171px] sm:flex sm:flex-row sm:h-[150px]  flex-start sm:flex-1  sm:justify-center rounded border border-solid p-4 bg-white border-grey35 sm:items-center drop-shadow-[0_10px_25px_rgba(0,0,51,0.08)] h-[88px] cursor-pointer"
                  >
                    <div className="flex flex-col justify-center items-center gap-1">
                      <div className="pt-4 pb-5 hidden sm:contents ">
                        <img
                          className="mb-3"
                          src="/assets/bodyAfterLogin/tick.svg"
                          alt=""
                        />
                      </div>
                      <div className="font-normal sm:text-center text-center text-heading3 text-darkGrey font-general rtl:font-Almarai  sm:pb-6 sm:w-[140px] w-24 h-2 flex justify-center items-center mt-5 ">
                        {value.value}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          {/* <div className="flex justify-center">
            <button className="sm_max:absolute sm_max:bottom-[-55px] sm_max:left-[10vw] sm_max:right-[10vw] sm:left-0 m:bottom-0  sm:relative items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid visible  rounded sm:invisible ">
              {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_CATEGORIES')}
            </button>
          </div> */}
        </div>
      </div>
      <div className=" sm_max:mt-6 bg-secondary w-full object-cover mx-0  "></div>
      <div className="relative px-5 md:px-24 ">
        {homeServices?.topListedSessions?.length ? (
          <div className="relative pb-14">
            <div className="flex flex-row mt-16 h-[41px] items-center ">
              <div className="  landing-heading-client  flex-grow mt-10">
                {t('LANDING_PAGE_AFTER_LOGIN.TOP_LISTED_SESSIONS')}
              </div>
              <button
                className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded invisible md:visible"
                onClick={() => {
                  handleListAllService('Sessions');
                }}
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_SERVICES')}
              </button>
            </div>

            <CommonCarousal
              data={homeServices?.topListedSessions}
              serviceType={'SESSIONS'}
            />

            <div className="flex justify-center ">
              <button
                onClick={() => {
                  handleListAllService('Sessions');
                }}
                className="sm_max:absolute sm_max:bottom-0 sm_max:left-[15vw] sm_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded visible md:invisible sm_max:mb-3"
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_SERVICES')}
              </button>
            </div>
          </div>
        ) : null}
        {homeServices?.topListedPackages?.length ? (
          <div className="relative pb-14">
            <div className="flex flex-row mt-16 h-[41px] items-center ">
              <div className="  landing-heading-client  flex-grow">
                {t('LANDING_PAGE_AFTER_LOGIN.TOP_LISTED_PACKAGES')}
              </div>
              <button
                className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded  invisible md:visible"
                onClick={() => {
                  handleListAllService('Packages');
                }}
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_SERVICES')}
              </button>
            </div>
            <CommonCarousal
              data={homeServices?.topListedPackages}
              serviceType={'PACKAGES'}
            />
            <div className="flex justify-center">
              <button
                onClick={() => {
                  handleListAllService('Packages');
                }}
                className="sm_max:absolute sm_max:bottom-0 sm_max:left-[15vw] sm_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded visible md:invisible"
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_SERVICES')}
              </button>
            </div>
          </div>
        ) : null}
        {homeServices?.topListedAssessments?.length ? (
          <div className="relative pb-14">
            <div className="flex flex-row mt-16 h-[41px] items-center ">
              <div className="  landing-heading-client  flex-grow">
                {t('LANDING_PAGE_AFTER_LOGIN.TOP_LISTED_ASSESSMENTS')}
              </div>
              <button
                className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded  invisible md:visible "
                onClick={() => {
                  handleListAllService('Assessments');
                }}
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_SERVICES')}
              </button>
            </div>
            <CommonCarousal
              data={homeServices?.topListedAssessments}
              serviceType={'ASSESSMENTS'}
            />
            <div className="flex justify-center">
              <button
                onClick={() => {
                  handleListAllService('Assessments');
                }}
                className="sm_max:absolute sm_max:bottom-0 sm_max:left-[15vw] sm_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded visible md:invisible"
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_SERVICES')}
              </button>
            </div>
          </div>
        ) : null}
        {homeServices?.featuredCoaches?.length ? (
          <div className="relative ">
            <div className="flex flex-row pt-[80px] h-[41px] items-center ">
              <div className="  landing-heading-client  flex-grow ">
                {t('LANDING_PAGE_AFTER_LOGIN.FEATURED_COACHES')}
              </div>

              <button
                className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded invisible md:visible "
                onClick={() => {
                  handleListAllService('Coaches');
                }}
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_COACHES')}
              </button>
            </div>
            <CoachCarousal data={homeServices?.featuredCoaches} />

            <div className="flex justify-center mt-11">
              <button
                className="sm_max:absolute sm_max:bottom-0 sm_max:left-[15vw] sm_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded visible md:invisible "
                onClick={() => {
                  handleListAllService('Coaches');
                }}
              >
                {t('LANDING_PAGE_AFTER_LOGIN.SEE_ALL_COACHES')}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LandingPageBody;

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Date from '../../../clientComponents/date';
import Input from '../../../clientComponents/inputs';
import { TextArea } from '../../../coachComponents';
import { addExperienceValidationSchema } from '../../../coachHelpers';
import { useAppSelector } from '../../../coachHelpers/hooks';
import { IExperienceCoachInterface } from '../../../coachInterfaces/profileInterface';
import {
  editExperience,
  editExperienceFile,
  getProfileExperienceById,
} from '../../../coachSlices/profileSlices/profileInfoSlice';

const EditCoachExperience = ({
  setShowEditExperience,
  experienceUserId,
}: {
  setShowEditExperience: React.Dispatch<React.SetStateAction<boolean>>;
  experienceUserId: number;
}) => {
  const [t] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);
  const [title, setTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [fileId, setFileId] = useState('');
  const [isCurrentlyWorking, setRole] = useState(false);

  const dispatch = useDispatch<any>();
  const { coachExperienceDetailsById, editExperienceFileId } = useAppSelector(
    (state) => state.profile
  );

  useEffect(() => {
    dispatch(getProfileExperienceById(experienceUserId));
  }, []);

  var curr = new window.Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  useEffect(() => {
    if (coachExperienceDetailsById) {
      setTitle(coachExperienceDetailsById?.title);
      setCompanyName(coachExperienceDetailsById?.companyName);
      setLocation(coachExperienceDetailsById?.location);
      setStartDate(coachExperienceDetailsById?.startDate);
      setEndDate(coachExperienceDetailsById?.endDate);
      setDescription(coachExperienceDetailsById?.description);
      setFileId(coachExperienceDetailsById?.fileId);
      setRole(coachExperienceDetailsById?.isCurrentlyWorking);
    }
  }, [coachExperienceDetailsById]);

  const uploadFile = (e: React.FormEvent<EventTarget>): void => {
    (document.getElementById('addImage') as HTMLInputElement).innerHTML = (
      e?.target as HTMLFormElement
    )?.files[0]?.name;
    dispatch(editExperienceFile((e?.target as HTMLFormElement)?.files));
  };

  const handleSubmit = (data: IExperienceCoachInterface) => {
    const allData = {
      title: data.title,
      companyName: data.companyName,
      location: data.location,
      isCurrentlyWorking: data.isCurrentlyWorking,
      startDate: data.startDate,
      endDate: data.isCurrentlyWorking ? date : data.endDate,
      description: data.description,
      fileId: editExperienceFileId.id || null,
    };
    const dataWithId = {
      data: {
        allData,
      },
      id: experienceUserId,
    };
    dispatch(editExperience(dataWithId));
    setShowEditExperience(false);
  };

  return (
    <>
      <div className="fixed h-[100vh] inset-0 bg-transparentBlack bg-opacity-50 z-10 overflow-y-hidden">
        <div className="flex h-[100vh] items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg h-[90vh]">
            <div className="mt-3 sm:flex">
              <div className="grid">
                <button
                  className="flex justify-self-end"
                  onClick={() => setShowEditExperience(false)}
                >
                  <img
                    src="/assets/profilePageImage/model.svg"
                    alt="icon"
                    className="mr-2 cursor-pointer"
                  />
                </button>
                <h1 className="text-28 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
                  {t('PROFILE.PROFILE_INFO.EDIT_EXPERIENCE')}
                </h1>
                <p className="text-16 font-normal font-satoshi rtl:font-Almarai text-gray mb-4">
                  {t('PROFILE.PROFILE_INFO.DEC')}
                </p>
                <div className="h-[65vh] w-full overflow-y-auto  pt-2">
                  <Formik
                    initialValues={{
                      title,
                      companyName,
                      location,
                      startDate,
                      endDate,
                      description,
                      fileId,
                      isCurrentlyWorking,
                    }}
                    enableReinitialize={true}
                    validationSchema={addExperienceValidationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                      submitForm,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="pb-[80px]">
                            <Input
                              id="FormTitle"
                              type="text"
                              name="title"
                              placeholder={t('PROFILE.PROFILE_INFO.TITLE')}
                              onBlur={handleBlur}
                              error={touched.title && 1}
                              onChange={handleChange}
                              value={values.title}
                              errormessage={errors.title}
                              maxLength={50}
                            />
                            <Input
                              id="FormCompanyName"
                              type="text"
                              name="companyName"
                              placeholder={t(
                                'PROFILE.PROFILE_INFO.COMPANY_NAME'
                              )}
                              onBlur={handleBlur}
                              error={touched.companyName && 1}
                              onChange={handleChange}
                              value={values.companyName}
                              errormessage={errors.companyName}
                              maxLength={50}
                            />
                            <Input
                              id="FormLocation"
                              type="text"
                              name="location"
                              placeholder={t('PROFILE.PROFILE_INFO.LOCATION')}
                              onBlur={handleBlur}
                              error={touched.location && 1}
                              onChange={handleChange}
                              value={values.location}
                              errormessage={errors.location}
                              maxLength={50}
                            />
                            <div className="flex justify-between items-center mb-4">
                              <div>
                                <label className="inline-flex items-center">
                                  <input
                                    name="isCurrentlyWorking"
                                    type="checkbox"
                                    className="form-checkbox h-6 w-6"
                                    checked={values?.isCurrentlyWorking}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `isCurrentlyWorking`,
                                        e.target.checked
                                      );
                                    }}
                                  />

                                  <span className=" ltr:ml-2 rtl:mr-2  text-gray font-normal text-16">
                                    {t('PROFILE.PROFILE_INFO.ROLE')}
                                  </span>
                                </label>
                              </div>
                            </div>

                            <div className="lg:flex lg:justify-between gap-3">
                              <div className={` lg:w-6/12 `}>
                                <Date
                                  id="StartDate"
                                  name="startDate"
                                  placeholder={t(
                                    'PROFILE.PROFILE_INFO.START_DATE'
                                  )}
                                  onBlur={handleBlur}
                                  value={values.startDate}
                                  error={touched.startDate && 1}
                                  onChange={(opt: any) => {
                                    const date = `${opt?.year}-${
                                      opt?.monthIndex + 1 < 10
                                        ? `0${opt?.monthIndex + 1}`
                                        : opt?.monthIndex + 1
                                    }-${
                                      opt?.day < 10 ? `0${opt?.day}` : opt?.day
                                    }`;
                                    setFieldValue('startDate', date);
                                  }}
                                  errormessage={errors.startDate}
                                />
                              </div>
                              {values.isCurrentlyWorking ? null : (
                                <div className=" lg:w-6/12">
                                  <Date
                                    type="date"
                                    id="dateBirth"
                                    name="endDate"
                                    placeholder={t(
                                      'PROFILE.PROFILE_INFO.END_DATE'
                                    )}
                                    onBlur={handleBlur}
                                    value={
                                      values.isCurrentlyWorking
                                        ? date
                                        : values.endDate
                                    }
                                    error={touched.endDate && 1}
                                    onChange={(opt: any) => {
                                      const date = `${opt?.year}-${
                                        opt?.monthIndex + 1 < 10
                                          ? `0${opt?.monthIndex + 1}`
                                          : opt?.monthIndex + 1
                                      }-${
                                        opt?.day < 10
                                          ? `0${opt?.day}`
                                          : opt?.day
                                      }`;
                                      setFieldValue('endDate', date);
                                    }}
                                    errormessage={errors.endDate}
                                  />
                                </div>
                              )}
                            </div>
                            <TextArea
                              id="FormBio"
                              type="text"
                              rows={3}
                              cols={50}
                              name="description"
                              placeholder={t('PROFILE.PROFILE_INFO.DESCRIP')}
                              onBlur={handleBlur}
                              error={touched.description && 1}
                              onChange={handleChange}
                              value={values.description}
                              errormessage={errors.description}
                              optional={1}
                              maxLength={50}
                            />
                            <h1 className="text-22 font-bold font-satoshi rtl:font-Almarai text-secondary mt-1 mb-1 ">
                              {t('PROFILE.PROFILE_INFO.MEDIA')}
                            </h1>
                            <p className="text-gray text-17 font-normal font-general rtl:font-Almarai">
                              {t('PROFILE.PROFILE_INFO.MEDIA_DEC')}
                            </p>
                            <div className="col-md-9 pe-5 my-5">
                              <div className=" flex sm:mb-3">
                                <label htmlFor="image">
                                  <p className="bg-gray w-44 mt-5 rounded text-center px-4 py-2 text-white font-satoshi rtl:font-Almarai text-14 font-bold">
                                    {t('PROFILE.PROFILE_INFO.UPLOAD_DOC')}
                                  </p>
                                </label>
                                <input
                                  id="image"
                                  name="fileId"
                                  className="form-control"
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  type="file"
                                  onChange={(e) => {
                                    uploadFile(e);
                                    handleChange(e);
                                  }}
                                  style={{ display: 'none' }}
                                />
                                <span
                                  id="addImage"
                                  className="ltr:ml-3 rtl:mr-3
                            mt-7 text-13 font-general rtl:font-Almarai not-italic font-normal text-gray"
                                >
                                  {t('PROFILE.PROFILE_INFO.NO_IMAGE')}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex lg:justify-end text-end grid mt-10 w-full h-[80px] items-center absolute bottom-0 left-0 bg-[#ffffff] px-5">
                            <button
                              type="submit"
                              onClick={submitForm}
                              className="bg-primary p-4 lg:px-10 lg:py-4 text-white rounded"
                            >
                              {t('PROFILE.PROFILE_INFO.SAVE')}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoachExperience;

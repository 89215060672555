import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// import Checkbox from '../checkbox';
import './index.css';

interface IRating {
  title: string;
  content: any[];
  checked?: any;
  setCheckBoxChecked?: any;
  onChangeAttribute?: any;
  setFilterValue?: any;
  filterValue?: any;
  onChangeAttributeFilter?: any;
  clearFieldToggle: boolean;
}
const MultiDropdown: FC<IRating> = ({
  content,
  title,
  onChangeAttribute,
  setFilterValue,
  filterValue,
  clearFieldToggle,
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [trigger, settrigger] = useState(false);
  const [checked, setCheckBoxChecked] = useState<string | null>();
  const onChangeAttributeFilter = (item: any) => {
    setCheckBoxChecked((value: any) => (value = item.name));
    const temp: any = { ...filterValue };
    if (item.filterName === 'ratings') {
      switch (item.name) {
        case '4 & above':
          temp[item.filterName] = 'FOUR_AND_ABOVE';
          break;
        case '3 & above':
          temp[item.filterName] = 'THREE_AND_ABOVE';
          break;
        case '2 & above':
          temp[item.filterName] = 'TWO_AND_ABOVE';
          break;
        default:
          temp[item.filterName] = 'ALL';
      }
    } else if (!!item.value) {
      temp[item.filterName] = item.value
    }
    else {
      temp[item.filterName] = item.name.toUpperCase()
    }

    setFilterValue((value: any) => (value = temp));
  };
  useEffect(() => {
    content?.length &&
      !searchParams.get(`${content[0]?.filterName}`) &&
      setCheckBoxChecked(null);
  }, [searchParams.get(`${content[0]?.filterName}`), clearFieldToggle]);

  return (
    <>
      <div className="accordion-item border-t-[1px] border-borderColor ">
        <div className="accordion-title " onClick={() => settrigger(!trigger)}>
          <div className="font-bold text-17 capitalize text-darkGrey rtl:font-Almarai">
            {title}
          </div>
          <div className="flex items-center">
            {trigger ? (
              <div className="rotate-180">
                <img src="/assets/gridView/downArrow.svg" alt="" />
              </div>
            ) : (
              <img src="/assets/gridView/downArrow.svg" alt="" />
            )}
          </div>
        </div>
        {trigger &&
          content.map((item, index) => (
            <div className="accordion-content font-normal text-[17px] text-darkGrey flex items-center rtl:font-Almarai">
              <input
                className="mr-4"
                checked={checked === item.name}
                onChange={() => onChangeAttributeFilter(item)}
                type="checkbox"
              />
              {item.name}(<span>{item.number}</span>)
            </div>
          ))}
      </div>
    </>
  );
};

export default MultiDropdown;

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IdraftInterface } from '../../coachInterfaces/coachHomeInterface';
import { deleteDraft } from '../../coachSlices/coachHomeSlice';
import { Image } from '../../components/Image';
interface IDraftCard {
  item: IdraftInterface;
}
const Component: FC<IDraftCard> = ({ item }): JSX.Element => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [isProcessingDraftDelete, setIsProcessingDraftDelete] =
    useState<boolean>(false);

  const onDeleteClick = async (id: string) => {
    setIsProcessingDraftDelete(true);
    await dispatch(deleteDraft(id));
    setIsProcessingDraftDelete(false);
  };
  const onEditClick = () => {
    switch (item?.draftType) {
      case 'ASSESSMENT':
        if (item?.id)
          navigate(`/coach/coach-assessment?form=DRAFT&id=${item?.id}`);
        break;
      case 'PACKAGE':
        if (item?.id)
          navigate(`/coach/coach-package?form=DRAFT&id=${item?.id}`);

        break;
      case 'SESSION':
        if (item?.id)
          navigate(`/coach/coach-session?form=DRAFT&id=${item?.id}`);

        break;
    }
  };
  const ProgressBar = (progressPercentage: number) => {
    return (
      <div className="progress-bar">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full rounded-sm bg-[#02A4EA]`}
        ></div>
      </div>
    );
  };

  return (
    <div className="draft-card mb-10 gap-2">
      <Image
        id={
          (item?.response?.imgId as number) ||
          (item?.response?.imageId as number)
        }
        bgImgUrl={'/assets/default-img-icon.svg'}
        bgClass={
          ' bg-[#F6F8F5] draft-card-image flex justify-center items-center'
        }
        propClass={'draft-card-image'}
      />
      <div className="draft-card-txt-container p-2 w-[75%] md:w-full  md:p-5">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-row justify-between text-[#153243] text-xl font-satoshi rtl:font-Almarai font-bold ">
            <span>{item?.response?.title}</span>
            <div className="draft-card-actions">
              <img
                className="mx-6 w-[20px] h-[20px] cursor-pointer"
                src="/assets/homePageImages/edit-icon.svg"
                alt="bell-icon"
                onClick={() => onEditClick()}
              />

              {isProcessingDraftDelete ? (
                <div className="ash-loader " />
              ) : (
                <img
                  className="cursor-pointer w-[20px] h-[20px]"
                  src="/assets/homePageImages/delete-icon.svg"
                  alt="bell-icon"
                  onClick={() => onDeleteClick(item?.id)}
                />
              )}
            </div>
          </div>
          <div className="text-[#687E8E] text-base font-satoshi rtl:font-Almarai font-normal ">
            {item?.response?.subTitle}
          </div>
        </div>
        <div className="service-progress-row ">
          <div className="w-[14rem]">
            {t('HOME.COMPLETE_THE_PACKAGE')}{' '}
            <span className="lowercase">
              {item?.draftType === 'SESSION'
                ? t('COACH_ASSESSMENT.SESSION')
                : item?.draftType === 'PACKAGE'
                ? t('COACH_PACKAGE.PACKAGE')
                : t('COACH_PACKAGE.ASSESSMENT')}
            </span>
          </div>
          {ProgressBar(70)}
        </div>
        <div className="draft-card-actions-mobile">
          <img
            className="mx-6 cursor-pointer w-[20px] h-[20px]"
            src="/assets/homePageImages/edit-icon.svg"
            alt="bell-icon"
            onClick={() => onEditClick()}
          />
          <img
            className="cursor-pointer w-[20px] h-[20px]"
            src="/assets/homePageImages/delete-icon.svg"
            onClick={() => onDeleteClick(item?.id)}
            alt="bell-icon"
          />
        </div>
      </div>
    </div>
  );
};
const DraftCard = styled(Component)``;

export default DraftCard;

import Slider from 'react-slick';
import { useAppSelector } from '../../clientHelpers/hooks';
import './index.css';
export const SimpleSlider = ({ settings, imagePath }: any) => {
  const { lang } = useAppSelector((state) => state.language);
  return (
    <div className="slider_parent">
      <Slider className="slider-class" {...settings}>
        {imagePath.map((item: any, index: number) => {
          return (
            <>
              <div key={index} className="slider-loop">
                <div
                  className=" R-slider-desc rtl:mr-[40px]"
                  dir={lang === 'ar' ? 'rtl' : ''}
                >
                  <h1 className="font-satoshi rtl:font-Almarai text-3xl font-bold text-white">
                    {item.heading}
                  </h1>
                  <p className="mt-5 text-base font-general rtl:font-Almarai font-normal">
                    {item.title}
                  </p>
                </div>
                <img className="image-class" src={item.path} alt="SlideImage" />
              </div>
            </>
          );
        })}
      </Slider>
    </div>
  );
};

import { IUpdatePassword } from '../clientInterfaces/profile';
import { deleteApi, get, post, put } from './api';

export const profileView = async () => {
  return await get('/clients/me');
};

export const languages = async () => {
  return await get('/languages');
};

export const cities = async (city: any) => {
  if (city) return await get(`/cities?country=${city}`);
};

export const countries = async () => {
  return await get('/countries');
};

export const updateClient = async (data: any) => {
  return await put(`/clients/${data.id}`, data.payload);
};

export const deleteClient = async (id: number) => {
  return await deleteApi(`/clients/${id}`);
};

export const updatePassword = async (data: IUpdatePassword) => {
  return await post(`/auth/update-password`, data);
};

export const downloadById = async (id: number) => {
  return await get(`/files/${id}`);
};

import { FieldArray, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { packageAssessmentValidationSchema } from '../../coachHelpers';
import { myCoachUploadsInterface } from '../../coachInterfaces/myUploadInterface';
import {
  assessmentTitlesInterface,
  assessmentValueArrayInterface,
  sessionTitlesInterface,
} from '../../coachInterfaces/packageInterface';
import { tagsInterface } from '../../coachInterfaces/sessionInterface';
import {
  fetchAssessmentSessionForPackages,
  packageInfo,
  setAssessmentPackageFormData,
} from '../../coachSlices/packageSlice';
import SessionsCard from './assessmentCard';

interface IFaqCard {
  assessmentFormRef: any;
}

const Component: FC<IFaqCard> = ({ assessmentFormRef }): JSX.Element => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();
  const {
    assessmentPackageFormValue,
    assessmentTitles,
    sessionTitles,
    isProcessingPackages,
    assessmentSession,
  } = useSelector(packageInfo);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    let totalValue = 0;

    assessmentSession.map((item: myCoachUploadsInterface) => {
      totalValue = totalValue + (item?.price as number);
    });
    setTotalPrice(totalValue);
  }, [assessmentSession]);
  useEffect(() => {
    let idArray: any = [];
    // if (assessmentPackageFormValue.assessmentId !== '')
    //   idArray.push({
    //     id: assessmentPackageFormValue.assessmentId,
    //     type: 'ASSESSMENT',
    //   });

    // if (assessmentPackageFormValue.sessionId !== '')
    //   idArray.push({
    //     id: assessmentPackageFormValue.sessionId,
    //     type: 'SESSION',
    //   });

    assessmentPackageFormValue.assessmentValue.map(
      (item: assessmentValueArrayInterface) => {
        if (
          item.assessmentId &&
          item.assessmentId !== '' &&
          item?.category === 'ASSESSMENT'
        ) {
          idArray.push({
            id: item.assessmentId,
            type: 'ASSESSMENT',
          });
        } else if (
          item.sessionId &&
          item.sessionId !== '' &&
          item?.category === 'SESSION'
        ) {
          idArray.push({
            id: item.sessionId,
            type: 'SESSION',
          });
        }
      }
    );
    if (idArray.length > 0) {
      dispatch(fetchAssessmentSessionForPackages(idArray));
    }
  }, [assessmentPackageFormValue?.assessmentValue]);
  const handleFormSubmit = () => {};
  const assessmentTitlesOptions = assessmentTitles.map(
    (item: assessmentTitlesInterface) => ({
      label: item?.title,
      value: item?.id,
    })
  );
  const sessionTitlesOptions = sessionTitles.map(
    (item: sessionTitlesInterface) => ({
      label: item?.title,
      value: item?.id,
    })
  );
  let hourDuration: { label: string; value: number }[] = [];
  const hoursOptions: number[] = [30, 60, 90, 120, 150];
  hoursOptions?.map((item: number) => {
    hourDuration.push({
      label: `${item} ${t('MINS')}`,
      value: item,
    });
  });
  const dayOption = Array.from(Array(31)).map((item: number, i: number) => ({
    label: `${i + 1} days`,
    value: i + 1,
  }));
  const weekOption = Array.from(Array(24)).map((item: number, i: number) => ({
    label: `${i + 1} weeks`,
    value: i + 1,
  }));
  let retakePeriodOptions: { label: string; value: string }[] = [];
  const array = Array.from({ length: 90 }, (_, index) => index + 1);
  array?.map((item: any) => {
    retakePeriodOptions.push({
      label: `${item} ${t('PREFORMANCE.DAYS')}`,
      value: item,
    });
  });
  const hoursOption = Array.from(Array(24)).map((item: number, i: number) => ({
    label: `${i + 1} hours`,
    value: i + 1,
  }));
  const categoryOptions = [
    {
      value: 'ASSESSMENT',
      label: t('COACH_ASSESSMENT.ASSESSMENT'),
    },
    {
      value: 'SESSION',
      label: t('COACH_ASSESSMENT.SESSION'),
    },
  ];
  const getAssessmentLabel = (id: string) => {
    const item: tagsInterface[] = assessmentTitlesOptions.filter(
      (item: any) => item.value === id
    );
    return item[0]?.label;
  };
  const getSessionLabel = (id: string) => {
    const item: tagsInterface[] = sessionTitlesOptions.filter(
      (item: any) => item.value === id
    );
    return item[0]?.label;
  };

  const getSessionCardLoader = () => {
    return (
      <div className="w-full flex rounded-lg shadow-xl min-h-[8.375rem] h-fit mt-6">
        <div className="content-loader h-[8.375rem] w-[8.375rem] rounded-l-xl" />
        <div className="w-[90%] justify-between flex flex-col min-h-[8.375rem] h-fit p-3">
          <div className="content-loader w-[20%] h-4 rounded-[3px]"></div>
          <div className="content-loader w-[30%] h-4 rounded-[3px]"></div>
          <div className="content-loader w-[100%] h-4 rounded-[3px]"></div>
          <div className="content-loader w-[100%] h-4 rounded-[3px]"></div>
        </div>
      </div>
    );
  };
  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-Almarai font-bold text-[34px]">
        {t('COACH_PACKAGE.SESSION_AND_ASSESSMENT')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_PACKAGE.SESSION_AND_ASSESSMENT_TXT')}
      </div>
      <div className="w-full font-satoshi rtl:font-Almarai mb-2 font-bold mt-9 text-xl">
        {t('COACH_PACKAGE.SELECT_SESSION_AND_ASSESSMENT')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 ">
        {t('COACH_PACKAGE.SELECT_SESSION_AND_ASSESSMENT_TXT')}
      </div>
      <Formik
        innerRef={assessmentFormRef}
        initialValues={assessmentPackageFormValue}
        validationSchema={packageAssessmentValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          dispatch(setAssessmentPackageFormData(values));
          const error: any = errors;

          return (
            <Form>
              {/* <div className="package-assessment-row">
                <div className=" package-assessment-input">
                  <Input
                    id="FormSubTitle"
                    type="text"
                    name="category"
                    placeholder={t('COACH_PACKAGE.CATEGORY')}
                    value={'ASSESSMENT'}
                  />
                </div>
                <div className=" package-assessmentTitle-input">
                  <SelectDropdown
                    id="FormAssessmentTitle"
                    name={`assessmentId`}
                    placeholder={t('COACH_PACKAGE.ASSESSMENT_TITLE')}
                    options={assessmentTitlesOptions}
                    error={touched.assessmentId}
                    errormessage={errors.assessmentId}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue(`assessmentId`, opt.value);
                    }}
                    controlShouldRenderValue={true}
                    isValue={values.assessmentId}
                    optional={0}
                    defaultValue={
                      values.assessmentId && {
                        label: getAssessmentLabel(values.assessmentId),
                        value: values.assessmentId,
                      }
                    }
                  />
                </div>
                <div className=" package-week-input">
                  <SelectDropdown
                    id="FormWeek"
                    name={`durationInWeek`}
                    placeholder={t('COACH_PACKAGE.WEEK')}
                    options={weekOption}
                    error={touched.durationInWeek}
                    errormessage={errors.durationInWeek}
                    isSearchable={false}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue(`durationInWeek`, opt.value);
                    }}
                    controlShouldRenderValue={true}
                    isValue={values.durationInWeek}
                    optional={0}
                    defaultValue={
                      values.durationInWeek && {
                        label: `${values.durationInWeek} weeks`,
                        value: values.durationInWeek,
                      }
                    }
                  />
                </div>
              </div>
              <div className="package-session-row  ">
                <div className="package-assessment-input">
                  <Input
                    id="FormSubTitle"
                    type="text"
                    name="category"
                    placeholder={t('COACH_PACKAGE.CATEGORY')}
                    value={'SESSION'}
                  />
                </div>
                <div className=" package-sessionTitle-input">
                  <SelectDropdown
                    id="FormSessionTitle"
                    name={`sessionId`}
                    placeholder={t('COACH_PACKAGE.SESSION_TITLE')}
                    options={sessionTitlesOptions}
                    error={touched.sessionId}
                    errormessage={errors.sessionId}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue(`sessionId`, opt.value);
                    }}
                    controlShouldRenderValue={true}
                    isValue={values.sessionId}
                    optional={0}
                    defaultValue={
                      values.sessionId && {
                        label: getSessionLabel(values.sessionId),
                        value: values.sessionId,
                      }
                    }
                  />
                </div>
                <div className=" package-days-input">
                  <SelectDropdown
                    id="FormDay"
                    name={`durationInDays`}
                    placeholder={t('COACH_PACKAGE.DAYS')}
                    isSearchable={false}
                    options={dayOption}
                    error={touched.durationInDays}
                    errormessage={errors.durationInDays}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue(`durationInDays`, opt.value);
                    }}
                    controlShouldRenderValue={true}
                    isValue={values.durationInDays}
                    optional={0}
                    defaultValue={
                      values.durationInDays !== '' && {
                        label: `${values.durationInDays} days`,
                        value: values.durationInDays,
                      }
                    }
                  />
                </div>
                <div className="package-days-input">
                  <SelectDropdown
                    id="FormHours"
                    name={`durationInHr`}
                    isSearchable={false}
                    placeholder={t('COACH_PACKAGE.HOURS')}
                    options={hoursOption}
                    error={touched.durationInHr}
                    errormessage={errors.durationInHr}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue(`durationInHr`, opt.value);
                    }}
                    controlShouldRenderValue={true}
                    isValue={values.durationInHr}
                    optional={0}
                    defaultValue={
                      values.durationInHr !== '' && {
                        label: `${values.durationInHr} hours`,
                        value: values.durationInHr,
                      }
                    }
                  />
                </div>
              </div> */}
              <FieldArray
                name="assessmentValue"
                render={(arrayHelpers) => {
                  const formData =
                    values?.assessmentValue?.length > 0 &&
                    values?.assessmentValue;

                  return (
                    <div className="border-b-[1px] border-[#cccccc] pb-6">
                      {formData && formData?.length > 0
                        ? formData?.map((formData: any, i: number) => {
                            return (
                              <div className={i === 0 ? 'pt-8' : ''}>
                                {i > 0 && (
                                  <div className="flex justify-end text-end border-info mt-8 lg:border-t-2">
                                    <button
                                      onClick={() => {
                                        arrayHelpers.remove(i);
                                        // removeFileFromArray(i);
                                      }}
                                      className="mb-5 mt-9 text-12 font-bold text-red rtl:font-Almarai border-2 border-red px-4 rounded-md py-2"
                                    >
                                      {t('COACHING_DETAILS.REMOVE')}
                                    </button>
                                  </div>
                                )}
                                <div className="package-assessment-row">
                                  <div className="package-assessment-input mb-8">
                                    <SelectDropdown
                                      id="FormCategory"
                                      name={`assessmentValue.${i}.category`}
                                      placeholder={t('COACH_PACKAGE.CATEGORY')}
                                      isSearchable={false}
                                      options={categoryOptions}
                                      onBlur={handleBlur}
                                      onChange={(opt: any) => {
                                        setFieldValue(
                                          `assessmentValue.${i}.category`,
                                          opt.value
                                        );
                                        if (opt.value === 'SESSION') {
                                          setFieldValue(
                                            `assessmentValue.${i}.assessmentId`,
                                            ''
                                          );
                                        } else if (opt.value === 'ASSESSMENT') {
                                          setFieldValue(
                                            `assessmentValue.${i}.sessionId`,
                                            ''
                                          );
                                        }
                                      }}
                                      controlShouldRenderValue={true}
                                      isValue={
                                        values.assessmentValue[i].category
                                      }
                                      optional={1}
                                      defaultValue={
                                        values.assessmentValue[i].category && {
                                          label: t(
                                            `COACH_PACKAGE.${values.assessmentValue[i].category}`
                                          ),
                                          value:
                                            values.assessmentValue[i].category,
                                        }
                                      }
                                    />
                                  </div>
                                  {values.assessmentValue[i].category ===
                                  'ASSESSMENT' ? (
                                    <>
                                      <div className=" package-assessmentTitle-input-2">
                                        <SelectDropdown
                                          id="FormAssessmentTitle"
                                          name={`assessmentValue.${i}.assessmentId`}
                                          placeholder={t(
                                            'COACH_PACKAGE.ASSESSMENT_TITLE'
                                          )}
                                          options={assessmentTitlesOptions}
                                          error={
                                            touched &&
                                            touched?.assessmentValue?.length &&
                                            touched?.assessmentValue[i]
                                              .assessmentId
                                          }
                                          errormessage={
                                            error &&
                                            error?.assessmentValue?.length &&
                                            error?.assessmentValue[i]
                                              ?.assessmentId
                                          }
                                          onBlur={handleBlur}
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `assessmentValue.${i}.assessmentId`,
                                              opt.value
                                            );
                                          }}
                                          controlShouldRenderValue={true}
                                          isValue={
                                            values.assessmentValue[i]
                                              .assessmentId
                                          }
                                          optional={0}
                                          defaultValue={
                                            values.assessmentValue[i]
                                              .assessmentId && {
                                              label: getAssessmentLabel(
                                                values.assessmentValue[i]
                                                  .assessmentId as string
                                              ),
                                              value:
                                                values.assessmentValue[i]
                                                  .assessmentId,
                                            }
                                          }
                                        />
                                      </div>
                                      {/* <div className=" package-week-input">
                                        <SelectDropdown
                                          id="FormWeek"
                                          name={`assessmentValue.${i}.durationInWeek`}
                                          placeholder={t('COACH_PACKAGE.WEEK')}
                                          isSearchable={false}
                                          options={weekOption}
                                          onBlur={handleBlur}
                                          error={
                                            touched &&
                                            touched?.assessmentValue?.length &&
                                            (touched?.assessmentValue[i].durationInWeek)
                                          }
                                          errormessage={
                                            error &&
                                            error?.assessmentValue?.length &&
                                            (error?.assessmentValue[i]?.durationInWeek)
                                          }
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `assessmentValue.${i}.durationInWeek`,
                                              opt.value
                                            );
                                          }}
                                          controlShouldRenderValue={true}
                                          isValue={
                                            values.assessmentValue[i]
                                              .durationInWeek
                                          }
                                          optional={0}
                                          defaultValue={
                                            values.assessmentValue[i]
                                              .durationInWeek && {
                                              label: `${values.assessmentValue[i].durationInWeek} weeks`,
                                              value:
                                                values.assessmentValue[i]
                                                  .durationInWeek,
                                            }
                                          }
                                        />
                                      </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <div className=" package-assessmentTitle-input-2">
                                        <SelectDropdown
                                          id="FormSessionTitle"
                                          name={`assessmentValue.${i}.sessionId`}
                                          placeholder={t(
                                            'COACH_PACKAGE.SESSION_TITLE'
                                          )}
                                          options={sessionTitlesOptions}
                                          error={
                                            touched &&
                                            touched?.assessmentValue?.length &&
                                            touched?.assessmentValue[i]
                                              ?.sessionId
                                          }
                                          errormessage={
                                            error &&
                                            error?.assessmentValue?.length &&
                                            error?.assessmentValue[i]?.sessionId
                                          }
                                          onBlur={handleBlur}
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `assessmentValue.${i}.sessionId`,
                                              opt.value
                                            );
                                          }}
                                          controlShouldRenderValue={true}
                                          isValue={
                                            values.assessmentValue[i].sessionId
                                          }
                                          optional={0}
                                          defaultValue={
                                            values.assessmentValue[i]
                                              .sessionId && {
                                              label: getSessionLabel(
                                                values.assessmentValue[i]
                                                  .sessionId as string
                                              ),
                                              value:
                                                values.assessmentValue[i]
                                                  .sessionId,
                                            }
                                          }
                                        />
                                      </div>
                                      {/* <div className=" package-days-input">
                                        <SelectDropdown
                                          id="Formduration"
                                          name={`assessmentValue.${i}.duration`}
                                          placeholder={t('COACH_PACKAGE.DURATION')}
                                          isSearchable={false}
                                          options={hourDuration}
                                          onBlur={handleBlur}
                                          error={
                                            touched &&
                                            touched?.assessmentValue?.length &&
                                            (touched?.assessmentValue[i].duration)
                                          }
                                          errormessage={
                                            error &&
                                            error?.assessmentValue?.length &&
                                            (error?.assessmentValue[i]?.duration)
                                          }
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `assessmentValue.${i}.duration`,
                                              opt.value
                                            );
                                          }}
                                          controlShouldRenderValue={true}
                                          isValue={
                                            values.assessmentValue[i]
                                              .duration
                                          }
                                          optional={0}
                                          defaultValue={
                                            values.assessmentValue[i]
                                              .duration !== '' && {
                                              label: `${values.assessmentValue[i].duration} ${t('MINS')}`,
                                              value:
                                                values.assessmentValue[i]
                                                  .duration,
                                            }
                                          }
                                        />
                                      </div>
                                      <div className="package-days-input">
                                        <SelectDropdown
                                          id="FormretakePeriod"
                                          name={`assessmentValue.${i}.retakePeriod`}
                                          placeholder={t('COACH_SESSION.HOUR')}
                                          isSearchable={false}
                                          options={retakePeriodOptions}
                                          onBlur={handleBlur}
                                          error={
                                            touched &&
                                            touched?.assessmentValue?.length &&
                                            (touched?.assessmentValue[i].retakePeriod)
                                          }
                                          errormessage={
                                            error &&
                                            error?.assessmentValue?.length &&
                                            (error?.assessmentValue[i]?.retakePeriod)
                                          }
                                          onChange={(opt: any) => {
                                            setFieldValue(
                                              `assessmentValue.${i}.retakePeriod`,
                                              opt.value
                                            );
                                          }}
                                          controlShouldRenderValue={true}
                                          isValue={
                                            values.assessmentValue[i]
                                              .retakePeriod
                                          }
                                          optional={0}
                                          defaultValue={
                                            values.assessmentValue[i]
                                              .retakePeriod !== '' && {
                                              label: `${values.assessmentValue[i].retakePeriod} ${t('PREFORMANCE.DAYS')}`,
                                              value:
                                                values.assessmentValue[i]
                                                  .retakePeriod,
                                            }
                                          }
                                        />
                                      </div> */}
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        : null}
                      <button
                        type="button"
                        className="add_more "
                        onClick={() => {
                          arrayHelpers.push({
                            category: 'ASSESSMENT',
                            assessmentId: '',
                            sessionId: '',
                            duration: '',
                            retakePeriod: '',
                          });
                        }}
                      >
                        {t('ADD_MORE')}
                      </button>
                    </div>
                  );
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <div className="w-full">
        {isProcessingPackages
          ? getSessionCardLoader()
          : assessmentSession.map((item: myCoachUploadsInterface) => (
              <SessionsCard data={item} />
            ))}

        {totalPrice !== 0 && (
          <>
            {' '}
            <div className="w-full flex justify-end mt-4 font-bold text-[#687E8E] text-sm">
              {t('COACH_PACKAGE.TOTAL')}
            </div>
            <div className="w-full flex justify-end mt-4 font-bold text-primary text-3xl">
              {t('SAR')}&nbsp;{totalPrice?.toFixed(2)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const SessionsAndAssessment = styled(Component)``;

export default SessionsAndAssessment;

import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { performanceInfo } from '../../coachSlices/coachPerformance';
import { Image } from '../../components/Image';
import Table from '../coachTable';

interface IClientsTableProps {
  coachRevenueClients: any;
  isLoading: boolean;
}
const ClientsTable: FC<IClientsTableProps> = ({
  coachRevenueClients,
  isLoading,
}): JSX.Element => {
  const [t] = useTranslation('common');
  const { clientsFilter } = useSelector(performanceInfo);
  const header =
    clientsFilter?.serviceStatusFilter === ''
      ? [
          {
            label: t('PREFORMANCE.NAME'),
            value: 'name',
          },
          {
            label: t('PREFORMANCE.ENROLLED_ITEM'),
            value: 'enrolledItem',
          },

          {
            label: t('PREFORMANCE.PURCHASED_DATE'),
            value: 'purchasedDate',
          },
          {
            label: t('PREFORMANCE.OFFERING'),
            value: 'offering',
          },
          // {
          //   label: t('PREFORMANCE.RATING'),
          //   value: 'rating',
          // },
          {
            label: t('PREFORMANCE.PRICE'),
            value: 'price',
          },
          {
            label: t('PREFORMANCE.REVENUE'),
            value: 'revenue',
          },
        ]
      : clientsFilter?.serviceStatusFilter === 'CLOSED'
      ? [
          {
            label: t('PREFORMANCE.NAME'),
            value: 'name',
          },
          {
            label: t('PREFORMANCE.ENROLLED_ITEM'),
            value: 'enrolledItem',
          },

          {
            label: t('PREFORMANCE.CATEGORY'),
            value: 'category',
          },
          {
            label: t('PREFORMANCE.START_DATE'),
            value: 'startDate',
          },
          {
            label: t('PREFORMANCE.END_DATE'),
            value: 'endDate',
          },
          {
            label: t('PREFORMANCE.PRICE'),
            value: 'price',
          },
        ]
      : [
          {
            label: t('PREFORMANCE.NAME'),
            value: 'name',
          },
          {
            label: t('PREFORMANCE.ENROLLED_ITEM'),
            value: 'enrolledItem',
          },

          {
            label: t('PREFORMANCE.CATEGORY'),
            value: 'category',
          },
          {
            label: t('PREFORMANCE.START_DATE'),
            value: 'startDate',
          },
          {
            label: t('PREFORMANCE.END_DATE'),
            value: 'endDate',
          },
          {
            label: t('PREFORMANCE.REMAINING_DAYS'),
            value: 'remainingDays',
          },
        ];
  const data = coachRevenueClients?.map((item: any) => {
    return {
      ...item,
      name: (
        <div className="flex flex-row text-[14px] font-bold gap-2 items-center ">
          {' '}
          <Image
            id={item?.clientProfilePicId}
            propClass={'w-10 h-10 rounded-full bg-lightBlack'}
            bgClass={
              'w-10 h-10 rounded-full flex items-center justify-center bg-lightBlack'
            }
            bgImgUrl={'/assets/profile/avatar.svg'}
          />
          {item?.clientName}
        </div>
      ),
      enrolledItem: item?.data?.title,
      purchasedDate: item?.startDate,

      offering:
        item?.data?.serviceType !== 'SESSION'
          ? '--'
          : `${item?.data?.duration} ${t('MINS')}`,
      rating: item?.data?.rating,
      price: `${t('SAR')} ${item?.data?.price}`,
      startDate: moment(item?.startDate).locale('en').format('MMM DD, YYYY'),
      endDate: moment(item?.endDate).locale('en').format('MMM DD, YYYY'),
      category: item?.data?.serviceType,
      revenue: item?.revenue,
      remainingDays: (
        <div className="text-[12px] font-bold ">
          {item?.remainingDays} {t('PREFORMANCE.DAYS')}
        </div>
      ),
    };
  });

  return (
    <div className="md:block hidden">
      <Table
        header={header}
        data={data}
        loading={isLoading}
        category={''}
        profilePicture={undefined}
        profilePictureLoading={false}
      />
    </div>
  );
};

export default ClientsTable;

import { myUploadsParamType } from '../coachHelpers/query';
import { PackagePayloadInterface } from '../coachInterfaces/packageInterface';
import {
  deleteApi,
  get,
  post,
  postImageFileWithToken,
  put,
} from './apiService';

export const createPackageApi = async (data: PackagePayloadInterface) => {
  return await post('/packages', data);
};
export const editPackageApi = async (
  data: PackagePayloadInterface,
  id: string
) => {
  return await put('/packages/' + id, data);
};
export const ImageUploadPackage = async (payload: any) => {
  return await postImageFileWithToken('/files', payload);
};
export const getAssessments = async (
  id: string,
  params?: myUploadsParamType
) => {
  return await get('/assessments?coachId=' + id, params);
};
export const getSession = async (id: string, params?: myUploadsParamType) => {
  return await get('/sessions?coachId=' + id, params);
};
export const getAssessmentsById = async (
  id: string,
  params?: myUploadsParamType
) => {
  return await get('/assessments/' + id, params);
};
export const getSessionById = async (
  id: string,
  params?: myUploadsParamType
) => {
  return await get('/sessions/' + id, params);
};
export const getPackage = async (id: string, params?: myUploadsParamType) => {
  return await get('/packages?coachId=' + id, params);
};
export const savePackageApi = async (data: {
  draftType: string;
  request: PackagePayloadInterface;
}) => {
  return await post('/coach-service-draft', data);
};
export const saveDraftPackageApi = async (
  data: {
    draftType: string;
    request: PackagePayloadInterface;
  },
  id: string
) => {
  return await put('/coach-service-draft/' + id, data);
};
export const deletePackageApi = async (id: string) => {
  return await deleteApi('/packages/' + id);
};
export const deleteAssessmentApi = async (id: string) => {
  return await deleteApi('/assessments/' + id);
};
export const deleteSessionApi = async (id: string) => {
  return await deleteApi('/sessions/' + id);
};
export const listDurationApi = async () => {
  return await get('/lists?name=PACKAGE_DURATION');
};
export const packageById = async (id: string) => {
  return await get('/packages/' + id);
};
export const assessmentById = async (id: string) => {
  return await get('/assessments/' + id);
};
export const sessionById = async (id: string) => {
  return await get('/sessions/' + id);
};

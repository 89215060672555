import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AccountListSideBar } from '../../coachComponents';

const Component = () => {
  const [t] = useTranslation('common');
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  const handleSubmit = () => {};

  return (
    <div className="mt-6 lg:mt-12 w-full h-fit">
      <div className="bg-white mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar handleSubmit={handleSubmit} />
        <div className=" lg:p-8 p-4 col-span-4 lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.NOTIFICATION.NOTIFICATION')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.NOTIFICATION.NOTIFICATION_DEC')}
          </p>
          <div className="border border-info mb-8"></div>
          <div className="flex mt-6 justify-between lg:items-center">
            <div>
              <h1 className="text-18 mb-2 font-bold font-satoshi rtl:font-Almarai text-secondary">
                {t('PROFILE.NOTIFICATION.TITLE')}
              </h1>
              <p className="text-16 font-normal font-general rtl:font-Almarai text-gray">
                {t('PROFILE.NOTIFICATION.DEC')}
              </p>
            </div>
            <div className="">
              <label
                htmlFor="toogleA"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    onClick={() => setToggle1(!toggle1)}
                    id="toogleA"
                    type="checkbox"
                    className="sr-only"
                  />
                  <div className=" dot-2 w-10 h-4 bg-info rounded-full shadow-inner"></div>
                  <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                </div>
              </label>
            </div>
          </div>
          <div className="flex mt-6 justify-between lg:items-center">
            <div>
              <h1 className="text-18 mb-2 font-bold font-satoshi rtl:font-Almarai text-secondary">
                {t('PROFILE.NOTIFICATION.TITLE')}
              </h1>
              <p className="text-16 font-normal font-general rtl:font-Almarai text-gray">
                {t('PROFILE.NOTIFICATION.DEC')}
              </p>
            </div>
            <div className="">
              <label
                htmlFor="toogleB"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    onClick={() => setToggle2(!toggle2)}
                    id="toogleB"
                    type="checkbox"
                    className="sr-only"
                  />
                  <div className=" dot-2 w-10 h-4 bg-info rounded-full shadow-inner"></div>
                  <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                </div>
              </label>
            </div>
          </div>
          <div className="flex mt-6 justify-between lg:items-center ">
            <div>
              <h1 className="text-18 mb-2 font-bold font-satoshi rtl:font-Almarai text-secondary">
                {t('PROFILE.NOTIFICATION.TITLE')}
              </h1>
              <p className="text-16 font-normal font-general rtl:font-Almarai text-gray">
                {t('PROFILE.NOTIFICATION.DEC')}
              </p>
            </div>
            <div className="">
              <label
                htmlFor="toogleC"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <input
                    onClick={() => setToggle3(!toggle3)}
                    id="toogleC"
                    type="checkbox"
                    className="sr-only"
                  />
                  <div className=" dot-2 w-10 h-4 bg-info rounded-full shadow-inner"></div>
                  <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

const Notification = styled(Component)``;

export default Notification;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CartCard from '../../clientComponents/cartCard';
import { purchaseViewHistory, selectPayment } from '../../clientSlices/payment';
import OverlayLoader from '../../components/overlayLoader';

const PurchaseHistory = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch<any>();
  useEffect(() => {
    const payload: any = {};
    payload['purchaseStatus'] = 'COMPLETED';
    payload['page'] = 0;
    payload['size'] = 5;
    payload['sort'] = 'created_at,desc';
    dispatch(purchaseViewHistory(payload));
  }, []);

  const { purchaseHistoryList, isProcessingpayment, isInvoicePdf } =
    useSelector(selectPayment);
  return (
    <>
      <OverlayLoader loader={isInvoicePdf} />
      <div className="lg:mx-24 mx-5 pb-10">
        <h1 className="mt-20 mb-12 font-satoshi rtl:font-Almarai lg:text-48 text-28 font-bold ">
          {t('LANDING_PAGE_AFTER_LOGIN.PURCHASE_HISTORY')}
        </h1>
        <CartCard
          cardData={purchaseHistoryList}
          category={'purchaseHistory'}
          isLoading={isProcessingpayment}
        />

        {!isProcessingpayment ? (
          purchaseHistoryList.length ? null : (
            <div className="justify-center flex h-[57vh] items-center flex-col rtl:font-Almarai">
              {t('EMPTY_MESSAGES.PURCHASE_EMPTY')}
              <div>
                <Link to="/search-result" className="text-primary">
                  {t('CLICK_HERE')}
                </Link>{' '}
                {t('EMPTY_MESSAGES.CART_ADD')}
              </div>
            </div>
          )
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default PurchaseHistory;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../clientHelpers/resizeWindow';
import './index.css';

export const AboutUsPageBody = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dummyAboutusData = [
    {
      image: '/assets/aboutUsPage/uploadIcon.svg',
      vision: t('ABOUT_US_PAGE.VISION'),

      description: t('ABOUT_US_PAGE.VISION_DESCRIPTION'),
    },
    {
      image: '/assets/aboutUsPage/uploadIcon.svg',
      vision: t('ABOUT_US_PAGE.MISSION'),

      description: t('ABOUT_US_PAGE.MISSION_DESCRIPTION'),
    },
    {
      image: '/assets/aboutUsPage/uploadIcon.svg',
      vision: t('ABOUT_US_PAGE.VALUES'),

      description: t('ABOUT_US_PAGE.VALUES_DESCRIPTION'),
    },
  ];
  const [width] = useWindowSize();

  return (
    <>
      <section className="bg-white w-full ">
        <div
          className="px-5 md:px-20  flex flex-col justify-around bg-contain xl:bg-cover h-fit  lg:pt-12 bg-no-repeat mt-10"
          style={{
            backgroundImage: `url(${
              width <= 760
                ? '/assets/aboutUsPage/mobileviewbg.svg'
                : '/assets/bodyAfterLogin/coverImage.svg'
            })`,
          }}
        >
          <span className=" w-fit  text-28 md:text-48  md:mt-5  mb-5  md:mb-[16px] font-satoshi rtl:font-Almarai  font-bold text-darkGrey">
            {t('ABOUT_US_PAGE.ABOUT_KUN')}
          </span>
          <div className="flex  justify-center flex-col lgmd:flex-row ">
            <div className="flex-shrink items-center ">
              <div className="flex flex-col justify-between h-fit lgmd:w-[40vw] w-full md:mt-[40px] mt-[20px]  text-28 md:text-48  mb-5  md:mb-[16px] font-satoshi rtl:font-Almarai  font-bold text-darkGrey ">
                <div className=" "></div>
                <div className=" "></div>

                <div>
                  <p className="p-text text-16 bg-[#ffffff] rounded  xl:w-/12  mr-4  mt-4  md:text-left  ltr:text-left rtl:text-right">
                    {t('LANDING_PAGE_AFTER_LOGIN.ABOUT_SUB_TITLE2')}
                  </p>
                </div>
              </div>
              {/* <div className=" mt-8 md:mt-0 mb-11 md:mb-14 lg:mb-[132px] flex item-center gap-5 ">
                <button className="bg-lightBlue text-white font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[50%] lg:w-[240px] h-[54px] rounded py-4  lg:px-auto">
                  {t('ABOUT_US_PAGE.BECOME_COCHES')}
                </button>
                <button className="bg-white text-black font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[50%] lg:w-[240px] h-[54px] rounded py-4  lg:px-auto border-[1px] border-black  text-darkGrey  ">
                  {t('ABOUT_US_PAGE.BECOME_COACH')}
                </button>
              </div> */}
              {pathname === '/about-us' && (
                <div className="md:w-full mt-12 md:mt-[-20px] mr-[0] md:mr-[10px] mb-11 md:mb-14 lg:mb-[132px] flex justify-between md:justify-start gap-0 md:gap-5 flex-row mr-6">
                  <button
                    onClick={() =>
                      navigate(
                        '/search-result?page=0&size=9&serviceType=COACHES'
                      )
                    }
                    className="bg-lightBlue text-white font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[47%] md:w-[240px]  h-[54px] rounded py-4  lg:px-auto"
                  >
                    {t('LANDING_PAGE_BEFORE_LOGIN.BROWSE_COACHES')}
                  </button>

                  <button
                    onClick={() =>
                      navigate('/coach/registration/personal-details')
                    }
                    className="bg-white text-black font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[47%] md:w-[240px]  h-[54px] rounded py-4  lg:px-auto border-[1px] border-black  text-darkGrey  "
                  >
                    {t('LANDING_PAGE_BEFORE_LOGIN.BECOME_COACH')}
                  </button>
                </div>
              )}
            </div>

            <div className=" flex w-full lgmd:justify-end justify-center  pr-0">
              <img
                className="object-cover"
                src="/assets/aboutUsPage/about_kun_image.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* jhsbejcberv */}
        <div
          className={`mt-7 px-5 md:px-20  ${
            width > 1171 && 'background-img-about '
          } `}
        >
          <div className="flex  flex-col lgmd:flex-row gap-4">
            {dummyAboutusData.map((value, index) => (
              <div
                key={index}
                className={`flex flex-col lgmd:w-[50%] w-[100%] border-[1px] border-borderColor  shadow-lg lgmd:shadow-none p-4 rounded-lg ${
                  width > 1171 && 'm-12 bg-white'
                } `}
              >
                <div>
                  <img className="w-[45px] p-1" src={value.image} alt=""></img>
                </div>
                <div className="p-1 text-black font-bold text-lg  text-darkGrey font-satoshi rtl:font-Almarai">
                  {value.vision}
                </div>
                <div className="p-1 text-greyText font-satoshi rtl:font-Almarai">
                  {value.description}{' '}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex pt-6 md:pt-16  mt-7 px-6 md:px-20">
          <div className="lgmd:flex-row flex-col flex gap-4">
            <img src="/assets/aboutUsPage/ourstory.jpg" alt=""></img>
            <div className="flex flex-col font-satoshi rtl:font-Almarai ">
              <div className="text-[34px] font-bold  text-darkGrey ">
                {t('ABOUT_US_PAGE.OUR_STORY')}
              </div>
              <div className="text-greyText">
                {t('ABOUT_US_PAGE.STORY_DESCRIPTION')}
              </div>
            </div>
          </div>
        </div>
        {pathname === '/about-us' && (
          <div className="flex  mb-10 mt-10 md:mt-20 mt-7 px-5 md:px-20 font-satoshi rtl:font-Almarai">
            <div className="flex lgmd:flex-row flex-col justify-between w-full">
              <div className="flex flex-col w-full lgmd:w-1/2">
                <div className="text-black font-bold w-full   text-darkGrey md:text-5xl text-3xl ">
                  {t('ABOUT_US_PAGE.SIGN_UP_TO_KUN')}
                </div>
                <div className="text-greyText pt-2 pb-2">
                  {t('ABOUT_US_PAGE.SIGN_UP_TO_KUN_NOW_DESCRIPTION')}
                </div>
              </div>
              <div className="flex lgmd:flex-row flex-col  md:p-4  items-center justify-cenr gap-4 ">
                <button
                  onClick={() =>
                    navigate('/search-result?page=0&size=9&serviceType=COACHES')
                  }
                  className="bg-lightBlue text-white font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[100%] md:w-[295px] h-[54px] rounded py-4  lg:px-auto"
                >
                  {t('ABOUT_US_PAGE.BROWSE_COACHES')}
                </button>

                <button
                  onClick={() =>
                    navigate('/kun/coach/registration/personal-details')
                  }
                  className="bg-white text-black font-satoshi rtl:font-Almarai font-bold text-14 md:text-18 w-[100%] md:w-[295px] h-[54px] rounded py-4  lg:px-auto border-[1px] border-black  text-darkGrey "
                >
                  {t('ABOUT_US_PAGE.BECOME_COACH')}
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

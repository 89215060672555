import { Form, Formik } from 'formik';
import jwt_decode from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../clientComponents/buttons';
import Date from '../../clientComponents/date';
import Input from '../../clientComponents/inputs';
import PhoneNumberInput from '../../clientComponents/phoneNumberInput/phoneNumberInput';
import SelectDropdown from '../../clientComponents/selectDropdown';
import {
  setNewPasswordValidationSchema,
  updateClientValidationSchema,
} from '../../clientHelpers';
import { getAccessToken } from '../../clientHelpers/localStorage';
import { getDefaultValue } from '../../clientHelpers/utility';
import { IUpdatePassword } from '../../clientInterfaces/profile';
import {
  deleteClientAccount,
  downloadProfilePicClient,
  listCities,
  listCountries,
  listLanguages,
  listProfileView,
  profileFile,
  selectClientProfile,
  updateClientPassword,
  updateProfile,
} from '../../clientSlices/profile';
import { timezoneList } from '../../coachSlices/registrationSlice';
import Modal from '../../components/modals/index';
import { useAppSelector } from '../../hooks';
import './index.css';
const ClientProfile = () => {
  const [t] = useTranslation('common');
  const [tab, setTab] = useState<string>('PROFILE_VIEW');
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useDispatch<any>();
  const decode: { id: number } = jwt_decode(getAccessToken() as string);
  useEffect(() => {
    dispatch(listProfileView());
    dispatch(listLanguages());
    dispatch(listCountries());
    dispatch(timezoneList());
  }, []);
  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="form-header  pb-12">
        {/* <LandingPageWithLoginHeader /> */}
        <div className="mdMax:mx-24 mx-4">
          <h2 className="mt-12 nav md:mt-[80px] font-satoshi rtl:font-Almarai h-[41px] text-34 font-bold mb-2">
            {t('CLIENT_PROFILE.ACCOUNT')}
          </h2>
          <NavBar tab={tab} setTab={setTab} t={t} lang={lang} />
          {tab === 'PROFILE_VIEW' ? (
            <Profile t={t} lang={lang} dispatch={dispatch} decode={decode} />
          ) : null}
          {tab === 'ACCOUNT_SECURITY' ? (
            <AccountSecurityForm t={t} dispatch={dispatch} />
          ) : null}
          {/* {tab === 'NOTIFICATION' ? <NotificationForm t={t} /> : null} */}
          {tab === 'CLOSE_ACCOUNT' ? (
            <CloseAccountForm dispatch={dispatch} decode={decode} t={t} />
          ) : null}
        </div>
      </div>
    </>
  );
};
const Profile = ({ t, lang, dispatch, decode }: any) => {
  const handleFormSubmit = (data: any) => {
    data['profilePicId'] = profileFileId && profileFileId;
    const payload = { id: decode['id'], payload: data };
    dispatch(updateProfile(payload));
  };
  useEffect(() => {
    dispatch(listProfileView());
    dispatch(listLanguages());
    dispatch(listCountries());
    dispatch(
      listCities(profileData?.country ? profileData?.country : 'Saudi Arabia')
    );
  }, []);

  const {
    profileData,
    profileFileId,
    languages,
    isProfileView,
    countries,
    cities,
    downloadProfilePictureClient,
  } = useSelector(selectClientProfile);
  const { timezoneValueList } = useAppSelector((state) => state.registration);
  useEffect(() => {
    if (profileData?.profilePicId) {
      dispatch(downloadProfilePicClient(profileData?.profilePicId));
    }
  }, [profileData]);
  useEffect(() => {
    dispatch(listCities(profileData?.country));
  }, [profileData]);
  let languageListEn: { label: ''; value: ''; label2: '' }[] = [];
  // let languageListAr: { label: ''; value: '' }[] = [];

  languages.map((item: any, index: number) => {
    languageListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
      label2: item.nameInArabic,
    });
  });

  let countryListEn: { label: ''; value: ''; label2: '' }[] = [];

  countries.map((item: any, index: number) => {
    countryListEn.push({
      label: item.countryNameInEnglish,
      value: item.countryNameInEnglish,
      label2: item.countryNameInArabic,
    });
  });

  let cityListEn: { label: ''; value: ''; label2: '' }[] = [];

  cities.map((item: any, index: number) => {
    cityListEn.push({
      label: item.city,
      value: item.city,
      label2: item.cityAr,
    });
  });
  let timeZoneOption: { label: ''; value: ''; label2: '' }[] = [];

  timezoneValueList.map((item: any, index: number) => {
    timeZoneOption.push({
      label: item.timezone,
      value: item.timezone,
      label2: item.timezone,
    });
  });
  const [languageInputValue, setLanguageInputValue] = useState('');
  const [countryInputValue, setCountryInputValue] = useState('');
  const [cityInputValue, setCityInputValue] = useState('');
  const [genderInputValue, setGenderInputValue] = useState('');

  const handleLanguageInputChange = (event: any) => {
    setLanguageInputValue(event);
  };
  const handleCountryInputChange = (event: any) => {
    setCountryInputValue(event);
  };
  const handleCityInputChange = (event: any) => {
    setCityInputValue(event);
  };
  const handleGenderInputChange = (event: any) => {
    setGenderInputValue(event);
  };

  const genderOption = [
    { label: 'MALE', value: 'MALE', label2: 'ذكر' },
    { label: 'FEMALE', value: 'FEMALE', label2: 'أنثى' },
  ];

  const handleImage = () => {
    document.getElementById('select-image')?.click();
  };

  const imageChange = (e: React.FormEvent<EventTarget> | any): void => {
    const imageFile = e.target?.files;
    dispatch(profileFile(imageFile));
  };
  useEffect(() => {
    if (profileFileId) dispatch(downloadProfilePicClient(profileFileId));
  }, [profileFileId]);
  const [iserveLoading, setIseferLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIseferLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [profileData]);
  return (
    <>
      {!isProfileView && !iserveLoading ? (
        <div className="flex flex-col mdlg:flex-row mt-6">
          <div className="h-full mdlg:flex-col flex flex-row w-full mdlg:w-3/12 gap-4">
            <img
              src={
                !downloadProfilePictureClient
                  ? '/assets/profile/profile-pic.svg'
                  : `data:image/png;base64,${downloadProfilePictureClient}`
              }
              alt="profile"
              className="xsm:w-[189px] xsm:h-[220px] w-[140px] h-[150px] rounded-[8px] object-cover "
            />
            <div className="h-[150px] xsmlg:h-[220px] mdlg:h-[59px] flex items-center">
              <Button
                onClick={handleImage}
                className="xsm:w-[189px] w-[152px] py-3 xsm:px-4 px-1 text-sm text-black font-bold font-satoshi rtl:font-Almarai rounded bg-lightGreen flex justify-center"
              >
                <>{t('CLIENT_PROFILE.UPDATE_PROFILE_PHOTO')}</>
              </Button>
              <>
                <input
                  id="select-image"
                  name="fileId"
                  className="form-control"
                  accept=".jpg,.jpeg,.png,.pdf,.svg"
                  type="file"
                  onChange={(e) => {
                    imageChange(e);
                  }}
                  style={{ display: 'none' }}
                />
              </>
            </div>
          </div>
          <div className="h-full mdlg:w-9/12 w-full mt-4 mdlg:mt-0">
            <p className="font-satoshi rtl:font-Almarai textflex-black font-bold mb-6">
              {t('CLIENT_PROFILE.BASIC_INFO')}
            </p>
            <Formik
              initialValues={{
                fullName: profileData?.fullName || '',
                email: profileData?.email || '',
                phoneNumber: profileData?.phoneNumber || '',
                description: profileData?.bioDescription || '',
                languages: profileData?.languages || [],
                // languages: ['English'] || [],
                dob: profileData?.dob || '',
                gender: profileData?.gender || '',
                addressLine1: profileData?.addressLine1 || '',
                addressLine2: profileData?.addressLine2 || '',
                country: profileData?.country || '',
                city: profileData?.city || '',
                countryCode: profileData?.countryCode,
                timezone: profileData?.timezone,
              }}
              validationSchema={updateClientValidationSchema}
              onSubmit={handleFormSubmit}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                submitForm,
                setFieldValue,
              }) => {
                return (
                  <Form>
                    <Input
                      id="FormFullName"
                      type="text"
                      name="fullName"
                      placeholder={t('CLIENT_PROFILE.FULL_NAME')}
                      onBlur={handleBlur}
                      error={touched.fullName && 1}
                      onChange={handleChange}
                      value={values.fullName}
                      errormessage={errors.fullName as string}
                      optional={false}
                    />
                    <div className="lg:flex justify-between gap-4">
                      <div className=" lg:w-6/12">
                        <Input
                          id="FormEmail"
                          type="email"
                          name="email"
                          placeholder={t('CLIENT_PROFILE.EMAIL')}
                          onBlur={handleBlur}
                          error={touched.email && 1}
                          onChange={handleChange}
                          value={values.email}
                          errormessage={errors.email as string}
                          optional={false}
                          disabled={true}
                        />
                      </div>
                      <div className=" lg:w-6/12 pr-[2px]">
                        {/* <Input
                        id="FormPhoneNumber"
                        type="number"
                        name="phoneNumber"
                        placeholder={t('CLIENT_PROFILE.PHONE_NUMBER')}
                        onBlur={handleBlur}
                        error={touched.phoneNumber && 1}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        errormessage={errors.phoneNumber as string}
                        optional={false}
                      /> */}
                        <PhoneNumberInput
                          id="FormPhoneNumber"
                          type="number"
                          name="phoneNumber"
                          codeValue={values.countryCode}
                          placeholder={t('CLIENT_PROFILE.PHONE_NUMBER')}
                          onBlur={handleBlur}
                          error={touched.phoneNumber && 1}
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber}
                          errormessage={errors.phoneNumber}
                          className="pl-[5px]    w-[40%]"
                          clearText={() => setFieldValue('phoneNumber', '')}
                        />
                      </div>
                    </div>
                    <Input
                      id="FormDescription"
                      className="h-[100px]"
                      type="text"
                      name="description"
                      placeholder={t('CLIENT_PROFILE.DESCRIPTION')}
                      onBlur={handleBlur}
                      error={touched.description && 1}
                      onChange={handleChange}
                      value={values?.description as string}
                      errormessage={errors.description as string}
                      optional={true}
                    />
                    <div className="lg:flex lg:gap-4">
                      <div className="lg:w-4/12 mb-8">
                        <SelectDropdown
                          name="language"
                          isMulti={true}
                          options={languageListEn}
                          placeholder={t('SPECIALIZATION_DETAILS.LANGUAGE')}
                          onBlur={handleBlur}
                          error={touched.languages && 1}
                          onChange={(opt: any) => {
                            setFieldValue(
                              'languages',
                              opt.map((item: any) => item.value)
                            );
                          }}
                          defaultValue={profileData?.languages?.map(
                            (ele: any) => {
                              return {
                                label: ele,
                                value: ele,
                                label2: getDefaultValue(ele, languageListEn)[0]
                                  ?.label2,
                              };
                            }
                          )}
                          getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                          }
                          errormessage={errors.languages}
                          id="FormLanguage"
                          isValue={values.languages?.length}
                          inputValue={languageInputValue}
                          onInputChange={handleLanguageInputChange}
                        />
                      </div>
                      <div className="lg:w-4/12">
                        <Date
                          type="date"
                          id="dob"
                          name="dob"
                          placeholder={t('CLIENT_PROFILE.DOB')}
                          onBlur={handleBlur}
                          optional={1}
                          error={touched.dob && 1}
                          value={values.dob}
                          onChange={(opt: any) => {
                            const date = `${opt?.year}-${
                              opt?.monthIndex + 1 < 10
                                ? `0${opt?.monthIndex + 1}`
                                : opt?.monthIndex + 1
                            }-${opt?.day < 10 ? `0${opt?.day}` : opt?.day}`;
                            setFieldValue(`dob`, date);
                          }}
                          errormessage={errors.dob as string}
                        />
                      </div>
                      <div className="lg:w-4/12 mb-8">
                        <SelectDropdown
                          id="FormGender"
                          name="gender"
                          placeholder={t('CLIENT_PROFILE.GENDER')}
                          options={genderOption}
                          onBlur={handleBlur}
                          error={touched.gender && 1}
                          onChange={(opt: any) => {
                            setFieldValue('gender', opt.value);
                          }}
                          errormessage={errors.gender}
                          isValue={values.gender}
                          inputValue={genderInputValue}
                          onInputChange={handleGenderInputChange}
                          getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                          }
                          defaultValue={
                            getDefaultValue(profileData.gender, genderOption)[0]
                          }
                        />
                      </div>
                    </div>

                    <Input
                      id="FormAddress1"
                      type="text"
                      name="addressLine1"
                      placeholder={t('CLIENT_PROFILE.ADDRESS1')}
                      onBlur={handleBlur}
                      error={touched.addressLine1 && 1}
                      onChange={handleChange}
                      value={values.addressLine1 as string}
                      errormessage={errors.addressLine1 as string}
                      optional={false}
                    />
                    <Input
                      id="FormAddress2"
                      type="text"
                      name="addressLine2"
                      placeholder={t('CLIENT_PROFILE.ADDRESS2')}
                      onBlur={handleBlur}
                      error={touched.addressLine2 && 1}
                      onChange={handleChange}
                      value={values.addressLine2 as string}
                      errormessage={errors.addressLine2 as string}
                      optional={false}
                    />
                    <div className="lg:flex gap-4">
                      <div className="lg:w-6/12 mb-8">
                        <SelectDropdown
                          id="FormCountry"
                          name="country"
                          placeholder={t('CLIENT_PROFILE.COUNTRY')}
                          options={countryListEn}
                          error={touched.country && 1}
                          errormessage={errors.country}
                          onBlur={handleBlur}
                          onChange={(opt: any) => {
                            setFieldValue('country', opt.value);
                            setFieldValue('city', '');

                            dispatch(listCities(opt.value));
                          }}
                          getOptionLabel={(option: any) =>
                              lang === 'en' ? option.label : option.label2
                          }
                          isValue={values.country}
                          // inputValue={countryInputValue}
                          // onInputChange={handleCountryInputChange}
                          // defaultValue={{
                          //   label: profileData?.country,
                          //   value: profileData?.country,
                          // }}
                          // getOptionLabel={(option: any) =>
                          //   lang === 'en' ? option.label : option.label2
                          // }
                          defaultValue={
                            getDefaultValue(
                              profileData?.country,
                              countryListEn
                            )[0]
                          }
                        />
                      </div>
                      <div className="lg:w-6/12 mb-8">
                        <SelectDropdown
                          id="FormCity"
                          name="city"
                          placeholder={t('CLIENT_PROFILE.CITY')}
                          options={cityListEn}
                          error={touched.city && 1}
                          errormessage={errors.city}
                          onBlur={handleBlur}
                          onChange={(opt: any) => {
                            setFieldValue('city', opt.value);
                          }}
                          isValue={values.city}
                          inputValue={cityInputValue}
                          onInputChange={handleCityInputChange}
                          getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                          }
                          defaultValue={
                            getDefaultValue(profileData?.city, cityListEn)[0]
                          }
                          // defaultValue={cityValue}
                          // defaultValue={{
                          //   label: profileData?.city,
                          //   value: profileData?.city,
                          // }}
                          isDisabled={values.country ? false : true}
                        />
                      </div>
                    </div>
                    <div className=" lg:w-6/12 mb-8">
                      <SelectDropdown
                        id="FormTimeZone"
                        name="timezone"
                        placeholder={t('PERSONAL_DETAILS.TIMEZONE')}
                        options={timeZoneOption}
                        onBlur={handleBlur}
                        error={touched.timezone && 1}
                        onChange={(opt: any) => {
                          setFieldValue('timezone', opt.value);
                        }}
                        errormessage={errors.timezone}
                        isValue={values.timezone}
                        controlShouldRenderValue={true}
                        // getOptionLabel={(option: any) =>
                        //   lang === 'en' ? option.label : option.label2
                        // }
                        defaultValue={
                          getDefaultValue(
                            profileData?.timezone,
                            timeZoneOption
                          )[0]
                        }
                        optional={0}
                      />
                    </div>
                    <div className="lg:w-3/12 w-full">
                      <Button
                        type="submit"
                        className={`btn-submit font-bold font-satoshi rtl:font-Almarai  mt-1 bg-primary`}
                      >
                        <>{t('CLIENT_PROFILE.SAVE_CHANGES')}</>
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : (
        <div className="flex justify-center h-[60vh] items-center">
          <div className="loader" />
        </div>
      )}
    </>
  );
};

const AccountSecurityForm = ({ t, dispatch }: any) => {
  const [passwordType, setPasswordType] = useState('password');

  const handleFormSubmit = (data: IUpdatePassword, onSubmitProps: any) => {
    dispatch(updateClientPassword({ ...data }));
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
  };
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const togglePassword = () => {
    if (passwordType === 'password') setPasswordType('text');
    else setPasswordType('password');
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === 'password') setConfirmPasswordType('text');
    else setConfirmPasswordType('password');
  };
  const [initialValue, setInitialValue] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  return (
    <>
      <p className="font-satoshi rtl:font-Almarai text-black text-2xl font-bold my-9">
        {t('CLIENT_PROFILE.ACCOUNT_TITLE')}
      </p>
      <Formik
        initialValues={initialValue}
        validationSchema={setNewPasswordValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          submitForm,
          setFieldValue,
        }) => (
          <Form>
            <div className="lg:w-9/12 w-full">
              <Input
                id="FormPassword"
                type={passwordType}
                name="newPassword"
                placeholder={t('CLIENT_PROFILE.ENTER_NEW_PASSWORD')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.newPassword && 1}
                value={values.newPassword}
                errormessage={errors.newPassword}
                optional={false}
                togglePassword={togglePassword}
                showEyeStrike={passwordType === 'password'}
                showEye={passwordType === 'text'}
              />
            </div>
            <div className="lg:w-9/12 w-full">
              <Input
                id="FormConfirmPassword"
                type={confirmPasswordType}
                name="confirmPassword"
                placeholder={t('CLIENT_PROFILE.RETYPE_NEW_PASSWORD')}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.confirmPassword && 1}
                value={values.confirmPassword}
                errormessage={errors.confirmPassword}
                optional={false}
                togglePassword={toggleConfirmPassword}
                showEyeStrike={confirmPasswordType === 'password'}
                showEye={confirmPasswordType === 'text'}
              />
            </div>
            <div className="mt-4 lg:w-3/12 w-full">
              <Button
                type="submit"
                className={`btn-submit font-bold font-satoshi rtl:font-Almarai   mt-1 bg-primary`}
              >
                <>{t('CLIENT_PROFILE.CHANGE_PASSWORD')}</>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const NotificationForm = ({ t }: any) => {
  return (
    <>
      <p className="font-satoshi rtl:font-Almarai text-black text-2xl font-bold mt-9">
        {t('CLIENT_PROFILE.NOTIFICATION_TITLE')}
      </p>
      <p className="font-satoshi rtl:font-Almarai text-black text-base font-bold mt-9">
        {t('CLIENT_PROFILE.NOTIFICATION_SUB_TITLE')}
      </p>
      <p className="font-normal  font-general rtl:font-Almarai  text-base text-gray">
        {t('CLIENT_PROFILE.NOTIFICATION_DESCRIPTION')}
      </p>
      <p className="font-satoshi rtl:font-Almarai text-black text-base font-bold mt-4">
        {t('CLIENT_PROFILE.NOTIFICATION_SUB_TITLE')}
      </p>
      <p className="font-normal  font-general rtl:font-Almarai  text-base text-gray">
        {t('CLIENT_PROFILE.NOTIFICATION_DESCRIPTION')}
      </p>
      <p className="font-satoshi rtl:font-Almarai text-black text-base font-bold mt-4">
        {t('CLIENT_PROFILE.NOTIFICATION_SUB_TITLE')}
      </p>
      <p className="font-normal  font-general rtl:font-Almarai  text-base text-gray">
        {t('CLIENT_PROFILE.NOTIFICATION_DESCRIPTION')}
      </p>
    </>
  );
};

const CloseAccountForm = ({ dispatch, decode, t }: any) => {
  const navigate = useNavigate();
  const [closeToggle, setCloseToggle] = useState(false);

  const deleteAccount = () => {
    dispatch(deleteClientAccount({ id: decode.id, history: navigate }));
    setCloseToggle(false);
  };
  const closeModal = () => {
    setCloseToggle(false);
  };
  return (
    <>
      <p className="font-satoshi rtl:font-Almarai text-black text-2xl font-bold my-9">
        {t('CLIENT_PROFILE.CLOSE_ACCOUNT_TITLE')}
      </p>
      <p className="text-closeRed font-bold  font-general rtl:font-Almarai  text-base ">
        {t('CLIENT_PROFILE.WARNING')}
        <span className="font-normal  font-general rtl:font-Almarai  text-base text-gray">
          {t('PROFILE.CLOSE_ACCOUNT.MESSAGE')}
        </span>
      </p>
      <div className="lgmd:w-2/12 w-full mt-6">
        <Button
          className="close-button bg-closeRed"
          onClick={() => setCloseToggle(true)}
        >
          <>{t('CLIENT_PROFILE.CLOSE_ACCOUNT_BUTTON')}</>
        </Button>
      </div>
      <Modal show={closeToggle} logOutClose={closeModal}>
        <div
          // onClick={() => deleteAccount()}
          className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center gap-7"
        >
          <img
            className="md:h-[73px] md:w-[73px] h-[60px] w-[60px]"
            src="/assets/clientProfile/closeAccount.svg"
            alt=""
          />
          <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-Almarai">
            {t('CLIENT_PROFILE.ARE_YOU_SURE')}{' '}
          </div>
          <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12">
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => setCloseToggle(false)}
                    className="h-13 border  text-gray  font-satoshi rtl:font-Almarai font-bold text-18 flex flex-grow items-center justify-center rounded  w-[100%] md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CANCEL')}
                  </div>
                }
              />
            </div>
            <div>
              <Button
                className={'w-full'}
                children={
                  <div
                    onClick={() => deleteAccount()}
                    className="h-13 bg-red text-white  font-satoshi rtl:font-Almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                  >
                    {t('CLIENT_PROFILE.CONFIRM')}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
const NavBar = ({ tab, setTab, t, lang }: any) => {
  const tabDatas = [
    { title: t('PROFILE_VIEW'), value: 'PROFILE_VIEW' },
    { title: t('ACCOUNT_SECURITY'), value: 'ACCOUNT_SECURITY' },
    // { title: t('NOTIFICATION'), value: 'NOTIFICATION' },
    { title: t('CLOSE_ACCOUNT'), value: 'CLOSE_ACCOUNT' },
  ];
  const tabDatasMobile = [
    { title: t('PROFILE_VIEW'), value: 'PROFILE_VIEW' },
    // { title: t('NOTIFICATION'), value: 'NOTIFICATION' },
    { title: t('CLOSE_ACCOUNT'), value: 'CLOSE_ACCOUNT' },
  ];

  const filtered = tabDatasMobile.map((element: any) => {
    if (
      !(tab === 'ACCOUNT_SECURITY' && element.value === 'PROFILE_VIEW') ||
      tab !== element.value
    ) {
      return element.value;
    }
  });
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const handleMenu = (element: any) => {
    setTab(element.value);
    setMobileMenu(false);
  };

  const handleTab = () => {
    if (tab === 'ACCOUNT_SECURITY')
      setTab((value: string) => (value = 'PROFILE_VIEW'));
    else setTab((value: string) => (value = tab));
  };
  const reference: any = useRef();
  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (e.path[0] !== reference.current) {
        mobileMenu && setMobileMenu(false);
      }
    };
    document.body.addEventListener('click', closeDropdown);
    return document.body.addEventListener('click', closeDropdown);
  }, []);

  return (
    <>
      <div
        className={`text-sm font-medium text-center text-gray-500 selected-tab  mdMax:flex hidden`}
      >
        <ul className="flex flex-row w-full gap-5  mt-4">
          {tabDatas.map((item: any, index: number) => {
            return (
              <li
                key={`${index}-tab`}
                className={`  ${
                  item.value === tab
                    ? 'text-black selected border-b-[2px] border-primary   pb-3'
                    : 'text-gray   '
                }`}
                onClick={() => setTab(item.value)}
              >
                <div
                  className={`rtl:font-Almarai inline-block cursor-pointer   hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 `}
                >
                  {item.title}
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className={`text-sm font-medium text-center text-gray-500 selected-tab  justify-between  mdMax:hidden flex`}
      >
        <ul className="flex flex-row w-full">
          <li className="mr-1" onClick={handleTab}>
            <span
              className={`inline-block cursor-pointer p-[0.75rem] h-full flex items-center xsm:p-4 rounded-t-lg border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 
                  ${
                    tab === 'ACCOUNT_SECURITY'
                      ? 'text-gray'
                      : 'text-black border-b-[2px] border-primary selected'
                  }`}
            >
              {tab === 'ACCOUNT_SECURITY' ? t('PROFILE_VIEW') : t(tab)}
            </span>
          </li>
          <li className="mr-1" onClick={() => setTab('ACCOUNT_SECURITY')}>
            <span
              className={`inline-block cursor-pointer p-[0.75rem] h-full flex items-center xsm:p-4 rounded-t-lg border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 
                ${
                  tab === 'ACCOUNT_SECURITY'
                    ? 'text-black border-b-[2px] border-primary selected '
                    : 'text-gray'
                }`}
            >
              {t('ACCOUNT_SECURITY')}
            </span>
          </li>
        </ul>
        <span className="profile-mobile-menu pt-[13px]">
          {!mobileMenu ? (
            <img
              onClick={() => setMobileMenu(true)}
              className="mt-auto mb-auto cursor-pointer"
              src="/assets/homePageImages/menu-dots-icon.svg"
              alt="bell-icon"
              id="click"
              ref={reference}
            />
          ) : null}
          {mobileMenu && (
            <div
              className="profile-dropdown-container shadow-lg ltr:right-0 rtl:left-0 z-50 "
              onMouseLeave={() => setMobileMenu(false)}
            >
              {tabDatasMobile.map((element: any, i) => (
                <div key={i}>
                  {tab !== element.value && (
                    <div
                      onClick={() => {
                        handleMenu(element);
                      }}
                      className="h-10 border-b-[1px] border-[#38383840] w-[213px] flex flex-row"
                    >
                      <div className="h-full w-[3px] " />
                      <div className="h-full font-general rtl:font-Almarai font-normal text-base w-full px-6 flex items-center cursor-pointer ">
                        {element.title}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </span>
      </div>
    </>
  );
};

export default ClientProfile;

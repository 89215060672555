import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postRating } from '../clientServices/messages';
import { showAlert } from '../slice/alert';
import { RootState } from '../store';

interface IRatingSlice {
  isRatingModalCoachOpen: boolean;
  isRatingModalKunOpen: boolean;
  isRatingModalServiceOpen: boolean;
  isProcessingRating: boolean;
}

const initialState: IRatingSlice = {
  isRatingModalCoachOpen: false,
  isRatingModalKunOpen: false,
  isRatingModalServiceOpen: false,
  isProcessingRating: false,
};
export const ratingSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {
    setRatingModalCoachOpen: (state, action: PayloadAction<boolean>) => {
      state.isRatingModalCoachOpen = action.payload;
    },
    setRatingModalKunOpen: (state, action: PayloadAction<boolean>) => {
      state.isRatingModalKunOpen = action.payload;
    },
    setRatingModalServiceOpen: (state, action: PayloadAction<boolean>) => {
      state.isRatingModalServiceOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postUserRating.pending, (state) => {
        state.isProcessingRating = true;
      })
      .addCase(postUserRating.fulfilled, (state, action) => {
        state.isProcessingRating = false;
      })
      .addCase(postUserRating.rejected, (state, action: any) => {
        state.isProcessingRating = false;
      });
  },
});

export const postUserRating = createAsyncThunk(
  'rating/postUserRating',
  async (data: any, thunkAPI) => {
    try {
      const response = await postRating(data);
      thunkAPI.dispatch(setRatingModalCoachOpen(false));
      thunkAPI.dispatch(setRatingModalKunOpen(false));
      thunkAPI.dispatch(setRatingModalServiceOpen(false));
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(setRatingModalCoachOpen(false));
      thunkAPI.dispatch(setRatingModalKunOpen(false));
      thunkAPI.dispatch(setRatingModalServiceOpen(false));
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const { setRatingModalCoachOpen,setRatingModalKunOpen,setRatingModalServiceOpen } = ratingSlice.actions;
export const selectRating = (state: RootState) => state.rating;
export const ratingReducer = ratingSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBankingInterface } from '../../coachInterfaces/profileInterface';
import {
  deleteBanking,
  getBankingById,
  getBankingInfo,
  postBankingInfo,
  putBankingInfo,
} from '../../coachServices/authenticationService';
import { showAlert } from '../../slice/alert';

const initialState: any = {
  isBankingInfoUpdateSuccess: false,
  bankingDetails: [],
  bankingDetailsById: [],
  isBankingInfoAddedSuccess: false,
  isBankingInfoDeleteSuccess: false,
};

export const bankingInfoSlice = createSlice({
  name: 'bankingInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBankingInfo.pending, (state) => {})
      .addCase(getAllBankingInfo.fulfilled, (state, action) => {
        state.bankingDetails = action?.payload?.data[0].content;
      })
      .addCase(getAllBankingInfo.rejected, (state) => {
        state.bankingDetails = [];
      })
      .addCase(getBankingInfoById.pending, (state) => {})
      .addCase(getBankingInfoById.fulfilled, (state, action) => {
        state.bankingDetailsById = action?.payload?.data[0];
      })
      .addCase(getBankingInfoById.rejected, (state) => {
        state.bankingDetailsById = [];
      })
      .addCase(addBankingInfo.pending, (state) => {
        state.isBankingInfoAddedSuccess = false;
      })
      .addCase(addBankingInfo.fulfilled, (state, action) => {
        state.isBankingInfoAddedSuccess = true;
      })
      .addCase(addBankingInfo.rejected, (state, err) => {
        state.isBankingInfoAddedSuccess = false;
      })
      .addCase(updateBankingInfo.pending, (state) => {
        state.isBankingInfoUpdateSuccess = false;
      })
      .addCase(updateBankingInfo.fulfilled, (state, action) => {
        state.isBankingInfoUpdateSuccess = true;
      })
      .addCase(updateBankingInfo.rejected, (state) => {
        state.isBankingInfoUpdateSuccess = false;
      })

      .addCase(deleteBankingInfo.pending, (state) => {
        state.isBankingInfoDeleteSuccess = false;
      })
      .addCase(deleteBankingInfo.fulfilled, (state, action) => {
        state.isBankingInfoDeleteSuccess = true;
      })
      .addCase(deleteBankingInfo.rejected, (state, err) => {
        state.isBankingInfoDeleteSuccess = false;
        state.isEducationDeleteErrorMessage = err;
      });
  },
});

//updateTradeRegistration APIS

export const updateBankingInfo = createAsyncThunk(
  'updateBankingInfo',
  async (data: IBankingInterface, thunkAPI) => {
    try {
      const response = await postBankingInfo(data);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteBankingInfo = createAsyncThunk(
  'deleteBankingInfo',
  async (id: number, thunkAPI) => {
    try {
      const response = await deleteBanking(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addBankingInfo = createAsyncThunk(
  'addBankingInfo',
  async (userData: any, thunkAPI) => {
    try {
      const response = await putBankingInfo(userData.allBankingData);
      userData.resetForm({
        accountNumber: '',
        name: '',
        iban: '',
        branch: '',
      });
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//getTradeRegistration APIS

export const getAllBankingInfo = createAsyncThunk(
  'getAllBankingInfo',
  async (_, thunkAPI) => {
    try {
      const response = await getBankingInfo();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getBankingInfoById = createAsyncThunk(
  'getAllBankingInfoById',
  async (id: number, thunkAPI) => {
    try {
      const response = await getBankingById(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const bankingInfoReducer = bankingInfoSlice.reducer;

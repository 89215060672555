import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import CommonCarousal from '../../clientComponents/carousal';
import CartCard from '../../clientComponents/cartCard';

import { useTranslation } from 'react-i18next';
import {
  deleteItem,
  deleteWishlistItem,
  getCartList,
  selectClientHome,
  wishlist,
  youMightAlsoLike,
} from '../../clientSlices/home';
import { saveTabbyToken } from '../../clientSlices/payment';

const Cart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch<any>();
  const { cartList, youMightAlsoLikeList, isLoading } =
    useSelector(selectClientHome);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    dispatch(getCartList());
    dispatch(youMightAlsoLike());
    if (searchParams.get('token')) {
      const token = { token: searchParams.get('token') };
      dispatch(saveTabbyToken(token));
    }
  }, []);

  const removeItem = (data: any) => {
    dispatch(deleteItem(data));
  };

  const wishlistUpdate = (item: any) => {
    if (item?.data?.inWishList) {
      dispatch(
        deleteWishlistItem({
          serviceType: item.serviceType,
          serviceId: item.serviceId,
        })
      );
    } else {
      dispatch(
        wishlist({
          serviceType: item.serviceType,
          serviceId: item.serviceId,
        })
      );
    }
  };
  const [t] = useTranslation('common');
  return (
    <>
      <div className="lg:mx-24 mx-5">
        <h1 className="mt-20 mb-12 font-satoshi rtl:font-Almarai lg:text-48 text-28 font-bold ">
          {t('LANDING_PAGE_AFTER_LOGIN.MY_CART')}
        </h1>{' '}
        {isLoading ? (
          <CartCard
            cardData={[]}
            category={'cart'}
            wishlistUpdate={wishlistUpdate}
            removeItem={removeItem}
            total={0}
            isLoading={isLoading}
          />
        ) : (
          <div>
            {cartList?.coachServiceSelectResponses?.content.length ? (
              <>
                {' '}
                <CartCard
                  cardData={cartList?.coachServiceSelectResponses?.content}
                  category={'cart'}
                  wishlistUpdate={wishlistUpdate}
                  removeItem={removeItem}
                  total={cartList?.finalAmount}
                  vatAmount={cartList?.vatAmount}
                  cartTotal={cartList?.cartTotal}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <div className="justify-center flex h-[57vh] items-center flex-col rtl:font-Almarai ">
                <div className="pb-4">
                  <AddShoppingCartIcon
                    sx={{ color: '#00a5ec', height: '40px', width: '60px' }}
                  />
                </div>
                {t('EMPTY_MESSAGES.CART_EMPTY')}
                <div className="rtl:font-Almarai">
                  <Link
                    to="/search-result"
                    className="text-primary rtl:font-Almarai"
                  >
                    {t('CLICK_HERE')}
                  </Link>{' '}
                  {t('EMPTY_MESSAGES.CART_ADD')}
                </div>
              </div>
            )}
          </div>
        )}
        {isLoading && (
          <div className="lg:border-none border-t border-disableGray  mt-4 lg:mt-12">
            <h4 className="font-bold font-satoshi rtl:font-Almarai text-24 lg:text-29 pt-10 ">
              {t('LANDING_PAGE_AFTER_LOGIN.YOU_MIGHT_LIKE')}
            </h4>
            <CommonCarousal isLoading={isLoading} data={[]} />
          </div>
        )}
        {youMightAlsoLikeList?.services?.length ? (
          <div className="lg:border-none border-t border-disableGray  mt-4 lg:mt-12">
            <h4 className="font-bold font-satoshi rtl:font-Almarai text-24 lg:text-29 pt-10 ">
              {t('LANDING_PAGE_AFTER_LOGIN.YOU_MIGHT_LIKE')}
            </h4>
            <CommonCarousal
              isLoading={isLoading}
              data={youMightAlsoLikeList?.services}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default Cart;

import { FC, useEffect, useState } from 'react';
interface IPagination {
  totalPagesNumber?: number;
  isLoading?: boolean;
  currentPage?: number;
  searchParams?: any;
  setSearchParams: any;
}

const Pagination: FC<IPagination> = ({
  totalPagesNumber,
  isLoading,
  currentPage,
  searchParams,
  setSearchParams,
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const temp = totalPagesNumber ? totalPagesNumber : 0;
  const page = [];
  for (let i = 1; i <= temp; i++) {
    page.push(i);
  }

  const selectedPage = (number: number) => {
    const params: { [key: string]: string } = {};
    searchParams &&
      searchParams.forEach((value: string, key: string) => {
        params[key] = value;
      });
    params['page'] = (number - 1).toString();
    setSearchParams(params);
  };
  const previous = () => {
    const page = searchParams.get('page');
    if (page && parseInt(page) + 1 - 1 >= 1) {
      const params: { [key: string]: string } = {};
      searchParams.forEach((value: string, key: string) => {
        params[key] = value;
      });
      params['page'] = (parseInt(page) - 1).toString();
      setSearchParams(params);
    }
  };
  const next = () => {
    const page = searchParams.get('page');
    if (page && parseInt(page) + 1 <= temp - 1) {
      const params: { [key: string]: string } = {};
      searchParams.forEach((value: string, key: string) => {
        params[key] = value;
      });
      params['page'] = (parseInt(page) + 1).toString();
      setSearchParams(params);
    }
  };
  useEffect(() => {
    searchParams.get('page') &&
      setCurrentPageNumber(parseInt(searchParams.get('page')!) + 1);
  }, [searchParams]);

  return (
    <>
      <div className="flex items-center justify-center pb-8">
        <div
          className="isolate  bottom-1 inline-flex -space-x-px rounded-md shadow-sm z-4"
          aria-label="Pagination"
        >
          <div
            className={`${
              currentPageNumber !== 1 && 'cursor-pointer'
            } flex justify-center items-center md:w-[40px] md:h-[40px] w-[30px] h-[30px] relative inline-flex items-center rounded    text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
          >
            <span className="sr-only">Previous</span>

            <svg
              className={`${
                document.querySelector('html')?.getAttribute('dir') === 'ltr'
                  ? ''
                  : 'rotate-180'
              }`}
              onClick={() => previous()}
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.22 20.7199C16.0793 20.5793 16.0002 20.3887 16 20.1899V19.8099C16.0023 19.6114 16.0811 19.4216 16.22 19.2799L21.36 14.1499C21.4539 14.0552 21.5817 14.002 21.715 14.002C21.8483 14.002 21.9761 14.0552 22.07 14.1499L22.78 14.8599C22.8741 14.952 22.9271 15.0782 22.9271 15.2099C22.9271 15.3415 22.8741 15.4677 22.78 15.5599L18.33 19.9999L22.78 24.4399C22.8747 24.5337 22.9279 24.6615 22.9279 24.7949C22.9279 24.9282 22.8747 25.056 22.78 25.1499L22.07 25.8499C21.9761 25.9445 21.8483 25.9978 21.715 25.9978C21.5817 25.9978 21.4539 25.9445 21.36 25.8499L16.22 20.7199Z"
                fill={`${
                  currentPageNumber === 1
                    ? 'rgba(56, 56, 56, 0.25)'
                    : 'rgba(21, 50, 67, 1)'
                }`}
              />
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="1.5"
                stroke={`${
                  currentPageNumber === 1
                    ? 'rgba(56, 56, 56, 0.25)'
                    : 'rgba(21, 50, 67, 1)'
                }`}
              />
            </svg>
          </div>

          {(totalPagesNumber as number) <= 5 ? (
            !isLoading ? (
              <ul className="flex flex-row gap-2 px-2">
                {page.map((value, index) => {
                  return (
                    <li
                      onClick={() => selectedPage(value)}
                      key={index}
                      aria-current="page"
                      className={`${
                        value === currentPageNumber
                          ? 'bg-primary text-white border-primary'
                          : 'text-textBlack'
                      }  flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]  relative cursor-pointer z-10 inline-flex items-center rounded-sm border border-borderBlack px-4 py-2 text-sm font-medium  focus:z-20`}
                    >
                      {value}
                    </li>
                  );
                })}
              </ul>
            ) : (
              ''
            )
          ) : (
            <>
              <div>
                <div
                  onClick={() => selectedPage(1)}
                  aria-current="page"
                  className={`${
                    1 === currentPageNumber
                      ? 'bg-primary text-white border-primary'
                      : 'text-textBlack'
                  }  flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]   relative cursor-pointer ml-2 z-10 inline-flex h-full items-center  border-x-[1px] border rounded px-4 py-2 text-sm font-medium  focus:z-20`}
                >
                  {1}
                </div>
              </div>
              <div className="pl-2">
                {[1, 2, 3].includes(currentPageNumber) ? (
                  ''
                ) : (
                  <img className=' md:w-[40px] md:h-[40px] w-[30px] h-[30px] ' src="/assets/pagination/dot.svg" alt="" />
                )}
              </div>
              <div>
                <div
                  onClick={() =>
                    selectedPage((currentPageNumber as number) - 1)
                  }
                  aria-current="page"
                  className={`${
                    [1, 2].includes(currentPageNumber) && 'hidden'
                  }  flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]   relative cursor-pointer ml-2 z-10 inline-flex h-full items-center  border-x-[1px] border rounded px-4 py-2 text-sm font-medium  focus:z-20`}
                >
                  {currentPageNumber === totalPagesNumber
                    ? currentPageNumber - 2
                    : currentPageNumber - 1}
                </div>
              </div>
              <div>
                <div
                  onClick={() =>
                    selectedPage(
                      currentPageNumber === 1
                        ? currentPageNumber + 1
                        : currentPageNumber === totalPagesNumber
                        ? totalPagesNumber - 1
                        : currentPageNumber
                    )
                  }
                  aria-current="page"
                  className={`${
                    totalPagesNumber !== currentPageNumber &&
                    currentPageNumber !== 1
                      ? 'bg-primary text-white border-primary'
                      : 'text-textBlack'
                  }  flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]   relative cursor-pointer mx-2 z-10 inline-flex items-center h-full border-x-[1px] border rounded px-4 py-2 text-sm font-medium  focus:z-20`}
                >
                  {(currentPageNumber as number) === 1
                    ? currentPageNumber + 1
                    : currentPageNumber === totalPagesNumber
                    ? currentPageNumber - 1
                    : currentPageNumber}
                </div>
              </div>
              <div>
                <div
                  onClick={() =>
                    selectedPage(
                      currentPageNumber === 1
                        ? currentPageNumber + 2
                        : currentPageNumber + 1
                    )
                  }
                  aria-current="page"
                  className={`${
                    [
                      totalPagesNumber,
                      (totalPagesNumber as number) - 1,
                    ].includes(currentPageNumber) && 'hidden'
                  }  flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]   relative cursor-pointer mr-2 z-10 inline-flex items-center h-full border-x-[1px] border rounded px-4 py-2 text-sm font-medium  focus:z-20`}
                >
                  {currentPageNumber === 1
                    ? currentPageNumber + 2
                    : currentPageNumber + 1}
                </div>
              </div>
              <div>
                {[
                  totalPagesNumber,
                  (totalPagesNumber as number) - 1,
                  (totalPagesNumber as number) - 2,
                ].includes(currentPageNumber) ? (
                  ''
                ) : (
                  <img
                    className="ml-2  md:w-[40px] md:h-[40px] w-[30px] h-[30px] "
                    src="/assets/pagination/dot.svg"
                    alt=""
                  />
                )}
              </div>
              <div>
                <div
                  onClick={() => selectedPage(totalPagesNumber as number)}
                  aria-current="page"
                  className={` ${
                    currentPageNumber === totalPagesNumber &&
                    'bg-primary text-white border-primary'
                  }  flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]  relative cursor-pointer mx-2 z-10 inline-flex items-center h-full  border rounded px-3 py-2 text-sm font-medium  focus:z-20`}
                >
                  {totalPagesNumber}
                </div>
              </div>
            </>
          )}

          <div
            className={`${
              currentPageNumber === totalPagesNumber ? '' : 'cursor-pointer'
            } flex justify-center items-center  md:w-[40px] md:h-[40px] w-[30px] h-[30px]  relative inline-flex items-center rounded ml-3    text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
          >
            <span className="sr-only">Next</span>
            <svg
              className={`${
                document.querySelector('html')?.getAttribute('dir') === 'ltr'
                  ? ''
                  : 'rotate-180'
              }`}
              onClick={() => next()}
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.78 20.7199C23.9207 20.5793 23.9998 20.3887 24 20.1899V19.8099C23.9977 19.6114 23.9189 19.4216 23.78 19.2799L18.64 14.1499C18.5461 14.0552 18.4183 14.002 18.285 14.002C18.1517 14.002 18.0239 14.0552 17.93 14.1499L17.22 14.8599C17.1259 14.952 17.0729 15.0782 17.0729 15.2099C17.0729 15.3415 17.1259 15.4677 17.22 15.5599L21.67 19.9999L17.22 24.4399C17.1253 24.5337 17.0721 24.6615 17.0721 24.7949C17.0721 24.9282 17.1253 25.056 17.22 25.1499L17.93 25.8499C18.0239 25.9445 18.1517 25.9978 18.285 25.9978C18.4183 25.9978 18.5461 25.9445 18.64 25.8499L23.78 20.7199Z"
                fill={`${
                  currentPageNumber !== totalPagesNumber
                    ? '#153243'
                    : 'rgba(56, 56, 56, 0.25)'
                }`}
              />
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="1.5"
                stroke={`${
                  currentPageNumber !== totalPagesNumber
                    ? '#153243'
                    : 'rgba(56, 56, 56, 0.25)'
                }`}
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;

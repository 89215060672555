import { get } from './api';

export const landingClientCount = async () => {
  return await get('/home/client-count');
};
export const landingCoachCount = async () => {
  return await get('/home/coach-count');
};
export const landingHoursCount = async () => {
  return await get('/home/total-coaching-hours');
};
export const landingAssessmentConducted = async () => {
  return await get('/home/assessment-conducted');
};
export const fetchCoachSubscription = async () => {
  return await get('/subscriptions?baseFilter=ACTIVE');
};
export const fetchCoachSubscriptionById = async (id: number) => {
  return await get('/subscriptions/' + id);
};
export const fetchCoachSubscriptionCoreFeature = async (id: string) => {
  return await get('/subscription-core-features/' + id);
};
export const fetchCoachSubscriptionAdditionalFeature = async (id: string) => {
  return await get('/subscription-additional-features/' + id);
};
export const fetchCoachOfTheMonth = async () => {
  return await get('/home/coach-of-the-month');
};

import {Form, Formik} from 'formik';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {contactUsSchema} from '../../clientHelpers';
import {useWindowSize} from '../../clientHelpers/resizeWindow';
import {IContactUs} from '../../clientInterfaces/authentication';
import {ContactUs, selectContactUs} from '../../clientSlices/conatctUs';
import Button from '../buttons';
import Input from '../inputs';
import {Loader} from '../loader';
import SelectDropdown from "../selectDropdown";
import {useAppSelector} from "../../hooks";

export const ContactUsPageBody = () => {
  const [t] = useTranslation('common');
  const { isProcessingContactUsRequest } = useSelector(selectContactUs);
  //   const { value } = useAppSelector((state) => state.contactUS.isProcessingContactUsRequest);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch<any>();
  const { lang } = useAppSelector((state) => state.language);


  const dummyContactusData = [
    {
      image: '/assets/contactUsPage/phone-icon.svg',
      vision: '+966 534 4789',
      description: t('CONTACT_US_PAGE.HELP_LINE'),
    },
    {
      image: '/assets/contactUsPage/location.svg',
      vision: '6557 Abdullah Ibn Shahwin , 3590',
      description: t('CONTACT_US_PAGE.ADDRESS'),
    },
  ];
  const contactTypeOption = [
    { label: 'Complaint', value: 'Complaint', label2: 'شكوى' },
    { label: 'Suggestion', value: 'Suggestion', label2: 'اقتراح' },
    { label: 'Inquiry', value: 'Inquiry', label2: 'استفسار' },
    { label: 'Other', value: 'FEMALE', label2: 'اخرى' },
  ];
  const handleFormSubmit = (data: IContactUs, onSubmitProps: any) => {
    dispatch(ContactUs(data));
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
  };

  const [width] = useWindowSize();

  return (
    <>
      {/* {successMessage?.message ? (
        <Alert
          success={true}
          error={false}
          data={
            successMessage.message === 'message.feedBackSubmit'
              ? 'Feedback submitted successfully'
              : ''
          }
        />
      ) : null} */}
      <section className="bg-white w-full ">
        <div
          className="  flex justify-end  h-fit  lg:pt-12 bg-no-repeat mt-10 md:bg-cover"
          style={{
            backgroundImage: `url(${
              width <= 760
                ? '/assets/aboutUsPage/mobileviewbg.svg'
                : '/assets/bodyAfterLogin/coverImage.svg'
            })`,
          }}
        >
          <div className="flex  justify-center flex-col lgmd:flex-row ">
            <div className="flex justify-center    mx-5" style={{
              marginTop: "40px"
            }}>
              <div className="lgmd:w-[40vw] sm:w-[80%] w-full flex flex-col  h-fit  text-28 md:text-48  sm:mt-[6rem] lgmd:mt-[2rem] mb-5  md:mb-[16px] font-satoshi rtl:font-Almarai  font-bold  text-darkGrey  ">
                <span className=" bg-[#ffffff] w-fit">
                  {t('CONTACT_US_PAGE.CONTACT_US')}
                </span>
                <div className=" "></div>
                <div className=" "></div>

                <div>
                  <p className="p-text bg-[#ffffff] text-justify  text-16  xl:w-/12 ltr:text-left rtl:text-right ltr:mr-4 rtl:ml-4  mt-4  md:text-left">
                    {t('LANDING_PAGE_AFTER_LOGIN.SUB_TITLE')}
                  </p>
                  <p className=" p-text bg-[#ffffff] text-justify  text-16  xl:w-/12 ltr:text-left rtl:text-right ltr:mr-4 rtl:ml-4  mt-4  md:text-left">
                    {t('LANDING_PAGE_AFTER_LOGIN.SUB_TITLE2')}
                  </p>
                </div>
              </div>
            </div>
            <div className=" flex w-full lgmd:justify-end justify-center  pr-0 ">
              <img
                className="object-cover overflow-x-visible"
                src={'/assets/contactUsPage/contact_us_image.jpg'}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="flex  flex-col px-5 md:px-20 lgmd:flex-row  items-center justify-between ">
          {dummyContactusData.map((value, index) => (
            <div
              key={index}
              className="flex flex-col lgmd:w-[48%] w-[100%] border-[1px] border-borderColor mt-10  shadow-lg lgmd:shadow-none rounded-[10px]  p-4 "
            >
              <div>
                <img
                  className="w-[65px] pl-4 pt-2 "
                  src={value.image}
                  alt=""
                ></img>
              </div>
              <div className="pl-4  text-darkGrey  font-bold text-lg pt-2 ">
                {value.vision}
              </div>
              <div className="pl-4 pt-2 text-greyText pb-4 ltr:font-satoshi rtl:font-Almarai">
                {value.description}{' '}
              </div>
            </div>
          ))}
        </div>
        <div className="flex mb-16 w-full mt-20">
          <iframe
            title="Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14491.662104860272!2d46.72578205!3d24.764085299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efd8a5c083207%3A0xbe0228bce24a2f62!2sAl%20Mughrizat%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2sin!4v1667067517723!5m2!1sen!2sin"
            width="100%"
            height="450"
            loading="lazy"
          ></iframe>
        </div>

        <div className="  items-center w-[90%] m-auto md:w-[50%]">
          <div className="font-bold text-[34px] pb-6 flex flex-row justify-center font-satoshi rtl:font-Almarai  text-darkGrey ">
            {t('CONTACT_US_PAGE.LETS_TALK')}
          </div>

          <Formik
            initialValues={{
              name: '',
              email: '',
              phoneNumber: '',
              subject: '',
              description: '',
              category: '',
            }}
            validationSchema={contactUsSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              submitForm,
              setFieldValue,
            }) => (
              <Form>
                <Input
                  id="FormFullName"
                  type="text"
                  name="name"
                  placeholder={t('CONTACT_US_PAGE.YOUR_NAME')}
                  onBlur={handleBlur}
                  error={touched.name && 1}
                  onChange={handleChange}
                  value={values.name}
                  errormessage={errors.name}
                  optional={false}
                />

                <Input
                  id="FormEmail"
                  type="email"
                  name="email"
                  placeholder={t('CONTACT_US_PAGE.YOUR_EMAIL')}
                  onBlur={handleBlur}
                  error={touched.email && 1}
                  onChange={handleChange}
                  value={values.email}
                  errormessage={errors.email}
                  optional={false}
                />
                <Input
                  id="FormPhoneNumber"
                  type="number"
                  name="phoneNumber"
                  placeholder={t('CONTACT_US_PAGE.MOBILE_NUMBER')}
                  onBlur={handleBlur}
                  error={touched.phoneNumber && 1}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  errormessage={errors.phoneNumber}
                  optional={false}
                />
                <SelectDropdown
                    className={'mb-6'}
                    id="FormType"
                    name="gender"
                    placeholder={t('CONTACT_US_PAGE.CONTACT_TYPE')}
                    options={contactTypeOption}
                    onBlur={handleBlur}
                    error={touched.category && 1}
                    onChange={(opt: any) => {
                      setFieldValue('category', opt.value);
                    }}
                    errormessage={errors.category}
                    isValue={values.category}
                    getOptionLabel={(option: any) =>
                        lang === 'en' ? option.label : option.label2
                    }

                />
                <Input
                  id="FormSubject"
                  type="text"
                  name="subject"
                  placeholder={t('CONTACT_US_PAGE.SUBJECT')}
                  onBlur={handleBlur}
                  error={touched.subject && 1}
                  onChange={handleChange}
                  value={values.subject}
                  errormessage={errors.subject}
                  optional={false}
                />
                <Input
                  id="FormDescription"
                  className="h-[100px]"
                  type="text"
                  name="description"
                  placeholder={t('CONTACT_US_PAGE.DESCRIPTION')}
                  onBlur={handleBlur}
                  error={touched.description && 1}
                  onChange={handleChange}
                  value={values.description}
                  errormessage={errors.description}
                  optional={false}
                />

                <div className="flex justify-center mb-10">
                  <Button
                    type="submit"
                    // onClick={()=>{ResettingForm()}}
                    className=" font-bold font-satoshi rtl:font-Almarai rounded text-lg text-[#ffffff] mt-1 bg-primary w-[200px] h-[52px] "
                  >
                    {isProcessingContactUsRequest ? (
                      <Loader />
                    ) : (
                      t('CONTACT_US_PAGE.SUBMIT')
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

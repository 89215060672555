import DownloadIcon from '@mui/icons-material/Download';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Date from '../../../clientComponents/date';
import Input from '../../../clientComponents/inputs';
import PhoneNumberInput from '../../../clientComponents/phoneNumberInput/phoneNumberInput';
import SelectDropdown from '../../../clientComponents/selectDropdown';
import { getDefaultValue } from '../../../clientHelpers/utility';
import { AccountListSideBar, TextArea } from '../../../coachComponents';
import { profileInfoValidationSchema } from '../../../coachHelpers';
import { FileDownload } from '../../../coachHelpers/downloadFIle';
import { useAppSelector } from '../../../coachHelpers/hooks';
import { IProfileInfoInterface } from '../../../coachInterfaces/profileInterface';
import {
  coachProfile,
  deleteEducation,
  deleteExperience,
  downloadFileEducation,
  downloadFileExperience,
  downloadProfilePic,
  getProfileEducations,
  getProfileExperience,
  profileFile,
  updateProfile,
} from '../../../coachSlices/profileSlices/profileInfoSlice';
import {
  cityList,
  countryList,
  languageList,
  timezoneList,
} from '../../../coachSlices/registrationSlice';
import { listCoachingTiming } from '../../../coachSlices/sessionSlice';
import AddCoachEducation from './addCoachEducation';
import AddCoachExperience from './addCoachExperience';
import EditCoachEducation from './editCoachEducation';
import EditCoachExperience from './editCoachExperience';

const CoachProfile = () => {
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(listCoachingTiming());
  }, []);
  const {
    personalDetails,
    coachEducationalDetails,
    coachExperienceDetails,
    isExperienceDeletedSuccess,
    isEducationDeletedSuccess,
    isEducationAddedSuccess,
    isExperienceAddedSuccess,
    isEducationEditSuccess,
    isExperienceEditSuccess,
    downloadEducationDocument,
    downloadExperienceDocument,
    profileFileId,
    downloadProfilePicture,
    isProcessingPersonalDetails,
  } = useAppSelector((state) => state.profile);

  const [showEducationIcon, setShowEducationIcon] = useState(0);
  const [showExperienceIcon, setShowExperienceIcon] = useState(0);

  let toggleEducation = (postId: any) => {
    if (showEducationIcon === postId) {
      setShowEducationIcon(0);
    } else {
      setShowEducationIcon(postId);
    }
  };
  let toggleExperience = (postId: any) => {
    if (showExperienceIcon === postId) {
      setShowExperienceIcon(0);
    } else {
      setShowExperienceIcon(postId);
    }
  };

  useEffect(() => {
    coachEducationalDetails.forEach((ele: any) => {
      ele.fileId && dispatch(downloadFileEducation(ele.fileId));
    });
  }, [coachEducationalDetails, dispatch]);

  useEffect(() => {
    profileFileId.id && dispatch(downloadProfilePic(profileFileId.id));
  }, [profileFileId, dispatch]);

  useEffect(() => {
    coachExperienceDetails?.forEach((ele: any) => {
      ele.fileId && dispatch(downloadFileExperience(ele.fileId));
    });
  }, [coachExperienceDetails, dispatch]);

  const {
    countryDropDownList,
    cityDropDown,
    languageDropDown,
    timezoneValueList,
  } = useAppSelector((state) => state.registration);

  useEffect(() => {
    dispatch(coachProfile());
    dispatch(countryList());
    // dispatch(cityList(lang.toUpperCase()));
    dispatch(languageList());
    dispatch(timezoneList());
    dispatch(coachProfile());
    dispatch(getProfileEducations());
    dispatch(getProfileExperience());
  }, [dispatch]);
  useEffect(() => {
    console.log(personalDetails[0]?.city);
    dispatch(cityList(personalDetails[0]?.country));
  }, [personalDetails]);
  useEffect(() => {
    if (
      isEducationDeletedSuccess ||
      isEducationAddedSuccess ||
      isEducationEditSuccess
    ) {
      dispatch(getProfileEducations());
    }
    if (
      isExperienceDeletedSuccess ||
      isExperienceAddedSuccess ||
      isExperienceEditSuccess
    ) {
      dispatch(getProfileExperience());
    }
  }, [
    isExperienceDeletedSuccess,
    isEducationAddedSuccess,
    isExperienceAddedSuccess,
    isEducationDeletedSuccess,
    isEducationEditSuccess,
    isExperienceEditSuccess,
    dispatch,
  ]);

  let cityOption: { value: string; label: string; label2: string }[] = [];
  if (cityDropDown?.length > 0) {
    cityDropDown.forEach((client: any) => {
      let roleDate = { value: '', label: '', label2: '' };
      roleDate.value = client.city;
      roleDate.label = client.city;
      roleDate.label2 = client.city;
      cityOption.push(roleDate);
    });
  }
  let countryOption: { value: string; label: string; label2: string }[] = [];
  if (countryDropDownList?.length > 0) {
    countryDropDownList.forEach((client: any) => {
      let roleDate = { value: '', label: '', label2: '' };
      roleDate.value = client.countryNameInEnglish;
      roleDate.label = client.countryNameInEnglish;
      roleDate.label2 = client.countryNameInEnglish;
      countryOption.push(roleDate);
    });
  }

  let languageOption: { label: ''; value: ''; label2: '' }[] = [];

  languageDropDown.map((item: any, index: number) => {
    languageOption.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
      label2: item.nameInArabic,
    });
  });

  let timeZoneOption: { label: ''; value: ''; label2: '' }[] = [];

  timezoneValueList?.map((item: any, index: number) => {
    timeZoneOption.push({
      label: item.timezone,
      value: item.timezone,
      label2: item.timezone,
    });
  });
  const [languageInputValue, setLanguageInputValueInputValue] = useState('');
  const [t] = useTranslation('common');
  const [educationUserId, setEducationUserId] = useState(0);
  const [experienceUserId, setExperienceUserId] = useState(0);
  const [showEditEducation, setShowEditEducation] = useState(false);
  const [showAddEducation, setShowAddEducation] = useState(false);
  const [showEditExperience, setShowEditExperience] = useState(false);
  const [showAddExperience, setShowAddExperience] = useState(false);

  useEffect(() => {
    if (personalDetails[0]?.profilePicId) {
      dispatch(downloadProfilePic(personalDetails[0]?.profilePicId));
    }
  }, [personalDetails]);

  function imageChange(e: React.FormEvent<EventTarget> | any): void {
    const imageFile = e.target?.files;
    dispatch(profileFile(imageFile));
  }

  const handleSubmit = (data: IProfileInfoInterface) => {
    const allData = {
      fullName: data.fullName,
      timezone: data.timezone,
      dob: data.dateOfBirth,
      phoneNumber: data.phoneNumber,
      countryCode: data.countryCode,
      bioDescription: data.description && data.description,
      languages: data.language,
      gender: data.gender,
      profilePicId: profileFileId.id,
      userAddressRequest: {
        addressLine1: data.address01,
        addressLine2: data.address02,
        country: data.country,
        city: data.city,
      },
    };
    dispatch(updateProfile(allData));
  };

  const handleLanguageInputChange = (event: any) => {
    setLanguageInputValueInputValue(event);
  };

  const genderOption = [
    { label: 'MALE', value: 'MALE', label2: 'ذكر' },
    { label: 'FEMALE', value: 'FEMALE', label2: 'أنثى' },
  ];

  const languageValue: any = [];
  useEffect(() => {
    personalDetails?.language?.map((element: string) => {
      languageValue.push({ key: element, value: element });
    });
  }, [personalDetails]);
  const [iserveLoading, setIseferLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIseferLoading(false);
    }, 1000);
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [personalDetails]);
  return (
    <div className="mt-6 lg:mt-12">
      <Helmet>
        <title>{t('KUN_COACH')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="bg-white mt-16 w-full  grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 min-h-screen lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar handleSubmit={handleSubmit} />
        <div className="h-auto w-full lg:p-8 p-4 col-span-5 lg:col-span-4  lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.PROFILE_INFO.PROFILE_INFO')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.PROFILE_INFO.ADD_INFO')}
          </p>
          <div className="border border-info mb-8"></div>
          {isProcessingPersonalDetails && iserveLoading ? (
            <div className="flex w-full h-[60vh] items-center justify-center ">
              <div className="loader" />
            </div>
          ) : (
            <Formik
              initialValues={{
                fullName:
                  (personalDetails && personalDetails[0]?.fullName) || '',
                email: personalDetails[0]?.email || '',
                phoneNumber: personalDetails[0]?.phoneNumber || '',
                language: personalDetails[0]?.languages,
                address01: personalDetails[0]?.addressLine1 || '',
                address02: personalDetails[0]?.addressLine2 || '',
                country: personalDetails[0]?.country || '',
                city: personalDetails[0]?.city || '',
                dateOfBirth: personalDetails[0]?.dob || '',
                gender: personalDetails[0]?.gender || '',
                description: personalDetails[0]?.bioDescription,
                fileId: profileFileId.id,
                countryCode: personalDetails[0]?.countryCode,
                timezone: personalDetails[0]?.timezone,
              }}
              validationSchema={profileInfoValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => {
                return (
                  <Form id="my-form">
                    <div className="flex mb-4 items-center">
                      <div className="w-48 ltr:mr-4 rtl:ml-4 h-30 sm:mb-3 bg-cover justify-center text-center rounded-lg">
                        <img
                          src={
                            !downloadProfilePicture?.fileContent
                              ? '/assets/profile/profile-pic.svg'
                              : `data:image/png;base64,${downloadProfilePicture?.fileContent}`
                          }
                          alt="Profile Pic"
                          className="w-48 h-48 rounded-[8px] object-cover"
                        />
                      </div>
                      <div className=" sm:mb-3">
                        <label htmlFor="select-image">
                          <p className="bg-skyBlue mt-5 rounded text-center px-4 py-2 text-secondary font-satoshi rtl:font-Almarai text-12 lg:text-14 font-bold cursor-pointer">
                            {t('PROFILE.PROFILE_INFO.UPLOAD_PROFILE')}
                          </p>
                        </label>
                        <input
                          id="select-image"
                          name="fileId"
                          className="form-control"
                          accept=".jpg,.jpeg,.png,.pdf,.svg"
                          type="file"
                          onChange={(e) => {
                            imageChange(e);
                            handleChange(e);
                          }}
                          style={{ display: 'none' }}
                        />
                      </div>
                    </div>
                    <div className="lg:flex lg:justify-between">
                      <div
                        className={` lg:w-6/12 lg:${
                          lang === 'en' ? 'mr-3' : 'ml-3'
                        } `}
                      >
                        <Input
                          id="FormFullName"
                          type="text"
                          name="fullName"
                          placeholder={t('PROFILE.PROFILE_INFO.FULL_NAME')}
                          onBlur={handleBlur}
                          error={touched.fullName && 1}
                          onChange={handleChange}
                          errormessage={errors.fullName}
                          value={values.fullName}
                        />
                      </div>
                      <div className=" lg:w-6/12">
                        <Input
                          id="FormEmail"
                          type="email"
                          name="email"
                          placeholder={t('PROFILE.PROFILE_INFO.EMAIL')}
                          onBlur={handleBlur}
                          error={touched.email && 1}
                          onChange={handleChange}
                          value={values.email}
                          errormessage={errors.email}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="lg:flex lg:justify-between ">
                      <div
                        className={` lg:w-6/12 lg:${
                          lang === 'en' ? 'mr-3' : 'ml-3'
                        } `}
                      >
                        {/* <Input
                          id="FormPhoneNumber"
                          type="text"
                          name="phoneNumber"
                          placeholder={t('PROFILE.PROFILE_INFO.PHONE_NUMBER')}
                          onBlur={handleBlur}
                          error={touched.phoneNumber && 1}
                          onChange={handleChange}
                          value={values.phoneNumber}
                          errormessage={errors.phoneNumber}
                        /> */}
                        <PhoneNumberInput
                          id="FormPhoneNumber"
                          type="number"
                          name="phoneNumber"
                          codeValue={values.countryCode}
                          placeholder={t('PROFILE.PROFILE_INFO.PHONE_NUMBER')}
                          onBlur={handleBlur}
                          error={touched.phoneNumber && 1}
                          onChange={handleChange}
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber}
                          errormessage={errors.phoneNumber}
                          className=" text-xl text-[#687E8E] pl-[5px]   w-[40%]"
                          clearText={() => setFieldValue('phoneNumber', '')}
                        />
                      </div>
                      <div className=" lg:w-6/12">
                        <Date
                          type="date"
                          id="birth"
                          name="dateOfBirth"
                          placeholder={t('PROFILE.PROFILE_INFO.DOB')}
                          onBlur={handleBlur}
                          value={values.dateOfBirth}
                          error={touched.dateOfBirth && 1}
                          onChange={(opt: any) => {
                            const date = `${opt?.year}-${
                              opt?.monthIndex + 1 < 10
                                ? `0${opt?.monthIndex + 1}`
                                : opt?.monthIndex + 1
                            }-${opt?.day < 10 ? `0${opt?.day}` : opt?.day}`;
                            setFieldValue('dateOfBirth', date);
                          }}
                          errormessage={errors.dateOfBirth}
                        />
                      </div>
                    </div>
                    <div className="lg:flex lg:justify-between ">
                      <div
                        className={` lg:w-6/12 mb-8 lg:${
                          lang === 'en' ? 'mr-3' : 'ml-3'
                        } `}
                      >
                        {/* <SelectDropdown
                          name="language"
                          isMulti={false}
                          options={languageOption}
                          placeholder={t('SPECIALIZATION_DETAILS.LANGUAGE')}
                          onBlur={handleBlur}
                          error={touched.language && 1}
                          onChange={(opt: any) => {
                            setFieldValue('language', opt.value);
                          }}
                          errormessage={errors.language}
                          id="FormLanguage"
                          isValue={values.language}
                          inputValue={languageInputValue}
                          onInputChange={handleLanguageInputChange}
                          defaultValue={
                            personalDetails[0]?.language &&
                            personalDetails[0]?.language.map((ele: any) => {
                              return { label: ele, value: ele };
                            })
                          }
                        /> */}
                        <SelectDropdown
                          name="language"
                          isMulti={true}
                          options={languageOption}
                          placeholder={t('SPECIALIZATION_DETAILS.LANGUAGE')}
                          onBlur={handleBlur}
                          error={touched.language && 1}
                          onChange={(opt: any) => {
                            setFieldValue(
                              'language',
                              opt.map((item: any) => item.value)
                            );
                          }}
                          defaultValue={personalDetails[0]?.languages?.map(
                            (ele: any) => {
                              return {
                                label: ele,
                                value: ele,
                                label2: getDefaultValue(ele, languageOption)[0]
                                  ?.label2,
                              };
                            }
                          )}
                          getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                          }
                          errormessage={errors.language}
                          id="FormLanguage"
                          isValue={values.language?.length}
                          inputValue={languageInputValue}
                          onInputChange={handleLanguageInputChange}
                        />
                      </div>
                      <div className=" lg:w-6/12 mb-8">
                        <SelectDropdown
                          id="FormGender"
                          name="gender"
                          placeholder={t('PERSONAL_DETAILS.GENDER')}
                          options={genderOption}
                          onBlur={handleBlur}
                          error={touched.gender && 1}
                          onChange={(opt: any) => {
                            setFieldValue('gender', opt.value);
                          }}
                          errormessage={errors.gender}
                          isValue={values.gender}
                          controlShouldRenderValue={true}
                          // defaultValue={{
                          //   label: values.gender,
                          //   value: values.gender,
                          // }}
                          getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                          }
                          defaultValue={
                            getDefaultValue(values.gender, genderOption)[0]
                          }
                        />
                      </div>
                    </div>
                    <TextArea
                      id="FormBio"
                      className="mb-7"
                      type="text"
                      rows={3}
                      cols={50}
                      name="description"
                      placeholder={t('PROFILE.PROFILE_INFO.DESCRIP')}
                      onBlur={handleBlur}
                      error={touched.description && 1}
                      onChange={handleChange}
                      value={values.description}
                      errormessage={errors.description}
                      optional={1}
                    />
                    <Input
                      id="FormAddress01"
                      type="text"
                      name="address01"
                      placeholder={t('PROFILE.PROFILE_INFO.ADDRESS1')}
                      onBlur={handleBlur}
                      error={touched.address01 && 1}
                      onChange={handleChange}
                      value={values.address01}
                      errormessage={errors.address01}
                    />
                    <Input
                      id="FormAddress02"
                      type="text"
                      name="address02"
                      placeholder={t('PROFILE.PROFILE_INFO.ADDRESS2')}
                      onBlur={handleBlur}
                      error={touched.address02 && 1}
                      onChange={handleChange}
                      value={values.address02}
                      errormessage={errors.address02}
                    />
                    <div className="lg:flex lg:justify-between  ">
                      <div
                        className={` lg:w-6/12 mb-8 lg:${
                          lang === 'en' ? 'mr-3' : 'ml-3'
                        } `}
                      >
                        <SelectDropdown
                          id="FormCountry"
                          name="country"
                          placeholder={t('PERSONAL_DETAILS.COUNTRY')}
                          options={countryOption}
                          error={touched.country && 1}
                          errormessage={errors.country}
                          onBlur={handleBlur}
                          // defaultValue={{
                          //   label: personalDetails[0]?.country,
                          //   value: personalDetails[0]?.country,
                          // }}
                          onChange={(opt: any) => {
                            setFieldValue('country', opt.value);
                            setFieldValue('city', '');
                            dispatch(cityList(opt.value));
                          }}
                          isValue={values.country}
                          getOptionLabel={(option: any) =>
                            lang === 'en' ? option.label : option.label2
                          }
                          defaultValue={
                            getDefaultValue(
                              personalDetails[0]?.country,
                              countryOption
                            )[0]
                          }
                        />
                      </div>
                      <div className=" lg:w-6/12 mb-8">
                        {cityOption?.length ? (
                          <SelectDropdown
                            id="FormCity"
                            name="city"
                            // defaultValue={{
                            //   label: personalDetails[0]?.city,
                            //   value: personalDetails[0]?.city,
                            // }}
                            placeholder={t('PERSONAL_DETAILS.CITY')}
                            options={cityOption}
                            optional={0}
                            onBlur={handleBlur}
                            error={touched.city && 1}
                            onChange={(opt: any) => {
                              setFieldValue('city', opt.value);
                            }}
                            errormessage={errors.city}
                            isValue={values.city}
                            // getOptionLabel={(option: any) =>
                            //   lang === 'en' ? option.label : option.label2
                            // }
                            defaultValue={
                              getDefaultValue(
                                personalDetails[0]?.city,
                                cityOption
                              )[0]
                            }
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className=" lg:w-6/12 mb-8">
                      <SelectDropdown
                        id="FormTimeZone"
                        name="timezone"
                        placeholder={t('PERSONAL_DETAILS.TIMEZONE')}
                        options={timeZoneOption}
                        onBlur={handleBlur}
                        error={touched.timezone && 1}
                        onChange={(opt: any) => {
                          setFieldValue('timezone', opt.value);
                        }}
                        errormessage={errors.timezone}
                        isValue={values.timezone}
                        controlShouldRenderValue={true}
                        // getOptionLabel={(option: any) =>
                        //   lang === 'en' ? option.label : option.label2
                        // }
                        defaultValue={
                          getDefaultValue(
                            personalDetails[0]?.timezone,
                            timeZoneOption
                          )[0]
                        }
                        optional={0}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
          <div className="w-full items-center justify-between border-t py-8 mt-12">
            <div className="flex justify-between">
              <h1 className="text-24 font-bold font-satoshi rtl:font-Almarai text-secondary mb-6 lg:mb-4 ">
                {t('PROFILE.PROFILE_INFO.EDUCATION')}
              </h1>
              <img
                className="cursor-pointer"
                src="/assets/profilePageImage/add.svg"
                alt="icon"
                onClick={() => setShowAddEducation(true)}
              />
            </div>
            {coachEducationalDetails.map((ele: any, i: number) => {
              return (
                <div key={i} className=" flex mb-4">
                  <div>
                    <img
                      src={`/assets/profilePageImage/${
                        i === 0 ? 'University' : 'Universal01'
                      }.svg`}
                      alt="icon"
                    />
                  </div>
                  <div
                    className={`flex ltr:ml-5 rtl:mr-5  w-full ${
                      i !== coachEducationalDetails.length - 1 && 'border-b'
                    }`}
                  >
                    <div className="justify-self-center w-full">
                      <div className="flex justify-between">
                        <h1 className="text-18 font-bold font-satoshi rtl:font-Almarai text-secondary mt mb-1 w-[70%]">
                          {ele.university}
                        </h1>
                        <div className="flex">
                          <div className={` sm_max:hidden sm:hidden lg:flex`}>
                            <img
                              src="/assets/profilePageImage/edit.svg"
                              alt="icon"
                              className="lg:mr-6 sm:mr-2 mb-5 lg:mb-0  cursor-pointer"
                              onClick={() => {
                                setEducationUserId(ele.id);
                                setShowEditEducation(true);
                              }}
                            />
                            <img
                              src="/assets/profilePageImage/delete.svg"
                              alt="icon"
                              className="mr-2 cursor-pointer"
                              onClick={() => {
                                dispatch(deleteEducation(ele.id));
                              }}
                            />
                          </div>

                          {showEducationIcon === ele.id && (
                            <div className={` lg:hidden`}>
                              <ul className="shadow-sm">
                                <li
                                  className="p-2 border-b-2 border-gray text-16"
                                  onClick={() => {
                                    setEducationUserId(ele.id);
                                    setShowEditEducation(true);
                                  }}
                                >
                                  {t('PROFILE.PROFILE_INFO.EDIT')}
                                </li>
                                <li
                                  className="p-2 text-16 "
                                  onClick={() => {
                                    dispatch(deleteEducation(ele.id));
                                  }}
                                >
                                  {t('PROFILE.PROFILE_INFO.DELETE')}
                                </li>
                              </ul>
                            </div>
                          )}
                          <div className="lg:hidden text-xl font-bold font-satoshi rtl:font-Almarai bg-white rounded-md cursor-pointer text-gray">
                            <button
                              className=" text-gray-700 rounded-md outline-none"
                              onClick={() => toggleEducation(ele.id)}
                            >
                              <img
                                className="cursor-pointer"
                                src="/assets/profilePageImage/iconShow.svg"
                                alt="icon"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <p className="text-13 font-normal text-gray font-general rtl:font-Almarai my-1">
                        {ele.degree}
                      </p>
                      <p className="font-normal font-general rtl:font-Almarai text-13 text-gray  mb-1">
                        {ele.startDate &&
                          new window.Date(ele.startDate).toLocaleDateString(
                            'en-us',
                            {
                              year: 'numeric',
                              month: 'short',
                            }
                          )}{' '}
                        -{' '}
                        {ele.endDate &&
                          new window.Date(ele.endDate).toLocaleDateString(
                            'en-us',
                            {
                              year: 'numeric',
                              month: 'short',
                            }
                          )}
                      </p>
                      <p className="mt-4 text-16 font-normal font-general rtl:font-Almarai text-secondary">
                        {ele.description}
                      </p>
                      <div className="flex items-center py-4">
                        <div className=" items-center">
                          <div className=" sm:mb-3">
                            <div className="flex">
                              <label htmlFor={`select-image${i}`}>
                                {/* {ele.fileId !== 0 && (
                                  <img
                                    src={`data:image/png;base64,${downloadEducationDocument[i]?.fileContent}`}
                                    className="w-14 h-10"
                                    alt="logo"
                                  />
                                )} */}
                                {ele.fileId ? (
                                  <span className="flex">
                                    <DownloadIcon />
                                    <FileDownload
                                      id={ele.fileId}
                                      purchased={true}
                                    />
                                  </span>
                                ) : null}
                              </label>
                              {/* <span className="ml-3 mt-3 text-13 font-general rtl:font-Almarai not-italic font-normal text-gray">
                                {ele.fileId !== 0 &&
                                  downloadEducationDocument[i]
                                    ?.originalFileName}
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {showAddEducation && (
              <AddCoachEducation setShowAddEducation={setShowAddEducation} />
            )}
            {showEditEducation && (
              <EditCoachEducation
                educationUserId={educationUserId}
                setShowEditEducation={setShowEditEducation}
              />
            )}
          </div>
          <div className="w-full items-center justify-between border-t py-8 mt-12">
            <div className="flex justify-between">
              <h1 className="text-24 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
                {t('PROFILE.PROFILE_INFO.EXPERIENCE')}
              </h1>
              <img
                className="cursor-pointer"
                src="/assets/profilePageImage/add.svg"
                alt="icon"
                onClick={() => setShowAddExperience(true)}
              />
            </div>
            {coachExperienceDetails?.map((ele: any, i: number) => {
              return (
                <div key={i} className=" flex mb-4">
                  <div>
                    <img
                      src={`/assets/profilePageImage/${
                        i === 0 ? 'title01' : 'tcs'
                      }.svg`}
                      alt="icon"
                    />
                  </div>
                  <div
                    className={`flex ltr:ml-5 rtl:mr-5  w-full  ${
                      i !== coachExperienceDetails.length - 1 && 'border-b'
                    }`}
                  >
                    <div className="justify-self-center w-full">
                      <div className="flex justify-between">
                        <h1 className="text-18 font-bold font-satoshi rtl:font-Almarai text-secondary mt mb-1 w-[70%]">
                          {ele.title}
                        </h1>
                        <div className="flex">
                          <div className={`sm_max:hidden sm:hidden lg:flex`}>
                            <img
                              src="/assets/profilePageImage/edit.svg"
                              alt="icon"
                              className="lg:mr-6 sm:mr-2 mb-5 lg:mb-0  cursor-pointer"
                              onClick={() => {
                                setExperienceUserId(ele.id);
                                setShowEditExperience(true);
                              }}
                            />
                            <img
                              src="/assets/profilePageImage/delete.svg"
                              alt="icon"
                              className="mr-2 cursor-pointer"
                              onClick={() => {
                                dispatch(deleteExperience(ele.id));
                              }}
                            />
                          </div>
                          {showExperienceIcon === ele.id && (
                            <div className={`lg:hidden`}>
                              <ul className="shadow-sm">
                                <li
                                  className="p-2 border-b-2 border-gray text-16"
                                  onClick={() => {
                                    setExperienceUserId(ele.id);
                                    setShowEditExperience(true);
                                  }}
                                >
                                  {t('PROFILE.PROFILE_INFO.EDIT')}
                                </li>
                                <li
                                  className="p-2 text-16 "
                                  onClick={() => {
                                    dispatch(deleteExperience(ele.id));
                                  }}
                                >
                                  {t('PROFILE.PROFILE_INFO.DELETE')}
                                </li>
                              </ul>
                            </div>
                          )}
                          <div className="lg:hidden text-xl font-bold font-satoshi rtl:font-Almarai bg-white rounded-md cursor-pointer text-gray">
                            <button
                              className=" text-gray-700 rounded-md outline-none"
                              onClick={() => toggleExperience(ele.id)}
                            >
                              <img
                                className="cursor-pointer"
                                src="/assets/profilePageImage/iconShow.svg"
                                alt="icon"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <p className="text-13 font-normal text-gray font-general rtl:font-Almarai my-1">
                        {ele.companyName}
                      </p>
                      <p className="font-normal font-general rtl:font-Almarai text-sm text-text-gray-new  mb-1">
                        {ele.location}
                      </p>
                      <p className="font-normal font-general rtl:font-Almarai text-13 text-gray mb-1">
                        {ele.startDate &&
                          new window.Date(ele.startDate).toLocaleDateString(
                            'en-us',
                            {
                              year: 'numeric',
                              month: 'short',
                            }
                          )}{' '}
                        -{' '}
                        {ele.isCurrentlyWorking
                          ? 'Present'
                          : ele.endDate &&
                            new window.Date(ele.endDate).toLocaleDateString(
                              'en-us',
                              {
                                year: 'numeric',
                                month: 'short',
                              }
                            )}
                      </p>
                      <p className="mt-4 text-16 font-normal text-secondary font-general rtl:font-Almarai">
                        {ele.description}
                      </p>
                      <div className="flex items-center py-4">
                        <div className=" items-center">
                          <div className=" sm:mb-3">
                            <div className="flex">
                              <label htmlFor={`select-images${i}`}>
                                {/* {ele.fileId !== 0 && (
                                  <img
                                    src={`data:image/png;base64,${downloadExperienceDocument[i]?.fileContent}`}
                                    className="w-14 h-10"
                                    alt="logo"
                                  />
                                )} */}
                                {ele.fileId ? (
                                  <span className="flex">
                                    <DownloadIcon />
                                    <FileDownload
                                      id={ele.fileId}
                                      purchased={true}
                                    />
                                  </span>
                                ) : null}
                              </label>
                              {/* <span className="ml-3 mt-3 text-13 font-general rtl:font-Almarai not-italic font-normal text-gray">
                                {ele.fileId !== 0 &&
                                  downloadExperienceDocument[i]
                                    ?.originalFileName}
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {showAddExperience && (
              <AddCoachExperience setShowAddExperience={setShowAddExperience} />
            )}
            {showEditExperience && (
              <EditCoachExperience
                experienceUserId={experienceUserId}
                setShowEditExperience={setShowEditExperience}
              />
            )}
          </div>
          <div className=" grid lg:hidden">
            <button
              form="my-form"
              type="submit"
              onClick={() => handleSubmit}
              className="  py-4 px-8 text-white font-satoshi rtl:font-Almarai font-bold bg-primary rounded"
            >
              {t('PROFILE.PROFILE_INFO.SAVE_CHANGE')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachProfile;

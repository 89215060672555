import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../clientComponents/buttons';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { getDefaultValue } from '../../clientHelpers/utility';
import { AccountListSideBar, TextArea } from '../../coachComponents';
import { bankingInfoValidationSchema } from '../../coachHelpers';
import { useAppSelector } from '../../coachHelpers/hooks';
import { IBankingInterface } from '../../coachInterfaces/profileInterface';
import {
  addBankingInfo,
  deleteBankingInfo,
  getAllBankingInfo,
} from '../../coachSlices/profileSlices/bankingInfoSlice';
import EditBankingInfo from './editBankingInfo';

const Component = () => {
  const [t] = useTranslation('common');
  const [isOpen, setIsOpen] = useState(-1);
  const [buffer, setBuffer] = useState<any>({ label: '', value: '' });
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const buttonRef: any = useRef();

  const [userId, setUserId] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch<any>();
  const [closeToggle, setCloseToggle] = useState(false);
  const [selectedId, setSelectedId] = useState<any>();
  const [bankCodevalue, setbankCodeValue] = useState('');
  const [bankNameValue, setbankNameValue] = useState('');
  const [bankData, setBankData] = useState<any>('');

  const {
    bankingDetails,
    isBankingInfoAddedSuccess,
    isBankingInfoDeleteSuccess,
    isBankingInfoUpdateSuccess,
  } = useAppSelector((state) => state.bankingInfo);

  useEffect(() => {
    dispatch(getAllBankingInfo());
  }, [dispatch]);

  useEffect(() => {
    if (
      isBankingInfoAddedSuccess ||
      isBankingInfoUpdateSuccess ||
      isBankingInfoDeleteSuccess
    ) {
      dispatch(getAllBankingInfo());
    }
  }, [
    dispatch,
    isBankingInfoAddedSuccess,
    isBankingInfoDeleteSuccess,
    isBankingInfoUpdateSuccess,
  ]);

  // useEffect(() => {
  //   if (bankingDetails) {
  //     setAccountNumber(bankingDetails?.accountNumber);
  //     setName(bankingDetails?.name);
  //     setIFSC(bankingDetails?.IBAN);
  //     setBranchName(bankingDetails?.branchName);
  //   }
  // }, [bankingDetails]);

  const handleSubmit = (
    data: IBankingInterface,
    resetForm: ({}: any) => void
  ) => {
    const allBankingData = {
      accountNumber: data.accountNumber,
      beneficiaryName: data.beneficiaryName,
      iban: data.iban,
      isPrimaryAccount: data.isPrimaryAccount,
      bankCode: data.bankCode,
      beneficiaryAddress: data.beneficiaryAddress,
      bankAddress: data?.bankAddress,
      bankName: data.bankName,
      bankClearanceCode: data.bankClearanceCode,
    };
    allBankingData &&
      dispatch(
        addBankingInfo({
          allBankingData: allBankingData,
          resetForm: resetForm,
        })
      );
  };
  useEffect(() => {
    showEdit
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [showEdit]);
  useEffect(() => {
    closeToggle
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [closeToggle]);
  const closeModal = () => {
    setSelectedId(false);
    setCloseToggle(false);
  };
  const deleteAccount = async () => {
    if (selectedId) await dispatch(deleteBankingInfo(selectedId));
    closeModal();
  };
  const [alternativeState, setAlternativeState] = useState(false);

  const onAlternateChange = (setFieldVlaue: any, name: string) => {
    setFieldVlaue(name, !alternativeState);
    setAlternativeState(!alternativeState);
  };
  const bankValues: any = {
    AAALSARI: 'Alawwal Bank',
    ALBISARI: 'BANK ALBILAD',
    ARNBSARI: 'ARAB NATIONAL BANK',
    BJAZSAJE: 'BANK ALJAZIRA',
    BNPASARI: 'BNP PARIBAS',
    BSFRSARI: 'BANQUE SAUDI FRANSI',
    DEUTSARI: 'DEUTSCHE BANK',
    EBILSARI: 'EMIRATES BANK INTERNATIONAL',
    GULFSARI: 'GULF INTERNATIONAL BANK',
    NCBKSAJE: 'NATIONAL COMMERCIAL BANK',
    RJHISARI: 'AL RAJHI BANK',
    SABBSARI: 'SABB',
    SAMBSARI: 'SAMBA',
    SIBCSARI: 'THE SAUDI INVESTMENT BANK',
    RIBLSARI: 'Riyad Bank',
    SAMASARI: 'Saudi Arabian Monetary Agency-SAMA',
    NBOKSAJE: 'National Bank of Kuwait (NBOK)',
    BMUSSARI: 'BANK MUSCAT',
    NBOBSARI: 'National Bank of Bahrain',
    INMASARI: 'AL INMA BANK',
    NBPASARI: 'National Bank of Pakistan',
    TCZBSAJE: 'TURKIYE CUMHURIYETI ZIRAAT BANKASI',
    SBINSAJE: 'State Bank of India',
    CHASSARI: 'JPMorgan Chase Bank, N.A.(Riyadh Branch)',
    ICBKSARI: 'Ind. and Commercial Bank of China',
    FABMSARI: 'First Abu Dhabi Bank',
    SABB: 'SABBSARI',
    SAMBA: 'SAMBSARI',
  };

  const bankCode = [
    { label: 'AAALSARI', value: 'AAALSARI' },
    { label: 'ALBISARI', value: 'ALBISARI' },
    { label: 'ARNBSARI', value: 'ARNBSARI' },
    { label: 'BJAZSAJE', value: 'BJAZSAJE' },
    { label: 'BNPASARI', value: 'BNPASARI' },
    { label: 'BSFRSARI', value: 'BSFRSARI' },
    { label: 'DEUTSARI', value: 'DEUTSARI' },
    { label: 'EBILSARI', value: 'EBILSARI' },
    { label: 'GULFSARI', value: 'GULFSARI' },
    { label: 'NCBKSAJE', value: 'NCBKSAJE' },
    { label: 'RJHISARI', value: 'RJHISARI' },
    { label: 'SABBSARI', value: 'SABBSARI' },
    { label: 'SAMBSARI', value: 'SAMBSARI' },
    { label: 'SIBCSARI', value: 'SIBCSARI' },
    { label: 'RIBLSARI', value: 'RIBLSARI' },
    { label: 'SAMASARI', value: 'SAMASARI' },
    { label: 'NBOKSAJE', value: 'NBOKSAJE' },
    { label: 'BMUSSARI', value: 'BMUSSARI' },
    { label: 'NBOBSARI', value: 'NBOBSARI' },
    { label: 'INMASARI', value: 'INMASARI' },
    { label: 'NBPASARI', value: 'NBPASARI' },
    { label: 'TCZBSAJE', value: 'TCZBSAJE' },
    { label: 'SBINSAJE', value: 'SBINSAJE' },
    { label: 'CHASSARI', value: 'CHASSARI' },
    { label: 'ICBKSARI', value: 'ICBKSARI' },
    { label: 'FABMSARI', value: 'FABMSARI' },
  ];
  const bankName = [
    { label: 'Alawwal Bank', value: 'Alawwal Bank' },
    { value: 'BANK ALBILAD', label: 'BANK ALBILAD' },
    { value: 'ARAB NATIONAL BANK', label: 'ARAB NATIONAL BANK' },
    { value: 'BANK ALJAZIRA', label: 'BANK ALJAZIRA' },
    { value: 'BNP PARIBAS', label: 'BNP PARIBAS' },
    { value: 'BANQUE SAUDI FRANSI', label: 'BANQUE SAUDI FRANSI' },
    { value: 'DEUTSCHE BANK', label: 'DEUTSCHE BANK' },
    {
      value: 'EMIRATES BANK INTERNATIONAL',
      label: 'EMIRATES BANK INTERNATIONAL',
    },
    { value: 'GULF INTERNATIONAL BANK', label: 'GULF INTERNATIONAL BANK' },
    { value: 'NATIONAL COMMERCIAL BANK', label: 'NATIONAL COMMERCIAL BANK' },
    { value: 'AL RAJHI BANK', label: 'AL RAJHI BANK' },
    { value: 'SABB', label: 'SABB' },
    { value: 'SAMBA', label: 'SAMBA' },
    {
      value: 'THE SAUDI INVESTMENT BANK',
      label: 'THE SAUDI INVESTMENT BANK',
    },
    { value: 'Riyad Bank', label: 'Riyad Bank' },
    {
      value: 'Saudi Arabian Monetary Agency-SAMA',
      label: 'Saudi Arabian Monetary Agency-SAMA',
    },
    {
      value: 'National Bank of Kuwait (NBOK)',
      label: 'National Bank of Kuwait (NBOK)',
    },
    { value: 'BANK MUSCAT', label: 'BANK MUSCAT' },
    { value: 'National Bank of Bahrain', label: 'National Bank of Bahrain' },
    { value: 'AL INMA BANK', label: 'AL INMA BANK' },
    {
      value: 'National Bank of Pakistan',
      label: 'National Bank of Pakistan',
    },
    {
      value: 'TURKIYE CUMHURIYETI ZIRAAT BANKASI',
      label: 'TURKIYE CUMHURIYETI ZIRAAT BANKASI',
    },
    { value: 'State Bank of India', label: 'State Bank of India' },
    {
      value: 'JPMorgan Chase Bank, N.A.(Riyadh Branch)',
      label: 'JPMorgan Chase Bank, N.A.(Riyadh Branch)',
    },
    {
      value: 'Ind. and Commercial Bank of China',
      label: 'Ind. and Commercial Bank of China',
    },
    { value: 'First Abu Dhabi Bank', label: 'First Abu Dhabi Bank' },
  ];
  const handleBankCode = (event: any) => {
    event &&
      setBuffer({
        label: 'bankCode',
        value: event,
      });
    setbankCodeValue(event);
  };
  const handleBankName = (event: any) => {
    setbankNameValue(event);
  };
  // useEffect(() => {
  //   const closeDropdown = (e: any) => {
  //     if (
  //       isOpen &&
  //       buttonRef.current &&
  //       !buttonRef.current.contains(e.target)
  //     ) {
  //       setIsOpen(-1);
  //     }
  //   };
  //   document.body.addEventListener('click', closeDropdown);
  //   return () => document.body.removeEventListener('click', closeDropdown);
  // }, [mobileMenu]);
  const handleBankcode = (setFieldValue: any, bankName: any) => {
    Object.keys(bankValues).map((bankCode: any) => {
      if (bankValues[bankCode] === bankName) {
        setFieldValue('bankCode', bankCode);
        // setbankCodeValue(getDefaultValue(bankCode, bankCode)[0]);
      }
    });
  };
  return (
    <>
      <div className=" w-full mt-6 lg:mt-12">
        <div className="bg-white mt-16 w-full  grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 min-h-screen lg:min-h-screen lg:space-y-6 lg:gap-4 ">
          <AccountListSideBar handleSubmit={handleSubmit} />
          <div className="h-auto w-full lg:p-8 p-4 col-span-5 lg:col-span-4  lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
            <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
              {t('PROFILE.PROFILE_INFO.BANKING_INFO')}
            </h1>
            <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
              {t('PROFILE.PROFILE_INFO.ADD_BANKING_INFO')}
            </p>
            <div className="border border-info mb-8"></div>

            <Formik
              initialValues={{
                accountNumber: '',
                iban: '',
                beneficiaryAddress: '',
                bankAddress: '',
                beneficiaryName: '',
                isPrimaryAccount: true,
                bankCode: '',
                bankName: '',
                bankClearanceCode: '',
              }}
              validationSchema={bankingInfoValidationSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
              }}
            >
              {({
                errors,
                touched,
                values,
                submitForm,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => {
                console.log(getDefaultValue(values.bankCode, bankCode)[0]);
                return (
                  <Form>
                    <div className="lgmd:w-4/5 md:w-5/5">
                      <Input
                        id="accountNumber"
                        type="text"
                        name="accountNumber"
                        placeholder={t('PROFILE.PROFILE_INFO.ACCOUNT_NUMBER')}
                        onBlur={handleBlur}
                        error={touched.accountNumber && 1}
                        onChange={handleChange}
                        value={values.accountNumber}
                        errormessage={errors.accountNumber}
                      />
                      <Input
                        id="beneficiaryName"
                        type="text"
                        name="beneficiaryName"
                        placeholder={t('PROFILE.PROFILE_INFO.NAME')}
                        onBlur={handleBlur}
                        error={touched.beneficiaryName && 1}
                        onChange={handleChange}
                        value={values.beneficiaryName}
                        errormessage={errors.beneficiaryName}
                      />
                      <TextArea
                        id="beneficiaryAddress"
                        type="text"
                        name="beneficiaryAddress"
                        placeholder={t(
                          'PROFILE.PROFILE_INFO.BENEFICIARY_ADDRESS'
                        )}
                        onBlur={handleBlur}
                        error={touched.beneficiaryAddress && 1}
                        onChange={handleChange}
                        value={values.beneficiaryAddress}
                        errormessage={t(errors.beneficiaryAddress as string)}
                      />
                      <div className="pt-6">
                        <Input
                          id="iban"
                          type="text"
                          name="iban"
                          placeholder={t('PROFILE.PROFILE_INFO.IBAN')}
                          onBlur={handleBlur}
                          error={touched.iban && 1}
                          onChange={handleChange}
                          value={values.iban}
                          errormessage={errors.iban}
                        />
                      </div>

                      <div className="flex lg:flex-row flex-col lg:justify-between gap-8 lg:gap-0">
                        <div className=" lg:w-6/12 lg:ltr:mr-3 lg:rtl:ml-3">
                          <SelectDropdown
                            id="bankName"
                            name="bankName"
                            placeholder={t('PROFILE.PROFILE_INFO.BANK_NAME')}
                            options={bankName}
                            error={touched.bankName && 1}
                            isSearchable={false}
                            errormessage={errors.bankName}
                            onBlur={handleBlur}
                            onChange={(opt: any) => {
                              // opt.value &&
                              //   setBuffer({
                              //     label: 'bankName',
                              //     value: opt.value,
                              //   });

                              setFieldValue('bankName', opt.value);

                              handleBankcode(setFieldValue, opt.value);
                            }}
                            onInputChange={handleBankName}
                            inputValue={bankNameValue}
                            controlShouldRenderValue={true}
                            isValue={values.bankName}
                            optional={0}
                            defaultValue={
                              values.bankName !== '' && {
                                label: values.bankName,
                                value: values.bankName,
                              }
                            }
                          />
                        </div>
                        <div className=" lg:w-6/12">
                          <Input
                            id="bankCode"
                            type="text"
                            name="bankCode"
                            placeholder={t('PROFILE.PROFILE_INFO.BANK_CODE')}
                            onBlur={handleBlur}
                            error={touched.bankCode && 1}
                            onChange={handleChange}
                            value={values.bankCode}
                            errormessage={errors.bankCode}
                            optional={false}
                            disabled={true}
                          />
                          {/* <SelectDropdown
                            id="bankCode"
                            name="bankCode"
                            placeholder={t('PROFILE.PROFILE_INFO.BANK_CODE')}
                            options={bankCode}
                            error={touched.bankCode && 1}
                            isSearchable={false}
                            errormessage={errors.bankCode}
                            onBlur={handleBlur}
                            onChange={(opt: any) => {
                              setFieldValue('bankCode', opt.value);
                            }}
                            onInputChange={handleBankCode}
                            inputValue={bankCodevalue}
                            controlShouldRenderValue={true}
                            isValue={values.bankCode}
                            optional={0}
                            value={
                              getDefaultValue(values.bankCode, bankCode)[0]
                            }
                            defaultValue={
                              getDefaultValue(values.bankCode, bankCode)[0]
                            }
                            isDisabled={true}
                          /> */}

                          {/* <SelectDropdown
                           
                            onInputChange={handleLanguageInputChange}
                            inputValue={languageInputValue}
                            controlShouldRenderValue={true}
                            isValue={values.language}
                            optional={0}
                            defaultValue={
                              values.language !== '' && {
                                label: values.language,
                                value: values.language,
                              }
                            }
                          /> */}
                        </div>
                      </div>
                      <div className="pt-2">
                        <Input
                          id="bankClearanceCode"
                          type="text"
                          name="bankClearanceCode"
                          placeholder={t(
                            'PROFILE.PROFILE_INFO.BANK_CLEARANCE_CODE'
                          )}
                          onBlur={handleBlur}
                          error={touched.bankClearanceCode && 1}
                          onChange={handleChange}
                          value={values.bankClearanceCode}
                          errormessage={errors.bankClearanceCode}
                          optional={true}
                        />
                      </div>
                      <TextArea
                        id="bankAddress"
                        type="text"
                        name="bankAddress"
                        placeholder={t('PROFILE.PROFILE_INFO.BANK_ADDRESS')}
                        onBlur={handleBlur}
                        error={touched.bankAddress && 1}
                        onChange={handleChange}
                        value={values.bankAddress}
                        optional={1}
                        errormessage={errors.bankAddress}
                      />

                      <div className="pb-6">
                        <input
                          name="isPrimaryAccount"
                          type="checkbox"
                          onChange={() =>
                            onAlternateChange(setFieldValue, 'isPrimaryAccount')
                          }
                          checked={values.isPrimaryAccount}
                        />{' '}
                        {t('PROFILE.PROFILE_INFO.PRIMARY')}
                      </div>
                      <div className="  mb-5">
                        <button
                          type="submit"
                          className=" w-full  lg:w-2/5 text-18 font-bold  py-4 px-3 text-white font-satoshi rtl:font-Almarai bg-primary rounded"
                        >
                          {t('PROFILE.PROFILE_INFO.ADD_ACC')}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            {showEdit && (
              <EditBankingInfo
                UserId={userId}
                setShowEdit={setShowEdit}
                showEdit={showEdit}
                bankingDetailsById={bankData}
              />
            )}
            <div className="border border-info mb-8"></div>
            <h1 className="text-20 font-bold font-satoshi rtl:font-Almarai text-secondary ">
              {t('PROFILE.PROFILE_INFO.ADDED_ACCOUNT')}
            </h1>
            {bankingDetails?.map((ele: IBankingInterface, i: number) => {
              return (
                <div
                  key={i}
                  className="lg:w-3/5 items-center justify-between shadow-[3px_0px_15px__rgba(0,0,0,0.15)]  py-3 px-4 mt-6"
                >
                  <div className=" flex">
                    <div className="self-center">
                      <img
                        className="w-30 h-auto ltr:mr-5 rtl:ml-5 lg:mr-2 sm:mt-1 "
                        src={`/assets/profilePageImage/bankLogo.svg`}
                        alt="icon"
                      />
                    </div>
                    <div
                      ref={buttonRef}
                      className="flex lg:ltr:ml-5 lg:rtl:mr-5 w-full items-center "
                    >
                      <div className="justify-self-center w-full relative">
                        <div className="flex justify-between">
                          <div>
                            <h1 className="text-16 font-semibold font-satoshi rtl:font-Almarai text-secondary ">
                              {ele.accountNumber}
                            </h1>
                            <p className="text-13 font-normal text-gray font-general rtl:font-Almarai">
                              {ele.beneficiaryName}
                            </p>
                            {ele?.isPrimaryAccount ? (
                              <p className="text-13 font-semibold text-primary font-general rtl:font-Almarai">
                                {t('PROFILE.PROFILE_INFO.PRIMARY_ACCOUNT')}
                              </p>
                            ) : null}
                          </div>
                          <div className="flex items-center ">
                            <div
                              className={`lg:hidden absolute bg-white rounded top-0 right-2 ${
                                isOpen === i ? 'block' : 'hidden'
                              }`}
                            >
                              <ul className="shadow-sm">
                                <li
                                  className="p-2 text-16"
                                  onClick={() => {
                                    setUserId(ele.id);
                                    setShowEdit(true);
                                    setIsOpen(-1);
                                  }}
                                >
                                  {t('PROFILE.PROFILE_INFO.EDIT')}
                                </li>
                                <li
                                  className="p-2 text-16 "
                                  onClick={() => {
                                    // dispatch(deleteBankingInfo(ele.id));
                                    setCloseToggle(true);
                                    setSelectedId(ele.id);
                                    setIsOpen(-1);
                                  }}
                                >
                                  {t('PROFILE.PROFILE_INFO.DELETE')}
                                </li>
                              </ul>
                            </div>
                            <div
                              className={` hidden lg:flex ${'block'} lg:block`}
                            >
                              <img
                                src="/assets/profilePageImage/edit.svg"
                                alt="icon"
                                className="ml-3 mr-6 w-6 cursor-pointer"
                                onClick={() => {
                                  setUserId(ele.id);
                                  setShowEdit(true);
                                  setBankData(ele);
                                }}
                              />
                            </div>
                            <div
                              className={` hidden lg:flex ${'block'} lg:block`}
                            >
                              <img
                                src="/assets/profilePageImage/delete.svg"
                                alt="icon"
                                className="mr-2 w-5 cursor-pointer"
                                onClick={() => {
                                  setCloseToggle(true);
                                  setSelectedId(ele.id);
                                  // dispatch(deleteBankingInfo(ele.id));
                                }}
                              />
                            </div>

                            <div className="lg:hidden text-xl  font-bold font-satoshi rtl:font-Almarai bg-white rounded-md cursor-pointer text-gray">
                              <button
                                className=" rounded-md outline-none"
                                // onClick={() =>
                                //   i === 0
                                //     ? setEducationItem1(!educationItem1)
                                //     : setEducationItem2(!educationItem2)
                                // }
                              >
                                <img
                                  onClick={() => setIsOpen(i)}
                                  className="cursor-pointer "
                                  src="/assets/profilePageImage/iconShow.svg"
                                  alt="icon"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <Modal show={closeToggle} logOutClose={closeModal}> */}
      {closeToggle && (
        <div className="fixed  h-[100vh] inset-0 bg-transparentBlack bg-opacity-50 z-10">
          <div className="flex  h-[100vh] items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3 sm:flex">
                <div className="w-full flex justify-end flex-col">
                  <div className="w-full h-fit flex justify-end">
                    <img
                      onClick={() => closeModal()}
                      src="/assets/profilePageImage/model.svg"
                      alt="icon"
                      className="mr-2 cursor-pointer h-5 w-5"
                    />
                  </div>
                  <div className=" h-fit w-full flex flex-col mt-10  items-center gap-7">
                    <div className="h-10 w-fit text-18 flex text-center">
                      {t('CLIENT_PROFILE.ARE_YOU_SURE_DELETE')}{' '}
                    </div>
                    <div className="w-full flex md:flex-row flex-col-reverse  gap-5 pb-5 justify-center">
                      <div>
                        <Button
                          className={'w-full'}
                          children={
                            <div
                              onClick={() => deleteAccount()}
                              className="h-13 bg-primary text-white  font-satoshi rtl:font-Almarai font-bold text-18 flex  items-center justify-center rounded  flex-grow w-full md:w-[200px]"
                            >
                              {t('CLIENT_PROFILE.CONFIRM')}
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* </Modal> */}
    </>
  );
};

const BankInfoEdit = () => {
  return;
};

const BankingInfo = styled(Component)``;

export default BankingInfo;

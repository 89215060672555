import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import { AccountListSideBar } from '../../coachComponents';
import { tradeValidationSchema } from '../../coachHelpers';
import { useAppSelector } from '../../coachHelpers/hooks';
import {
  createTradeRegistration,
  downloadFileTradeRegistration,
  getTradeRegistration,
  tradeRegistrationFile,
} from '../../coachSlices/profileSlices/tradeRegistrationSlice';

const Component = () => {
  const [t] = useTranslation('common');
  const [tradeRegistrationNumber, setTradeRegistrationNumber] = useState('');

  const dispatch = useDispatch<any>();
  const {
    tradeRegistrationDetails,
    tradeRegistrationFileId,
    downloadTradeDocument,
  } = useAppSelector((state) => state.tradeRegistration);

  useEffect(() => {
    dispatch(getTradeRegistration());
  }, []);

  useEffect(() => {
    if (tradeRegistrationFileId) {
      dispatch(downloadFileTradeRegistration(tradeRegistrationFileId.id));
    }
  }, [tradeRegistrationFileId]);

  useEffect(() => {
    if (tradeRegistrationDetails) {
      setTradeRegistrationNumber(
        tradeRegistrationDetails?.tradeRegistrationNumber
      );
      tradeRegistrationDetails.documentId &&
        dispatch(
          downloadFileTradeRegistration(tradeRegistrationDetails.documentId)
        );
    }
  }, [tradeRegistrationDetails]);

  const [file, setFile] = useState('');

  function imageChange(e: React.FormEvent<EventTarget> | any): void {
    (document.getElementById('addImage') as HTMLInputElement).innerHTML = (
      e?.target as HTMLFormElement
    )?.files[0]?.name;
    const bloFile = URL.createObjectURL(
      (e?.target as HTMLFormElement)?.files[0]
    );
    const imageFile = e.target?.files;
    setFile(bloFile);
    dispatch(tradeRegistrationFile(imageFile));
  }

  const handleSubmit = (data: { tradeRegistrationNumber: string }) => {
    const tradeNumber = {
      tradeRegistrationNumber: data.tradeRegistrationNumber,
      documentId: tradeRegistrationFileId
        ? tradeRegistrationFileId?.id
        : tradeRegistrationDetails.documentId,
    };
    dispatch(createTradeRegistration(tradeNumber));
  };

  return (
    <div className="mt-6 lg:mt-12 w-full h-auto">
      <div className="bg-white  w-full mt-16 grid lg:grid-cols-5 lg:px-24 sm:px-3 py-6 lg:min-h-screen lg:space-y-6 lg:gap-4 ">
        <AccountListSideBar handleSubmit={handleSubmit} />
        <div className=" lg:p-8  w-full p-4 col-span-4 lg:shadow-[3px_0px_15px__rgba(0,0,0,0.15)] rounded-md">
          <h1 className="text-34 font-bold font-satoshi rtl:font-Almarai text-secondary mb-4 ">
            {t('PROFILE.PROFILE_INFO.TRADE_REGISTRATION')}
          </h1>
          <p className="text-17.5 text-gray font-normal font-general rtl:font-Almarai mb-6">
            {t('PROFILE.NOTIFICATION.NOTIFICATION_DEC')}
          </p>
          <div className="border border-info mb-8"></div>

          <Formik
            initialValues={{
              tradeRegistrationNumber,
            }}
            validationSchema={tradeValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form id="my-form">
                <div className="lg:w-2/5">
                  <Input
                    id="tradeRegistrationNumber"
                    type="text"
                    name="tradeRegistrationNumber"
                    placeholder={t('PROFILE.PROFILE_INFO.TRADE_REGISTRATION')}
                    onBlur={handleBlur}
                    error={touched.tradeRegistrationNumber && 1}
                    onChange={handleChange}
                    value={values.tradeRegistrationNumber}
                    errormessage={errors.tradeRegistrationNumber}
                  />
                </div>
                <div className="mb-4 font-bold text-20 lg:text-18 font-satoshi rtl:font-Almarai text-secondary">
                  {t('PROFILE.PROFILE_INFO.TRADE_REG_DOC')}
                </div>
                <div className="lg:flex  lg:items-center mb-4">
                  <p className="flex lg:hidden mb-6  text-17 font-normal sm:mt-5 font-general rtl:font-Almarai text-gray">
                    {' '}
                    {t('PROFILE.PROFILE_INFO.UPLOAD_DOC_DEC')}
                  </p>
                  <div className="w-64   h-auto justify-center text-center rounded-lg">
                    {downloadTradeDocument?.fileContent ? (
                      <img
                        src={
                          file.length
                            ? file
                            : `data:image/png;base64,${
                                downloadTradeDocument &&
                                downloadTradeDocument?.fileContent
                              }`
                        }
                        className="w-64 h-40"
                        alt=""
                      />
                    ) : (
                      <div className="bg-[#f6f8f5] rounded w-64 h-40 flex justify-center items-center">
                        <img src="/assets/default-img-icon.svg" alt="" />
                      </div>
                    )}
                  </div>
                  <div className="">
                    <p className=" ltr:ml-5 rtl:mr-5 hidden lg:flex text-17 font-normal font-general rtl:font-Almarai text-gray">
                      {t('PROFILE.PROFILE_INFO.UPLOAD_DOC_DEC')}
                    </p>
                    <div className="flex">
                      <label htmlFor="image">
                        <p className=" lg:ltr:ml-5 lg:rtl:mr-5   w-5/5 lg:w-[190px] bg-skyBlue rounded text-center mt-6 lg:mt-2 px-4 py-2 text-secondary font-satoshi rtl:font-Almarai text-12 lg:text-14 font-bold">
                          {t('PROFILE.PROFILE_INFO.UPLOAD_PROFILE')}
                        </p>
                      </label>
                      <input
                        id="image"
                        name="fileId"
                        className="form-control"
                        accept=".jpg,.jpeg,.png,.pdf"
                        type="file"
                        onChange={(e) => {
                          imageChange(e);
                          handleChange(e);
                        }}
                        style={{ display: 'none' }}
                      />
                      <span
                        id="addImage"
                        className="ml-3 mt-8 lg:mt-4 text-13 font-general rtl:font-Almarai rtl:mr-3 not-italic font-normal text-gray"
                      >
                        {downloadTradeDocument?.originalFileName
                          ? downloadTradeDocument?.originalFileName
                          : t('PROFILE.PROFILE_INFO.NO_IMAGE')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" grid lg:hidden">
                  <button
                    form="my-form"
                    type="submit"
                    onClick={() => handleSubmit}
                    className="  py-4 px-8 text-white font-satoshi rtl:font-Almarai font-bold bg-primary rounded"
                  >
                    {t('PROFILE.PROFILE_INFO.SAVE_CHANGE')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const TradeRegistration = styled(Component)``;

export default TradeRegistration;

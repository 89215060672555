import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IModalElements,
  IModalHeaderElements,
} from '../clientInterfaces/modal';
import { RootState } from '../store';
import  ModalConstants  from '../clientConstants/modal/modalConstants';

const initialState: IModalElements = {
  createOpen: false,
  editOpen: false,
  deleteOpen: false,
  getOpen: false,
  title: '',
};

export const modalSlice = createSlice({
  name: 'modalItems',
  initialState,
  reducers: {
    modalState: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      const { key, value } = action.payload;
      if (key === ModalConstants.MODAL_CREATE) {
        state.createOpen = value;
      } else if (key === ModalConstants.MODAL_DELETE) {
        state.deleteOpen = value;
      } else if (key === ModalConstants.MODAL_EDIT) {
        state.editOpen = value;
      } else if (key === ModalConstants.MODAL_GET) {
        state.getOpen = value;
      }
    },
    setModalHeader: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        title: action.payload.title,
      };
    },
  },
});
export const setModalElements =
  (elements: IModalHeaderElements) => async (dispatch: any) => {
    dispatch(setModalHeader(elements));
  };

export const { modalState, setModalHeader } = modalSlice.actions;
export const modalInfo = (state: RootState) => state.modalInfo;
export const modalReducer = modalSlice.reducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getCoachRevenueClientParams,
  getCoachRevenueOverviewParams,
  getCoachReviewParams,
} from '../coachHelpers/query';
import { IPerformanceSliceInterface } from '../coachInterfaces/paymentInterface';
import {
  downloadReceiptTransaction,
  fetchCoachPerformanceCount,
  fetchCoachPerformanceOverView,
  fetchCoachRevenueClients,
  fetchReView,
  fetchTransactionDetails,
  withdrawMoneyRequest,
} from '../coachServices/performanceService';
import { showAlert } from '../slice/alert';
import { RootState } from '../store';

const initialState: IPerformanceSliceInterface = {
  isProcessingCoachCounts: false,
  isProcessingCoachOverview: false,
  isProcessingCoachRevenueClients: false,
  isProcessingReview: false,
  isTransaction: false,
  transactionDetails: [],
  coachCounts: {
    activeClientCount: 0,
    lastMonthActiveClientCount: 0,
    lastMonthClientCount: 0,
    lastMonthTotalRevenue: 0,
    totalClientCount: 0,
    totalRevenue: 0,
  },
  coachOverView: [],
  coachRevenueClients: [],
  coachReview: [],
  clientsFilter: {
    serviceStatusFilter: '',
    filter: '',
    page: 0,
    size: 10,
  },
  overviewFilter: {
    filter: '',
    page: 0,
    size: 10,
  },
  reviewFilter: {
    coachServiceFilter: 'ALL',
    filter: '',
    page: 0,
    size: 10,
  },
  transactionFilter: {
    withdrawnStatus: 'ALL',
    sort: 'id,desc',
    page: 0,
    size: 10,
    coachId: 0,
  },
  clientTotalPages: 0,
  overviewTotalPages: 0,
  reviewTotalPages: 0,
};

export const performanceSlice = createSlice({
  name: 'performanceSlice',
  initialState,
  reducers: {
    setClientServiceStatusFilter: (state, action: PayloadAction<string>) => {
      state.clientsFilter.serviceStatusFilter = action.payload;
    },
    setClientDayfilter: (state, action: PayloadAction<string>) => {
      state.clientsFilter.filter = action.payload;
    },
    setOverviewDayfilter: (state, action: PayloadAction<string>) => {
      state.overviewFilter.filter = action.payload;
    },
    setOverviewPage: (state, action: PayloadAction<number>) => {
      state.overviewFilter.page = action.payload;
    },
    setClientsPage: (state, action: PayloadAction<number>) => {
      state.clientsFilter.page = action.payload;
    },
    setReviewfilter: (state, action: PayloadAction<string>) => {
      state.reviewFilter.filter = action.payload;
    },
    setReviewPage: (state, action: PayloadAction<number>) => {
      state.reviewFilter.page = action.payload;
    },
    setTransactionsDetailsPage: (state, action: PayloadAction<any>) => {
      state.transactionFilter.page = action.payload.page;
      state.transactionFilter.coachId = action.payload.id;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getCoachPerformanceCounts.pending, (state) => {
        state.isProcessingCoachCounts = true;
      })
      .addCase(getCoachPerformanceCounts.fulfilled, (state, action) => {
        state.isProcessingCoachCounts = false;
        state.coachCounts = action.payload?.data[0];
      })
      .addCase(getCoachPerformanceCounts.rejected, (state) => {
        state.isProcessingCoachCounts = false;
      })
      .addCase(getCoachPerformanceOverview.pending, (state) => {
        state.isProcessingCoachCounts = true;
        state.isProcessingCoachOverview = true;
      })
      .addCase(getCoachPerformanceOverview.fulfilled, (state, action) => {
        state.isProcessingCoachCounts = false;
        state.isProcessingCoachOverview = false;

        state.coachOverView = action.payload?.data[0]?.content;
        state.overviewTotalPages = action.payload?.data[0]?.totalPages;
      })
      .addCase(getCoachPerformanceOverview.rejected, (state) => {
        state.isProcessingCoachCounts = false;
      })
      .addCase(getCoachRevenueClients.pending, (state) => {
        state.isProcessingCoachRevenueClients = true;
      })
      .addCase(getCoachRevenueClients.fulfilled, (state, action) => {
        state.isProcessingCoachRevenueClients = false;
        state.coachRevenueClients = action.payload?.data[0]?.content;
        state.clientTotalPages = action.payload?.data[0]?.totalPages;
      })
      .addCase(getCoachRevenueClients.rejected, (state) => {
        state.isProcessingCoachRevenueClients = false;
      })
      .addCase(getCoachReview.pending, (state) => {
        state.isProcessingReview = true;
      })
      .addCase(getCoachReview.fulfilled, (state, action) => {
        state.isProcessingReview = false;
        state.coachReview = action.payload?.data[0]?.content;
        state.reviewTotalPages = action.payload?.data[0]?.totalPages;
      })
      .addCase(getCoachReview.rejected, (state) => {
        state.isProcessingReview = false;
      })
      .addCase(getTransactionsDetails.pending, (state) => {
        state.isTransaction = true;
      })
      .addCase(getTransactionsDetails.fulfilled, (state, action) => {
        state.isTransaction = false;
        state.transactionDetails = action.payload?.data[0]?.content;
      })
      .addCase(getTransactionsDetails.rejected, (state) => {
        state.isTransaction = false;
      });
  },
});

export const getCoachPerformanceCounts = createAsyncThunk(
  'get/getCoachCounts',
  async (id, thunkAPI) => {
    try {
      const response = await fetchCoachPerformanceCount();

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachPerformanceOverview = createAsyncThunk(
  'get/getCoachPerformanceOverview',
  async (filter: any, thunkAPI) => {
    try {
      const params = getCoachRevenueOverviewParams(
        filter.filter,
        filter.page,
        filter.size
      );
      const response = await fetchCoachPerformanceOverView(params);

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachReview = createAsyncThunk(
  'get/getCoachReview',
  async (filter: any, thunkAPI) => {
    try {
      const params = getCoachReviewParams(
        filter.coachServiceFilter,
        filter.filter,
        filter.page,
        filter.size
      );
      const response = await fetchReView(params);

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachRevenueClients = createAsyncThunk(
  'get/getCoachRevenueClinets',
  async (filter: any, thunkAPI) => {
    try {
      const params = getCoachRevenueClientParams(
        filter.serviceStatusFilter,
        filter.filter,
        filter.page,
        filter.size
      );
      const response = await fetchCoachRevenueClients(params);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getTransactionsDetails = createAsyncThunk(
  'get/getTransactionsDetails',
  async (id: any, thunkAPI) => {
    try {
      const params = await fetchTransactionDetails(id);
      return params;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const withdrawMoney = createAsyncThunk(
  'get/withdrawMoney',
  async (id: any, thunkAPI) => {
    try {
      const params = await withdrawMoneyRequest(id);
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: params?.message,
          messageAr: params?.messageAr
        })
      );
      // window.location.reload();
      return params;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const downloadTransaction = createAsyncThunk(
  'get/downloadTransaction',
  async (id: number, thunkAPI) => {
    try {
      const params = await downloadReceiptTransaction(id);
      const pdfBlob = new Blob([params], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'reciept.pdf';
      link.click();
      return params;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const {
  setClientServiceStatusFilter,
  setClientDayfilter,
  setOverviewDayfilter,
  setOverviewPage,
  setClientsPage,
  setReviewfilter,
  setReviewPage,
  setTransactionsDetailsPage,
} = performanceSlice.actions;
export const performanceInfo = (state: RootState) => state.performanceInfo;
export const performanceInfoReducer = performanceSlice.reducer;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import CarouselCard from '../../clientComponents/carouselCard';
import { purchaseViewMy, selectPayment } from '../../clientSlices/payment';

const MyPurchase = () => {
  const [removeToggle, setRemoveToggle] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { myPurchase, isProcessingpayment } = useSelector(selectPayment);

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(purchaseViewMy(searchParams));
  }, [searchParams]);
  const onHandleSearch = (e: any) => {
    e.preventDefault();
    if (search !== null) {
      setSearchParams({ keyword: search });
      // dispatch(getWishlists(searchParams));
    }
  };
  const [search, setSearch] = useState(searchParams.get('keyword'));

  const [t] = useTranslation('common');
  return (
    <div>
      <div className="lg:mx-24 mx-5">
        <h1 className="mt-20 mb-12 font-satoshi rtl:font-Almarai lg:text-48 text-28 font-bold ">
          {t('LANDING_PAGE_AFTER_LOGIN.MY_PURCHASE')}
        </h1>
        <div className="w-full">
          <form>
            <div className=" my-upload-search-box">
              <div className="w-[10%]  h-[44px] flex items-center justify-center">
                <button type="submit" onClick={(e: any) => onHandleSearch(e)}>
                  <img
                    src="/assets/gridView/searchIcon.svg"
                    id={search as string}
                    alt=""
                  />
                </button>
              </div>
              <input
                type="text"
                id="simple-search"
                className="text-gray-900 text-sm h-[42px] search-input  w-[85%] rtl:font-Almarai"
                placeholder={t('MY_UPLOADS.SEARCH_TXT')}
                required
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
                value={search as string}
              />
              {search && (
                <img
                  onClick={() => {
                    setSearchParams();
                    setSearch('');
                  }}
                  className="cursor-pointer"
                  src="/assets/subscriptionPage/plusIcon.svg"
                  alt="bell-icon"
                />
              )}
            </div>
          </form>
        </div>
        {!isProcessingpayment ? (
          myPurchase.length ? (
            <div className="mt-12 ">
              <CarouselCard data={myPurchase} isLoading={isProcessingpayment} />
            </div>
          ) : (
            <div className="justify-center flex h-[57vh] items-center flex-col rtl:font-Almarai">
              {t('EMPTY_MESSAGES.PURCHASE_EMPTY')}
              <div>
                <Link to="/search-result" className="text-primary">
                  {t('CLICK_HERE')}
                </Link>{' '}
                {t('EMPTY_MESSAGES.CART_ADD')}
              </div>
            </div>
          )
        ) : (
          <div className="mt-12 ">
            <CarouselCard data={[]} isLoading={isProcessingpayment} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPurchase;

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import moment from 'moment';
import { useEffect, useState } from 'react';
import arabic_ar from 'react-date-object/locales/arabic_ar';
import gregorian_en from 'react-date-object/locales/gregorian_en';
import DatePicker, { Calendar } from 'react-multi-date-picker';
// import 'react-multi-date-picker/styles/colors/black.css';
import 'moment/min/locales';
import { momentLocalizer } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { CalendarProps } from 'react-multi-date-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAccessToken } from '../../clientHelpers/localStorage';
import {
  bookingASlot,
  getSchedulesSlot,
  recommendedSessionList,
  selectClientHome,
  setBookSessionToggle,
  setTimeSlotInitials,
  viewSession,
} from '../../clientSlices/home';
import { scheduleDateToLocal } from '../../coachHelpers/utility';
import { useAppSelector } from '../../hooks';
import { showAlert } from '../../slice/alert';
import Button from '../buttons';
import './index.css';

interface CustomCalendarProps extends CalendarProps {
  disabledDates?: string[];
}

function CustomCalendar(props: CustomCalendarProps) {
  return <Calendar {...props} />;
}
interface ISelectTimeSlot {
  toggleFunction?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleValue?: boolean;
  addToCart: any;
  findEndDate: any;
  availableSlot: any;
  coachId: number;
  multiple: boolean;
  type?: string;
  data?: any;
  timeSlotMatching?: any;
}

const SelecttTimeSlot = ({
  data,
  toggleFunction,
  toggleValue,
  addToCart,
  findEndDate,
  availableSlot,
  coachId,
  multiple,
  type,
  timeSlotMatching,
}: ISelectTimeSlot) => {
  const [selectedDates, setSelectedDates] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const [duration, setDuration] = useState(2);
  const { lang } = useAppSelector((state) => state.language);
  const localizer = momentLocalizer(moment);

  moment.locale(lang === 'en' ? 'en' : 'ar');

  useEffect(() => {
    dispatch(setTimeSlotInitials({}));
  }, []);
  const [pickedDate, setPickedDate] = useState(
    moment(new Date()).locale('en').format('dddd, MMMM DD')
  );

  const [bookedSession, setBookedSession] = useState<
    { date: string; startTime: string; endTime: string }[]
  >([]);
  const { timeSlots, scheduleData, isLoading } = useSelector(selectClientHome);
  const dateFormat = (date: any) => {
    // var output = date.split(/[, ]+/).pop();
    // return moment(output).format('YYYY-MM-DD') as any;
  };
  const timeSlotChecking = (data: any) => {
    const date1 = moment(selectedDate).locale('en').format('YYYY-MM-DD');
    const date2 = scheduleDateToLocal(data);
    return moment(date1).isSame(moment(date2));
  };
  const handleChange = (date: any) => {
    const temp: any = {};
    var output = moment(date.split(/[, ]+/).pop()).locale('en').format('YYYY-MM-DD').toString() as any;
    setSelectedDate(output);
    if (!availableSlot?.includes(output)) {
      setPickedDate('');
      dispatch(setTimeSlotInitials(''));
    }
    output
      ? setPickedDate(
          (value) => (value = moment(output).locale('en').format('dddd, MMMM DD'))
        )
      : setPickedDate('');
    temp['startDate'] =
      timeSlotMatching[moment(output).locale('en').format('YYYY-MM-DD')];
    temp['endDate'] =
      timeSlotMatching[moment(output).locale('en').format('YYYY-MM-DD')];

    if (multiple) {
      temp['sessionId'] =
        searchParams.get('serviceType') === 'PACKAGES'
          ? searchParams?.get('serviceId')
          : searchParams?.get('id');
      temp['packageId'] =
        searchParams.get('serviceType') === 'PACKAGES'
          ? searchParams?.get('id')
          : 0;
    } else temp['coachId'] = searchParams?.get('id');
    if (output && availableSlot.includes(output)) {
      dispatch(getSchedulesSlot(temp));
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: t('PLEASE_SELECT'),
        })
      );
    }
    setSelectedDates(
      (value) => (value = moment(output).locale('en').format('YYYY-MM-DD') as any)
    );
  };

  const [slotBookingToggle, setSoltBookingToggle] = useState(false);
  const [slotConfirmToggle, setSlotConfirmToggle] = useState(false);
  const [timeId, setTimeId] = useState(null);
  const { bookSessionToggle } = useSelector(selectClientHome);

  // const handleDateSelect = (date: DateObject | DateObject[]) => {
  //   const index = selectedDates.findIndex(
  //     (d) => d.getTime() === date.getTime()
  //   );
  //   if (index === -1) {
  //     // If the date isn't already selected, add it to the array
  //     setSelectedDates([...selectedDates, date]);
  //   } else {
  //     // If the date is already selected, remove it from the array
  //     const updatedDates = [...selectedDates];
  //     updatedDates.splice(index, 1);
  //     setSelectedDates(updatedDates);
  //   }
  // };
  const navigate = useNavigate();

  const handleBookSlot = async () => {
    if (!getAccessToken()) {
      dispatch(setBookSessionToggle(false));
      navigate('/login?user=CLIENT');
      return;
    }
    let sessionId;
    if (multiple) {
      if (searchParams?.get('serviceId')) {
      }
    }
    const payload = {
      dateTimeRequest: bookedSession,
      scheduleType: multiple ? 'SESSION' : 'DEMO_SESSION',
      coachId: multiple ? coachId : Number(searchParams?.get('id')),
      sessionId: multiple
        ? searchParams?.get('serviceType') === 'PACKAGES'
          ? Number(searchParams?.get('serviceId'))
          : Number(searchParams?.get('id'))
        : 0,
      packageId: multiple
        ? searchParams?.get('serviceType') === 'PACKAGES'
          ? Number(searchParams?.get('id'))
          : 0
        : 0,
    };
    dispatch(setBookSessionToggle(false));
    await dispatch(
      bookingASlot({
        payload: payload,
        addToCart: addToCart,
        serviceType: searchParams?.get('serviceType'),
      })
    );
    if (
      searchParams.get('serviceType') === 'PACKAGES' &&
      searchParams.get('id')
    )
      dispatch(
        viewSession({
          serviceId: searchParams.get('id'),
          serviceType: searchParams.get('serviceType')?.toLowerCase(),
        })
      );
    if (searchParams.get('serviceType') === 'PACKAGES')
      dispatch(
        recommendedSessionList(
          searchParams.get('serviceType')?.toLowerCase() as string
        )
      );
    // toggleFunction={setBookSessionToggle}
    // toggleValue={bookSessionToggle};
    // addToCart();
  };

  const selectedTime = (data: any) => {
    // const dat = bookedSession.push(data);
    !bookedSession.includes(data) &&
      setBookedSession((value: any) => (value = [...bookedSession, data]));
  };

  const showConfirmation = () => {
    if (bookedSession.length) {
      setSlotConfirmToggle(true);
    } else setSlotConfirmToggle(false);
  };
  const handleBookSession = (value: any) => {
    setBookedSession([value]);
    // bookedSession?.length === 0 && setBookedSession([ value]);
    // let temp: { date: string; startTime: string; endTime: string }[] = [];
    // let tempVar = 0;

    // bookedSession.map((bookedValue, index) => {
    //   if (
    //     bookedValue?.date === value?.date &&
    //     bookedValue?.startTime === value?.startTime &&
    //     bookedValue?.endTime === value?.endTime
    //   ) {
    //     tempVar += 1;
    //     bookedSession.map((bookedItem) => {
    //       if (
    //         bookedItem?.date !== value?.date ||
    //         (bookedItem?.date === value?.date &&
    //           bookedItem?.startTime !== value?.startTime &&
    //           bookedItem?.endTime !== value?.endTime)
    //       ) {
    //         temp.push(bookedItem);
    //       }
    //     });
    //   }
    // });
    // if (tempVar >= 1) {
    //   setBookedSession(temp);
    // } else {
    //   setBookedSession([...bookedSession, value]);
    // }
    type === 'COACH' && setBookedSession([value]);
  };

  const [t] = useTranslation('common');
  return (
    <div className=" fixed top-0 left-0 right-0 bottom-0 bg-lightBlack   flex items-center justify-center z-50">
      <div className=" lg:max-w-[78%] xl:max-w-[55vw] max-w-[90vw] lg:h-fit  bg-white rounded flex lg:flex-row flex-col px-6 lg:mt-9 mt-20 mb-10 relative lgmax:overflow-scroll ">
        <img
          onClick={() => dispatch(setBookSessionToggle(!bookSessionToggle))}
          className="absolute ltr:right-3 rtl:left-3 top-3 cursor-pointer"
          src="/assets/closeButton.svg"
          alt=""
        />
        {slotBookingToggle && (
          <img
            onClick={() => (
              setSoltBookingToggle(!slotBookingToggle),
              setSlotConfirmToggle(!slotConfirmToggle)
            )}
            className="absolute ltr:left-3 rtl:right-3 top-3"
            src="/assets/goBack.svg"
            alt=""
          />
        )}
        {!slotBookingToggle && (
          <div className=" w-full lg:w-[30%] flex flex-col flex-shrink lg:justify-between gap-5 pt-6 lg:pb-10">
            <div>
              {' '}
              <img src="/assets/kunLogo.svg" alt="" />
            </div>
            <div className="flex flex-col lg:gap-6 gap-4">
              <h1
                className={`lg:text-24 text-20 font-bold font-satoshi rtl:font-Almarai`}
              >
                {data?.name}
              </h1>
              <div className="flex gap-5 items-center">
                <img
                  className="lg:h-5 h-4"
                  src="/assets/clientSession/duration.svg"
                  alt=""
                />
                <div className="lg:text-17 text-13 font-normal font-general rtl:font-Almarai">
                  {' '}
                  {data?.duration ? data?.duration : 15}&nbsp;{t('MINS')}
                </div>
              </div>
              <div className="flex gap-5 items-center">
                <img
                  className="lg:h-5 h-4"
                  src="/assets/clientSession/location.svg"
                  alt=""
                />
                <div className="lg:text-17 text-13 font-normal font-general rtl:font-Almarai">
                  {t('SLOT_BOOK.BIG_BLUE_BUTTON')}
                </div>
              </div>
            </div>
            <div>
              <p className="text-13 text-transparentBlack font-normal font-general rtl:font-Almarai flex flex-grow w-fit ltr:pr-4 rtl:pl-4">
                {t('SLOT_BOOK.DESCRIPTION')}{' '}
              </p>
            </div>
          </div>
        )}

        <div
          className={`  lg:w-full lgmax:items-center ${
            slotConfirmToggle
              ? 'lg:min-h-[60vh]'
              : 'lg:max-h-[60%] max-h-[45vh] lgmax:overflow-y-scroll '
          } flex flex-col lg:gap-5 lg:justify-between lg:ltr:pl-7 lg:rtl:pr-7 mt-4 lg:mt-0 lg:ltr:border-l lg:rtl:border-r border-disableGray pt-6 pb-10`}
        >
          {!slotConfirmToggle && (
            <div className="">
              <div
                className={`font-bold text-20 font-satoshi rtl:font-Almarai pb-6 lg:pb-0 ${
                  slotBookingToggle && 'hidden'
                }`}
              >
                {t('SLOT_BOOK.SELECT_A_DATE_AND_TIME')}
              </div>
              <div
                className={`flex ${
                  !slotBookingToggle
                    ? ' lg:flex-row flex-col h-fit'
                    : 'flex-row'
                }`}
              >
                <div
                  className={`flex over flex-row h-fit lg:ltr:pl-8 lg:rtl:pr-8 w-full`}
                >
                  {!slotBookingToggle && (
                    <div className=" h-fit flex-grow flex flex-col lg:justify-between">
                      <div className=" flex justify-center pb-2 lg:pb-0">
                        <DatePicker
                          locale={gregorian_en}
                          value={selectedDates}
                          onChange={handleChange}
                          format="YYYY-MM-DD"
                          render={
                            <Calendar
                              minDate={new Date()}
                              value={selectedDates}
                              // value={availableSlot}
                              onChange={(dateObject) => {
                                handleChange(dateObject?.toString());
                              }}
                              onMonthChange={(data) => {
                                findEndDate(data.toString());
                              }}
                              multiple={multiple}
                              format="YYYY-MM-DD"
                              className="my-calendar"
                              mapDays={({
                                date,
                                today,
                                selectedDate,
                                disabled,
                                currentMonth,
                                isSameDate,
                              }: any) => {
                                let props: any = {};
                                props.style = {
                                  borderRadius: '0px',
                                  backgroundColor: '#ffffff',
                                  border: '1px solid rgba(56, 56, 56, 0.25)',
                                  color: 'rgba(56, 56, 56, 0.25)',
                                  height: '30px',
                                  width: '30px',
                                  padding: '0px',
                                };

                                if (isSameDate(date, today))
                                  props.style = {
                                    ...props.style,

                                    color: '#ffffff',
                                    border: '1px solid #9ee0e6',
                                    backgroundColor: ' #9ee0e6',
                                    height: '30px',
                                    width: '30px',
                                    padding: '0px',
                                  };

                                if (
                                  availableSlot.some(
                                    (item: string) =>
                                      moment(item).format('YYYY-MM-DD') ===
                                      moment(
                                        `${date.year}-${date.month.number}-${date.day}`
                                      ).format('YYYY-MM-DD')
                                  )
                                ) {
                                  props.style = {
                                    ...props.style,
                                    color: 'rgba(2, 164, 234, 1)',
                                    backgroundColor: ' #ffffff',
                                    fontWeight: 'bold',
                                    border: '1px solid rgba(2, 164, 234, 1)',
                                    height: '30px',
                                    width: '30px',
                                    padding: '0px',
                                  };
                                } else {
                                  props.style = {
                                    ...props.style,

                                    cursor: 'not-allowed',
                                    borderRadius: '0px',
                                    backgroundColor: '#ffffff',
                                    border: '1px solid rgba(56, 56, 56, 0.25)',
                                    color: 'rgba(56, 56, 56, 0.25)',
                                    height: '30px',
                                    width: '30px',
                                    padding: '0px',
                                  };
                                }

                                return props;
                              }}
                            />
                          }
                        />
                      </div>
                      <div className="border-y border-disableGray  mt-0  h-10 flex lg:gap-[18px] gap-5 lg:items-center w-full py-2">
                        <div className=" flex gap-2 lg:my-4  lg:h-4 items-center lg:flex-grow">
                          <img
                            src="/assets/clientSession/availableSlots.svg"
                            alt=""
                          />
                          <div className="text-12 font-general rtl:font-Almarai font-normal text-light-black">
                            {t('SLOT_BOOK.AVAILABLE_SLOT')}
                          </div>
                        </div>
                        <div className=" flex gap-2 lg:h-4  items-center flex-grow   ">
                          <img
                            src="/assets/clientSession/closedSlots.svg"
                            alt=""
                          />

                          <div className="text-12 font-general rtl:font-Almarai font-normal text-light-black">
                            {t('SLOT_BOOK.CLOSED_SLOTS')}
                          </div>
                        </div>
                      </div>
                      <div
                        className={` pt-4 flex items-center justify-center lg:justify-start mt-5 lg:mt-0 gap-[10px]  w-full lg:h-7  ${
                          slotBookingToggle && 'hidden'
                        }`}
                      >
                        <img
                          className="lg:h-full h-4"
                          src="/assets/globeLogo.svg"
                          alt=""
                        />
                        <div className="lg:w-full text-12 lg:text-14 font-general rtl:font-Almarai font-normal text-gray ">
                          {t('SLOT_BOOK.TIME_STATUS')} -{' '}
                          {localStorage.getItem('timezone')}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`lg:ltr:pl-7 lg:rtl:pr-7 w-[78vw] lg:w-full lg:flex flex-grow flex-col gap-2 lg:h-[46vh] rtl:font-Almarai ${
                    slotBookingToggle && 'hidden'
                  }`}
                >
                  <div
                    className={`flex justify-center pb-3 w-full  lg:flex-row flex-col `}
                  >
                    <div
                      className={`h-[24px]  ${
                        slotBookingToggle &&
                        'flex justify-center font-bold text-18 font-satoshi rtl:font-Almarai pt-10 '
                      } `}
                    >
                      {pickedDate && pickedDate}
                    </div>
                    <div
                      className={`${
                        slotBookingToggle &&
                        'flex justify-center font-bold lg:text-18 text-14 font-satoshi rtl:font-Almarai  pt-4'
                      } `}
                    >
                      {/* August 25 */}
                    </div>
                    {slotBookingToggle && (
                      <div
                        className={`lg:ltr:pl-8 lg:rtl:pr-8 flex items-center justify-center lg:justify-start mt-5 lg:mt-0 gap-[10px]  w-full lg:h-7  `}
                      >
                        <img
                          className="lg:h-full h-4"
                          src="/assets/globeLogo.svg"
                          alt=""
                        />
                        <div className="lg:w-full text-12 lg:text-14 font-general rtl:font-Almarai font-normal text-gray">
                          {t('SLOT_BOOK.TIME_STATUS')} -{' '}
                          {localStorage.getItem('timezone')}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex gap-6 flex-col lg:h-full justify-between w-full  pb-6 ">
                    {slotBookingToggle && (
                      <div className="flex  flex-col font-general rtl:font-Almarai gap-2 pt-6 justify-center pb-1">
                        <div className="text-16 font-semibold flex justify-center ">
                          {t('SLOT_BOOK.SELECT_TIME')}
                        </div>
                        <div className="text-12 font-normal  flex justify-center">
                          {t('SLOT_BOOK.DURATION')} 2 {t('SLOT_BOOK.HOURS')}
                        </div>
                      </div>
                    )}
                    <ul
                      className={`flex flex-col gap-1   lg:overflow-scroll lg:h-[calc(46vh-60px)] pb-5 `}
                    >
                      {pickedDate
                        ? isLoading
                          ? [1, 2, 3, 4, 5, 6].map(() => (
                              <div className="w-full rounded h-13 bg-offWhite animate-pulse flex justify-center items-center">
                                <div className="h-6 w-[45%] bg-[#e4e4e7] animate-pulse"></div>
                              </div>
                            ))
                          : timeSlots?.map((value: any, index: number) => (
                              <li
                                className={`${
                                  timeSlotChecking(value?.startDateTime)
                                    ? ''
                                    : 'hidden'
                                }`}
                                key={`${value.id}-time-${index}`}
                                onClick={() => handleBookSession(value)}
                              >
                                {
                                  <Button
                                    className={` w-full rounded h-13 border border-disableGray  ${bookedSession.map(
                                      (item) =>
                                        item?.date == value?.date &&
                                        item?.startTime == value?.startTime &&
                                        item?.endTime == value?.endTime
                                          ? ' bg-secondary text-white '
                                          : ' border-disableGray '
                                    )}`}
                                    children={
                                      <div>
                                        {moment(
                                          new Date(`${value.startDateTime}Z`),
                                          'h:mm'
                                        ).format('HH:mm')}{' '}
                                        -{' '}
                                        {moment(
                                          new Date(`${value.endDateTime}Z`),
                                          'h:mm'
                                        ).format('HH:mm')}
                                      </div>
                                    }
                                  />
                                }
                              </li>
                            ))
                        : ''}
                    </ul>
                    {!slotBookingToggle && (
                      <Button
                        onClick={() => showConfirmation()}
                        className={`hidden lg:block rounded !h-13  ${
                          bookedSession.length
                            ? 'bg-primary'
                            : 'bg-disableGray cursor-not-allowed'
                        } w-full`}
                        children={
                          <div
                            className={`font-general rtl:font-Almarai text-white text-13 font-normal`}
                          >
                            {t('SLOT_BOOK.NEXT')}
                          </div>
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {slotConfirmToggle && (
            <div className="flex flex-col w-[75vw] lg:w-full  justify-between ">
              <div className="text-20 font-satoshi rtl:font-Almarai font-bold mt-5 mb-5">
                {t('SLOT_BOOK.SCHEDULES_OVERVIEW')}
              </div>
              <div className="h-[55vh] overflow-scroll">
                {bookedSession.map((item: any, index: number) => {
                  return (
                    <div
                      className="p-2 text-18 font-satoshi rtl:font-Almarai font-bold "
                      key={`${item.id}-book-${index}`}
                    >
                      <EventOutlinedIcon
                        sx={{ color: '#02A4EA' }}
                        className="mr-3 rtl:ml-3 "
                      />
                      {moment(new Date(`${item.startDateTime}Z`)).format(
                        'DD MMM YYYY'
                      )}
                      :{'   '}
                      {moment(
                        new Date(`${item.startDateTime}Z`),
                        'h:mm'
                      ).format('HH:mm')}{' '}
                      -{' '}
                      {moment(new Date(`${item.endDateTime}Z`), 'h:mm').format(
                        'HH:mm'
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                className={`pt-5 lg:border-t border-disableGray
               flex flex-col-reverse gap-4 lg:flex-row justify-center lg:justify-between`}
              >
                <div
                  onClick={() => (
                    setSlotConfirmToggle(false), setSoltBookingToggle(false)
                  )}
                  className=" cursor-pointer w-full flex items-center  justify-center lg:w-fit text-18 font-bold font-satoshi rtl:font-Almarai"
                >
                  {t('SLOT_BOOK.BACK')}
                </div>
                <div
                  className="lg:w-[40%] w-full  "
                  // onClick={() => addToCart()}
                >
                  <Button
                    onClick={handleBookSlot}
                    className={' rounded h-13  bg-primary w-full'}
                    children={
                      <div
                        className={`font-satoshi rtl:font-Almarai text-white text-16 font-bold  w-full rtl:font-Almarai`}
                      >
                        {t('SCHEDULE.SCHEDULE')}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {!slotConfirmToggle && (
          <div className="lg:hidden flex w-full flex-grow justify-center pt-5 mb-5">
            <Button
              className={`${
                bookedSession.length ? 'bg-primary' : 'bg-disableGray'
              }  w-full`}
              children={
                <div
                  onClick={() => (
                    setSoltBookingToggle(!slotBookingToggle),
                    setSlotConfirmToggle(true)
                  )}
                  className={`py-4 w-full text-14 font-satoshi rtl:font-Almarai font-bold text-white rounded`}
                >
                  {t('SLOT_BOOK.NEXT')}
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelecttTimeSlot;

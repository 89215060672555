import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/modals';

type ITabbyProps = {
  price?: number;
  toggle: boolean;
  children?: any;
  toggleFunction?: () => void;
  title?: string;
  titleChildren?: JSX.Element;
};
const TabbyPopup: React.FC<ITabbyProps> = ({
  toggle,
  toggleFunction,
  price = 0,
}) => {
  const [t] = useTranslation('common');
  const dates = [
    { value: 'Today' },
    { value: 'in 1 month' },
    { value: 'in 2 month' },
    { value: 'in 3 month' },
  ];
  const howItWorks = [
    {
      value: (
        <div className="flex">
          Choose
          <div>
            <img className="h-5" src="/assets/payment/tabbyLogo.svg" alt="" />
          </div>
          at checkout
        </div>
      ),
    },
    { value: 'Enter your information and add your debit or credit card' },
    { value: 'Split your purchase in 4 monthly payments' },

    { value: "We'll send SMS reminders when your next payment is due" },
  ];
  const payIcons = ['visa', 'master', 'mada', 'ipay'];
  return (
    <div>
      <Modal
        titleChildren={
          <div className="w-full px-5 pt-5">
            <img className="h-8" src="/assets/payment/tabbyLogo.svg" alt="" />
          </div>
        }
        logOutClose={toggleFunction}
        show={toggle}
      >
        <div
          // onClick={() => deleteAccount()}
          className=" h-fit w-full flex flex-col md:mt-11 mt-[72px]  items-center "
        >
          <div className="h-10 w-full md:text-24 text-22 flex justify-center text-center rtl:font-Almarai  font-general">
            Split in 4 interest-free payments of {t('SAR ')}
            {`${price}`}
          </div>
          <div className="pt-4 pb-6 text-14 font-normal text-transparentBlack w-full">
            Get what you love, split the cost, no fees
          </div>
          <div className=" w-full">
            <div className="flex flex-col w-fit gap-2 px-2 pb-6  ">
              <div className="flex items-center  w-full px-1 smmd:px-4 sm:px-5">
                <div>
                  <img
                    className="h-8"
                    src="/assets/payment/firstPayment.svg"
                    alt=""
                  />
                </div>
                <div className=" flex items-center flex-grow">
                  <div className="h-[2px] w-full bg-lightBlack mx-1"></div>
                </div>

                <div>
                  <img
                    className="h-8"
                    src="/assets/payment/secondPayment.svg"
                    alt=""
                  />
                </div>

                <div className=" flex items-center flex-grow">
                  <div className="h-[2px] w-full bg-lightBlack mx-1"></div>
                </div>
                <div>
                  <img
                    className="h-8"
                    src="/assets/payment/thirdPayment.svg"
                    alt=""
                  />
                </div>

                <div className=" flex items-center flex-grow">
                  <div className="h-[2px] w-full bg-lightBlack mx-1"></div>
                </div>
                <div>
                  <img
                    className="h-8"
                    src="/assets/payment/fourthPayment.svg"
                    alt=""
                  />
                </div>
              </div>

              <div className="flex gap-5 text-12">
                {dates?.map((data: any, index: number) => {
                  return (
                    <div className=" flex flex-col" key={index}>
                      <div className="text-12 rtl:font-Almarai  font-bold text-center">
                        {Number(price) / 4} {t('SAR')}
                      </div>
                      <div className="text-13 text-normal text-transparentBlack flex justify-center ">
                        <span className=" font-normal">{data.value}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="w-full text-16 font-general pb-3 ">How it works:</div>
          <div className="w-full text-14 font-normal text-transparentBlack flex flex-col gap-1 pb-3 ">
            {howItWorks.map((item, index) => (
              <div className="flex flex-row gap-1">
                <div>{index + 1}.</div>
                <div>{item?.value}</div>
              </div>
            ))}
          </div>
          <div className="border-b border-lightBlack w-full pb-3 text-16 font-general text-[#0000FF]">
            <div className=" cursor-pointer" onClick={toggleFunction}>
              Continue shopping{' '}
              <ArrowForwardIcon
                sx={{ color: '#0000FF', width: '16px', height: '16px' }}
              />
            </div>
          </div>
          <div className="flex justify-between border-b border-lightBlack w-full mt-0 py-3 text-16 font-general">
            <div className="text-left">
              {/* Content for the left div */}
              <div className="text-transparentBlack font-normal">
                Use any card with tabby
              </div>
            </div>
            <div className="text-right">
              {/* Content for the right div */}
              <div className="flex">
                {payIcons.map((element) => (
                  <img
                    className="h-5"
                    src={`/assets/payment/${element}.svg`}
                    alt=""
                  />
                ))}
              </div>
            </div>
          </div>
          {/* <div className=" flex ">Use any card with tabby</div>
          <div className="w-full flex md:flex-row flex-col-reverse justify-center gap-5 pb-12"></div> */}
        </div>
      </Modal>
    </div>
  );
};

export default TabbyPopup;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ILanguage {
  lang: string;
}
const initialState: ILanguage = {
  lang: 'ar',
};
export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    languageSelect: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        lang: action.payload,
      };
    },
  },
});

export const { languageSelect } = languageSlice.actions;
export const selectLang = (state: RootState) => state.language.lang;
export const languageSelectReducer = languageSlice.reducer;

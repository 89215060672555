import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import CommonCarousal from '../../clientComponents/carousal';
import { getTodaySchedules, selectSchedule } from '../../clientSlices/schedule';
import DraftCard from '../../coachComponents/HomePageComponents/DraftCard';
import HomeCard from '../../coachComponents/HomePageComponents/HomeCard';
import ScheduleCard from '../../coachComponents/HomePageComponents/ScheduleCard';
import { useAppSelector } from '../../coachHelpers/hooks';
import { scheduleDateToLocal, UtcDateToTime } from '../../coachHelpers/utility';
import { IdraftInterface } from '../../coachInterfaces/coachHomeInterface';
import {
  fetchCountOfService,
  listDrafts,
} from '../../coachSlices/coachHomeSlice';
import { getCoachPerformanceCounts } from '../../coachSlices/coachPerformance';
import { fetchMyUploads, myUploadInfo } from '../../coachSlices/myUploadSlice';
import { coachProfile } from '../../coachSlices/profileSlices/profileInfoSlice';
import NoData from '../../components/noData';
import OverlayLoader from '../../components/overlayLoader';
import './index.css';

const Component = () => {
  const [t] = useTranslation('common');
  let now = dayjs();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(coachProfile());
    dispatch(listDrafts());
    dispatch(fetchCountOfService());
    dispatch(getCoachPerformanceCounts());
  }, []);

  const [dateNum, setDateNum] = useState<number>(0);
  const [date, setDate] = useState<string>(now.locale('en').format('MMM D, YYYY',));
  const { lang } = useAppSelector((state) => state.language);
  const { drafts, isProcessingDrafts } = useAppSelector(
    (state) => state.coachHomeInfo
  );
  const prevDate = () => {
    setDate(now.subtract(dateNum + 1, 'day').locale('en').format('YYYY-MM-DD'));
    setDateNum((value) => (value = dateNum + 1));
    dispatch(getTodaySchedules(moment(dateNum).locale('en').format('YYYY-MM-DD')));
  };
  const nxtDate = () => {
    setDate(now.subtract(dateNum - 1, 'day').locale('en').format('YYYY-MM-DD'));
    setDateNum((value) => (value = dateNum - 1));
    dispatch(getTodaySchedules(moment(dateNum).locale('en').format('YYYY-MM-DD')));
  };
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);

  // Set the locale for the moment instance used by the calendar component
  moment.locale(lang === 'en' ? 'en' : 'ar');

  const notificationData = [
    {
      title: 'Semper feugiat nibh sed pulvinar',
      subTitle:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
      time: '4h',
    },
    {
      title: 'Semper feugiat nibh sed pulvinar',
      subTitle:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
      time: '2m',
    },
    {
      title: 'Semper feugiat nibh sed pulvinar',
      subTitle:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
      time: '3m',
    },
    {
      title: 'Semper feugiat nibh sed pulvinar',
      subTitle:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.',
      time: '5h',
    },
  ];
  useEffect(() => {
    const data = {
      startDate: moment(new Date()).locale('en').format('YYYY-MM-DD'),
      endDate: moment(new Date()).locale('en').format('YYYY-MM-DD'),
    };
    dispatch(getTodaySchedules(data));
  }, []);
  const { scheduleTodayData, homeServices, serviceType, isProcessingSchedule } =
    useSelector(selectSchedule);
  const scheduleData = [
    {
      title: 'Demo with Client',
      date: '25/08/2022',
      time: '9 AM - 11 AM',
    },
    {
      title: 'Demo with Client',
      date: '25/08/2022',
      time: '9 AM - 11 AM',
    },
    {
      title: 'Demo with Client',
      date: '25/08/2022',
      time: '9 AM - 11 AM',
    },
    {
      title: 'Demo with Client',
      date: '25/08/2022',
      time: '9 AM - 11 AM',
    },
  ];
  useEffect(() => {
    dispatch(
      fetchMyUploads({
        search: '',
        activityStatus: 'LIVE',
        requestStatusFilter: 'APPROVED',
      })
    );
  }, []);
  const { myCoachUploads, isProcessingMyCoachUploads, deletedId } =
    useSelector(myUploadInfo);

  return (
    <>
      <Helmet>
        <title>{t('KUN_COACH')}</title>
      </Helmet>
      <div className=" home-container">
        <HomeCard />
        {isProcessingMyCoachUploads ? (
          <OverlayLoader loader={true} />
        ) : myCoachUploads.length > 0 ? (
          <div className="relative pb-14">
            <div className="flex flex-row mt-16 h-[41px] items-center ">
              <div className="  landing-heading-client  flex-grow mt-10">
                {t(`COACH_HOME.MY_UPLOADS`)}
              </div>
              {myCoachUploads.length > 1 ? (
                <button
                  className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded invisible md:visible"
                  onClick={() => {
                    navigate('/coach/my-uploads?filter=APPROVED');
                  }}
                >
                  {t(`COACH_HOME.SEE_ALL_MY_UPLOADS`)}
                </button>
              ) : null}
            </div>

            <CommonCarousal data={myCoachUploads} serviceType={'My Uploads'} />

            <div className="flex justify-center">
              <button
                onClick={() => {
                  navigate('/coach/my-uploads?filter=APPROVED');
                }}
                className="sm_max:absolute sm_max:bottom-0 sm_max:left-[15vw] sm_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded visible md:invisible"
              >
                {t(`COACH_HOME.SEE_ALL_MY_UPLOADS`)}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="  landing-heading-client  flex-grow mt-10">
              {t(`COACH_HOME.MY_UPLOADS`)}
            </div>
            <div className="w-full h-[70vh] flex justify-center items-center font-bold text-2xl">
              <NoData />
            </div>
          </div>
        )}
        <div className="notification-box-row ">
          <div className="notification-box ">
            <div className=" notification-box-heading font-bold text-xl">
              <span className="draft-heading ">{t('HOME.DRAFT')}</span>
            </div>
            <div className="notification-box-card ">
              {drafts?.length > 0 ? (
                <div>
                  {drafts.map((item: IdraftInterface) => (
                    <DraftCard item={item} />
                  ))}
                </div>
              ) : isProcessingDrafts ? (
                <div className="w-full h-full flex justify-center items-center">
                  <div className="loader"></div>
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <NoData />
                </div>
              )}
            </div>
          </div>
          <div className="schedule-box ">
            <div className=" schedule-box-heading font-bold text-lg rtl:font-Almarai">
              {date === dayjs().format('MMM D, YYYY')
                ? t('HOME.TODAYS_SCHEDULE')
                : date}
              {/* <div className="flex items-center">
                {lang === 'en' ? (
                  <>
                    <img
                      className="mx-8 cursor-pointer w-[0.313rem] h-[0.5rem]"
                      src="/assets/homePageImages/back-arrow.svg"
                      onClick={prevDate}
                      alt="bell-icon"
                    />
                    <img
                      onClick={nxtDate}
                      className="cursor-pointer w-[0.313rem] h-[0.5rem]"
                      src="/assets/homePageImages/front-arrow.svg"
                      alt="bell-icon"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="mx-8 cursor-pointer w-[0.313rem] h-[0.5rem]"
                      src="/assets/homePageImages/front-arrow.svg"
                      alt="bell-icon"
                    />
                    <img
                      className="cursor-pointer w-[0.313rem] h-[0.5rem]"
                      src="/assets/homePageImages/back-arrow.svg"
                      alt="bell-icon"
                    />
                  </>
                )}
              </div> */}
            </div>
            <div className="pt-5 w-full overflow-y-auto h-[20rem]">
              {scheduleTodayData.length ? (
                scheduleTodayData.map(
                  ({ title, scheduledDate, startTime, endTime }: any) => (
                    <ScheduleCard
                      title={title}
                      date={scheduleDateToLocal(startTime)}
                      time={`${UtcDateToTime(startTime)}-${UtcDateToTime(
                        endTime
                      )}`}
                    />
                  )
                )
              ) : isProcessingSchedule ? (
                <div className="w-full h-full flex justify-center items-center">
                  <div className="loader"></div>
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  <NoData />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const HomePage = styled(Component)``;

export default HomePage;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formDataConverter } from '../coachHelpers/utility';
import { getApi } from '../coachServices';
import { postData, postFile } from '../coachServices/apiService';
import { fileUploadRegistration } from '../coachServices/assessmentService';
import { env } from '../config/env';
import { showAlert } from '../slice/alert';

export const registrationFormDetails = createAsyncThunk(
  'auth/register',
  async (userData: any, thunkAPI) => {
    try {
      const accreditationRequestIdArray: number[] = [];

      if (userData?.accreditationRequest) {
        await Promise.all(
          userData?.accreditationRequest?.map(
            async (item: any, index: number) => {
              if (item.file?.length !== 0) {
                if (item?.file) {
                  const imageData = await thunkAPI.dispatch(
                    uploadFile({
                      file: item?.file,
                      fileType: 'COACH_ACCREDITATION',
                    })
                  );
                  accreditationRequestIdArray.push(
                    imageData.payload.data[0]?.id
                  );
                }
              } else {
                accreditationRequestIdArray.push(0);
              }
            }
          )
        );
      }
      const educationRequestsIdArray: number[] = [];
      if (userData?.educationRequests) {
        await Promise.all(
          userData?.educationRequests?.map(async (item: any, index: number) => {
            if (item.file?.name) {
              const imageData = await thunkAPI.dispatch(
                uploadFile({
                  file: item?.file,
                  fileType: 'EDUCATION_CERTIFICATES',
                })
              );
              educationRequestsIdArray.push(imageData.payload.data[0]?.id);
            } else {
              educationRequestsIdArray.push(0);
            }
          })
        );
      }
      const experienceRequestsIdArray: number[] = [];

      if (userData?.experienceRequests) {
        await Promise.all(
          userData?.experienceRequests?.map(
            async (item: any, index: number) => {
              if (item?.file?.name) {
                const imageData = await thunkAPI.dispatch(
                  uploadFile({
                    file: item?.file,
                    fileType: 'EXPERIENCE_CERTIFICATE',
                  })
                );
                experienceRequestsIdArray.push(imageData?.payload?.data[0]?.id);
              } else {
                experienceRequestsIdArray.push(0);
              }
            }
          )
        );
      }
      userData?.accreditationRequest?.map((data: any, index: number) => {
        delete data['file'];
        data['fileId'] = accreditationRequestIdArray[index];
      });
      userData?.educationRequests?.map((data: any, index: number) => {
        delete data['file'];
        data['fileId'] = educationRequestsIdArray[index];
      });
      userData?.experienceRequests?.map((data: any, index: number) => {
        delete data['file'];
        data['fileId'] = experienceRequestsIdArray[index];
      });

      const response = await postData(
        `${env.development.BASE_URL}/coaches`,
        userData
      );
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: 'Successfully applied. Will recieve mail after admin approval.',
          messageAr: "تم تقدم الطلب بنجاح سيتم ارسال بريد الألكتروني بعد الموافقة"
        })
      );
      userData.history('/login?user=COACH');

      return response;
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({
          type: 'error',
          message: err?.message,
          messageAr: err?.messageAr,
        })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const oneMSizeByByte = 1024 * 1024;

export const uploadFile = createAsyncThunk(
  'image/upload',
  async (payload: { fileType: string; file: any }, thunkAPI) => {
    try {
        if (!!payload.file && payload.file.size <= oneMSizeByByte) {
            const response = await fileUploadRegistration(
                formDataConverter(payload.file, payload.fileType)
            );

            return response;
        } else {
            thunkAPI.dispatch(
                showAlert({
                    type: 'error',
                    message: 'File size should be less than 1MB',
                })
            );
        }
    } catch (err: any) {
      thunkAPI.dispatch(
        showAlert({ alertType: 'error', message: err.message })
      );
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const coachingFile = createAsyncThunk(
  'coachingFile',
  async (userData: any, thunkAPI) => {
    try {
      const res = await Promise.all(
        userData?.map(async (item: any, index: number) => {
          const formData: any = new FormData();
          formData.append('file-type', 'COACH_ACCREDITATION');
          formData.append('file', item[0]);
          return await postFile(`${env.development.BASE_URL}/files`, formData);
        })
      );
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const educationFile = createAsyncThunk(
  'educationFile',
  async (userData: any, thunkAPI) => {
    try {
      const res = await Promise.all(
        userData?.map(async (item: any, index: number) => {
          const formData: any = new FormData();
          formData.append('file-type', 'EDUCATION_CERTIFICATES');
          formData.append('file', item[0]);
          return await postFile(`${env.development.BASE_URL}/files`, formData);
        })
      );
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const experienceFile = createAsyncThunk(
  'experienceFile',
  async (userData: any, thunkAPI) => {
    try {
      const res = await Promise.all(
        userData?.map(async (item: any, index: number) => {
          const formData: any = new FormData();
          formData.append('file-type', 'EXPERIENCE_CERTIFICATE');
          formData.append('file', item[0]);
          return await postFile(`${env.development.BASE_URL}/files`, formData);
        })
      );
      return res;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const cityList = createAsyncThunk(
  'cityList',
  async (country: string, thunkAPI) => {
    try {
      if (country) {
        const response = await getApi(
          `${env.development.BASE_URL}/cities?country=${country}`
        );
        return response;
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const countryList = createAsyncThunk(
  'countryList',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(`${env.development.BASE_URL}/countries`);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const timezoneList = createAsyncThunk(
  'timezoneList',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(`${env.development.BASE_URL}/timezones`);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const languageList = createAsyncThunk(
  'languageList',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(`${env.development.BASE_URL}/languages`);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const coachingCategoryList = createAsyncThunk(
  'coachingCategoryList',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(
        `${env.development.BASE_URL}/coaching-categories`
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const coachingSpecializationList = createAsyncThunk(
  'coachingSpecializationList',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(
        `${env.development.BASE_URL}/coaching-specializations`
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getCoachDetailsById = createAsyncThunk(
  'getCoachDetailsById',
  async (id: any, thunkAPI) => {
    try {
      const response = await getApi(
        `${env.development.BASE_URL}/coaches/coach-details/` + id
      );
      const data = response?.data?.data[0];
      thunkAPI.dispatch(
        personalInformation({
          fullName: data?.fullName || '',
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          address01: data?.addressLine1,
          address02: data?.addressLine2,
          country: data?.country || '',
          city: data?.city || '',
          dateOfBirth: data?.dob || '',
          gender: data?.gender || '',
          countryCode: data?.countryCode || '+966',
        })
      );
      thunkAPI.dispatch(
        specializationInformation({
          language: data?.languages,
          coachingCategories: data?.coachingCategories,
          coachingSpecialization: data?.specialization,
        })
      );
      thunkAPI.dispatch(
        coachingInformation({
          accreditationRequest: data?.accreditationResponses?.map(
            (item: any) => ({
              ...item,
              certificates: item?.certificatesName,
              file: [],
            })
          ),
        })
      );
      thunkAPI.dispatch(
        educationalInformation({
          educationRequests: data?.educationResponses?.map((item: any) => ({
            ...item,
            fileId: '',
          })),
        })
      );
      thunkAPI.dispatch(
        experienceInformation({
          experienceRequests: data?.experienceResponses?.map((item: any) => ({
            ...item,
            role: item?.isCurrentlyWorking,
            fileId: '',
          })),
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
// isCurrentlyWorking
const initialState: any = {
  educationFileId: [],
  accreditationFileId: [],
  experienceFileId: [],
  isSuccess: false,
  educationalFiles: [],
  cityDropDown: [],
  languageDropDown: [],
  coachingSpecializationDropDown: [],
  coachingCategoryDropDown: [],
  cityDropDownList: [],
  timezoneValueList: [],
  countryDropDownList: [],
  registrationInfo: [],
  personalInfo: {},
  accreditationRequest: {},
  specializationInfo: {},
  educationalRequests: {},
  experienceRequests: {},
  // [
  // {
  //   title: '',
  //   companyName: '',
  //   location: '',
  //   startDate: '',
  //   description: '',
  //   fileId: '',
  //   role: false,
  //   endDate: '',
  //   file: [],
  // },
  // ],
  accreditationRequestFiles: [],
  isProcessingRegistration: false,
  isProcessingCoachDetailsById: false,
};

export const registrationSlice = createSlice({
  name: 'personalData',
  initialState: initialState,
  reducers: {
    personalInformation: (state, action) => {
      state.personalInfo = action.payload;
    },
    coachingInformation: (state, action) => {
      state.accreditationRequest = action.payload;
    },
    specializationInformation: (state, action) => {
      state.specializationInfo = action.payload;
    },
    educationalInformation: (state, action) => {
      state.educationalRequests = action.payload;
    },
    experienceInformation: (state, action) => {
      state.experienceRequests = action.payload;
    },
    educationalFile: (state, action) => {
      state.educationalFiles = action.payload;
    },
    setAccreditationRequestFiles: (state, action) => {
      state.accreditationRequestFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registrationFormDetails.pending, (state) => {
        state.isSuccess = false;
        state.isProcessingRegistration = true;
      })
      .addCase(registrationFormDetails.fulfilled, (state, action) => {
        state.registrationInfo = action.payload;
        state.isSuccess = action?.payload?.success;
        state.personalInfo = {};
        state.accreditationRequest = {};
        state.specializationInfo = {};
        state.educationalRequests = {};
        state.educationalFiles = {};
        state.experienceRequests = {};
        state.isProcessingRegistration = false;
      })
      .addCase(registrationFormDetails.rejected, (state) => {
        state.isSuccess = false;
        state.isProcessingRegistration = false;
      })
      .addCase(coachingFile.pending, (state) => {})
      .addCase(coachingFile.fulfilled, (state, action) => {
        state.accreditationFileId = action.payload;
      })
      .addCase(coachingFile.rejected, (state) => {})
      .addCase(educationFile.pending, (state) => {})
      .addCase(educationFile.fulfilled, (state, action) => {
        state.educationFileId = action.payload;
      })
      .addCase(educationFile.rejected, (state) => {})
      .addCase(experienceFile.pending, (state) => {})
      .addCase(experienceFile.fulfilled, (state, action) => {
        state.experienceFileId = action.payload;
      })
      .addCase(experienceFile.rejected, (state) => {})
      .addCase(cityList.pending, (state) => {})
      .addCase(cityList.fulfilled, (state, action) => {
        state.cityDropDown = action.payload?.data?.data[0];
      })
      .addCase(countryList.pending, (state) => {})
      .addCase(countryList.fulfilled, (state, action) => {
        state.countryDropDownList = action.payload?.data?.data[0];
      })
      .addCase(timezoneList.pending, (state) => {})
      .addCase(timezoneList.fulfilled, (state, action) => {
        state.timezoneValueList = action.payload?.data?.data[0];
      })
      .addCase(languageList.pending, (state) => {})
      .addCase(languageList.fulfilled, (state, action) => {
        state.languageDropDown = action.payload?.data?.data[0];
      })
      .addCase(coachingSpecializationList.pending, (state) => {})
      .addCase(coachingSpecializationList.fulfilled, (state, action) => {
        state.coachingSpecializationDropDown = action.payload?.data?.data;
      })
      .addCase(coachingCategoryList.pending, (state) => {})
      .addCase(coachingCategoryList.fulfilled, (state, action) => {
        state.coachingCategoryDropDown = action.payload?.data?.data[0];
      })
      .addCase(getCoachDetailsById.pending, (state) => {
        state.isProcessingCoachDetailsById = true;
      })
      .addCase(getCoachDetailsById.rejected, (state) => {
        state.isProcessingCoachDetailsById = false;
      })

      .addCase(getCoachDetailsById.fulfilled, (state, action) => {
        state.isProcessingCoachDetailsById = false;
      });
  },
});

export const {
  personalInformation,
  coachingInformation,
  specializationInformation,
  educationalInformation,
  experienceInformation,
  educationalFile,
  setAccreditationRequestFiles,
} = registrationSlice.actions;
export const registrationDetails = registrationSlice.reducer;

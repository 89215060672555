import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISocialMediaInterface } from '../../coachInterfaces/profileInterface';
import {
  getSocialMedia,
  postSocialMedia,
} from '../../coachServices/authenticationService';
import { showAlert } from '../../slice/alert';

const initialState: any = {
  isSocialMediaUpdateSuccess: false,
  socialMediaDetails: {},
};

export const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(createSocialMedia.pending, (state) => {
        state.isSocialMediaUpdateSuccess = false;
      })
      .addCase(createSocialMedia.fulfilled, (state, action) => {
        state.isSocialMediaUpdateSuccess = true;
      })
      .addCase(createSocialMedia.rejected, (state) => {
        state.isSocialMediaUpdateSuccess = false;
      })

      .addCase(getAllSocialMedia.pending, (state) => {
        state.socialMediaDetails = [];
      })
      .addCase(getAllSocialMedia.fulfilled, (state, action) => {
        state.socialMediaDetails = action?.payload?.data[0];
      })
      .addCase(getAllSocialMedia.rejected, (state) => {
        state.tradeRegistrationDetails = [];
      });
  },
});

//updateTradeRegistration APIS

export const createSocialMedia = createAsyncThunk(
  'createSocialMedia',
  async (linkData: ISocialMediaInterface, thunkAPI) => {
    try {
      const response = await postSocialMedia(linkData);
      thunkAPI.dispatch(getAllSocialMedia());
      thunkAPI.dispatch(
        showAlert({
          type: 'success',
          message: response?.message,
          messageAr: response?.messageAr
        })
      );
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//getTradeRegistration APIS

export const getAllSocialMedia = createAsyncThunk(
  'getAllSocialMedia',
  async (_, thunkAPI) => {
    try {
      const response = await getSocialMedia();
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const socialMediaReducer = socialMediaSlice.reducer;

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ContactUsPageBody } from '../../clientComponents/contactUsBody';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { coachingInformation, educationalFile, educationalInformation, experienceInformation, personalInformation, setAccreditationRequestFiles, specializationInformation } from '../../coachSlices/registrationSlice';
const ContactUsPage = () => {
  const [t] = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <div className="form-header">
        <ContactUsPageBody />
      </div>
    </>
  );
};

export default ContactUsPage;

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Image } from '../../components/Image';
import { Bestseller } from '../bestSeller';

export const SearchListViewContent = ({
  serviceType,
  data,
  handleListAll,
  showAll,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  return (
    <>
      <h1 className="font-bold font-satoshi rtl:font-Almarai text-2xl pb-6 pt-6">
        {t(`SEARCH_RESULT_VIEW.${serviceType.toUpperCase()}`)}
      </h1>
      {data && data.length
        ? data?.map((item: any, index: number) => (
            <div
              className="flex mt-5 shadow-lg rounded-lg cursor-pointer"
              key={index}
              onClick={() =>
                navigate(
                  `/service-view?id=${
                    item.id
                  }&serviceType=${serviceType.toUpperCase()}`
                )
              }
            >
              <div className="w-[30%]">
                {/* <img
                  className="w-full h-full object-cover rounded-r-lg"
                  src={item.image}
                  alt=""
                ></img> */}
                <Image
                  id={(item?.imgId as number) || (item?.imageId as number)}
                  bgImgUrl={'/assets/default-img-icon.svg'}
                  bgClass={
                    ' bg-[#F6F8F5] object-fill ltr:rounded-l-lg rtl:rounded-r-lg flex justify-center items-center h-60 '
                  }
                  propClass={
                    'object-fill ltr:rounded-l-lg rtl:rounded-r-lg h-60 w-60'
                  }
                />
              </div>
              <div className="w-[70%] p-5">
                <div className="flex justify-between">
                  <div className="font-bold text-darkGrey text-2xl ">
                    {item.title}
                  </div>
                  <div>
                    <div className="font-bold text-2xl">
                      {t('SAR')}&nbsp;{item.price}
                    </div>
                  </div>
                </div>
                <div className="text-[12px] font-xs leading-4 text-gray  pb-1">
                  {item.subTitle}
                </div>
                <div className="text-[12px] font-bold leading-4  text-darkGrey   pb-1">
                  {item.coachName}
                </div>
                {/* <div className="flex flex-row justify-start items-center pb-1">
                  <span className="text-closeRed font-semibold text-xs pr-1">
                    {item.rating}
                  </span>
                  <Rating value={item.rating} />
                  <span className="text-[12px] font-xs leading-4 text-gray  pb-1">
                    {item.ratingCount}
                  </span>
                </div> */}
                <div className="text-[12px] font-xs text-gray  pb-1 ">
                  {item?.duration ? `${item?.duration} ${t('MINS')}` : null}
                </div>
                <div className="w-24 flex mt-6">
                  <Bestseller />
                </div>
              </div>
            </div>
          ))
        : null}
      {data && data.length ? (
        <div className="flex justify-center pt-10 pb-10">
          {showAll && (
            <button
              className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded invisible md:visible"
              onClick={() => handleListAll(serviceType)}
            >
              {t(`SEARCH_RESULT_VIEW.SEE_ALL_${serviceType.toUpperCase()}`)}
            </button>
          )}
        </div>
      ) : (
        <div className="flex justify-center">{/* <NoData /> */}</div>
      )}
    </>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import styled from 'styled-components';
import { IPathType } from '../../coachInterfaces/imageSlider';

const Component = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [t] = useTranslation('common');

  const imagePath = [
    {
      path: '/assets/registrationPageImages/add_coach_1.jpg',
      heading: t('SLIDER_IMAGE_TEXT.FIRST'),
      title: t('SLIDER_IMAGE_TEXT.DESCRIPTION1'),
    },
    {
      path: '/assets/registrationPageImages/add_coach_2.jpg',
      heading: t('SLIDER_IMAGE_TEXT.SECOND'),
      title: t('SLIDER_IMAGE_TEXT.DESCRIPTION2'),
    },
    {
      path: '/assets/registrationPageImages/add_coach_3.jpg',
      heading: t('SLIDER_IMAGE_TEXT.THIRD'),
      title: t('SLIDER_IMAGE_TEXT.DESCRIPTION3'),
    },
    {
      path: '/assets/registrationPageImages/add_coach_4.jpg',
      heading: t('SLIDER_IMAGE_TEXT.FORTH'),
      title: t('SLIDER_IMAGE_TEXT.DESCRIPTION4'),
    },
    {
      path: '/assets/registrationPageImages/add_coach_5.jpg',
      heading: t('SLIDER_IMAGE_TEXT.FIFTH'),
      title: t('SLIDER_IMAGE_TEXT.DESCRIPTION5'),
    },
  ];

  var settings = {
    className: 'w-screen lg:w-auto lg:h-auto',
    dotsClass: 'slick-dots',
    initialSlide: 0,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    pauseOnHover: true,
    arrows: false,
    appendDots: (dots: string) => {
      return (
        <ul className="" style={{ margin: '0px', display: '-webkit-box' }}>
          {dots}
        </ul>
      );
    },
    beforeChange: (prev: number, next: number) => {
      setCurrentSlideIndex(next);
    },
    customPaging: (i: number) => {
      const style = {
        width: 6,
        height: 6,
        display: 'inline-block',
        backgroundImage: `url(/assets/registrationPageImages/dot.svg)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      };
      const activeStyle = {
        width: 18,
        height: 6,
        display: 'inline-block',
        backgroundImage: `url(/assets/registrationPageImages/activeDot.svg)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      };
      return (
        <link
          className=" mb-[200%] ml-[-100%] md:ml-[10%] left-0"
          style={i === currentSlideIndex ? activeStyle : style}
        />
      );
    },
  };
  return (
    <div className=" lg:w-4/12 h-full">
      <Slider {...settings}>
        {imagePath.map((item: IPathType, index: number) => {
          return (
            <li key={index} className="relative h-full">
              <div className="ml-10 left-[-20px] md:left-[-2px] absolute bottom-[6%]  w-10/12 md:w-7/12  ">
                <h1 className="text-white font-bold text-22 md:text-28 mb-5">
                  {item.heading}
                </h1>
                <p className="text-white font-normal text-13 md:text-16 font-general rtl:font-Almarai mb-5">
                  {item.title}
                </p>
              </div>
              <div
                className="flex h-auto bg-no-repeat bg-cover min-h-screen "
                style={{
                  backgroundImage: `url(${item.path})`,
                }}
              ></div>
            </li>
          );
        })}
      </Slider>
    </div>
  );
};
const Carousel = styled(Component)``;

export default Carousel;

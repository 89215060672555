import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { decodeEmail } from '../../coachHelpers/localStorage';
import { myCoachUploadsInterface } from '../../coachInterfaces/myUploadInterface';
import {
  deleteAssessment,
  deletePackages,
  deleteSession,
} from '../../coachSlices/myUploadSlice';
import { Image } from '../../components/Image';
// import { randomCaps, randomFontSize } from '../../helpers/utility';
import './index.css';

const Component = ({
  item,
  filter,
}: {
  item: myCoachUploadsInterface;
  filter: string;
}) => {
  const [t] = useTranslation('common');
  const [mobileMenu, setMobileMenu] = useState<any>();
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const cardMenuContent = [
    {
      title: t('MY_UPLOADS.EDIT'),
      value: 'EDIT',
    },
    {
      title: t('MY_UPLOADS.DELETE'),
      value: 'DELETE',
    },
  ];
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        mobileMenu &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setMobileMenu(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [mobileMenu]);
  const handleDropdownClick = (value: string) => {
    if (value === 'DELETE') {
      switch (item?.type) {
        case 'ASSESSMENT':
          if (item?.id) dispatch(deleteAssessment(item.id));
          break;
        case 'PACKAGE':
          if (item?.id) dispatch(deletePackages(item.id));
          break;
        case 'SESSION':
          if (item?.id) dispatch(deleteSession(item.id));
          break;
      }
    }
    if (value === 'EDIT') {
      switch (item?.type) {
        case 'ASSESSMENT':
          if (item?.id)
            // dispatch(fetchAssessmentById({ id: item.id, navigate: navigate }));
            navigate(`/coach/coach-assessment?form=EDIT&id=${item?.id}`);
          break;
        case 'PACKAGE':
          // if (item?.id) dispatch(fetchPackageById(item.id));
          if (item?.id)
            navigate(`/coach/coach-package?form=EDIT&id=${item?.id}`);

          break;
        case 'SESSION':
          if (item?.id)
            navigate(`/coach/coach-session?form=EDIT&id=${item?.id}`);

          break;
      }
    }
  };
  return (
    <div className="w-full h-[120px] rounded-lg shadow-lg min-h-[325px] h-[100%] relative ">
      {mobileMenu !== `${item.id}${item?.type}` ? (
        <img
          onClick={() => setMobileMenu(`${item.id}${item?.type}`)}
          className="rotate-90 cursor-pointer absolute z-30 top-2 ltr:right-2 rtl:left-2 top-2 "
          src="/assets/homePageImages/menu-dots-icon.svg"
          alt="bell-icon"
        />
      ) : (
        <img
          onClick={() => setMobileMenu(false)}
          className="cursor-pointer absolute z-30 top-2 ltr:right-2 rtl:left-2 top-2 object-cover"
          src="/assets/subscriptionPage/plusIcon.svg"
          alt="bell-icon"
        />
      )}
      {mobileMenu === `${item.id}${item?.type}` && (
        <div
          ref={modalRef}
          className="coach-session-dropdown-container shadow-lg ltr:right-4 rtl:left-4 top-5 z-30 "
        >
          {cardMenuContent.map(({ title, value }) =>
            filter !== 'PENDING' ||
            (filter === 'PENDING' && value === 'DELETE') ? (
              <div
                onClick={() => handleDropdownClick(value)}
                className="h-10 border-b-[1px] border-[#38383840] w-full flex flex-row cursor-pointer"
              >
                <div className="h-full font-general rtl:font-Almarai font-normal text-base w-full px-6 flex items-center ">
                  {title}
                </div>
              </div>
            ) : null
          )}
        </div>
      )}
      <div
        className="object-cover  rounded-t h-40 w-full cursor-pointer"
        onClick={() =>
          navigate(
            `/service-view?id=${
              item?.id
            }&serviceType=${item?.serviceType.toUpperCase()}S`
          )
        }
      >
        <Image
          id={(item?.imgId as number) || (item?.imageId as number)}
          bgImgUrl={'/assets/default-img-icon.svg'}
          bgClass={
            ' bg-[#F6F8F5] object-cover  rounded-t-lg flex justify-center items-center h-[165px]'
          }
          propClass={'object-fill aspect-video w-full rounded-t h-40'}
        />
      </div>
      <div className="w-full min-h-[160px] h-fit p-3 flex flex-col justify-between">
        <div
          className="w-full flex justify-between font-bold text-lg cursor-pointer"
          onClick={() =>
            navigate(
              `/service-view?id=${
                item?.id
              }&serviceType=${item?.serviceType.toUpperCase()}S`
            )
          }
        >
          {item?.title}
        </div>
        <div className=" font-normal text-xs text-[#687E8E]" style={{ wordBreak: 'break-word' }}>
          {item?.subTitle}
        </div>
        <div className="font-bold text-xs text-[black]">{decodeEmail()}</div>
        {/* <span className="flex gap-2 text-xs items-center font-semibold text-[#BC455E] ">
          {item?.rating}
          <img src="/assets/rating-star-full.svg" className="" alt="" />
          <img src="/assets/rating-star-full.svg" className="" alt="" />
          <img src="/assets/rating-star-full.svg" className="" alt="" />
          <img src="/assets/rating-half-star.svg" className="" alt="" />
          <img src="/assets/rating-empty-star.svg" className="" alt="" />
          <span className="font-normal text-xs text-[#687E8E]">
            ({item?.ratingCount}&nbsp; {t('COACH_PACKAGE.RATINGS')})
          </span>
        </span> */}
        <div className="w-full flex justify-between items-center font-normal text-xs text-[#687E8E]">
          {item?.duration ? `${item?.duration} ${t('MINS')}` : null}
        </div>
        <div className="text-2xl font-bold text-lg w-full text-[black] flex justify-between items-center">
          {t('SAR')}&nbsp;{item?.price}
          {/* {(item?.state === 'PENDING' && (
            <div className="h-[25px] rounded w-fit px-2 bg-lightGreen font-general rtl:font-Almarai text-xs font-semibold flex items-center">
              {t('MY_UPLOADS.APPROVAL_PENDING')}
            </div>
          )}
          {item?.state === 'REJECTED' && (
            <div className="h-[25px] rounded w-fit px-2 bg-closeRed text-[#ffffff] font-general rtl:font-Almarai text-xs font-semibold flex items-center">
              {t('MY_UPLOADS.REJECTED')}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
const CoachMyUploadsCard = styled(Component)``;

export default CoachMyUploadsCard;

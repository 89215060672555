import { getFile, post } from '../clientServices/api';
import {
  coachRevenueClientType,
  coachRevenueOverviewType,
  coachReviewType,
} from '../coachHelpers/query';
import { get } from './apiService';

export const fetchCoachPerformanceCount = async () => {
  return await get('/coach-revenue/counts');
};
export const fetchCoachPerformanceOverView = async (
  params: coachRevenueOverviewType
) => {
  return await get('/coach-revenue/overview', params);
};
export const fetchCoachRevenueClients = async (
  params: coachRevenueClientType
) => {
  return await get('/coach-revenue/clients', params);
};
export const fetchTransactionDetails = async (id: number) => {
  return await get('/payment/withdrawn-history', id);
};
export const fetchCoachReview = async (id: string) => {
  return await get('/review-rating?reviewType=COACH&coachId=' + id);
};
export const fetchReView = async (params: coachReviewType) => {
  return await get('/review-rating/coaches?coachServiceFilter=ALL', params);
};
export const withdrawMoneyRequest = async (id: number) => {
  return await post(`/payment/withdraw/${id}`, {});
};
export const downloadReceiptTransaction = async (id: number) => {
  return await getFile(`/invoice/pay-out/${id}`);
};

import { getAccessToken } from './localStorage';
import {getLang} from "../clientHelpers/localStorage";

export const getHeaderInfo = async function () {
  const token = await getAccessToken();
  const lang = await getLang();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Language': lang
    },
  };
};
export const getImageHeaderInfo = async function () {
  const token = await getAccessToken();
  const lang = await getLang();

  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      'Language': lang
    },
  };
};

export const getFormDataHeader = async function () {
  const lang = await getLang();

  return {
    headers: {
      'Content-Type': 'application/json',
      'Language': lang
    },
  };
};

export const getFormFileHeader = async function () {
  const lang = await getLang();
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Language': lang
    },
  };
};

import { decodeUserId, getAccessToken } from '../coachHelpers/localStorage';
import {
  ILoginPayload,
  IPostCaptchaPayload,
} from '../coachInterfaces/loginInterface';
import { IUpdatePasswordPayload } from '../coachInterfaces/passwordInterface';
import {
  IBankingInterface,
  IEducationCoachInterface,
  IExperienceCoachInterface,
  ISocialMediaInterface,
  ITradeRegistrationInterface,
} from '../coachInterfaces/profileInterface';
import {
  deleteApi,
  get,
  post,
  postDataWithToken,
  putDataWithToken,
} from './apiService';

export const authenticate = async (data: ILoginPayload) => {
  return await post('/auth/login', data);
};
export const callLogOut = async () => {
  return await post('/auth/logout', {});
};
export const captchaImage = async () => {
  return await get('/auth/generate-captcha');
};
export const postVerifyCaptcha = async (captcha: IPostCaptchaPayload) => {
  return await post('/auth/verify-captcha', captcha);
};
export const getCoachProfile = async () => {
  return await get(`/coaches/me`);
};
export const updateCoachProfile = async (userData: any, id: number) => {
  return await putDataWithToken(`/coaches/${id}`, userData);
};
export const getProfilesEducations = async () => {
  return await get(`/educations?baseFilter=ACTIVE&page=0&size=8&sort=id,desc`);
};
export const educationById = async (id: number) => {
  return await get(`/educations/${id}`);
};
export const getProfilesExperience = async () => {
  return await get(`/experiences?baseFilter=ACTIVE&page=0&size=8&sort=id,desc`);
};
export const experienceById = async (id: number) => {
  return await get(`/experiences/${id}`);
};
export const postAddEducation = async (userData: IEducationCoachInterface) => {
  return await postDataWithToken(`/educations`, userData);
};
export const postAddExperience = async (
  userData: IExperienceCoachInterface
) => {
  return await postDataWithToken(`/experiences`, userData);
};
export const postEditExperience = async (
  userData: IExperienceCoachInterface
) => {
  return await putDataWithToken(
    `/experiences/${userData.id}`,
    userData?.data?.allData
  );
};
export const postEditEducation = async (userData: IEducationCoachInterface) => {
  return await putDataWithToken(
    `/educations/${userData.id}`,
    userData?.data?.allData
  );
};
export const educationDelete = async (id: number) => {
  return await deleteApi(`/educations/${id}`);
};
export const experienceDelete = async (id: number) => {
  return await deleteApi(`/experiences/${id}`);
};
export const postTradeRegistration = async (
  userData: ITradeRegistrationInterface
) => {
  return await postDataWithToken(`/trade-registers`, userData);
};
export const getTradeRegistrations = async () => {
  return await get(
    `/trade-registers/${decodeUserId(getAccessToken() as string)}`
  );
};
export const getSocialMedia = async () => {
  return await get(
    `/social-medias?baseFilter=ACTIVE&page=0&size=7&sort=id,desc`
  );
};
export const postSocialMedia = async (userData: ISocialMediaInterface) => {
  return await postDataWithToken(`/social-medias`, userData);
};
export const postAccountSecurity = async (userData: IUpdatePasswordPayload) => {
  return await postDataWithToken(`/auth/update-password`, userData);
};
export const getBankingInfo = async () => {
  return await get(
    `/banking-info?baseFilter=ACTIVE&page=0&size=7&sort=id,desc&coachId=${decodeUserId(
      getAccessToken() as string
    )}`
  );
};
export const getBankingById = async (id: number) => {
  return await get(`/banking-info/${id}`);
};
export const putBankingInfo = async (userData: IBankingInterface) => {
  return await postDataWithToken(`/banking-info`, userData);
};
export const postBankingInfo = async (data: IBankingInterface) => {
  return await putDataWithToken(`/banking-info/${data.id}`, data?.data?.data);
};
export const deleteBanking = async (id: number) => {
  return await deleteApi(`/banking-info/${id}`);
};
export const deleteCoach = async (id: number) => {
  return await deleteApi(`/coaches/${id}`);
};
export const downloadById = async (id: number) => {
  return await get(`/files/${id}`);
};

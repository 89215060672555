import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../../components/Image';
import { useAppSelector } from '../../hooks';
import Table from '../coachTable';

interface IoverviewTableProps {
  coachOverView: any;
  isLoading: boolean;
}
const OverviewTable: FC<IoverviewTableProps> = ({
  coachOverView,
  isLoading,
}): JSX.Element => {
  const [t] = useTranslation('common');
  const { lang } = useAppSelector((state) => state.language);
  moment.locale(lang === 'en' ? 'en' : 'ar');

  const header = [
    {
      label: t('PREFORMANCE.ITEM'),
      value: 'title',
    },
    {
      label: t('PREFORMANCE.UPLOAD_DATE'),
      value: 'createdAt',
    },

    {
      label: t('PREFORMANCE.OFFERING'),
      value: 'offering',
    },
    {
      label: t('PREFORMANCE.STUDENTS_ENROLLED'),
      value: 'studentsEn',
    },
    // {
    //   label: t('PREFORMANCE.RATING'),
    //   value: 'rating',
    // },
    {
      label: t('PREFORMANCE.REVENUE'),
      value: 'revenue',
    },
  ];
  const data = coachOverView?.map((item: any) => {
    return {
      ...item,
      title: (
        <div className="flex flex-row text-[14px] font-bold gap-2 items-center text-[#153243]">
          {' '}
          <Image
            id={
              item?.data?.serviceType !== 'PACKAGE'
                ? item?.data?.imgId
                : item?.data?.imageId
            }
            propClass={'w-10 h-10 rounded-full bg-lightBlack'}
            bgClass={
              'w-10 h-10 rounded-full flex items-center justify-center bg-lightBlack'
            }
            bgImgUrl={'/assets/profile/avatar.svg'}
          />
          {item?.data?.title}
        </div>
      ),
      offering:
        item?.data?.serviceType !== 'SESSION'
          ? '--'
          : `${item?.data?.duration} ${t('MINS')}`,
      createdAt: moment(item?.data?.createdAt).locale('en').format('MMM DD, YYYY'),
      studentsEn: item?.data?.clientCount ? item?.data?.clientCount : 0,
      rating: item?.data?.rating,
      revenue: `${t('SAR')} ${item?.revenue}`,
    };
  });

  return (
    <div className="md:block hidden">
      <Table
        header={header}
        data={data}
        loading={isLoading}
        category={''}
        profilePicture={undefined}
        profilePictureLoading={false}
      />
    </div>
  );
};

export default OverviewTable;

import axios from 'axios';
import { getHeaderInfo } from '../coachHelpers/apiHeaderCreator';

export const postApi = async function (url: string, body: any) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.post(url, body, header);
    return resp;
  } catch (err: any) {
    return err;
  }
};

export const getApi = async function (url: string) {
  try {
    const resp = await axios.get(url);
    return resp;
  } catch (err: any) {
    return err;
  }
};
